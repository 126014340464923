import React from "react"
import i18n from "i18next"
import { settings } from "./_settings.js"

import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import axios from "axios"
import _ from "lodash"

import { withSnackbar } from "notistack"
import { compose } from "recompose"

import {
  Grid,
  Typography,
  TextField,
  Button,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Link
} from "@material-ui/core";

import DialogConfirm from "components/Dialogs/DialogConfirm"
import DialogDeleteValidation from "components/Dialogs/DeleteValidationPhrase"
import AttributeComboBox from "components/ComboBox/AttributeComboBox"
import TableBoxTaxonomy from 'components/Forms/TableBoxTaxonomy'
import CreatableUIMult from 'components/ComboBox/CreatableUIMult'

import Settings from 'utils/Settings'
import Utils from 'utils/Utils'

const getAssetnameRegex = (text = "") => {
  if (typeof text === "string") {
    return (text.toUpperCase().indexOf("ASSETNAME") !== -1)
  }
  return false
}
class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      structure: [],
      required_structure: true,
      name: { pt: '', en: '', es: '' },
      dialogs: {
        updateDialogOpen: false,
      },
      dictionary: [],
      inputValue: '',
      formErrors: {},
      separator: "_",
    }

    this.IS_CREATE = props.typeForm === "create"
  }

  componentDidMount() {
    const ID = this.props.match.params.id
    this.setState((prev) => ({
      ...prev,
      id: ID,
    }))
    if (ID) {
      this.getData(ID)
    }
  }

  getData(id) {
    const SELF = this
    const { requestHeaders, pathServer } = SELF.props
    const { t } = this.props
    axios
      .get(`${pathServer}/${SELF.state.entity}/${id}`, requestHeaders)
      .then(
        function (resp) {
          const data = _.get(resp, 'data.item.structure', []).map(i => {
            if (i.column === 'name') {
              i.value = `${i.entity}.entity`
            } else {
              i.value = i.column
            }
            return i
          })

          let state = {
            separator: _.get(resp, 'data.item.separator', '_'),
            asset_regex: _.get(resp, 'data.item.asset_regex', ''),
            name: _.get(resp, 'data.item.title', { pt: '', en: '', es: '' }),
            structure: data
          }
          let dictionary = Utils.getFailOver(resp, 'data.item.asset_regex_dictionary', [])

          if (dictionary.length > 0) {
            state.dictionary = dictionary
              .split(';')
              .filter(i => Boolean(i))
              .map(i => ({ value: i, label: i }))
          }

          if (Boolean(_.get(resp, 'data.item.asset_regex', ''))
            && _.get(resp, 'data.item.asset_regex', '').length > 0) {
            if (getAssetnameRegex(_.get(resp, 'data.item.asset_regex', ''))) {
              state.required_structure = false
            } else {
              state.required_structure = true
            }
          }

          SELF.setState((prev) => ({ ...prev, ...state }))
        },
      )
      .catch((err) => {
        console.log(err)
        SELF.setState({ ...SELF.state, loading: false })
        SELF.showMessage(_.get(err, 'response.headers.x-message', t('commmon:Erro ao carregar os dados, contate o suporte')), 'error')
        SELF.props.history.push('/manager/advanced-settings')
      })
  }

  getParamsAxios() {
    const { pathServer } = this.props

    return {
      method: this.IS_CREATE ? "post" : "put",
      url: this.IS_CREATE
        ? `${pathServer}/${this.state.entity}`
        : `${pathServer}/${this.state.entity}/${this.state.id}`,
    }
  }

  validateForm(parameters) {
    const { t } = this.props
    let formValid = true
    let formErrors = {}

    if (_.get(parameters, "title.pt", '').length === 0 && this.IS_CREATE) {
      formErrors.name_pt = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (_.get(parameters, "title.en", '').length === 0 && this.IS_CREATE) {
      formErrors.name_en = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (_.get(parameters, "title.es", '').length === 0 && this.IS_CREATE) {
      formErrors.name_es = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (this.state.required_structure) {
      if (_.get(parameters, "structure", []).length === 0) {
        formErrors.structure = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      }
    }

    if (_.get(parameters, "asset_regex", false) || _.get(parameters, "asset_regex_dictionary", false)) {
      if (_.get(parameters, "asset_regex", '').trim().length === 0) {
        formErrors.asset_regex = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      }    

      if (_.get(parameters, "asset_regex_dictionary", '').trim().length === 0) {
        formErrors.asset_regex_dictionary = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      }
    }
    this.setState({ ...this.state, formErrors, formValid })
    return formValid
  }

  showMessage = (message, type) => {
    const params = type === "success"
      ? { ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS }
      : {
        variant: type,
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }

    this.props.enqueueSnackbar(message, params)
  }

  submitForm(e) {
    e.preventDefault()
    const SELF = this
    const { t, requestHeaders } = SELF.props
    const parameters = SELF.getParameters()

    let formValid = SELF.validateForm(parameters)

    if (!formValid) {
      return
    }
    
    const paramsAxios = this.getParamsAxios()

    SELF.setState({ ...SELF.state, loading: true })

    axios({
      method: paramsAxios.method,
      url: paramsAxios.url,
      data: parameters,
      headers: { ...requestHeaders.headers },
    })
      .then(
        function (resp) {
          SELF.showMessage(resp.headers["x-message"], 'success')
          SELF.props.history.push(`/manager/${SELF.state.route}`)
        },
        (err) => {
          if (err.response.status === 422) {
            const message = err.response
              ? t(err.response.data.slug) || err.response.data.message || err.response.headers["x-message"]
              : t("Erro inesperado. Contate o suporte")
            SELF.showMessage(message, 'error')
            SELF.setState({
              ...SELF.state,
              loading: false,
              dialog: {
                open: true,
                data: err.response.data
              }
            })
          } else {
            SELF.responseError(err)
          }
        }
      )
      .catch(function (error) {
        SELF.setState({ ...SELF.state, loading: false })
        SELF.showMessage(t("common:Erro ao processar, contate o suporte"), 'error')
      })
  }


  insert(evt) {
    evt.preventDefault()
    if (this.IS_CREATE) {
      this.submitForm(evt)
    } else {
      this.setState((prev) => ({
        ...prev,
        dialogs: {
          ...prev.dialogs,
          updateDialogOpen: true,
        },
      }))
    }
  }

  handleInsert = async (evt) => {
    evt.persist()
    await this.handleClose("updateDialogOpen")
    await this.submitForm(evt)
  }

  handleChange(evt, field) {
    const valueField = _.get(evt, 'target.value', evt)
    let value = { [field]: valueField }
    
    if (field === "asset_regex") {
      if ((typeof valueField === "string") && getAssetnameRegex(valueField)) {
        value.required_structure = false
      } else {
        value.required_structure = true
      }
    }
    this.setState((prev) => ({ ...prev, ...value }))
  }

  handleChangeDictionary(value) {
    if (!value) {
      value = []
    }

    this.setState((prev) => ({
      ...prev,
      dictionary: value
    }))
  }

  handleChangeName(evt, field) {
    const value = evt.target.value

    this.setState((prev) => ({
      ...prev,
      name: {
        ...prev.name,
        [field]: value
      }
    }))
  }

  handleKeyDown = (event) => {
    let inputValue = this.state.inputValue;
    let value = this.state.value;
    if (!inputValue) return;

    const createOption = (label) => ({
      label: label,
      value: label,
    })

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        let newValue = Array.isArray(value) ? [...value] : [];

        if (inputValue.includes(',') || inputValue.includes(';')) {
          let separatorList = ','

          if (inputValue.includes(';')) {
            separatorList = ';'
          }

          inputValue.split(separatorList).forEach((i) => {
            if (Boolean(i)) {
              newValue.push(createOption(i.toLowerCase().trim()))
            }
          })
        } else {
          newValue = Array.isArray(value) ? [...value, createOption(inputValue)] : [createOption(inputValue)]
        }

        this.setState(prev => ({
          ...prev,
          inputValue: '',
          dictionary: [...prev.dictionary, ...newValue],
        }));
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  getParameters() {
    const SELF = this
    let { name, structure, asset_regex, dictionary, separator } = SELF.state

    const dataStructure = structure.map(i => ({
      column: i.column, entity: i.entity, label: i.label
    }))

    let params = {
      structure: dataStructure,
      separator
    }

    if (this.IS_CREATE) {
      params['title'] = name
    }

    if (Boolean(asset_regex)) {
      params['asset_regex'] = asset_regex
    }

    if (dictionary.length > 0) {
      const data_dictionary = dictionary.map(i => i.value).join(";")
      params['asset_regex_dictionary'] = data_dictionary
    }

    return params
  }


  handleClose = (dialog) => {
    this.setState((prev) => ({
      ...prev,
      dialogs: {
        ...prev.dialogs,
        [dialog]: false,
      },
    }))
  }

  delete = () => {
    const SELF = this
    const { pathServer, requestHeaders } = SELF.props
    SELF.setState({ ...SELF.state, loading: true })

    axios
      .delete(
        `${pathServer}/${SELF.state.entity}/${SELF.state.id}`,
        requestHeaders
      )
      .then(
        function (resp) {
          SELF.setState(
            {
              ...SELF.state,
              loading: false,
            },
            () => SELF.props.history.push(`/manager/${SELF.state.route}`)
          )
          SELF.showMessage(resp.headers["x-message"], 'success')
        },
        (resp) => {
          SELF.setState({ ...SELF.state, loading: false })
          SELF.showMessage(resp.headers["x-message"], 'error')
        }
      )
      .catch((err) => {
        SELF.showMessage(SELF.props.t("Erro ao deletar os dados, contate o administrador."), 'error')
      })
  }

  handleAddAttribute(data) {
    let newValue = { ...data }

    if (data.value.indexOf('.entity') !== -1) {
      const index = data.value.indexOf('.entity')
      newValue['column'] = 'name'
      newValue['entity'] = data.value.slice(0, index)
    } else {
      newValue['column'] = data.value
      newValue['entity'] = 'attribute'
    }

    const newStructure = [...this.state.structure, newValue]

    if (newStructure.length < 11) {
      this.setState((prev) => ({
        ...prev,
        structure: newStructure
      }))
    } else {
      this.showMessage(this.props.t('Limite de 10 metadados foi atingido.'), 'warning')
    }
  }

  handleUpdateTaxonomy = (data) => {
    this.setState(prev => ({ ...prev, structure: data }))
  }

  getDisabledSubmit() {
    const { loading, name } = this.state

    const name_pt = _.get(name, 'pt', '').length > 0
    const name_en = _.get(name, 'en', '').length > 0
    const name_es = _.get(name, 'es', '').length > 0

    if (loading) {
      return loading
    }

    if (!(name_pt && name_en && name_es)) {
      return true
    }
  }

  getForm() {
    const SELF = this
    const { t } = SELF.props
    const { dialogs } = SELF.state

    const DISABLED_ATTIBUTES = this.state.structure.map(i => i.value)

    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginBottom: 20, display: "flex", justifyContent: "space-between" }}>
          <Typography variant='h5'>
            {this.IS_CREATE ? t("common:Criar") : t("common:Editar")}
          </Typography>

          <Typography variant='body2'>
            {`*${t('common:Campo obrigatório')}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body2' style={{ marginBottom: 20 }}>
            {t("manager:Crie um nome alternativo para a taxonomia de seus itens para que sejam exibidos como opção nos downloads e compartilhamento. Após a criação, vincule na categoria desejada. Dúvidas entre em contato com suporte@yapoli.com")}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={this.props.region} src={this.props.region_thumbnail} />
            <Typography variant='body2' style={{ marginLeft: 15 }}>
              {t("manager:Será aplicado somente em itens da mesma região.")}
            </Typography>
          </div>
        </Grid>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant='body2' style={{ marginBottom: 12 }}>
              {t("manager:Defina qual será o nome que representará esse nome alternativo ao baixar o item")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="name-pt"
              variant="outlined"
              fullWidth
              id="name-pt"
              label={`${t("manager:Nome em português")} *`}
              value={_.get(this.state, 'name.pt', '')}
              onChange={evt => this.handleChangeName(evt, 'pt')}
              disabled={this.state.loading || !this.IS_CREATE}
              error={Boolean(this.state.formErrors.name_pt)}
              helperText={this.state.formErrors.name_pt ? this.state.formErrors.name_pt : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name-pt"
              variant="outlined"
              fullWidth
              id="name-pt"
              label={`${t("manager:Nome em inglês")} *`}
              value={_.get(this.state, 'name.en', '')}
              onChange={evt => this.handleChangeName(evt, 'en')}
              disabled={this.state.loading || !this.IS_CREATE}
              error={Boolean(this.state.formErrors.name_en)}
              helperText={this.state.formErrors.name_en ? this.state.formErrors.name_en : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="name-pt"
              variant="outlined"
              fullWidth
              id="name-pt"
              label={`${t("manager:Nome em espanhol")} *`}
              value={_.get(this.state, 'name.es', '')}
              onChange={evt => this.handleChangeName(evt, 'es')}
              disabled={this.state.loading || !this.IS_CREATE}
              error={Boolean(this.state.formErrors.name_es)}
              helperText={this.state.formErrors.name_es ? this.state.formErrors.name_es : ''}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body2' style={{ margin: '20px 0' }}>
            {t('manager:Busque quais metadados serão adicionados ao nome alternativo')}
          </Typography>

          <AttributeComboBox
            dataDefault={[
              { name: t("common:Região"), id: "region.entity" },
              { name: t("common:Divisão"), id: "division.entity" },
              { name: t("common:Segmento"), id: "segment.entity" },
              { name: t("common:Categoria"), id: "category.entity" },
              { name: t("common:Item"), id: "item.entity" }
            ]}
            disabledItems={DISABLED_ATTIBUTES}
            handleChange={(selectedItem) => this.handleAddAttribute(selectedItem)}
            error={this.state.formErrors.structure}
            field_required={this.state.required_structure}
          />
        </Grid>

        {
          _.get(this.state, 'structure', []).length > 0 &&
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <TableBoxTaxonomy
              data={this.state.structure}
              handleSetState={this.handleUpdateTaxonomy.bind(this)}
            />
          </Grid>
        }

        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Divider style={{ marginBottom: 20 }} />
            <Typography style={{ marginBottom: 4 }}>
              {t('manager:Defina qual será o separador de palavras nas nomenclaturas para download e para a distribuição.')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined">
              <InputLabel id="separator">{t("manager:Separador de palavras")}</InputLabel>
              <Select
                labelId="separator"
                id="separator"
                value={this.state.separator}
                onChange={evt => this.handleChange(evt, 'separator')}
                label={t("manager:Separador de palavras")}
                disabled={this.state.loading}
              >
                {
                  Settings.ALTERNATIVE_NAME_SEPARATOR.map(i =>
                    <MenuItem value={i.value} key={i.value}>{t(`manager:${i.label}`)}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ margin: '4px 0 20px 0' }} />
            <Typography style={{ marginBottom: 4 }}>
              {t('manager:Defina como aparecerá o nome do ativo ao baixar e/ou nas integrações do item.')}
              <Link href={
                i18n.language === 'pt'
                  ? Settings.DATA_LINKS_INFO_REGEX.pt
                  : Settings.DATA_LINKS_INFO_REGEX.en
              } target="_blank"> {t('common:Saiba mais  ')}</Link>
              {t('manager:sobre expressão regular.')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="asset_regex"
              variant="outlined"
              fullWidth
              id="asset_regex"
              label={t("manager:Expressão de busca")}
              value={_.get(this.state, 'asset_regex', '')}
              onChange={evt => this.handleChange(evt, 'asset_regex')}
              disabled={this.state.loading}
              error={Boolean(this.state.formErrors.asset_regex)}
              helperText={this.state.formErrors.asset_regex ? this.state.formErrors.asset_regex : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <CreatableUIMult
              title={t("manager:Dicionário de substituição")}
              description={t("manager:Para adicionar aperte ENTER ou TAB. Para colar de uma lista de palavras, utilize vírgula (;) ou ponto e vírgula (;) para separar.")}
              values={this.state.dictionary}
              handleChangeCreatable={this.handleChangeDictionary.bind(this)}
              error={this.state.formErrors.asset_regex_dictionary}
              inputValue={this.state.inputValue}
              handleInputChangeCreatable={value => this.handleChange({ target: { value } }, 'inputValue')}
              handleKeyDown={this.handleKeyDown.bind(this)}
              isDisabled={this.state.loading}
            />
          </Grid>
        </Grid>

        {_.get(dialogs, "updateDialogOpen", false) && (
          <DialogConfirm
            handleClose={() => this.handleClose("updateDialogOpen")}
            handleConfirm={this.handleInsert.bind(this)}
            title={t("Confirmação")}
            icon
            description={t('common:A alteração feita será aplicada no nome alternativo. Deseja continuar?')}
            open={_.get(dialogs, "updateDialogOpen", false)}
          />
        )}

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginTop: 20
        }}>
          {this.props.typeForm !== "create" && (
            <DialogDeleteValidation
              entity={this.props.t(`manager:${_.get(this.state, 'title.singular', '')}`)}
              name={_.get(this.state, `name.${i18n.language}`, '')}
            >
              {(confirm) => (
                <Button
                  style={{
                    height: 40,
                    width: 115,
                    marginRight: 20,
                    color: "#F4511E",
                    border: "1px solid #F4511E",
                  }}
                  variant="outlined"
                  onClick={confirm(this.delete.bind(this))}
                  disabled={this.state.loading}
                >
                  {this.props.t("common:Excluir")}
                </Button>
              )}
            </DialogDeleteValidation>
          )}
          <Button
            style={{ height: 40, width: 115, marginRight: 20 }}
            variant="outlined"
            color="primary"
            onClick={() =>
              this.props.history.push(`/manager/${this.state.route}`)
            }
          >
            {t("common:Voltar")}
          </Button>
          <Button
            style={{ height: 40, width: 115 }}
            variant="contained"
            color="primary"
            onClick={this.insert.bind(this)}
            disabled={this.getDisabledSubmit()}
          >
            {t("common:Salvar")}
          </Button>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return this.getForm()
  }
}

export const mapStateToProps = ({ appReducer }) => {
  return {
    region_id: appReducer.userData.region_id,
    region: appReducer.userData.region,
    region_name: appReducer.userData.region_name,
    region_thumbnail: appReducer.userData.region_thumbnail,
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
  }
}

export default compose(
  connect(mapStateToProps)
)(withTranslation(["manager", "common"])(withSnackbar(Form)))
