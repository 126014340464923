import React, { useCallback } from 'react'
import _ from 'lodash'
import axios from "axios"
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import {
    Grid,
    FormHelperText
} from "@material-ui/core"

import SegmentComboBox from "components/ComboBox/SegmentComboBox"
import CategoryComboBox from 'components/ComboBox/CategoryComboBox'
import { createEditForm } from 'components/CategoryAttributes'
import PaperCustom from '../PaperCustom'
import Utils from 'utils/Utils'
import Settings from 'utils/Settings'

import { useDataFormItem } from "../context"

const SegmentsCategory = () => {
    const { segments: state, changeSegment, changePublishField, isEdit } = useDataFormItem()
    const { requestHeaders, pathServer } = useSelector(state => state.appReducer)
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const getAttributesCategory = useCallback(async (categoryId) => {
        try {
            const categoryResult = await axios.get(`${pathServer}/attributesByCategory/${categoryId}`, requestHeaders)
            const dataAttributes = _.get(categoryResult, 'data.items', []).map((dataAttribute) => {
                dataAttribute.mask_value = dataAttribute.mask_value ? dataAttribute.mask_value.trim() : '';
                return dataAttribute
            })
            const dataFields = createEditForm(_.get(state, 'initalAttribute', []), _.get(categoryResult, 'data.items', []))

            return { attributes: dataAttributes, dataFields }
        } catch (err) {
            const message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao buscar os attributes da categoria, tente novamente.`))
            enqueueSnackbar(message, {
                variant: "error",
                autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            })
        }
    }, [enqueueSnackbar, pathServer, requestHeaders, t, state])

    const changeCategory = useCallback(async (selectedCategory) => {
        const { attributes, dataFields } = await getAttributesCategory(_.get(selectedCategory, 'value', ''))
        changeSegment({
            categoryData: selectedCategory,
            attributes,
            dataFields,
            canLink: _.get(selectedCategory, 'canLink', false),
            isGallery: _.get(selectedCategory, 'isGalleryType', false)
        })
        changePublishField('category', _.get(selectedCategory, 'canPublish', false))
    }, [changePublishField, getAttributesCategory, changeSegment])

    const categoryOnLoad = useCallback(async (selectedCategory) => {
        const { attributes, dataFields } = await getAttributesCategory(_.get(selectedCategory, 'value', ''))
        changeSegment({ categoryData: selectedCategory, attributes, dataFields })
    }, [getAttributesCategory, changeSegment])

    return (
        <Grid item xs={12} id="segmentsCategory">
            <PaperCustom title='Segmento e Categoria'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <SegmentComboBox
                            optionSelected={_.get(state, 'segmentIem.value', _.get(state, 'segmentIem.id', ''))}
                            handleChange={(selectedItem) => {
                                changeSegment({ segmentIem: selectedItem })
                                changePublishField('segment', _.get(selectedItem, 'canPublish', false))
                            }}
                            error={_.get(state, 'errors.segment', false)}
                            title={`${t("manager:Divisão")} / ${t("manager:Segmento")}`}
                            field_required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CategoryComboBox
                            optionSelected={_.get(state, 'categoryData.label', _.get(state, 'categoryData.name', ''))}
                            handleChange={(selectedItem) => changeCategory(selectedItem)}
                            onLoadDataCallback={(selectedItem) => categoryOnLoad(selectedItem)}
                            isDisabled={isEdit && _.get(state, 'isGalleryInitial', false)}
                            error={_.get(state, 'errors.category', false)}
                            field_required
                        />
                        {
                            _.get(state, 'isGallery', false) && !_.get(state, 'isGalleryInitial', false) &&
                            <FormHelperText>
                                {t("manager:Essa Categoria não poderá ser alterada após sua alteração.")}
                            </FormHelperText>
                        }
                        {
                            isEdit && _.get(state, 'isGalleryInitial', false) &&
                            <FormHelperText>
                                {t("manager:Essa Categoria não pode ser alterada por ser do tipo galeria de itens.")}
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
            </PaperCustom>
        </Grid>
    );
}

export default React.memo(SegmentsCategory)
