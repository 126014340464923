import React from "react"
import { useHistory } from "react-router-dom"
import {
  Breadcrumbs as BreadcrumbsUI,
  Avatar,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import CloseIcon from "@material-ui/icons/Close"
import { useDataFormItem } from "../context"
import { useStyles } from "./styles"

const HeaderBreadcrumbs = ({
  handleClose
}) => {
  const { info, isEdit } = useDataFormItem()
  const history = useHistory()
  const classes = useStyles()

  return (
    <Paper elevation={2} className={classes.root}>
      <Avatar
        alt="Logo Cliente"
        src={localStorage.getItem("logoInsideJPG")}
        onClick={() => history.push("/")}
        style={{
          cursor: "pointer",
          marginRight: 20,
          width: 60,
          height: 60,
        }}
      />
      <div className="content-label">
        <div className="divTaxonomy">
          <Typography
            variant="h5"
            style={{ lineHeight: 1.2, color: "#ffffff", fontWeight: 300 }}
          >
            {isEdit ? "Editar informações do item" : "Adicionar novo item"}
          </Typography>
        </div>
        <BreadcrumbsUI
          separator={<NavigateNextIcon id="dot" fontSize="small" />}
          aria-label="breadcrumb"
          className="breadcrumb"
        >
          <span
            style={{ fontWeight: 500, color: '#fff' }}
            data-testid="not-link"
          >
            {info.name}
          </span>
        </BreadcrumbsUI>
      </div>
      <Tooltip title="Voltar" placement="top">
        <div className={classes.divIconClose}>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Tooltip>
    </Paper>
  )
}

export default HeaderBreadcrumbs
