import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'relative',
    width: 'calc(100% - 57px)',
    marginLeft: 57,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginLeft: 0,
      width: '100%'
    },
  },
  rootWrapper: {
    display: "flex",
    width: "100%",
    maxWidth: 900,
    justifyContent: "space-around",
    alignItems: "center",
  },
  content: {
    width: "100%",
    maxWidth: 603,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "calc(100vh - 170px)",
    maxHeight: 745,
  },
  contentMobile: {
    width: "100vw",
    height: "100%",
    maxWidth: "initial",
    maxHeight: "initial",
    margin: 0,
    top: 0,
    transform: "none",
    justifyContent: "flex-start",
    "& > $carouselWrapper": {
      borderRadius: 0,
    },
  },
  arrow: {
    width: 48,
    height: 48,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  arrowIcon: {
    color: grey[700],
  },
  carouselWrapper: {
    height: "100%",
    maxHeight: 600,
    overflow: "hidden",
    borderRadius: 4,
    transform: "scale(1.0)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 270px)",
    },
  },
  footer: {
    width: "100%",
    position: "relative",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: 22,
  },
  footerMobile: {
    margin: "10px 0",
  },
  slide: {
    width: "100%",
    height: "100%",
  },
  slideMobile: {
    width: "100%",
    height: "100%",
  },
  carousel: {
    height: "100%",
    maxHeight: 600,
  },
  dragDisplay: {
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    flexWrap: "wrap",
    flexDirection: "column"
  },
  cloudUploadIcon: {
    fontSize: "5em",
    color: grey[700],
  }
}))
