import React from "react"
import axios from "axios/index"
import { withSnackbar } from 'notistack';
import Dropzone from "react-dropzone"
import Template from "../Template"
import imgUpload from "../../../imagens/ui/ico-upload.svg"
import connect from "react-redux/es/connect/connect"
import { withTranslation } from "react-i18next"
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,   
  Divider
} from "@material-ui/core";
import { compose } from "recompose"
import { Content } from '../Clients/partials/Content'
import UploadDialog from '../Core/UploadDialog'
import ImageLazyLoad from "components/ImageLazyLoad"

import { styles } from '../Core/UploadLanguage/styles'

import _ from "lodash"

import Settings from 'utils/Settings'
class Upload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      region_slug: "",
      picturesPreview: "",
      loading: true,
      data: {},
    }
    this.handleRemovePicture = this.handleRemovePicture.bind(this)
    this.onDropRejected = this.onDropRejected.bind(this)
  }

  componentDidMount() {
    const ID = this.props.match.params.id
    this.setState({
      ...this.state,
      slug: ID,
    })
    if (ID) this.getData(ID)
  }

  getData(_id) {
    const SELF = this
    const { t, pathServer, requestHeaders, enqueueSnackbar } = SELF.props

    axios
      .get(`${pathServer}/tool/${_id}`, requestHeaders)
      .then(
        function (resp) {
          const DATA = resp.data.item || []

          if (_.get(DATA, "thumbnail", false)) {
            SELF.setState({
              ...SELF.state,
              loading: false,
              name: DATA.title,
              id: DATA.id,
              picturesPreview: DATA.thumbnail,
            })
          } else {
            SELF.setState({
              ...SELF.state,
              loading: false,
              name: DATA.title,
              id: DATA.id,
            })
          }
        },
        function (resp) {
          SELF.setState({
            ...SELF.state,
            loading: false,
          })
        
          enqueueSnackbar(resp.headers["x-message"], {
            variant: 'error',
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          })
        }
      )
      .catch(function (err) {
        console.log(err)
        const message = err.response
              ? err.response.data.message || err.response.headers["x-message"]
              : t("Erro ao carregar os dados, contate o suporte");
     
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
  }

  onDropUpload(files) {
    const SELF = this
    const { pathServer, requestHeaders, enqueueSnackbar, t } = SELF.props
    const uploaders = files.map((file) => {
      const formData = new FormData()
      formData.append("thumbnail", file)
      formData.append("id", SELF.state.slug)

      if(SELF.props.workflow) {
        formData.append("type", "WORKFLOW")
      }
      
      SELF.setState({
        ...SELF.state,
        loading: true,
      })

      return axios
        .post(`${pathServer}/tool/import/${SELF.state.id}`, formData, {
          ...requestHeaders,
          onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            console.log("percentCompleted", percentCompleted)
          },
        })
        .then(
          (resp) => {
            SELF.setState({
              ...SELF.state,
              loading: true,
              picturesPreview:
                resp.data.thumbnail +
                "?=timestamp=" +
                Math.floor(Date.now() / 1000),
            })
            
            enqueueSnackbar(t('Imagem enviada com sucesso'), {
              ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
            });
          },
          (err) => {
            const message = err.response
              ? err.response.data.message || err.response.headers["x-message"]
              : t("Erro inesperado. Contate o suporte");
            enqueueSnackbar(message, {
              variant: 'error',
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })
          }          
        )
    })

    axios.all(uploaders).then(() => {
      this.setState({
        ...this.state,
        loading: false,
      })
    })
  }

  onDropRejected() {
    const { t , enqueueSnackbar } = this.props
 
    enqueueSnackbar(t("Arquivo inválido. Arquivo até 2MB em SVG"), {
      variant: 'error',
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    })
  }

  handleRemovePicture(event) {
    event.preventDefault()
    const SELF = this
    const { pathServer, requestHeaders, enqueueSnackbar, t } = SELF.props
    this.setState({
      ...this.state,
      loading: true,
    })
    axios
      .post(
        `${pathServer}/tool/remove/${this.state.id}/thumbnail`,
        {},
        requestHeaders
      )
      .then(
        function (resp) {
          SELF.setState({
            ...SELF.state,
            loading: false,
            picturesPreview: "",
          })
        
          enqueueSnackbar(t('Miniatura deletada com sucesso.'), {
            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
          });
        },
        function (resp) {
          SELF.setState({
            ...SELF.state,
            loading: false,
          })
      
          enqueueSnackbar(resp.headers["x-message"], {
            variant: 'error',
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          })
        }
      )
      .catch(function (err) {
        console.log(err)
        
        enqueueSnackbar(t("Erro ao deletar, contate o administrador."), {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
  }

  render() {
    const { t, classes } = this.props

    return (
      <Template
        area={8}
        localHistory={this.props.history}
        customClassName={"container-center"}
      >
       <Content title={"manager:Adicionar miniatura"}>
                        <Divider style={{ marginBottom: 20 }} />
                        <Typography variant='body2' style={{ marginBottom: 0 }}>
                            {t("manager:Selecione a miniatura correspondente a ferramenta desejada.")}
                        </Typography>
                        <Typography variant='body2' style={{ marginBottom: 20 }}>
                            {`${t('common:Especificações')}: SVG; 60px x 60px; ${t('common:tamanho máximo')} 2MB.`}
                        </Typography>
                                          
                                <Dropzone
                                    onDrop={this.onDropUpload.bind(this)}
                                    accept="image/svg+xml"
                                    maxSize={2000000}
                                    onDropRejected={this.onDropRejected}
                                    multiple={false}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className={classes.rootDropzone}>
                                            <div className='content'>
                                                <input {...getInputProps()} />
                                                {this.state.loading ? (
                                                    <div>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <>                                                    
                                                        {
                                                            this.state.picturesPreview ? (
                                                                <div className='image'>
                                                                    <ImageLazyLoad
                                                                        images={{
                                                                          imgJPG: this.state.picturesPreview, 
                                                                          imgWEBP: this.state.picturesPreview
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <img
                                                                        src={imgUpload}
                                                                        width={50}
                                                                        alt={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                                    />
                                                                    <br />
                                                                    <Typography variant="body2">
                                                                        {t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                                    </Typography>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                    )}
                                </Dropzone>

                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    margin: '20px 0',
                                }}>
                                    {
                                        this.state.picturesPreview &&
                                        <>
                                            <Button
                                                style={{ height: 40, width: 115, marginRight: 20 }}
                                                variant="outlined"
                                                color="primary"
                                                disabled={!this.state.picturesPreview}
                                                onClick={this.handleRemovePicture}
                                            >
                                                {t("common:Excluir")}
                                            </Button>
                                            <UploadDialog
                                                t={t}
                                                title={t("manager:Adicionar miniatura")}
                                                descriptionUpload={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                onDropUpload={this.onDropUpload}
                                                onDropRejected={this.onDropRejected}
                                                loading={this.state.loading}
                                                acceptFiles="image/jpeg, image/png"
                                            >
                                                {(confirm) => (
                                                    <Button
                                                        variant={"contained"}
                                                        color="primary"
                                                        style={{ height: 40 }}
                                                        onClick={confirm()}
                                                    >
                                                        {t("manager:Adicionar miniatura")}
                                                    </Button>
                                                )}
                                            </UploadDialog>
                                        </>
                                    }
                                </Grid>
                            


                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ height: 40, width: 115 }}
                                variant="contained"
                                color="primary"
                                onClick={() => this.props.history.goBack()}>
                                {t("common:Voltar")}
                            </Button>
                        </div>

                    </Content>
      </Template>
    )
  }
}

export const mapStateToProps = ({ appReducer }) => {
  return {
    pathServer: appReducer.pathServer,
    requestHeaders: appReducer.requestHeaders,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)  
)(withTranslation(["manager", "common"])(withSnackbar(Upload)))
