import * as ActionTypes from "../actionTypes";
import _ from 'lodash'
const actions = {};

actions.addFileReprocess = file => {
  return dispatch => new Promise(resolve => {
    dispatch({
      type: ActionTypes.ADD_FILE_REPROCESS,
      file
    })
    resolve()
  })
}

actions.updateFileReprocess = file => {
  return {
    type: ActionTypes.UPDATE_FILE_REPROCESS,
    file
  }
}

actions.removeFileReprocess = file => {
  return {
    type: ActionTypes.REMOVE_FILE_REPROCESS,
    file
  }
}

actions.addUploadFiles = (files) => {
  return {
    type: ActionTypes.ADD_UPLOAD_FILES,
    files,
  };
};

actions.uploadFileFinished = (file, awsKey) => {
  return {
    type: ActionTypes.UPLOAD_FILE_FINISHED,
    data: { file, awsKey },
  };
};

actions.uploadFileProcessed = (notification) => {
  return {
    type: ActionTypes.UPLOAD_FILE_PROCESSED,
    data: notification,
  };
};

actions.uploadFileError = (file, message) => {
  return {
    type: ActionTypes.UPLOAD_FILE_ERROR,
    data: { file: file, error: message },
  };
};

actions.uploadClearFiles = () => {
  return {
    type: ActionTypes.CLEAR_UPLOAD_FILES,
  };
};

actions.uploadCancelFile = item => (dispatch, getState) => new Promise((resolve, reject) => {
  const { uploadReducer } = getState();
  const { evaporate } = uploadReducer;

  evaporate[item.entity].cancel(`${item.bucket_name}/${item.path_file}`).then(
    () => {
      dispatch({
        type: ActionTypes.UPLOAD_FILE_REMOVE,
        item,
      });

      resolve(item);
    },
    error => reject(error)
  ).catch(error => reject(error))
});

actions.uploadCancelAllFiles = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { uploadReducer } = getState();
    const { evaporate, uploadFiles, postCancelData } = uploadReducer;

    Promise.allSettled(uploadFiles.map((i) => evaporate[_.get(i, 'entity', 'asset')].cancel()))
      .then(() => {
        resolve(postCancelData)
        dispatch({ type: ActionTypes.UPLOAD_FILES_CANCELED_ALL })
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      })
  });

actions.uploadFileRemove = (item) => {
  return {
    type: ActionTypes.UPLOAD_FILE_REMOVE,
    item,
  };
};

actions.showConfirmDialog = (postCancelData, messageKey) => {
  return {
    type: ActionTypes.SHOW_UPLOAD_CANCEL_DIALOG,
    messageKey,
    postCancelData
  }
}

actions.cancelRegionChange = () => {
  return {
    type: ActionTypes.HIDE_UPLOAD_CANCEL_DIALOG
  }

}

actions.setEvaporate = (evaporate, entity) => {
  return {
    type: ActionTypes.SET_EVAPORATE_REDUCER,
    payload: {evaporate, entity}
  }

}

actions.pauseAll = () => {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { uploadReducer } = getState();
    const { evaporate, uploadFiles } = uploadReducer;

    Promise.allSettled(uploadFiles.map((i) => evaporate[_.get(i, 'entity', 'asset')].pause()))
      .then((result) => {
        resolve(result);
        dispatch({
          type: ActionTypes.UPLOAD_ALL_FILES_PAUSED,
          pause: true
        })
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      })
  });
}

actions.resumeAll = () => {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { uploadReducer } = getState();
    const { evaporate } = uploadReducer;
      evaporate.resume().then((response) => {
        dispatch({
          type: ActionTypes.UPLOAD_ALL_FILES_PAUSED,
          pause: false
        })
        resolve(response);
     },(error) => reject(error))
  });
}

actions.pause = (asset) => {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { uploadReducer } = getState();
    const { evaporate } = uploadReducer;
      evaporate.pause(`${evaporate.config.bucket}/${asset.path_file}`)
      .then((response) => {
        dispatch({
          type: ActionTypes.UPLOAD_PAUSED,
          id: asset.id,
          pause: true
        })
        resolve(response);
     },(error) => reject(error))
  });
}

actions.resume = (asset) => {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { uploadReducer } = getState();
    const { evaporate } = uploadReducer;
      evaporate.resume(`${evaporate.config.bucket}/${asset.path_file}`)
      .then((response) => {
        dispatch({
          type: ActionTypes.UPLOAD_PAUSED,
          id: asset.id,
          pause: false
        })
        resolve(response);
     },(error) => reject(error))
  });
}

actions.setDataProgressfile = data => {
  return {
    type: ActionTypes.SET_DATA_PROGRESS_FILE,
    data
  }
}

actions.addItemToContext = item => {
  return {
    type: ActionTypes.SET_ITEM_CONTEXT,
    item
  }
}

actions.removeItemFromContext = () => {
  return {
    type: ActionTypes.CLEAR_ITEM_CONTEXT
  }
}

export default actions;
