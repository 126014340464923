import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Box, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import CollectionsRoundedIcon from "@material-ui/icons/CollectionsRounded"

import YapoliImage from "components/Filters/YapoliImage"
import _ from "lodash"
import PaperTitle from "../PaperTitle"
import { useDataAssetIntegra } from "../context"
import { useStyles } from "./styles"
import EditFilesDialog from './EditFilesDialog'
import AppActions from "flux/actions/AppActions"

const ItemFiles = ({ data = [], subTitle, slugItem,  taxonomyName, itemID, isDraft, isManager, allowManageAssets, isGallery }) => {
  const { supportsWebp } = useSelector((state) => state.uploadReducer)
  const { policies } = useSelector((state) => state.appReducer.userData)
  const type = supportsWebp ? "webp" : "original"
  const { t } = useTranslation()
  const classes = useStyles()
  const { slideIndex, setSlideIndex, setOpenDrawerAssets } = useDataAssetIntegra()
  const [partData, setPartData] = useState([])
  const [scrollInfinite, setScrollInfinite] = useState(false)
  const refList = useRef(null)

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch()

  const handleClickOpen = () => {
    setOpenDrawerAssets(false)
    setOpen(true,  dispatch(AppActions.clearMarkedAssets()));
  };

  const handleClose = () => {
    setOpen(false,  dispatch(AppActions.clearMarkedAssets()));
  };


  useEffect(() => {
    setPartData(data.slice(0, 10))

    refList.current.addEventListener("scroll", function () {
      if (
        refList.current.scrollTop + refList.current.clientHeight >=
        refList.current.scrollHeight
      ) {
        setScrollInfinite(true)
      }
    })
  }, [data])

  useEffect(() => {
    if (scrollInfinite) {
      const newData = data.slice(partData.length, partData.length + 10)
      setPartData((prev) => [...prev, ...newData])
      setScrollInfinite(false)
    }

    if (partData.length === data.length) {
      refList.current.removeEventListener("scroll", function () {
        if (
          refList.current.scrollTop + refList.current.clientHeight >=
          refList.current.scrollHeight
        ) {
          setScrollInfinite(true)
        }
      })
    }
  }, [scrollInfinite, data, partData])

  return (
    <>
      <PaperTitle
        title={t("DAM:Ativos do item")}
        subTitle={
          data.length === 1
            ? `${t("common:ativo encontrado para a ", {
                qtd: data.length,
              })} ${subTitle.toLowerCase()}`
            : `${t("common:ativos encontrados para a ", {
                qtd: data.length,
              })} ${subTitle.toLowerCase()}`
        }
        icon={CollectionsRoundedIcon}
      />
      <div style={{ padding: "30px" }}>
        {
          allowManageAssets && (
            <Button
              style={{ height: "40px", width: "100%" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              disabled={!isManager}
            >
              {t("DAM:Gerenciar ativos")}
            </Button>
          )
        }
      </div>
      {partData && (
        <div className={classes.listWrapper} ref={refList}>
          {partData.map((value, index) => {
            return (
              <div
                className={classes.row}
                key={`${_.get(partData, "id", "")}-${index}`}
              >
                <div className={classes.info}>
                  <Typography
                    component="div"
                    variant="body2"
                    style={{ lineHeight: 1.2 }}
                  >
                    <Box fontWeight={500}>{`#${index + 1}`}</Box>
                  </Typography>
                </div>
                <div
                  className={classes.image}
                  id={slideIndex === index ? "selected" : ""}
                  onClick={() => setSlideIndex(index)}
                >
                  <YapoliImage
                    extension={_.get(value, "extension", "")}
                    asset={_.get(value, "id", "")}
                    file={value[`thumbnail_card_${type}`]}
                    type={`thumbnail_card_${type}`}
                    alt={_.get(value, "client_name", "Thumbnail-Yapoli")}
                    height="154px"
                    width='154px'
                  />
                </div>
                <div />
              </div>
            )
          })}
        </div>
      )}

      {
        open &&
        <EditFilesDialog
          open={open}
          handleClose={handleClose}
          slugItem={slugItem}
          policies={policies}
          taxonomyName={taxonomyName}
          itemID={itemID}
          isDraft={isDraft}
          isGallery={isGallery}
        />
      }

    </>
  )
}

export default ItemFiles
