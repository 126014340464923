import _ from "lodash"

export const Types = {
  SET_VISUALIZATION_MODE: "items/SET_VISUALIZATION_MODE",
  SET_SESSION_STORAGE: "items/SET_SESSION_STORAGE",
  RESET_SESSION_STORAGE: "items/RESET_SESSION_STORAGE",
  SET_OPEN_DIALOG_ITEMS_MARKED: "items/SET_OPEN_DIALOG_ITEMS_MARKED",
  SET_DATA_TO_CREATE_ITEMS: "items/SET_DATA_TO_CREATE_ITEMS",
  SET_PARAMS_DATA_TO_CREATE_ITEMS: "items/SET_PARAMS_DATA_TO_CREATE_ITEMS",
  RESET_PARAMS_DATA_TO_CREATE_ITEMS: "items/RESET_PARAMS_DATA_TO_CREATE_ITEMS"
}

const options = JSON.parse(sessionStorage.getItem("itemsFilters"))

const INITIAL_STATE = {
  filtersOptions: _.get(options, "filtersOptions", []),
  checkedShortcut: false,
  search: "",
  dataLevels: {},
  sorting: _.get(options, "sorting", null),
  showPublished: _.get(options, "showPublished", "all"),
  pathname: _.get(options, "pathname", false),
  visualization_mode: localStorage.getItem("visualization_mode") || 'card-mode',
  page: _.get(options, "page", 1),
  name_dialog_open: '',
  paramsDataToCreateItems: {},
  defaultDataToCreateItems: {},
  pit: ''
}

export default function itemsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_SESSION_STORAGE: {
      let newState = {
        ...state,
        [action.payload.name]: action.payload.data,
      }

      if (action.payload.name === "filtersShortcut") {
        delete newState.filtersShortcut
        if (action.payload.data.filters) {
          newState.filtersOptions = action.payload.data.filters
        }
        if (_.get(action, 'payload.data.sorting', _.get(action, 'payload.data.order', false))) {
          newState.sorting = _.get(action, 'payload.data.sorting', _.get(action, 'payload.data.order', ""))
        }
        if (action.payload.data.showPublished) {
          if(newState.showPublished === "all") {
            newState.showPublished = action.payload.data.showPublished
          }
        }
        if (action.payload.data.search) {
          newState.search = action.payload.data.search
        }
      }

      sessionStorage.setItem("itemsFilters", JSON.stringify(newState))

      return newState
    }

    case Types.RESET_SESSION_STORAGE: {
      const resetState = {
        ...state,
        pathname: false,
        filtersOptions: [],
        dataLevels: {},
        sorting: null,
        showPublished: "all",
        page: 1,
      }

      sessionStorage.setItem("itemsFilters", JSON.stringify(resetState))

      return resetState
    }

    case Types.SET_VISUALIZATION_MODE: {
      const newState = {
        ...state,
        visualization_mode: action.payload,
      }

      sessionStorage.setItem("itemsFilters", JSON.stringify(newState))

      return newState
    }

    case Types.SET_OPEN_DIALOG_ITEMS_MARKED: {
      const newState = {
        ...state,
        name_dialog_open: action.payload.open,
      }
      return newState
    }
    case Types.SET_PARAMS_DATA_TO_CREATE_ITEMS: {
      const newState = {
        ...state,
        paramsDataToCreateItems: {
          ...state.paramsDataToCreateItems,
          ...action.payload
        },
      }
      return newState
    }

    case Types.RESET_PARAMS_DATA_TO_CREATE_ITEMS: {
      const newState = {
        ...state,
        paramsDataToCreateItems: {},
        defaultDataToCreateItems: {}
      }
      return newState
    }

    default:
      return state
  }
}

export const Creators = {
  setSession: (payload) => ({
    type: Types.SET_SESSION_STORAGE,
    payload,
  }),
  resetSession: () => ({
    type: Types.RESET_SESSION_STORAGE,
  }),
  setOpenDialogItemsMarked: (payload) => ({
    type: Types.SET_OPEN_DIALOG_ITEMS_MARKED,
    payload,
  }),
  setVisualizationMode: (payload) => {
    localStorage.setItem('visualization_mode', payload)
    return ({
      type: Types.SET_VISUALIZATION_MODE,
      payload
    })
  },
  setParamsDataToCreateItems: (payload) => ({
    type: Types.SET_PARAMS_DATA_TO_CREATE_ITEMS,
    payload
  }),
  resetParamsDataToCreateItems: () => ({
    type: Types.RESET_PARAMS_DATA_TO_CREATE_ITEMS,
  }),
}
