import React, { useEffect, useState, useCallback } from "react"

import axios from "axios"
import _ from 'lodash'
import { useSelector, useDispatch } from "react-redux"

import { formatedAttributes } from "utils/Pages"
import { Creators as ItemsActions } from "flux/ducks/Items"
import { Creators as AppBarActions } from "flux/ducks/appbar_app"

import View from './view'

import { useDataItems } from 'pages/DAM/Items/context'

const Filters = () => {
    const [state, setState] = useState({
        loadingData: true,
        isDataEmpty: false,
        dataAttributes: {},
        dataAttributesPrev: {},
        shouldGetFilters: false
    })

    const { pathServer, requestHeaders } = useSelector(
        (state) => state.appReducer
    )
    const { filtersOptions } = useSelector((state) => state.itemsReducer)
    const dispatch = useDispatch()

    const {
        totalItems,
        getRenewedData,
        getParamsToItemsWithoutMarked
    } = useDataItems()

    const handleClearOptions = useCallback(() => {
        const dataParams = getParamsToItemsWithoutMarked()
        const filterfiltersOptions = filtersOptions.filter(i => i.isInitial)
        dispatch(
            ItemsActions.setSession({
                name: "filtersOptions",
                data: filterfiltersOptions,
            }),
            ItemsActions.setSession({
                name: "page",
                data: 1,
            })
        )

        getRenewedData({ ...dataParams, filtersOptions: filterfiltersOptions })
    }, [dispatch, getParamsToItemsWithoutMarked, getRenewedData, filtersOptions])

    const handleCallbackInput = useCallback((text) => {
        if (!Boolean(text)) {
            setState(prev => ({ ...prev, isDataEmpty: false, dataAttributes: state.dataAttributesPrev }))
            return
        }

        let DATA = {
            attributes: [], structures: state.dataAttributes.structures
        }

        const TEXT_REPLACE = text.toLowerCase().replace(' ', '')

        Object.entries(state.dataAttributes).forEach(([key, item]) => {
            item.forEach(value => {
                const valueList = value.list_value.map(i => ({
                    ...i, id: value.id
                }))
                const filtered = valueList.filter(i => {
                    const valueClear = i.value.toLowerCase().replace(' ', '')
                    return valueClear.indexOf(TEXT_REPLACE) !== -1
                })
                if (key === 'attributes' && filtered.length > 0) {
                    DATA['attributes'] = [
                        ...DATA['attributes'],
                        {
                            ...value,
                            list_value: filtered
                        }
                    ]
                }
            })
        })

        if (DATA.attributes.length === 0) {
            setState(prev => ({ ...prev, isDataEmpty: true }))
            return
        }

        setState(prev => ({ ...prev, isDataEmpty: false, dataAttributes: DATA }))
    }, [state.dataAttributes, state.dataAttributesPrev])

    const getParams = useCallback(() => {
        const parameters = getParamsToItemsWithoutMarked()
        const dataParamsToItems = { ...parameters, limit: 2000 }
        delete dataParamsToItems.only
        delete dataParamsToItems.order
        return parameters
    }, [getParamsToItemsWithoutMarked])

    useEffect(() => {
        let isMounted = true
        if (isMounted && ((totalItems.total && totalItems.total > 0))) {
            setState(prev => ({ ...prev, shouldGetFilters: true }))
        }
        return () => { isMounted = false }
    }, [totalItems])

    useEffect(() => {
        let isMounted = true
        if (state.shouldGetFilters && isMounted) {
            const parameters = getParams()

            setState(prev => ({ ...prev, loadingData: true }),
                axios
                    .post(`${pathServer}/item/attribute/filters`, parameters, requestHeaders)
                    .then((resp) => {
                        const { filters } = resp.data
                        if (!_.isEmpty(filters)) {
                            let dataCategory = _.get(filters, 'structures', []).find(i => i.id === 'cat')
                            dispatch(AppBarActions.setDataCategory(_.get(dataCategory, 'list_value', [])))
                            const formated = formatedAttributes(filters)

                            setState(prev => ({
                                ...prev,
                                dataAttributes: formated,
                                dataAttributesPrev: formated,
                                loadingData: false,
                                shouldGetFilters: false
                            }))
                        } else {
                            setState(prev => ({
                                ...prev,
                                loadingData: false,
                                shouldGetFilters: false
                            }))
                        }
                    })
                    .catch((err) => {
                        setState(prev => ({ ...prev, loadingData: false, shouldGetFilters: false }))
                        console.log("Request canceled", err.message)
                    })
            )
        }
        return () => { isMounted = false }
    }, [pathServer, requestHeaders, state.shouldGetFilters, dispatch, getParams])

    const setIsDataEmpty = useCallback((value) => {
        setState(prev => ({ ...prev, isDataEmpty: value }))
    }, [])
 
    return (
        <View
            dataAttributes={state.dataAttributes}
            handleCallbackInput={handleCallbackInput}
            isDataEmpty={state.isDataEmpty}
            setIsDataEmpty={setIsDataEmpty}
            hasFilters={_.get(totalItems, "total", 0) !== 0}
            isClearOptions={Boolean(filtersOptions.length > 0)}
            total={filtersOptions.length}
            handleClearOptions={handleClearOptions}
            loading={state.loadingData}
        />
    )
}


export default Filters
