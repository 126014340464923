import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    rootMenuList: {
        [theme.breakpoints.down("sm")]: {
            padding: 0,
            overflow: 'auto',
            height: 'calc(100vh - 56px)',
        }
    },
    rootMenu: {
        zIndex: 1050,
        transform: 'none !important',
        top: '128px !important',
        position: 'fixed !important',
        "& .MuiPaper-root": {
            minWidth: 300,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
        "& .MuiCollapse-wrapper": {
            overflow: 'auto',
            maxHeight: '351px'
        },
        '& .SimpleAlertMessage': {
            width: '100%',
            maxWidth: 300,
            padding: '10px 20px'
        },
        '& .MuiAlert-root': {
            width: '100% !important',
            minWidth: 50,
            maxHeight: '100% !important',
            margin: '0 !important'
        },
        '& .rootListItem': {
            justifyContent: 'space-between',
            '& .title': {
                flexGrow: 1,
                marginLeft: 5
            }
        },
        [theme.breakpoints.down("sm")]: {
            top: '56px !important',
            zIndex: 2050,
            "& .MuiPaper-root": {
                width: '100vw',
                maxWidth: '100vw',
                borderRadius: '0 !important',
            },
            "& .MuiCollapse-wrapper": {
                maxHeight: '100vh'
            },
            "& .MuiButton-startIcon": {
                margin: 0
            }
        },
    },
    liHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        paddingTop: 10,
        '& div': {
            display: 'flex',
            alignItems: 'center',
            '& .title svg': {
                marginRight: 10
            }
        }
    }
}))