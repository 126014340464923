import React from 'react';

import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import _ from 'lodash'

import {
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core';

import { mdiCloudDownload } from "@mdi/js"

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { useDataAssetIntegra } from "../../context"

import { useStyles } from './styles'

import DownloadStepsWrapper from 'components/DownloadStepsWrapper'

export default function DetailsAsset({ dataAsset }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { handleOpenDownloadButton } = useDataAssetIntegra()
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.paperHeader}>
          <div className='header'>
            <div>
              <Icon path={mdiCloudDownload} size={1} color="#616161" />
              <Typography variant='h6'>
                {t('DAM:Download')}
              </Typography>
            </div>
            <IconButton onClick={() => handleOpenDownloadButton()}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <Typography variant='body2' className='sub_header'>
            {t('common:O ativo que não possuir suporte para as especificações será mantido na versão original.')}
          </Typography>
        </div>

        <div className={classes.paperContent}>
          <DownloadStepsWrapper
            id_asset={_.get(dataAsset, 'props.data.asset.id', '')}
            id_item={_.get(dataAsset, 'props.data.item_id', '')}
            version_item={_.get(dataAsset, 'props.data.versionIndex', 0)}
            hasHighLow={_.get(dataAsset, 'props.data.asset.hasHighLow', false)}
            dates={_.get(dataAsset, 'props.data.asset.dates', { end: '' })}
            category_id={_.get(dataAsset, 'props.data.category_id', '')}
          />
        </div>
      </Paper>
    </div>
  );
}