import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { darken } from "polished";

import SVGKeyboardReturn from 'imagens/icons/SVGKeyboardReturn.svg'

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#eee',
        color: '#666',
        '&:hover': {
            backgroundColor: darken(0.1, "#eee"),
        },
        width: 'auto',
        margin: '10px 20px',
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',       
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    rootBtnGoBack: {  
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', 
        cursor: 'pointer'
    }
}));

export default function SearchButton({
    handleCallbackInput,
    placeholder,
    value,  
    isBtnBack
}) {
    const [textInput, setTextInput] = React.useState(value)
    const classes = useStyles();

    const onChange = (evt) => {       
        setTextInput(evt.target.value)
    }

    const runTextSearch = (e) => {  
        e.stopPropagation() 
        let key = e.key

        if (key.toLowerCase() === "enter" && e.target.value.trim() !== "") {
            handleCallbackInput(textInput)
        }

        if (key.toLowerCase() === "backspace" && e.target.value.length === 1) {
            handleCallbackInput('')
        }
    }

    return (

        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}            
                onChange={(evt) => onChange(evt)}
                onKeyDown={(evt) => runTextSearch(evt)}
                value={textInput}
                endAdornment={    
                    isBtnBack &&             
                    <div className={classes.rootBtnGoBack} onClick={() => {
                        setTextInput('')
                        handleCallbackInput('')
                    }}>
                        <InputAdornment>
                            <img src={SVGKeyboardReturn}  alt="SVG Keyboard Return" />                       
                        </InputAdornment>
                    </div>
                }               
            />
        </div>

    );
}