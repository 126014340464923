import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { IconButton, Tooltip } from "@material-ui/core"
import EditRoundedIcon from "@material-ui/icons/EditRounded"
import ItemContainer from "./ItemContainer"

import { useDataAssetIntegra } from "../context"

import DialogEditItem from 'components/Dialogs/FormItem'

const EditItem = ({ isDisabled, dataEdit }) => {
    const [openDialog, setOpenDialog] = useState(false)
    const { enableNewItemFormPage } = useSelector(state => state.appReducer.userData)
    const { setOpenDrawerAssets } = useDataAssetIntegra()
    const { t } = useTranslation()

    const openDialogEdit = () => {
        const callback = () => {
            setOpenDrawerAssets(false)
        }
        setOpenDialog(true, callback())
    }

    const closeDialogEdit = () => {
        setOpenDialog(false)
    }

    return (
        <>
            <Tooltip title={t("DAM:Editar informações do item")} placement="left">
                <div>
                    <IconButton
                        color="inherit"
                        onClick={openDialogEdit}
                        disabled={isDisabled}
                    >
                        <EditRoundedIcon />
                    </IconButton>
                </div>
            </Tooltip>

            {
                (openDialog && enableNewItemFormPage) &&
                <DialogEditItem
                    open
                    isEdit
                    handleClose={closeDialogEdit}
                    slugItem={dataEdit.slugItem}
                />
            }
            {
                (openDialog && !enableNewItemFormPage) &&
                <ItemContainer
                    slugItem={dataEdit.slugItem}
                    name={dataEdit.name}
                    open
                    closeDialog={closeDialogEdit}
                />
            }
        </>

    )
}

export default EditItem
