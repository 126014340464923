import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
      boxHeight: false,
      support_text: {
        title: 'Ops!',
        first: 'Desculpe, ocorreu um erro no sistema.',
        second: 'Por favor, atualize a página do seu navegador. Caso o erro persista, entre em contato com o suporte através do e-mail suporte@yapoli.com',
        third: 'Descrição do erro'
      }
    };
    this.changeBodyStyle = this.changeBodyStyle.bind(this)
    this.handleHeight = this.handleHeight.bind(this)
    this.getUserLanguage = this.getUserLanguage.bind(this)
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    this.changeBodyStyle()
    this.getUserLanguage()
    // You can also log error messages to an error reporting service here
  }

  changeBodyStyle() {
    document.body.style.backgroundColor = "#f5f5f5"
  }

  handleHeight() {
    this.setState({
      ...this.state,
      boxHeight: !this.state.boxHeight
    })
  }

  getUserLanguage() {
    const userLanguage = navigator.language

    if (userLanguage === 'en' || userLanguage === 'en-US') {
      this.setState(prev => ({
        ...prev,
        support_text: {
          title: 'Oops!',
          first: 'Sorry, a system error occurred.',
          second: 'Please, refresh your browser. If the error persists, contact support via email help@yapoli.com',
          third: 'Error description'
        }
      }))
    }

    if (userLanguage === 'es') {
      this.setState(prev => ({
        ...prev,
        support_text: {
          title: '¡Oops!',
          first: 'Lo sentimos, ocurrió un error del sistema.',
          second: 'Actualice la página de su navegador. Si el error persiste, comuníquese con soporte a través del correo electrónico help@yapoli.com',
          third: 'Descripción del error'
        }
      }))
    }

  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div style={{ display: 'flex', justifyContent: 'center', background: '#F5F5F5', margin: 0, width: '100%' }}>
          <div style={{ marginTop: 20, width: 700, height: this.state.boxHeight ? 'auto' : 300, background: '#FFF' }}>
            <div style={{ padding: 25, marginTop: 20 }}>
              <h2 style={{ color: '#666', fontFamily: 'sans-serif', margin: 0 }}>{this.state.support_text.title}</h2>
              <div>
                <p style={{ color: '#666', fontFamily: 'sans-serif' }}>{this.state.support_text.first}</p>
                <p style={{ color: '#666', fontFamily: 'sans-serif', marginTop: 28 }}>{this.state.support_text.second}</p>
                <details style={{ color: '#666', whiteSpace: 'pre-wrap', fontFamily: 'sans-serif', marginTop: 35 }}>
                  <summary onClick={this.handleHeight}>{this.state.support_text.third}</summary>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;