import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    Grid,
    Chip,
    Typography,
    TextField
} from "@material-ui/core"


import UserComboBox from "components/ComboBox/UserComboBox"
import SelectUI from 'components/ComboBox/SelectUI'
import SSOComboBox from "components/ComboBox/SSOComboBox"

const FILEDS = [
    { label: "Yourself", value: "yourself" },
    { label: "Group", value: "group" },
]

const Moderation = ({
    type_approval = { label: '', value: '' },
    usersInApprovers,
    approvers,
    formErrors = {
        type_approval: ""
    },
    domain_email,
    handleAddApprover,
    handleRemoveApprover,
    handleChange,
    handleTypeApproval,
    handleChangeComboBoxSSO,
    sso_id,
    typeAuth,
    handleChangeTypeAuth
}) => {
    const { t } = useTranslation()
    return (
        <>
            <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid className="form-field full-width" style={{ marginBottom: 20 }}>
                    <label className='section'>{t("manager:Escolha o modelo de autenticação")} *</label>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid className="form-field full-width">
                    <Typography className="subtitle" style={{ marginBottom: 15 }}>
                        {t("manager:Escolha qual será o tipo de autenticação do perfil")}
                    </Typography>

                    <SelectUI
                        selectValues={[
                            { label: t("manager:Pela plataforma Yapoli"), value: "yapoli" },
                            { label: "SSO", value: "sso" },
                        ]}
                        nameField='typeAuth'
                        valueSelect={typeAuth}
                        handleChangeValue={(value) => handleChangeTypeAuth(value)}
                        error={formErrors.typeAuth}
                    />
                </Grid>
            </Grid>
            {
                typeAuth.value === "yapoli" ? (
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <SelectUI
                      selectValues={FILEDS}
                      nameField={'moderation'}
                      title={`${t("common:Tipo de moderação")}*`}
                      valueSelect={type_approval}
                      handleChangeValue={(value) => handleTypeApproval(value)}
                      error={formErrors.type_approval}
                    />

                    {type_approval.value === "yourself" &&
                      <Grid item xs={12}>
                        <Grid className="form-field full-width" style={{ marginTop: 16 }}>
                          <Typography className="subtitle">
                            {t("common:Domínio do e-mail")}
                          </Typography>
                          <TextField
                            type="text"
                            name="domain_email"
                            autoComplete="domain_email"
                            variant="outlined"
                            placeholder=""
                            fullWidth
                            color="primary"
                            value={domain_email}
                            onChange={(evt) => handleChange("domain_email", evt)}
                            error={Boolean(formErrors.domain_email)}
                            helperText={Boolean(formErrors.domain_email) ? formErrors.domain_email : ""}
                          />
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                                <SSOComboBox
                                    optionSelected={sso_id}
                                    handleChange={handleChangeComboBoxSSO}
                                    error={formErrors.sso_id}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="form-field full-width" style={{ marginTop: 16 }}>
                                <Typography className="subtitle">
                                    {t("manager:Digite o domínio para acesso")}
                                </Typography>
                                <TextField
                                    type="text"
                                    name="domain_email"
                                    placeholder=""
                                    variant="outlined"
                                    fullWidth
                                    color="primary"
                                    value={domain_email}
                                    onChange={(evt) => handleChange("domain_email", evt)}
                                    error={Boolean(formErrors.domain_email)}
                                    helperText={Boolean(formErrors.domain_email) ? formErrors.domain_email : ""}
                                />
                            </Grid>
                        </Grid>
                    </>
                )
            }

            <>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <UserComboBox
                  title={t("common:Moderadores")}
                  disabledItems={usersInApprovers}
                  value={{ label: "", value: "" }}
                  handleChange={(selectedItem) =>
                    handleAddApprover("approvers", selectedItem)
                  }
                  error={formErrors.approvers}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 10 }}>
                {approvers.map((item, key) => (
                  <Chip
                    key={key}
                    label={item.label}
                    onDelete={() => handleRemoveApprover(item.value)}
                    color="primary"
                  />
                ))}
              </Grid>
            </>

        </>
    )
}

export default Moderation
