import React from 'react';
import { useTranslation } from "react-i18next"
import PropTypes from 'prop-types';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { 
  TextField,  
  Grid,
  IconButton,
  Tooltip,
  Divider
} from "@material-ui/core";

const LinkLine = ({item, index, handleChangeLink, handleDeleteLink, loading}) => {
  const { t } = useTranslation()
  
  return (
    <Grid item xs={12} key={index}>
      <div style={{
        display: 'flex', alignItems: 'center'
      }}>
      <Grid item xs={12} sm={5}>
        <TextField
          variant="outlined"         
          label={t("common:Legenda")}
          placeholder={t("common:Digite a legenda.")}
          value={item.label}                 
          onChange={(evt) => handleChangeLink('label', index, evt.target)}
          style={{width: '100%'}}
          error={Boolean(item.error_label)}
          helperText={item.error_label ? item.error_label : ''}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6} style={{marginLeft: 30}}>
        <TextField
          variant="outlined"         
          label={t("Link")}
          placeholder={t("common:Digite o link.")}
          value={item.value}                 
          onChange={(evt) => handleChangeLink('value', index, evt.target)}
          style={{width: '100%'}}
          error={Boolean(item.error_value)}
          helperText={item.error_value ? item.error_value : ''}
          disabled={loading}
        />       
      </Grid>
      <Grid item xs={12} sm={1} style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center'
      }}>
        <Tooltip title={t("common:Excluir")} placement="top" aria-label={t("common:Excluir")}>
          <IconButton disabled={loading} color="default" onClick={() => handleDeleteLink(index)}>
            <DeleteRoundedIcon style={{marginRight: 5, color: '#666'}} />
          </IconButton>
        </Tooltip>
      </Grid>
      </div>
      
      <Divider style={{ margin: '20px 0' }} />
            
    </Grid>
  );
};

LinkLine.defaultProps = {
  item: {
    label: '',    
    value: '',
  }, 
  index: Math.random(), 
  handleChangeLink: () => {}, 
  handleDeleteLink: () => {},
  loading: false
};

LinkLine.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,    
    value: PropTypes.string,
  }), 
  index: PropTypes.number, 
  handleChangeLink: PropTypes.func, 
  handleDeleteLink: PropTypes.func,
  loading: PropTypes.bool,
};

export default LinkLine;
