export const handleChangeSwitch = (attribute, evt, state, callback) => {
    if(attribute && evt && state && callback) {
        let newState = { ...state }

        newState.data[attribute] = evt.target.checked

        callback(newState)
    }
}

export const handleSwitchRelateImages = (evt, state, callback) => {
    if(evt && state && callback) {
        let newState = { ...state }
        newState.data.relateImages = evt.target.checked

        if(evt.target.checked) {
            newState.data.canLink = true
        }
        callback(newState)
    }
}

export const handleChangeCategoryId = (category, state, callback) => {
    let newState = { ...state }
    newState['data']['linked_category_ids'] = category
    callback(newState)
}

export const addAllMetadataToStructure = (t, structureItems, attributes) => {
  attributes.forEach((item, index) => addMetadataToStructure(t, item, structureItems));
  return structureItems;
}

export const addMetadataToStructure = (t, metadata, structureItems, id = null) => {
  if (!metadata.required) {
    if (id) {
      return structureItems.filter((item) => item.value !== `attribute.${id}`)
    }
    return structureItems
  }
  if(structureItems.find(i => i.value === `attribute.${id}`)) {
    return structureItems;
  }
  structureItems.push({
    label: `${t("common:Metadado")} - ${metadata.name}`,
    value: `attribute.${metadata.id}`,
    disabled: false
  })
  return structureItems
}

export const disableSelectedItems = (structureItems, sourceToCheck) => {
  return structureItems.map((obj) => {
    sourceToCheck && sourceToCheck.forEach(
      (item) => obj = disableStructureItem(obj, `${item.entity}.${item.column}`)
    );
    return obj
  });
}

export const disableStructureItem = (item, valueToCheck) => {
  if (item.value === valueToCheck) {
    item.disabled = true;
  }
  return item
}

export const removeAttributeFromStructure = (id, structureItems) => {
  if (structureItems.find(i => i.value === `attribute.${id}`)) {
      structureItems = structureItems.filter((item) => item.value !== `attribute.${id}`)
  }
  return structureItems;
}

