import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from "@material-ui/core/Avatar/Avatar";

const Logo = () => { 
  const history = useHistory()
  return (
    <Avatar
      alt="Logo Cliente"
      src={localStorage.getItem("logoInsideJPG")}
      onClick={() => history.push("/")}
      style={{
        cursor: "pointer",      
        marginRight: 24,
        width: 60,
        height: 60,
      }}
    />
  )
};

export default Logo;
