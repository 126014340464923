import React, { useState, useCallback } from "react"
import { useTranslation } from "react-i18next"
import {
    Typography,
    Grid,
    Button,
    Box,
} from '@material-ui/core';
import LinkShared from 'components/LinkShared'
import FormSendEmail from 'components/FormSendEmail'

export default function SharedStepsWrapper({
    componentStep1,
    componentStep2,
    componentStep3,
    isLinkShare,
    id_item,
    alternativeNameId,
    disabled,
    versionIndex,
    data,
    shareExpirationTime,
    typeListrecipe,
    copyLink,
    includeMetadata
}) {
    const [step, setStep] = useState(1)
    const { t } = useTranslation()

    const handleStep = useCallback((action) => {
        setStep(action)
    }, [])

    const getStep = (key) => {
        switch (key) {
            case 1:
                return (
                    <>                        
                        {componentStep1}
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                            padding: 20
                        }}>
                            <Button variant="contained" color="primary" onClick={() => handleStep(2)} disableElevation>
                                {t("common:Avançar")}
                            </Button>
                        </div>
                    </>
                )
            case 2:
                return (
                    <>
                        {componentStep2}
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                            padding: 20
                        }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ margin: '0 15px 0 0', minWidth: 80 }}
                                onClick={() => handleStep(1)}
                            >
                                {t("common:Voltar")}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => handleStep(3)} disableElevation>
                                {t("common:Avançar")}
                            </Button>
                        </div>
                    </>
                )
            case 3:
                return (
                    <>
                        {componentStep3}
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'end',
                            padding: 20
                        }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ margin: '0 15px 0 0', minWidth: 80 }}
                                onClick={() => handleStep(2)}
                            >
                                {t("common:Voltar")}
                            </Button>

                            <Button disabled={disabled} variant="contained" color="primary" onClick={() => handleStep(4)} disableElevation>
                                {t("common:Avançar")}
                            </Button>
                        </div>
                    </>
                )
            case 4:
                return isLinkShare ? (
                    <Box style={{ padding: "0 20px 20px" }}>
                        <LinkShared
                            idItem={id_item}
                            alternativeNameId={alternativeNameId}
                            handleStepBack={() => handleStep(3)}
                            copyLink={copyLink}
                            idAsset={data.id_asset}
                            type={data.type}
                            shareExpirationTime={shareExpirationTime}
                            typeListrecipe={typeListrecipe}
                            includeMetadata={includeMetadata}
                        />
                    </Box>
                ) : (
                    <FormSendEmail
                        data={{ ...data, alternativeNameId }}
                        handleStepBack={() => handleStep(3)}
                        disabled={disabled}
                        versionIndex={versionIndex}
                        shareExpirationTime={shareExpirationTime}
                        typeListrecipe={typeListrecipe}
                        includeMetadata={includeMetadata}
                    />
                )
            default:
                return null
        }
    }

    const getStepTitle = (key) => {
        switch (key) {
            case 1:
                return <>
                    <Typography style={{ fontWeight: 500 }} variant="body1">
                        {t("common:Etapa 01 de 04.")}
                    </Typography>
                    <Typography variant="body2">
                        {t("common:Escolha o nome para o compartilhamento.")}
                    </Typography>
                </>
            case 2:
                return <>
                    <Typography style={{ fontWeight: 500 }} variant="body1">
                        {t("common:Etapa 02 de 04.")}
                    </Typography>
                    <Typography variant="body2">
                        {t("common:Escolha o tipo de download.")}
                    </Typography>
                </>
            case 3:
                return <>
                    <Typography style={{ fontWeight: 500 }} variant="body1">
                        {t("common:Etapa 03 de 04.")}
                    </Typography>
                    <Typography variant="body2">
                        {t('common:Como você deseja compartilhar?')}
                    </Typography>
                </>
            case 4:
                return <>
                    <Typography style={{ fontWeight: 500 }} variant="body1">
                        {t("common:Etapa 04 de 04.")}
                    </Typography>
                </>
            default:
                return null
        }
    }

    return (
        <>
            <Grid style={{ padding: 20, color: "#666" }}>
                {getStepTitle(step)}
            </Grid>
            {getStep(step)}
        </>
    );
}