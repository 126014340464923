export const settings = {
    area: 3,
    route: "metadata",
    entity: "attribute",
    title: {
        singular: "Metadado",
        plural: "Metadados"
    },
    optionsSelectType: [
        {label: "Data", value: "date"},
        {label: "Lista", value: "list"},
        {label: "Número", value: "number"},
        {label: "Texto", value: "text"},   
    ]
};
