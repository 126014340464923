import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios'
import _ from 'lodash'

import View from './view'

import { useDataItems } from 'pages/DAM/Items/context'

import { Creators as ItemsActions } from "flux/ducks/Items"

function MenuSelected() {
    const {
        totalItems,
        userData,
        getRenewedData,
        getParamsToItemsWithoutMarked
    } = useDataItems()

    const getIinitial = (value) => {
        switch (value) {
            case 'published_only':
                return 1
            case 'unpublished_only':
                return 2
            default:
                return 0
        }
    }
    const { showPublished } = useSelector((state) => state.itemsReducer)
    const [options, setOptions] = useState([])
    const [data, setData] = useState([])
    const [selectedIndex, setSelectedIndex] = React.useState(getIinitial(showPublished))
    const [shouldGetFilters, setShouldGetFilters] = useState(false)
    const [canSeePublicationFilter, setCanSeePublicationFilter] = useState(false)
    const { pathServer, requestHeaders, data: { primaryColor } } = useSelector(
        (state) => state.appReducer
    )
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleMenuItemClick = (option) => {
        setSelectedIndex(option.id)
        const paramsToItems = getParamsToItemsWithoutMarked()

        if (option.value === 'all') {
            dispatch(ItemsActions.setSession({
                name: "showPublished",
                data: 'all',
            }), getRenewedData({ ...paramsToItems, showPublished: 'all' }))
        } else {
            dispatch(ItemsActions.setSession({
                name: "showPublished",
                data: option.value,
            }), getRenewedData({ ...paramsToItems, showPublished: option.value }))
        }
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) setShouldGetFilters(_.get(totalItems, "total", 0) > 0)
        return () => { isMounted = false }
    }, [totalItems])

    useEffect(() => {
        let isMounted = true
        if (isMounted) setCanSeePublicationFilter(_.get(userData, 'userCanSeePublishedFilter', false))
        return () => { isMounted = false }
    }, [userData])

    useEffect(() => {
        let isMounted = true
        let options = []
        if(!_.isEmpty(data)) {
            if (canSeePublicationFilter) {
                if (data.total !== 0) {
                    options.push({ id: 0, total: data.total, label: `${t("common:Todos")} (${data.total})`, value: 'all' })
                }
                if (data.published !== 0) {
                    options.push({ id: 1, total: data.published, label: `${t("common:Publicados")} (${data.published})`, value: 'published_only' })
                }
                if (data.unpublished !== 0) {
                    options.push({ id: 2, total: data.unpublished, label: `${t("common:Despublicados")} (${data.unpublished})`, value: 'unpublished_only' })
                }
            } else {
                if (data.published !== 0) {
                    options.push({ id: 0, total: data.published, label: `${t("common:Todos")} (${data.published})`, value: 'published_only' })
                }
            }
        }
        if (isMounted) setOptions(options)
        return () => { isMounted = false }
    }, [canSeePublicationFilter, data, t])

    useEffect(() => {
        let isMounted = true
        if(isMounted && shouldGetFilters) {
            const paramsToItems = getParamsToItemsWithoutMarked()
            const dataParamsToItems = { ...paramsToItems, limit: 2000 }
            delete dataParamsToItems.only
            delete dataParamsToItems.order
            axios
                .post(pathServer + "/item/amount/filters", dataParamsToItems, requestHeaders)
                .then(function (resp) {
                    const { data } = resp.data
                    setData(data)
                })
                .catch((err) => console.log(err))
        }
        return () => { isMounted = false }
    }, [pathServer, requestHeaders, t,  getParamsToItemsWithoutMarked, shouldGetFilters])

    return (
        <Fragment>
            <View
                title={t("common:Publicação dos Itens")}
                options={options}
                selectedIndex={selectedIndex}
                handleMenuItemClick={handleMenuItemClick}
                hasFilters={_.get(totalItems, "total", 0) !== 0}
                primaryColor={primaryColor}
            />
        </Fragment>
    )
}

export default MenuSelected
