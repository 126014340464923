import React from 'react'
import arrayMove from "array-move"

import BoxTaxonomy from "./BoxTaxonomy"

const TableBoxTaxonomy = ({ data = [], data_disabled = [], handleSetState }) => {

    const handleRemoveTaxonomy = (entity, column) => {
        let newState = [...data]

        newState = data.filter((item) => !(item.column === column && item.entity === entity));
       
        if(data_disabled.length > 0 ) {
            const taxonomyItems = data_disabled.map((item) => {
                if (item.value === `${entity}.${column}`) {
                    item.disabled = false;
                }
                return item;
            })
            handleSetState(newState, taxonomyItems)
        } else {
            handleSetState(newState)
        }        
    }

    const handleMoveTaxonomy = ({ oldIndex, newIndex }) => {
        let newState = [...data]

        newState = arrayMove(newState, oldIndex, newIndex)

        handleSetState(newState)
    }

    let dataTaxonomy = data.map(item => {
        item.handleRemoveTaxonomy = (entity, column) => handleRemoveTaxonomy(entity, column)
        return item;
    })

    return data.length > 0 && (
        <BoxTaxonomy items={dataTaxonomy} handleMoveTaxonomy={(obj) => handleMoveTaxonomy(obj)} />
    )
}

export default TableBoxTaxonomy