import React, { useEffect, useCallback, useState } from "react";
import axios from 'axios'
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"

import Template from "../Template";
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Typography,
    Divider
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WrapperPaperForms } from 'components/WrapperForms'

const ToolUsage = (props) => {
    const { t } = useTranslation()
    const id = props.match.params.id
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer);
    const [data, setData] = useState({ tool_record: {}, references: {} })

    const getData = useCallback(() => {
        axios
            .get(`${pathServer}/tool/${id}/references`, requestHeaders)
            .then((resp) => {
                setData(prev => ({ ...prev, tool_record: resp.data.tool_record, references: resp.data.references }))
            })
            .catch((err) => {
                console.log(err)
            });
    }, [pathServer, requestHeaders, id])

    useEffect(() => {
        let isMounted = true
        if (isMounted) getData()
        return () => {
            isMounted = false
        }
    }, [getData])

    return (
        <>
            <Template area={8} localHistory={props.history} customClassName={"container-center"}>
                <WrapperPaperForms title={t("manager:Utilização da ferramenta")}>
                    <Grid>
                        <Typography variant="body1">
                            {t(`manager:está sendo utilizado em`, { name: data.tool_record.title })}:
                        </Typography>
                    </Grid>
                    <Grid style={{ marginTop: 20 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography style={{ fontWeight: 500 }}>{t("common:Categorias")}</Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails>
                                <Grid container>
                                    {
                                        data.references.categories && data.references.categories.length > 0 ?
                                        data.references.categories.map((i, k) => <Grid key={`${k}-${i}`} item xs={12}><Typography style={{ marginTop: 23 }}>{i}</Typography></Grid>) :

                                            <Typography style={{ marginTop: 15 }}>{t("manager:Esta ferramenta não está associada a nenhuma categoria")}</Typography>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', marginTop: 20 }}>
                        <Button
                            style={{ height: 40, width: 115 }}
                            variant="outlined"
                            color="primary"
                            onClick={() => props.history.push("/manager/tools")}>
                            {t("common:Voltar")}
                        </Button>
                    </div>
                </WrapperPaperForms>
            </Template>
        </>
    )
}

export default ToolUsage;
