import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import View from './view'

function MenuByUpload({ setParametersFilters, filtersParams, hasIntegration }) {
    const { user_id } = useSelector((state) => state.appReducer.userData)
    const { t } = useTranslation()

    const options = [
        { id: 0, title: t("DAM:Todos"), value: user_id, field: 'all' },
        { id: 1, title: t("DAM:Meus uploads"), value: user_id, field: 'user_id' },
        { id: 2, title: t("DAM:Integração não processada"), value: hasIntegration, field: 'onlyIntegrationErrors' },
    ]

    const handleMenuItemClick = (index) => {
        const item = options.find(i => i.id === index)

        let params = {}

        if(_.get(item, 'field', '') === 'onlyIntegrationErrors') {
            params = {
                onlyIntegrationErrors: item.value
            }
        }

        if(_.get(item, 'field', '') === 'user_id') {
            params = {
                user_id: _.get(item, 'value', ''),
            }
        }

        setParametersFilters('byUpload', params)
    }

    return (
            <View
                title={t("DAM:Visualizar por")}
                options={options}
                data={filtersParams}
                handleMenuItemClick={handleMenuItemClick}
            />
    )
}


export default MenuByUpload
