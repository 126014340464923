export const styles = theme => ({
    rootDropzone: {
        backgroundColor: '#F0F0F0',
        border: '1px dashed #E0E0E0',
        boxSizing: 'border-box',
        borderRadius: 4,
        width: '100%',
        height: 196,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',       
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          '& p': {
            color: '#B3B3B3'
          },          
          '& .image': {
            maxWidth: 372,
            '& img': {
              marginTop: 4,          
              height: 193,
            }
          }
        }
      },
      formControl: {
        width: '100%',
        marginBottom: 20,
      }
  })