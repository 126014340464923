import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapperRoot: {
    width: "100%",
    position: "fixed",
    bottom: 20,
    display: "flex",
    justifyContent: "center",
    zIndex: 9999990
  },
  container: {
    display: "flex",
    color: "white",
    padding: 16,
    background: theme.palette.primary.main,
    alignItems: "center",
    width: '80%',
    borderRadius: '4px',
    "& .title": {
      fontSize: "1rem",
      width: "80%",
      paddingRight: 24
    },
    "& .button": {
      width: "20%"
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      flexDirection: 'column',
      "& .title": {
        width: "100%",
        padding: 0,
        marginBottom: 16
      },
      "& .button": {
        width: "100%"
      },
    },
  },
  confirmButton: {
    color: "white",
    width: "200px",
    height: "40px",
    fontSize: "1em",
    border: "1px solid white",
    padding: "5px 15px",
    background: "transparent",
    borderRadius: "4px",
    fontWeight: "500",
    lineHeight: "1.75",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: `${theme.palette.primary.dark}`,
      border: `1px solid ${theme.palette.primary.dark}`,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  }
}))
