import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Router from '../Core/Router';
import Data from '../Core/Data';
import connect from "react-redux/es/connect/connect";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { lighten } from "polished"
import { UploadWrapper } from '../Upload'
import { useTranslation } from 'react-i18next';
import ReactCookie from '../ReactCookie'
import _ from 'lodash'
import CssBaseline from '@material-ui/core/CssBaseline';
import ZenDesk from 'components/ZenDesk'

const notistackRef = React.createRef()
const useStyles = makeStyles({
  error: { backgroundColor: '#F4511E' },
  root: { zIndex: 1000010 }
})

const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

const Wrapper = ({ text }) => (
  <>
    <CssBaseline />
    <div style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '72px',
      background: '#f4f4f4'
    }}>
      {text}
    </div>
  </>
);

const Theme = ({ palette, policies, hasInvalidDns, isUpdating, hasInvalidRegion }) => {
  const colorPrimary = Boolean(_.get(palette, 'primary.main', '')) ? _.get(palette, 'primary.main', '') : "rgba(46, 115, 252, 0.11)"

  const overrides = {
    MuiTooltip: {
      popper: {
        zIndex: 1000005 + '!important',
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#eee',
          '& #ampl-survey': {
            right: 'calc(50% - 189px)'
          },
          "& .selection-area": {
            background: `${lighten(0.3, colorPrimary)}`,
            border: `2px solid ${colorPrimary}`,
            borderRadius: 4,
            opacity: 0.5,
          },
          "& .selection-area-container": {
            zIndex: '1080 !important'
          }
        },       
      },
    },
  }


  let muiTheme = {
    ...(_.get(palette, 'primary.main', false) && {
      palette: {
        ...palette,
        grey200: "#EEEEEE",
        grey500: "#9E9E9E",
        grey700: "#616161",
      }
    }),
    overrides
  }
  const theme = createMuiTheme(muiTheme);
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <ZenDesk />
        <CssBaseline />
        {!hasInvalidDns && !isUpdating && !hasInvalidRegion ? (
          <SnackbarProvider
            classes={{
              variantError: classes.error,
              containerRoot: classes.root
            }}
            ref={notistackRef}
            maxSnack={3}
            action={(key) => (
              <Button
                style={{ color: '#FFF' }}
                aria-label="close"
                size="small"
                onClick={onClickDismiss(key)}>{t('common:Entendi')}</Button>
            )
            }
          >
            <UploadWrapper>
              <Data />
              <BrowserRouter>
                <Router policies={policies} />
              </BrowserRouter>
            </UploadWrapper>
            <ReactCookie />
          </SnackbarProvider>
        ) : hasInvalidDns
          ? <Wrapper text='Dns inválido.' />
          : (hasInvalidRegion
            ? <Wrapper text='Região inválida.' />
            : <Wrapper text='Atualizando aplicação' />)
        }
      </MuiThemeProvider>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    palette: state.appReducer.palette,
    policies: state.appReducer.userData.policies,
    hasInvalidDns: state.appReducer.hasInvalidDns,
    hasInvalidRegion: state.appReducer.hasInvalidRegion,
    isUpdating: state.appReducer.isUpdating,
  }
};

export default connect(mapStateToProps, null)(Theme);
