import { appReducer } from "./appReducer"
import { menuReducer } from "./menuReducer"
import { combineReducers } from "redux"
import { uploadReducer } from "./uploadReducer"

import itemsReducer from "../ducks/Items"
import headerAppReducer from "../ducks/header_app"
import assetReducer from "../ducks/Asset"
import appBarReducer from "../ducks/appbar_app"
import downloadCentralReducer from '../ducks/Download_central'
import pageBucketReducer from '../ducks/page_bucket'

export const reducers = combineReducers({
  appReducer: appReducer,
  menuReducer: menuReducer,
  uploadReducer: uploadReducer,
  itemsReducer,
  headerAppReducer,
  assetReducer,
  appBarReducer,
  downloadCentralReducer,
  pageBucketReducer,
})
