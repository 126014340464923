import React, { useState } from 'react';

import Previewpdf from '../../components/Previewpdf'

const PreviewpdfContainer = ({ file }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(.6)


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <Previewpdf
      onDocumentLoadSuccess={onDocumentLoadSuccess}
      pageNumber={pageNumber}
      numPages={numPages}
      pdf={file}
      scale={scale}
      setScale={setScale}
      setPageNumber={setPageNumber}
      />
  )
}

export default PreviewpdfContainer
