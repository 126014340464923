import React from "react"
import { useTranslation } from "react-i18next"
import _ from 'lodash'
import { IconButton, Tooltip, useMediaQuery } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { withStyles } from "@material-ui/core/styles"
import CheckSVG from 'imagens/ui/fact_check-24px.svg'
import { useDataAssetIntegra } from "../context"
import EditItem from './EditItem'
import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded'
import InfoRoundedIcon from "@material-ui/icons/InfoRounded"
import ShareRoundedIcon from "@material-ui/icons/ShareRounded"
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded"
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded"
import LinkRoundedIcon from "@material-ui/icons/LinkRounded"
import CollectionsRoundedIcon from "@material-ui/icons/CollectionsRounded"
import ThreeDRotationRoundedIcon from '@material-ui/icons/ThreeDRotationRounded'
import { ReactComponent as PublishIconSVG } from 'imagens/ui/publish_icon.svg'
import { ReactComponent as FamilyHistorySVG } from 'imagens/ui/family_history.svg'

import ItemComponent from './ItemComponent'

const ContentSVG = withStyles({
  svg: {
    filter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(19deg) brightness(106%) contrast(101%)"
  },
  disabled_svg: {
    filter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(19deg) brightness(0.1) opacity(30%)"
  },
})(({ classes, thumbnail, title, disabled }) => (
  <img
    src={thumbnail}
    alt={title}
    width="24"
    height="24"
    className={!disabled ? classes.svg : classes.disabled_svg}
  />
))

const NavMenu = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width:600px)')
  const { stateItem, setNavComponentName } = useDataAssetIntegra()

  const sizeSkeleton = {
    width: 24,
    height: 24
  }

  return (
    <div className="contentNav">
      {stateItem.loading ? (
        <>
          <Skeleton
            variant="rect"
            {...sizeSkeleton}
          />
          <Skeleton
            variant="rect"
            {...sizeSkeleton}
          />
          <Skeleton
            variant="rect"
            {...sizeSkeleton}
          />
          <Skeleton
            variant="rect"
            {...sizeSkeleton}
          />
        </>
      ) : (
        <>
          <Tooltip title={t("DAM:Informações do item")} placement="left">
            <div>
              <IconButton
                color="inherit"
                onClick={() => setNavComponentName("details")}
                style={{ marginTop: 6 }}
              >
                <InfoRoundedIcon />
              </IconButton>
            </div>
          </Tooltip>

          <Tooltip title={t("DAM:Versões do item")} placement="left">
            <div>
              <IconButton
                color="inherit"
                onClick={() => {
                  setNavComponentName("versions")
                }}
              >
                <UpdateRoundedIcon />
              </IconButton>
            </div>
          </Tooltip>

          {
            !isMobile &&
            <Tooltip title={t("DAM:Ativos do item")} placement="left">
              <div>
                <IconButton
                  color="inherit"
                  onClick={() => setNavComponentName("itemFiles")}
                  disabled={!(_.get(stateItem, 'versionIndex', 0) === 0 && _.get(stateItem, 'allow.managerItemAsset', false))}
                >
                  <CollectionsRoundedIcon />
                </IconButton>
              </div>
            </Tooltip>
          }

          <Tooltip title={t("common:Download do item")} placement="left">
            <div>
              <IconButton
                color="inherit"
                onClick={() => setNavComponentName("downloadItem")}
                disabled={!_.get(stateItem, 'allow.download', false) || _.get(stateItem, 'data.assets', []).length <= 0}
              >
                <GetAppRoundedIcon />
              </IconButton>
            </div>
          </Tooltip>

          <Tooltip title={t("DAM:Compartilhar item")} placement="left">
            <div>
              <IconButton
                color="inherit"
                onClick={() => setNavComponentName("sendEmail")}
                disabled={!_.get(stateItem, 'allow.email', false) || _.get(stateItem, 'data.assets', []).length <= 0}
              >
                <ShareRoundedIcon />
              </IconButton>
            </div>
          </Tooltip>

          <ItemComponent
            title={t("common:Distribuição")}
            component_name="distributedFiles"
            icon={FamilyHistorySVG}
            handleComponentName={setNavComponentName}
            url={`item/${_.get(stateItem, 'data.id', '')}/distributed_files`}
            field_data='data'
          />

          <ItemComponent
            title={t("common:Itens relacionados")}
            component_name="relatedItems"
            icon={LinkRoundedIcon}
            handleComponentName={setNavComponentName}
            url={`item/${_.get(stateItem, 'data.id', '')}/links`}
            isDisabled={!_.get(stateItem, 'data.hasLinks', false)}
          />

          <ItemComponent
            title={t("common:Publicação do item")}
            component_name="publish"
            icon={PublishIconSVG}
            handleComponentName={setNavComponentName}
            url={`item/publishhistory/${_.get(stateItem, 'data.id', '')}`}
            field_data='history'
          />

          {
            !isMobile &&
            <EditItem
              dataEdit={{
                slugItem: _.get(stateItem, 'data.taxonomy.slug', '').split("-").join("_"),
                name: _.get(stateItem, 'data.taxonomy.name', '')
              }}
              isDisabled={!_.get(stateItem, 'allow.manager', false) || !(_.get(stateItem, 'versionIndex', 0) === 0 && _.get(stateItem, 'allow.managerItemAsset', false))} />
          }

          {(_.get(stateItem, 'data.tools', []).length > 0) && _.get(stateItem, 'data.tools', []).map((i) => (
            <Tooltip title={i.title || ""} placement="left" key={i.id}>
              <div>
                <IconButton
                  color="inherit"
                  onClick={() => setNavComponentName("request-tool", i.slug)}
                  disabled={i.disabled || false}
                >
                  <ContentSVG
                    thumbnail={i.thumbnail ? i.thumbnail : CheckSVG}
                    title={i.title ? i.title : t("common:Solicitações")}
                    disabled={i.disabled || false}
                  />
                </IconButton>
              </div>
            </Tooltip>
          ))}

          {
            _.get(stateItem, 'data.is3DModel', false) &&
            <Tooltip title={t("common:Itens 3D")} placement="left">
              <div>
                <IconButton
                  color="inherit"
                  onClick={() => setNavComponentName("associateFilesTextures")}
                >
                  <ThreeDRotationRoundedIcon />
                </IconButton>
              </div>
            </Tooltip>
          }

          <ItemComponent
            title={t("common:Paleta de cores")}
            component_name="colorPallete"
            icon={PaletteRoundedIcon}
            handleComponentName={setNavComponentName}
            url={`item/${_.get(stateItem, 'data.id', '')}/pallete`}
            field_data='colorpallete'
          />

        </>
      )}

    </div>
  )
}

export default NavMenu
