import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 660px)',
    '& button:last-child': {
      borderBottom: "1px solid #DDDDDD",
    },
    [theme.breakpoints.down("sm")]:{
      maxHeight: '100vh'
    }
  },
  grid_row: {
    display: 'flex',
    alignItems: 'center',
    color: '#666',
    cursor: 'pointer',
    padding: 10,
    '& .icon': {
      marginRight: 10,
      display: 'flex'
    },
    "&:hover": {
      backgroundColor: "#F0F0F0",
    },
  },
  disabled: {
    opacity: 0.6,
    cursor: 'default',
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 16px",
    border: 'none',
    borderTop: "1px solid #DDDDDD",
    borderLeft: 'none',
    borderRight: 'none',
    backgroundColor: "#fff",
    textAlign: 'left',
    height: '60px',
    color: '#666',
    width: '100%',
    "&:hover": {
      backgroundColor: "#F0F0F0",
      cursor: 'pointer'
    },
  },
  boxInfo: {
    width: "30px",
    height: "30px",
    backgroundColor: "#666",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginRight: "10px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}))