import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  accessRequest: {
    width: "40%",
    maxWidth: "355px",
    height: "100%",
    maxHeight: "600px",
    display: "none",
    margin: "0 22.5px",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",     
      alignItems: "center",
      width: "100%",
      maxWidth: "100%",
      height: "30vh",
      maxHeight: "none",
      margin: 0,
      paddingTop: '15px',
      paddingBottom: '70px',
      borderRadius: 0,      
    },
    "& .containerText": {
      maxWidth: "70%",
      maxHeight: "60%",
      marginBottom: "40px",
      overflow: "hidden",
      "@media (max-height: 600px)": {
        display: "none",
      },
      "& p": {
        fontWeight: "300",
        fontSize: "2.57em",
        lineHeight: "1.17em",
        textAlign: "center",
        color: "#818080",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "10px",
        "& p": {
          fontSize: "1rem",
          lineHeight: "1.2em",
        },
      },
    },
    "& button": {
      width: "100%",
      minHeight: "80px",
      maxWidth: "250px",
      textAlign: "center",
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        minHeight: "40px",
        maxWidth: "75%",
      },
    },
  },
  login: {
    width: '40%',
    maxWidth: "355px",
    height: "100%",
    padding: '20px 0',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 22.5px",
    fontSize: "1em",   
    "@media (max-height: 768px) and (min-width:960px)": {
      width: props => props.hasSso ? '100%' : '40%',
      maxWidth: props => props.hasSso ? '100%' : '40%',
      '& .root-formLogin': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
      },
      '& .root-sso': {
        width: '30%'
      }
    }, 
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",      
      maxHeight: "none",
      margin: 0,
      borderRadius: 0,
      boxShadow: 'none',
      "& .btnLink": {
        paddingBottom: "10px",
      },
    },
    "& .descriptionMobile": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center",
        fontSize: "1rem",
        lineHeight: "1em",
        color: "#818080",
        padding: "0 10%",
        margin: "5px 0 20px 0",
        "@media (max-height: 450px)": {
          padding: "10px",
          paddingTop: 0,
          margin: "0",
          width: "100%",
        },
      },
    },
    "& .formLogin": {     
      "& input": {
        fontSize: "1em",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },      
    },    
  },
  btnForgot: {  
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    '& button': {
      fontSize: "1em",
      cursor: "pointer",
      border: 'none',
      background: 'transparent',
      textAlign: 'left',
      padding: 0,
      fontFamily: 'inherit',
      color: theme.palette.primary.main || "#000000",   
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    }    
  },
  actions: {
    width: '100%',
    "& button, a": {
      width: "100%",
      height: "40px",   
      fontSize: '1rem',    
    },
    '& a': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  divider: { 
    width: '100%',
    margin: '20px 0',   
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    "& .MuiDivider-root": {
      width: "100%",      
    },
    '& .text': {      
      width: '100%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      margin: '0 5px',
      color: '#666',
      marginTop: 10,
    },
    '& .divider': {
      width: '100%'
    }
  },
  rootBtnSso: {
    '& a': {
      width: '100%',
      '&:hover':{
        color: '#fff'
      }
    },    
  }
}));
