import React, { useMemo, useCallback } from "react"
import _ from 'lodash'
import SelectUI from 'components/ComboBox/SelectUI'

const ReactSelect = ({
    data,
    isMulti,
    value = [],
    error = false,
    handleChangeAttribute,
    optionCreateMetadata = true
}) => {
    const handleUpdateMetadataValues = useCallback((values, id) => {
        handleChangeAttribute(values, id)
    }, [handleChangeAttribute])

    const handleAddValueAttribute = useCallback((tag = [], actionSelect) => {
        if (_.get(actionSelect, 'option.value', '') !== 'create_x99') {
            const id = _.get(actionSelect, 'option.id', _.get(actionSelect, 'removedValue.id', ''))
            if (Boolean(tag)) {
                if (Array.isArray(tag)) {
                    handleChangeAttribute(tag, id)
                } else {
                    handleChangeAttribute(tag, _.get(tag, 'id', ''))
                }
            } else {
                handleChangeAttribute([], id)
            }
        }
    }, [handleChangeAttribute])

    const constants = useMemo(() => ({
        options: data.list_value.map((value) => {
            if (isMulti) {
                if (!Boolean(value)) {
                    return null
                }
                if (typeof value === "string") {
                    return ({
                        label: value,
                        value: value,
                        id: data.id,
                    })
                }
                if (Boolean(value) && (value.value && value.label)) {
                    return { ...value, id: data.id, }
                }
                return null
            } else {
                if (_.get(value, 'value', _.get(value, 'label', ''))) {
                    return { ...value, id: data.id, }
                } else {
                    return ({
                        label: value,
                        value: value,
                        id: data.id,
                    })
                }
            }
        }).filter(i => Boolean(i)),
    }), [data.list_value, data.id, isMulti])

    return (
        <SelectUI
            listType={data.list_type}
            selectValues={constants.options}
            disabled={data.isDisabled}
            nameField={data.name}
            title={Boolean(data.required) ? `${data.name} *` : data.name}
            valueSelect={value}
            error={error}
            optionCreateMetadata={optionCreateMetadata}
            idMeta={data.id}
            isColumnChip={data.isColumnChip}
            isMulti={isMulti}
            handleChangeValue={(tags, action) => handleAddValueAttribute(tags, action)}
            handleUpdateMetadataValues={handleUpdateMetadataValues}
        />
    )
}

export default ReactSelect
