import React from "react";
import Utils from "../../utils/Utils";
import { Redirect } from "react-router";

class RedirectDAM extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      region: Utils.getStoredData("region")
    };
  }

  
  render() {
    const { region } = this.state;
    return <Redirect to={{pathname: `/dam/${region}`}} />
  }  
}

export default RedirectDAM;