import { useCallback } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux"
import { Creators as DownloadCentralActions } from "flux/ducks/Download_central"
import { useSnackbar } from "notistack";
import Utils from 'utils/Utils'

export default function useDownloadCentral() {
    const { requestHeaders, pathServer, userData: { user_token } } = useSelector((state) => state.appReducer)
    const params = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const getData = useCallback(async (isMounted = false, customParams = {}) => {
        if (Boolean(user_token)) {
            let parameters = { limit: 40, highlight: params.id, ...customParams }
            try {
                const result = await axios.post(`${pathServer}/item/download/find`, parameters, requestHeaders)
                if (isMounted) {
                    if (_.get(customParams, 'last')) {
                        dispatch(DownloadCentralActions.setMoreItems(_.get(result, 'data.items', [])))
                    } else {
                        dispatch(DownloadCentralActions.setDownloadData({
                            checkedData: true,
                            loading: false,
                            items: _.get(result, 'data.items', []),
                            hasData: (_.get(result, 'data.items', []).length > 0),
                            items_total: Boolean(_.get(result, 'data.highlighted', false))
                                ? (_.get(result, 'data.total', 0) - 1)
                                : _.get(result, 'data.total', 0) - 1,
                            pageLast: _.get(result, 'data.last', ''),
                            highlightItem: _.get(result, 'data.highlighted', null)
                        }))
                    }
                }
            } catch (e) {
                if (isMounted) {
                    dispatch(DownloadCentralActions.setDownloadData({
                        loading: false, checkedData: true, error: true
                    }))
                }
            }
        }
    }, [pathServer, requestHeaders, params.id, dispatch, user_token])

    const onClickGoBack = useCallback(() => {
        if (params.id) {
            history.push('/')
        } else {
            history.goBack()
        }
    }, [params.id, history])

    const getDownloadLink = useCallback(async (data, type) => {
        try {
            const result = await axios.get(`${pathServer}/items/download/${data.id}`, requestHeaders)
            dispatch(DownloadCentralActions.updateItemsData(data))
            if (_.get(result.data, "url", "")) {
                Utils.makeDownload(_.get(result.data, "url", ""))
            } else {
                const errorMessage = { response: { data: { message: t('common:Erro no link de download. Tente novamente.') } } }
                throw errorMessage
            }
        } catch (err) {
            const message = Utils.ajaxErrorGetMessage(err, t, t('common:Tente novamente, ao persistir por favor contate o suporte.'))
            throw new Error(message)
        }
    }, [pathServer, requestHeaders, dispatch, t])

    const showMessage = useCallback((message = "", type = "error") => {
        enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            },
        })
    }, [enqueueSnackbar])

    const downloadFile = useCallback(async (data, type = "manual") => {
        if (data.state === 'invalid') {
            dispatch(DownloadCentralActions.updateItemsData({ ...data, error: true }))
        } else {
            if (type === "automatic") {
                try {
                    const controller = new AbortController()
                    setTimeout(() => controller.abort(), 2000)
                    await navigator.locks.request(
                        `${data.id}`,
                        { signal: controller.signal },
                        async () => {
                            const dataSession = JSON.parse(localStorage.getItem('historyDownloads')) || []
                            if (dataSession.some(i => i === data.id)) {
                                dispatch(DownloadCentralActions.updateItemsData(data))
                            } else {
                                localStorage.setItem('historyDownloads', JSON.stringify([...dataSession, data.id]))
                                await getDownloadLink(data)
                            }
                            return "success"
                        })
                } catch (err) {
                    let msg = err.message
                    if (err.name === "AbortError") {
                        msg = t('common:Tempo excedido para fazer o download automático.')
                    }
                    console.log(err)
                    showMessage(msg)
                }
            } else {
                try {
                    await getDownloadLink(data)
                } catch (err) {
                    console.log(err)
                    showMessage(err.message)
                }
            }
        }
    }, [dispatch, getDownloadLink, showMessage, t])

    return { onClickGoBack, downloadFile, getData }
}
