import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next'
import _ from "lodash"
import {
    InputLabel,
    Grid,
    FormControl,
    FormHelperText
} from '@material-ui/core';

import { useSelector } from 'react-redux'
import Creatable from 'react-select/creatable'
import { components } from 'react-select'


import svgRemove from '../../imagens/ui/cancel-24px.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'rgba(0, 0, 0, 0.54)',
        '& .label-focus-on': {
            transform: 'translate(14px, -6px) scale(0.75)',
            backgroundColor: '#fff',
            padding: '0 5px',
            color: props => props.is_value ? 'inherit' : theme.palette.primary.main,
        },
        '& .label-focus-off': {
            color: 'inherit'
        },
        '& .select-custom__option': {
            cursor: 'pointer'
        },
        '& div.select-custom__value-container': {
            padding: '12.6px 14px',
        },
        '& .select-custom__control--is-focused': {
            borderColor: `${theme.palette.primary.main} !important`,
            boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& div#duplicate': {
            backgroundColor: '#f44336'
        }
    },
    rootError: {
        color: 'rgba(0, 0, 0, 0.54)',
        '& .label-focus-on': {
            transform: 'translate(14px, -6px) scale(0.75)',
            backgroundColor: '#fff',
            padding: '0 5px',
            color: theme.palette.error.main,
        },
        '& .label-focus-off': {
            color: theme.palette.error.main
        },
        '& .select-custom__option': {
            cursor: 'pointer'
        },
        '& div.select-custom__value-container': {
            padding: '12.6px 14px',
        },
        '& .select-custom--is-disabled': {
            opacity: 0.3,
        },
        '& .select-custom__control': {
            borderColor: `${theme.palette.error.main} !important`,
        },
        '& .select-custom__control--is-focused': {
            borderColor: `${theme.palette.error.main} !important`,
            boxShadow: `0 0 0 1px ${theme.palette.error.main} !important`,
        },
        '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& div#duplicate': {
            backgroundColor: '#f44336'
        }
    }
}));

export default function CreatableUIMult({
    title = '',
    description = '',
    values = [],
    handleChangeCreatable,
    error = false,
    inputValue,
    handleInputChangeCreatable,
    handleKeyDown,
    isDisabled = false
}) {
    const [focus, setFocus] = React.useState({ status: 'off' });
    const classes = useStyles({ ...focus, error: error });
    const { primaryColor } = useSelector((state) => state.appReducer.data)
    const { t } = useTranslation()

    useEffect(() => {
        if (Boolean(values) && values.length > 0) {
            setFocus({ status: 'on', is_value: true })
        }
    }, [values])

    const handleFocus = (evt) => {
        evt.preventDefault()
        setFocus({ status: 'on' })
    };

    const handleBlur = (evt) => {
        evt.preventDefault()

        if (!Boolean(values) || values.length <= 0) {
            setFocus({ status: 'off' })
        }
    };

    const customStyles = {
        multiValueLabel: () => ({
            color: '#fff',
            padding: '0 12px',
        }),
        multiValue: () => ({
            margin: '4px',
            minHeight: '32px',
            maxWidth: '100%',
            backgroundColor: `${primaryColor}`,
            display: 'inline-flex',
            outline: 0,
            padding: 0,
            fontSize: '0.8125rem',
            boxSizing: 'border-box',
            transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            alignItems: 'center',
            borderRadius: '16px',
            verticalAlign: 'middle',
            justifyContent: 'center',
            textDecoration: 'none',
            border: 'none',
            whiteSpace: 'nowrap'
        }),
        multiValueRemove: () => ({
            borderRadius: '50%',
            width: 22,
            height: 22,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundImage: `url(${svgRemove})`,
            backgroundPosition: 'center',
            margin: '0 5px 0 -6px',
            '& svg': {
                display: 'none'
            },
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)'
            }
        }),
        menu: provided => ({ ...provided, zIndex: 3 })
    }

    const MultiValueContainer = (props) => {
        const isDuplicate = Boolean(props.data.duplicate)

        const newProps = isDuplicate ? {
            ...props,
            innerProps: {
                ...props.innerProps,
                id: 'duplicate'
            }
        } : { ...props }

        return (
            <components.MultiValueContainer {...newProps}>
                {props.children}
            </components.MultiValueContainer>
        );
    }

    const componentsCustom = { DropdownIndicator: null, MultiValueContainer: MultiValueContainer }

    let newValues = []

    for (let key in values) {
        if (!Array.isArray(_.get(values[key], "value", ""))) {
            const data = _.get(values[key], "value", "")
            const value = Array.isArray(data) ? data : data.toLowerCase().trim()      
            if (newValues.some(i => i.value.toLowerCase().trim() === value)) {
                const { value, label } = values[key]
                newValues.push({
                    value: `${value}-${Math.random()}`,
                    label,
                    duplicate: true
                })
            } else {
                newValues.push(values[key])
            }
        }
    }

    let helperText = Boolean(description) ? description : t("manager:Digite o e-mail e aperte ENTER ou TAB para adicionar. Para lista de e-mails, utilize vírgula (,) ou ponto e vírgula (;) para separar")

    return (
        <Grid item xs={12} className={Boolean(error) ? classes.rootError : classes.root}>
            <FormControl variant="outlined">
                <InputLabel className={(focus.status === 'on') ? 'label-focus-on' : 'label-focus-off'}>
                    {title}
                </InputLabel>
                <Creatable
                    components={componentsCustom}
                    styles={customStyles}
                    isMulti
                    isClearable
                    classNamePrefix="select-custom"
                    onChange={handleChangeCreatable}
                    value={newValues}
                    placeholder={''}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    inputValue={inputValue}
                    onInputChange={handleInputChangeCreatable}
                    onKeyDown={handleKeyDown}
                    isDisabled={isDisabled}
                    menuIsOpen={false}
                />

                <FormHelperText style={{ marginLeft: 0 }}>
                    {!Boolean(error) ? helperText : error}
                </FormHelperText>
            </FormControl>
        </Grid>
    );
}