import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  card: {
    width: 250,
    height: 365,
    margin: theme.spacing(1),
  },
  cardSelected: {
    position: "relative",
    bottom: "calc(100% - 1px)",
    width: 250,
    height: 366,
    zIndex: 0,
    border: `3px solid ${theme.palette.primary.main}`,
  },
  cardError: {
    width: 250,
    height: 365,
    margin: theme.spacing(1),
    backgroundColor: "#ff9999",
  },
  cardUnpublished: {
    width: 250,
    height: 365,
    opacity: 0.5,
    margin: theme.spacing(1),
    backgroundColor: "#cccccc",
  },
  button: {
    padding: "5px",
  },
  cardContent: {
    padding: "9px 16px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  headerDate: {
    padding: '3px 4px',
    borderRadius: 16,
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 7,
    right: '8%',
    '& svg': {
      fontSize: '.8rem',
      color: '#616161',
      marginRight: 5,
    }
  }
}))
