import React from 'react'
import ListDefault from "../Core/List/DataGridCustom"
import ListWrapper from '../Core/List/ListWrapper'
import {
  getColumnDelete,
  getColumnEdit,
  getColumnThumbnail,
  getColumnDetails,
} from "../Core/utils"
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "../../../utils/Flux"
import { withTranslation } from "react-i18next"
import _ from 'lodash'
import Tooltip from "@material-ui/core/Tooltip/Tooltip"
import Zoom from "@material-ui/core/Zoom/Zoom"
import IconButton from "@material-ui/core/IconButton/IconButton"
import DialogUploadCsv from './DialogUploadCsv';
import { withSnackbar } from 'notistack';
import Settings from "utils/Settings";

class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
    }
    this.superRender = super.render
  }

  getLine(item) {
    let thumbnail = _.get(item, "urls.thumbnail.original")

    let attributes = ""
    if (item.hasOwnProperty("attributes") && item.attributes.length) {
      attributes = item.attributes
        .map((item) => {
          return item.name
        })
        .join(", ")
    }

    let line = {
      id: item.id,
      thumbnail: { id: item.id, thumbnail: thumbnail },
      name: item.name,
      canLink: item.canLink ? "Sim" : "Não",
      attributes,
    }
    return line
  }

  getColumns() {
    const { t } = this.props

    const thumb = getColumnThumbnail((id) => {
      this.props.history.push(`/manager/${this.state.route}/upload/${id}`)
    }, t)

    let columns = [
      getColumnDetails("name", t("common:Nome"), 220),
      getColumnDetails("canLink", t("common:Relacionar itens"), 120),
      getColumnDetails("attributes", t("common:Metadados"), 220),
    ]

    columns.unshift(thumb)

    columns.push(
      this.getColumnCreateItems((id) => {
        this.setState({
          ...this.state,
          uploadCsvDialogOpen: true,
          idToUpload: id
        })
      }, t)
    )

    columns.push(
      getColumnEdit((id) => {
        this.props.history.push(`/manager/${this.state.route}/edit/${id}`)
      }, t)
    )

    columns.push(
      getColumnDelete((id, name) => {
        this.setState({
          ...this.state,
          deleteDialogOpen: true,
          idToDelete: id,
          nameToDelete: name,
        })
      }, t)
    )

    return columns
  }

  getColumnCreateItems(callback, t) {
    return {
      field: "upload_csv",
      headerName: t("manager:Criar itens"),
      width: 70,
      sortable: false,
      headerAlign: "center",
      cellClassName: "centerCell",
      renderCell: (params) => {
        const id = _.get(params, 'row.id', '')

        return (
          <Tooltip
            title={`${t('manager:Criar itens')}`}
            placement="right"
            TransitionComponent={Zoom}
          >
            <IconButton onClick={() => callback(id)}>
              <CloudUploadIcon />
            </IconButton>
          </Tooltip>
        )
      }
    }
  }

  handleCloseCsv(SELF) {
    SELF.setState({...SELF.state, uploadCsvDialogOpen: false, idToUpload: null})
  }

  onSuccessFullUploadCsv(SELF) {
    SELF.setState({...SELF.state, uploadCsvDialogOpen: false, idToUpload: null}, () => {
      const { t } = SELF.props
      SELF.props.enqueueSnackbar(t("manager: Upload CSV feito com sucesso."), {
        ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
      })
    })
  }

  render(){
    const { hasFab, area, title, route, uploadCsvDialogOpen, idToUpload } = this.state

    return (
      <>
      <ListWrapper
        area={area}
        title={title.singular}
        route={route}
        hasFab={hasFab}
      >
        {this.superRender()}
      </ListWrapper>
      {uploadCsvDialogOpen &&
        <DialogUploadCsv
          onSuccess={() => this.onSuccessFullUploadCsv(this)}
          handleClose={() => this.handleCloseCsv(this)}
          open={uploadCsvDialogOpen}
          idToUpload={idToUpload}
        />
      }
      </>
    )
  }
}

export default connect(
  mapStateToPropsToSettings,
  null
)(withTranslation(["manager", "common"])(withSnackbar(List)))
