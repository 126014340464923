import React from "react"
import View from './view'
import Utils from "utils/Utils"

function MenuRangeDate({ setParametersFilters, filtersParams }) {
    const handleMenuItemClick = (values) => {
        const { start, end } = values

        setParametersFilters('rangeDate', {
            ...(Boolean(start) && { start: Utils.formatDate(start, "YYYY-MM-DD") }),
            ...(Boolean(end) && { end: Utils.formatDate(end, "YYYY-MM-DD") }),
        })
    }

    return <View  data={filtersParams} handleMenuItemClick={handleMenuItemClick}  />
}


export default MenuRangeDate
