import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { SortableElement } from "react-sortable-hoc"
import CreatableInputOnly from "components/CreatableInputOnly"
import YapoliImage from "components/Filters/YapoliImage"

import DragIndicatorRoundedIcon from "@material-ui/icons/DragIndicatorRounded"

import { useStyles } from "./styles";
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

const AssetdndItem = SortableElement(
  ({ asset, assetDnd, handleChangeTags, isTagsComponent, dataValues }) => {
    const { supportsWebp } = useSelector((state) => state.appReducer)
    const type = supportsWebp ? "webp" : "original"
    const classes = useStyles()
    const { t } = useTranslation();
    const [dataValueFind, setDataValueFind] = useState([])

    useEffect(() => {
      if(Array.isArray(dataValues) && dataValues.length > 0) {
        const data = dataValues.filter(i => i.id === asset.id)
        const value = _.get(data, '0.value', [])

        setDataValueFind(value)
      }
    }, [dataValues, asset])

    return (
      <div className={classes.contentItem}>
            <span
              style={{
                marginBottom: 23,
                display: "flex",
                alignItems: "center",
              }}
            >
              {assetDnd + 1}
              <DragIndicatorRoundedIcon fontSize="inherit" />
            </span>

            <YapoliImage
              key={asset.id}
              alt={asset.client_name}
              asset={asset}
              file={asset[`thumbnail_card_${type}`]}
              width="200px"
              height="200px"
            />
        {isTagsComponent && (
            <>
            <div style={{marginTop: 7}}>
             <label className={classes.labelDescription}>{t('common:Marcadores')}</label>

            <CreatableInputOnly
              assetId={asset.id}
              dataValues={dataValueFind}
              handleChangeTags={handleChangeTags}
              valueLimited={10}
              t={t}
            />
            </div>
          </>
        )}
      </div>
    )
  }
)

export default AssetdndItem
