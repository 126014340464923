import React from "react"
import { useTranslation } from "react-i18next"
import {
  Card,
  CardContent,
  CssBaseline,
  Grid,
  Typography,
  Checkbox,
  Box,
  Tooltip,
  CardActions,
} from "@material-ui/core"

import _ from 'lodash'

import CardCarousel from 'components/Layout/Card/CardItem/CardCarousel'
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"

import { useStyles } from "../styles"

export const CardFile = ({ file, handleChangeSelected, markedItems = [] }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  
  let checked = markedItems.find(i => _.get(i, 'destination.id', '') === file.id)

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            variant="body2"
            color="textPrimary"
            component="div"
            textoverflow="ellipsis"
          >
            <Box
              style={{ whiteSpace: "nowrap" }}
              component="div"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {_.get(file, "name", "")}
            </Box>
          </Typography>


          <Typography
            variant="body2"
            color="textPrimary"
            style={{ fontSize: ".7rem", marginTop: 2 }}
          >
            {`${_.get(file, "assets", []).length} ${_.get(file, "assets", []).length === 1
              ? t("common:arquivo")
              : t("common:arquivos")
              }`}
          </Typography>
        </CardContent>

        <CardCarousel
          data={{
            assets: _.get(file, "assets", [{ file: "" }]),
          }}
        />

        <CardActions
          disableSpacing
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >

          <Tooltip title={t("common:Selecionar")}>
            <div>
              <Checkbox
                checked={Boolean(checked)}
                onChange={evt => handleChangeSelected(evt, file)}
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                disabled={_.get(checked, 'isDisabled', false)}
              />
            </div>
          </Tooltip>

        </CardActions>
        {Boolean(checked) && (
          <Tooltip title={file.name}>
            <div className={classes.cardSelected} />
          </Tooltip>
        )}
      </Card>
    </React.Fragment>
  )
}

const Cards = ({ dataItems, handleChangeSelected, markedItems, loading }) => {
  const { t } = useTranslation()
  
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container style={{ padding: 20, justifyContent: 'center' }}>
        {dataItems.length > 0 &&
          dataItems.map((file, index) => (
            <CardFile
              file={file}
              key={index}
              handleChangeSelected={handleChangeSelected}
              markedItems={markedItems}
            />
          ))}
        {dataItems.length === 0 && !loading && (
          <div style={{ padding: "0 20px" }}>
            <SimpleAlertMessage
              severity="info"
              alertTitle="Ops!"
              message={t(
                "common:Não encontramos o que você procura. Que tal uma nova busca?"
              )}
            />
          </div>
        )}
      </Grid>
    </React.Fragment>
  )
}

Cards.defaultProps = {
  dataItems: [],
  markedItems: []
}

export default Cards
