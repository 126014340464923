import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  row: {
    padding: "30px 0 30px 40px",  
    borderBottom: "1px solid #EEEEEE",
    display: "flex",
    "& p": {
      color: "#808080",
      display: "block",
    },
    "& p.title": {
      fontWeight: 500,
      width: "150px",
    },
  }, 
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    padding: '20px 20px 0 20px',
    "& button": {
      margin: "0 10px",
      minWidth: "140px",
      height: "40px",
      maxHeight: "40px",
      fontWeight: 500,
    },
    "& button.action.ok": {
      backgroundColor: theme.palette.primary.main,
      width: 'auto', 
      color: "#fff",
      '&:disabled': {
        backgroundColor: '#eee',
      }
    },
    "& button.action.not": {
      backgroundColor: "#F4511E",
      width: 'auto',      
      color: "#fff",
      '&:disabled': {
        backgroundColor: '#eee',
      }
    },
    "& button.cancel": {
      minWidth: "140px",           
      '&:disabled': {
        backgroundColor: '#eee',
      }
    },
  },
  rootAvatar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    marginLeft: "40px",
    "& h2": {
      color: "#808080",
      fontSize: "1.5em",
      lineHeight: "1.1em",
      marginLeft: "20px",
    },
    '& button.btnEditImg': {
      cursor: 'pointer'
    }
  },
  avatar: {
    color: "#7D7D7D",
    backgroundColor: "#fff",
    width: "100px",
    height: "100px",
    "& svg": {
      fontSize: "6.1875rem !important",
    },
  },
}));
