import React, { useState, useCallback } from "react"

import { useSelector } from "react-redux"
import SkeletonResponsive from './Skeleton'
import Settings from "utils/Settings"
import { simpleGet } from "utils/Request"
import _ from "lodash"

import EmptyImageWhite from "imagens/ui/empty_image_white.svg"
import EmptyImageGray from "imagens/ui/empty_image_gray.svg"

const ImageLazyLoad = ({
  images = { imgWEBP: null, imgJPG: null },
  alt = "",
  styles = { width: '100%', height: '' },
  iconEmptyColorGray = false,
  isBucket = false,
}) => {
  const [dataImage, setDataImage] = useState({
    urls: images, errorUrl: 0, loading: false
  })
  const { userData: { user_token } } = useSelector((state) => state.appReducer)

  const iconDefaultEmpty = useCallback(() => iconEmptyColorGray ? EmptyImageGray : EmptyImageWhite,
    [iconEmptyColorGray])

  const getData = useCallback((value) => {
    const payload = {
      file: value,
    }
    simpleGet(`${Settings.URL_API}/api/url`, payload, { user_token })
      .then((data) => {
        setDataImage(prev => ({
          errorUrl: prev.errorUrl + 1,
          urls: { imgJPG: data, imgWEBP: data },
          loading: false
        }))
      })
      .catch((err) => {
        setDataImage(prev => ({
          loading: false,
          errorUrl: (prev.errorUrl + 1)
        }))
      })
  }, [user_token])

  const getUrlByState = useCallback(() => {
    let urlJPG = iconDefaultEmpty()
    if ((dataImage.errorUrl === 0) && (Boolean(images.imgJPG) || Boolean(images.imgWEBP))) {
      urlJPG = images.imgJPG || images.imgWEBP
    } else {
      urlJPG = _.get(dataImage, "urls.imgJPG", _.get(dataImage, "urls.imgWEBP", urlJPG))
    }
    return urlJPG
  }, [dataImage, images, iconDefaultEmpty])

  const handleErrorImage = useCallback(() => {
    const src = getUrlByState()
    const icon = iconDefaultEmpty()
    if (!dataImage.loading) {
      if (dataImage.errorUrl < 2) {
        setDataImage(prev => ({ ...prev, loading: true }), getData(src))
      } else {
        setDataImage(prev => ({ ...prev, urls: { imgJPG: icon, imgWEBP: icon }, loading: false }))
      }
    }
  }, [getData, dataImage.errorUrl, dataImage.loading, iconDefaultEmpty, getUrlByState])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {
        isBucket ? (
          <div style={{ paddingTop: '100%', width: '100%', position: 'relative', overflow: 'hidden' }}>
            {
              dataImage.loading ? (
                <SkeletonResponsive width={styles.width} height={styles.height} />
              ) : (
                <img
                  src={getUrlByState()}
                  onError={evt => handleErrorImage()}
                  style={{ width: '100%', position: 'absolute', top: 0, zIndex: 1 }}
                  alt={alt}
                  loading="lazy"
                />
              )
            }
          </div>
        ) : (
          <>
            {
              dataImage.loading ? (
                <SkeletonResponsive width={styles.width} height={styles.height} />
              ) : (
                <img
                  src={getUrlByState()}
                  onError={evt => handleErrorImage()}
                  style={styles}
                  alt={alt}
                  loading="lazy"
                />
              )
            }
          </>
        )
      }

    </div>
  )
}

export default ImageLazyLoad
