import React, { useState } from "react";
import {Button, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import axios from "axios";
import { useSnackbar } from "notistack";
import Utils from '../../../../utils/Utils'
import CircularProgress from '@material-ui/core/CircularProgress';
import UploadActions from "../../../../flux/actions/UploadActions";
import Settings from 'utils/Settings'

const ErrorReport = ({ t, children, asset }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [callbackState, setCallbackState] = useState(null);
  const [sendingReport, setSendingReport] = useState(false)
  const {
    palette,
    userData: { user_email },
    requestHeaders,
    pathServer,
  } = useSelector((state) => state.appReducer);
  const classes = useStyles({ palette });
  const { enqueueSnackbar } = useSnackbar();

  const show = (callback) => (event) => {
    event.preventDefault();

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    };

    setOpen(true);
    setCallbackState(() => () => callback(event));
  };

  const hide = () => {
    setOpen(false);    
  };

  const handleSendReport = () => {
    const data = { message: asset.error, asset_id: asset.id, date: asset.dateError }
    setSendingReport(true)
    axios
      .post(`${pathServer}/asset/report/error`, data, requestHeaders)
      .then((resp) => { 
        callbackState()               
        enqueueSnackbar(t('DAM:Erro reportado com sucesso.'), {
          ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
        });
        setCallbackState(null);
        setSendingReport(false)
        dispatch(UploadActions.uploadFileRemove(asset))
        hide()
      })
      .catch((err) => {
        enqueueSnackbar(
          err.response.data.message || err.response.headers["x-message"],
          {
            variant: "error",
            autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
        setSendingReport(false)
      });
  };

  const confirm = () => {
    handleSendReport() 
  };  

  return (
    <React.Fragment>
      {children(show)}
      {open && (
        <Dialog
          open={open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            <div className="info">
              <ErrorRoundedIcon id="main" />
              <Typography variant="h5">
              {t('DAM:Reportar erro')}
              </Typography>              
            </div>
          </DialogTitle>

          <DialogContent className={classes.desc}>
            <Typography variant="subtitle1" gutterBottom>
              {t('DAM:Você deseja realmente reportar esse problema?')}
            </Typography>
            <br />
            <Typography variant="body1" style={{fontWeight: 500}}>
              {t('common:Arquivo')}:
            </Typography>
            <Typography variant="body1" gutterBottom>              
                {asset.name}
                <br />
                {`${t('DAM:em')} ${Utils.formatDateFull(asset.updated_at)}`}
            </Typography>
            <br />
            <Typography variant="body1" style={{fontWeight: 500}}>
              {t('common:Descrição')}:
            </Typography>
            <Typography variant="body1" gutterBottom>              
              {t(`common:${asset.error_msg}`)}
            </Typography>
            <br />
            <Typography variant="body1">              
              {t('DAM:Ao reportar esse erro um chamado será aberto e a equipe de suporte da Yapoli retornará diretamente para ')} 
              <b style={{fontWeight: 500}}>{user_email}</b>
              {t('DAM: com mais informações sobre a ocorrência em até 24h a partir da data de envio.')}
            </Typography>           
          </DialogContent>

          <DialogActions className={classes.actions}>
            <Button variant="outlined" color="primary" onClick={hide}>
              {t(`common:Cancelar`)}
            </Button>
            
            {sendingReport ? (
              <Button
                variant="contained"
                color="primary"
                disabled={true}
              >
                <CircularProgress className="white" size={20} />
              </Button>
            ) : (
              <Button
                onClick={confirm}
                variant="contained"
                color="primary"                
              >
                {t(`DAM:Enviar erro`)}
              </Button>
            )}            
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default ErrorReport;
