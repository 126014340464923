import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios'
import { getRequiredLngCombo } from 'utils/Helpers'
import PolicyUpload from './partials/Policy'
import _ from 'lodash'
import {
    Button,
    Typography,
    Grid,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';

import { useSnackbar } from 'notistack'

const WrapperUpload = ({ dataLng, index, shouldGetData, setShouldGetData }) => {
    const [currentPolicy, setCurrentPolicy] = useState({})
    const [loading, setLoading] = useState(true)
    const { requestHeaders, pathServer } = useSelector(
        (state) => state.appReducer
    )
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const getData = useCallback(() => { 
        axios.get(pathServer + "/client/privacy", requestHeaders)
            .then(function (resp) {
                
                const { current_policy } = resp.data || {};
               
                if(!Array.isArray(current_policy)) {
                    setCurrentPolicy({
                        ...current_policy,
                        file: current_policy.file[dataLng.lng]
                    })
                }
                setShouldGetData(false)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte");
                enqueueSnackbar(message, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                })
                console.log(err)
            });
    }, [pathServer, requestHeaders, enqueueSnackbar, t, dataLng, setShouldGetData])

    useEffect(() => {
        if(shouldGetData) {
            getData()
        }
        
        return () => { }
    }, [getData, shouldGetData])

    return (
        <>
        {
            index !== 0 &&
            <Grid item xs={12} style={{ margin: '20px 0' }}>
                <Divider />
            </Grid>
        }
        <Grid item xs={12} style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20
        }}>
            <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 5  
        }}>   
                <TranslateIcon fontSize='small' style={{ color: '#666' }} />            
                <Typography variant="body1" style={{ color: '#666', marginLeft: 10, fontWeight: 500 }}>
                    {t(`manager:${_.get(dataLng, 'short_name', '')}`)}
                </Typography> 
            </div>
            <Typography variant="body2" display="block">
                {t(`manager:${_.get(dataLng, 'description', '')}`)}         
            </Typography>           
        </Grid>
        <PolicyUpload policie={currentPolicy} _loading={loading}
            language={dataLng.lng}
            setShouldGetData={setShouldGetData}
        />        
        </>
    )
}

const SecurityConfig = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const { requestHeaders, pathServer } = useSelector(
        (state) => state.appReducer
        )
    const [shouldGetData, setShouldGetData] = useState(true)
    const [maxAttempts, setMaxAttempts] = useState(null)
    const [loading, setLoading] = useState(true)
    
    const ID_PLATFORM = useSelector(state =>
        _.get(state, 'appReducer.data.client_id', ''))

    const handleChange = (evt) => {
        const value = evt.target.value

        if (value !== 'notBlock') {
            setMaxAttempts(parseInt(value))
        }
        else {
            setMaxAttempts(null)
        }
    }

    const getData = useCallback(() => {
        axios.get(`${pathServer}/client/attempts_login_config/${ID_PLATFORM}`, requestHeaders)
            .then((resp) => {
                if (_.get(resp.data, 'attempts_login_config', '')) {
                    setMaxAttempts(_.get(resp.data, 'attempts_login_config.maxAttempts', ''))
                }
                setLoading(false)
            })
    }, [pathServer, ID_PLATFORM, requestHeaders])

    useEffect(() => {
        getData()
        return () => { }
    }, [getData])

    const submitData = () => {
        const parameters = {
            maxAttempts: parseInt(maxAttempts)
        }

        axios.put(`${pathServer}/client/attempts_login_config/${ID_PLATFORM}`, parameters, requestHeaders)
            .then(()=> {
                const message = t("Registro inserido com sucesso!")
                enqueueSnackbar(message, {
                    variant: "success",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
            })

            .catch((err) => {
                const message = err.response
                    ? t(err.response.data.slug) || err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte")
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
                console.log(err)
            })
    }

    return (
        <>
            <Grid item xs={12} style={{ margin: '30px 0' }}>
                <Typography variant="body1">
                    {t('manager:A plataforma pode conter nome e descrição de acordo com o idioma desejado. Caso possua apenas o idioma obrigatório preenchido as informações serão utilizadas como padrão em todos os idiomas.')}
                </Typography>
            </Grid>
            <Divider />
            <Grid>
                <Typography variant='body1' style={{ fontWeight: 500, marginTop: 10 }}>
                    {t('manager:Acesso do usuário')}
                </Typography>
                <div style={{ margin: '10px 0 10px 0' }}>
                    <Typography style={{ margin: '8px 0 8px 0' }}>
                        {t('manager:A plataforma poderá bloquear o acesso do usuário após tentativas excessivas de login.')}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <RadioGroup onClick={(evt) => handleChange(evt)}>
                            <FormControlLabel
                                control={<Radio color='primary' value='notBlock' checked={!loading && !maxAttempts} disabled={loading} />}
                                label={<Typography>{t('manager:Não bloquear')}</Typography>}
                            />
                            <FormControlLabel
                                control={<Radio color='primary' value='3' checked={maxAttempts === 3} disabled={loading} />}
                                label={<Typography>{t('manager:Bloquear após tentativas', {qtd: 3})}</Typography>}
                            />
                            <FormControlLabel
                                control={<Radio color='primary' value='4' checked={maxAttempts === 4} disabled={loading} />}
                                label={<Typography>{t('manager:Bloquear após tentativas', {qtd: 4})}</Typography>}
                            />
                            <FormControlLabel
                                control={<Radio color='primary' value='5' checked={maxAttempts === 5} disabled={loading} />}
                                label={<Typography>{t('manager:Bloquear após tentativas', {qtd: 5})}</Typography>}
                            />
                        </RadioGroup>
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'end', margin:'10px 0 15px'}}>
                    <Button
                        disabled={loading}
                        style={{ height: 40, width: 115 }}
                        variant="contained"
                        color="primary"
                        onClick={submitData}
                    >
                        {t("common:Salvar")}
                    </Button>
                </div>
                        
            </Grid>
            <Divider style={{ marginBottom: 15 }} />
            <Typography variant='body1' style={{ fontWeight: 500, margin: '20px 0 20px' }}>
                {t('manager:Política de privacidade')}
            </Typography>
            {getRequiredLngCombo().map((item, index) =>
                <WrapperUpload dataLng={item} key={index} index={index} shouldGetData={shouldGetData} setShouldGetData={setShouldGetData} />
            )}  
        </>
    )
}

export default SecurityConfig