import React from "react"
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux"

import {  
  CssBaseline,
  Grid,
} from "@material-ui/core"

import AppActions from "flux/actions/AppActions" 
import { useDataEditFiles } from '../context'

import { CardFile } from "pages/DAM/Bucket/Cards"

const Cards = ({ bucket }) => {
  const { markedAssets } = useSelector(
    (state) => state.appReducer
  )
  const {
    dataSelected,
    setDataSelected,
  } = useDataEditFiles()
  const dispatch = useDispatch()

  const handleSelectCardBucket = (evt, file) => {    
    evt.preventDefault()
    
    dispatch(AppActions.unmarkAsset(file))    
  }

  const handleSelectCard = (evt, file) => {
    evt.preventDefault()

    if (dataSelected.some((f) => f.id === file.id)) {
      const filter = dataSelected.filter(i => i.id !== file.id)
      setDataSelected(filter)
    } else {
      setDataSelected(prev => [...prev, file])
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container style={{ padding: 20, overflow: 'auto', height: "calc(100vh - 164px)" }} spacing={2}>
        {
          bucket ? (
            <>
              {markedAssets.length > 0 &&
                markedAssets.map((file) => (
                  <CardFile
                    file={file}
                    key={file.id}
                    dataMarked={markedAssets}
                    handleSelectCard={handleSelectCardBucket}
                    policies={[]}                   
                  />
                ))}
            </>
          ) : (
            <>
              {dataSelected.length > 0 &&
                dataSelected.map((file) => (
                  <CardFile
                    file={file}
                    key={file.id}
                    dataMarked={dataSelected}
                    handleSelectCard={handleSelectCard}
                    policies={[]}                
                  />
                ))}
            </>
          )
        }
      </Grid>
    </React.Fragment>
  )
}

Cards.propTypes = {
  bucket: PropTypes.bool,
}

export default Cards
