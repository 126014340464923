import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack"

import {
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
} from '@material-ui/core';

const headCells = [
    { id: 'thumbnail', label: 'Miniatura' },
    { id: 'itemTaxonomy', label: 'Nome do item' },
    { id: 'assets', label: 'Ativos' },
    { id: 'status', label: 'Status' },
    { id: 'validity_date', label: 'Data de vigência' },
    { id: 'details', label: 'Detalhes' },
    { id: 'delete', label: 'Excluir' },
]

const Head = ({ onSelectAllClick, selectedData, data = [], maxCount = 0 }) => {
    const [checked, setChecked] = useState(false)

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const dataLength = useMemo(() => data.length, [data])

    useEffect(() => {
        let isMounted = true
        let newData = []
        const dataIds = data.map(i => i.id)

        for (let id of dataIds) {
            if (selectedData.some(i => i === id)) {
                newData.push(id)
            }
        }

        if (isMounted) setChecked(newData.join() === dataIds.join())

        return () => { isMounted = false }        
    }, [selectedData, data])


    const handleClick = useCallback((evt) => {
        setChecked(_.get(evt, 'target.checked', false), onSelectAllClick(evt))

        if (maxCount > 0) {
            enqueueSnackbar(t(`common:Todos os itens dessa página foram selecionados.`, { name: dataLength }), {
                variant: "info",
                autoHideDuration: null,
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                },
            });
        }
    }, [dataLength, enqueueSnackbar, maxCount, onSelectAllClick, t])

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <Checkbox checked={checked} onChange={handleClick} color='primary' />
                </TableCell>
                {headCells.map((headCell, key) => (
                    <TableCell key={_.get(headCell, 'id', key)} align='left'>
                        {t(`common:${_.get(headCell, 'label', '')}`)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default Head
