import React from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'

import ClientImage from "./partials/ClientImage";
import FormLayout from './partials/FormLayout'
import {
    Typography,
    Grid,     
} from '@material-ui/core';

const LayoutConfig = () => {  
    const ID_PLATFORM = useSelector(state =>
        _.get(state, 'appReducer.data.client_id', '')
    )
    const { t } = useTranslation()

    return (
        <>
            <Grid item xs={12} style={{ margin: '30px 0' }}>
                <Typography variant="body1">
                    {t('manager:Personalize a plataforma com a sua identidade visual.')}
                </Typography>                 
            </Grid>

            <FormLayout />          
          
            <ClientImage
                id={ID_PLATFORM}
                title={t("Background login")}
                description={t("common:Imagem aplicada ao fundo da área de login.")}
                specifications={`${t('common:Especificações')}: PNG, JPG; 1280px x 960px; ${t('common:tamanho máximo')} 2MB.`}
                name={"background_login"}
            />
            <ClientImage
                id={ID_PLATFORM}
                title={t("common:Logo da página inicial")}
                description={t("common:Imagem aplicada na página de login sob o background.")}
                specifications={`${t('common:Especificações')}: PNG, JPG; 200px x 200px; ${t('common:tamanho máximo')} 2MB.`}
                name={"logo_login"}
            />
            <ClientImage
                id={ID_PLATFORM}
                title={t("common:Logo para menu superior")}
                description={t("common:Imagem aplicada na barra superior da página de login.")}
                specifications={`${t('common:Especificações')}: PNG, JPG; 300px x 60px; ${t('common:tamanho máximo')} 2MB.`}
                name={"top_menu_logo"}
            />
            <ClientImage
                id={ID_PLATFORM}
                title={t("Favicon")}
                description={t("common:Imagem aplicada no ícone do navegador.")}
                specifications={`${t('common:Especificações')}: PNG, ICO; 50px x 50px; ${t('common:tamanho máximo')} 2MB.`}
                name={"favicon"}
                type="image/x-icon, image/png"
            />
            <ClientImage
                id={ID_PLATFORM}
                title={t("common:Logo interno")}
                description={t("common:Imagem aplicada na barra superior dentro da plataforma.")}
                specifications={`${t('common:Especificações')}: PNG, JPG; 300px x 300px; ${t('common:tamanho máximo')} 2MB.`}
                name={"logo_inside"}
            />
            <ClientImage
                id={ID_PLATFORM}
                title={t("common:Logo do e-mail")}
                description={t("common:Imagem aplicada nos e-mails enviados pela plataforma.")}
                specifications={`${t('common:Especificações')}: PNG, JPG;  300px x 70px; ${t('common:tamanho máximo')} 2MB.`}
                name={"logo_email"}
            />
            <ClientImage
                id={ID_PLATFORM}
                title={t("common:Print da tela para compartilhamento de arquivos")}
                description={t("common:Imagem que será anexada em redes sociais e plataformas externas.")}
                specifications={`${t('common:Especificações')}: PNG, JPG; 1920px x 1080px; ${t('common:tamanho máximo')} 2MB.`}
                name={"logo_sharing"}
            />
        </>
    )
}

export default LayoutConfig