import React, { useEffect, useCallback } from "react"
import axios from 'axios'
import _ from "lodash"

import { useSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useTheme } from "@material-ui/core"

import Utils from 'utils/Utils'
import { useDataAssetIntegra } from "pages/DAM/Item/context"
import { useDataRecognition } from "./context"
import ImageLazyLoad from "components/ImageLazyLoad"
import ButtonDemarcation from "./ButtonDemarcation"
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'

export function changeBackground(evt, box_id, color) {
    evt.stopPropagation()
    const elem = document.getElementById(box_id)
    const elemRoot = document.getElementById(`root-${box_id}`)

    if (Boolean(elem)) {
        if (elem.style.visibility === "hidden") {
            elem.style.visibility = "visible"
            elemRoot.style.border = `2px solid ${color}`
        } else {
            elem.style.visibility = "hidden"
            elemRoot.style.border = "2px solid rgba(255, 255, 255, 0.9)"
        }
    }
}

const RootBoundingbox = ({ DATA_ASSET = {}, modelType = "person" }) => {
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
    const { state, setDataState } = useDataRecognition()
    const { stateItem: { data: { id: itemId } } } = useDataAssetIntegra()
    const theme = useTheme()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const getData = useCallback(() => {
        axios
            .post(`${pathServer}/item/${itemId}/asset/${DATA_ASSET.id}/dataannotation`, {
                modelType: modelType,
                width: 600,
                height: 600
            }, requestHeaders)
            .then(({ data }) => {
                const field = modelType === "person" ? "persons.bounding_boxes" : "objects.bounding_boxes"
                const boxes = _.get(data, field, []).map(i => ({ ...i, id: JSON.stringify(i) }))
                setDataState({
                    checked: true,
                    bounding_boxes: boxes
                })
            })
            .catch((err) => {
                const message = Utils.ajaxErrorGetMessage(err, t)
                setDataState({ checked: true })
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
            })
    }, [pathServer, requestHeaders, enqueueSnackbar, t, itemId, DATA_ASSET.id, modelType, setDataState])

    const submit = useCallback((params, callbackError) => {
        if (!_.isEmpty(params)) {
            axios
                .post(`${pathServer}/item/${itemId}/asset/${DATA_ASSET.id}/dataannotation/transit`, { ...params, modelType }, requestHeaders)
                .then(() => getData())
                .catch((err) => {
                    const message = Utils.ajaxErrorGetMessage(err, t)
                    callbackError()
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    })
                })
        }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, itemId, DATA_ASSET.id, modelType, getData])

    useEffect(() => {
        let isMounted = true
        if (isMounted && !state.checked) getData()
        return () => { isMounted = false }
    }, [getData, state.checked])

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%"
            }}>
            {
                state.bounding_boxes.length > 0 && state.bounding_boxes.map((box, key) => {
                    return (
                        <div
                            key={box.id}
                            style={{
                                position: "absolute",
                                width: box.width,
                                height: box.height,
                                top: box.y,
                                left: box.x,
                            }}>
                            <div
                                className="root"
                                id={`root-${box.id}`}
                                onClick={evt => changeBackground(evt, box.id, theme.palette.primary.main)}
                                style={{
                                    width: box.width,
                                    height: box.height,
                                    border: "2px solid rgba(255, 255, 255, 0.9)",
                                    borderRadius: 4,
                                    cursor: "pointer",
                                    boxShadow: `0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F`,
                                    position: "relative",
                                }}
                            />

                            {
                                box.marking === "Não Identificado" &&
                                <div style={{
                                    position: "absolute",
                                    backgroundColor: "#fff",
                                    top: "-8px",
                                    right: "-8px",
                                    width: 24,
                                    height: 24,
                                    borderRadius: '50%'
                                }}>
                                    <ErrorRoundedIcon color="primary" />
                                </div>
                            }

                            <ButtonDemarcation
                                submit={submit}
                                dataBox={{ ...box, key }}
                                modelType={modelType}
                            />
                        </div>
                    )
                })
            }
            <ImageLazyLoad
                styles={{ width: 600, height: 600 }}
                images={{
                    imgWEBP: DATA_ASSET.thumbnail_card_webp,
                    imgJPG: DATA_ASSET.thumbnail_card_original
                }}
                alt={DATA_ASSET.client_name}
            />
        </div>
    )
}

export default RootBoundingbox
