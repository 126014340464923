import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'start'
    },
  },
  wrapperButttons: {
    width: '100%',
    maxHeight: 'calc(100vh - 440px)',
    wordWrap: 'break-word',
    overflowX: 'hidden',
    overflowY: 'auto',        
    [theme.breakpoints.down("sm")]: {
      height: 'auto'
    },
    '& div.MuiGrid-root': {
      width: '100%',
      maxHeight: '100%',
      flexDirection: 'column',
      margin: 0,
      padding: 0,
      flexWrap: 'nowrap',
      '& button:first-child': {        
        borderTop: "1px solid #DDDDDD",
      },
      '& div.skeleton-wrapper': {
        maxWidth: '100%',
        padding: 8
      }
    }
  },
  boxInfo: {
    width: "30px",
    height: "30px",
    backgroundColor: "#666",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginRight: "10px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }, 
}));
