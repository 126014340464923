export const Types = {
  SET_SELECTED_ASSET: "asset/SET_SELECTED_ASSET",
  REMOVE_SELECTED_ASSET: "asset/REMOVE_SELECTED_ASSET",
  CLEAR_PATHNAME: "asset/CLEAR_PATHNAME",
  SET_ALL_DATA: "asset/SET_ALL_DATA"
}

const INITIAL_STATE = {
  data: [],
  pathname: ''
}

export default function assetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_SELECTED_ASSET:
      if (!state.data.some(i => i.id === action.payload.id)) {
        action.payload.tags = []
        return { ...state, data: [...state.data, action.payload] }
      }
      return state
    case Types.REMOVE_SELECTED_ASSET:
      return { ...state, data: state.data.filter(i => i.id !== action.payload.id) }
    case Types.CLEAR_PATHNAME:
      return { ...state, pathname: ''}
    case Types.SET_ALL_DATA:
      return { ...state, data: action.payload}

    default:
      return state
  }

}

export const Creators = {
  setSelectedAsset: (payload) => ({
    type: Types.SET_SELECTED_ASSET,
    payload,
  }),
  removeSelectedAsset: (payload) => ({
    type: Types.REMOVE_SELECTED_ASSET,
    payload,
  }),
  clearPathname: () => ({
    type: Types.CLEAR_PATHNAME
  }),
  setAllData: (payload) => ({
    type: Types.SET_ALL_DATA,
    payload
  }),
}
