import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import PropTypes from 'prop-types'
import {
  Typography,
  Grid,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Switch
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import _ from "lodash";

import PaperTitle from "../PaperTitle";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

import Skeleton from '@material-ui/lab/Skeleton'
import { useStyles } from "./styles"

import RecipesDownloadsBtns from 'components/RecipesDownloadsBtns'
import { useDataAssetIntegra } from "../context"
import Utils from "utils/Utils"

const DownloadItem = ({ versionIndex }) => {
  const [state, setState] = useState({
    step: 0, loading: true, alternativeNames: [], checkedData: false, alternativeName: "", include_metadata: false
  })

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation();
  const { pathServer, requestHeaders } = useSelector(
    (state) => state.appReducer
  );
  const { stateItem: { data, hasHighLow } } = useDataAssetIntegra()
  const classes = useStyles()

  const getData = useCallback((id_category) => {
    axios.get(`${pathServer}/alternative-name/listByCategory/${id_category}`, requestHeaders)
      .then((result) => {
        const data = _.get(result, 'data.items', [])
        setState(prev => ({ ...prev, loading: false, alternativeNames: data, checkedData: true }))
      })
      .catch((err) => {
        const message = Utils.ajaxErrorGetMessage(err, t, t("manager:Erro inesperado. Contate o suporte"))
        setState(prev => ({ ...prev, loading: false, checkedData: true }),
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }))
      })
  }, [pathServer, requestHeaders, enqueueSnackbar, t])

  useEffect(() => {
    let isMounted = true
    if (isMounted && !state.checkedData) {
      getData(data.category_id)
    }
    return () => { isMounted = false }
  }, [pathServer, requestHeaders, enqueueSnackbar, t, getData, data, state.checkedData])

  const handleNameSelect = (evt) => {
    setState(prev => ({ ...prev, alternativeName: _.get(evt, 'target.value', '') }))
  }

  const handleStep = (value = 0) => {
    setState(prev => ({ ...prev, step: value }))
  }

  const onChangeIncludeMetadata = (value) => {
    setState(prev => ({ ...prev, include_metadata: value.target.checked }))
  }

  return (
    <>
      <PaperTitle title={t("common:Download do item")} icon={GetAppRoundedIcon} />
      <div style={{ padding: 20, color: "#666" }}>
        <Typography variant="body1" style={{ fontWeight: 500, marginBottom: 10 }}>
          {t("common:Opções de download")}
        </Typography>
        <Typography variant="body2">
          {t("common:O ativo que não possuir suporte para as especificações será mantido na versão original.")}
        </Typography>
      </div>
      {
        state.loading ? (
          <>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton variant="rect" width={288} height={40} />
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton variant="rect" width={288} height={40} />
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Skeleton variant="rect" width={288} height={40} />
            </div>
            <Divider />
          </>
        ) : (
          <>
            <Grid style={{ padding: "0px 20px 20px", color: "#666" }}>
              <Typography style={{ fontWeight: 500 }} variant="body1">
                {state.step === 0 ? t("common:Etapa 01 de 02.") : t("common:Etapa 02 de 02.")}
              </Typography>
              <Typography variant="body2">
                {state.step === 0 ? t("common:Escolha o nome para download.") : t("common:Escolha o tipo de download.")}
              </Typography>
            </Grid>
            {
              state.step === 0 ? (
                <>
                  <Grid style={{ padding: "0px 20px 20px", color: "#666" }}>
                    <Typography variant='body1' style={{ margin: 0 }}>
                      {t("common:Informações de metadados")}:
                    </Typography>
                    <Grid style={{ width: '100%' }}>
                      <FormControl component="fieldset">
                        <Switch
                          checked={_.get(state, 'include_metadata', false)}
                          onChange={onChangeIncludeMetadata}
                          color="primary"
                          name="include_metadata"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={_.get(state, 'alternativeName', '')} onChange={handleNameSelect}>
                      <Divider />
                      <FormControlLabel value="" control={<Radio color='primary' />} label={t('manager:Taxonomia do item')} style={{ margin: 0 }} />
                      <Divider />
                      {_.get(state, 'alternativeNames', []).map((i) =>
                        <div key={i.id}>
                          <FormControlLabel value={i.id} control={<Radio color='primary' />} label={i.title} style={{ margin: 0 }} />
                          <Divider />
                        </div>
                      )}
                    </RadioGroup>
                  </FormControl>

                  <div className={classes.btnContainer}>
                    <Button variant="contained" color="primary" onClick={() => handleStep(1)} disableElevation>
                      {t("common:Avançar")}
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.wrapperButttons}>
                    <RecipesDownloadsBtns
                      path={`item/${data.id}/listrecipes`}
                      data={{
                        item_id: _.get(data, "id", ''),
                        versionIndex: versionIndex,
                        date_end: _.get(data, "validity_date", ''),
                        alternativename_id: _.get(state, 'alternativeName', ''),
                        hasHighLow: hasHighLow,
                        include_metadata: _.get(state, 'include_metadata', false),
                        options: [
                          {
                            id: 1,
                            title: 'Ativos originais',
                            params: {
                              items: [{ id: _.get(data, "id", ''), version: versionIndex }],
                              type: 'download',
                              ...(_.get(state, 'include_metadata', false) && { include_metadata: true })
                            },
                            hasHighLow: true,
                          },
                          {
                            id: 2,
                            title: 'Imagens web em alta',
                            params: {
                              items: [{ id: _.get(data, "id", ''), version: versionIndex }],
                              type: 'path_clone',
                              ...(_.get(state, 'include_metadata', false) && { include_metadata: true })
                            },
                            can_use: true,
                            hasHighLow: hasHighLow,
                          },
                          {
                            id: 3,
                            title: 'Imagens web em baixa',
                            params: {
                              items: [{ id: _.get(data, "id", ''), version: versionIndex }],
                              type: 'path_low',
                              ...(_.get(state, 'include_metadata', false) && { include_metadata: true })
                            },
                            hasHighLow: hasHighLow,
                          }
                        ]
                      }}
                    />
                  </div>

                  <div className={classes.btnContainer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ minWidth: 80 }}
                      onClick={() => handleStep(0)}
                    >
                      {t("common:Voltar")}
                    </Button>
                  </div>
                </>
              )
            }
          </>
        )
      }
    </>
  );
};

DownloadItem.defaultProps = {
  data: {
    name: '',
    taxonomy: {
      name: ''
    },
    versions: [{ assets: [] }]
  },
  versionIndex: 0
};

DownloadItem.propTypes = {
  data: PropTypes.object,
  versionIndex: PropTypes.number.isRequired
}

export default DownloadItem;
