import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({   
    contentCard_list: {      
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 90,
      paddingTop: 136
  }
}))