import React from 'react'
import _ from 'lodash'
import ListDefault from "../Core/List/DataGridCustom"
import ListWrapper from '../Core/List/ListWrapper'
import {
  getColumnDelete,
  getColumnEdit,
  getColumnThumbnail,
  getColumnDetails,
} from "../Core/utils"
import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "../../../utils/Flux"
import { withTranslation } from "react-i18next"

class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
    }
    this.superRender = super.render
  }

  getLine(item) {
    let thumbnail = _.get(item, "urls.thumbnail.original")
    let line = {
      id: item.id,
      thumbnail: { id: item.id, thumbnail: thumbnail },
      name: item.name,
      division: item.division.name,
    }
    return line
  }

  getColumns() {
    const { t } = this.props

    const thumb = getColumnThumbnail((id) => {
      this.props.history.push(`/manager/${this.state.route}/upload/${id}`)
    }, t)

    let columns = [
      getColumnDetails("name", t("common:Nome")),
      getColumnDetails("division", t("common:Divisão"), 220),
    ]

    columns.unshift(thumb)

    columns.push(
      getColumnEdit((id) => {
        this.props.history.push(`/manager/${this.state.route}/edit/${id}`)
      }, t)
    )

    columns.push(
      getColumnDelete((id, name) => {
        this.setState({
          ...this.state,
          deleteDialogOpen: true,
          idToDelete: id,
          nameToDelete: name,
        })
      }, t)
    )

    return columns
  }
  render() {
    const { hasFab, area, title, route } = this.state

    return (
      <ListWrapper
        area={area}
        title={title.singular}
        route={route}
        hasFab={hasFab}
      >
        {this.superRender()}
      </ListWrapper>
    )
  }
}

export default connect(
  mapStateToPropsToSettings,
  null
)(withTranslation(["manager", "common"])(List))
