import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import InputPermissions from "./InputPermissions"
import { Paper } from "@material-ui/core"
import { withTranslation } from "react-i18next"
import SettingsIcon from "@material-ui/icons/Settings"
import Table from "@material-ui/core/Table"

import TableBody from "@material-ui/core/TableBody"

import _ from "lodash"

import BlockRoundedIcon from '@material-ui/icons/BlockRounded';

import { RowTablePermissions, HeaderTablePermissions } from './RowTablePermissions'

const styles = (theme) => ({
  root: {
    width: "100%",
    "& .MuiBreadcrumbs-separator": {
      margin: 3,
    },
  },
  input: {
    marginTop: theme.spacing(2),
  },
  column: {
    flexBasis: "33.33%",
    padding: theme.spacing(1),
  },
  details: {
    marginTop: theme.spacing(2),
  },
  lineDetails: {
    padding: theme.spacing(1.5),
  },
  headerTableRegion: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "4px 4px 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconCheck: {
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
})


class BoxPermissions extends React.Component {

  render() {
    const {
      classes,
      structures,
      addPermission,
      removePermission,
      handleChangePolicy,
      t,
    } = this.props

    const getDataFormate = (region) => {
      const { permissions, name, thumb } = structures[region]

      const data = permissions.map((i)=> {
        const array = Object.keys(i.policies).map(function (chave) {
          return { name: i.entity ? 'Gerenciar': t(`common:${chave}`), value: i.policies[chave] }
        })

        const data_allow = array.map(item => {
          if (i.allow.findIndex(p => p === item.value) !== -1) {
            return item.value
          }
          return null
        })
       
        return {
          ...i,
          id: i.id || i.entity,
          policies: array,
          allow: data_allow,
          headers_name: array.map(a => a.name),
        }
      })

      return ({
        region_name: name || '',
        region_slug: region,
        data: _.groupBy(data, 'headers_name'),
        thumb: thumb || ''
      })
    }


    return (
      <div className={classes.root}>
        <div className="form-field">
          <label className='section'>{t("common:Permissões")}</label>
        </div>
        <div className={classes.input}>
          <InputPermissions
            addPermission={(item) => addPermission(item)}
          />
        </div>

        {
          structures &&
          Object.keys(structures).map((region) => {
            const dataTable = getDataFormate(region)
            const isEmpty = Object.keys(dataTable.data).length === 0

            if(isEmpty){
              return null
            }

            return (
              <Paper className={classes.details} key={region}>
                <div className={classes.headerTableRegion}>
                  <Typography variant="body2" style={{color: '#fff'}}>
                    {
                      dataTable.region_name
                        ? `${t("common:Escopo de acesso na região")} ${dataTable.region_name}`
                        : t(`common:Escopo de acesso do gerenciador`)
                    }
                  </Typography>
                  {
                    !dataTable.region_name
                      ? (<SettingsIcon />)
                      : (
                        <>
                          {
                            _.get(dataTable, `thumb`) !== null ? (
                              <img
                                src={_.get(dataTable, `thumb`)}
                                alt={dataTable.region_name}
                                width="30"
                                height="30"
                                style={{ borderRadius: "50%" }}
                              />
                            ) : (
                              <BlockRoundedIcon />
                            )
                          }
                        </>
                      )
                  }
                </div>
                <div style={{ maxHeight: 350, overflow: "auto" }}>
                  {
                    Object.keys(dataTable.data).map((key) => {
                      return (
                        <Table size="small" stickyHeader key={key}>

                          <HeaderTablePermissions rowsData={key.split(',')} />

                          <TableBody>
                            {
                              dataTable.data[key].map((rowsData, key) => (
                                <RowTablePermissions
                                  key={key}
                                  rowData={rowsData}
                                  classes={classes}
                                  handleChangePolicy={(rule, id) =>
                                    handleChangePolicy(dataTable.region_slug, rule, id)
                                  }
                                  removePermission={(id) =>
                                    removePermission(dataTable.region_slug, id)
                                  }
                                />
                              ))
                            }
                          </TableBody>
                        </Table>
                      )
                    })
                  }
                </div>
              </Paper>

            )
          })
        }
      </div>
    )
  }
}

BoxPermissions.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(
  withTranslation(["manager", "common"])(BoxPermissions)
)
