import React from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Breadcrumbs as BreadcrumbsUI,
  Avatar,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import CloseIcon from "@material-ui/icons/Close"
import _ from "lodash"
import Skeleton from "@material-ui/lab/Skeleton"
import { useStyles } from "./styles"
import { useSelector, useDispatch } from "react-redux"
import { redirectUserHistory } from 'utils/Helpers'
import AppActions from "flux/actions/AppActions"
import { useDataAssetIntegra } from "../context"

const HeaderBreadcrumbs = () => {
  const { stateItem } = useDataAssetIntegra()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const dataLinks = stateItem.dataHeader.filter((item) => _.get(item, "url", false))
  const dataTaxonomy = stateItem.dataHeader.find((item) => !item.hasOwnProperty("url"))
  const {
    pathClient,
    requestHeaders,
    userData: { region },
    navigation_view_mode
  } = useSelector((state) => state.appReducer)

  const redirectHistory = () => {

    const changeRegion = (link) => {
      dispatch(AppActions.changeRegion(
        link.region, { requestHeaders, pathClient }, () => history.push(link.url)
      ))
    }

    redirectUserHistory(region, history.push, changeRegion)
  }

  const isModeItemsView = navigation_view_mode === 'view-items'

  return (
    <>
      {
        stateItem.loading ? (
          <Paper elevation={2} className={classes.root}>
            <Skeleton variant="rect" width={60} height={60} />
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <div className={classes.wrapperSkeleton}>
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="70%" style={{ marginTop: 5 }} />
              </div>
            </div>
            <div className={classes.divIconClose}>
              <Skeleton variant="rect" width={35} height={35} />
            </div>
          </Paper>
        ) : (
          <Paper elevation={2} className={classes.root}>
            <Avatar
              alt="Logo Cliente"
              src={localStorage.getItem("logoInsideJPG")}
              onClick={() => history.push("/")}
              style={{
                cursor: "pointer",
                marginRight: 20,
                width: 60,
                height: 60,
              }}
            />
            <div className="content-label">
              <BreadcrumbsUI
                separator={<NavigateNextIcon id="dot" fontSize="small" />}
                aria-label="breadcrumb"
                className="breadcrumb"
              >
                {dataLinks.length > 0 && (
                  dataLinks.map((item, key) => {
                    if (isModeItemsView) {
                      return (
                        <span
                          key={key}
                          style={{ fontWeight: 500, color: '#fff' }}
                          data-testid="not-link"
                        >
                          {item.label}
                        </span>
                      )
                    } else {
                      return <Link to={item.url} key={key}>
                        {item.label}
                      </Link>
                    }
                  })
                )}
              </BreadcrumbsUI>

              {
                _.get(dataTaxonomy, "label", false) &&
                <div className="divTaxonomy">
                  <Typography
                    variant="h5"
                    style={{ lineHeight: 1.2, color: "#ffffff", fontWeight: 300 }}
                  >
                    {dataTaxonomy.label}
                  </Typography>
                </div>
              }

            </div>
            <Tooltip title="Voltar" placement="top">
              <div className={classes.divIconClose}>
                <IconButton color="inherit" onClick={redirectHistory}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Paper>
        )
      }
    </>
  )
}

export default HeaderBreadcrumbs
