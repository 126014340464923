import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  mediaContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      "& div#imageHero": {
        width: "100vw !important",
      },
    },
  },
}))
