import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    groupButton: {
        display: 'flex',
         justifyContent: 'flex-start',
        '& .MuiButton-root': {
            color: '#666'
        },
        '& .MuiButton-textPrimary': {
            color: `${theme.palette.primary.main} !important`
        },
        '& button:first-child': {
            paddingLeft: 0
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center'            
        }
    },
    labelDescription: {
        top: "12px",
        position: "relative",
        background: "#fff",
        display: 'inline-block',
        left: "11px",
        padding: "0 3px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.00938em',
        zIndex: 1
    },
    formField: {
        width: '100%',
        position: 'relative',
        color: '#666',        
    },
    infoLength: {
        display: 'flex',
        justifyContent: 'flex-end',
        "& p": {
            color: '#666',
            margin: '8px 0 0 0'
        }
    },
    wrapperRichText:{
        minHeight: "200px",
        border: "1px solid #bababa",
        borderRadius: "4px",
      },
}))