import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next";
import _ from 'lodash'
import {
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogTitle,
  Button,
  Grid,
  FormControl,
  Box,
  TextField,
  Switch
} from "@material-ui/core";

import ShareRoundedIcon from "@material-ui/icons/ShareRounded";
import ListSendEmail from "./ListSendEmail";
import LinkShared from 'components/LinkShared'
import { useStyles } from "../styles";
import ListDownloadsbyitems from "../BulkDownload/ListDownloadsbyitems";
import { Creators as ItemsActions } from "flux/ducks/Items"
import { getDefaultName } from '../utils'

const INITIAL_STATE = {
  displaySharingOption: false,
  mustZipAssetsWithoutFolders: 'default',
  alternativeName: { id: 'item-taxonomy' },
  shareExpirationTime: "24",
  wayToShare: "",
  inputName: "",
  inputNameError: "",
  recipeName: { id: 'original-assets', type: 'download' },
  include_metadata: false
}

const SharedItemsDialog = ({ handleSendEmails, children, handleSetOpen, dataAsset, markedItemsToShare, openDefault, pathDefault, paramsToItems, dataItems }) => {
  const [state, setState] = useState({ open: openDefault, ...INITIAL_STATE })
  const { data: { client_slug }, userData: { region } } = useSelector((state) => state.appReducer)
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const show = () => (event) => {
    event.preventDefault();
    setState(prev => ({
      ...prev, open: event.currentTarget
    }), handleSetOpen(true))
  };

  const hide = useCallback(() => {
    dispatch(ItemsActions.setOpenDialogItemsMarked({ open: '' }))
    setState({ open: null, ...INITIAL_STATE }, handleSetOpen(false))
  }, [handleSetOpen, dispatch])

  const handleCheck = useCallback((event) => {
    const value = _.get(event, 'target.value', '')
    const name = _.get(event, 'target.name', '')
    setState(prev => ({ ...prev, [name]: value }))
  }, [])

  const handleDisplaySharingOption = useCallback((value) => {
    setState(prev => ({ ...prev, displaySharingOption: value }))
  }, [])

  const getDataCategory = useCallback(() => {
    let data = []
    if (dataItems) {
      Object.entries(dataItems.items).forEach(([key, value]) => {
        if (!data.some(i => i === value.category_slug) && Boolean(value.category_slug)) {
          data.push(value.category_slug)
        }
      })
    }
    return data
  }, [dataItems])

  const onChangeOption = useCallback((field, value) => {
    setState(prev => ({
      ...prev, [field]: {
        id: _.get(value, 'id', ''),
        ...(_.get(value, 'type', '') && { type: value.type })
      }
    }))
  }, [])

  const handleChangeInput = useCallback((value) => {
    if (value.length > 30) {
      setState(prev => ({ ...prev, inputNameError: true }))
    } else {
      setState(prev => ({ ...prev, inputNameError: false, inputName: value }))
    }
  }, [])

  const onChangeIncludeMetadata = useCallback((value) => {
    setState(prev => ({ ...prev, include_metadata: value.target.checked }))
  }, [])

  const constants = useMemo(() => ({
    category_slug: getDataCategory(),
    defaultName: getDefaultName(client_slug, region)
  }), [getDataCategory, client_slug, region])

  return (
    <Fragment>
      {children(show)}
      {Boolean(state.open) && (
        <Dialog
          open={Boolean(state.open)}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
          maxWidth='lg'
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            <Box className="info">
              <ShareRoundedIcon id="main" />
              <span>
                {t("common:Compartilhar")}
              </span>
            </Box>
          </DialogTitle>

          <Box className={classes.details}>
            {!state.displaySharingOption ? (
              <>
                <Grid container spacing={6}>
                  <Grid item md={3} xs={12} style={{ borderRight: '1px solid #e0e0e0' }}>
                    <ListDownloadsbyitems
                      data_slugs={[..._.get(paramsToItems, 'category_slug', []), ...constants.category_slug]}
                      path='items/listalternativenamesbyitems'
                      title={t("common:Nome do item")}
                      option={state.alternativeName.id}
                      optionFieldName='alternativeName'
                      onChangeOption={onChangeOption}
                      opstionsDefault={[
                        { id: 'item-taxonomy', title: t('manager:Taxonomia do item'), can_use: true, hasHighLow: true }
                      ]}
                      alternativesNames
                    />
                  </Grid>
                  <Grid item md={3} xs={12} style={{ borderRight: '1px solid #e0e0e0' }}>
                    <ListDownloadsbyitems
                      data_slugs={[..._.get(paramsToItems, 'category_slug', []), ...constants.category_slug]}
                      path='items/listrecipesbyitems'
                      title={t("common:Selecione o formato")}
                      option={state.recipeName.id}
                      optionFieldName='recipeName'
                      onChangeOption={onChangeOption}
                      opstionsDefault={[
                        {
                          id: 'original-assets',
                          title: t("common:Ativos originais"),
                          type: 'download',
                          can_use: true,
                          hasHighLow: true,
                        },
                        {
                          id: 'high-quality-web-images',
                          title: t("common:Imagens da web em alta"),
                          type: 'path_clone',
                          can_use: true,
                          hasHighLow: true,
                        },
                        {
                          id: 'downloadable-web-images',
                          title: t("common:Imagens da web em baixa"),
                          type: 'path_low',
                          can_use: true,
                          hasHighLow: true,
                        }
                      ]}
                    />
                  </Grid>
                  <Grid item md={3} xs={12} style={{ borderRight: '1px solid #e0e0e0' }}>
                    <Grid>
                      <Typography variant='body1' style={{ color: "#666" }}>
                        {t('common:Como você deseja compartilhar?')}
                      </Typography>
                      <Grid style={{ width: '100%', maxHeight: 460, overflow: 'auto' }}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="wayToShare"
                            name="wayToShare"
                            onChange={(event) => handleCheck(event)}
                            value={state.wayToShare}
                          >
                            <FormControlLabel
                              value='link'
                              control={<Radio color="primary" />}
                              label={t('common:Compartilhar por link')}
                              style={{ color: "#666" }}
                            />
                            <FormControlLabel
                              value='email'
                              control={<Radio color="primary" />}
                              label={t('common:Compartilhar por e-mail')}
                              style={{ color: "#666" }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 20 }}>
                      <Typography variant='body1' style={{ color: "#666" }}>
                        {t('common:Tempo de expiração')}:
                      </Typography>
                      <Grid style={{ width: '100%', maxHeight: 460, overflow: 'auto' }}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="shareExpirationTime"
                            name="shareExpirationTime"
                            onChange={(event) => handleCheck(event)}
                            value={state.shareExpirationTime}
                          >
                            <FormControlLabel
                              control={<Radio color="primary" />}
                              label={t('common:24 horas')}
                              style={{ color: "#666" }}
                              value="24"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" />}
                              label={t('common:48 horas')}
                              style={{ color: "#666" }}
                              value="48"
                            />
                            <FormControlLabel
                              control={<Radio color="primary" />}
                              label={t('common:72 horas')}
                              style={{ color: "#666" }}
                              value="72"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <Grid>
                      <Typography variant='body1' style={{ color: "#666" }}>
                        {t("common:Defina seu ZIP")}:
                      </Typography>

                      <Grid style={{ width: '100%' }}>
                        <FormControl component="fieldset">
                          <TextField
                            label={t("common:Nome")}
                            variant="outlined"
                            placeholder={constants.defaultName}
                            value={state.inputName}
                            onChange={evt => handleChangeInput(_.get(evt, 'target.value', ''))}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={state.inputNameError}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 20 }}>
                      <Typography variant='body1' style={{ margin: 0 }}>
                        {t("common:Itens dentro do ZIP")}:
                      </Typography>
                      <Grid style={{ width: '100%' }}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="mustZipAssetsWithoutFolders"
                            name="mustZipAssetsWithoutFolders"
                            onChange={(event) => handleCheck(event)}
                            value={state.mustZipAssetsWithoutFolders}
                          >
                            <FormControlLabel
                              value="default"
                              control={<Radio color='primary' />}
                              label={t(`common:Cada Item em uma pasta`)}
                              style={{ color: "#666" }}
                            />
                            <FormControlLabel
                              value="mustZipAssetsWithoutFolders"
                              control={<Radio color='primary' />}
                              label={t(`common:Todos os Itens em uma única pasta`)}
                              style={{ color: "#666" }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 20 }}>
                      <Typography variant='body1' style={{ margin: 0 }}>
                        {t("common:Informações de metadados")}:
                      </Typography>
                      <Grid style={{ width: '100%' }}>
                        <FormControl component="fieldset">
                          <Switch
                            checked={state.include_metadata}
                            onChange={onChangeIncludeMetadata}
                            color="primary"
                            name="include_metadata"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Box className={classes.actions}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={hide}
                    style={{ height: 40 }}
                  >
                    {t("common:Cancelar")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleDisplaySharingOption(true)}
                    disabled={(!state.wayToShare || state.inputNameError)}
                    style={{ height: 40, marginLeft: 10 }}
                  >
                    {t("common:Avançar")}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                {
                  state.wayToShare === "link" && (
                    <LinkShared
                      isItens={_.get(dataAsset, 'isItens', true)}
                      type={_.get(dataAsset, 'type', 'item')}
                      idItem={_.get(dataAsset, 'idItem', '')}
                      idAsset={_.get(dataAsset, 'idAsset', '')}
                      copyLink={_.get(dataAsset, 'copyLink', false)}
                      handleStepBack={() => handleDisplaySharingOption(false)}
                      handleClose={hide}
                      mustZipAssetsWithoutFolders={state.mustZipAssetsWithoutFolders !== "default"}
                      alternativeNameId={state.alternativeName.id}
                      pathDefault={pathDefault}
                      paramsToItems={paramsToItems}
                      shareExpirationTime={state.shareExpirationTime}
                      name={Boolean(state.inputName.trim()) ? state.inputName : constants.defaultName}
                      typeListrecipe={state.recipeName.type}
                      includeMetadata={state.include_metadata}
                    />
                  )
                }
                {
                  state.wayToShare === "email" && (
                    <ListSendEmail
                      handleSendEmails={handleSendEmails}
                      handleClose={hide}
                      dataAsset={dataAsset}
                      handleStepBack={() => handleDisplaySharingOption(false)}
                      mustZipAssetsWithoutFolders={state.mustZipAssetsWithoutFolders !== "default"}
                      alternativeNameId={state.alternativeName.id}
                      pathDefault={pathDefault}
                      paramsToItems={paramsToItems}
                      shareExpirationTime={state.shareExpirationTime}
                      name={Boolean(state.inputName.trim()) ? state.inputName : constants.defaultName}
                      typeListrecipe={state.recipeName.type}
                      includeMetadata={state.include_metadata}
                    />
                  )
                }
              </>
            )}
          </Box>
        </Dialog>
      )}

    </Fragment>
  );
};

SharedItemsDialog.defaultProps = {
  handleSetOpen: () => { },
  pathDefault: null,
  paramsToItems: {},
  openDefault: false,
  handleSendEmails: () => { },
  dataAsset: {}
}

export default SharedItemsDialog
