import React, { useCallback } from 'react'
import { Calendar } from 'react-date-range'
import i18n from "i18next"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'

import {
    Button,
    IconButton,
    Menu,
    OutlinedInput,
    FormControl,
    InputLabel,
    InputAdornment,
    FormHelperText,
} from '@material-ui/core'

import * as locales from 'react-date-range/dist/locale'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded'

import Utils from 'utils/Utils'

export default function InputDatePicker({
    title = '',
    isDisabled = false,
    error = false,
    errorMsg = '',
    onChange,
    defaultValue = null
}) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [dateStart, setDateStart] = React.useState(defaultValue)    
    const { primaryColor } = useSelector((state) => state.appReducer.data)
    const { t } = useTranslation()

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [])

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [])

    const handleChangeDate = useCallback((value) => {
        setDateStart(value)
        onChange({ target: { value: value } })
        handleClose()
    }, [onChange, handleClose])
   
    return (
        <>
            <FormControl variant="outlined">
                <InputLabel
                    htmlFor="outlined-adornment-calendar"
                    style={{ color: error ? 'rgb(244, 67, 54)' : 'inherit', backgroundColor: '#fff', padding: '0 5px' }}
                >
                    {title}
                </InputLabel>
                <OutlinedInput
                    disabled={isDisabled}
                    id="outlined-adornment-calendar"
                    type='text'
                    readOnly
                    value={dateStart ? Utils.formatDate(dateStart) : t('DAM:Selecione uma data')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle calendar visibility"
                                onClick={handleClick}
                                edge="end"
                                disabled={isDisabled}
                            >
                                <CalendarTodayRoundedIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    error={error}
                />
                {
                    error &&
                    <FormHelperText style={{ color: 'rgb(244, 67, 54)', marginLeft: 11 }}>
                        {errorMsg}
                    </FormHelperText>
                }
            </FormControl>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Calendar
                    onChange={item => handleChangeDate(item)}
                    locale={locales[i18n.language]}
                    date={dateStart}
                    color={primaryColor}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 10 }}>
                    <Button color='primary' onClick={() => setDateStart(null)}>
                        {t('DAM:Limpar')}
                    </Button>                  
                </div>
            </Menu>
        </>

    );
}