import React, { useState } from "react"
import _ from 'lodash'
import { useStyles } from "./styles"
import { Fade, Chip, useMediaQuery } from "@material-ui/core"
import ButtonsActions from "../ButtonsActions"

import { mdiVideo } from "@mdi/js"
import Icon from "@mdi/react"
import Utils from 'utils/Utils'
import pdfBox from 'imagens/ui/pdf-box.svg'

function Slide({ media, data, handleComponentArea }) {
  const [viewActions, setViewActions] = useState(false)
  const classes = useStyles()  
  const isMobile = useMediaQuery('(max-width:600px)')

  const hasAction = _.get(data, 'asset.extension', '') === 'pdf' 
                    || Utils.enabledExtensionsVideos(_.get(data, 'asset.extension', ''))
                    || _.get(data, 'is3DTexture', false)

  const propsDivAction = (hasAction || isMobile) ? {
    onMouseOver: () => setViewActions(true),
    onMouseOut: () => setViewActions(false)
  } : {}

  return (
    <div
      {...propsDivAction}
      className={classes.mediaRoot}
    >
      <div className={classes.mediaContent}>
        {
          data.asset.tags && (
            <div className={classes.rootTags}>
              <div className='content'>
              {Utils.sortedList(data.asset.tags).map((i, index) =>
              <Chip
                label={i}
                key={index}
                color="primary"
                style={{margin: '15px 20px 0 0', borderRadius: 4}}
              />)}
              </div>
            </div>
          )
        }
        {
          data.extension === 'pdf' && (
            <div style={{position: 'absolute', bottom: 25, right: 25}}>
               <img src={pdfBox} width={30} alt='PDF icon' />
            </div>
          )
        }
        
        {Utils.enabledExtensionsVideos(data.extension) &&
          <div style={{position: 'absolute', bottom: 15, right: 20}}>
            <Icon path={mdiVideo} size={2} color="#666" />
          </div>
        }

        {media}

        <Fade in={viewActions}>
          <div className={classes.rootActions}>
            <div>
            <ButtonsActions
              data={{
                ...data,
                hasHighLow: data.asset.hasHighLow,
              }}
              handleComponentArea={handleComponentArea}
            />
            </div>
          </div>
        </Fade>

      </div>
    </div>
  )
}

export default Slide
