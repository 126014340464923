import React, { useEffect } from 'react';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import { useDataItems } from '../context';

import { Grid } from '@material-ui/core'

import ListVisualization from './ListVisualization';
import ListSkeleton from './ListSkeleton';
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"

import { useStyles } from "./styles"

const ContentList = () => {
    const {
        loading,
        getRenewedData,
        getParamsToItems,
        dataParams,
        checkedData,
        dataItems
    } = useDataItems()

    const classes = useStyles()
    const { t } = useTranslation()

    useEffect(() => {
        let isMounted = true
        if (!checkedData && !loading) {
            if (isMounted) {
                const paramsToItems = getParamsToItems()
                getRenewedData(paramsToItems)
            }
        }

        return () => {
            isMounted = false
        }
    }, [getRenewedData, checkedData, loading, getParamsToItems])

    return (
        <Grid container className={classes.contentCard_list}>
            <Grid item xs={12}>
                {
                    loading ? <ListSkeleton /> : (
                        <>
                            {
                                dataItems.length > 0 &&
                                <ListVisualization data={dataItems} />
                            }
                            {
                                dataItems.length === 0 && !loading && (
                                    <SimpleAlertMessage
                                        severity="info"
                                        alertTitle="Ops!"
                                        message={
                                            _.get(dataParams, 'searchText', false)
                                                ? t(
                                                    "common:Não encontramos o que você procura. Que tal uma nova busca?"
                                                )
                                                : t("common:Não há itens a serem mostrados neste filtro.")
                                        }
                                    />
                                )
                            }
                        </>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default ContentList
