import React, { useMemo } from "react"
import filesize from "filesize"
import _ from "lodash"

import { Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"

const Details = ({ asset = {} }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const constants = useMemo(() => {
    let data = [
      { title: 'common:Nome', value: _.get(asset, 'client_name', '') },
      { title: 'manager:Extensão', value: _.get(asset, 'extension', '') },
      { title: 'common:Tamanho', value: filesize(_.get(asset, 'bytes', 0)) },
    ]
    if(_.get(asset, 'dimensions', null)) {
      data.push({ title: 'common:Dimensões',
        value: _.get(asset, 'dimensions.x', 0) + "x" + _.get(asset, 'dimensions.y', 0)
      })
    }
    return {data}
  }, [asset])

  return (
    <div className={classes.paperWrapper}>
      {constants.data.length > 0 &&
        constants.data.map((item, index) => (
          <div className={classes.rowWrapper} key={index}>
            <Typography component="span" variant="body2" id="title">
              {t(item.title)}
            </Typography>
            <Typography component="span" variant="body2" id="info">
              {item.value}
            </Typography>
          </div>
        ))}
    </div>
  )
}

export default Details
