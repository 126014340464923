import React, { useEffect, useState, useCallback } from 'react'
import axios from "axios"
import _ from 'lodash'

import { useSelector } from 'react-redux'

import { IconButton, Tooltip } from "@material-ui/core"

const ItemComponent = ({
  url = '',
  title = '',
  component_name = '',
  handleComponentName,
  icon: Icon,
  field_data = '',
  isDisabled = false,
}) => {
  const [state, setState] = useState({ hasData: false, checked: false })
  const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)

  const getData = useCallback(async () => {
    let resultData = { hasData: false }
    try {
      const { data: result } = await axios.get(`${pathServer}/${url}`, requestHeaders)

      if (Boolean(field_data)) {
        if (_.get(result, field_data, []).length > 0) {
          resultData.hasData = true
        }
        if (Array.isArray(result) && result.length > 0) {
          resultData.hasData = true
        }
      } else {
        const DATA_LINKS = [..._.get(result, 'item.links', []), ..._.get(result, 'item.linked_to', [])]
        const removeDuplicateLinks = DATA_LINKS.filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
        }, Object.create(null))

        if (removeDuplicateLinks.length > 0) {          
          resultData.hasData = true
        }
      }
    } catch (err) {
      resultData.hasData = false
    }

    setState(prev => ({ ...prev, ...resultData, checked: true }))
  }, [pathServer, requestHeaders, url, field_data])


  useEffect(() => {
    let isMounted = true
    if (isMounted && Boolean(url) && !state.checked) getData()
    return () => {
      isMounted = false
    }
  }, [url, getData, state.checked])

  return state.hasData && (
    <Tooltip title={title} placement="left">
      <div>
        <IconButton
          color="inherit"
          onClick={() => handleComponentName(component_name)}
          disabled={isDisabled}
        >
          <Icon style={{ fill: "#fff" }} />
        </IconButton>
      </div>
    </Tooltip>
  )
}

export default ItemComponent