import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';

import _ from 'lodash'

import {
    Divider,
    Grid,
    Typography,
    TextField
} from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Translate';
import { genereteHash, getRequiredLngCombo } from 'utils/Helpers'
import Settings from 'utils/Settings'

const Names = ({
    data = {},
    formErrors = {},    
    handleChangeNames
}) => {
    const [platformData, setPlatformData] = useState({})  
    const [hasData, setHasData] = useState(false) 
    const { t } = useTranslation()
    
    useEffect(() => {
        let dataFormated = {}

        Settings.DATA_SUPPORTED_LNG.forEach((item) => {
            dataFormated = {
                ...dataFormated,
                [item.lng]: {
                    name: {
                        value: '',
                        hash: genereteHash(''),
                        hasChanged: false
                    },
                    description: {
                        value: '',
                        hash: genereteHash(''),
                        hasChanged: false
                    }
                }
            }
        })

        setPlatformData(dataFormated)
       
        return () => { }
    }, [])
    
    useEffect(() => {
        const { name, description } = data       

        if (!hasData && name && description) {
            setHasData(true)
            let dataAPI = {}

            if (typeof name === 'string' &&
                typeof description === 'string') {
                dataAPI['pt'] = {
                    name: {
                        value: name,
                        hash: genereteHash(name),
                        hasChanged: false
                    },
                    description: {
                        value: description,
                        hash: genereteHash(description),
                        hasChanged: false
                    }
                }

            } else {
                Object.entries(name).forEach(([lng, value]) => {
                    dataAPI[lng] = {
                        name: {
                            value: value,
                            hash: genereteHash(value),
                            hasChanged: false
                        }
                    }
                })
                Object.entries(description).forEach(([lng, value]) => {
                    dataAPI[lng] = {
                        ...dataAPI[lng],
                        description: {
                            value: value,
                            hash: genereteHash(value),
                            hasChanged: false
                        }
                    }
                })
            }
            
            setPlatformData(prev => ({...prev, ...dataAPI}))
        }        
    }, [data, hasData])

    const handleChange = (evt, lng, fieldName) => {
        const newData = { ...platformData }

        const fieldHash = genereteHash(evt.target.value)

        if (newData[lng][fieldName].hash !== fieldHash) {
            newData[lng][fieldName] = { ...newData[lng][fieldName], hasChanged: true };
        } else {
            newData[lng][fieldName] = { ...newData[lng][fieldName], hasChanged: false };
        }

        newData[lng][fieldName] = { ...newData[lng][fieldName], value: evt.target.value };

        handleChangeNames(newData)
        setPlatformData(newData)
    }


    const content = (dataLng, index) => {
        return (
            <Grid item xs={12} key={dataLng.lng}>
                {
                    index !== 0 &&
                    <Grid item xs={12} style={{ margin: '20px 0' }}>
                        <Divider />
                    </Grid>
                }

                <Grid item xs={12} style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 20
                }}>
                    <TranslateIcon fontSize='small' style={{ color: '#666' }} />
                    <Typography variant="body1" style={{ color: '#666', marginLeft: 10, fontWeight: 500 }}>
                        {t(`manager:${_.get(dataLng, 'short_name', '')}`)}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        type="text"
                        name="name"
                        label={index !== 0 ? t("manager:Nome do perfil") : `${t("manager:Nome do perfil")}*`}
                        variant="outlined"
                        value={_.get(platformData, `${dataLng.lng}.name.value`, '')}
                        onChange={(event) => handleChange(event, dataLng.lng, "name")}
                        error={Boolean(_.get(formErrors, `name.${dataLng.lng}`, false))}
                        helperText={_.get(formErrors, `name.${dataLng.lng}`, '')}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextField
                        type="text"
                        name="description"
                        label={index !== 0 ? t("common:Descrição") : `${t("common:Descrição")}*`}
                        variant="outlined"
                        value={_.get(platformData, `${dataLng.lng}.description.value`, '')}
                        onChange={(event) => handleChange(event, dataLng.lng, "description")}
                        error={Boolean(_.get(formErrors, `description.${dataLng.lng}`, false))}
                        helperText={_.get(formErrors, `description.${dataLng.lng}`, '')}
                        fullWidth
                        multiline
                        rows={4}
                    />                    
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            {getRequiredLngCombo().map((item, index) => content(item, index))}
        </>

    )
}

export default Names