
import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types';
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import Settings from 'utils/Settings'
import _ from 'lodash'

import { CircularProgress } from "@material-ui/core"

import { useDataEditFiles } from '../context'

import SortableListWrapper from './SortableListWrapper'
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"
import {simpleGet} from "utils/Request"

const CurrentFiles = ({
    slugItem,
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const { userData, requestHeaders, pathServer, supportsWebp, markedAssets } = useSelector(
        (state) => state.appReducer
    )
    const { t } = useTranslation()

    const {
        dataAssets,
        setDataAssets,
        setDataTags,
        hasData,
        setHasData,
        setInitialAssetsLength,
        loading,
        setLoading,
        itemIsDraft,
        setDataComment,
    } = useDataEditFiles()
    const url = `${Settings.URL_API}/api/url`

    const getUrls = useCallback((data) => {
        let dataUrls
        const dataEndpoint = data.map(i => 
            simpleGet(url, { file: supportsWebp ? i.thumbnail_card_webp : i.thumbnail_card_original }, userData)
        )
        Promise.all(dataEndpoint).then((values) => {
            try {
                dataUrls = values.map(i => i.data)

                for (var index in data) {
                    const id = data[index]['id']
                    const find = dataUrls.find(i => i.indexOf(id) !== -1)

                    if (find) {
                        data[index]['thumbnail_card_original'] = find
                        data[index]['thumbnail_card_webp'] = find
                    }
                }
                setDataAssets(data)
                setLoading(false)
            }
            catch (e) {
                setDataAssets(data)
                setLoading(false)
            }
        }).catch(() => {
            setDataAssets(data)
            setLoading(false)
        })

    }, [userData, url, setDataAssets, setLoading, supportsWebp])

    const getDataItem = useCallback((isMounted) => {
        setLoading(true)
        axios
            .post(`${pathServer}/item/by/taxonomy/${slugItem}`, { "send_notpermitted": true }, requestHeaders)
            .then((resp) => {
                if (isMounted) {
                    const tagsFormatted = resp.data.item.assets ? resp.data.item.assets.map(i => ({
                        id: i.id, value: i.tags ? i.tags.map(tag =>
                            ({ label: tag, value: tag, id: i.id })) : []
                    })) : []

                    if (typeof _.get(resp.data, 'item.versions[0].comment', false) === 'string') {
                        setDataComment(_.get(resp.data, 'item.versions[0].comment', ''))
                    }

                    setInitialAssetsLength(_.get(resp, 'data.item.assets', []).length)

                    getUrls(_.get(resp, 'data.item.assets', []))
                    setDataTags(tagsFormatted)
                    setHasData(true)
                }
            }
            )
            .catch((err) => {
                if (isMounted) {
                    setLoading(false)
                    setHasData(false)
                    enqueueSnackbar(err.response.data.message || err.response.headers["x-message"], {
                        variant: "error",
                        autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    })
                }
            });
    }, [requestHeaders, pathServer, enqueueSnackbar, slugItem, getUrls, setDataTags, setDataComment, setHasData, setInitialAssetsLength, setLoading])

    useEffect(() => {
        if (itemIsDraft && markedAssets.length > 0) {
            setHasData(true)
            setDataAssets(markedAssets)
        }

    }, [markedAssets, itemIsDraft, setDataAssets, setHasData])

    useEffect(() => {
        let isMounted = true;

        if (!hasData && !itemIsDraft) {
            getDataItem(isMounted)
        }

        return () => {
            isMounted = false;
        };
    }, [itemIsDraft, hasData, getDataItem])


    return (
        <div style={{ padding: 20, overflow: 'auto', height: "calc(100vh - 164px)" }}>
            {
                loading && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200
                    }}>
                        <CircularProgress />
                    </div>
                )
            }
            {
                dataAssets.length > 0 && !loading &&
                <SortableListWrapper />
            }
            {
                dataAssets.length === 0  && !loading && (
                    <div style={{ width: '100%' }}>
                        <SimpleAlertMessage
                            severity="info"
                            alertTitle="Ops!"
                            message={t("DAM:Não há nenhum ativo neste momento. Que tal procurar no bucket?")}
                        />
                    </div>
                )
            }
        </div>
    )
}

CurrentFiles.defaultProps = {
    slugItem: ""
}

CurrentFiles.propTypes = {
    slugItem: PropTypes.string,
}

export default CurrentFiles