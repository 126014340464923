import React, { Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import _ from 'lodash'

import { useTranslation } from 'react-i18next'

import View from './view'

import { useDataItems } from 'pages/DAM/Items/context'
import { Creators as ItemsActions } from "flux/ducks/Items"

function MenuSorting() {
    const { sorting } = useSelector((state) => state.itemsReducer)
    const indexInitial = _.get(sorting, "id", null)
    const [selectedIndex, setSelectedIndex] = React.useState(indexInitial)
    const { totalItems, getParamsToItems, getRenewedData } = useDataItems()

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const options = [
        { id: 0, title: t("DAM:Relevância"), field: "_score", direction: "desc" },
        { id: 1, title: t("DAM:Itens de A-Z"), field: "name.keyword", direction: "asc" },
        { id: 2, title: t("DAM:Itens de Z-A"), field: "name.keyword", direction: "desc" },
        { id: 3, title: t("DAM:Mais recentes"), field: "created_at", direction: "desc" },
        { id: 4, title: t("DAM:Mais antigos"), field: "created_at", direction: "asc" },
    ]

    const handleMenuItemClick = (index) => {
        setSelectedIndex(index)
        const option = options.find((el) => index === el.id)
        const order = !option.field ? null : _.pick(option, "field", "direction", "id")
        const paramsToItems = getParamsToItems()

        dispatch(ItemsActions.setSession({
            name: "sorting",
            data: order,
        }), getRenewedData({ ...paramsToItems, order: order }))

    }

    return (
        <Fragment>
            <View
                title={t("DAM:Ordenação de itens")}
                options={options}
                selectedIndex={selectedIndex}
                handleMenuItemClick={handleMenuItemClick}
                hasFilters={_.get(totalItems, "total", 0) !== 0}
            />
        </Fragment>
    )
}


export default MenuSorting
