import React, { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'
import axios from 'axios'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Helmet } from "react-helmet"
import { useSelector, useDispatch } from "react-redux"
import { Grid } from "@material-ui/core"

import Items from "pages/DAM/Items"
import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"
import ItensFilterBar from 'components/ItensFilterBar'
import Content from './Content'

import Utils from "utils/Utils"
import ItemsProvider from 'pages/DAM/Items/context'
import { Creators as ItemsActions } from "flux/ducks/Items"
import AppActions from "flux/actions/AppActions"

import { useStyles } from './styles'

const GroupingCards = ({ match, ...rest }) => {
    const [component, setComponent] = useState('list-cards')
    const [state, setState] = useState({
        loading: true,
        checked: false,
        data: [],
        params: {},
        shortcutBreadcrumbs: [{ label: '', url: '' }],
        galleryBreadcrumbs: [{ label: '', url: '' }],
        page: {
            number: 1,
            total: 0,
            last: [],
        }
    })
    const { requestHeaders, pathServer, markedItems } = useSelector((state) => state.appReducer)

    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()

    const shortcut_slug = _.get(match, 'params.shortcut_slug', '')
    const levels = _.get(match, 'params.grouped', '')
    const region = _.get(match, 'params.region', '')

    const getData = useCallback((params = {}) => {
        const parameters = {
            limit: 30,
            levels: levels,
            ...params
        }

        axios.post(`${pathServer}/item/shortcut/${shortcut_slug}`, parameters, requestHeaders)
            .then(({ data }) => {
                let breadcrumbs = []
                if (levels) {
                    breadcrumbs = Utils.getBreadcrumbsGallery(data.levels, region, shortcut_slug)
                    if (_.get(data, 'shortcuts', []).length === 0
                        && _.get(parameters, 'search_after', []).length === 0) {
                        setState(prev => ({
                            ...prev,
                            galleryBreadcrumbs: breadcrumbs,
                        }));
                        setComponent('list-items')
                    }
                }

                setState(prev => ({
                    ...prev,
                    checked: true,
                    loading: false,
                    page: {
                        number: data.page + 1,
                        total: data.total,
                        last: data.last || [],
                        ...(Boolean(params.updated) && { updated: params.updated })
                    },
                    shortcutBreadcrumbs: [
                        {
                            label: 'Atalho',
                            url: `/dam/${region}`
                        }
                    ],
                    galleryBreadcrumbs: [
                        {
                            label: t('common:Galeria'),
                            url: `/dam/${region}`
                        },
                        {
                            label: shortcut_slug,
                            url: `/gallery/${region}/${shortcut_slug}`,
                        },
                        ...breadcrumbs,
                    ],
                    ...(data.hasOwnProperty('levels') && { data: data.levels, dataLevels: data.levels }),
                    ...(data.hasOwnProperty('shortcuts') && { data: [...prev.data, ...data.shortcuts] }),
                    ...(data.hasOwnProperty('params') && { params: data.params }),
                    ...((data.page > 1 && data.shortcuts.length === 0) && { page_end: true }),
                }))

                if (_.get(data, "levels.key", false)) {
                    dispatch(ItemsActions.setSession({
                        name: "dataLevels",
                        data: data.levels,
                    }))
                }
            })
            .catch(err => {
                if (_.get(err, 'response.status', 0) === 401) {
                    setState(prev => ({ ...prev, loading: false, data: [], checked: true }),
                        dispatch(AppActions.openDialog("expiredToken"))
                    )
                } else {
                    setState(prev => ({ ...prev, loading: false, data: [] }))
                }
            })
    }, [pathServer, requestHeaders, t, dispatch, levels, region, shortcut_slug])

    const getDataUpdated = useCallback(() => {
        axios.get(`${pathServer}/item/updated/shortcut/${shortcut_slug}`, requestHeaders)
            .then(({ data }) => {
                getData({ updated: data })
            })
            .catch(err => {
                if (_.get(err, 'response.status', 0) === 401) {
                    setState(prev => ({ ...prev, loading: false, data: [], checked: true }),
                        dispatch(AppActions.openDialog("expiredToken"))
                    )
                } else {
                    setState(prev => ({ ...prev, loading: false, data: [] }))
                }
            })
    }, [pathServer, requestHeaders, dispatch, shortcut_slug, getData])

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            if (!state.checked) {
                getDataUpdated()
            }
        }
        return () => {
            isMounted = false
        }
    }, [getDataUpdated, state.checked])


    if (component === 'list-items') {
        return <Items {...rest} galleryBreadcrumbs={state.galleryBreadcrumbs} />
    }

    return (
        <Grid container className={classes.root}>
            <Helmet>
                <title>{Utils.getTitleFromUrl(t)}</title>
                <meta name="description" content="Galeria" />
            </Helmet>

            <HeaderMaterialDesign
                title="DAM"
                breadcrumbs={match.path.indexOf('/gallery/') !== -1 ? state.galleryBreadcrumbs : state.shortcutBreadcrumbs}
                localHistory={history}
            />

            <ItemsProvider>
                <ItensFilterBar
                    hideInfoItems={markedItems.length <= 0}
                    data={{
                        title: `${Utils.makeTitle(shortcut_slug)}`,
                        defaultOpen: ''
                    }}
                />
            </ItemsProvider>
            {
                state.checked && (
                    <Content
                        match={match}
                        setStateComponent={setComponent}
                        stateRoot={{
                            loading: state.loading,
                            data: state.data,
                            data_length: state.data.length,
                            params: state.params,
                        }}
                        page={state.page}
                        getData={(params) => getData(params)}
                    />
                )
            }
        </Grid>
    )
}

export default GroupingCards
