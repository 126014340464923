import React, { useState, useCallback } from "react"
import axios from "axios"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button
} from "@material-ui/core"

import EditRoundedIcon from "@material-ui/icons/EditRounded"
import SimpleFormMetadata from "components/CategoryAttributes/SimpleForm"
import Utils from 'utils/Utils'

export const ListRow = ({ variant_attribute, itemId, attribute_id, metadata_name }) => {
  const [state, setState] = useState({ openEdit: false, loading: false })
  const [field, setField] = useState(variant_attribute.value)
  const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const submit = useCallback((id) => {
    const payload = {
      variant: {
        master_attribute_id: attribute_id,
        master_attribute_value: metadata_name,
        variant_attribute_id: variant_attribute.id,
        variant_attribute_value: Array.isArray(field) ? field.map(i => i.value) : _.get(field, "label", field)
      }
    }

    const callback = () => {
      axios.patch(`${pathServer}/item/${id}/variant`, payload, requestHeaders)
        .then(function () {
          setState(prev => ({ ...prev, loading: false }))
        })
        .catch((err) => {
          const message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading: false }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })
          )
        })
    }
    setState(prev => ({ ...prev, loading: true, openEdit: false }), callback())
  }, [field, pathServer, requestHeaders, enqueueSnackbar, t, attribute_id, variant_attribute.id, metadata_name])

  if (state.openEdit) {
    return (
      <ListItem style={{ marginBottom: 8 }}>
        <div style={{ width: "calc(100% - 35px)" }}>
          <SimpleFormMetadata
            attribute={variant_attribute}
            changeAttributes={(id, data) => setField(data)}
            disabled={state.loading}
            value={field}
          />
        </div>

        <ListItemSecondaryAction style={{ right: 0 }}>
          <Button
            color="primary"
            onClick={() => submit(itemId)}
            disabled={state.loading}
            variant="contained"
            size="small"
          >
            {t("common:Salvar")}
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <ListItem button onClick={() => setState(prev => ({ ...prev, openEdit: true }))} disabled={state.loading}>
      <ListItemText primary={variant_attribute.name} secondary={Array.isArray(field) ? field.map(i => i.label).join(", ") : _.get(field, "label", field)} />
      <ListItemSecondaryAction>
        <IconButton
          color="inherit"
          onClick={() => setState(prev => ({ ...prev, openEdit: true }))}
          disabled={state.loading}
        >
          <EditRoundedIcon fontSize="small" style={{ color: "#666" }} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
