import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export const useStyles = makeStyles((theme) => ({  
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: (props) => `url(${props.originalBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: 'calc(100vh - 64px)',
    [theme.breakpoints.down("sm")]: { 
      height: 'calc(100vh - 56px)',
    }
  },  
  container: {
    display: "flex",
    flexDirection: "column",    
    alignItems: "center",
    width: "100%",   
    height: "100%",    
    padding: "2% 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      maxWidth: "none",
      height: 'calc(100vh - 56px)',
    },
    "& .wrapperTitles": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "#FFFFFF",
      marginBottom: "30px",
      "& h1": {
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: "80px",
        fontSize: "4rem",
        textShadow: "3px 4px 6px rgba(0, 0, 0, 0.67)",
        marginBottom: "10px",
      },
      "& h2": {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "1.6em",
        textShadow: "3px 4px 6px rgba(0, 0, 0, 0.25)",
        padding: "0 10px",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "@media (max-height: 768px)": {
        "& h1": {
          fontSize: "2rem",
          lineHeight: "20px",
        },
        "& h2": {
          fontSize: "1rem",
        },
      },
    },
  },
  papers: {
    display: "flex",
    justifyContent: "center",
    width: "100%",   
    "& .MuiFormLabel-root.Mui-error, .MuiFormHelperText-root.Mui-error ": {
      color: "#FF0000",
      marginLeft: 0,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
    },
    "& .logoImageLogin": {
      width: "100%",
      maxWidth: 200,
      maxHeight: 200,     
      paddingTop: '50%',
      marginBottom: 10,   
      backgroundImage: (props) => `url(${props.logoLoginJPG})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",   
      "@media (max-height: 650px)": {        
        display: "none",
      },
    },
    "& button.btnLink": {
      marginTop: "18px",      
      cursor: "pointer",
      color: (props) => props.primaryColor || "#000000",      
      marginBottom: "10px",
      display: "none",
      "&:hover": {
        color: (props) => darken(0.12, props.primaryColor || "#000000"),
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",    
    },
    "@media (min-height: 768px)": {
      minHeight: 500,
    },
  },  
  content: {
    width: "40%",
    maxWidth: "355px",
    height: "100%",
    maxHeight: "600px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 22.5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      margin: 0,
      borderRadius: 0,
      height: "calc(100vh - 56px)",
      maxHeight: "none",
      padding: "0",
    },
    "& .containerText": {
      maxWidth: "100%",
      maxHeight: "100%",
      padding: "40px 20px",
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
      },
      "& p": {
        fontWeight: "300",
        fontSize: "2.57em",
        lineHeight: "1.17em",
        textAlign: "center",
        color: "#818080",
      },
    },
  },  
}));
