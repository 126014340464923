import React from "react";

import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom'

import { useStyles } from "./styles"
import { Typography } from "@material-ui/core"

const AlreadyRegistered = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <div className={classes.wrapper}>
      <div className='container-footer' style={{ color: '#666'}}>
        {t('solicitacaoAcesso:Já possui cadastro? faça o')}
        <button
          className={classes.btnForgot}
          onClick={() => history.push("/login")}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',
          }}
        >
          {` ${t('login')}`}
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>        
        <Typography style={{color:'#A6A6A6'}} variant='caption' align='center'>Powered by Yapoli</Typography>
      </div>
      <div className="spacing"/>
    </div>

  );
}

export default AlreadyRegistered