import React, { Fragment, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Paper,
  Fade,
  Typography,
  Divider,
  useMediaQuery
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useStyles } from "./styles";
import _ from 'lodash'

import OauthButton from 'components/OAuth/Button'

const Login = ({ data, csrf_token, t, handleSubmit, loading, handleAccessRequest }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const sso = _.get(data, "sso", []);
  const hasSso = sso.length > 0;
  const mobile = useMediaQuery('(max-width:600px)')

  const classes = useStyles({
    hasSso
  });

  const validation = (evt) => {
    evt.preventDefault();
    const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i

    if (!regex.test(email)) {
      setEmailError(true);
      return
    }

    if (!emailError) {
      handleSubmit(evt, { email, password, token: csrf_token });
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter" && !emailError)
      validation(evt);
  };

  const handleEmail = (evt) => {
    evt.preventDefault()
    const value = _.get(evt, 'target.value', '')

    if (value.length > 6 && emailError) {
      setEmailError(false);
    }

    setEmail(value)
  };

  const handlePassword = (evt) => {
    evt.preventDefault()
    const value = _.get(evt, 'target.value', '')

    setPassword(value)
  };
  
  const DISABLED = (!email || !password)
  return (
    <Fragment>
      <Paper elevation={2} className={classes.accessRequest}>
        <div className="containerText">
          <Typography>
            {t("login:Ainda não possui acesso ao ")}
            <span style={{ fontWeight: "500" }}>{data.platform_name}</span>?
          </Typography>
        </div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleAccessRequest('Card')}
        >
          {t("common:Solicitar acesso")}
        </Button>
        {
           mobile &&
           <div style={{display: 'flex', alignItems: 'center', marginTop: 25}}>
             <Typography style={{ color: '#A6A6A6', fontSize: 12 }} align='center'>Powered by Yapoli</Typography>
           </div>
        }
      </Paper>
      <Paper
        elevation={2}
        className={classes.login}
        onKeyDown={(evt) => handleKeyDown(evt)}
      >
        <div className="logoImageLogin" />

        <Typography className="descriptionMobile">
          {data.description}
        </Typography>

        <div className="root-formLogin">
          <form className="formLogin">
            <div style={{ width: "100%", marginBottom: 20 }}>
              <TextField
                error={emailError}
                helperText={emailError ? t("login:E-mail inválido") : null}
                variant="outlined"
                id="email"
                label="E-mail"
                name="email"
                value={email}
                autoComplete="email"
                onChange={(evt) => handleEmail(evt)}
                disabled={loading}
              />
            </div>
            <div style={{ width: "100%", marginBottom: 20 }}>
              <TextField
                variant="outlined"
                name="password"
                label={t("login:Digite sua senha")}
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(evt) => handlePassword(evt)}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                disabled={loading}
              />
            </div>

            <div className={classes.actions}>
              <TextField
                name="csrf_token"
                id="csrf_token"
                value={csrf_token}
                style={{display: 'none'}}
              />
              <Button
                id='submit'
                disabled={DISABLED || loading}
                type="submit"
                variant="contained"
                color="primary"
                onClick={validation}
              >
                {loading ? (
                  <Fade
                    in={loading}
                    style={{
                      transitionDelay: loading ? "800ms" : "0ms",
                    }}
                    unmountOnExit
                  >
                    <CircularProgress color="inherit" />
                  </Fade>
                ) : (
                  t("login:Acessar")
                )}
              </Button>
            </div>
            <div className={classes.btnForgot}>
              <button onClick={() => history.push("/forgot-password")}>
                {t("login:Esqueceu a Senha?")}
              </button>
            </div>
          </form>
          {
            hasSso &&
            <div className='root-sso'>
              <div className={classes.divider}>
                <Divider />
                <div className='text'>
                  <span>
                    {t("login:ou acesse com")}
                  </span>
                </div>
              </div>
              <div className={classes.rootBtnSso}>
                {sso.map(sso => <OauthButton key={`authBtn-${_.get(sso, "id", "")}`} ssoData={sso} />)
                }
              </div>
            </div>
          }
        </div>

        {
          !mobile &&
          <div style={{display: 'flex', alignItems: 'center', marginTop: 25}}>
             <Typography style={{ color: '#A6A6A6', marginRight: 10, fontSize: 12 }} align='center'>Powered by Yapoli</Typography>
           </div>
        }

      </Paper>
    </Fragment>
  );
};

export default withTranslation(["common", "login"])(Login);
