export function formatedAttributes(data) {
    const { attributes, structures } = data
  
    const attributesFormat = attributes ? attributes.map((att) => {
      const keys = Object.keys(att.list_value)
      const list = keys.map((i) => ({
        label: i,
        ...att.list_value[i],
      }))
      att.list_value = list
      return att
    }) : []
  
    const structuresFormat = structures ? structures.map((att) => {
      const keys = Object.keys(att.list_value)
      const list = keys.map((i) => ({
        label: i,
        ...att.list_value[i],
      }))
      att.list_value = list
      return att
    }) : []
  
    return { attributes: attributesFormat, structures: structuresFormat }
  }
  