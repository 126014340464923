import React from "react"
import { Paper, Typography, Box } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { useStyles } from "./styles"

const PaperTitle = ({ icon, title, subTitle, iconSVG_IMG }) => {
  const classes = useStyles()
  const Icon = icon

  return (
    <>
      {!icon && !title ? (
        <Paper elevation={2} className={classes.paperTitle}>
          <Skeleton variant="rect" width={48} height={48} />
          <Skeleton variant="text" width="60%" style={{ marginLeft: 20 }} />
        </Paper>
      ) : (
        <Paper elevation={2} className={classes.paperTitle}>
          {icon && <Icon style={{ fill: "#666", width: 48, height: 48 }} />}
          {iconSVG_IMG && iconSVG_IMG}
          <Typography component="div" variant="h6" style={{ lineHeight: 1.2 }}>
            <Box fontWeight={500}>{title}</Box>
            {subTitle && (
              <Box fontWeight={300} fontSize={"0.75rem"}>
                {subTitle}
              </Box>
            )}
          </Typography>
        </Paper>
      )}
    </>
  )
}

export default PaperTitle
