import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        backgroundColor: "#fff",
        minHeight: "100vh",
        gridTemplateColumns: "1fr 300px 60px",
        gridTemplateAreas: `
          'view info info-nav'
          `,
        "& .MuiSkeleton-root": {
            backgroundColor: "#666",
            borderRadius: 4,
            margin: '12px 9px',
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
            gridTemplateAreas: `
          "view"
          "info-nav"
          "info"
          `,
        },
    },
    view: {
        gridArea: "view",
        display: "grid",
        gridTemplateRows: "100px 1fr",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        [theme.breakpoints.down("sm")]: {
            gridTemplateRows: "auto 1fr",
        },
    },
    container: {
        [theme.breakpoints.down("sm")]: {
            width: "100vw",
        },
    },
    info: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFF",
        gridArea: "info",
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            gridArea: "info",
            paddingBottom: 70
        },
    },
    infoNav: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        gridArea: "info-nav",
        "& .contentNav": {
            display: "flex",
            alignItems: "center",
            flexDirection: 'column',
            marginTop: 6,
            [theme.breakpoints.down("sm")]: {
                flexDirection: 'row',
                maxWidth: "100vw",
                overflow: "auto",
                height: 50,
                marginTop: 0,
                "& button": {
                    margin: "0 !important",
                    padding: '12px 9px'
                },
            },
        },
    },
    draftContent: {
        width: 600,
        height: 600,
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            width: "100vw",
            height: 200,
        },
    },
}))
