import React from "react";
import Template from "../Template";
import axios from "axios/index";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Utils from "utils/Utils";
import DialogDeleteValidation from 'components/Dialogs/DeleteValidationPhrase'
import Loading from "components/Loading";
import { mapDispatchToPropsToSettings, mapStateToPropsToSettings } from "utils/Flux";
import connect from "react-redux/es/connect/connect";
import _ from 'lodash'
import { withTranslation } from 'react-i18next';
import { settings } from './_settings'

import {
  Grid,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography
} from "@material-ui/core";

import { WrapperPaperForms } from 'components/WrapperForms'
import SelectUI from 'components/ComboBox/SelectUI'
import AlertDialogSimple from 'components/Alert/AlertDialogSimple'
import CreatableUIMult from 'components/ComboBox/CreatableUIMult'

const createOption = (label) => ({
  label: label,
  value: label,
})
class Edit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...settings,
      label: "",
      description: "",
      mask: "",
      list_values: "",
      value: [],
      inputValue: '',
      id: '',
      valueType: 'unique',
      type: {
        label: '', value: ''
      },
      loading: false,
      data: {},
      dataForm: {},
      values: [],
      formErrors: {},
      formValid: true,
      disableList: true,
      openAlert: {
        open: false,
        description: '',
        list: []
      },
      alternative_name: {
        pt: '', en: '', es: ''
      }
    };
    this.formSubmit = this.formSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleChangeValueType = this.handleChangeValueType.bind(this);
    this.getParameters = this.getParameters.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount() {
    const ID = this.props.match.params.id;
    this.setState({
      ...this.state,
      slug: ID,
    });
    if (ID) this.getData(ID);
  }

  getData(_id) {
    const SELF = this;
    const { pathServer, requestHeaders, t } = SELF.props;
    const OPTIONS = this.state.optionsSelectType

    SELF.setState({ ...SELF.state, id: _id });
    axios.get(pathServer + "/attribute/" + _id, requestHeaders)
      .then(function (resp) {
        const DATA = resp.data.item;

        let list_value = "";
        let temp = [];
        if (DATA.list_value && DATA.list_value !== " ") {
          list_value = DATA.list_value;
          list_value = list_value.filter((item) => !!item);
          for (let key in list_value) {
            let obj = list_value[key];
            temp.push({ value: obj, label: obj });
          }
        } else {
          temp = "";
        }

        let mask = DATA.mask_value;

        if (mask === null) {
          mask = "";
        } else {
          mask = DATA.mask_value;
        }

        const getLabel = OPTIONS.find(i => i.value === DATA.field_type)

        SELF.setState({
          ...SELF.state,
          loading: false,
          label: DATA.name,
          alternative_name: {
            ...SELF.state.alternative_name,
            ...DATA.alternative_name
          },
          type: {
            ...getLabel,
            label: t(`manager:${getLabel.label}`),
          },
          valueType: DATA.list_type || SELF.state.valueType,
          mask: mask,
          description: DATA.description,
          value: temp,
        });
      }, function (err) {
        SELF.setState({ ...SELF.state, loading: false });
        toast.error(err.response.headers['x-message']);
      })
      .catch(function (err) {
        toast.error(t('Erro ao carregar os dados, contate o suporte'));
      });
  }

  getParameters() {
    const SELF = this;
    const names = Utils.removeEmptyValues(SELF.state.alternative_name)

    let data = {
      name: SELF.state.label,
      region_id: SELF.state.region_id,
      description: SELF.state.description,
      field_type: SELF.state.type.value,
    }

    if (SELF.state.type.value === 'list') {
      data["list_type"] = SELF.state.valueType
      data["list_value"] = Array.isArray(SELF.state.value) ? SELF.state.value.map(i => i.value) : []
    }

    if (SELF.state.type.value === 'number' || SELF.state.type.value === 'text') {
      if (Boolean(SELF.state.mask) && Boolean(SELF.state.mask.trim())) {
        data["mask_value"] = SELF.state.mask
      }
    }

    if (!_.isEmpty(names)) {
      data["alternative_name"] = names
    }

    return data
  }

  formSubmit(e) {
    const { pathServer, requestHeaders, t } = this.props;
    e.preventDefault();
    const SELF = this;

    const params = this.getParameters()

    let data = {
      ...params,
      "list_values": this.state.value,
      "alternative_name": SELF.state.alternative_name
    }

    const formValid = this.validateField(data)

    if (!formValid) {
      return false;
    }

    const parameters = this.getParameters();

    SELF.setState({ ...SELF.state, loading: true });

    axios.put(pathServer + '/attribute/' + SELF.state.id, parameters, requestHeaders)
      .then(function (resp) {
        toast.success(
          resp.headers['x-message'],
          {
            autoClose: 1500, onClose: () => {
              SELF.props.history.push("/manager/metadata/list");
            }, pauseOnHover: false
          });
      })
      .catch(function (err) {

        if (err.response.status === 422) {
          SELF.setState({
            ...SELF.state,
            loading: false,
            openAlert: {
              open: true,
              description: _.get(err, 'response.headers.x-message', ''),
              list: _.get(err, 'response.data.data', [])
            }
          })

          return
        }

        const message = err.response ? err.response.data.message || err.response.headers["x-message"] : t('Erro ao carregar os dados, contate o suporte');
        SELF.setState({
          ...SELF.state,
          loading: false
        });
        toast.error(message);
      });

  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  handleChangeType = (selectedOption) => {
    const SELF = this;
    this.setState({
      ...this.state,
      type: selectedOption
    }, () => {
      if (this.state.type.value === "List") {
        SELF.setState({
          ...SELF.state,
          disableList: false
        });
      } else {
        SELF.setState({
          ...SELF.state,
          value: [],
          disableList: true
        });
      }
    });
  };

  handleChangeValueType = (selectedOption) => {
    this.setState({
      ...this.state,
      valueType: selectedOption.target.value
    });
  };

  handleChangeCreatable = (value) => {
    this.setState({ value });
  };

  handleInputChangeCreatable = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    let inputValue = this.state.inputValue;
    let value = this.state.value;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        let newValue = [...value];

        if (inputValue.includes(',')) {
          inputValue.split(',').forEach((i) => {
            newValue.push(createOption(i))
          })
        } else {
          newValue = [...value, createOption(inputValue)]
        }

        this.setState({
          inputValue: '',
          value: newValue,
        });
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  validateField(parameters) {
    const { t } = this.props;
    let fieldValidationErrors = {}

    Object.entries(parameters).forEach(([key, value]) => {
      const typeCurrent = _.get(parameters, 'field_type', '').toLowerCase()
      switch (key) {
        case 'name':
          const label_lenght = value.length;

          if (label_lenght < 2) {
            fieldValidationErrors.label = label_lenght > 2 ? '' : '* ' + t('common:Este campo deve conter mais que 2 caracteres');
          } else {
            fieldValidationErrors.label = ''
          }

          if (label_lenght > 10) {
            fieldValidationErrors.label = label_lenght < 40 ? '' : '* ' + t('common:Este campo deve conter menos que 40 caracteres');
          }

          break;
        case 'alternative_name':
          Object.entries(value).forEach(([field, item]) => {
            if (Boolean(item)) {
              const item_lenght = item.length;

              if (item_lenght < 2) {
                fieldValidationErrors[`alternative_name_${field}`] = item_lenght > 2 ? '' : t('common:Este campo deve conter mais que 2 caracteres');
              } else {
                fieldValidationErrors[`alternative_name_${field}`] = ''
              }

              if (item_lenght > 10) {
                fieldValidationErrors[`alternative_name_${field}`] = item_lenght < 40 ? '' : t('common:Este campo deve conter menos que 40 caracteres');
              }
            } else {
              fieldValidationErrors[`alternative_name_${field}`] = ''
            }
          })
          break;
        case 'description':
          const descriptionValid = value.length > 3;
          fieldValidationErrors.description = descriptionValid ? '' : '* ' + t('common:Este campo deve conter mais que 3 caracteres');
          break;
        case 'mask_value':
          if (((typeCurrent === 'text') || (typeCurrent === 'number')) && Boolean(value)) {
            const maskValid = Utils.maskValid(value);
            fieldValidationErrors.mask = maskValid ? '' : '* ' + t('common:Máscara inválida.');
            break;
          }
          break;
        case 'field_type':
          fieldValidationErrors.type = Boolean(typeCurrent) ? '' : '* ' + t("common:Este campo é obrigatório");
          break;
        case 'list_values':
          const listData = Array.isArray(value) ? value : []
          const duplicates = Utils.hasDuplicates(listData)
          if (typeCurrent === 'list') {
            fieldValidationErrors.list_values = listData.length > 0 ? '' : '* ' + t("common:Este campo é obrigatório")
          }
          if (duplicates) {
            fieldValidationErrors.list_values = t("common:Este campo contem itens duplicados")
          }
          break;
        default:
          break;
      }
    })

    const formErrors = {
      ...this.state.formErrors,
      ...fieldValidationErrors
    }

    const getFormValid = (() => {
      let formValid = true

      for (var value in formErrors) {
        if (formErrors[value] && formErrors[value].trim().length > 0) {
          formValid = false
        }
      }
      return formValid
    })()

    this.setState({
      ...this.state,
      formErrors,
      formValid: getFormValid,
    });

    return getFormValid
  }

  errorClass(error) {
    return (error.length === 0 ? 'form-control' : 'form-control has-error');
  }


  handleDelete = () => {
    const { pathServer, requestHeaders } = this.props;
    const SELF = this;
    SELF.setState({
      ...SELF.state,
      loading: true
    });
    axios.delete(pathServer + "/attribute/" + SELF.state.id, requestHeaders)
      .then(function (resp) {
        SELF.setState({
          ...SELF.state,
          loading: false,
        });
        toast.success(
          resp.headers['x-message'],
          {
            autoClose: 1500,
            onClose: () => {
              SELF.props.history.push("/manager/metadata/list");
            }, pauseOnHover: false
          });
        toast.success(resp.headers['x-message']);
      })
      .catch(function (err) {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : 'Erro ao deletar os dados, contate o administrador.'
        SELF.setState({
          ...SELF.state,
          loading: false
        });
        toast.error(message);
      })

  };

  handleCloseAlert() {
    this.setState(prev => ({
      ...prev,
      openAlert: {
        open: false,
        description: '',
        list: []
      }
    }))
  }

  handleInputAlternativeName(e, key) {
    const value = e.target.value;

    this.setState(prev => ({
      ...prev,
      alternative_name: {
        ...prev.alternative_name,
        [key]: value
      }
    }));
  }

  render() {
    const { t } = this.props;

    let value = this.state.value;
    if (JSON.stringify(value) === '[{"value":"null","label":"null"}]') {
      value = [];
    }

    return (
      <Template area={3} localHistory={this.props.history} customClassName={"container-center"}>
        <Loading showLoading={this.state.loading} />
        <WrapperPaperForms title={t("manager:Edição de metadado")}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="label"
                label={`${t('common:Nome original')}*`}
                variant="outlined"
                value={this.state.label}
                onChange={(event) => this.handleUserInput(event)}
                error={Boolean(this.state.formErrors.label)}
                helperText={this.state.formErrors.label ? this.state.formErrors.label : ''}
                style={{ marginBottom: 0 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="alternative-pt"
                label={t('common:Nome alternativo')}
                variant="outlined"
                value={this.state.alternative_name.pt}
                onChange={(event) => this.handleInputAlternativeName(event, 'pt')}
                style={{ marginBottom: 0 }}
                error={Boolean(this.state.formErrors.alternative_name_pt)}
                helperText={this.state.formErrors.alternative_name_pt ? this.state.formErrors.alternative_name_pt : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="alternative-en"
                label={t('common:Nome alternativo em inglês')}
                variant="outlined"
                value={this.state.alternative_name.en}
                onChange={(event) => this.handleInputAlternativeName(event, 'en')}
                error={Boolean(this.state.formErrors.alternative_name_en)}
                helperText={this.state.formErrors.alternative_name_en ? this.state.formErrors.alternative_name_en : ''}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="alternative-es"
                label={t('common:Nome alternativo em espanhol')}
                variant="outlined"
                value={this.state.alternative_name.es}
                onChange={(event) => this.handleInputAlternativeName(event, 'es')}
                error={Boolean(this.state.formErrors.alternative_name_es)}
                helperText={this.state.formErrors.alternative_name_es ? this.state.formErrors.alternative_name_es : ''}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="description"
              label={`${t('common:Descrição')}*`}
              variant="outlined"
              value={this.state.description}
              onChange={(event) => this.handleUserInput(event)}
              error={Boolean(this.state.formErrors.description)}
              helperText={this.state.formErrors.description ? this.state.formErrors.description : ''}
              multiline
              rows={4}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectUI
              selectValues={this.state.optionsSelectType.map(i => ({
                ...i, label: t(`manager:${i.label}`)
              }))}
              nameField={'type'}
              title={`${t("common:Tipo do campo")}*`}
              valueSelect={this.state.type}
              handleChangeValue={(value) => this.handleChangeType(value)}
              error={this.state.formErrors.type}
            />
          </Grid>
          {
            this.state.type
            && this.state.type.hasOwnProperty('value')
            && ((this.state.type.value).toLowerCase() === 'number'
              || (this.state.type.value).toLowerCase() === 'text'
            ) &&
            <Grid item xs={12}>
              <TextField
                name="mask"
                label={t("common:Máscara padrão")}
                variant="outlined"
                value={this.state.mask}
                onChange={(event) => this.handleUserInput(event)}
                error={Boolean(this.state.formErrors.mask)}
                helperText={this.state.formErrors.mask ? this.state.formErrors.mask : ``}
              />
              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Typography variant='body2' gutterBottom>
                  {t('manager:Insira a máscara de acordo com o tipo')}:
                </Typography>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: 15,
                  color: '#666'
                }}>
                  <Typography variant='caption'>
                    {t('manager:1 - número')}
                  </Typography>
                  <Typography variant='caption'>
                    {t('manager:a - letra')}
                  </Typography>
                  <Typography variant='caption'>
                    {t('manager:A - letra, forçada para maiúsculas quando inserida')}
                  </Typography>
                  <Typography variant='caption'>
                    {t('manager:* - alfanumérico')}
                  </Typography>
                  <Typography variant='caption'>
                    {t('manager:# - alfanumérico, forçado para maiúsculas quando inserido')}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          }

          {(
            this.state.type
            && this.state.type.hasOwnProperty('value')
            && (this.state.type.value).toLowerCase() === 'list'
          ) ? (
            <React.Fragment>
              <Grid item xs={12}>
                <CreatableUIMult
                  title={t("common:Valores")}
                  description={t("common:Digite o valor desejado e aperte ENTER ou TAB para adicionar. Para lista de valores, utilize vírgula (,) para separar.")}
                  values={value}
                  handleChangeCreatable={this.handleChangeCreatable}
                  error={this.state.formErrors.list_values}
                  inputValue={this.state.inputValue}
                  handleInputChangeCreatable={this.handleInputChangeCreatable}
                  handleKeyDown={this.handleKeyDown}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' style={{ marginTop: 20 }}>
                  {t("common:Tipo de valores")}
                </Typography>
                <RadioGroup name="radio-valuetype" value={this.state.valueType} onChange={this.handleChangeValueType} row>
                  <FormControlLabel
                    value="unique"
                    control={<Radio color="primary" />}
                    label={t("Único")}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="multiple"
                    control={<Radio color="primary" />}
                    label={t("Múltiplo")}
                    labelPlacement="end"
                  />
                </RadioGroup>
                {
                  this.state.formErrors.valueType &&
                  <div className="error">{this.state.formErrors.valueType}</div>
                }
              </Grid>
            </React.Fragment>
          ) : null}

          <div className='paper-actions'>
            <DialogDeleteValidation entity={t("common:Metadado")} name={this.state.label}>
              {(confirm) => (
                <Button
                  style={{
                    height: 40,
                    width: 115,
                    marginRight: 20,
                    color: '#F4511E',
                    border: '1px solid #F4511E'
                  }}
                  variant="outlined"
                  disabled={this.state.loading}
                  onClick={confirm(this.handleDelete)}
                >
                  {t("common:Excluir")}
                </Button>
              )}
            </DialogDeleteValidation>
            <Button
              style={{ height: 40, width: 115, marginRight: 20 }}
              variant="outlined"
              color="primary"
              onClick={() => this.props.history.push("/manager/metadata/list")}>
              {t("common:Voltar")}
            </Button>
            <Button
              style={{ height: 40, width: 115, }}
              variant="contained"
              color="primary"
              onClick={this.formSubmit}
              disabled={this.state.loading}
            >
              {t("common:Salvar")}
            </Button>
          </div>
        </WrapperPaperForms>
        {
          this.state.openAlert.open && (
            <AlertDialogSimple
              title={t("common:Erro ao editar metadados.")}
              description={this.state.openAlert.description}
              listNames={this.state.openAlert.list.join(', ')}
              open={this.state.openAlert.open}
              handleClose={this.handleCloseAlert}
            />
          )
        }
      </Template>
    );
  }
}

export default connect(mapStateToPropsToSettings, mapDispatchToPropsToSettings)(withTranslation(['manager', 'common'])(Edit));
