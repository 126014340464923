import React from 'react'
import { useTranslation } from "react-i18next"
import { Typography } from '@material-ui/core'

import DialogConfirm from './DialogConfirm'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'

const AlertContentPublication = ({ open = false, text = "", handleDialogConfirm, closeDialogConfirm }) => {
    const { t } = useTranslation()
    return open && (
        <DialogConfirm
            open={open}
            icon={<InfoRoundedIcon id="main" />}
            title={t('DAM:Item despublicado')}
            handleConfirm={handleDialogConfirm}
            handleClose={closeDialogConfirm}
        >
            <div>
                <Typography variant='body2' style={{ marginBottom: 10 }}>
                    {text} {t('DAM:Deseja continuar?')}
                </Typography>
            </div>
        </DialogConfirm>
    )
}

export default AlertContentPublication