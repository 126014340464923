import React, { useCallback } from "react";
import _ from 'lodash'
import Icon from "@mdi/react";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip, ListItem, ListItemAvatar, Checkbox, Typography, CircularProgress } from "@material-ui/core";

import UploadActions from "flux/actions/UploadActions";
import DialogConfirm from "components/Alert/DialogConfirm";
import ImageLazyLoad from "components/ImageLazyLoad"
import ViewStatus from 'containers/AccordionBucketContainer/components/DetailsRows/ViewStatus'
import ErrorWrapper from "containers/AccordionBucketContainer/components/ErrorWrapper"
import SkeletonResponsive from 'components/ImageLazyLoad/Skeleton'

import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import { mdiClose, mdiWallpaper } from "@mdi/js";
import { useStyles } from "./styles"
import Utils from "utils/Utils";

const DetailsRows = ({ entity = 'asset', canSelectAttach = false, getUrlDownload, dataFilesCheckeds, submitAssetMarked, dataIds, isWorkflow }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const rowsFiltered = useSelector((state) => state.uploadReducer.uploadFiles)
    .filter(
      i => Utils.isSameUploadContext(entity, i.entity) && !dataIds.some(asset => i.id === asset)
    )
  const classes = useStyles()
  const { t } = useTranslation("DAM")

  const handleConfirmCancel = useCallback((item) => {
    dispatch(UploadActions.uploadCancelFile(item))
      .catch(error => {
        console.log(error, item.path_file);
        enqueueSnackbar(
          `${t('DAM:Erro ao tentar cancelar o upload')}: ${item.path_file}`,
          {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
  }, [enqueueSnackbar, dispatch, t])

  const handleResumeUpload = useCallback((item) => {
    dispatch(UploadActions.resume(item))
      .then(() => console.log("Upload resumed."))
      .catch(error => {
        console.log(error, item.path_file);
        enqueueSnackbar(
          `${t('DAM:Erro ao tentar retomar o upload')}: ${item.path_file}`,
          {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
  }, [enqueueSnackbar, dispatch, t])

  return (
    <>
      {
        rowsFiltered.map(item => {
          return (
            <ListItem
              className={`${classes.list_item} ${(item.status_pipeline === "ERROR" || item.error !== undefined) && classes.error}`}
              key={item.id}
            >
              {
                isWorkflow && (
                  <div style={{ paddingRight: 16 }}>
                    <Checkbox
                      color='primary'
                      checked={Boolean(dataFilesCheckeds[item.id])}
                      onChange={evt => submitAssetMarked(evt, item.id)}
                      disabled={!canSelectAttach || !item.finishedSuccessfully || item.error}
                    />
                  </div>
                )
              }
              <ListItemAvatar>
                {
                  item.error ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, height: 100 }}>
                      <Icon path={mdiWallpaper} size={4} color="rgb(240, 240, 240)" />
                    </div>
                  ) : (
                    <>
                      {
                        !item.finishedSuccessfully ?
                          <SkeletonResponsive width={100} height={100} /> : (
                            <ImageLazyLoad
                              images={{ imgWEBP: _.get(item, "thumbnail_card_webp", ""), imgJPG: _.get(item, "thumbnail_card_original", "") }}
                              styles={{ width: 100, height: 100 }}
                              loadingData={!item.finishedSuccessfully}
                            />
                          )
                      }
                    </>

                  )
                }
              </ListItemAvatar>

              <div className={classes.row_info} style={{ maxWidth: 'calc(100% - 234px)' }}>
                <div className="info">
                  <Tooltip title={_.get(item, 'name', '')}>
                    <Typography variant='body2' noWrap>{_.get(item, 'name', '')}</Typography>
                  </Tooltip>
                  {
                    (item.status_view === 'upload-complete' && item.finishedSuccessfully) &&
                    <div className="icon" onClick={evt => getUrlDownload(evt, item.id)}>
                      <GetAppRoundedIcon />
                    </div>
                  }
                </div>
                <div className="actions">
                  {
                    item.error ? (
                      <ErrorWrapper
                        t={t}
                        item={item}
                        classError={classes.error}
                      />
                    ) : (
                      <>
                        {
                          item.status_view === 'upload' && (
                            <ViewStatus item={item} />
                          )
                        }
                        {
                          (item.status_view === 'upload-complete' && !item.finishedSuccessfully) && (
                            <CircularProgress style={{ width: "25px", height: "25px", color: "#00B207" }}
                            />
                          )
                        }
                      </>
                    )
                  }

                  {
                    item.upload_paused && (
                      <Tooltip title={t("DAM:Reiniciar upload")}>
                        <div className="icon" style={{ zIndex: 5, display: 'flex', marginRight: 3 }} onClick={evt => handleResumeUpload(item)}>
                          <RefreshRoundedIcon />
                        </div>
                      </Tooltip>
                    )
                  }

                  {item.status_view === 'upload' && (
                    <DialogConfirm
                      t={t}
                      title={t("DAM:Cancelar upload")}
                      description={t(
                        `DAM:Você tem certeza que deseja cancelar o upload do arquivo`,
                        { file: item.name }
                      )}
                    >
                      {(confirm) => (
                        <Tooltip title={t("DAM:Cancelar upload")}>
                          <div className="icon" style={{ zIndex: 5, display: 'flex' }}>
                            <Icon
                              path={mdiClose}
                              size={1}
                              onClick={confirm(() => handleConfirmCancel(item))}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </DialogConfirm>
                  )}
                </div>
              </div>
            </ListItem>
          )

        })
      }
    </>
  );
};

export default DetailsRows;
