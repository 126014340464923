export const settings = {
    area: 7,
    route: "profiles",
    entity: "profile",
    title: {
        singular: "Perfil",
        plural: "Perfis"
    },
    page: 0,
    initial_page: 0,
    isNotPageOne: true
};