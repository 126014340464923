import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles";
import { Menu, Typography, IconButton } from "@material-ui/core"
import { Creators as HeaderAppActions } from "flux/ducks/header_app"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AppActions from "flux/actions/AppActions";
import Tabs from './Tabs'

const useStyles = makeStyles((theme) => ({
  menu: {
    zIndex: '1000000 !important',
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: "640px !important",
      left: 'calc(100vw - 640px) !important',
      top: '64px !important',
      display: 'flex',
      overflow: 'hidden',
      maxHeight: 'calc(100vh - 64px) !important',
      height: '100% !important',
      borderRadius: 0,
      "& ul": {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        left: '0 !important',
        top: '0 !important',
        maxHeight: '100vh !important',
      }
    }
  },
  header: {
    padding: 20,
    color: '#666'
  },
  errorGetData: {
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
  }
}))


const Notifications = ({ anchorEl, handleMenuClose, changeRegion }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()  

  const { userData: { user_token } } = useSelector((state) => state.appReducer)
 
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      dispatch(AppActions.bindToChannel("private-web-platform", "notifications-read",
      ({ read, pending }) => {
        console.info(`${read} messagees marked as read. Pending: ${pending}`);
        dispatch(HeaderAppActions.setCountNotifications({ data: pending }))
      }, "notifications"
    ));
    }
    return () => {
      dispatch(AppActions.unbindContext("private-web-platform", "notifications"))
      isMounted = false
    }
  }, [dispatch, user_token])

  const mobile = useMediaQuery('(max-width:600px)');
  const open = Boolean(anchorEl)

  return (
    <Menu
      className={classes.menu}
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
    >
      <div className={classes.header}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography variant='h5'>{t('common:Notificações')}</Typography>
            <Typography variant='body2' style={{ opacity: .6 }}>{t('common:Verifique as atualizações dos itens e o Link para compartilhamento.')}</Typography>
          </div>
          {
            mobile
              ? (
                <IconButton color="inherit" onClick={handleMenuClose}>
                  <CloseRoundedIcon />
                </IconButton>
              )
              : <div />
          }
        </div>
      </div>

      <Tabs 
        changeRegion={changeRegion}       
        handleMenuClose={handleMenuClose}
      />
      

    </Menu>
  );
};

export default Notifications;
