import _ from 'lodash'
import { getDomain } from './Helpers'
class Settings {
  constructor() {
    const domain = getDomain().indexOf('yapoli.com') !== -1

    // TODO : Com a mudança da versão do react-dropzone a lista de extensões precisa contemplar os mime-types (https://react-dropzone.js.org/#section-accepting-specific-file-types)
    //      : Para voltar é necessário mapear todas as extensões para os mimetypes correspondentes. Alguns (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types).
    //      : Não sei dizer se ele de fato valida o mime-type do arquivo. Se sim, teremos problemas porque alguns mime-types vêem errados.
    //this.ACCEPTED_EXTENSIONS = ".webp,.xlsx,.xls,.csv,.xltx,.ods,.ots,.doc,.docx,.docb,.dot,.dotx,.odt,.ott,.mpp,.mp3,.flac,.m4a,.aac,.aif,.aiff,.wav,.wma,.mov,.otf,.woff,.ttf,.svg,.zip,.rar,.tar,.gz,.gzip,.jar,.7z,.fgz,.webarchive,.pdf,.ai,.jpg,.png,.jpeg,.jpe,.jfif,.bmp,.heic,.heif,.psd,.psb,.tiff,.tif,.gif,.eps,.ppt,.pptx,.odp,.otp,.odg,.pps,.pot,.ppsx,.mp4,.mpeg,.mpg,.mpe,.mkv,.webm,.ogv,.vob,.m4v,.3gp,.mxf,.m1v,.flv,.m2ts,.avi,.wmv,.xps,.oxps,.pub,.indd,.fla,.xcf,.cdr,.dwg,.obj,.skp,.txt, .nef, .crw, .cr2, .cr3, .dng, .swf, .htm, .html, .oft, .rtf, .msg, .cda, .idml"
    this.ACCEPTED_EXTENSIONS = {}
    this.THUMB_EXTENSIONS = ['JPG', 'PNG', 'GIF', 'JPEG', 'JPE', 'JFIF', 'BMP', 'TIFF', 'TIF', 'AVI', 'MPEG', 'MPG', 'MP4', 'MKV', 'WebMMOV', 'OGV', 'VOB', 'M4V', '3GP', 'WMV', 'M1V', 'FLV', 'M2TS', 'PPT', 'PPTX', 'PDF', 'PSD', 'PSB', 'AI', 'INDD', 'FLA', 'EPS']
    this.STAGE_TESTE = (process.env.REACT_APP_STAGE === "test" || process.env.NODE_ENV === "development");
    this.URL_API = domain ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_IO;
    this.BUCKET_NAME_DEFAULT = process.env.REACT_APP_BUCKET_NAME_DEFAULT;
    this.BUCKET_NAME_TASKS = process.env.REACT_APP_BUCKET_NAME_TASKS;
    this.BUCKET_NAME_DATA = process.env.REACT_APP_BUCKET_NAME_DATA;
    this.BUCKET_NAME_ITEM = process.env.REACT_APP_BUCKET_NAME_ITEM;
    this.AWS_KEY = process.env.REACT_APP_AWS_KEY;
    this.AWS_REGION = process.env.REACT_APP_AWS_REGION;
    this.APP_ID = process.env.REACT_APP_APP_ID;
    this.PUSHER_APP_ID = process.env.REACT_APP_PUSHER_KEY;
    this.PUSHER_REGION = process.env.REACT_APP_PUSHER_REGION;
    this.ITEMS_PAGING_LIMIT = 30;
    this.SUCCESS_NOTIFICATION_AUTOHIDE = 5000;
    this.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS = {
      variant: "success",
      autoHideDuration: 5000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      action: () => null,
    };
    this.COLOR_PLATFORM_DEFAULT = '#01BEC4'
    this.DATA_SUPPORTED_LNG = _.orderBy([
      { lng: 'pt', label: 'PT', name: 'Português / PT', short_name: 'Português', description: 'Esse arquivo será mostrado a todos os usários que não possuirem um idioma definido ao entrar na plataforma.' },
      { lng: 'en', label: 'EN', name: 'English / EN', short_name: 'Inglês', description: 'Esse arquivo será mostrado a todos os usários que possuirem o inglês como idioma da plataforma.' },
      { lng: 'es', label: 'ES', name: 'Español / ES', short_name: 'Espanhol', description: 'Esse arquivo será mostrado a todos os usários que possuirem o espanhol como idioma da plataforma.' },
    ], ['name'], ['asc'])
    this.RESPONSE_STATUS = {
      NOT_SENT: "notsent",
      SUCCEEDED: "succeeded",
      FAILED: "failed"
    };
    this.DATA_ACTION_DIALOGS = {
      selected_item: { msg: 'Ao trocar de Região, a seleção de Itens será desfeita. Deseja continuar?', title: 'Itens selecionados' },
      selected_asset: { msg: 'Ao trocar de Região, a seleção de Ativos será desfeita. Deseja continuar?', title: 'Ativos selecionados' }
    };
    this.DATA_LINKS_LGPD = {
      pt: "https://yapoli.zendesk.com/hc/pt-br/articles/5045749519636-O-que-s%C3%A3o-dados-sens%C3%ADveis-e-dados-pessoais-de-acordo-com-a-LGPD-",
      en: "https://yapoli.zendesk.com/hc/en-us/articles/5045749519636-What-is-Sensitive-Data-and-Personal-Data-according-to-the-LGPD-"
    };
    this.ALTERNATIVE_NAME_SEPARATOR = [{ label: '_ (Underline)', value: '_' }, { label: '- (Hífen)', value: '-' }];
    this.DATA_LINKS_INFO_REGEX = {
      pt: "https://yapoli.zendesk.com/hc/pt-br/articles/8275012761492",
      en: "https://yapoli.zendesk.com/hc/en-us/articles/8275012761492"
    };
    this.DATA_POLICY = {
      pt: "https://yapoli.zendesk.com/hc/pt-br/articles/6529252463892-Pol%C3%ADtica-de-privacidade-da-Yapoli"
    };
    this.DATA_LABELS_FILTER_BUCKET = [
      { id: 0, label: "Imagem", value: 'IMAGES' },
      { id: 1, label: "PDF", value: 'PDF' },
      { id: 2, label: "Documentos", value: 'DOCUMENT' },
      { id: 3, label: "Compactados", value: 'COMPRESSED' },
      { id: 4, label: "Áudio", value: 'AUDIO' },
      { id: 5, label: "Vídeo", value: 'MOVIE' },
      { id: 6, label: "3D", value: 'THREE_DIMENSIONS' }
    ];
    this.DATA_CATEGORY_LINKS_SHARED_ITENS = {
      pt: "",
      en: ""
    };
    this.MAX_ITEMS_DOWNLOAD = 1000;
    this.MAX_ITEMS_SHARED = 1000;
    this.ASSET_UPLOAD_SOURCE = "web";
    this.VIDEO_EXTENSIONS = [
      '.3g2',
      '.3gp',
      '.amv',
      '.asf',
      '.avi',
      '.divx',
      '.drc',
      '.dv',
      '.f4a',
      '.f4b',
      '.f4p',
      '.f4v',
      '.flv',
      '.gvi',
      '.gxf',
      '.ismv',
      '.iso',
      '.m1v',
      '.m2t',
      ".m2ts",
      '.m2v',
      '.m4v',
      '.mkv',
      '.mov',
      '.mp2',
      '.mp2v',
      '.mp4',
      '.mp4v',
      '.mpe',
      '.mpeg',
      '.mpeg1',
      '.mpeg2',
      '.mpeg4',
      '.mpg',
      '.mpv2',
      '.mts',
      '.mtv',
      '.mxf',
      '.mxg',
      '.nsv',
      '.nut',
      '.nuv',
      '.ogm',
      '.ogv',
      '.ogx',
      '.ps',
      '.rec',
      '.rm',
      '.rmvb',
      '.rpl',
      '.thp',
      '.tod',
      '.ts',
      '.tts',
      '.vob',
      '.vro',
      '.webm',
      '.wm',
      '.wmv',
      '.wtv',
      '.xesc'
    ];
    this.ENABLE_NEW_TEM_FORM_PAGE = []
  }

}

export default new Settings();
