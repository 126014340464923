import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import MUIRichTextEditor from "mui-rte"
import axios from "axios"
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { convertToRaw, EditorState } from 'draft-js'

import {
    Grid,
    Divider,
    Paper,
    Typography,
    TextField,
    Box,
    Button,
    FormHelperText
} from "@material-ui/core"
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import SegmentComboBox from "components/ComboBox/SegmentComboBox"
import CategoryComboBox from 'components/ComboBox/CategoryComboBox'
import { SelectPublished } from 'components/ComboBox/SelectPublished'
import RelatedItemsComboBox from 'components/ComboBox/RelatedItemsComboBox'
import CategoryAttributes, { createEditForm, validation, attributesForInput } from 'components/CategoryAttributes'
import { useStyles } from "./styles";
import Utils from 'utils/Utils'
import Settings from 'utils/Settings'

const defaultTheme = createMuiTheme({
    overrides: {
        MUIRichTextEditor: {
            container: {
                margin: 0
            },
            toolbar: {
                borderBottom: '1px solid #eee',
                padding: '8px 0',
            },
            placeHolder: {
                margin: 0,
                padding: '19px 16px',
            },
            editorContainer: {
                margin: 0,
                padding: '19px 16px',
            }
        }
    }
})

const FormCreateOrEditItem = ({ isEdit, submit, goback, defaultData }) => {
    const [state, setState] = useState({
        name: _.get(defaultData, 'name', ''),
        editorState: _.get(defaultData, 'editorState', null),
        editorStateTemp: EditorState.createEmpty(),
        segmentIem: _.get(defaultData, 'segmentIem', _.get(defaultData, 'segment', {})),
        categoryData: _.get(defaultData, 'categoryData', _.get(defaultData, 'category', {})),
        isGallery: _.get(defaultData, 'isGalleryType', _.get(defaultData, 'isGallery', false)),
        isDefaultGallery: _.get(defaultData, 'isGalleryType', _.get(defaultData, 'isGallery', false)),
        isPublished: _.get(defaultData, 'isPublished', true),
        isCanPublish: _.get(defaultData, 'isCanPublish', {
            value: false, id: 'item'
        }),
        startDate: _.get(defaultData, 'startDate', ''),
        validityDate: _.get(defaultData, 'validityDate', ''),
        hasDate: _.get(defaultData, 'hasDate', false),
        relatedItems: _.get(defaultData, 'relatedItems', []),
        dataFields: _.get(defaultData, 'dataFields', {}),
        attributes: _.get(defaultData, 'attributes', []),
        errorFields: {},
        errors: {},
        loading: false,
    })
    console.log()
    const { requestHeaders, pathServer } = useSelector(state => state.appReducer)

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const classes = useStyles()

    const setStateByField = useCallback((field, value) => {
        setState(prev => ({ ...prev, [field]: value }))
    }, [])

    const handleChangeEditorDraft = useCallback((editorValue) => {
        const currentContent = editorValue.getCurrentContent()
        if (currentContent && !state.loading) {
            const dataDescription = convertToRaw(currentContent)
            const textPerLine = dataDescription.blocks.map((el) => el.text).join(" ").trim()
            setState(prev => ({
                ...prev,
                editorStateTemp: editorValue,
                editorState: { text: textPerLine, data: JSON.stringify(dataDescription) }
            }))
        }
    }, [state.loading])

    const handleSetCanPublishField = useCallback((id, value) => {
        const id_current = _.get(state.isCanPublish, 'id', '')
        const value_current = _.get(state.isCanPublish, 'value', '')

        if (id === 'segment') {
            if (!(id_current === 'category' && value_current)) {
                setState(prev => ({ ...prev, isCanPublish: { value, id } }))
            }
        }

        if (id === 'category') {
            if (!(id_current === 'segment' && value_current)) {
                setState(prev => ({ ...prev, isCanPublish: { value, id } }))
            }
        }
    }, [state.isCanPublish])

    const handleChangeSegment = useCallback((segment) => {
        setState(prev => ({ ...prev, segmentIem: segment }),
            handleSetCanPublishField('segment', _.get(segment, 'canPublish', false))
        )
    }, [handleSetCanPublishField])

    const getAttributesCategory = useCallback(async (categoryId) => {
        try {
            const categoryResult = await axios.get(`${pathServer}/attributesByCategory/${categoryId}`, requestHeaders)
            const dataAttributes = _.get(categoryResult, 'data.items', []).map((dataAttribute) => {
                dataAttribute.mask_value = dataAttribute.mask_value ? dataAttribute.mask_value.trim() : '';
                return dataAttribute
            })
            const dataFields = createEditForm(_.get(defaultData, 'initalAttribute', []), _.get(categoryResult, 'data.items', []))

            return { attributes: dataAttributes, dataFields }
        } catch (err) {
            const message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao buscar os attributes da categoria, tente novamente.`))
            setState(prev => ({ ...prev, loading: false, error: true }),
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: Settings.SUCCESS_NOTIFICATION_AUTOHIDE,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                }))
        }
    }, [enqueueSnackbar, pathServer, requestHeaders, t, defaultData])

    const handleChangeCategory = useCallback(async (selectedCategory) => {
        const { attributes, dataFields } = await getAttributesCategory(_.get(selectedCategory, 'value', ''))
        setState(prev => ({
            ...prev,
            categoryData: selectedCategory,
            attributes,
            dataFields,
            canLink: _.get(selectedCategory, 'canLink', false),
            relatedItems: _.get(selectedCategory, 'canLink', false) ? prev.relatedItems : [],
            isGallery: _.get(selectedCategory, 'isGalleryType', false)
        }), handleSetCanPublishField('category', _.get(selectedCategory, 'canPublish', false)))
    }, [handleSetCanPublishField, getAttributesCategory])

    const categoryOnLoad = useCallback(async (selectedCategory) => {
        const { attributes, dataFields } = await getAttributesCategory(_.get(selectedCategory, 'value', ''))
        setState(prev => ({ ...prev, categoryData: selectedCategory, attributes, dataFields }))
    }, [getAttributesCategory])

    const handleChangeSelectPublished = useCallback((newState) => {
        setState(prev => ({ ...prev, ...newState }))
    }, [])

    const handleChangeAttributes = useCallback((id, value) => {
        const newAttributes = { ...state.dataFields }
        Object.entries(newAttributes).forEach(([key, item]) => {
            if (key === id) {
                newAttributes[key] = value
            }
        })
        setState(prev => {
            let errorFields = prev.errorFields
            delete errorFields[id]
            return {
                ...prev,
                dataFields: newAttributes,
                errorFields: errorFields
            }
        });
    }, [state.dataFields])

    const handleChangeRelatedItems = useCallback((items = []) => {
        setState(prev => ({ ...prev, relatedItems: (items && items.length > 0) ? items : [] }))
    }, [])

    const getParameters = useCallback(() => {
        const [attributes, unset_attributes]
            = attributesForInput(_.get(state, 'dataFields', {}), _.get(state, 'attributes', []))
        let parameters = {
            name: _.get(state, 'name', ''),
            description: {
                text: _.get(state, 'editorState.text', t("DAM:Sem descrição")),
                formatter: {
                    name: "MUI-RTE/DraftJs",
                    version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
                    format: _.get(state, 'editorState.data', ""),
                },
            },
            segment_id: _.get(state, 'segmentIem.value', _.get(state, 'segmentIem.id', '')),
            category_id: _.get(state, 'categoryData.value', _.get(state, 'categoryData.id', '')),
            attributes,
            unset_attributes
        }
        if (_.get(state, 'hasDate', false)) {
            if (state.validityDate) {
                parameters['validity_date'] = _.get(state, 'validityDate', '')
            }
            if (state.startDate) {
                parameters['start_date'] = _.get(state, 'startDate', '')
            }
        } else {
            parameters['published'] = _.get(state, 'isPublished', false)
        }
        if (_.get(state, 'relatedItems', []).length > 0) {
            parameters['links'] = _.get(state, 'relatedItems', []).map(i => i.value);
        }
        if (!isEdit && _.get(state, 'isDefaultGallery', false)) {
            delete parameters['name']
        }

        return parameters
    }, [state, t, isEdit])

    const formValidationAndSubmit = useCallback(() => {
        const {
            name,
            isGallery,
            segmentIem,
            categoryData,
            startDate,
            validityDate,
            hasDate,
            dataFields,
            attributes
        } = state
        let formErrors = {}

        if (isGallery && isEdit && !(name).trim()) {
            formErrors.name = t("common:Campo obrigatório")
        }
        if (!(name).trim() && !isGallery) {
            formErrors.name = t("common:Campo obrigatório")
        }
        if (_.get(categoryData, 'value', _.get(categoryData, 'name', '')).trim().length === 0) {
            formErrors.category = t("common:Campo obrigatório")
        }
        if (_.get(segmentIem, 'value', _.get(segmentIem, 'name', '')).trim().length === 0) {
            formErrors.segment = t("common:Campo obrigatório")
        }

        if (hasDate) {
            if (!Boolean(startDate) && !Boolean(validityDate)) {
                formErrors.hasDate = t('common:Insira uma data')
            }
            if (Boolean(startDate) && Boolean(validityDate)) {
                if (Utils.checkIfDateIsAfter(startDate, validityDate)) {
                    formErrors.hasDate = t('common:Data final deve ser maior')
                }
            }
        }

        const errorFields = validation(dataFields, attributes)

        if (_.isEmpty(errorFields) && _.isEmpty(formErrors)) {
            const params = getParameters()
            submit(params)
        } else {
            setState(prev => ({ ...prev, errors: formErrors, errorFields }))
        }
    }, [state, getParameters, t, submit, isEdit])

    const getDisableCategory = useCallback(() => {
        let disabled = isEdit
        const category_initial = _.get(defaultData, 'categoryData', _.get(defaultData, 'category', {}))
        if (isEdit) {
            disabled = _.get(state.categoryData, 'value', '') === _.get(category_initial, 'value', '')
        }
        return disabled
    }, [state.categoryData, defaultData, isEdit])

    return (
        <Paper elevation={2} className={classes.paperForm}>
            <Grid item xs={12} style={{ marginBottom: 20, display: "flex", justifyContent: "space-between" }}>
                <Typography variant='h5'>
                    {isEdit ? t("common:Editar") : t("common:Criar")}
                </Typography>
                <Typography variant='body2'>
                    {`*${t('common:Campo obrigatório')}`}
                </Typography>
            </Grid>
            <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                    <TextField
                        label={t("common:Nome")}
                        value={_.get(state, 'name', '')}
                        onChange={(evt) => setStateByField("name", _.get(evt, 'target.value', ''))}
                        variant="outlined"
                        error={_.get(state, 'errors.name', false)}
                        disabled={_.get(state, 'isGallery', false) && !isEdit}
                        helperText={
                            _.get(state, 'isGallery', false) && !isEdit
                                ? t("common:A categoria trata-se de uma galeria, portanto o nome do item terá o mesmo nome do arquivo.")
                                : _.get(state, 'errors.name', '')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.labelDescription}>
                        {t("common:Descrição")}
                    </Typography>

                    <Box className={classes.wrapperRichText}>
                        <MuiThemeProvider theme={defaultTheme}>
                            <MUIRichTextEditor
                                maxLength={1000}
                                controls={["bold", "italic", "underline", "link"]}
                                label={t("common:Descrição do item")}
                                onChange={handleChangeEditorDraft}
                                editorState={_.get(state, 'editorStateTemp', null)}
                                defaultValue={_.get(defaultData, 'editorState.data', "")}
                            />
                        </MuiThemeProvider>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <SegmentComboBox
                        optionSelected={_.get(state, 'segmentIem.value', _.get(state, 'segmentIem.id', ''))}
                        handleChange={(selectedItem) =>
                            handleChangeSegment(selectedItem)
                        }
                        error={_.get(state, 'errors.segment', false)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CategoryComboBox
                        optionSelected={_.get(state, 'categoryData.label', _.get(state, 'categoryData.name', ''))}
                        handleChange={(selectedItem) => handleChangeCategory(selectedItem)}
                        onLoadDataCallback={(selectedItem) => categoryOnLoad(selectedItem)}
                        isDisabled={_.get(state, 'isDefaultGallery', false)}
                        error={_.get(state, 'errors.category', false)}
                        field_required
                    />
                    {
                        _.get(state, 'isGallery', false) && !_.get(state, 'isDefaultGallery', false) &&
                        <FormHelperText>
                            {t("manager:Essa Categoria não poderá ser alterada após sua alteração.")}
                        </FormHelperText>
                    }
                    {
                        _.get(state, 'isDefaultGallery', false) &&
                        <FormHelperText>
                            {t("manager:Essa Categoria não pode ser alterada por ser do tipo galeria de itens.")}
                        </FormHelperText>
                    }
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body" component="span" className='label'>
                        {t("common:Em relação a publicação, o item deverá ser criado como")}
                    </Typography>
                </Grid>

                <SelectPublished
                    canPublish={_.get(state, "isCanPublish.value", false)}
                    handleChange={handleChangeSelectPublished}
                    valueDateDefault={{
                        isPublished: _.get(state, 'isPublished', false) && !_.get(state, "validityDate", false),
                        startDate: _.get(state, 'startDate', ""),
                        validityDate: _.get(state, "validityDate", ""),
                        hasDate: _.get(state, "hasDate", false),
                        error: _.get(state, 'errors.hasDate', false)
                    }}
                />

                {
                    _.get(state, 'attributes', []).length > 0 &&
                    <CategoryAttributes
                        attributes={_.get(state, 'attributes', [])}
                        dataFields={_.get(state, 'dataFields', {})}
                        errorFields={_.get(state, 'errorFields', {})}
                        changeAttributes={handleChangeAttributes}
                        isDisabled={getDisableCategory()}
                    />
                }

                {
                    _.get(state, 'categoryData.canLink', false) &&
                    <Grid item xs={12}>
                        <RelatedItemsComboBox
                            limitItens={1000}
                            selectedItems={_.get(state, 'relatedItems', []).length}
                            defaultItems={_.get(state, 'relatedItems', [])}
                            currentId={_.get(defaultData, 'itemId', '')}
                            handleChange={handleChangeRelatedItems}
                        />
                    </Grid>
                }

                <Grid item xs={12} className={classes.actions}>
                    <Button
                        style={{ height: 40, width: 115, marginRight: 25 }}
                        variant="outlined"
                        color="primary"
                        onClick={goback}
                    >
                        {t("common:Voltar")}
                    </Button>
                    <Button
                        style={{ height: 40, width: 115 }}
                        variant="contained"
                        color="primary"
                        onClick={formValidationAndSubmit}
                    >
                        {t("common:Salvar")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

FormCreateOrEditItem.defaultProps = {
    isEdit: false,
    defaultData: {},
    submit: () => { },
    goback: () => { },
}

export default FormCreateOrEditItem
