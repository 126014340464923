import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: '#666',
    '& #label-on': {
      transform: 'translate(14px, -6px) scale(0.75)',
      backgroundColor: '#fff',
      padding: '0 5px',
    },
    '& .label-focus-on': {
      transform: 'translate(14px, -6px) scale(0.75)',
      backgroundColor: '#fff',
      padding: '0 5px',
      color: theme.palette.primary.main,
    },
    '& .label-focus-off': {
      color: '#666',
    },
    '& .label-focus-on-is-mult': {
      transform: 'translate(14px, -6px) scale(0.75)',
      backgroundColor: '#fff',
      padding: '0 5px',
      color: theme.palette.primary.main,
    },
    '& .label-focus-off-is-mult': {
      transform: 'translate(14px, -6px) scale(0.75)',
      backgroundColor: '#fff',
      padding: '0 5px',
      color: '#666',
    },
    '& .select-custom__option': {
      cursor: 'pointer'
    },
    "& .select-custom__multi-value__label": {
      width: "100% !important"
    },
    '& div.select-custom__value-container': {
      padding: '12.6px 14px',
    },
    '& .select-custom__control--is-focused': {
      borderColor: `${theme.palette.primary.main} !important`,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& div.select-custom__multi-value__label': {
      overflow: props => props.isColumnChip ? 'auto' : '',
      width: props => props.isColumnChip ? 170 : 'auto',
    },
  },
  rootError: {
    color: 'rgba(0, 0, 0, 0.54)',
    '& .label-focus-on': {
      transform: 'translate(14px, -6px) scale(0.75)',
      backgroundColor: '#fff',
      padding: '0 5px',
      color: theme.palette.error.main,
    },
    '& .label-focus-off': {
      color: theme.palette.error.main,      
    },
    '& .select-custom__option': {
      cursor: 'pointer'
    },
    '& div.select-custom__value-container': {
      padding: '12.6px 14px',
    },
    '& .select-custom--is-disabled': {
      opacity: 0.3,
    },
    '& .select-custom__control': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .select-custom__control--is-focused': {
      borderColor: `${theme.palette.error.main} !important`,
      boxShadow: `0 0 0 1px ${theme.palette.error.main} !important`,
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  rootOption: {
    color: 'inherit',
    cursor: 'pointer',
    fontSize: 'inherit',
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    "& img": {
      borderRadius: 4,
      marginRight: 10,
    },
    "&:hover": {
      backgroundColor: '#DEEBFF',
    },
  }
}));
