import Utils from "../../utils/Utils"
import * as ActionsType from "../actionTypes"
import Settings from "../../utils/Settings"
import Pusher from "pusher-js"
import i18n from "i18next"

let primaryColor = localStorage.getItem("primaryColor") || ""

const Lngi18nextInitial = localStorage.getItem('i18nextLng') || i18n.language

let policies = localStorage.getItem("policies")
if (policies) {
  policies = typeof policies === "string" ? policies.split(",") : policies
} else {
  policies = []
}

const getCurrentPolicy = () => {
  let current = localStorage.getItem("currentPolicy")
  if (current && current !== "undefined") {
    return JSON.parse(current)
  }
  return {}
}

const getSSO = () => {
  const current = localStorage.getItem("sso")
  if (current && current !== "undefined") {
    return JSON.parse(current)
  }
  return [];
}

const getUploadConfig = () => {
  const current = localStorage.getItem("upload_config")
  if (current && current !== "undefined") {
    return JSON.parse(current)
  }
  return {};
}

const getFaceRecognition = () => {
  const current = localStorage.getItem("faceRecognition")
  if (current && current !== "undefined") {
    return JSON.parse(current)
  }
  return false;
}

const getObjectRecognition = () => {
  const current = localStorage.getItem("objectRecognition")
  if (current && current !== "undefined") {
    return JSON.parse(current)
  }
  return false;
}

const returnPusher = (token) => {
  Pusher.logToConsole = false
  if (token) {
    return new Pusher(Settings.PUSHER_APP_ID, {
      cluster: Settings.PUSHER_REGION,
      disableStats: true,
      forceTLS: true,
      authEndpoint: `${Settings.URL_API}/broadcasting/auth`,
      auth: {
        headers: { Authorization: token },
      },
    })
  }

  return null
}

const initialState = {
  hasInvalidDns: false,
  hasInvalidRegion: false,
  isUpdating: false,
  version: localStorage.getItem("version") || 0,
  canmanage: false,
  language: Lngi18nextInitial,
  checkingRegion: true,
  regionInvalid: null,
  dialogs: {
    expiredToken: false,
    policyDialog: false,
  },
  pusher: {
    connection: returnPusher(localStorage.getItem("user_token")),
    channels_connected: [],
    channels_connecting: [],
    binds_pending: [],
  },
  data: {
    name: localStorage.getItem("name") || "",
    client_slug: localStorage.getItem("client_slug") || "",
    client_id: localStorage.getItem("client_id") || "",
    platform_name: localStorage.getItem("platform_name") || "",
    description: localStorage.getItem("description") || "",
    favicon: localStorage.getItem("favicon"),
    primaryColor: localStorage.getItem("primaryColor"),
    logoLoginJPG: localStorage.getItem("logoLoginJPG"),
    logoLoginWEBP: localStorage.getItem("logoLoginWEBP"),
    logoInsideJPG: localStorage.getItem("logoInsideJPG"),
    logoInsideWEBP: localStorage.getItem("logoInsideWEBP"),
    originalBg: localStorage.getItem("originalBg"),
    links: localStorage.getItem("links"),
    logoTopMenuJPG: localStorage.getItem("logoTopMenuJPG"),
    logoTopMenuWEBP: localStorage.getItem("logoTopMenuWEBP"),
    sso: getSSO(),
    currentPolicy: getCurrentPolicy(),
    faceRecognition: getFaceRecognition(),
    objectRecognition: getObjectRecognition()
  },
  userData: {
    policies,
    other_regions: JSON.parse(localStorage.getItem("other_regions")) || [],
    can_manage: localStorage.getItem("can_manage") || "",
    region_id: localStorage.getItem("region_id") || "",
    region: localStorage.getItem("region") || "",
    region_name: localStorage.getItem("region_name") || "",
    region_thumbnail: localStorage.getItem("region_thumbnail") || "",
    path: localStorage.getItem("path") || "",
    user_auth: localStorage.getItem("user_auth") || "",
    user_id: localStorage.getItem("user_id") || "",
    user_token: localStorage.getItem("user_token") || "",
    user_email: localStorage.getItem("user_email") || "",
    profile_id: localStorage.getItem("profile_id") || "",
    hasSupport: localStorage.getItem("hasSupport") === "true" || false,
    current_policy_accepted: localStorage.getItem("current_policy_accepted") === 'false' ? false : true,
    upload_config: getUploadConfig(),
    enableNewItemFormPage: (localStorage.getItem("enableNewItemFormPage") === "true") || false,
  },
  hasIntegration: localStorage.getItem("hasIntegration") === "true" || false,
  requestHeaders: {
    headers: {
      Authorization: localStorage.getItem("user_token") || "",
      "X-Localization": Lngi18nextInitial,
      device: "desktop",
    },
  },
  pathServer:
    Settings.URL_API +
    "/api/" +
    localStorage.getItem("client_slug") +
    "/" +
    (localStorage.getItem("region") || ""),
  pathClient: Settings.URL_API + "/api/" + localStorage.getItem("client_slug"),
  searchText: "",
  palette: {
    primary: {
      main: primaryColor ? primaryColor : "",
      dark: primaryColor ? Utils.decreaseBrightness(primaryColor, 30) : "",
      contrastText: "#FFF",
    },
  },
  supportsWebp: Utils.WebpIsSupported(),
  markedItems: [],
  markedAssets: [],
  enqueueRequestCount: 0,
  navigation_view_mode: localStorage.getItem("navigation_view_mode") || "view-navigation",
  updated_item: null,
  csrf_token: ""
}

export const appReducer = (state = initialState, action) => {
  let newState

  const finishPusher = () => {
    if (state.pusher) {
      const { connection, channels_connected } = state.pusher
      const { user_id } = state.userData

      if (connection) {
        connection.unbind_all()

        if (user_id !== "") {
          channels_connected.forEach((channel) =>
            connection.unsubscribe(`${channel}-${user_id}`)
          )
        }
      }
    }

    return {
      channels_connected: [],
      channels_connecting: [],
      binds_pending: [],
    }
  }

  switch (action.type) {
    case ActionsType.UPDATED_VERSION:
      return {
        ...state,
        ...action,
        pathClient: Settings.URL_API + "/api/" + action.data.client_slug,
        pathServer: Settings.URL_API + "/api/" + action.data.client_slug + "/" + state.userData.region
      }

    case ActionsType.INVALID_DNS:
      return { ...state, ...action, hasInvalidDns: true }

    case ActionsType.INVALID_REGION:
      return { ...state, ...action, hasInvalidRegion: true }

    case ActionsType.IS_UPDATING:
      return { ...state, ...action, isUpdating: true }

    case ActionsType.UPDATE_USER_DATA:
      let headers = {
        Authorization: action.token,
        device: "desktop",
      }
      if (action.language || state.language) {
        headers = {
          ...headers,
          "X-Localization": action.language || state.language,
        }
      }

      let connection = null
      const pusher_initial_state = finishPusher()

      if (action.data) {
        if (action.data.user_token) {
          connection = returnPusher(action.data.user_token)
        }
      }
      return {
        ...state,
        userData: action.data,
        pathServer: action.path,
        pathClient: action.clientPath,
        language: action.language || state.language,
        navigation_view_mode: action.data.navigation_view_mode,
        requestHeaders: {
          headers: headers,
        },
        pusher: {
          connection,
          ...pusher_initial_state,
        },
      }

    case ActionsType.UPDATE_REGION:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.data,
        },
        checkingRegion: false,
        pathServer: action.path,
        hasIntegration: action.hasIntegration,
        markedItems: []
      }

    case ActionsType.REGION_CHECKED:
      return {
        ...state,
        checkingRegion: false,
        regionInvalid: action.regionInvalid,
      }

    case ActionsType.CHANGE_REGION:
      return {
        ...state,
        region_in_update: action.payload
      }

    case ActionsType.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
        requestHeaders: {
          headers: {
            Authorization: state.requestHeaders.headers.Authorization,
            device: state.requestHeaders.headers.device,
            "X-Localization": action.language,
          },
        },
      }

    case ActionsType.BIND_CHANNEL_SUCCEEDED:
      return {
        ...state,
        pusher: {
          ...state.pusher,
          channels_connecting: state.pusher.channels_connecting.filter(
            (channel) => channel !== action.channelName
          ),
          channels_connected: [
            ...state.pusher.channels_connected,
            action.channelName,
          ],
          binds_pending: state.pusher.binds_pending.filter(
            (bind) => bind.channel !== action.channelName
          ),
        },
      }

    case ActionsType.BIND_CHANNEL_FAILED:
      return {
        ...state,
        pusher: {
          ...state.pusher,
          channels_connecting: state.pusher.channels_connecting.filter(
            (channel) => channel !== action.channelName
          ),
          binds_pending: state.pusher.binds_pending.filter(
            (bind) => bind.channel !== action.channelName
          ),
        },
      }

    case ActionsType.BINDING_CHANNEL:
      return {
        ...state,
        pusher: {
          ...state.pusher,
          channels_connecting: [
            ...state.pusher.channels_connecting,
            action.channelName,
          ],
          binds_pending: [
            ...state.pusher.binds_pending,
            {
              channel: action.channelName,
              message: action.message,
              callback: action.callback,
              context: action.context
            },
          ],
        },
      }

    case ActionsType.CHANNEL_UNSUBSCRIBE:
      if (action.key) {
        state.pusher.connection.unsubscribe(`${action.channel}-${action.key}`)
      } else {
        state.pusher.connection.unsubscribe(
          `${action.channel}-${state.userData.user_id}`
        )
      }

      return {
        ...state,
        channels_connected: state.pusher.channels_connected.filter(
          (channel) => channel !== channel.channel
        ),
        channels_connecting: state.pusher.channels_connecting.filter(
          (channel) => channel !== action.channel
        ),
        binds_pending: state.pusher.binds_pending.filter(
          (bind) => bind.channel === action.channelName
        ),
      }

    case ActionsType.FINISH_INITIAL_LOADING:
      return { ...state, initialLoading: false }

    case ActionsType.SET_SEARCH_TEXT:
      return { ...state, searchText: action.text }

    case ActionsType.OPEN_DIALOG:
      newState = { ...state }
      newState.dialogs[action.target] = true
      return newState
    case ActionsType.SET_USER_DATA:
      newState = { ...state }
      newState.userData[action.data.field] = action.data.value
      return newState
    case ActionsType.CLOSE_DIALOG:
      newState = { ...state }
      newState.dialogs[action.target] = false
      return newState

    case ActionsType.CLEAR_USER_DATA:
      newState = { ...state, markedItems: [] }
      newState.pusher = {
        connection: null,
        ...finishPusher(),
      }
      newState.dialogs[action.target] = false

      const userData = [
        "policies",
        "other_regions",
        "can_manage",
        "region_id",
        "region",
        "region_name",
        "region_thumbnail",
        "path",
        "user_auth",
        "user_id",
        "user_token",
        "user_name",
        "user_email",
        "user_profile_image_original",
        "user_profile_image_webp",
        "profile_name",
        "profile_id",
        "historyDownloads",
        "upload_config",
      ]

      userData.map((item) => {
        localStorage.removeItem(item)
        return item
      })
      return newState

    case ActionsType.SET_SELECTED_ITEMS:
      if (Array.isArray(action.item) && action.item.length > 0) {
        return { ...state, markedItems: action.item }
      } else {
        return { ...state, markedItems: [...state.markedItems, action.item] }
      }


    case ActionsType.REMOVE_SELECTED_ITEMS:
      return {
        ...state,
        markedItems: state.markedItems.filter(
          (m) => m.id !== action.items.id
        ),
      }

    case ActionsType.CLEAR_ITEMS_SELECTEDS:
      return { ...state, markedItems: [] }

    case ActionsType.SHOW_CONFIRM_DIALOG_REGION:
      return {
        ...state, showDialogRegion: {
          open: true,
          data: action.data,
        }
      }

    case ActionsType.HIDDEN_CONFIRM_DIALOG_REGION:
      return {
        ...state, showDialogRegion: {
          open: false,
          data: {},
          action: ''
        }
      }

    case ActionsType.MARK_ASSET:
      if (!state.markedAssets.some((f) => f.id === action.asset.id)) {
        return { ...state, markedAssets: [...state.markedAssets, action.asset] }
      } else {
        return state
      }

    case ActionsType.UNMARK_ASSET:
      return {
        ...state,
        markedAssets: state.markedAssets.filter(
          (m) => m.id !== action.asset.id
        ),
      }

    case ActionsType.CLEAR_MARKED_ASSETS:
      return { ...state, markedAssets: [] }

    case ActionsType.MARK_LIST_ASSETS:
      return {
        ...state,
        markedAssets: action.assets,
      }

    case ActionsType.INCREASE_REQUEST_COUNT:
      return { ...state, enqueueRequestCount: state.enqueueRequestCount + 1 }

    case ActionsType.DECREASE_REQUEST_COUNT:
      return { ...state, enqueueRequestCount: state.enqueueRequestCount - 1 }

    case ActionsType.SET_USER_MODE_VIEW:
      return { ...state, navigation_view_mode: action.option }

    case ActionsType.SET_HAS_SUPPORT_CURRENT_REGION:
      return { ...state, userData: { ...state.userData, hasSupport: action.option } }

    case ActionsType.SET_COLOR_PLATFORM:
      return {
        ...state,
        palette: {
          primary: {
            ...state.palette,
            main: action.option
          },
        }
      }

    case ActionsType.UPLOAD_FILE_PROCESSED:
      return {
        ...state,
        updated_item: action.data.item ?? null
      }

    case ActionsType.SET_UPDATED_ITEM:
      console.log("SET_UPDATED_ITEM")
      return {
        ...state,
        updated_item: action.updated_item
      }

    case ActionsType.TRIGGER_ERROR:
    default:
      return state
  }
}
