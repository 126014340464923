import React from "react"
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import { Fab, Typography, Box } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

import Pagination from '../Pagination'
import classNames from "classnames"
import Carousel from "../SwipableCarouselView"
import { useDataAssetIntegra } from "../../context"
import { useStyles } from "./styles"

import Utils from "utils/Utils"
import DetailsAsset from '../Details'
import DownloadButton from '../DownloadButton'
import ItemDropzone from "../../ItemDropzone";
import FileUploadIcon from "@material-ui/icons/CloudUpload"

function DropzoneComponent({ children }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { getRootProps, isDragActive } = useDropzone({})

  return (
    <div {...getRootProps({ onClick: event => event.stopPropagation() })} className={classes.root} >
      {isDragActive && <div className={classes.dragDisplay}>
        <FileUploadIcon className={classes.cloudUploadIcon} />
        <Box style={{
          padding: '0 16px',
          textAlign: 'center'
        }}>
          <Typography variant="h5" gutterBottom>
            {t("DAM:Esta opção não está disponível para este item, apenas pelo Gerenciador de Ativos.")}
          </Typography>
        </Box>
      </div>}
      {children}
    </div>
  );
}

const AutoRotatingCarousel = ({ children, containerStyle, mobile, countAssets, handleChangeSlide, context }) => {
  const { slideIndex, setSlideIndex, openDetailsAsset, openDownloadButton, stateItem: { isGallery = false } } = useDataAssetIntegra()
  const classes = useStyles()
  const handleContentClick = (e) => e.stopPropagation() || e.preventDefault()
  const decreaseIndex = () => {
    setSlideIndex(() => slideIndex - 1)
  }

  const hasOneChildren = countAssets > 1

  const carousel = (
    <Carousel
      className={classes.carousel}
      containerStyle={{ height: "100%", ...containerStyle }}
      index={slideIndex}
      onChangeIndex={handleChangeSlide}
      slideClassName={classes.slide}
    >
      {children}
    </Carousel>
  )

  const childComponents =
    <>
      <div className={classes.rootWrapper}>
        {hasOneChildren && (
          <Fab
            className={classes.arrow}
            onClick={() => decreaseIndex()}
            disabled={slideIndex === 0}
          >
            <ArrowBackIcon className={classes.arrowIcon} />
          </Fab>
        )}

        <div
          className={classNames(classes.content, {
            [classes.contentMobile]: mobile,
          })}
          onClick={handleContentClick}
        >
          <div elevation={mobile ? 0 : 1} className={classes.carouselWrapper}>
            {carousel}
          </div>

          {hasOneChildren && (
            <div
              className={classNames(classes.footer, {
                [classes.footerMobile]: mobile,
              })}
            >
              <Pagination
                count={countAssets}
                page={Utils.modulo(slideIndex, countAssets)}
                handleChange={handleChangeSlide}
              />
            </div>
          )}
        </div>
        {hasOneChildren && (
          <Fab
            className={classes.arrow}
            onClick={() => handleChangeSlide(slideIndex + 1)}
            disabled={slideIndex === countAssets - 1}
          >
            <ArrowForwardIcon className={classes.arrowIcon} />
          </Fab>
        )}
      </div>
      {
        openDetailsAsset &&
        <DetailsAsset dataAsset={children[slideIndex]} />
      }
      {
        openDownloadButton &&
        <DownloadButton dataAsset={children[slideIndex]} />
      }
    </>

  if (Boolean(isGallery)) {
    return (
      <DropzoneComponent>
        {childComponents}
      </DropzoneComponent>
    )
  }

  return (
    <ItemDropzone className={classes.root} context_id={context.item_id}>{childComponents}</ItemDropzone>
  )
}

AutoRotatingCarousel.defaultProps = {
  mobile: false,
}

export default AutoRotatingCarousel
