import React from "react";
import { connect } from "react-redux";
import axios from "axios/index";
import { getDomain, validHexColor } from 'utils/Helpers'
import AppActions from "flux/actions/AppActions";
import Settings from "utils/Settings";
import 'react-toastify/dist/ReactToastify.css';
class Data extends React.Component {
  constructor(props) {
    super(props);
    this.getClientData = this.getClientData.bind(this);
  }

  componentDidMount() {
    this.getClientData()
  }

  getClientData() {
    const { updateVersion, invalidDns, updatingApp, requestHeaders } = this.props;

    const domain = getDomain();
    axios.post(Settings.URL_API + '/client', { dns: domain }, requestHeaders)
      .then(function ({ data }) {
        const DATA = {
          ...data,
          primary_color: validHexColor(data.primary_color)
        }
        updateVersion(DATA)
      })
      .catch(function (err) {
        if (err.response) {
          if (err.response.status === 422) {
            invalidDns(err.response);
          }
          else if (err.response.status === 503) {
            updatingApp(err.response);
          }
        }
      });
  }
  render() {
    return null
  }
}

const mapStateToProps = ({ appReducer }) => {
  return {
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateVersion: (data) => dispatch(AppActions.updateVersion(data)),
    invalidDns: (response) => dispatch(AppActions.invalidDns(response)),
    updatingApp: (response) => dispatch(AppActions.updatingApp(response)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Data);