import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Avatar from "@material-ui/core/Avatar";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';

let styles = {
    box: {
        display: "flex",
        flexWrap: 'wrap',
        padding: "10px"
    },
    item: {
        margin: "0.5%"
    },
    chip: {
        margin: "3px 3px"
    }
};

const DragHandle = SortableHandle(() => {
    return (
        <Avatar style={{width: 32, height: 32, background: "rgba(255,255,255, .1)", left: -1}}>
            <DragIndicatorIcon/>
        </Avatar>
    )
});

const SortableItem = SortableElement(({value}) => {
    let {entity, label, column, handleRemoveTaxonomy} = value;
    return (
        <Chip
            icon={<DragHandle/>}
            style={styles.chip}
            label={label}
            onDelete={(evt) => handleRemoveTaxonomy(entity, column, evt)}
            color="primary"
            data-entity={entity}
            data-column={column}
        />
    )
});

const SortableList = SortableContainer(({items}) => {
    return (
        <Paper style={styles.box}>
            {items.map((item, index) => {
                return <SortableItem key={`item-${index}`} index={index} value={item}/>;
            })}
        </Paper>
    );
});

class BoxTaxonomy extends React.Component {
    render() {
        const {items, handleMoveTaxonomy} = this.props
        return (
            <SortableList
                useDragHandle={true}
                items={items}
                onSortEnd={handleMoveTaxonomy}
                axis='xy'
            />
        );
    }
}

export default withStyles(styles)(BoxTaxonomy);