import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import {withStyles} from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

export const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
))

class ProgressDialog extends React.Component {
    getContent() {
        const {totalProgress, progress, loading, confirm, entity, entityName, t} = this.props;
        const COLOR1 = localStorage.getItem("primaryColor") || "";
        const COLOR3 = localStorage.getItem("primaryColor3") || "";
        const styleItalic = {
            fontStyle: 'italic'
          };


        const ColorLinearProgress = withStyles({
            colorPrimary: {
              backgroundColor: COLOR1,
            },
            barColorPrimary: {
              backgroundColor: COLOR3,
            },
        })(LinearProgress);

        if(confirm) {
            return  <React.Fragment>
                        <Typography gutterBottom>
                            {t('texto-alteracao-entity-1')}{entity.toString().toLowerCase()}&nbsp;<span style={styleItalic}>{entityName}</span>&nbsp;{t('texto-alteracao-entity-2')}
                        </Typography>
                        <Typography gutterBottom>{t('common:Tem certeza que deseja realizar essa alteração?')}</Typography>
                    </React.Fragment>
        }

        if(loading) {
            const normalise = value => (value - 0) * 100 / (totalProgress - 0);
            return totalProgress > 0
            ? <ColorLinearProgress variant="determinate" value={normalise(progress)} />
            : <CircularProgress />;
        } else {
            return t("Esta tela pode ser fechada")
        }
    }

    getActions() {
        const {handleClose, handleConfirm, handleCancel, confirm, loading, t} = this.props;

        if(confirm) {
            return <React.Fragment>
                        <Button onClick={handleConfirm} color="primary">Sim</Button>
                        <Button onClick={handleCancel} color="primary">Não</Button>
                    </React.Fragment>
        } else {
            return <Button onClick={handleClose} color="primary">
                {loading ? t("Fechar, o processamento continuará") : t("common:Fechar")}
            </Button>;
        }
    }

    render() {
        const {open, title} = this.props;

        return (
            <Dialog
                keepMounted
                maxWidth={"sm"}
                fullWidth={true}
                open={open}
                TransitionComponent={Transition}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title">
                  {title}
                </DialogTitle>
                <DialogContent>{this.getContent()}</DialogContent>
                <DialogActions>{this.getActions()}</DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['manager', 'common'])(ProgressDialog)
