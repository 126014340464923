import React from "react"
import axios from "axios"
import _ from 'lodash'
import DialogDeleteList from "../DialogDeleteList"

import { toast } from "react-toastify"
import { responseError } from "../../../../utils/Ajax"

import AccessDeniedSimpleAlert from "../../AccessDeniedSimpleAlert"

import { DataGrid } from "@material-ui/data-grid"

import HeaderTable from "./HeaderTable"
import CustomNoRowsOverlay from "./CustomNoRowsOverlay"
import CustomFooterTable from "./CustomFooterTable"

import { DEFAULT_LOCALE_TEXT } from "../utils"

class DataGridCustom extends React.Component {
  constructor(props) {
    const LOCAL_DATA = JSON.parse(sessionStorage.getItem("manager"))

    super(props)
    this.state = {
      accessDenied: false,
      loading: true,
      deleteDialogOpen: false,
      idToDelete: "",
      nameToDelete: "",
      page: 1,
      limit: 10,
      searchText: '',
      data: [],
      page_last: [],
      isEmptyLocalData: _.isEmpty(LOCAL_DATA),
      initial_page: 1,
    }

    this.responseError = responseError.bind(this)
    this.runSearch = this.runSearch.bind(this)
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true

    if (!this.state.isEmptyLocalData) {
      const { entity } = JSON.parse(sessionStorage.getItem("manager"))
      if (Boolean(entity) && entity !== this.state.entity) {
        sessionStorage.removeItem("manager")
        this.getData()
        return
      }
    }

    this.getData(!this.state.isEmptyLocalData)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    const headerPrev = prevProps.requestHeaders.headers
    const headerNext = this.props.requestHeaders.headers

    if (headerPrev['X-Localization'] !== headerNext['X-Localization']) {
      this.getData(!this.state.isEmptyLocalData)
    }
  }

  getUrl() {
    const { pathServer } = this.props
    return `${pathServer}/${this.state.entity}`
  }

  getLocalParameters() {
    const SELF = this
    const LOCAL_DATA = JSON.parse(sessionStorage.getItem("manager"))

    let payload = {
      limit: _.get(LOCAL_DATA, 'limit', SELF.state.limit),
      page: _.get(LOCAL_DATA, 'page', SELF.state.page),
    }

    if (_.get(LOCAL_DATA, 'last_pages_history', SELF.state.page_last).length > 0
      && _.get(LOCAL_DATA, 'page', SELF.state.page) > 0
    ) {
      const getLast = _.get(LOCAL_DATA, 'last_pages_history', SELF.state.page_last).find(
        (i) => i.page === _.get(LOCAL_DATA, 'page', SELF.state.page)
      )
      if (Boolean(getLast) && getLast.last) {
        payload["last"] = getLast.last
      }
    }

    if (_.get(LOCAL_DATA, 'name.keyword', _.get(LOCAL_DATA, "search", false))) {
      const field_search = SELF.state.entity === 'user' ? "search" : "name.keyword"
      payload[field_search] = _.get(LOCAL_DATA, 'name.keyword', _.get(LOCAL_DATA, 'search', ''))
    }

    return payload
  }

  getParameters() {
    const SELF = this

    let payload = {
      limit: SELF.state.limit,
      page: SELF.state.page,
    }

    if (SELF.state.page_last.length > 0 && SELF.state.page > 0) {
      const getLast = SELF.state.page_last.find(
        (i) => i.page === SELF.state.page
      )

      if (Boolean(getLast)) {
        payload["last"] = getLast.last
        payload["last_pages_history"] = SELF.state.page_last
      }
    }

    if (SELF.state.searchText !== "") {
      if (SELF.state.entity === 'user') {
        payload["search"] = `${SELF.state.searchText
          .split(" ")
          .join("*")}`
      } else {
        payload["name.keyword"] = `*${SELF.state.searchText
          .split(" ")
          .join("*")}*`
      }

      payload["search_text_label"] = SELF.state.searchText
    }

    payload['entity'] = SELF.state.entity

    sessionStorage.setItem("manager", JSON.stringify(payload))

    delete payload.name_label
    delete payload.last_pages_history
    delete payload.entity
    delete payload.search_text_label

    return payload
  }

  getData(local) {
    const SELF = this
    const { requestHeaders } = SELF.props
    SELF.setState({ ...SELF.state, loading: true, data: [] })
    let params = local ? this.getLocalParameters() : this.getParameters()
    const LOCAL_DATA = JSON.parse(sessionStorage.getItem("manager"))

    axios
      .post(`${this.getUrl()}/find`, params, requestHeaders)
      .then(
        (resp) => {
          const DATA = {
            loading: false,
            data: resp.data.items,
            total: resp.data.total,
            page: resp.data.page,
          }

          if (resp.data.last) {
            DATA["page_last"] = [
              ...this.state.page_last,
              { page: resp.data.page + 1, last: resp.data.last },
            ]
          } else {
            DATA["page_last"] = []
          }
          if (_.get(resp, 'data.items', []).some(i => i.id)) {
            SELF._isMounted &&
              SELF.setState({
                ...SELF.state,
                ...DATA,
                searchText: _.get(LOCAL_DATA, 'search_text_label', '')
              })
          } else {
            throw new Error("Error: incomplete data to assemble the list")
          }
        },
        (err) => {
          if (err.response.status) {
            SELF._isMounted &&
              this.setState({
                ...SELF.state,
                accessDenied: err.response.status !== 404,
                accessDeniedStatus: err.response.status,
                loading: false,
              })
          }

          if (err.response.status !== 404) {
            return SELF.responseError(err)
          }
        }
      )
      .catch(
        (err) =>
          SELF._isMounted &&
          SELF.setState({
            ...SELF.state,
            loading: false,
            error: true,
          })
      )
  }

  handleClose = () => {
    this.setState({ deleteDialogOpen: false })
  }

  handleDelete = () => {
    const SELF = this
    const { requestHeaders, triggerError } = SELF.props
    axios
      .delete(`${this.getUrl()}/${SELF.state.idToDelete}`, requestHeaders)
      .then(
        function (resp) {
          SELF.setState(
            {
              ...SELF.state,
              deleteDialogOpen: false,
            },
            () => SELF.getData(!SELF.state.isEmptyLocalData)
          )
          toast.success(resp.headers["x-message"], {
            autoClose: 1500,
            pauseOnHover: false,
          })
        },
        function (err) {
          if (err.response.data.hasOwnProperty("errors")) {
            Object.values(err.response.data.errors).forEach((item) => {
              item.forEach((message) => {
                toast.error(message)
              })
            })
          } else {
            let message = ''
            Object.values(err.response.data).forEach((item) => {
              message = item
            })

            toast.error(message || err.response.headers["x-message"])
          }
          SELF.setState({ deleteDialogOpen: false })
        }
      )
      .catch((err) => triggerError(err))
  }

  changePage(page) {
    const SELF = this

    if (page !== SELF.state.page) {
      const DATA = {
        page: page,
      }

      SELF.setState(
        {
          ...SELF.state,
          ...DATA,
        },
        () => SELF.getData()
      )
    } else {
      return
    }
  }

  changeRowsPerPage(evt) {
    const SELF = this

    if (evt.target.value !== SELF.state.limit) {
      SELF.setState(
        {
          ...SELF.state,
          limit: evt.target.value,
          page: SELF.state.initial_page,
        },
        () => SELF.getData()
      )
    } else {
      return
    }
  }

  runSearch(text) {
    const SELF = this
    const rowsPerPage = SELF.state.limit

    if (text) {
      SELF.setState(
        {
          ...SELF.state,
          limit: rowsPerPage,
          page: SELF.state.initial_page,
          searchText: text,
          page_last: []
        },
        () => SELF.getData()
      )
    } else {
      SELF.setState(
        {
          ...SELF.state,
          limit: rowsPerPage,
          page: SELF.state.initial_page,
          searchText: "",
          page_last: []
        },
        () => SELF.getData()
      )
    }
  }

  disabledAddButton() {
    return false
  }

  customComponentHeader() {
    return null
  }

  render() {
    const SELF = this
    const { classes } = this.props

    if (this.state.accessDenied) {
      return <AccessDeniedSimpleAlert status={this.state.accessDeniedStatus} />
    }

    const { deleteDialogOpen } = this.state

    const columns = this.getColumns()
    const dataTable = this.state.data || []
    const dataRows = dataTable.map((i) => SELF.getLine(i))

    return (
      <>
        <DataGrid
          className={classes ? classes.rootDataGrid : "root-data-grid"}
          rows={dataRows}
          columns={columns}
          loading={this.state.loading}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          components={{
            ColumnResizeIcon: () => null,
            NoRowsOverlay: CustomNoRowsOverlay,
            Pagination: () => (
              <CustomFooterTable
                page={this.state.page}
                isNotPageOne={Boolean(this.state.isNotPageOne)}
                loading={this.state.loading}
                rowsLength={this.state.total}
                rowsPerPage={this.state.limit}
                handleChangePage={(page) => this.changePage(page)}
                handleChangeRowsPerPage={(evt) =>
                  this.changeRowsPerPage(evt)
                }
              />
            ),
            Toolbar: () => (
              <HeaderTable
                title={this.state.title}
                handleSubmitSearch={this.runSearch}
                hasSearchText={this.state.searchText}
                route={this.state.route}
                entity={this.state.entity}
                disabledAddButton={this.disabledAddButton()}
                customComponentHeader={this.customComponentHeader()}
              />
            ),
          }}
          rowHeight={60}
          onCellClick={(evt) =>
            this.handleClickRow ? this.handleClickRow(evt) : {}
          }
          localeText={DEFAULT_LOCALE_TEXT}
          error={this.state.error}
        />


        {deleteDialogOpen &&
          <DialogDeleteList
            handleClose={this.handleClose}
            handleDelete={this.handleDelete}
            open={deleteDialogOpen}
            entity={SELF.props.t(`common:${this.state.title.singular}`)}
            name={this.state.nameToDelete}
          />
        }
      </>

    )
  }
}

export default DataGridCustom
