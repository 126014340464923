import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from 'prop-types';
import axios from 'axios'
import {
    Tooltip,
    Button,
    IconButton,
    Badge,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";

import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

import DialogConfirmValidation from "components/Alert/DialogConfirmValidation";
import AppActions from "flux/actions/AppActions";

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

import Loading from "components/Loading"

import Settings from 'utils/Settings'

const MarkedAssets = ({ checkedValue, getData, handleViewItens, simpleBucket }) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        markedAssets,
        requestHeaders,
        pathServer,
    } = useSelector((state) => state.appReducer);

    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const handleOpenDialogDelete = () => {
        setOpenDelete(prev => !prev);
    };

    const handleClearItemList = () => {
        dispatch(AppActions.clearMarkedAssets())
        getData()
    };

    const totalmarkedAssets = markedAssets.length;

    if (markedAssets.length <= 0) {
        return null
    }

    const handleConfirmDeleteAllMarked = (text) => {
        const idsmarkedAssets = markedAssets.map((item) => item.id)

        setLoading(true,
            axios.delete(`${pathServer}/assets`, {
                ...requestHeaders,
                data: { uuids: idsmarkedAssets, confirm_message: text },
            })
                .then(
                    function (resp) {
                        enqueueSnackbar(resp.headers["x-message"], {
                            ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                        })
                        dispatch(AppActions.clearMarkedAssets())
                        setLoading(false)
                        getData()
                    },
                    (error) => {
                        setLoading(false)
                        enqueueSnackbar(_.get(error, "response.headers.x-message", `Erro inesperado:${error.message} .`),
                            {
                                variant: "error",
                                autoHideDuration: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center",
                                },
                            });
                    }
                )
                .catch((err) => {
                    setLoading(false)
                    enqueueSnackbar(t("manager:Erro inesperado. Contate o suporte"), {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    });
                }))
    }

    const totalMarkedAssets = markedAssets.length

    return (
        <>
            <Loading showLoading={loading} />           
            <Tooltip title={t("DAM:Mostrar selecionados")} placement="top">
                <FormControlLabel
                    control={<Checkbox icon={
                        <IconButton>
                            <Badge badgeContent={totalmarkedAssets} color="primary">
                                <CheckCircleOutlineRoundedIcon />
                            </Badge>
                        </IconButton>
                    } checkedIcon={
                        <IconButton>
                            <Badge badgeContent={totalmarkedAssets} color="primary">
                                <CheckCircleRoundedIcon color="primary" />
                            </Badge>
                        </IconButton>
                    }
                        name="checkedH"
                        style={{ margin: 0 }}
                        checked={checkedValue}
                        onClick={handleViewItens}
                    />}
                />
            </Tooltip>

            {!simpleBucket && (
                <DialogConfirmValidation
                    openDefault={openDelete}
                    title={t("common:Arquivos para exclusão")}
                    description={totalMarkedAssets === 1
                        ? t(`common:Você deseja realmente excluir o arquivo selecionado?`)
                        : t(`common:Você deseja realmente excluir todos os arquivos selecionados?`, {
                            total: totalMarkedAssets,
                        })}
                    phraseInput={totalMarkedAssets === 1 ? t(`common:Excluir arquivo`, { total: totalMarkedAssets }) : t(`common:Excluir arquivos`, { total: totalMarkedAssets })}
                    bucket
                    icon={<DeleteRoundedIcon />}
                    handleDelete={handleConfirmDeleteAllMarked}
                    handleOpenDialogDelete={handleOpenDialogDelete}
                    total={totalmarkedAssets}
                >
                    {(confirm) => (
                        <Tooltip title={t("manager:Excluir")} placement="top">
                            <IconButton
                                disableRipple
                                aria-haspopup="true"
                                color="inherit"
                                onClick={confirm()}
                                disabled={totalMarkedAssets > 100}
                            >
                                <DeleteRoundedIcon
                                    id={openDelete ? 'svg-primary' : ''}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </DialogConfirmValidation>
            )}

            <Tooltip title={t("DAM:Limpar lista")} placement="top">
                <Button
                    component='a'
                    onClick={handleClearItemList}
                >
                    {t("DAM:Limpar")}
                </Button>
            </Tooltip>
        </>
    );
};

MarkedAssets.defaultProps = {
    checkedValue: false,
    simpleBucket: false,
    getData: () => { },
    handleViewItens: () => { },
}

MarkedAssets.propTypes = {
    simpleBucket: PropTypes.bool,
    checkedValue: PropTypes.bool,
    handleViewItens: PropTypes.func,
    getData: PropTypes.func,
}

export default MarkedAssets

