import React, { Fragment, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";

import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Paper,
  Typography,
  Fade,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useStyles } from "./styles";

const Login = ({ t, handleSubmit, loading, csrf_token }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (email.length > 6) {
      if (!!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
  }, [email]);

  const validation = (evt) => {
    evt.preventDefault();
    if (!(emailError)) {
      handleSubmit({ email, password, csrf_token });
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter" && !(emailError)) {
      handleSubmit({ email, password, csrf_token });
    }
  };

  return (
    <Fragment>
      <Paper
        elevation={2}
        className={classes.login}
        onKeyDown={(evt) => handleKeyDown(evt)}
      >
        <div className="logoImageLogin" />
        <Typography className="descriptionMobile">
          {t('common:Solicitação de acesso')}
        </Typography>
        <form className="formLogin">
          <div style={{ width: "100%", height: "83px" }}>
            <TextField
              error={emailError}
              helperText={emailError ? t("common:E-mail inválido") : null}
              variant="outlined"
              id="email"
              label="E-mail"
              name="email"
              value={email}
              autoComplete="email"
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>
          <div style={{ width: "100%", height: "83px" }}>
            <TextField
              variant="outlined"
              name="password"
              label={t("login:Digite sua senha")}
              type={showPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <TextField
            name="csrf_token"
            id="csrf_token"
            value={csrf_token}
            style={{display: 'none'}}
          />
          <Button
            id="submit"
            disabled={loading ? true : false}
            type="submit"
            variant="contained"
            color="primary"
            onClick={validation}
            style={{fontSize: '1rem'}}
          >
            {loading ? (
              <Fade
                in={loading}
                style={{
                  transitionDelay: loading ? "800ms" : "0ms",
                }}
                unmountOnExit
              >
                <CircularProgress color="inherit" />
              </Fade>
            ) : (
              t("login:Acessar")
            )}
          </Button>
        </form>
      </Paper>
    </Fragment>
  );
};

export default withTranslation(["common", "login"])(Login);
