import React from "react"

import { useTranslation } from 'react-i18next'
import Settings from 'utils/Settings'
import View from './view'

function MenuTypes({ setParametersFilters, filtersParams }) {
    const { t } = useTranslation()

    const options = Settings.DATA_LABELS_FILTER_BUCKET

    const handleMenuItemClick = (data) => {
        let newData = [...filtersParams]

        if(data.field === 'ALL') {
            newData = []
        } else {
            if(data.value) {
                newData.push(data.field)
            } else {
                newData = newData.filter(i => i !== data.field)
            }
        }

        setParametersFilters('fileTypes', newData)
    }

    return (
            <View
                title={t("DAM:Tipo")}
                options={options}
                selecteds={filtersParams}
                handleMenuItemClick={handleMenuItemClick}
            />
    )
}


export default MenuTypes
