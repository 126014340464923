import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootGrid: {
    width: "100%",
    padding: "20px 0",
    justifyContent: 'center',
  },
  paperForm: {
    padding: 20,
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 50,
    color: "#666",
    '& span.label': {
      marginBottom: 5,
      fontWeight: 500,
    },
    '& div.react-select__value-container': {
      padding: '13.5px 14px',
    },
    '& div.react-select__single-value': {
      padding: '2px 0'
    },
  },
  labelDescription: {
    top: "12px",
    margin: '0!important',
    position: "relative",
    background: "#fff",
    display: 'inline-block !important',
    left: "11px",
    padding: "0 3px",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: 1,
    letterSpacing: '0.00938em',
    zIndex: 1
  },
  wrapperRichText: {
    minHeight: "200px",
    border: "1px solid #bababa",
    borderRadius: "4px",
  },
  rootForm: {
    padding: "0 10px",
  },
  select: {
    width: '100%',
    borderRadius: 4,
    textAlign: 'left',
    fontSize: 15,
    color: '#666',
    border: '1px solid #bababa',
    padding: '10px 8px',
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
}));
