import React, { createContext, useState, useContext, useCallback } from "react"
import _ from 'lodash'
import axios from "axios"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom"
import { useSnackbar } from 'notistack'
import Utils from 'utils/Utils'
import Settings from 'utils/Settings'
import { slugify } from 'utils/Helpers'
import { validation, attributesForInput } from 'components/CategoryAttributes'
import { Creators as AssetActions } from "flux/ducks/Asset"
import AppActions from "flux/actions/AppActions"

const FormItemContext = createContext()

export default function FormItemProvider({ children, isEdit = false }) {
  const [loading, setLoading] = useState({ loading: true, checkedData: false })
  const [segments, setSegments] = useState({
    isCanPublish: { id: "item", value: false },
    segmentIem: {},
    categoryData: {},
    attributes: [], 
    initalAttribute: [],
    dataFields: {},
    errorFields: {},
    errors: {},
    relatedItems: [],
    isGallery: false
  })
  const [relatedItems, setRelatedItems] = useState([])
  const [info, setInfo] = useState({
    itemId: "",
    name: "",
    editorState: null,
  })
  const [published, setPublished] = useState({
    option: "published",
    startDate: "",
    validityDate: "",
  })
  const [updatePageFunction, setUpdatePageFunction] = useState({ func: () => { } })
  const { pathServer, requestHeaders, markedItems } = useSelector(state => state.appReducer)
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const setInitialState = useCallback((newData = {}, callback, updateFunction) => {
    setInfo(prev => ({
      ...prev,
      itemId: newData.id,
      name: _.get(newData, 'name', ''),
      editorState: {
        text: _.get(newData, 'description.text', ''),
        data: Utils.getFormattedDescription(newData.description, t)
      },
    }))
    setSegments(prev => ({
      ...prev,
      segmentIem: {
        label: _.get(newData, 'division.name', '') + ' > ' + _.get(newData, 'segment.name', ''),
        name: _.get(newData, 'segment.name', ''),
        value: _.get(newData, 'segment.id', ''),
      },
      categoryData: {
        label: _.get(newData, 'category.name', ''),
        value: _.get(newData, 'category.id', ''),
        canLink: _.get(newData, 'category.canLink', false),
      },
      isGallery: _.get(newData, 'isGalleryType', false),
      isGalleryInitial: _.get(newData, 'isGalleryType', false),
      isCanPublish: _.get(newData, 'canPublish', false),
      dataFields: _.get(newData, 'dataFields', {}),
      attributes: _.get(newData, 'attributes', []),
      initalAttribute: _.get(newData, 'attribute', [])
    }))
    setRelatedItems(_.get(newData, 'relatedItems', []))

    let defaultpublished = {}

    if (_.get(newData, 'published', false)) {
      defaultpublished["option"] = "published"
    } else {
      defaultpublished["option"] = "unpublished"
    }

    if (_.get(newData, 'start_date', '') || _.get(newData, 'validity_date', '')) {
      defaultpublished["option"] = "validity"
    }

    defaultpublished["startDate"] = _.get(newData, 'start_date', '')
    defaultpublished["validityDate"] = _.get(newData, 'validity_date', '')

    setPublished(prev => ({
      ...prev,
      ...defaultpublished,
      validation: JSON.stringify(defaultpublished)
    }))

    setLoading({ loading: false, checkedData: true })
    setUpdatePageFunction({ func: updateFunction })
    if (callback) callback()
  }, [t])

  const changeLoading = useCallback((data) => {
    setLoading(prev => ({ ...prev, ...data }))
  }, [])

  const changePublishField = useCallback((id, value) => {
    const id_current = _.get(segments.isCanPublish, 'id', '')
    const value_current = _.get(segments.isCanPublish, 'value', '')

    if (id === 'segment') {
      if (!(id_current === 'category' && value_current)) {
        setSegments(prev => ({ ...prev, isCanPublish: { value, id } }))
      }
    }

    if (id === 'category') {
      if (!(id_current === 'segment' && value_current)) {
        setSegments(prev => ({ ...prev, isCanPublish: { value, id } }))
      }
    }
  }, [segments.isCanPublish])

  const changeSegment = useCallback((data) => {
    setSegments(prev => ({ ...prev, ...data }))
  }, [])

  const changeRelatedItems = useCallback((items) => {
    setRelatedItems((items && items.length > 0) ? items : [])
  }, [])

  const changeName = useCallback((value) => {
    setInfo(prev => ({ ...prev, name: value }))
  }, [])

  const changeEditorDraft = useCallback((data = {}) => {
    setInfo(prev => ({ ...prev, ...data }))
  }, [])

  const changeAttributes = useCallback((data, id) => {
    setSegments(prev => {
      let errorFields = prev.errorFields
      delete errorFields[id]
      return {
        ...prev,
        dataFields: data,
        errorFields: errorFields
      }
    })
  }, [])

  const changePublished = useCallback((evt) => {
    const option = _.get(evt, "target.value", "")
    setPublished(prev => ({
      ...prev,
      option: option,
      startDate: "",
      validityDate: "",
    }))
  }, [])

  const changeDates = useCallback((field, value) => {
    setPublished(prev => ({ ...prev, [field]: value }))
  }, [])

  const callbackCreate = useCallback(() => {
    dispatch(AssetActions.clearPathname())
    dispatch(AssetActions.setAllData([]))
    dispatch(AppActions.markListAssets([]))
    history.goBack()
  }, [dispatch, history])

  const callbackEdit = useCallback((taxonomy = "", params) => {
    const updateSetmarkedItems = markedItems.map((item) => {
      if (item.id === params.itemId) {
        return { ...item, ...params, taxonomy: taxonomy };
      }
      return item;
    });
    if (updateSetmarkedItems.length > 0) {
      dispatch(AppActions.setmarkedItems(updateSetmarkedItems))
    }

    const pathItem = {
      ...(_.get(params, 'segmentIem.division', false)
        && { division: slugify(_.get(params, 'segmentIem.division', '')) }),
      ...(_.get(params, 'segmentIem.name', false)
        && { segment: slugify(_.get(params, 'segmentIem.name', '')) }),
      ...(_.get(params, 'categoryData.label', false)
        && { category: slugify(_.get(params, 'categoryData.label', '')) })
    }
    setLoading(prev => ({ ...prev, loading: false }), updatePageFunction.func({ ...pathItem, taxonomy: taxonomy.slug }))
  }, [dispatch, updatePageFunction, markedItems])

  const submit = useCallback((params) => {
    axios({
      method: isEdit ? "PUT" : "POST",
      url: isEdit ? `${pathServer}/item/${params.itemId}` : `${pathServer}/item`,
      data: params,
      headers: { ...requestHeaders.headers },
    })
      .then((resp) => {
        enqueueSnackbar(resp.headers["x-message"] || t("Registro inserido com sucesso."), {
          ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
        })
        if (isEdit) {
          callbackEdit(_.get(resp, 'data.taxonomy', {}), params)
        } else {
          callbackCreate()
        }
      })
      .catch(err => {
        console.log(err)
        let message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao salvar dados do item, tente novamente.`))

        if (_.get(err, 'response.status', '') === 409 && _.get(err, 'response.data.taxonomy', false)) {
          message = _.get(err, 'response.data.taxonomy')
        }

        if (_.get(err, 'response.status', '') === 422 && _.get(err, 'response.data.validity_date', false)) {
          message = _.get(err, 'response.data.validity_date')
        }

        if (_.get(err, 'response.status', '') === 406 && _.get(err, 'response.data.secondary_key_duplicated')) {
          message = _.get(err, 'response.data.secondary_key_duplicated')
        }
        setLoading(prev => ({ ...prev, loading: false }),
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }))
      })
  }, [requestHeaders, pathServer, enqueueSnackbar, isEdit, t, callbackCreate, callbackEdit])

  const getParameters = useCallback(() => {
    const [attributes, unset_attributes]
      = attributesForInput(segments.dataFields, segments.attributes)
    let parameters = {
      itemId: info.itemId,
      name: info.name,
      description: {
        text: _.get(info.editorState, 'text', t("DAM:Sem descrição")),
        formatter: {
          name: "MUI-RTE/DraftJs",
          version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
          format: _.get(info.editorState, 'data', ""),
        },
      },
      segment_id: _.get(segments.segmentIem, 'value', _.get(segments.segmentIem, 'id', '')),
      segmentIem: segments.segmentIem,
      category_id: _.get(segments.categoryData, 'value', _.get(segments.categoryData, 'id', '')),
      categoryData: segments.categoryData,
      attributes,
      unset_attributes
    }
    if (published.option === "validity") {
      if (published.validityDate) {
        parameters['validity_date'] = published.validityDate
      }
      if (published.startDate) {
        parameters['start_date'] = published.startDate
      }
    } else {
      parameters['published'] = published.option === "published"
    }
    if (relatedItems.length > 0) {
      parameters['links'] = relatedItems.map(i => i.value);
    }
    return parameters
  }, [
    info.name,
    info.editorState,
    info.itemId,
    segments.segmentIem,
    segments.categoryData,
    segments.dataFields,
    segments.attributes,
    published.startDate,
    published.validityDate,
    published.option,
    relatedItems,
    t,
  ])

  const formValidationAndSubmit = useCallback((customParams = {}) => {
    let formErrors = {}

    if (segments.isGallery && isEdit && !(info.name).trim()) {
      formErrors.name = t("common:Campo obrigatório")
    }
    if (!(info.name).trim() && !segments.isGallery) {
      formErrors.name = t("common:Campo obrigatório")
    }
    if (_.get(segments.categoryData, 'value', _.get(segments.categoryData, 'name', '')).trim().length === 0) {
      formErrors.category = t("common:Campo obrigatório")
    }
    if (_.get(segments.segmentIem, 'value', _.get(segments.segmentIem, 'name', '')).trim().length === 0) {
      formErrors.segment = t("common:Campo obrigatório")
    }

    if (published.option === "validity") {
      if (!Boolean(published.startDate) && !Boolean(published.validityDate)) {
        formErrors.hasDate = t('common:Insira uma data')
      }
      if (Boolean(published.startDate) && Boolean(published.validityDate)) {
        if (Utils.checkIfDateIsAfter(published.startDate, published.validityDate)) {
          formErrors.hasDate = t('common:Data final deve ser maior')
        }
      }
    }

    const errorFields = validation(segments.dataFields, segments.attributes)

    if (_.isEmpty(errorFields) && _.isEmpty(formErrors)) {
      const params = getParameters()
      if (isEdit) {
        setLoading(prev => ({ ...prev, loading: true }), submit({ ...params, ...customParams }))
      } else {
        submit({ ...params, ...customParams })
      }
    } else {
      setSegments(prev => ({ ...prev, errors: formErrors, errorFields }))
    }
  }, [
    info.name,
    segments.isGallery,
    segments.segmentIem,
    segments.categoryData,
    segments.dataFields,
    segments.attributes,
    published.startDate,
    published.validityDate,
    published.option,
    getParameters,
    t,
    submit,
    isEdit
  ])

  return (
    <FormItemContext.Provider
      value={{
        segments,
        changeSegment,
        changePublishField,
        info,
        changeName,
        changeEditorDraft,
        published,
        changePublished,
        changeDates,
        relatedItems,
        changeRelatedItems,
        changeAttributes,
        setInitialState,
        formValidationAndSubmit,
        errors: segments.errors,
        isGallery: segments.isGallery,
        isEdit,
        loading,
        changeLoading
      }}
    >
      {children}
    </FormItemContext.Provider>
  )
}

export function useDataFormItem() {
  const {
    segments,
    changeSegment,
    changePublishField,
    info,
    changeName,
    changeEditorDraft,
    published,
    changePublished,
    changeDates,
    relatedItems,
    changeRelatedItems,
    changeAttributes,
    setInitialState,
    formValidationAndSubmit,
    errors,
    isGallery,
    isEdit,
    loading,
    changeLoading
  } = useContext(FormItemContext)

  return {
    segments,
    changeSegment,
    changePublishField,
    info,
    changeName,
    changeEditorDraft,
    published,
    changePublished,
    changeDates,
    relatedItems,
    changeRelatedItems,
    changeAttributes,
    setInitialState,
    formValidationAndSubmit,
    errors,
    isGallery,
    isEdit,
    loading,
    changeLoading
  }
}
