import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Typography, InputBase, Tooltip, IconButton } from "@material-ui/core"

import SearchIcon from "@material-ui/icons/Search"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckSVG from "../../../imagens/ui/fact_check-24px.svg"
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'

import { useStyles } from "./styles"

const HeaderTable = ({
  title,
  handleSubmitSearch,
  hasSearchText,
  toolThumbnail,
  data
}) => {
  const [searchText, setSearchText] = useState(hasSearchText)
  const history = useHistory()
  const classes = useStyles()

  const { t } = useTranslation()
  useEffect(() => {
    if (hasSearchText) {
      setSearchText(hasSearchText)
    } else {
      setSearchText("")
    }
  }, [hasSearchText])

  const runTextSearch = (e) => {
    let key = e.key

    if (key.toLowerCase() === "enter" && e.target.value.trim() !== "") {
      handleSubmitSearch(searchText)
    }

    if (key.toLowerCase() === "backspace" && e.target.value.length === 1) {
      handleSubmitSearch()
    }
  }

  return (
    <div className={classes.rootHeader}>
      <div style={{ display: "flex" }}>
        <div className={classes.iconSvg}>
          <img
            src={toolThumbnail ? toolThumbnail : CheckSVG}
            width="30"
            height="30"
            alt={title.name}
          />
          <Typography variant="h6">{title.name}</Typography>
        </div>
      </div>
      <div style={{display: 'flex'}}>
        {
          data.workflow &&
          <Tooltip
            title={`${t('common:Incluir')}`}
            placement="left"
          >
            <IconButton
              color="inherit"
              onClick={() => history.push(`/${data.region}/workflow/${data.slug}/new`)}
              id='icon-button'
              style={{padding: 0, marginRight: 15}}
            >
              <AddCircleRoundedIcon fontSize='large' color='primary' />
            </IconButton>
          </Tooltip>
        }  
        <div className={classes.search}>
        <div className="icon search">
          <SearchIcon />
        </div>

        <InputBase
          placeholder={t("manager:buscar")}
          onChange={(evt) => setSearchText(evt.target.value)}
          onKeyDown={(evt) => runTextSearch(evt)}
          autoFocus
          value={searchText}
        />
        <div className="icon">
          {searchText.length > 0 && (
            <CancelIcon
              onClick={() => handleSubmitSearch()}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      </div>
      
    </div>
  )
}

export default HeaderTable
