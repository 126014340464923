import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  listWrapper: {
    marginTop: "30px",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 200px)",
    wordWrap: "break-word",
    "& button:first-child": {
      borderTop: "1px solid #DDDDDD",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 25px",
  },
  rowButton: {
    width: '100%',    
    border: 'none',      
    '& > div': {
      padding: '0 10px'
    },
    color: '#666',    
    height: 80,  
    padding: '10px 16px',    
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #DDDDDD',    
    backgroundColor: '#fff', 
    "&:hover": {
      backgroundColor: "#F0F0F0",
      cursor: "pointer",
    },  
  },  
  boxInfo: {
    width: "30px",
    height: "30px",
    backgroundColor: "#666",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginRight: "10px",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 200
  },
}))
