import React, { useState } from "react";

import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorReport from '../ErrorReport'

const ErrorWrapper = ({ t, item, classError }) => {
  const [senterror, setSentError] = useState(false);

  const confirmSent = () => {
    setSentError(true)
  }

  return (
    <ErrorReport t={t} asset={item}>
      {(confirm) => (
        <Tooltip
          title={!senterror ? t("DAM:Reportar erro") : t("DAM:Erro reportado")}
        >
          {!senterror ? (
            <ErrorRoundedIcon
              className={classError}
              style={{ cursor: "pointer"}}
              onClick={confirm(confirmSent)}
            />
          ) : (
            <CheckCircleRoundedIcon style={{ color: "#F4511E" }} />
          )}
        </Tooltip>
      )}
    </ErrorReport>
  );
};

export default ErrorWrapper
