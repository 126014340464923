import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 185px)",
    borderRadius: 0,
    padding: "0 25px",
    wordWrap: "break-word",
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      marginBottom: 80,
      width: 'calc(100vw - 50px)'
    },
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#666",
    marginTop: "20px",
    "& #title": {
      fontWeight: 500,
    },
    "& #info": {
      marginTop: "10px",
      fontWeight: 300,
      "& span": {
        fontSize: "0.875rem",
      }
    },
  },
  row: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
    paddingBottom: 24,
    marginBottom: 24,
    borderBottom: "1px solid #e0e0e0"
  },
  rowButton: {
    display: "flex",
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer"
    }
  }
}))
