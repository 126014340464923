import React, { useEffect, useState, useCallback, useMemo } from 'react'
import axios from "axios"
import _ from 'lodash'

import { useSnackbar } from "notistack"
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"

import {
    Typography,
    Grid
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import AssetsToRecord from './AssetsToRecord'
import Settings from '../../../utils/Settings'
import DropzoneS3Uploader from "components/Upload/DropzoneS3Uploader"

const useStyles = makeStyles({
    root: {
        marginTop: 20,
        "& .btn-attach": {
            width: 140,
            marginTop: 10
        }
    },
});

const UploadBucketDialog = ({ data }) => {
    const [openData, setOpenData] = useState({ open: false, items: [], loadingItems: true, canSelectAttach: false, canUpload: false })
    const { pathServer, requestHeaders, userData: { upload_config } } = useSelector((state) => state.appReducer)
    const uploadFilesLength = useSelector((state) => state.uploadReducer.uploadFiles)
        .filter(i => i.entity === data.entity).length
    const { t } = useTranslation();
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const onError = useCallback((errors) => {
        errors.forEach((error) => enqueueSnackbar(error.message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
            },
        }))
    }, [enqueueSnackbar])

    const getData = useCallback(async () => {
        let resultData = {}

        try {
            const resp = await axios.get(`${pathServer}/tool/${data.slugTool}/record/${data.idRecord}/assets`, requestHeaders)
            resultData.items = _.get(resp, 'data', [])
            resultData.loadingItems = false
        } catch (err) {
            resultData.items = []
            resultData.loadingItems = false
            console.log('get data attachments: ', err)
        }

        try {
            const resp = await axios.get(`${pathServer}/tool/${data.slugTool}/record/${data.idRecord}/can/attach`, requestHeaders)
            resultData.canUpload = resp.data.hasOwnProperty('permission')

        } catch (err) {
            resultData.canUpload = false
        }

        try {
            const resp = await axios.get(`${pathServer}/tool/${data.slugTool}/record/${data.idRecord}/can/selectAttach`, requestHeaders)
            resultData.canSelectAttach = resp.data.hasOwnProperty('permission')
        } catch (err) {
            resultData.canSelectAttach = false
        }

        setOpenData(prev => ({ ...prev, ...resultData }))
    }, [pathServer, data.idRecord, data.slugTool, requestHeaders])

    useEffect(() => {
        let isMounted = true

        if (isMounted) getData()
        return () => {
            isMounted = false
        }
    }, [data.marking, getData])

    const openDataItemsLength = useMemo(() => (openData.items.length + uploadFilesLength)
        , [openData.items, uploadFilesLength])
    
    return (
        <div style={{ marginTop: 40 }} className={classes.root}>
            {
                data.original_marking === 'Em validação' || data.original_marking === 'Aprovada' ?
                    <AssetsToRecord
                        dataAssets={openData.items}
                        loadingItems={openData.loadingItems}
                        data={{
                            original_marking: data.original_marking,
                            workflow: data.workflow,
                            slugTool: data.slugTool,
                            idRecord: data.idRecord,
                            recipients: data.recipients,
                            approvers: data.approvers,
                            marking: data.marking,
                            canSelectAttach: openData.canSelectAttach,
                            entity: data.entity,
                            axios: { pathServer, requestHeaders }
                        }}
                    /> : null
            }
            {
                openData.canUpload && (
                    <>
                        <Grid style={{ display: 'flex', marginBottom: 15, flexDirection: 'column' }}>
                            <Grid style={{ display: 'flex' }}>
                                <AttachFileIcon style={{ color: '#666', marginRight: 5 }} />
                                <Typography variant='body1' style={{ fontWeight: 500, color: '#666' }}>
                                    {t('manager:Anexar arquivos para a tarefa')}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography style={{ margin: '5px 0 0 7px' }} variant='body2'>
                                    {t('manager:Anexe arquivos para auxiliar na tarefa, como referências, documentos, etc.')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <DropzoneS3Uploader
                            config={{
                                acceptedExtensions: Settings.ACCEPTED_EXTENSIONS,
                                bucket_name: _.get(upload_config, 'tasks_bucket', Settings.BUCKET_NAME_TASKS),
                                tagging: data.tagging,
                                entity: data.entity,
                                maxFilesUploads: (10 - openDataItemsLength),
                                max_size_file: '53687091200', //bytes                              
                            }}
                            onError={onError}                            
                            paddingControl
                            isDisabled={openDataItemsLength === 10}
                        />
                    </>
                )
            }
            {
                data.original_marking !== 'Em validação' && data.original_marking !== 'Aprovada' ?
                    <AssetsToRecord
                        dataAssets={openData.items}
                        loadingItems={openData.loadingItems}
                        data={{
                            workflow: data.workflow,
                            slugTool: data.slugTool,
                            idRecord: data.idRecord,
                            recipients: data.recipients,
                            approvers: data.approvers,
                            marking: data.marking,
                            canSelectAttach: openData.canSelectAttach,
                            entity: data.entity,
                            axios: { pathServer, requestHeaders }
                        }}
                    /> : null
            }
        </div>
    )
}

export default UploadBucketDialog