import {useState, useCallback} from 'react';
import { useSelector, useDispatch } from "react-redux"
import _ from 'lodash'
import Settings from "utils/Settings";
import {simpleGet} from "utils/Request"
import AWS from 'aws-sdk/global';
import Evaporate from 'evaporate';
import UploadActions from "flux/actions/UploadActions"
import Utils from 'utils/Utils';


export default function useUpload() {
    const [retryRequest, setRetryRequest] = useState([])
    const {data: { client_slug }, userData: { region_id, user_id, user_token, upload_config }, requestHeaders} = useSelector(state => state.appReducer);
    
    const { evaporate } = useSelector(state => state.uploadReducer)
    const dispatch = useDispatch()

    const uploadFinished = useCallback((file, awsKey) => {
        dispatch(UploadActions.uploadFileFinished(file, awsKey))
    }, [dispatch])

    const retry = useCallback((fileName) => {
        let data = retryRequest
        if (data.length === 0) {
            data.push({name: fileName, retries: 1})
        } else {
            data = data.map((retryData) => {
                if (retryData.name === fileName) {
                    retryData.retries += 1
                    return retryData
                }
                retryData.retries = 1
                retryData.name = fileName
                return retryData
            })
        }

        let assetData = data.find((retryData) => retryData.name === fileName)
        setRetryRequest(data)
        return assetData
    }, [retryRequest])

    const uploadError = useCallback(
        (file, reason, expectRetry, evaporate) => {
          if(!expectRetry) {
            if(reason !== 'User aborted the upload') {
              dispatch(UploadActions.uploadFileError(file, reason))
             }
          } else {
            const retryData = retry(file.path_file)
            if (retryData.retries >= 5) {
              if(!evaporate) {
                dispatch(UploadActions.uploadFileError(file, reason))
                return;
              }
              evaporate.cancel(`${evaporate.config.bucket}/${file.path_file}`).then(
                () => dispatch(UploadActions.uploadFileError(file, reason)),
                (error) => console.log('Error: ', error)
              )
            } else {
              dispatch(UploadActions.uploadFileError(file, reason))
            }
          }
        }
    , [dispatch, retry])

  const addUploadFile = useCallback((file, evaporate, tagging) => {
    const isAssetUpload = Utils.isOrdinaryAssetUpload(file.entity)
    const fileParams = {
      name: file.path_file,
      file: file,
      warn: (message, step, reason) => uploadError(file, `${message} ${step} ${reason}`, true),
      progress: progressValue => {
        const value = Math.floor(progressValue * 100)

        if ((value % 2) > 0) {
          dispatch(UploadActions.setDataProgressfile({ id: file.id, value: value }))
        }
      },
      error: message => uploadError(file, message, true, evaporate),
      ...(Boolean(tagging) && { xAmzHeadersAtInitiate: { 'x-amz-tagging': `${tagging}&&client_slug=${client_slug}&&region_id=${region_id}&&user_id=${user_id}&&bucket=${isAssetUpload}` } })
    }
    const overrides = { signParams: { fileName: file.path_file } };

    evaporate.add(fileParams, overrides)
      .then(awsKey => uploadFinished(file, awsKey), reason => uploadError(file, reason, false))
      .catch(err => console.log("Caught:", err))

  }, [dispatch, uploadFinished, uploadError, client_slug, region_id, user_id ])

  const creatEvaporate = useCallback((acceptedFiles, config) => {
    const params = {
        aws_key: _.get(upload_config, 'accessKeyId', Settings.AWS_KEY),
        awsRegion: Settings.AWS_REGION,
        bucket: config.bucket_name ? config.bucket_name : _.get(upload_config, 'upload_bucket', Settings.BUCKET_NAME_DEFAULT),
        cloudfront: false,
        logging: false,
        computeContentMd5: true,
        cryptoMd5Method: data => AWS.util.crypto.md5(data, 'base64'),
        cryptoHexEncodedHash256: data => AWS.util.crypto.sha256(data, 'hex'),
        signHeaders: requestHeaders,
        customAuthMethod: (signParams, signHeaders, stringToSign, signatureDateTime, canonicalRequest) =>
          new Promise((resolve, reject) => {
            const paramsAuth = {
              ...signParams,
              to_sign: decodeURIComponent(stringToSign),
              datetime: signatureDateTime,
            }
            const url = `${Settings.URL_API}/api/sign?access_key=${_.get(upload_config, 'accessKeyId', Settings.AWS_KEY)}`
            simpleGet(url, paramsAuth, {user_token})
              .then(data => resolve(data))
              .catch(err => reject(err))
          })
    }

    if (evaporate[config.entity]) {
        acceptedFiles.forEach((file) => {
            addUploadFile(file, evaporate[config.entity], config.tagging)
        })
        dispatch(UploadActions.addUploadFiles(acceptedFiles))
    } else {
        Evaporate.create(params)
            .then(evaporate_ => {
                acceptedFiles.forEach((file) => {
                    addUploadFile(file, evaporate_, config.tagging)
                })
                dispatch(UploadActions.addUploadFiles(acceptedFiles))
                dispatch(UploadActions.setEvaporate(evaporate_, config.entity))
            })
            .catch(error => console.log("Error on creating evaporate object:", error))
    }

}, [dispatch, requestHeaders, user_token, addUploadFile, evaporate, upload_config])



  return { creatEvaporate }
}
