import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    grid_root: {
        '& .MuiDataGrid-root': {
            height: 800,
            marginTop: 30,
            border: 'none',
        },
        '& div.form-root': {
            padding: '30px 20px',
            '& .MuiFormControl-root': {
                width: '100%'
            }
        }        
    }
}))