import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import svgRemove from '../../imagens/ui/cancel-24px.svg'
import connect from "react-redux/es/connect/connect";
import { withSnackbar } from 'notistack';


 class CreatableInputOnly extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: '',
    };

    this.components = {
      DropdownIndicator: null,
      ClearIndicator: null, 
      MultiValueRemove: props => {
        const value = JSON.stringify({id: props.data.id, value: props.data.value })    
        return (     
          <div
            id='MultiValueRemove'
            value={value}
            style={{
            borderRadius: '50%',
            width: 22,
            height: 22,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundImage: `url(${svgRemove})`,
            backgroundPosition: 'center',
            margin: '0 5px 0 -6px'
            }}
          />    
        )},
        ...this.props.cutsomComponents
      }

    this.customStyles = {
      multiValueLabel: () => ({
        color: '#fff',
        padding: '0 12px',
      }),
      multiValue: () => ({
        margin: '4px',
        minHeight: '32px',
        backgroundColor: `${this.props.primaryColor}`,
        display: 'inline-flex',
        outline: 0,
        padding: 0,
        fontSize: '0.8125rem',
        boxSizing: 'border-box',
        transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        alignItems: 'center',
        borderRadius: '16px',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        border: 'none',
        wordBreak: 'break-all',
        maxWidth: 155
      }),
      control: () => ({
        padding: '13.5px 14px',
        minHeight: 74,
        border: '1px solid #bababa',
        borderRadius: 4,
        display: 'flex'
      }),
      ...this.props.customStyles
    }

    this.VALUE_LIMITED = this.props.valueLimited
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event) => {
    const { inputValue } = this.state;
    if (!inputValue) return;
    if (inputValue.length >= 40) {
      return this.props.enqueueSnackbar(
        this.props.t('common:Limite de 40 caracteres por tag foi excedido.'), {
          variant: 'info',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        }
      );
    };
    if(event.key === ' ') {
      return this.handleInputChange(inputValue + ' ')
    }
    if(this.props.dataValues.length >= this.VALUE_LIMITED) {
      return this.props.enqueueSnackbar(
        this.props.t('common:Limite de 10 tags por assets foi excedido.'), {
          variant: 'info',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        }
      );
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.props.handleChangeTags(inputValue.trim(), this.props.assetId)
        this.setState({
          inputValue: ''});
        event.preventDefault();
        break
      default:
        return
    }
  };
  render() {
    const { inputValue } = this.state;

    return (
      <CreatableSelect
        components={this.components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.t("common:Pressione enter/tab")}
        value={this.props.dataValues}
        styles={this.customStyles}
        isDisabled={this.props.disabled}
      />
    );
  }
}

const mapStateToProps = ({appReducer}) => {
  return {
    primaryColor: appReducer.data.primaryColor
  }
};


export default connect(mapStateToProps, null)(withSnackbar(CreatableInputOnly))
