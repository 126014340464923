import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: 20,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 70,
    },
  },
  rootPaper: {
    height: 800,
    width: "100%",
  },
  rootHeader: {
    display: "flex",
    padding: "20px 11px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  iconSvg: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: 15,
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
    "& .icon": {
      width: 44,
      height: "100%",
      display: "flex",
      justifyContent: "center",
    },
    "& .icon.search": {
      pointerEvents: "none",
      margin: 0,
    },
    "& input": {
      padding: 0
    }
  },
  tableContainer: {
    height: "100%",
    maxHeight: 660,
    "& .MuiTableCell-root": {
      padding: "12px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: 600,
    },
  },
  rootLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 712,
    flexDirection: "column",
  },
  tablePagination: {
    "& .MuiTablePagination-toolbar": {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      },
    },
  },
}))
