import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Fab, Tooltip } from "@material-ui/core"
import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';

import { Creators as AssetActions } from "flux/ducks/Asset"

const BookmarkAsset = ({ asset }) => {
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.assetReducer)
    const { t } = useTranslation()

    const handleClick = (evt, checked) => {
        evt.preventDefault()        
        if (checked) {
            dispatch(AssetActions.setSelectedAsset(asset))
        } else {
            dispatch(AssetActions.removeSelectedAsset(asset))
        }
    }

    const checkedValue = data.some(i => i.id === asset.id)

    return (
        <div>
            <Tooltip title={t("DAM:Selecionar ativo")}>
                {
                    checkedValue ? (
                        <Fab id='marginRight' onClick={evt => handleClick(evt, false)}>
                            <BookmarkRoundedIcon color="primary" />
                        </Fab>
                    ) : (
                        <Fab id='marginRight' onClick={evt => handleClick(evt, true)}>
                            <BookmarkBorderRoundedIcon style={{ color: '#666' }} />
                        </Fab>
                    )
                }

            </Tooltip>
        </div>
    )
}

export default BookmarkAsset