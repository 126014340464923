import React from "react";

import axios from "axios/index";
import { withSnackbar } from "notistack"
import Dropzone from 'react-dropzone'

import imgUpload from "../../../imagens/ui/ico-upload.svg";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux"
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Settings from "utils/Settings";
import ImageLazyLoad from "../../../components/ImageLazyLoad"
import UploadDialog from '../Core/UploadDialog'
import _ from "lodash"

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {   
      picturesPreview: '',
      loading: true,             
    };
    this.handleRemovePicture = this.handleRemovePicture.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
  }

  componentDidMount() {
    const ID = this.props.id;
    this.setState({ ...this.state, slug: ID });
    if (ID) this.getData(ID);
  }

  getData(id) {
    const SELF = this;
    const { t, pathServer, requestHeaders, name, enqueueSnackbar } = SELF.props;
   
    axios.get(pathServer + "/client/layout", requestHeaders)
      .then(function (resp) { 
        const preview = _.get(resp, `data.${name}`, null)
        SELF.setState({
          ...SELF.state,
          id,
          loading: false,         
          picturesPreview: !preview ? "" : preview + "?=timestamp=" + Math.floor(Date.now() / 1000)
        });
      }, function (resp) {
        SELF.setState({ ...SELF.state, loading: false });       
        const message = resp.headers['x-message'] ||
        t('Erro ao carregar os dados, contate o suporte');
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
      .catch(function (err) {
        console.log(err);
        const message = err.response
          ? err.response.data.message
          || err.response.headers["x-message"]
          : this.props.t('Erro ao carregar os dados, contate o suporte');
        this.props.enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      });
  }

  onDropUpload(files) {
    const SELF = this;
    const { pathServer, requestHeaders, name, enqueueSnackbar } = SELF.props;

    if(files.length <= 0) {
      return
    }
    
    const formData = new FormData();
    formData.append(name, files[0]);
    
    SELF.setState({ ...SELF.state, loading: true });

    axios
      .post(`${pathServer}/client/import/${SELF.state.id}`, formData, requestHeaders)
      .then(resp => {
        enqueueSnackbar(resp.headers['x-message'], {
          ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
        })

        this.setState({
          ...this.state,
          loading: false,
          picturesPreview: resp.data[name] + "?=timestamp=" + Math.floor(Date.now() / 1000)
        });
      }, (err) => {
        const message = err.response
          ? err.response.data.message
          || err.response.headers["x-message"]
          : this.props.t("Erro inesperado. Contate o suporte");
        SELF.setState({ ...SELF.state, loading: false });
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      }
      )
  }

  onDropRejected() {  
    const { t, enqueueSnackbar } = this.props; 
    let message = t('Arquivo inválido. Arquivo até 2MB em JPG ou PNG')
  
    if(Boolean(this.props.type) && Boolean(this.props.type.indexOf('/x-icon'))) {
      message = t('Arquivo inválido. Arquivo até 2MB em ICO ou PNG')
    }

    enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
  }

  handleRemovePicture(event) {
    event.preventDefault();
    const SELF = this;
    const { pathServer, requestHeaders, name, enqueueSnackbar } = SELF.props;

    this.setState({ ...this.state, loading: true });
    axios.post(
      `${pathServer}/client/remove/${SELF.state.id}/${name}`, {}, requestHeaders)
      .then(function (resp) {
        SELF.setState({
          ...SELF.state,
          loading: false,
          picturesPreview: '',
        });
      
        enqueueSnackbar(resp.headers['x-message'], {
          ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
        }) 
      }, function (resp) {
        SELF.setState({ ...SELF.state, loading: false, });     
        const message = resp.headers['x-message'] ||
          this.props.t('Erro ao remover a imagem, contate o suporte');
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
      .catch(function (err) {
        console.log(err);
        const message = err.response
          ? err.response.data.message
          || err.response.headers["x-message"]
          : this.props.t("Erro ao deletar, contate o administrador.");
        SELF.setState({ ...SELF.state, loading: false });
        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })        
      });
  }

  render() {
    const { t, loading, classes } = this.props;

    return (
      <React.Fragment>
        <Dropzone
          onDrop={this.onDropUpload.bind(this)}   
          accept={Boolean(this.props.type) ? this.props.type : "image/jpeg, image/png"}
          maxSize={2000000}
          onDropRejected={this.onDropRejected}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.rootDropzone}>
              <div className='content'>
                <input {...getInputProps()} />
                {(loading || this.state.loading) ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {
                      this.state.picturesPreview ? (
                        <div className='image'>
                          {                           
                            <ImageLazyLoad    
                              images={{
                                imgJPG: this.state.picturesPreview, 
                                imgWEBP: this.state.picturesPreview
                              }}
                            />                            
                          }
                        </div>
                      ) : (
                        <>
                          <img
                            src={imgUpload}
                            width={50}
                            alt={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                          />
                          <br />
                          <Typography variant="body2">
                            {t("manager:Clique aqui ou arraste uma imagem para enviar")}
                          </Typography>
                        </>
                      )
                    }
                  </>
                )}
              </div>
            </div>
          )}
        </Dropzone>

        {
          this.state.picturesPreview && (
            <Grid item xs={12} style={{ margin: '20px 0' }}>
              <div>
                <div
                  style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ height: 40, marginRight: 25  }}
                    onClick={this.handleRemovePicture}
                    disabled={(loading || this.state.loading)}
                  >
                    {t("common:Excluir")}
                  </Button>
                  <UploadDialog
                    t={t}
                    title={t("common:Adicionar nova imagem")}
                    descriptionUpload={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                    onDropUpload={this.onDropUpload.bind(this)}
                    onDropRejected={this.onDropRejected}
                    loading={loading}                   
                    acceptFiles={Boolean(this.props.type) ? "image/x-icon" : "image/jpeg, image/png"}
                  >
                    {(confirm) => (
                      <Button
                        variant="contained" 
                        color="primary"
                        style={{ height: 40 }}
                        onClick={confirm()}
                      >
                        {t("common:Adicionar nova imagem")}
                      </Button>
                    )}
                  </UploadDialog>                  
                </div>
              </div>
            </Grid>
          )}          
      </React.Fragment>

    );
  }
}


const mapStateToProps = ({appReducer}) => {
  return {  
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer
  }
};


export default connect(mapStateToProps, null)(withTranslation(['manager', 'common'])(withSnackbar(Upload)))