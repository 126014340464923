import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
    header_root: {
        '& #header-root-page': {
            position: 'fixed',
            zIndex: '2 !important'
          },
          [theme.breakpoints.down("sm")]: {      
            '& #margin-top-mobile': {
              marginTop: 200,
            }
          }
    },
    contentCard_root: {
        display: 'flex', 
        flexWrap: 'wrap', 
        justifyContent: 'center',
        paddingTop: 136,
        [theme.breakpoints.down("sm")]: {
          paddingTop: 230
        }        
    },
    contentCard_list: {      
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 90,
      paddingTop: 136
  }
}))