/*eslint-disable no-unused-vars*/
import React from "react"
/*eslint-enable  no-unused-vars*/
import ComboBox from "./index.js"
import connect from "react-redux/es/connect/connect"
import {
  mapDispatchToPropsToSettings,
  mapStateToPropsToSettings,
} from "../../utils/Flux"
import { withTranslation } from "react-i18next"

class RegionComboBox extends ComboBox {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      entity: "region",
      title: props.title || this.props.t('Regiões'),
      subtitle: props.subtitle,
    }
  }

  componentDidMount() {
    super.componentDidMount.apply(this)
    this.getData()
  }
}

export default connect(
  mapStateToPropsToSettings,
  mapDispatchToPropsToSettings
)(withTranslation(["manager", "common"])(RegionComboBox))
