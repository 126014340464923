import React, { useState } from "react";
import _ from 'lodash'
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import ProfileComboBox from "components/ComboBox/ProfileComboBox"

import { useStyles } from "./styles";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

const DialogConfirm = ({
  open,
  handleClose,
  handleConfirm,
}) => {
  const [optionSelected, setOptionSelected] = useState({ id: "", label: "" })
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChangeComboBox = (option) => {
    setOptionSelected({
      id: _.get(option, 'value', _.get(option, 'id', '')),
      label: _.get(option, 'label', _.get(option, 'value', ''))
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.root}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          <AccountCircleRoundedIcon id="main" />
          <div>{t("manager:Mover usuário para outro perfil")}</div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: '0', overflowY: 'visible' }}>
        <Box>
          {t("manager:Essa ação pode ser realizada apenas para perfis em que seu usuário é moderador. Defina o novo perfil do usuário")}:
          <Box style={{ marginTop: 16 }}>
            <ProfileComboBox
              optionSelected={_.get(optionSelected, 'label', '')}
              handleChange={handleChangeComboBox}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          style={{ height: 40, width: 115, marginRight: 20 }}
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          {t("common:Cancelar")}
        </Button>

        <Button
          style={{ height: 40, width: 115 }}
          variant="contained"
          color="primary"
          onClick={() => handleConfirm(_.get(optionSelected, 'id', ''))}
          disabled={!Boolean(optionSelected.id)}
        >
          {t("manager:Mover")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirm
