import { makeStyles } from "@material-ui/core/styles";
import { darken } from "polished";

export const useStyles = makeStyles((theme) => ({
  login: { 
    width: "40%",
    maxWidth: "355px",
    height: "100%",
    maxHeight: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 22.5px",
    fontSize: "1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      height: 'calc(100vh - 56px)',
      maxHeight: "none",
      margin: 0,
      borderRadius: 0,
      "& .btnLink": {
        paddingBottom: "10px",
      },
    },
    "& .descriptionMobile": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center",
        fontSize: "1rem",
        lineHeight: "1em",
        color: "#818080",
        padding: "0 10%",
        margin: "5px 0 20px 0",
        "@media (max-height: 450px)": {
          padding: "0 10px",
          margin: "0",
          width: "100%",
        },
      },
    },
    "& .formLogin": {
      width: "90%",
      maxWidth: "250px",
      "@media (max-height: 450px)": {
        display: "flex",
        alignItems: "center",
        width: "100vw",
        maxWidth: "none",
        padding: "20px 5px 0 5px",
      },

      "& input": {
        fontSize: "1em",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
      "& button#submit": {
        width: "100%",
        minHeight: "50px",
        borderRadius: "4px",
        marginBottom: "10px",
        "@media (max-height: 450px)": {
          height: "56px",
          marginBottom: "27px",
        },
      },
    },
  },
  btnForgot: {
    marginTop: "18px",
    fontSize: "1em",
    cursor: "pointer",
    color: (props) => props.primaryColor || "#000000",
    marginBottom: "10px",
    "&:hover": {
      color: (props) => darken(0.12, props.primaryColor || "#000000"),
    },
  },
}));
