import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography';

import { useDataItemFiles } from './context'

import HeaderDialog from 'components/Header/HeaderDialog'

const Header = ({ handleClose, taxonomyName }) => {
    const { t } = useTranslation()

    const { componentStatus } = useDataItemFiles()

    const getTitle = () => {
        const value = componentStatus.current
        switch (value) {
            case 'search_bucket':
                return t('DAM:Selecione ativos para adicionar ao item')
            case 'show_selected':
                return t('DAM:Arquivos selecionados')
            default:
                return t('DAM:Selecione ativos para adicionar ao item')
        }
    }

    return (
        <HeaderDialog handleClose={handleClose}>
            <Typography variant="h6">
                {getTitle()}
            </Typography>
            <Typography variant="body2">
                {taxonomyName}
            </Typography>
        </HeaderDialog>

    );
}

Header.propTypes = {
    handleClose: PropTypes.func,
    taxonomyName: PropTypes.string,
}

export default Header