import React from 'react'

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%', 
        height: 128,   
        [theme.breakpoints.down("sm")]: {
          height: 244,      
        },
        
      },
}))

const DividerHeader = () => {  
    const classes = useStyles();
  
    return  <div className={classes.root} data-disableselect={true} id='dividerFiltersBucket' />
  }

  export default DividerHeader
  