import React, { useMemo } from 'react'
import PropTypes from 'prop-types';
import i18n from "i18next"
import { useTranslation } from 'react-i18next'
import { Menu, MenuItem, makeStyles, Button, CircularProgress } from '@material-ui/core';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #666',
        borderRadius: 4,
        height: 32
    },
    button: {       
        '& span.MuiButton-startIcon': {
            margin: 0
        },
        "& #circularProgress": {
            width: "18px !important",
            height: "18px !important",
        },
        "& button": {
            color: '#666',
            textTransform: 'none'            
        },
        "& .highlight": {
            color: theme.palette.primary.main,
        }        
    },
    rootMenu: {
        '& .Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
            opacity: 1
        }
    },
    displayQuantity: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        height: '100%',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

const MenuViewItems = ({ totalResultItems = 0, totalMarkedItems, options, loading = false, isPageItens = false, exceededLimit }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()
    const { t } = useTranslation()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const constants = useMemo(() => ({       
        textButton: isPageItens
            ? (totalResultItems === 1) ? `1 ${t(`common:Item`)}` : `${totalResultItems.toLocaleString(i18n.language)} ${t(`common:Itens`)}`
            : (totalMarkedItems === 1) ? t(`DAM:Item selecionado`) : t(`DAM:Itens selecionados`)
    }), [totalMarkedItems, totalResultItems, isPageItens, t])

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <div className={classes.displayQuantity}>
                        {totalMarkedItems}
                    </div>
                    <div className={classes.button}>
                        <Button
                            className={(totalResultItems > 10000) ? 'highlight': null}                            
                            endIcon={
                                loading
                                    ? <CircularProgress id='circularProgress' />
                                    : Boolean(anchorEl)
                                        ? <KeyboardArrowUpRoundedIcon fontSize='small' />
                                        : <KeyboardArrowDownRoundedIcon fontSize='small' />
                            }
                            onClick={handleClick}
                            disabled={loading}
                        >
                            {exceededLimit ? `9.999+ ${t('common:Itens')}` : constants.textButton}
                        </Button>

                    </div>
                </div>
            </div>

            <Menu
                id="menu-ordination"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className={classes.rootMenu}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        disabled={option.disabled}
                        onClick={() => {
                            option.func()
                            handleClose()
                        }}
                    >
                        {option.title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

MenuViewItems.defaultProps = {
    options: [],
    totalMarkedItems: 0,
    path: '',
    paramsToItems: {}
}

MenuViewItems.propTypes = {
    options: PropTypes.array,
    totalMarkedItems: PropTypes.number
}

export default MenuViewItems