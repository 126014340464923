import React, { createContext, useState, useContext } from "react"
import _ from 'lodash'
const RequestToolsContext = createContext()

export default function RequestToolsProvider({ children }) {
  const [status, setStatus] = useState('')
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  })
  const [message, setMessage] = useState('Nenhum dado encontrado.')
  const [errorValidDate, setErrorValidDate] = useState('')

  const hasDate = Boolean(_.get(date, 'startDate', false)) || Boolean(_.get(date, 'endDate', false))
  const hasStatus = Boolean(status)

  const resetInitialState = () => {
    setMessage('Nenhum dado encontrado.')
    setStatus('')
    setErrorValidDate('')
    setDate({
      startDate: null,
      endDate: null,
    })
  }

  return (
    <RequestToolsContext.Provider
      value={{
        date,
        setDate,
        hasDate,
        status,
        setStatus,
        hasStatus,
        message,
        setMessage,
        resetInitialState,
        errorValidDate,
        setErrorValidDate
      }}
    >
      {children}
    </RequestToolsContext.Provider>
  )
}

export function useDataRequestTools() {
  const {
    date,
    setDate,
    hasDate,
    status,
    setStatus,
    hasStatus,
    message,
    setMessage,
    resetInitialState,
    errorValidDate,
    setErrorValidDate
  } = useContext(RequestToolsContext)

  return {
    date,
    setDate,
    hasDate,
    status,
    setStatus,
    hasStatus,
    message,
    setMessage,
    resetInitialState,
    errorValidDate,
    setErrorValidDate
  }
}
