import React from "react"

//Libs
import Select from "react-select"
import { omit } from "ramda"
import { arrayMove } from "react-sortable-hoc"
// Material UI
import { Grid } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
// Helpers
// ComboBox
import AttributeComboBox from "../../../components/ComboBox/AttributeComboBox"
import RegionComboBox from "../../../components/ComboBox/RegionComboBox"
//Structure
import CreateDefault from "../Core/CreateDefault"
import { settings } from "./_settings.js"
import BoxPermissions from "./partials/BoxPermissions"
import TableAttribute from "./partials/TableAttributes"
import Moderation from "./partials/Moderation"
import { compose } from "recompose";
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles'
import { withSnackbar } from "notistack"
import Names from './Names'

import _ from "lodash"
import { v4 as uuidv4 } from 'uuid';

class Create extends CreateDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      structures: [],
      fields: [
        "domain_email",
        "type_approval",
        "attributes",
        "approvers",
        "expiration",
        "inactivity_expiration",
        "sso_id"
      ],
      policies: {},
      usersInApprovers: [],
      approvers: [],
      type: {
        label: '', value: ''
      },
      typeItems: [
        { label: "Yourself", value: "yourself" },
        { label: "Group", value: "group" },
      ],
      hasData: true,
      data: {
        type_approval: "",
        approvers: "",
        domain_email: "",
        sso_id: "",
        attributes: [],
        region: { id: "", name: "" },
        expiration: { id: 0, name: props.t("common:Sem expiração") },
        inactivity_expiration: { id: 0, name: props.t("common:Sem expiração") },
      },
      typeAuth: { label: props.t("manager:Pela plataforma Yapoli"), value: "yapoli" },
      dataNames: {},
      inputValueExpiration: "",
      inputValueInactivityExpiration: '',
      dataSelectExpiration: this.getDataSelectExpiration()
    }


    this.getParamsAxios = this.getParamsAxios.bind(this)
    this.handleChangeNames = this.handleChangeNames.bind(this)
  }

  componentDidMount() {
    if (!this.state.hasData) {
      this.setState({ ...this.state, loading: true })
    }
    this.setRegion()
  }

  getDataSelectExpiration() {
    let data = [{ label: this.props.t("common:Sem expiração"), value: 0 }]
    for (var i = 1; i <= 1095; i++) {
      data.push({ label: `${i} ${i === 1 ? this.props.t("common:dia") : this.props.t("common:dias")}`, value: i })
    }
    return data
  }

  setRegion() {
    const SELF = this
    if (SELF.props.region_id) {
      SELF.setState({
        ...SELF.state,
        data: {
          type_approval: SELF.state.data.type_approval,
          approvers: SELF.state.data.approvers,
          attributes: SELF.state.data.attributes,
          region: {
            id: SELF.props.region_id,
            name: SELF.props.region,
          },
          domain_email: "",
          expiration: { id: 0, name: this.props.t("common:Sem expiração") },
          inactivity_expiration: { id: 0, name: this.props.t("common:Sem expiração") },
        },
      })
    }
  }

  getParamsAxios() {
    const { pathClient } = this.props

    return {
      method: "post",
      url: `${pathClient}/${this.state.entity}`,
    }
  }

  validateForm(parameters) {
    const { t } = this.props
    let { data, dataNames, typeAuth } = this.state
    let formValid = true
    let formErrors = {}

    Object.entries(dataNames).forEach(([lng, item]) => {
      if (_.get(item, 'name.value', false) ||
        _.get(item, 'description.value', false)
      ) {
        Object.entries(item).forEach(([field, data]) => {
          switch (field) {
            case 'name':
              if (_.get(data, 'value', '').trim().length === 0) {
                formErrors.name = {
                  ...formErrors.name,
                  [lng]: `* ${t("common:Este campo é obrigatório")}`
                }
                formValid = false
              }
              break;
            case 'description':
              if (_.get(data, 'value', '').trim().length === 0) {
                formErrors.description = {
                  ...formErrors.description,
                  [lng]: `* ${t("common:Este campo é obrigatório")}`
                }
                formValid = false
              }
              break;
            default:
              break;
          }
        })
      }
    })

    if (!_.get(parameters, 'name', false) && !_.get(parameters, 'description', false)) {
      formErrors.name = { pt: `* ${t("common:Este campo é obrigatório")}` }
      formErrors.description = { pt: `* ${t("common:Este campo é obrigatório")}` }
      formValid = false
    }

    if (parameters.approvers.length === 0) {
      formErrors.approvers = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (typeAuth.value === 'sso') {
      if (!parameters.sso_id) {
        formErrors.sso_id = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      }
      if (!parameters.domain_email) {
        formErrors.domain_email = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      } else {
        const re = /\b([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}\b/

        if (parameters.domain_email.indexOf("@") !== -1) {
          formErrors.domain_email = t("manager:Digite o domínio sem o @")
          formValid = false
        }
        if (!re.test(parameters.domain_email)) {
          formErrors.domain_email = t("manager:Digite um domínio valido")
          formValid = false
        }
      }
    } else {
      if (!data.type_approval) {
        formErrors.type_approval = `* ${t("common:Este campo é obrigatório")}`
        formValid = false
      } else {
        if (data.type_approval === "yourself") {
          if (!parameters.domain_email) {
            formErrors.domain_email = `* ${t("common:Este campo é obrigatório")}`
            formValid = false
          } else {
            const re = /\b([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}\b/

            if (parameters.domain_email.indexOf("@") !== -1) {
              formErrors.domain_email = t("manager:Digite o domínio sem o @")
              formValid = false
            }
            if (!re.test(parameters.domain_email)) {
              formErrors.domain_email = t("manager:Digite um domínio valido")
              formValid = false
            }
          }
        }
      }
    }

    this.setState({ ...this.state, formErrors, formValid })

    return formValid
  }

  handleChangeNames(data) {
    this.setState({ ...this.state, dataNames: data })
  }

  getParameters() {
    let parameters = super.getParameters()
    let { structures, data, dataNames, typeAuth } = this.state

    if (_.get(parameters, "attributes", false)) {
      parameters.attributes = parameters.attributes.map((item) =>
        omit(["handleRemoveAttribute", "label"], item)
      )
    }

    let policies = {}

    Object.entries(structures).forEach(([key, value]) => {
      let dataPolice = []

      value.permissions.forEach((item) => {
        Object.entries(item.policies).forEach(([key, police]) => {
          if (item.entity) {
            dataPolice.push(police)
          } else {
            item.allow.forEach((action) => {
              if (police.includes(action)) {
                dataPolice.push(action)
              }
            })
          }
        })
      })

      policies[key] = dataPolice
    })

    Object.entries(policies).forEach(([key, value]) => {
      if (value.length <= 0) {
        delete policies[key]
      }
    })

    if (Object.keys(policies).length > 0) {
      parameters["policies"] = policies
    }

    parameters["region_id"] = this.state.data.region.id
    parameters["approvers"] = this.state.approvers.map((item) => item.value)

    parameters["_unset"] = []

    if (typeAuth.value === 'sso') {
      parameters["sso_id"] = this.state.data.sso_id
      parameters["domain_email"] = this.state.data.domain_email
      parameters["type_approval"] = "yourself"
      parameters["type_auth"] = "sso"
    } else {
      if (data.type_approval === "group") {
        parameters["_unset"].push("domain_email")
        parameters["_unset"].push("sso_id")
        parameters["type_auth"] = "yapoli"
        delete parameters.sso_id
        delete parameters.domain_email
      }
      if (data.type_approval === "yourself") {
        parameters["_unset"].push("sso_id")
        parameters["type_auth"] = "yapoli"
        parameters["domain_email"] = this.state.data.domain_email
        delete parameters.sso_id
      }
    }

    if (parameters.hasOwnProperty("expiration")) {
      if (parameters.expiration.id === 0) {
        delete parameters.expiration
      } else {
        parameters["expiration"] = parameters.expiration.id
      }
    }

    if (parameters.hasOwnProperty("inactivity_expiration")) {
      if (parameters.inactivity_expiration.id === 0) {
        delete parameters.inactivity_expiration
      } else {
        parameters["inactivity_expiration"] = parameters.inactivity_expiration.id
      }
    }

    if (dataNames) {
      let paramsNames = {}

      Object.entries(dataNames).forEach(([lng, item]) => {
        if (_.get(item, 'name.value', false) &&
          _.get(item, 'description.value', false)
        ) {
          paramsNames = {
            name: {
              ...paramsNames.name,
              [lng]: _.get(item, 'name.value', '')
            },
            description: {
              ...paramsNames.description,
              [lng]: _.get(item, 'description.value', '')
            }
          }
        }
      })

      parameters["name"] = paramsNames.name
      parameters["description"] = paramsNames.description
    }

    return parameters
  }

  // HANDLER ATTRIBUTE
  handleAddAttribute(attribute) {
    if (!attribute) {
      return
    }

    let SELF = this
    let { data } = SELF.state
    let newRegister = {
      required: 0,
      id: attribute.value,
      name: attribute.label,
      order: data.attributes.length,
    }
    data.attributes.push(newRegister)
    data.attribute = null

    SELF.setState(prev => ({ ...prev, data, formErrors: { ...prev.formErrors, attributes: "" } }))
  }

  handleRemoveAttribute(id) {
    let { data } = this.state
    data.attributes = data.attributes.filter((item) => item.id !== id)
    this.setState({ ...this.state, data })
  }

  handleMoveAttribute = ({ oldIndex, newIndex }) => {
    const newState = { ...this.state }
    newState.data.attributes = arrayMove(
      newState.data.attributes,
      oldIndex,
      newIndex
    )
    this.setState(newState)
  }

  handleAddPermission(item) {
    let structures = { ...this.state.structures }

    if (item.entity && !item.region) {
      if (_.get(structures, "#noregion.permissions", false)) {
        if (structures["#noregion"].permissions.findIndex(i => i.entity === item.entity) === -1) {
          structures["#noregion"] = {
            permissions: [...structures["#noregion"].permissions, item],
          }
        }
      } else {
        structures["#noregion"] = {
          permissions: [item],
        }
      }
    } else {
      if (_.get(structures, `${item.region.name}`, false)) {
        const dataPermissions = [...structures[item.region.name].permissions]

        if (!dataPermissions.find(i => _.isEqual(item, i))) {
          const policy = { ...item, id: uuidv4() }
          structures[item.region.name] = {
            permissions: [...structures[item.region.name].permissions, policy],
            thumb: item.region.thumb,
            name: item.region.display_name
          }
        }

      } else {
        const policy = { ...item, id: uuidv4() }
        structures[item.region.name] = {
          permissions: [policy],
          thumb: item.region.thumb,
          name: item.region.display_name
        }
      }

    }

    this.setState({ ...this.state, structures })
  }

  handleRemovePermission(region, item) {
    let { structures } = this.state
    let permissionsFromRegion = _.get(structures, `${region}.permissions`, [])

    if (permissionsFromRegion.find((el) => el.id === item)) {
      permissionsFromRegion = permissionsFromRegion.filter(
        (el) => el.id !== item
      )
    } else if (permissionsFromRegion.find((el) => el.entity === item)) {
      permissionsFromRegion = permissionsFromRegion.filter(
        (el) => el.entity !== item
      )
      this.setState({ ...this.state, structures: structures })
    }

    structures[region]["permissions"] = permissionsFromRegion
    this.setState({ ...this.state, structures: structures })
  }

  handleChangePolicy(region, permission, item) {
    let { structures } = this.state
    const permissionsFromRegion = _.get(structures, `${region}.permissions`, [])

    const s =
      permissionsFromRegion.find((element) => element.id === item) ||
      permissionsFromRegion.find((element) => element.entity === item)
    let allow = s ? s.allow : null

    if (allow) {
      const found = allow.find((a) => a === permission)

      if (found) {
        allow = allow.filter((el) => el !== permission)
      } else {
        allow.push(permission)
      }
      ; (
        permissionsFromRegion.find((element) => element.id === item) ||
        permissionsFromRegion.find((element) => element.entity === item)
      ).allow = allow

      structures[region]["permissions"] = permissionsFromRegion

      this.setState({ ...this.state, structures: structures })
    }
  }

  handleChangeTypeAuth(option) {
    this.setState(prev => ({
      ...prev,
      typeAuth: option,
      domain_email: "",
      approvers: [],
      sso_id: ""
    }))
  }

  handleChangeComboBoxSSO(optionSSO) {
    this.setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        sso_id: optionSSO.value
      },
      formErrors: {
        ...prev.formErrors,
        sso_id: "",
        domain_email: "",
        approvers: "",
      }
    }))
  }

  handleTypeApproval(type) {
    this.setState(prev => ({
      ...prev,
      type: type,
      formErrors: { ...prev.formErrors, type_approval: "" },
      data: {
        ...prev.data,
        type_approval: _.get(type, 'value', ''),
        domain_email: "",
        approvers: [],
        sso_id: ""
      }
    }))
  }

  handleAddApprover(attributeName, selectOption) {
    let { value, label } = selectOption
    let newState = { ...this.state }
    newState[attributeName].push({ value, label, disabled: true })
    newState.usersInApprovers.push(value)
    this.setState(newState)
  }

  handleRemoveApprover(id) {
    let approvers = this.state.approvers.filter((item) => item.value !== id)
    this.setState({ ...this.state, approvers })
  }


  handleOnFocus(text, nameBox) {
    this.handleChangeComboBox(nameBox, { value: 0, label: this.props.t("common:Sem expiração") })

    this.props.enqueueSnackbar(text, {
      variant: "info",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
        autoHideDuration: 2000,
      },
    })
  }

  onInputChangeExpiration(value, nameField) {
    const valueValid = value.replace(/[^0-9]/g, '')

    if (!Number.isInteger(Number(valueValid))) {
      return
    } else {
      this.setState({ ...this.state, [nameField]: valueValid })
    }
  }

  handleChangeChangeBoxExpiration(nameBox, selectedItem, nameField) {
    this.handleChangeComboBox(nameBox, selectedItem)
    this.setState({ ...this.state, [nameField]: "" })
  }

  getForm() {
    const SELF = this
    const { t } = SELF.props
    let attributesInProfile = []
    let { data } = SELF.state
    if (data.hasOwnProperty("attributes")) {
      data["attributes"].forEach((item) => {
        attributesInProfile.push(item.id)
      })
    } else {
      data["attribute"] = null
    }
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      return <div />
    }

    return (
      <>
        <Names
          handleChangeNames={this.handleChangeNames}
          formErrors={this.state.formErrors}
        />

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width" style={{ marginBottom: 20 }}>
            <label className='section'>{t("common:Região de entrada")}</label>
            <p className="subtitle">
              {t("common:Essa região será utilizada no primeiro login realizado pelo usuário.")}
            </p>
          </div>

          <RegionComboBox
            title={t("common:Região")}
            optionSelected={_.get(data, "region.name", "")}
            handleChange={(selectedItem) =>
              SELF.handleChangeComboBox("region", selectedItem)
            }
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width" style={{ marginBottom: 20 }}>
            <label className='section'>{t("common:Informações adicionais")}</label>
          </div>
          <AttributeComboBox
            title={t('common:Selecione o metadado')}
            disabledItems={attributesInProfile}
            value={data["attribute"]}
            handleChange={(selectedItem) =>
              this.handleAddAttribute(selectedItem)
            }
            field_required={false}
            showAllRegions={true}
          />
        </Grid>

        {
          _.get(data, 'attributes', []).length > 0 && (
            <>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <TableAttribute
                  items={data.attributes}
                  style={{ marginTop: 0 }}
                  handleRemoveAttribute={(id, evt) =>
                    this.handleRemoveAttribute(id, evt)
                  }
                  handleCheckAttribute={(id, evt) =>
                    this.handleCheckAttribute(id, evt)
                  }
                  handleMoveAttribute={(obj) => this.handleMoveAttribute(obj)}
                  t={this.props.t}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Divider />
              </Grid>
            </>
          )
        }

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <BoxPermissions
            structures={SELF.state.structures}
            handleChangePolicy={(region, permission, item) =>
              SELF.handleChangePolicy(region, permission, item)
            }
            addPermission={(item) => SELF.handleAddPermission(item)}
            removePermission={(region, item) =>
              SELF.handleRemovePermission(region, item)
            }
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Moderation
          type_approval={SELF.state.type}
          usersInApprovers={SELF.state.usersInApprovers}
          approvers={SELF.state.approvers}
          formErrors={SELF.state.formErrors}
          domain_email={data.domain_email}
          sso_id={data.sso_id}
          typeAuth={SELF.state.typeAuth}
          handleChangeTypeAuth={SELF.handleChangeTypeAuth.bind(SELF)}
          handleAddApprover={SELF.handleAddApprover.bind(SELF)}
          handleRemoveApprover={SELF.handleRemoveApprover.bind(SELF)}
          handleChange={SELF.handleChange.bind(SELF)}
          handleTypeApproval={SELF.handleTypeApproval.bind(SELF)}
          handleChangeComboBoxSSO={SELF.handleChangeComboBoxSSO.bind(SELF)}
        />

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width">
            <label className='section'>{t("common:Notificações")}</label>
            <p className="subtitle">
              {t(
                "common:Todos os usuários com permissão de visualizar itens do conteúdo acima, receberão notificações sobre as atualizações."
              )}
            </p>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 7 }}>
          <Divider />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <div className="form-field full-width">
            <label className='section'>{t("common:Expiração")}</label>
            <p className="subtitle">
              {t(
                "common:Escolha apenas um dos tipos de expiração de perfil abaixo."
              )}
            </p>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }} className={this.props.classes.rootSelect}>
          <div className="form-field full-width">
            <label>{t("common:Perfil temporário")}</label>
            <p className="subtitle">
              {t(
                "common:Essa funcionalidade aplica o conceito de perfil temporário. Assim que aplicada, o prazo de expiração (bloqueio do usuário) passa a ser contado em dias a partir da data de aprovação da solicitação de acesso deste usuário."
              )}
            </p>
            <Grid item xs={3}>
              <Select
                menuIsOpen={
                  _.get(data, 'inactivity_expiration.id', 0) > 0
                    ? false
                    : undefined
                }
                id={"select-attribute"}
                name="expiration"
                value={{
                  label: _.get(data, 'expiration.name', ''),
                  value: _.get(data, 'expiration.id', 0),
                }}
                classNamePrefix="select-custom"
                onChange={(selectedItem) =>
                  SELF.handleChangeChangeBoxExpiration('expiration', selectedItem, 'inputValueExpiration')
                }
                onFocus={() => _.get(data, 'inactivity_expiration.id', 0) > 0
                  ? this.handleOnFocus(t("common:A inatividade em Perfil foi desativada."), 'inactivity_expiration')
                  : {}
                }
                onInputChange={(evt) => this.onInputChangeExpiration(evt, 'inputValueExpiration')}
                inputValue={this.state.inputValueExpiration}
                options={this.state.dataSelectExpiration}
                noOptionsMessage={() => t("manager:Nenhuma opção")}
                styles={{
                  menuList: (provided) => ({
                    ...provided,
                    height: 110,
                  }),
                }}
              />
              <div className="error">{SELF.state.formErrors.expiration}</div>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }} className={this.props.classes.rootSelect}>
          <div className="form-field full-width">
            <label>{t("common:Inatividade em perfil")}</label>
            <p className="subtitle">
              {t(
                "common:Essa funcionalidade aplica um prazo, em dias, de inatividade no perfil, onde o usuários que está atrelado a ele será bloqueado no tempo definido, contando a partir do seu último acesso a plataforma."
              )}
            </p>
            <Grid item xs={3}>
              <Select
                menuIsOpen={
                  (_.get(data, 'expiration.id', 0) > 0)
                    ? false
                    : undefined
                }
                id={"select-attribute"}
                name="inactivity_expiration"
                value={{
                  label: _.get(data, 'inactivity_expiration.name', ''),
                  value: _.get(data, 'inactivity_expiration.id', 0),
                }}
                onChange={(selectedItem) =>
                  SELF.handleChangeChangeBoxExpiration('inactivity_expiration', selectedItem, 'inputValueInactivityExpiration')
                }
                onFocus={() => (_.get(data, 'expiration.id', 0) > 0)
                  ? this.handleOnFocus(t("common:O Perfil temporário foi desativado."), 'expiration')
                  : {}
                }
                onInputChange={(evt) => this.onInputChangeExpiration(evt, 'inputValueInactivityExpiration')}
                inputValue={this.state.inputValueInactivityExpiration}
                options={this.state.dataSelectExpiration}
                noOptionsMessage={() => t("manager:Nenhuma opção")}
                classNamePrefix="select-custom"
                styles={{
                  menuList: (provided) => ({
                    ...provided,
                    height: 110,
                  }),
                }}
              />
              <div className="error">{SELF.state.formErrors.inactivity_expiration}</div>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: 20 }} />

      </>
    )
  }
}

export const mapStateToProps = ({ appReducer }) => {
  return {
    region_id: appReducer.userData.region_id,
    region: appReducer.userData.region,
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
    pathClient: appReducer.pathClient,
  }
}


export default compose(withSnackbar, withStyles(styles),
  connect(mapStateToProps))(withTranslation(["manager", "common"])(Create));

