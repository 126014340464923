import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./styles";

import { useTranslation } from 'react-i18next';
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

const DialogDeleteValidation = ({
  entity,
  name,
  children,
  hideCallback
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [callbackState, setCallbackState] = useState(null);
  const classes = useStyles();
  const [phrase, setPhrase] = useState("");

  const show = (callback) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    };

    setOpen(true);
    setCallbackState(() => () => callback(event));
  };

  const hide = (event) => {
      setOpen(false);
      setCallbackState(null);
      hideCallback();
      event.stopPropagation();
  };

  const confirm = (evt) => {
      callbackState();
      hide(evt);     
  };

  const checkPhrase = t(`common:Excluir-entity`, {name: entity})

  return (
    <React.Fragment>
      {children(show)}
      {open && (
        <Dialog
          open={open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
          onClick={evt => evt.stopPropagation()}
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            <div className="info">
              <DeleteRoundedIcon id="main" />
              <div>{t(`common:para exclusão`, {name: entity})}</div>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: "0", overflowY: "hidden" }}>
            <DialogContentText id="alert-dialog-description">
              {t(`common:Você deseja realmente excluir`, {name: name})}
              <br />
              <span style={{ fontWeight: 700 }}>
                {t("DAM:(essa ação é irreversível)")}
              </span>
            </DialogContentText>

            <TextField
              id='delete-validation-field-phrase'
              role='input'
              label={`${t(`DAM:Escreva aqui`)} "${checkPhrase.toUpperCase()}"`}
              variant="outlined"
              style={{ width: "100%", marginTop: "10px" }}
              onChange={(evt) => setPhrase(evt.target.value)}
              data-testid='delete-validation-field-phrase'
            />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
                variant="outlined"
                color="primary"
                onClick={hide}
              >
              {t(`common:Cancelar`)}
            </Button>
            <Button
              disabled={!(checkPhrase.toUpperCase() === phrase)}
              onClick={confirm}
              variant="contained"
              className={`delete ${checkPhrase.toUpperCase() === phrase ? "active" : null}`}
              data-testid='delete-validation-btn-confirm'
             >
              {`${t('common:Excluir')} ${entity}`}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

DialogDeleteValidation.defaultProps = {
  hideCallback: () => {} 
}

export default DialogDeleteValidation;
