import * as ActionTypes from '../actionTypes';
import Utils from "../../utils/Utils";
import { toast } from 'react-toastify';
import Settings from "../../utils/Settings";
import axios from "axios";
import UploadActions from './UploadActions';
import _ from 'lodash';

import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import { simpleGet } from "../../utils/Request"

const formatRegionData = (data) => {
  const { region, otherRegions } = data;

  const formattedData = {
    regionSlug: _.get(region, 'slug'),
    region: _.get(region, 'slug'),
    region_name: _.get(region, 'name'),
    region_id: _.get(region, 'id'),
    region_thumbnail: _.get(region, 'urls.thumbnail.original'),
    path: Settings.URL_API + "/api/" + localStorage.getItem("client_slug") + "/" + _.get(region, 'slug'),
    hasIntegration: _.get(region, 'hasIntegration'),
    hasSupport: _.get(region, 'hasSupport', false)
  };
  for (let index in formattedData) {
    localStorage.setItem(index, formattedData[index]);
  }
  if (otherRegions) {
    formattedData.other_regions = otherRegions;
    localStorage.setItem('other_regions', JSON.stringify(otherRegions));
  }

  return formattedData;
}

const actions = {};

actions.updateVersion = (data) => {
  let index;
  let formattedData = {
    name: data.name,
    client_slug: data.slug,
    client_id: data.id,
    platform_name: data.platform_name,
    description: data.description,
    favicon: data.urls.favicon.original,
    primaryColor: data.primary_color,
    primaryColor2: Utils.decreaseBrightness(data.primary_color, 15),
    primaryColor3: Utils.decreaseBrightness(data.primary_color, 30),
    primaryColor4: Utils.decreaseBrightness(data.primary_color, 45),
    secondaryColor: data.secondary_color,
    tertiaryColor: data.tertiary_color,
    logoLoginJPG: data.urls.logo_login.original,
    logoLoginWEBP: data.urls.logo_login.webp,
    logoInsideJPG: data.urls.logo_inside.original,
    logoInsideWEBP: data.urls.logo_inside.webp,
    logoTopMenuJPG: data.urls.top_menu_logo.original,
    logoTopMenuWEBP: data.urls.top_menu_logo.webp,
    originalBg: data.urls.background_login.original,
    webpBg: data.urls.background_login.webp,
    links: (data.hasOwnProperty('links')) ? JSON.stringify(data.links) : JSON.stringify([]),
    currentPolicy: _.get(data, 'current_policy', null),
    sso: _.get(data, 'sso', []),
    faceRecognition: _.get(data, 'engine_params.images.person_recognition', false),
    objectRecognition: _.get(data, 'engine_params.images.object_recognition', false),
  };

  for (index in formattedData) {
    if (index === 'currentPolicy' || index === "sso") {
      localStorage.setItem(index, JSON.stringify(formattedData[index]))
    } else {
      localStorage.setItem(index, formattedData[index])
    }
  }
  return {
    type: ActionTypes.UPDATED_VERSION,
    data: formattedData,
    csrf_token: _.get(data, 'csrf_token', ""),
    palette: {
      primary: {
        main: formattedData.primaryColor,
        dark: formattedData.primaryColor3,
        contrastText: '#FFF',
      }
    }
  }
};

actions.cleanData = (data) => {
  let index;
  let formattedData = [
    'name', 'client_slug', 'platform_name', 'description', 'favicon', 'primaryColor', 'primaryColor2',
    'primaryColor3', 'primaryColor4', 'secondaryColor', 'tertiaryColor', 'logoLoginJPG', 'logoLoginWEBP',
    'logoInsideJPG', 'logoInsideWEBP', 'originalBg', 'webpBg', 'links'
  ];
  for (index in formattedData) {
    localStorage.removeItem(formattedData[index]);
  }
  return {
    data: [],
    version: 0,
  };
};

actions.invalidDns = () => {
  let response = actions.cleanData();
  response.type = ActionTypes.INVALID_DNS;
  return response;
};

actions.invalidRegion = () => {
  let response = actions.cleanData();
  response.type = ActionTypes.INVALID_REGION;
  return response;
};

actions.updatingApp = () => {
  let response = actions.cleanData();
  response.type = ActionTypes.IS_UPDATING;
  return response;
};

actions.updateUserData = (data) => {
  return async (dispatch, getState) => {
    let index;
    let { user, profile, region, token, otherRegions, canmanage, upload } = data;
    const { appReducer: { data: { currentPolicy } } } = getState()

    let formattedData = {
      region: region.slug,
      region_name: region.name,
      region_id: region.id,
      region_thumbnail: region.urls.thumbnail.original,
      hasIntegration: _.get(region, 'hasIntegration'),
      path: Settings.URL_API + "/api/" + (localStorage.getItem("client_slug") || data.client_slug) + "/" + region.slug,
      clientPath: Settings.URL_API + "/api/" + (localStorage.getItem("client_slug") || data.client_slug),
      user_auth: true,
      user_id: user.id,
      user_token: "Bearer " + token,
      user_email: user.email,
      policies: profile.policies,
      profile_id: profile.id,
      navigation_view_mode: data.user.navigation_view_mode,
      language: user.language,
      hasSupport: _.get(region, 'hasSupport', false),
      current_policy_accepted: Boolean(currentPolicy) ? _.get(user, 'current_policy.accepted', false) : true,
      enableNewItemFormPage: Settings.ENABLE_NEW_TEM_FORM_PAGE.some(email => email === user.email)
    };
    for (index in formattedData) {
      localStorage.setItem(index, formattedData[index]);
    }

    if (user.navigation_view_mode) {
      localStorage.setItem('navigation_view_mode', user.navigation_view_mode)
    }

    formattedData.other_regions = otherRegions;
    formattedData.can_manage = canmanage;
    formattedData.upload_config = upload;

    localStorage.setItem('other_regions', JSON.stringify(otherRegions));
    localStorage.setItem('can_manage', JSON.stringify(canmanage));
    localStorage.setItem('upload_config', JSON.stringify(upload));

    let dataAction = {
      type: ActionTypes.UPDATE_USER_DATA,
      data: formattedData,
      path: formattedData.path,
      clientPath: formattedData.clientPath,
      token: formattedData.user_token,
    }


    if (localStorage.getItem('i18nextLng') && localStorage.getItem('i18nextLng') !== user.language) {
      const lngCurrent = localStorage.getItem('i18nextLng')
      const { appReducer } = getState()
      try {
        const resp = await axios.put(`${appReducer.pathClient}/local`,
          { local: lngCurrent.toLowerCase() },
          {
            headers: {
              Authorization: formattedData.user_token
            }
          });

        dataAction['language'] = resp.data.local
      } catch (err) {
        dispatch(actions.triggerError(err))
      }
    } else {

      if (i18n.language.toLowerCase() !== user.language) {
        dataAction['language'] = user.language
        i18n.changeLanguage(user.language, () => {
          localStorage.setItem('i18nextLng', user.language)
        })
      } else {
        dataAction['language'] = user.language
      }
    }

    return dispatch(dataAction);
  }
};

actions.triggerError = (err, t = null) => {
  if (err.response) {
    if (err.response.headers) {
      if (err.response.headers['x-message']) {
        let message = err.response.headers['x-message'];
        if (err.response.data.name) {
          message += ' -> ' + err.response.data.name;
        }

        toast.error(message);

        return {
          type: ActionTypes.TRIGGER_ERROR
        }
      }
    }
  }

  if (t) {
    toast.error(t("Erro ao carregar os dados, contate o suporte"));
  } else {
    toast.error("Erro ao carregar os dados, contate o suporte");
  }

  return {
    type: ActionTypes.TRIGGER_ERROR
  }
};

actions.openDialog = (target) => {
  return { type: ActionTypes.OPEN_DIALOG, target }
};

actions.closeDialog = (target) => {
  return { type: ActionTypes.CLOSE_DIALOG, target }
};

actions.setUserData = (data) => {
  localStorage.setItem(data.field, data.value)
  return { type: ActionTypes.SET_USER_DATA, data }
};

actions.setSearchText = (text) => {
  return { type: ActionTypes.SET_SEARCH_TEXT, text }
};

actions.checkRegion = () => {
  return (dispatch, getState) => {
    const { appReducer } = getState();
    const { other_regions, region } = appReducer.userData;
    const { pathClient, requestHeaders } = appReducer;

    const regionUrl = Utils.getRegionFromUrl(
      _.get(appReducer.userData, 'other_regions', [])
      , _.get(appReducer.userData, 'region', ''));

    if ((!regionUrl || !other_regions) || (region === regionUrl)) {
      dispatch({ type: ActionTypes.REGION_CHECKED, regionInvalid: null });
      return;
    }

    const regionsWithUrl = other_regions.filter(r => r.slug === regionUrl);

    if (regionsWithUrl.length === 0) {
      dispatch({ type: ActionTypes.REGION_CHECKED, regionInvalid: regionUrl })
      return;
    }

    const regionToChange = regionsWithUrl[0];

    dispatch(
      actions.changeRegion(
        regionToChange.slug,
        { pathClient, requestHeaders }
      )
    );
  }
}

actions.setChangeRegion = (payload) => {
  return { type: ActionTypes.CHANGE_REGION, payload }
}

actions.changeRegion = (regionSlug, data, callback, checkUpload = true) => {
  return async (dispatch, getState) => {
    function onSuccess(resp) {
      const formattedData = formatRegionData(resp.data);

      dispatch({
        type: ActionTypes.UPDATE_REGION,
        data: formattedData,
        path: formattedData.path,
        hasIntegration: formattedData.hasIntegration,
      });

      if (callback)
        callback(formattedData);

      return resp;
    }

    function onError(err) {
      switch (parseInt(err.response.status, 10)) {
        case 401:
          dispatch(actions.openDialog('expiredToken'));
          break;
        default:
          dispatch(actions.triggerError(err));
      }

      return err;
    }

    try {
      const { uploadReducer, appReducer, assetReducer } = getState();
      const { uploadFiles } = uploadReducer;
      if (!data.pathClient) {
        throw Object.assign(
          new Error("actions.changeRegion: pathClient nulo."),
          {
            response: {
              status: 400,
              headers: {
                'x-message': "Parameters missing. Please logout and login again."
              },
              data
            }
          }
        );
      }

      if (appReducer.markedItems.length > 0) {
        dispatch(actions.showConfirmDialogRegion({ regionSlug, data, callback, action: 'selected_item' }))
        return
      }

      if (assetReducer.data.length > 0) {
        dispatch(actions.showConfirmDialogRegion({ regionSlug, data, callback, action: 'selected_asset' }))
        return
      }

      if (uploadFiles.some(i => !i.uploadFinished) && checkUpload) {
        dispatch(
          UploadActions.showConfirmDialog({ regionSlug, data, callback, postCancelEvent: 'changeRegion' }, 'troca-regiao-upload')
        );
      } else {
        dispatch(UploadActions.uploadClearFiles());
        const resp = await axios.get(`${data.pathClient}/region/${regionSlug}`, data.requestHeaders);

        return onSuccess(resp);
      }
    } catch (error) {
      console.log("error", error)
      return onError(error);
    }
  }
};

actions.showConfirmDialogRegion = (data) => {
  return { type: ActionTypes.SHOW_CONFIRM_DIALOG_REGION, data }
};

actions.hiddenConfirmDialogRegion = () => {
  return { type: ActionTypes.HIDDEN_CONFIRM_DIALOG_REGION }
};

actions.bindToClientChannel = (channelName, message, callback) => {
  return async (dispatch, getState) => {
    const { appReducer } = getState();
    const { client_id } = appReducer.data;

    dispatch(actions.bindToChannel(channelName, message, callback, null, client_id));
  }
}

actions.unbindContext = (channelName, context) => {
  return async (dispatch, getState) => {
    const { appReducer } = getState();
    if (appReducer.pusher.connection) {
      const { user_id } = appReducer.userData;
      const channel = appReducer.pusher.connection.channel(`${channelName}-${user_id}`)
      if (channel) {
        channel.unbind(null, null, context)
      }
    }
  }
}

actions.bindToChannel = (channelName, message, callback, context = null, key = null) => {
  return async (dispatch, getState) => {
    const { appReducer } = getState();
    const { connection, channels_connecting } = appReducer.pusher;
    const { user_id } = appReducer.userData;
    const channelKey = key || user_id;

    if (connection && user_id) {
      const key = `${channelName}-${channelKey}`
      const channel = appReducer.pusher.connection.subscribe(key);
      if (!channels_connecting.includes(channelName) && !channel.subscribed) {
        channel.bind('pusher:subscription_succeeded', () => {
          console.log('Pusher: subscription OK: ', channelName);

          const { appReducer } = getState();
          const { binds_pending } = appReducer.pusher;

          binds_pending.forEach((bind) => {
            if (bind.channel === channelName) {
              if (bind.context)
                channel.bind(bind.message, bind.callback, bind.context)
              else
                channel.bind(bind.message, bind.callback)
            }
          });

          dispatch({ type: ActionTypes.BIND_CHANNEL_SUCCEEDED, channelName });
        });

        channel.bind('pusher:subscription_error', (error, data) => {
          console.log("Pusher: subscription ERROR: ", channelName)
          dispatch({ type: ActionTypes.BIND_CHANNEL_FAILED, channelName });
        });

        dispatch({ type: ActionTypes.BINDING_CHANNEL, channelName, message, callback, context });
      } else {
        if (!channels_connecting.includes(channelName)) {
          channel.unbind(message, callback);
          if (context)
            channel.bind(message, callback, context);
          else
            channel.bind(message, callback);
        } else {
          dispatch({ type: ActionTypes.BINDING_CHANNEL, channelName, message, callback, context });
        }
      }
    }
  }
}

actions.changeLanguage = (language) => {
  if (language) {
    localStorage.setItem('i18nextLng', language)
    localStorage.setItem('language', language)
    i18n.use(initReactI18next)
      .init({ lng: language });
  }

  return { type: ActionTypes.CHANGE_LANGUAGE, language }
}

actions.channelUnsubscribe = (channel, key = null) => {
  return { type: ActionTypes.CHANNEL_UNSUBSCRIBE, channel, key }
}

actions.clientChannelUnsubscribe = (channel) => {
  return (dispatch, getState) => {
    const { appReducer } = getState();
    const { client_id } = appReducer.data;

    dispatch(actions.channelUnsubscribe(channel, client_id));
  }
}

actions.changeLanguage = (language) => {
  return { type: ActionTypes.CHANGE_LANGUAGE, language }
}

actions.setmarkedItems = (item) => {
  return { type: ActionTypes.SET_SELECTED_ITEMS, item }
}

actions.removeMarkedItems = (items) => {
  return { type: ActionTypes.REMOVE_SELECTED_ITEMS, items }
}

actions.clearMarkedItems = () => {
  return { type: ActionTypes.CLEAR_ITEMS_SELECTEDS }
}

actions.markAsset = (asset) => {
  return { type: ActionTypes.MARK_ASSET, asset }
}

actions.unmarkAsset = (asset) => {
  return { type: ActionTypes.UNMARK_ASSET, asset }
}

actions.clearMarkedAssets = () => {
  return { type: ActionTypes.CLEAR_MARKED_ASSETS }
}

actions.markListAssets = (assets) => {
  return { type: ActionTypes.MARK_LIST_ASSETS, assets }
}

actions.enqueueRequest = params => {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { appReducer } = getState();
    const { requestHeaders, userData, enqueueRequestCount } = appReducer;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    dispatch({ type: ActionTypes.INCREASE_REQUEST_COUNT })

    setTimeout(() => {
      dispatch({ type: ActionTypes.DECREASE_REQUEST_COUNT });
      const promise =
        _.get(params, "type", "post") === "simple"
          ? simpleGet(params.url, params.payload, userData)
          : axios.post(params.url, params.payload, { ...requestHeaders, cancelToken: source.token })

      promise
        .then(resp => resolve(resp))
        .catch(err => reject(err))
    }, enqueueRequestCount * 500)
  });
}

actions.setUserModeView = (option) => {
  localStorage.setItem('navigation_view_mode', option)
  return { type: ActionTypes.SET_USER_MODE_VIEW, option }
}

actions.setHasSupportCurrentRegion = (option) => {
  return { type: ActionTypes.SET_HAS_SUPPORT_CURRENT_REGION, option }
}

actions.setColorPlatform = (option) => {
  localStorage.setItem('primaryColor', option)
  return { type: ActionTypes.SET_COLOR_PLATFORM, option }
}

actions.setUpdatedItem = (updated_item) => {
  return { type: ActionTypes.SET_UPDATED_ITEM, updated_item }
}

export default actions;
