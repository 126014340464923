import { makeStyles } from "@material-ui/core/styles"
import { darken } from "polished"

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "calc(100vw - 360px)",
    wordWrap: 'break-word',
    backgroundColor: theme.palette.primary.main,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "30px 20px",
    borderRadius: 0,
    "& .breadcrumb": {
      marginBottom: 5
    },
    "& .divTaxonomy": {
      "& h5": {
        display: "inline",
        fontSize: "clamp(1rem, 1.5vw, 2rem)",
      },
    },
    '& .MuiSkeleton-root': {
      backgroundColor: '#666',
      borderRadius: 4
    },
    "& .content-label": {
      display: "flex",
      flexDirection: "column",
      width: "calc(100% - 160px)",
      wordBreak: "break-all",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100%",
      "& .content-label": {
        width: "100%"
      },
      "& .breadcrumb": {
        display: "none",
      },
      "& .MuiAvatar-root.MuiAvatar-circle": {
        width: "30px !important",
        height: "30px !important",
      },
      "& .MuiBreadcrumbs-li": {
        color: "#fff",
        whiteSpace: "wrap",
        maxWidth: "250px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .MuiBreadcrumbs-separator": {
      margin: "0 3px",
    },
    "& svg.MuiSvgIcon-root": {
      fill: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        backgroundColor: darken(0.12, theme.palette.primary.main),
        fill: "#fff",
        borderRadius: "2px",
      },
    },
    "& svg.MuiSvgIcon-root#dot": {
      fill: "#fff",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& a": {
      color: "#ffffff",
      fontSize: "0.875rem",
      textDecoration: "none",
      fontWeight: 500,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: darken(0.12, theme.palette.primary.main),
      },
    },
  },
  wrapperSkeleton: {
    padding: '0 20px'
  },
  divIconClose: {
    "& .MuiSvgIcon-root": {
      fill: "#fff !important",
      fontSize: "2.2rem",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
  },
}))
