import React from 'react'
import ListDefault from "../Core/List/DataGridCustom"
import ListWrapper from '../Core/List/ListWrapper'
import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "../../../utils/Flux"
import { withTranslation } from "react-i18next"

import {
  getColumnThumbnail,
  getColumnDelete,
  getColumnEdit,
  getColumnAttributeUsage
} from "../Core/utils"

class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      title: {
        singular: props.workflow
          ? props.typeForm === "create"
            ? "Criação da ferramenta de workflow"
            : "Edição da ferramenta de workflow"
          : "Ferramenta",
        plural: props.workflow
          ? props.typeForm === "create"
            ? "Criação das ferramentas de workflow"
            : "Edição das ferramentas de workflow"
          : "Ferramentas",
      },
      messageToDelete:
        props.t("manager:Ao excluir uma ferramenta você estará excluindo todo fluxo atrelado a ela. Os dados da ferramenta serão perdidos e ela não aparecerá para nenhum perfil. Você deseja continuar?"),
    }
    this.superRender = super.render
  }

  getLine(item) {
    let thumbnail = item.thumbnail ? item.thumbnail : null

    let line = {
      id: item.id,
      thumbnail: { id: item.id, thumbnail: thumbnail },
      image: item.image,
      name: item.title,
      type: item.type
    }
    return line
  }
  getColumns() {
    const { t } = this.props

    const thumb = getColumnThumbnail((id, isWorkflow) => {
      this.props.history.push(`/manager/${isWorkflow ? "workflow" : "request"}/upload/${id}`)
    }, t)

    let columns = [
      {
        field: "name",
        headerName: t("common:Nome"),
        flex: 1,
        sortable: false,
      },
      {
        field: "type",
        headerName: t("common:Tipo"),
        flex: 1,
        sortable: false,
        renderCell: (params) => {        
          return (
            <span>
              {t(`manager:${params.value}`)}
            </span>
          )
        },
      },
    ]

    columns.unshift(thumb)

    columns.push(
      getColumnAttributeUsage((id)=>{
        this.props.history.push(`/manager/tool/${id}/usage`)
      }, t)
    )

    columns.push(
      getColumnEdit((id, isWorkflow) => {        
        this.props.history.push(`/manager/${isWorkflow ? "workflow" : "request"}/edit/${id}`)
      }, t)
    )

    columns.push(
      getColumnDelete((id, name) => {
        this.setState({
          ...this.state,
          deleteDialogOpen: true,
          idToDelete: id,
          nameToDelete: name,
        })
      }, t)
    )

    return columns
  }

  render(){
    const { hasFab, area, title, route } = this.state

    return (
      <ListWrapper 
        area={area}
        title={title.singular}
        route={route}
        hasFab={hasFab}
      >
        {this.superRender()}
      </ListWrapper>
    )
  }
}

export default connect(
  mapStateToPropsToSettings,
  null
)(withTranslation(["manager", "common"])(List))
