import React from 'react'
import _ from "lodash"
import { useTranslation } from "react-i18next"

import {
  FormControlLabel,
  Switch,
  Tooltip,
  Breadcrumbs,
  Typography,
  IconButton,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded"


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '395px',
    margin: '0px !important',
    '&::before': {
      content: 'url()',
      display: 'block',
      position: 'absolute',
      left: 'calc(50% - 10px)',
      top: '-10px',
      width: 0,
      height: 0,    
      zIndex: 15001,
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid rgba(97, 97, 97, 0.9)',
    }
  }
}))

export const HeaderTablePermissions = ({ rowsData }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getTooltip = (value) => {
    const msg = `
    ${t('manager:Habilitar')}: ${t('manager:Os Perfis permitidos terão acesso aos itens, mas apenas perfis com permissão de Gerenciar terão visualização na Categoria pela navegação no DAM.')}
    ${t('manager:Desabilitar')}: ${t('manager:A Categoria ficará visível para todos durante a navegação no DAM.')}
    `
    switch (value) {
      case 'Ocultar navegação':
      case 'Ocultar navegación':
      case 'Hide navigation':
        return msg        
      default:
        return ''
    }
  }
  
  return (
    <TableHead >
      <TableRow>
        <TableCell>{t(`common:Permissão`)}</TableCell>       
          {
            rowsData.map((policy, key) => (
              <Tooltip title={getTooltip(policy)} key={key} classes={{ tooltip: classes.root }}>
                <TableCell align="center">
                  {t(`common:${policy}`)}
                </TableCell>   
              </Tooltip>         
            ))
          }       
        <TableCell align="center">
          {t(`common:Excluir`)}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export const RowTablePermissions = ({
  rowData,
  classes,
  removePermission,
  handleChangePolicy
}) => {
  const getTitle = () => {  
    const getName = (data) => {
      let newData = []

      if(_.get(rowData, "name", false)) {
        newData.push(rowData.name)
      } else {
        for (var prop in data) {    
          if(data[prop].name) {
            newData.push(data[prop].name)
          }
          if(data[prop].value){
            newData.push(`Valor: ${data[prop].value}`)
          }    
        }  
      }
      
      return newData
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          getName(_.get(rowData, "entity_data", [])).map((i, key)=> (
            <Typography key={key} color="textPrimary" variant='body2' style={{ fontWeight: 500 }}>
              {i}
            </Typography>
          ))
        }        

        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {_.get(rowData, "hierarchy", []).map((i, key) => (
            <Typography key={key} color="textPrimary" variant='caption'>
              {i}
            </Typography>
          ))}
        </Breadcrumbs>
      </div>
    )
  }

  const titleTooltip =
      _.get(rowData, "hierarchy", []).length > 0
      ? `${_.get(rowData, "hierarchy", []).toString()} > ${_.get(rowData, "name", '')}`
      : `${_.get(rowData, "name", '')}`

  return (
    <TableRow key={rowData.id}>
      <TableCell style={{ width: 350}}>
        <Tooltip title={titleTooltip}>
          {getTitle()}
        </Tooltip>
      </TableCell>
      {
        rowData.policies.map((rule, key) => {
          const isCheckCircle = rowData.entity || _.get(rowData, "allow_by_default", []).indexOf(rule.value) > -1

          return (
            <TableCell align="center" key={key}>
              {
                isCheckCircle
                  ? (
                    <div className={classes.iconCheck}>
                      <CheckCircleOutlineRoundedIcon />
                    </div>
                  ) : (
                    <FormControlLabel
                      onChange={() => handleChangePolicy(rule.value, rowData.id)}
                      control={
                        <Switch
                          checked={rowData.allow.includes(rule.value)}
                          color="primary"
                        />
                      }
                      color="primary"
                      style={{ margin: 0 }}
                    />
                  )
              }
            </TableCell>
          )
        })
      }
      <TableCell align="center">
        <IconButton onClick={() => removePermission(rowData.id)}>
          <DeleteRoundedIcon  />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
