import React from "react"

import { Grid } from "@material-ui/core"
import Skeleton from '@material-ui/lab/Skeleton'

const LoadingSkeleton = () => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Skeleton variant="rect" width="100%" height={167.22} style={{ borderRadius: 4, margin: 0 }} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rect" width="100%" height={404.03} style={{ borderRadius: 4, margin: 0 }} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant="rect" width="100%" height={381} style={{ borderRadius: 4, margin: 0 }} />
            </Grid>
        </Grid>
    )
}

export default React.memo(LoadingSkeleton)