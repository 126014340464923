import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root:{
    '& .MuiDialog-paperWidthSm': {
     margin: '0 !important',
     padding: '40px',
     minWidth: '550px'
    }
  },
  title: {
    padding: '0 0 16px 0',
    "& h2": {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'space-between',
      '& div.info':{
        display: "flex",
        alignItems: 'center',
        color: '#666'
      },
      "& svg#main": {
        width: "40px",
        height: "40px",
        backgroundColor: '#FF9800',
        borderRadius: "50%",
        padding: "8px",
        marginRight: "10px",
        color: "#fff",
      },
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    width: '100%',
    "& button": {
      height: 40,
      width: 115,
    },
  },
}));
