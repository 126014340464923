import React, { useState } from 'react'
import axios from "axios"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { Button, Box } from '@material-ui/core'
import Settings from 'utils/Settings'
import Utils from 'utils/Utils'

const ExportCSV = () => {
    const [loading, setLoading] = useState(false)
    const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const handleExport = () => {
        axios.post(`${pathServer}/user/export`, {}, requestHeaders)
            .then((resp) => {
                setLoading(false,
                    enqueueSnackbar(t(`common:Acompanhe na Central de downloads a sua solicitação.`), {
                        ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                    }))
            })
            .catch((err) => {
                const message = Utils.ajaxErrorGetMessage(err, t, t(`common:Erro ao solicitar a geração do CSV, tente novamente.`))
                setLoading(false,
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    }))
            })
    }

    const onClick = () => {
        setLoading(true, handleExport())
    }

    return (
        <Box>
            <Button
                variant="contained"
                color="primary"
                onClick={() => onClick()}
                disabled={loading}
            >
                {t('manager:Gerar CSV')}
            </Button>
        </Box>
    )
}

export default ExportCSV