import React from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import PropTypes from "prop-types"


import {
  MenuItem,
  Avatar,
  Menu,
  Typography,
  IconButton,
  Toolbar,
  AppBar
} from "@material-ui/core"

import { withStyles } from "@material-ui/core/styles"
import axios from 'axios'
import SettingsIcon from "@material-ui/icons/Settings"
import MoreIcon from "@material-ui/icons/MoreVert"
import AppsIcon from "@material-ui/icons/Apps"
import { styles } from "./style"

import AppActions from "../../../flux/actions/AppActions"
import { Creators as HeaderAppActions } from "../../../flux/ducks/header_app"
import { Creators as ItemsActions } from "../../../flux/ducks/Items"
import AuthenticationActions from "../../../flux/actions/AuthenticationActions"
import Logo from "./Logo"
import RegionMenu from "./RegionMenu"
import UserMenu from "./UserMenu"
import AppMenu from "./AppMenu"
import { withTranslation } from "react-i18next"
import Tooltip from "@material-ui/core/Tooltip"
import blankAvatar from '../../../imagens/blank-avatar-header.svg'

import Breadcrumbs from "../Breadcrumbs"
import SearchInput from "../../Input/SearchInput"

import { LanguageButton } from '../../LanguageButton'

import DownloadCentral from './MenuItems/DownloadCentral'
import Notifications from './MenuItems/Notifications'
import PageDraftItems from './MenuItems/PageDraftItems'

import _ from 'lodash'

const avatarSize = {
  width: 30,
  height: 30,
}

const avatarMenuSize = {
  width: 30,
  height: 30,
  marginLeft: -3,
}

class HeaderMaterialDesign extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: {
        user: null,
        app: null,
        region: null,
        notifications: null
      },
      dataSubmenu: [
        {
          index: "region",
          label: "Regiões",
          url: "/manager/regions",
          active: true,
        },
        {
          index: "division",
          label: "Divisões",
          url: "/manager/divisions",
          active: true,
        },
        {
          index: "segment",
          label: "Segmentos",
          url: "/manager/segments",
          active: true,
        },
        {
          index: "attribute",
          label: "Metadados",
          url: "/manager/metadata",
          active: true,
        },
        {
          index: "schema",
          label: "Esquema de Metadados",
          url: "/manager/schema",
          active: true,
        },
        {
          index: "category",
          label: "Categorias",
          url: "/manager/categories",
          active: true,
        },
        {
          index: "user",
          label: "Usuários",
          url: "/manager/users",
          active: true,
        },
        {
          index: "profile",
          label: "Perfis",
          url: "/manager/profiles",
          active: true,
        },
        {
          index: "client",
          label: "Plataforma",
          url: "/manager/client",
          active: true,
        },
        {
          index: "request-tool",
          label: "Ferramentas",
          url: "/manager/tools",
          active: true,
        },
        {
          index: "advanced_settings",
          label: "Configurações avançadas",
          url: "/manager/advanced-settings",
          active: true,
        },
      ],
      mobileMoreAnchorEl: null,
    }
    this._isMounted = false
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this)
  }

  componentDidMount() {  
    this._isMounted = true
    if(this._isMounted)  this.handleGetCountNotifications()
    
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
  })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleResetSearch() {
    this.props.handleResetSearch()
  }

  postChangeRegion = (data) => {
    const { region } = data
    this.handleMenuClose("region", `/dam/${region}`)
  }

  handleGetCountNotifications = () => {
    const { requestHeaders, pathClient, pathServer } = this.props    
    axios.post(`${pathClient}/notification/pending`, {}, requestHeaders)
        .then((result) => {
            this.props.setCountNotifications({
              data: _.get(result, 'data.count', 0)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    axios.post(`${pathServer}/item/download/countUnread`, {}, requestHeaders)
        .then((result) => {          
            this.props.setDownloadCount({
              data: _.get(result, 'data.count', 0)
            })            
        })
        .catch((err) => {
          console.log(err)
        })        
  }

  handleChangeRegion = (slug) => {
    const { requestHeaders, pathClient } = this.props
    const data = { requestHeaders, pathClient }
    this._isMounted &&
      this.setState(
        {
          ...this.state,
          anchorEl: {
            user: null,
            app: null,
            region: null,
            notifications: null
          },
        },
        () => {
          this.props.changeRegion(slug, data, this.postChangeRegion)         
        }
      )
  }

  handleSubmitSearch(value) {
    let { localHistory, current_region, title } = this.props
    value = encodeURIComponent(value)

    if (value.trim() !== "" && title !== "Bucket") {
      localHistory.push("/search/" + current_region + "/" + value)
      this.props.resetSession()
      this.props.setSearchText(value)
    }
    if (title === "Bucket" && value !== "") {
      this.props.getFilterData(value)
    }

    if (title === "Bucket" && value.length === 0) {
      this.props.handleResetSearch()
    }
    return true
  }

  handleMenuOpen = (event, buttonName) => {
    let newState = { ...this.state }
    newState.anchorEl[buttonName] = event.currentTarget
    this.setState(newState)
  }

  handleMobileMenuOpen = (event) => {
    let newState = { ...this.state }
    newState.mobileMoreAnchorEl = event.currentTarget
    this.setState(newState)
  }

  handleMenuClose = (buttonName, route = null, logout) => {
    let { localHistory, logoutToPlataform } = this.props
    if (this._isMounted) {
      let newState = { ...this.state }
      newState.anchorEl[buttonName] = null
      newState.mobileMoreAnchorEl = null
      this.setState(newState, () => {
        route && localHistory.push(route)
        logout && logoutToPlataform()
      })
    }
  }

  handleMobileMenuClose = () => {
    let newState = { ...this.state }
    newState.mobileMoreAnchorEl = null
    this.setState(newState)
  }


  render() {
    const SELF = this
    const { anchorEl, mobileMoreAnchorEl, dataSubmenu } = SELF.state
    let {
      classes,
      title,
      pattern,
      index,
      localHistory,
      canManage,
      t,
    } = SELF.props
    const breadcrumbs = SELF.props.breadcrumbs || []
    const user = { isMenuOpen: Boolean(anchorEl.user) }
    const app = { isMenuOpen: Boolean(anchorEl.app) }
    const region = { isMenuOpen: Boolean(anchorEl.region) }   
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
    const isShortMenu = Boolean(window.innerWidth > 700)
    let submenuActive = title
    let styleLink
    let menuSettings = dataSubmenu.map((item, key) => {
      let styleElem = { fontWeight: 700 }
      if (key !== index) {
        styleElem = { fontWeight: 100 }
      } else {
        submenuActive = item.label
      }
      styleLink = { margin: "0 5px", cursor: "pointer", textAlign: 'center' }
      if (!item.active) {
        styleLink.display = "none"
      }
      return (
        <span
          key={item.index}
          onClick={() => localHistory.push(item.url)}
          style={styleLink}          
        >
          <Typography variant="body2" color="inherit" style={styleElem}>
            {this.props.t(`common:${item.label}`)}
          </Typography>
        </span>
      )
    })

    if (!pattern) {
      pattern = "dam"
    }   
       
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}        
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}        
      >     
        <li>
          <Notifications changeRegion={this.props.changeRegion} isMobile />
        </li>
        <li>
          <DownloadCentral isMobile />
        </li>     

        {this.props.otherRegions.length ? (
          <MenuItem onClick={(evt) => this.handleMenuOpen(evt, "region")}>
            <IconButton color="inherit">
              <Avatar
                alt="Região"
                src={this.props.region_thumbnail}
                style={avatarMenuSize}
              />
            </IconButton>
            <p>{this.props.t("common:Alterar Região")}</p>
          </MenuItem>
        ) : null}
        <MenuItem onClick={(evt) => this.handleMenuOpen(evt, "app")}>
          <IconButton color="inherit">
            <AppsIcon />
          </IconButton>
          <p>{this.props.t("common:Aplicações")}</p>
        </MenuItem>       
        <MenuItem onClick={(evt) => this.handleMenuOpen(evt, "user")}>
          <IconButton color="inherit">
            <Avatar
              alt="Logo Cliente"
              src={blankAvatar}
              style={avatarMenuSize}
            />
          </IconButton>
          <p>{this.props.t("common:Conta")}</p>
        </MenuItem>
        <MenuItem className={classes.btnTranslateMobile}>
          <LanguageButton  isLogged={Boolean(this.props.user_auth)} />
        </MenuItem>
      </Menu>
    )

    return (
      <div className={classes.root} data-disableselect={true}>
        <AppBar position="static" color="primary" id="header-root-page" style={{ zIndex: 0 }}>
          <Toolbar>
            <Logo />

            {pattern === "dam" ? (
              <div className={classes.contentTitle}>
                <Breadcrumbs data={breadcrumbs} title={title} />
              </div>
            ) : (
              <Typography
                className={classes.title}
                style={{ display: "flex" }}
                variant="h6"
                color="inherit"                
              >
                {isShortMenu ? menuSettings : submenuActive}
              </Typography>
            )}

            <div className={classes.grow} />

            {pattern === "dam" && title !== "Bucket" && (              
                <SearchInput               
                  placeholder={`${this.props.t("common:Buscar no DAM")}...`}
                  handleSubmitSearch={SELF.handleSubmitSearch}
                  value={this.props.searchText}
                />              
            )}
            
            <div className={classes.sectionDesktop}>
             { pattern === "dam"  && (
               <>
                <DownloadCentral /> 
                <Notifications changeRegion={this.props.changeRegion} />             
               </>
              )}

                {canManage && pattern === "dam"  && (
                  <PageDraftItems />
                )}
               
              <div className={classes.btnTranslate}>
                <LanguageButton  isLogged={Boolean(this.props.user_auth)}/>
              </div>

              {this.props.otherRegions.length ? (
                <React.Fragment>
                  <Tooltip title={this.props.t("common:Regiões")}>
                    <IconButton
                      disableRipple
                      aria-owns={
                        region.isMenuOpen ? "material-appbar" : undefined
                      }
                      aria-haspopup="true"
                      onClick={(evt) => this.handleMenuOpen(evt, "region")}
                      color="inherit"
                      style={{ padding: 10 }}
                    >
                      <Avatar
                        alt="Região"
                        src={this.props.region_thumbnail}
                        style={avatarSize}
                      />
                    </IconButton>
                  </Tooltip>
                  <RegionMenu
                    anchorEl={anchorEl}
                    region={region}
                    otherRegions={this.props.otherRegions}
                    handleMenuClose={this.handleMenuClose}
                    changeRegion={this.handleChangeRegion}
                  />
                </React.Fragment>
              ) : null}
              <IconButton
                aria-owns={user.isMenuOpen ? "material-appbar" : undefined}
                aria-haspopup="true"
                onClick={(evt) => this.handleMenuOpen(evt, "app")}
                color="inherit"
                disableRipple
                style={{
                  padding: 10,
                }}
              >
                <AppsIcon />
              </IconButton>
              {app.isMenuOpen && (
                <AppMenu
                  anchorEl={anchorEl}
                  app={app}
                  handleMenuClose={this.handleMenuClose}
                />
              )}

              {canManage ? (
                <Tooltip title={this.props.t("common:Configurações")}>
                  <IconButton
                    color="inherit"
                    onClick={() => this.handleMenuClose("", "/manager/regions")}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title={this.props.t("DAM:Dados do usuário")}>
                <IconButton
                  disableRipple
                  aria-owns={user.isMenuOpen ? "material-appbar" : undefined}
                  aria-haspopup="true"
                  onClick={(evt) => this.handleMenuOpen(evt, "user")}
                  color="inherit"
                  style={{ padding: 10 }}
                >
                  <Avatar
                    alt="Logo Cliente"
                    src={blankAvatar}
                    style={avatarSize}
                  />
                </IconButton>
              </Tooltip>
              <UserMenu
                t={t}
                anchorEl={anchorEl}
                user={user}
                handleMenuClose={this.handleMenuClose}
              />
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    )
  }
}

HeaderMaterialDesign.propTypes = {
  classes: PropTypes.object.isRequired,
  otherRegions: PropTypes.array,
}

const mapStateToProps = ({ appReducer, headerAppReducer }) => {
  let otherRegions = appReducer.userData.other_regions
  let canManage = appReducer.userData.can_manage
  let region_thumbnail = appReducer.userData.region_thumbnail
  let current_region = appReducer.userData.region  
  let user_auth = appReducer.userData.user_auth
  
  return {
    otherRegions,
    region_thumbnail,
    current_region,
    canManage,
    requestHeaders: appReducer.requestHeaders,
    pathServer: appReducer.pathServer,
    pathClient: appReducer.pathClient,   
    user_auth,    
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchText: (text) => dispatch(AppActions.setSearchText(text)),
    triggerError: (exception) => dispatch(AppActions.triggerError(exception)),   
    changeRegion: (slug, data, callback) =>
      dispatch(AppActions.changeRegion(slug, data, callback)),
    logoutToPlataform: () => dispatch(AuthenticationActions.logout()),   
    setCountNotifications: (data) => dispatch(HeaderAppActions.setCountNotifications(data)),
    setDownloadCount: (data) => dispatch(HeaderAppActions.setDownloadCount(data)),
    resetSession: () => dispatch(ItemsActions.resetSession())
  }
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation(["login", "common"])(HeaderMaterialDesign))
