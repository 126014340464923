import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { useDataItemFiles } from '../context'
import { useSelector } from "react-redux"
import CardsAssetsSelected from './CardsAssetsSelected'

const ShowSelectedAssets = ({bucket}) => {
    const { markedAssets } = useSelector(
        (state) => state.appReducer
      )
    const {
        dataSelected,        
        handleComponentStatus,
    } = useDataItemFiles()

    useEffect(() => {
        if(dataSelected.length === 0 && !bucket) {
            handleComponentStatus('initial')
            return
        }

        if(markedAssets.length === 0 && bucket) {
            handleComponentStatus('search_bucket')            
            return
        }

    },[dataSelected, handleComponentStatus, markedAssets, bucket])
    

    return <CardsAssetsSelected bucket={bucket} />
}

ShowSelectedAssets.propTypes = {
    bucket: PropTypes.bool,     
}


export default ShowSelectedAssets