import React from 'react'
import { useTranslation } from 'react-i18next'

import SortableListWrapper from './SortableListWrapper'
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"
import { CircularProgress } from "@material-ui/core"

import { useDataItemFiles } from '../context'

const CurrentFiles = () => {
    const { dataAssets, loading } = useDataItemFiles()
    const { t } = useTranslation()

    return (
        <div style={{ padding: 20, overflow: 'auto', height: "calc(100vh - 164px)" }}>

            {
                loading && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200
                    }}>
                        <CircularProgress />
                    </div>
                )
            }
            {
                dataAssets.length > 0 && !loading &&
                <SortableListWrapper />
            }
            {
                dataAssets.length === 0 && !loading && (
                    <div style={{ width: '100%' }}>
                        <SimpleAlertMessage
                            severity="info"
                            alertTitle="Ops!"
                            message={t("DAM:Não há nenhum ativo neste momento. Que tal procurar no bucket?")}
                        />
                    </div>
                )
            }
        </div>
    )
}


export default CurrentFiles