import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 185px)",
    borderRadius: 0,
    padding: "0 25px",
    wordWrap: "break-word",
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      marginBottom: 80,
      width: 'calc(100vw - 50px)'
    },
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#666",
    marginTop: "20px",
    "& #title": {
      fontWeight: 500,
    },
    "& #info": {
      marginTop: "10px",
      fontWeight: 300,
      "& span": {
        fontSize: "0.875rem",
      },
      "& #mui-rte-container": {
        margin: 0,
      },
      "& #mui-rte-editor-container": {
        margin: 0,
        padding: 0,
      },
    },
  },
  rootChips: {
    display: 'flex',   
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))
