import React,  { useState, useEffect, useCallback } from "react"
import axios from 'axios'
import _ from "lodash"

import { useSnackbar } from "notistack"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Typography, CircularProgress } from "@material-ui/core"

import PaperTitle from "../PaperTitle"

import { useStyles } from "./styles"

import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded'

const ColorPallete = ({ idItem }) => {
  const [state, setState] = useState({ items: [], loading: true})  
  const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
  
  const { t } = useTranslation()  
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const getData = useCallback((id) => {
    axios
      .get(`${pathServer}/item/${id}/pallete`, requestHeaders)
      .then(({ data }) => {
        const DATA =_.get(data, 'colorpallete', [])      
        setState({ items: DATA, loading: false})      
      })
      .catch((err) => {
        setState({ items: [], loading: false})
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("manager:Erro inesperado. Contate o suporte")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
      })
  }, [pathServer, requestHeaders, enqueueSnackbar, t])

  useEffect(() => {
    let isMounted = true
    if (isMounted) getData(idItem)
    return () => {
      isMounted = false
    }
  }, [getData, idItem])

  return (
    <>
      <PaperTitle title={t("common:Paleta de cores")} icon={PaletteRoundedIcon} />

      {state.loading && (
        <>
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        </>
      )}

      {(!state.loading && state.items.length > 0) && (
        <div className={classes.listWrapper}>
          <div style={{ padding: "0 20px 20px 20px", color: "#666" }}>          
            <Typography variant="body2">
              {t("common:Visualize as cores que se destacam no item")}
            </Typography>
          </div>          
         <div style={{padding: '0 20px'}}>
          {
             state.items.map((item, key) => (
              <div key={`${key}-${item.hex_extracted}-${item.percentage}`} className={classes.contentColor} style={{backgroundColor: item.hex_extracted}}>
                 <Typography variant="body2" style={{color: item.font_color}}>
                  {item.hex_extracted}
                </Typography>
                <Typography variant="body2" style={{color: item.font_color}}>
                  {item.percentage}%
                </Typography>
              </div>
            ))
          }
         </div>
        </div>
      )}
    </>
  )
}

export default ColorPallete
