import React, { createContext, useState, useContext } from "react"
import { useSelector } from "react-redux"
import Utils from "utils/Utils"
const ItemFilesContext = createContext()

export default function ItemFilesProvider({ children }) {
    const { data } = useSelector(state => state.assetReducer)
    const { markedAssets } = useSelector((state) => state.appReducer)

    const [dataAssets, setDataAssets] = useState(data)
    const [dataSelected, setDataSelected] = useState([])
    const [componentStatus, setComponentStatus] = useState({
        current: 'initial', last: 'initial'
    })

    const handleComponentStatus = (status) => {
        setComponentStatus(prev => ({
            current: status,
            last: prev.current
        }))
    }

    const handleGoBackShowBucket = () => {
        handleComponentStatus('initial')
    }

    const handleAddAssets = () => {
        setDataAssets(prev => Utils.removeDuplicateDataForIDs(prev, markedAssets))
        handleComponentStatus('initial')
    }

    const isDisabledAddAssets = (dataAssets.length === 0) || dataAssets.length >= 151

    return (
        <ItemFilesContext.Provider
            value={{
                dataAssets,
                setDataAssets,
                dataSelected,
                setDataSelected,
                componentStatus,
                handleComponentStatus,
                handleGoBackShowBucket,
                handleAddAssets,
                isDisabledAddAssets
            }}
        >
            {children}
        </ItemFilesContext.Provider>
    )
}

export function useDataItemFiles() {
    const {
        dataAssets,
        setDataAssets,
        dataSelected,
        setDataSelected,
        componentStatus,
        handleComponentStatus,
        handleGoBackShowBucket,
        handleAddAssets,
        isDisabledAddAssets
    } = useContext(ItemFilesContext)

    return {
        dataAssets,
        setDataAssets,
        dataSelected,
        setDataSelected,
        componentStatus,
        handleComponentStatus,
        handleGoBackShowBucket,
        handleAddAssets,
        isDisabledAddAssets
    }
}
