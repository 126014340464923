import React from 'react';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { makeStyles } from "@material-ui/core/styles";
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ZoomOutRoundedIcon from '@material-ui/icons/ZoomOutRounded';

import Loading from '../Loading'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 10,
    width: '100%'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: 5,
    '& .btnZoom': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 80,
      backgroundColor: '#eee',
      padding: 5,
      borderRadius: 4,
      color: '#333',  
      '& button': {
        cursor: 'pointer',
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        fontSize: 20,
      },
      '& .btnHandleZoom': {
        opacity: 0.2,
        cursor: 'default',
      }
    },  
    '& .btnPages': {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: 200,
      backgroundColor: '#eee',
      padding: 5,
      borderRadius: 50,
  
      '& p': {
        fontSize: 12,
        color: '#333',
      },  
      '& button': {
        cursor: 'pointer',
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        fontSize: 20,
      },
      '& .btnHandlepage': {
        opacity: 0.2,
        cursor: 'default',
      }
    }
  }
}))

const Previewpdf = ({onDocumentLoadSuccess, pageNumber, numPages,
    setPageNumber, pdf, scale, setScale  }) => {
    const classes = useStyles()
    const next = pageNumber >= numPages
    const previous = pageNumber <= 1
    const showZoomIn  = scale > 2
    const showZoomOut = scale <= 0.7

    return (
    <div className={classes.wrapper}>
      <div className={classes.buttonsContainer}>
        {(numPages > 1) &&
          <div className='btnPages'>

          <button className={previous ? 'btnHandlepage' : null}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',            
          }}
          onClick={() => previous ? null : setPageNumber(pageNumber -1)}>{'<'}</button>

            <p>Page {pageNumber} of {numPages}</p>

          <button className={next ? 'btnHandlepage' : null}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',            
          }}
          onClick={() => next ? null : setPageNumber(pageNumber +1)}>{'>'}</button>
        </div>
        }
        <div className='btnZoom'>
          <button className={showZoomIn ? 'btnHandleZoom' : null}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',            
          }}
            onClick={() => showZoomIn ? null : setScale(scale + 0.2)}>
              <ZoomInRoundedIcon  />
            </button>
          <button className={showZoomOut ? 'btnHandleZoom' : null}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',            
          }}
          onClick={() => showZoomOut ? null : setScale(scale - 0.2)}>
              <ZoomOutRoundedIcon  />
          </button>
        </div>
      </div>

      <Document
        error="Error..."
        noData="No data to display..."
        file={pdf}
        loading={<Loading showLoading={true} />}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} width={800} scale={scale} />
      </Document>
    </div>
  );
}

export default Previewpdf
