import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    backdrop_root: {
        inset: 0,
        zIndex: 1050,
        position: 'fixed',
        backgroundColor: '#eee',
        userSelect: "none"
    },
    content: {
        position: 'fixed',
        top: 164,
        left: 0,
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100% - 164px)',
        zIndex: 1055
    },
}))

const FullScreenDialogCustom = ({ open, children }) => {
    const classes = useStyles();

    useEffect(() => {
        let isMoutend = true
        if (isMoutend) {
            document.body.style.overflow = "hidden"
        }
        return () => {
            isMoutend = false
            document.body.style.overflow = "inherit"
        }
    })

    return open && (
        <>
            <div className={classes.backdrop_root} />
            <div className={classes.content}>
                {children}
            </div>
        </>
    );
}


export default FullScreenDialogCustom