import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"

export const useStyles = makeStyles((theme) => ({
  dragDisplay: {
  fontWeight: "bold",
  width: "100%",
  height: "100%",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  flexWrap: "wrap",
  flexDirection: "column"
  },
  cloudUploadIcon: {
    fontSize: "5em",
    color: grey[700],
  }
}))
