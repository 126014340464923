import React from 'react';
import _ from 'lodash'
import { useTranslation } from "react-i18next"
import {
    Grid,
    Dialog,
    Typography,
    IconButton,
    Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import Content from './Content';
import { useStyles } from "../styles"

function DialogDetails({ open, downloadData, handleDownload, handleOnlyDownload, handleCloseDialog }) {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            fullWidth
            className={classes.rootDialog}
        >
            <Grid style={{ width: "100%" }}>
                <div className="header">
                    <div id="header-divider" />

                    <div id="header-main">
                        <Typography variant="body2">
                            {t("DAM:Este link será desativado em")} {t("common:x horas e y minutos", { hours: _.get(downloadData, 'ttl.hours', 0), minutes: _.get(downloadData, 'ttl.minutes', 0) })}
                        </Typography>
                        <Typography variant="body2">
                            {`${t("DAM:Compartilhado por")} ${_.get(downloadData, "sender.name", t('DAM:Remetente desconhecido'))}`}
                        </Typography>
                    </div>
                    <div id="header-action">
                        <IconButton onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </Grid>
            <Grid
                container
                style={{
                    width: "100%",
                    overflow: "auto",
                    maxHeight: "calc(100% - 120px)"
                }}
            >
                <Grid item xs={12} style={{ width: "100%" }}>
                    <div className="sub-header">
                        <div id="header-main">
                            <Typography variant='h6' className='p-h6'>
                                {downloadData.name}
                            </Typography>
                            <Typography variant='body2'>
                                {
                                    _.get(downloadData, 'quantityAssets', 0) === 1
                                        ? `${_.get(downloadData, 'quantityAssets', 0)} ${t('DAM:arquivo')}`
                                        : `${_.get(downloadData, 'quantityAssets', 0)} ${t('DAM:arquivos')}`
                                } - {_.get(downloadData, 'size', 0)}
                            </Typography>
                        </div>
                        <div id="header-action">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleDownload(downloadData.name)}
                                startIcon={<GetAppIcon />}
                                style={{ width: "100%" }}
                                size="large"
                            >
                                {t("DAM:Baixar tudo")}
                            </Button>
                        </div>
                    </div>
                </Grid>
                {_.get(downloadData, 'dataAssets', []).length > 0 && (
                    <Content
                        cardAsset
                        title="DAM:Arquivos"
                        data={_.get(downloadData, 'dataAssets', [])}
                        dataLoadingAsset={_.get(downloadData, 'loadingAsset', [])}
                        handleOnlyDownload={handleOnlyDownload}
                    />
                )}

                {_.get(downloadData, 'items_detail', []).length > 0 && (
                    <Content
                        title="DAM:Conjunto de arquivos"
                        data={_.get(downloadData, 'items_detail', [])}
                    />
                )}
            </Grid>
        </Dialog >
    );
}

export default DialogDetails
