import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core';

import { useDataEditFiles } from './context'

const ActionsBucketManagerAssets = () => {
    const { t } = useTranslation()
    const { handleGoBackShowBucket, handleAddAssets, isDisabledAddAssets, loading } = useDataEditFiles()

    return (
        <>
            <Button
                color='primary'
                onClick={handleGoBackShowBucket}
                disabled={loading}
            >
                {t("common:Voltar")}
            </Button>

            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                <Button
                    variant='contained'
                    color='primary'
                    style={{ height: 40 }}
                    onClick={handleAddAssets}
                    disabled={isDisabledAddAssets || loading}
                >
                    {t("common:Adicionar")}
                </Button>
            </div>
        </>
    )
}

ActionsBucketManagerAssets.propTypes = {
    loading: PropTypes.bool,
}

export default ActionsBucketManagerAssets