import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash'
import axios from "axios"
import Settings from "../../utils/Settings";
import { useStyles } from "./styles"
import { useTranslation } from "react-i18next"

import {
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    CircularProgress,
    Box
} from "@material-ui/core"

const Regions = ({ handleClick, requestHeaders, client_slug }) => {
    const [state, setState] = useState({
        data: [], loading: true, dataError: false
    })
    const classes = useStyles()
    const { t } = useTranslation()

    const getData = useCallback(async () => {
        try {
            const { data } = await axios.get(`${Settings.URL_API}/client/${client_slug}/region`, requestHeaders)
            setState(prev => ({ ...prev, data: _.get(data, 'items', []), loading: false }))
        } catch (e) {
            setState(prev => ({ ...prev, loading: false, dataError: true }))
            console.log(e)
        }
    }, [requestHeaders, client_slug])

    useEffect(() => {
        let isMounted = true
        if (isMounted && state.loading) getData()
        return () => { isMounted = false }
    }, [getData, state.loading])

    return (
        <>
            {
                state.loading ? (
                    <Box className={classes.loading}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {
                            state.dataError ? (
                                <Box className={classes.loading}>
                                    {t('common:Erro ao carregar os dados.')}
                                </Box>
                            ) : (
                                <List className={classes.rootList}>
                                    {
                                        state.data.map((item, index) => (
                                            <ListItem
                                                key={_.get(item, 'id', '')}
                                                button
                                                onClick={() => handleClick({ region: _.get(item, 'slug', ''), activeStep: 1 })}
                                                id={index === (state.data.length - 1) ? 'last' : ''}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar src={_.get(item, 'urls.thumbnail.original', '')} />
                                                </ListItemAvatar>
                                                <ListItemText primary={_.get(item, 'name', '')} style={{ marginLeft: 20 }} />
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            )
                        }
                    </>
                )
            }
        </>
    );
};

export default Regions;
