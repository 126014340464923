import React, { useState, useCallback } from "react"
import _ from 'lodash'
import axios from "axios"
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { useSnackbar } from "notistack"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  List
} from "@material-ui/core"

import { ListRow } from './ListRow'
import { useStyles } from "./styles"

import Utils from 'utils/Utils'

const DialogChangeSchemas = ({ children, metadata_name = "", category_id, attribute_id, itemId }) => {
  const [state, setState] = useState({ data: [], open: false })
  const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
  const { t } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const getData = useCallback(() => {

    var _metadata_name = metadata_name;
    if (_metadata_name.indexOf('#') !== -1 || _metadata_name.indexOf('/') !== -1) {
      _metadata_name = _metadata_name.replaceAll('#', escape('#'))
      _metadata_name = _metadata_name.replaceAll('/', escape(encodeURIComponent('/')));
    }

    const promises = [
      axios.get(`${pathServer}/category/${category_id}/attribute/${attribute_id}/variant_structure`, requestHeaders),
      axios.get(`${pathServer}/item/${itemId}/variant/${attribute_id}/${_metadata_name}`, requestHeaders)
    ]

    Promise.allSettled(promises)
      .then((list) => {
        let result = {}
        list.forEach(item => {
          if (item.status === "fulfilled") {
            if (Array.isArray(item.value.data)) {
              result = {
                ...result,
                ..._.get(item, "value.data[0]", {}),
              }
            } else {
              result = {
                ...result,
                ..._.get(item, "value.data", {}),
              }
            }
          }
        })

        const attributes = (() => {
          let newData = _.get(result, 'attribute.attributes', []).map(att => {
            const attributeValue = _.get(result, "variants", []).find(i => i.attribute_id === att.id)
            if (attributeValue) {
              return {
                ...att,
                value: Array.isArray(attributeValue.value)
                  ? attributeValue.value.map(i => ({ value: i, label: i, id: att.id }))
                  : attributeValue.value
              }
            }
            return att
          })
          return newData
        })()

        setState(prev => ({ ...prev, attributes, open: true }))
      })
      .catch((err) => {
        console.log('Error:', err)
        let message = Utils.ajaxErrorGetMessage(err, t)
        setState(prev => ({ ...prev, error: true, open: true }),
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }))
      })
  }, [pathServer, requestHeaders, itemId, category_id, attribute_id, metadata_name, enqueueSnackbar, t])

  const show = useCallback(() => () => {
    getData()
  }, [getData])

  const hide = useCallback(() => {
    setState(prev => ({ ...prev, open: false }))
  }, [])

  return (
    <React.Fragment>
      {children(show)}
      {state.open && (
        <Dialog
          open={state.open}
          onClose={hide}
          className={classes.root}
          maxWidth="sm"
          PaperProps={{ style: { overflowY: 'visible' } }}
        >
          <DialogTitle style={{ padding: 0, marginBottom: 16 }}>
            <Typography variant='h6'>
              {t("manager:Editar variantes do atributo")}: {_.get(state, "data.name", "")}
            </Typography>
          </DialogTitle>
          <DialogContent style={{ overflowY: 'auto', maxHeight: 400 }}>
            <List dense className={classes.rootList}>
              {
                _.get(state, "attributes", []).map(variant => (
                  <ListRow
                    key={variant.id}
                    variant_attribute={variant}
                    attribute_id={attribute_id}
                    itemId={itemId}
                    metadata_name={metadata_name}
                  />
                ))
              }
            </List>

          </DialogContent>
          <DialogActions style={{ padding: 0, marginTop: 16 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={hide}
            >
              {t(`common:Fechar`)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}

export default DialogChangeSchemas
