import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import Content from './Content'
import { Creators as pageBucketActions } from "flux/ducks/page_bucket"
import HeaderMaterialDesign from "components/Header/HeaderMaterialDesign/HeaderMaterialDesign"

const useStyles = makeStyles((theme) => ({
  root: {
    '& header': {
      position: 'fixed',
      zIndex: '1100 !important'
    }
  },
}))

const BucketWrapper = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    let isMounted = true
    return () => {      
      if (isMounted) dispatch(pageBucketActions.resetData())
      isMounted = false
    }
  }, [dispatch])

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Bucket - {localStorage.getItem("name")}</title>
        <meta
          name="description"
          content={localStorage.getItem("description")}
        />

      </Helmet>
      <HeaderMaterialDesign
        title="Bucket"
        localHistory={history}
      />

      <Content {...props} />
    </div>
  )
}

export default BucketWrapper
