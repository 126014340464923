import _ from "lodash"

export const Types = {
    SET_STATE_BY_FIELD: "page_bucket/SET_STATE_BY_FIELD",
    SET_FILTERS_PARAMS: "page_bucket/SET_FILTERS_PARAMS",
    SET_DATA_STATE: "page_bucket/SET_DATA_STATE",
    RESET_DATA: "page_bucket/RESET_DATA",
    DELETE_FILE: "page_bucket/DELETE_FILE",
    THUMBNAIL_UPDATED: "page_bucket/THUMBNAIL_UPDATED"
}

const INITIAL_STATE = {
    checkedData: false,
    loading: true,
    loadingData: false,
    initiating: true,
    files: [],
    allowBucket: true,
    pageFindAll: 1,
    totals: {
        current: 0,
        deleted: 0,
        returned: 0,
    },
    checkedGetAssets: false,
    searchReloading: false,
    filters_params: {
        order: {},
        fileTypes: []
    }
}

export default function pageBucketReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.SET_STATE_BY_FIELD: {
            const { field, value } = action.payload
            let newState = {
                ...state,
                [field]: value,
            }
            return newState
        }
        case Types.SET_DATA_STATE: {
            const { pageFindAll, files, filters_params } = action.payload
            let newState = {
                ...state,
                ...action.payload
            }

            if (pageFindAll > 1) {
                newState.files = [...state.files, ...files]
            }
            if (!_.isEmpty(filters_params)) {
                newState.filters_params = { ...state.filters_params, ...filters_params }
            }
            return newState
        }
        case Types.SET_FILTERS_PARAMS: {
            const { value } = action.payload
            let newState = {
                ...state,
                loading: true,
                filters_params: {
                    ...state.filters_params,
                    ...value
                }
            }
            return newState
        }
        case Types.DELETE_FILE: {
            const { value } = action.payload
            let newState = { ...state }
            newState.files = newState.files.filter((file) => file.id !== value)
            newState.loading = false
            newState.totals = {
                ...state.totals,
                current: state.totals.current - 1,
                deleted: state.totals.deleted + 1,
            }
            return newState
        }
        case Types.RESET_DATA: {
            return INITIAL_STATE
        }
        case Types.THUMBNAIL_UPDATED: {
            const { data } = action.payload
            let newState = { ...state }
            const filesUpdate = newState.files.map((item) => {
                const foundFile = (item.id === data.id)

                if (foundFile) {
                    item.thumbnail_card_original = _.get(data, 'thumbnail_card_original', item.thumbnail_card_original)
                    item.thumbnail_card_webp = _.get(data, 'thumbnail_card_webp', item.thumbnail_card_webp)
                    item.thumbnail_zoom_webp = _.get(data, 'thumbnail_zoom_webp', item.thumbnail_zoom_webp)
                }

                return item
            })
            newState.files = filesUpdate
            return newState
        }
        default:
            return state
    }
}

export const Creators = {
    setStateByField: (payload) => ({
        type: Types.SET_STATE_BY_FIELD,
        payload,
    }),
    setDataState: (payload) => ({
        type: Types.SET_DATA_STATE,
        payload,
    }),
    setFiltersParams: (payload) => ({
        type: Types.SET_FILTERS_PARAMS,
        payload,
    }),
    setDeleteFile: (payload) => ({
        type: Types.DELETE_FILE,
        payload,
    }),
    thumbnailUpdated: (payload) => ({
        type: Types.THUMBNAIL_UPDATED,
        payload,
    }),
    resetData: () => ({ type: Types.RESET_DATA }),
}
