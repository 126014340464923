import React from "react"
import { Link, useRouteMatch } from "react-router-dom"
import { Breadcrumbs as BreadcrumbsUI } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import { darken } from "polished"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#ffffff",
    "& svg.MuiSvgIcon-root": {
      fill: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        backgroundColor: darken(0.12, theme.palette.primary.main),
        fill: "#fff",
        borderRadius: "2px",
      },
    },
    "& svg.MuiSvgIcon-root#dot": {
      fill: "#fff",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "& a": {
      color: "#ffffff",
      fontSize: "1em",
      textDecoration: "none",
      fontWeight: 500,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: darken(0.12, theme.palette.primary.main),
      },
    },
  },
}))

const Breadcrumbs = ({ data = [], title }) => {
  const { navigation_view_mode } = useSelector(
    (state) => state.appReducer
  )
  const classes = useStyles()
  const { t } = useTranslation()
  const match = useRouteMatch()

  const isModeItemsView = navigation_view_mode === 'view-items'
    
  if(typeof title === 'string') {
    title = {
      label: title, url: '/'
    }
  }

  if(isModeItemsView && (match.path.indexOf('/dam/') !== -1)) {
    title = {
      label: 'DAM', url: '/'
    }
    data = [{ label: 'Todos os itens', url: "#" }]
  }

  return (
    <BreadcrumbsUI
      separator={<NavigateNextIcon id="dot" fontSize="small" />}
      maxItems={8}
      aria-label="breadcrumb"
      className={classes.link}
    >
      
      <Link to={title.url} data-testid={`link-${title.label}`}>
        {t(`common:${title.label}`)}
      </Link>

      {data &&
        data.map((item, key) => {          
          if (data.length - 1 === key) {
            return (
              <span
                key={key}
                style={{ fontWeight: 200 }}
                data-testid="not-link"
              >
                 {t(`DAM:${item.label}`)}
              </span>
            )
          } else {
            return (
              <Link to={item.url} key={key} data-testid={`link-${item.label}`}>
                {t(`DAM:${item.label}`)}
              </Link>
            )
          }
        })}
    </BreadcrumbsUI>
  )
}

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
}

export default Breadcrumbs
