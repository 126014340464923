import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import DialogConfirm from "components/Dialogs/DialogConfirm"
import { useTranslation } from 'react-i18next'

import AppActions from "flux/actions/AppActions"
import { Creators as AssetActions } from "flux/ducks/Asset"

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'

import Settings from 'utils/Settings'

const PrivateDialogs = () => {    
    const { showDialogRegion } = useSelector((state) => state.appReducer) 
    
    const dispatch = useDispatch()
    const { t } = useTranslation()
    
    const handleCloseDialogConfirm = () => {
        dispatch(AppActions.hiddenConfirmDialogRegion())        
    }

    const handleDialogConfirm = () => {
        dispatch(AppActions.hiddenConfirmDialogRegion())
        dispatch(AssetActions.setAllData([]))
       
        const { regionSlug, data, callback, push, go } = showDialogRegion.data
        try { 
            if(push && go) {
                push()
                go()
            }  else {    
                dispatch(AppActions.setChangeRegion(true)) 
                dispatch(AppActions.clearMarkedItems())                          
                dispatch(AppActions.changeRegion(regionSlug, data, callback, false))                
            }         
        } catch (err) {
            
        }  
          
    }

    const getTitle = () => {
        const {action} = showDialogRegion.data        
        return t(`DAM:${Settings.DATA_ACTION_DIALOGS[action]['title']}`)
    }

    const getDescription = () => {
        const {action} = showDialogRegion.data
        return t(`DAM:${Settings.DATA_ACTION_DIALOGS[action]['msg']}`)
    }

    return (
        <>              
            {(showDialogRegion && showDialogRegion.open) &&
                <DialogConfirm 
                    title={getTitle()}
                    open
                    icon={<CheckCircleRoundedIcon id="main" />}
                    description={getDescription()}
                    handleClose={handleCloseDialogConfirm}
                    handleConfirm={handleDialogConfirm}
                /> 
            } 

        </>
    )
}

export default PrivateDialogs