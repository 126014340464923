import React from "react";

import { useTranslation } from "react-i18next";
import SelectUIMultiAsync from 'components/ComboBox/SelectUIMultiAsync'

const SelectAsync = ({
    title,
    loadOptions,
    loading,
    handleChange,
    selectedLimit = 0,
    selectionLimit = 30,
    defaultData = [],
    error = null
}) => {
  const { t } = useTranslation();

  const msgNoOptionsandLimit =  selectedLimit === selectionLimit ? t("common:Pode selecionar no maximo itens", {count: selectionLimit }) : null

  const customStyles = {
    menuList: (provided) => ({
      color: 'red',
      maxHeight: '30px',
    }),

  }

  return (
    <React.Fragment>
      <SelectUIMultiAsync
        loadOptions={loadOptions}
        isLoading={loading}
        isSearchable={selectedLimit === selectionLimit ? false : true}
        msgNoOptionsandLimit={msgNoOptionsandLimit}
        valueSelect={defaultData}
        title={title}
        handleChangeValue={handleChange}
        styles={customStyles}
        error={error}
      />
    </React.Fragment>
  );
};

export default SelectAsync;
