import React, { useState, useEffect } from "react"
import YapoliImage from "components/Filters/YapoliImage"
import photoRoundedIcon from "imagens/ui/photo-white-24dp.svg"
import { useSelector } from "react-redux"
import Skeleton from "@material-ui/lab/Skeleton"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import AutoRotatingCarousel from "./AutoRotatingCarousel"
import Slide from "./Slide"
import { useDataAssetIntegra } from "../context"

const SkeletonWrapper = () => {
  return (
    <div
      style={{
        padding: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Skeleton
        variant="rect"
        height={"calc(100vh - 342px)"}
        style={{
          borderRadius: 4,
          backgroundImage: `url(${photoRoundedIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "50%",
          maxHeight: 603,
        }}
      />
    </div>
  )
}

const AssetIntegra = ({ data, loading = true, handleComponentArea }) => {
  const [assets, setAssets] = useState([])
  const { supportsWebp } = useSelector((state) => state.uploadReducer)
  const matches = useMediaQuery("(max-width:600px)")

  const { setSlideIndex, slideIndex } = useDataAssetIntegra()

  useEffect(() => {
    if (!loading && data.assets && data.assets.length > 0) {
      const data_ = data.assets.map((i, k) => {
        if (k <= 1) {
          return i
        }
        return { id: i.id, index: k, data: i }
      })
      setAssets(data_)
    }
  }, [data.assets, loading])


  useEffect(() => {
    if (slideIndex > 1) {
      setAssets(prev =>
        prev.map(i => {
          if (i.index === slideIndex) {
            i = i.data
          }
          return i
        })
      )
    }

  }, [slideIndex])

  const renderView = (asset) => {
    const type = supportsWebp ? "webp" : "original"

    return (    
        <YapoliImage
          key={`image-${asset.id}`}
          extension={asset.extension}
          asset={asset.id}
          file={asset[`thumbnail_card_${type}`]}
          type={`thumbnail_card_${type}`}
          alt={`${asset.id}-thumbnail`}
          width={"600px"}
          height={"600px"}
        />     
    )
  }

  const showAsset = (asset, index) => {
    return (
      <Slide
        key={`${asset.id}-${index}`}
        data={{
          ...data,
          asset: asset,
          extension: asset.extension,
        }}
        media={renderView(asset)}
        mobile={matches}
        handleComponentArea={handleComponentArea}
      />
    )
  }

  const handleChangeSlide = (index) => {
    setSlideIndex(index)
  }

  return (
    <>
      {loading ? (
        <SkeletonWrapper />
      ) : (
        <AutoRotatingCarousel
          context={data}
          mobile={matches}
          countAssets={assets.length}
          handleChangeSlide={handleChangeSlide}
        >
          {assets.map((asset, index) => showAsset(asset, index))}
        </AutoRotatingCarousel>

      )}
    </>
  )
}

AssetIntegra.defaultProps = {
  data: { assets: [] },
}

export default AssetIntegra
