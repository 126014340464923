import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Paper, Box } from "@material-ui/core"

import DividerHeader from './DividerHeader'
import FiltersTags from './FiltersTags'
import Filters from "./Filters"
import Cards from "./CardWrapper"
import Loading from "components/Loading"
import DropzoneS3Uploader from "components/Upload/DropzoneS3Uploader"
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"

import { Creators as ItemsActions } from "flux/ducks/Items"
import AppActions from "flux/actions/AppActions"
import Settings from "utils/Settings"
import useContent from './hook/Content'

const SectionCards = ({ simpleBucket }) => {
    const { userData: { policies } } = useSelector(state => state.appReducer)
    const {
        loading,
        allowBucket,
        files,
    } = useSelector(state => state.pageBucketReducer)
    const { t } = useTranslation()
    const { handleReprocessItem, handleConfirmDelete } = useContent()

    const IS_MANAGER = policies.indexOf("manager-bucket") !== -1
    const hasMsgEmptyDataFiles = (files.length === 0) && !loading

    return (
        <>
            {(IS_MANAGER && allowBucket) ? (
                <Box component="section" style={{ width: '100%', height: 'auto' }}>
                    <Cards
                        dataAssets={files}
                        isManager={IS_MANAGER}
                        handleConfirmDelete={handleConfirmDelete}
                        handlerReprocess={handleReprocessItem}
                        simpleBucket={simpleBucket}
                    />

                    {
                        hasMsgEmptyDataFiles && (
                            <div className="SimpleAlertMessage">
                                <SimpleAlertMessage
                                    severity="info"
                                    alertTitle="Ops!"
                                    message={t("common:Não encontramos o que você procura. Que tal uma nova busca?")}
                                />
                            </div>
                        )
                    }
                </Box>
            ) : (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                    }}
                >
                    <Paper elevation={1} style={{ padding: 30 }}>
                        <Typography variant="h5" component="h3">
                            {t("DAM:Houve erro ao obter os dados para processar o bucket.")}
                        </Typography>
                        <Typography component="p">
                            {t("DAM:Tente sair e entrar novamente. Se o problema persistir, favor contatar o administrador do sistema informando o problema.")}
                        </Typography>
                    </Paper>
                </div>
            )}
        </>
    )
}

const callbackHandleReload = _.debounce((callback = () => { }) => {
    callback()
}, 1500)

const ContentBucket = ({
    notUplaodField = false,
    upload_config = {},
    simpleBucket = false,
    isDialog = false,
    toolbarManagerAssets
}) => {
    const [state, setState] = useState({
        assets_ids: [], bindChannel: false
    })
    const { loading, checkedData } = useSelector(state => state.pageBucketReducer)
    const { finishedUploadFiles } = useSelector(state => state.uploadReducer)

    const {
        onError,
        getData,
        getParatmeters,
        onThumbnailUpdated
    } = useContent()
    const history = useHistory()
    const dispatch = useDispatch()

    const handleReload = useCallback((data = []) => {
        const state_ids = JSON.stringify(state.assets_ids.toString())
        const current_ids = JSON.stringify(data.toString())
        if (state_ids !== current_ids) {
            const callback = () => {
                const parameters = getParatmeters()
                getData(parameters)
            }
            setState(prev => ({ ...prev, assets_ids: data }), callback())
        }
    }, [state.assets_ids, getData, getParatmeters])

    useEffect(() => {
        let isMounted = true
        const parameters = getParatmeters()
        if (isMounted && !checkedData) {
            dispatch(ItemsActions.setSession({
                name: "pathname",
                data: history.location.pathname,
            }))

            dispatch(ItemsActions.resetParamsDataToCreateItems())
            getData(parameters)
        }
        return () => isMounted = false
    }, [checkedData, getData, getParatmeters, dispatch, history])

    useEffect(() => {
        if (!state.bindChannel) {
            setState(prev => ({ ...prev, bindChannel: true }),
                dispatch(
                    AppActions.bindToChannel(
                        "private-web-platform",
                        "asset-processed-thumbnail",
                        ({ data }) => onThumbnailUpdated(data),
                        "bucket"
                    )))
        }
        return () => dispatch(AppActions.unbindContext("private-web-platform", "asset-processed-thumbnail"))
    }, [state.bindChannel, dispatch, onThumbnailUpdated])

    useEffect(() => {
        let isMounted = true
        if (isMounted && checkedData) {
            if (finishedUploadFiles.length > 0) {
                const assets_ids = finishedUploadFiles.map(asset => asset.id)
                callbackHandleReload(() => handleReload(assets_ids))
            }
        }
        return () => isMounted = false
    }, [finishedUploadFiles, checkedData, handleReload])

    return (
        <Grid container>
            {loading && <Loading showLoading />}
            {!isDialog && <Filters
                toolbarManagerAssets={toolbarManagerAssets}
                simpleBucket={simpleBucket}
            />}
            <DividerHeader />
            <FiltersTags />
            {
                notUplaodField ? (
                    <SectionCards simpleBucket={simpleBucket} />
                ) : (
                    <DropzoneS3Uploader
                        config={{
                            acceptedExtensions: Settings.ACCEPTED_EXTENSIONS,
                            entity: 'asset',
                            bucket_name: _.get(upload_config, "upload_bucket", Settings.BUCKET_NAME_DEFAULT),
                            tagging: '',
                            maxFilesUploads: 500,
                            max_size_file: '53687091200' //bytes
                        }}
                        onError={onError}
                    >
                        <SectionCards simpleBucket={simpleBucket} />
                    </DropzoneS3Uploader>
                )
            }
        </Grid>
    )
}

export default ContentBucket
