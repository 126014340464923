import React from 'react'
import { Grid } from '@material-ui/core'
import RelatedItemsComboBox from 'components/ComboBox/RelatedItemsComboBox'
import PaperCustom from '../PaperCustom'
import { useDataFormItem } from "../context"

const RelatedItems = () => {
    const { relatedItems: state, changeRelatedItems } = useDataFormItem()
    return (
        <Grid item xs={12} id="relatedItems">
            <PaperCustom title='Relacionar Itens'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RelatedItemsComboBox
                            limitItens={1000}
                            selectedItems={state.length}
                            defaultItems={state}
                            currentId={""}
                            handleChange={changeRelatedItems}
                        />
                    </Grid>
                </Grid>
            </PaperCustom>
        </Grid>
    );
}

export default React.memo(RelatedItems)
