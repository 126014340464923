import React, { useCallback } from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IconButton, Tooltip, Badge, MenuItem, Typography } from "@material-ui/core"

import { mdiProgressDownload } from "@mdi/js"
import Icon from "@mdi/react"

import { Creators as HeaderAppActions } from "flux/ducks/header_app"

export default function Menu({ isMobile }) {
    const { download_count } = useSelector((state) => state.headerAppReducer)  
    const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const onClick = useCallback(() => {
        axios.post(`${pathServer}/item/download/read`, {}, requestHeaders)
            .then(() => dispatch(HeaderAppActions.setDownloadCount({data: 0})))
            .catch(e => console.error('Erro ao zerar contador da central de downloads', e))        
        history.push(`/downloads`)
    }, [dispatch, pathServer, requestHeaders, history])

    return (
        <>
        {
                isMobile ? (
                    <MenuItem component='div' onClick={onClick}>
                        <IconButton
                            disableRipple
                            color="inherit"                        
                            aria-haspopup="true"
                        >
                            <Badge badgeContent={download_count} color="secondary">
                            <Icon path={mdiProgressDownload} size={1} />
                        </Badge>
                        </IconButton>
                        <Typography>{t("DAM:Central de downloads")}</Typography>
                    </MenuItem>
                ) : (
                    <Tooltip title={t("DAM:Central de downloads")}>
                    <IconButton
                        disableRipple
                        onClick={onClick}
                        color="inherit"
                        aria-haspopup="true"
                    >
                        <Badge badgeContent={download_count} color="secondary">
                            <Icon path={mdiProgressDownload} size={1} />
                        </Badge>
                    </IconButton>
                </Tooltip>
                )
            }
        </>      
    );
}