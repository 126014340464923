import React, { useCallback } from 'react';
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {
  Drawer,
  List,
  useMediaQuery,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import DetailsAsset from './DetailsAsset'
import BookmarkAsset from './BookmarkAsset'
import SharedAsset from './SharedAsset'
import DownloadAsset from './DownloadAsset'
import MetadataDetailsAsset from './MetadataDetailsAsset'
import Details from './Details'
import RecognitionAsset from './RecognitionAsset'

import { useDataAssetIntegra } from "../context"

const drawerWidth = 340;
const menuHeigth = 100

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 1,
    '& .MuiDrawer-paper': {
      position: 'absolute',
      height: 'calc(100vh - 100px)',
      overflowX: 'hidden',
    }
  },
  drawerOpen: {
    top: menuHeigth,
    width: drawerWidth,
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    top: menuHeigth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props => props.open ? 'flex-end' : 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  skeleton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0 8px 0'
  }
}));

export default function DrawerAssets() {
  const { data: { faceRecognition = false, objectRecognition = false } } = useSelector((state) => state.appReducer)
  const { slideIndex, stateItem: { openDrawerAssets, data, allow, loading, versionIndex }, setOpenDrawerAssets } = useDataAssetIntegra()

  const isMobile = useMediaQuery('(max-width:600px)')
  const classes = useStyles({ open: openDrawerAssets })

  const handleDrawer = useCallback(() => {
    setOpenDrawerAssets(!openDrawerAssets)
  }, [openDrawerAssets, setOpenDrawerAssets])

  const { t } = useTranslation()

  const DATA_ASSET = _.get(data, `assets[${slideIndex}]`, {})

  const SekeletonUI = () => {
    return (
      <li>
        <div className={classes.skeleton}>
          <Skeleton variant="rect" height={24} width={24} style={{ borderRadius: 4, margin: 0 }} />
        </div>
      </li>
    )
  }

  return !isMobile && (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={openDrawerAssets ? classes.drawerOpen : classes.drawerClose}
        classes={{
          paper: openDrawerAssets ? classes.drawerOpen : classes.drawerClose,
        }}
      >
        {
          loading ? (
            <List>
              {[1, 2, 3, 4, 5, 6, 7, 8].map(i => <SekeletonUI key={i} />)}
            </List>

          ) : (
            <List>
              <ListItem button onClick={handleDrawer}>
                <ListItemIcon>
                  <InfoOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t("DAM:Informações do ativo")} />
                {openDrawerAssets ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </ListItem>

              {
                openDrawerAssets &&
                <li>
                  <Details asset={DATA_ASSET} />
                </li>
              }

              <li>
                <BookmarkAsset asset={DATA_ASSET} isDisabled={!allow.manager} />
              </li>
              <li>
                <MetadataDetailsAsset
                  id_asset={_.get(DATA_ASSET, 'id', '')}
                  id_item={data.id}
                  isDrawerOpen={openDrawerAssets}
                  setDrawerOpen={openDrawerAssets ? () => { } : () => setOpenDrawerAssets(true)}
                  metadataType={'exif'}
                />
              </li>
              <li>
                <MetadataDetailsAsset
                  id_asset={_.get(DATA_ASSET, 'id', '')}
                  id_item={data.id}
                  isDrawerOpen={openDrawerAssets}
                  setDrawerOpen={openDrawerAssets ? () => { } : () => setOpenDrawerAssets(true)}
                  metadataType={'iptc'}
                />
              </li>
              {
                faceRecognition && (
                  <li>
                    <RecognitionAsset
                      DATA_ASSET={DATA_ASSET}
                      id_item={data.id}
                      isDrawerOpen={openDrawerAssets}
                      setDrawerOpen={openDrawerAssets ? () => { } : () => setOpenDrawerAssets(true)}
                      modelType="person"
                    />
                  </li>
                )
              }
              {
                objectRecognition && (
                  <li>
                    <RecognitionAsset
                      DATA_ASSET={DATA_ASSET}
                      id_item={data.id}
                      isDrawerOpen={openDrawerAssets}
                      setDrawerOpen={openDrawerAssets ? () => { } : () => setOpenDrawerAssets(true)}
                      modelType="object"
                    />
                  </li>
                )
              }
              <li>
                <DetailsAsset
                  id_asset={_.get(DATA_ASSET, 'id', '')}
                  id_item={data.id}
                  isDrawerOpen={openDrawerAssets}
                  setDrawerOpen={openDrawerAssets ? () => { } : () => setOpenDrawerAssets(true)}
                />
              </li>
              <li>
                <SharedAsset
                  id_asset={_.get(DATA_ASSET, 'id', '')}
                  id_item={data.id}
                  hasHighLow={_.get(DATA_ASSET, 'hasHighLow', false)}
                  version_item={versionIndex}
                  isDrawerOpen={openDrawerAssets}
                  setDrawerOpen={openDrawerAssets ? () => { } : () => setOpenDrawerAssets(true)}
                  isDisabled={!allow.email}
                  category_id={data.category_id}
                  dates={data.dates}
                />
              </li>
              <li>
                <DownloadAsset
                  id_asset={_.get(DATA_ASSET, 'id', '')}
                  id_item={data.id}
                  hasHighLow={_.get(DATA_ASSET, 'hasHighLow', false)}
                  version_item={versionIndex}
                  isDrawerOpen={openDrawerAssets}
                  setDrawerOpen={openDrawerAssets ? () => { } : () => setOpenDrawerAssets(true)}
                  isDisabled={!allow.download}
                  asset={DATA_ASSET}
                  dates={data.dates}
                  category_id={data.category_id}
                />
              </li>
            </List>
          )

        }
      </Drawer>
    </div>
  );
}
