import React, { useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4'
import Settings from 'utils/Settings'
import Utils from "utils/Utils"
import ContentImport from './ContentImport'
import useUpload from './useUpload'

const MAX_SIZE_VIDEO = '53687091200' //bytes

const DropzoneS3Uploader = ({
    config,
    onError,
    children,
    isDisabled = false,
    paddingControl,
    withPlaceholder = true,
    className,
    dragDisplay,
    context_id
  }) => {
    const { data: { client_slug }, userData: { region_id, user_id } } = useSelector(state => state.appReducer);
    const { uploadFiles } = useSelector(state => state.uploadReducer)
    const { creatEvaporate } = useUpload()
    const { t } = useTranslation('common')
    const withNoClick = !withPlaceholder
    const getBasePath = () => {
      let path = `${client_slug}/${region_id}/${user_id}/${Settings.ASSET_UPLOAD_SOURCE}`
      if(context_id) {
        path = `${path}/${context_id}`
      }
      return path
    }
    const BASE_PATH_FILE = getBasePath()
    const isAssetUpload = Utils.isOrdinaryAssetUpload(config.entity)
    const getExtension = useCallback((path) => {
        var idx = (~-path.lastIndexOf(".") >>> 0) + 2;
        return path.substr((path.lastIndexOf("/") - idx > -3 ? -1 >>> 0 : idx)).toLowerCase();
    }, [])
    const onDropDropzone = useCallback(acceptedFiles => {
      const getDuplicates = acceptedFiles.filter((i) =>
          uploadFiles.filter(i => !i.finishedSuccessfully).find((f) => f.size === i.size && f.name === i.name
              && f.lastModified === i.lastModified && f.type === i.type)
      );

      if (getDuplicates.length > 0) {
          onError &&
              onError([
                  {
                      files: [],
                      message:
                          t("DAM:Não pode enviar exatamente o mesmo arquivo simultaneamente."),
                  },
              ]);
          return
      }

      if (acceptedFiles.length > config.maxFilesUploads) {
          onError && onError([{
              files: acceptedFiles,
              message: isAssetUpload
              ? t("DAM:Ultrapassou o limite máximo de upload de itens ao mesmo tempo.", {limit: config.maxFilesUploads})
              : t("DAM:Limite de itens atingido. Permitido apenas itens anexados no total.", {limit: 10})
          }]);
          return;
      }

      acceptedFiles = [...acceptedFiles].map(file => {
          file.id = uuid();
          file.path_file = `${BASE_PATH_FILE}/${file.id}/${file.name}`;
          file.updated_at = Utils.getDate("YYYY-MM-DD HH:mm:ss")
          file.client_name = file.name
          file.status_view = 'upload'
          file.entity = config.entity
          file.bucket_name = config.bucket_name
          file.extension = getExtension(file.path)
          if(file.size === 0) {
            onError && onError([{
                files: acceptedFiles,
                message: t('Arquivo para upload vazio.', { fileName: file.name})
            }])
            return 'removed'
          }

          if (Settings.VIDEO_EXTENSIONS.some(i => i === `.${file.extension}`)) {
              if (file.size > MAX_SIZE_VIDEO) {
                  onError && onError([{
                      files: acceptedFiles,
                      message: t('Arquivo para upload acima do limite 30', { fileName: file.name, limit: 20 })
                  }])
                  return 'removed'
              }
          } else {
              if (file.size > config.max_size_file) {
                  onError && onError([{
                      files: acceptedFiles,
                      message: t('O arquivo ultrapassa o limite de GB permitido.', { fileName: file.name, limit: (config.max_size_file / (1024 * 1024 * 1024)) })
                  }])
                  return 'removed'
              }
          }

          return file;
      }).filter(i => i !== 'removed');

      if (acceptedFiles.length > 0) {
          creatEvaporate(acceptedFiles, config)
      }
    }, [onError, BASE_PATH_FILE, uploadFiles, t, creatEvaporate, config, isAssetUpload, getExtension])

    const onDropRejected = useCallback(rejectedFiles => {
        const errors = rejectedFiles.map((error) => {
            const file = error.file;
            let message = "";
            switch (error.errors[0].code) {
                case "file-invalid-type":
                    message = t('Tipo de arquivo não permitido', { fileName: file.name, fileType: file.type })
                    break;
                default:
                    message = t('Erro desconhecido ao subir arquivo', { fileName: file.name })
            }

            return { file, message }
        })

        onError && onError(errors);
    }, [onError, t])

    const { getRootProps, getInputProps, isDragActive } =
        useDropzone({
            onDrop: onDropDropzone,
            onDropRejected,
            noClick: withNoClick,
            accept: config.acceptedExtensions || ""
        });

    const displayArea = () => {
      const params= {};
      if(className) {
        params["className"] = className
      }
      if(withPlaceholder) {
        params["style"] = { width: '100%' }
        return <div {...getRootProps({ onClick: event => event.stopPropagation() })} {...params}>
                  <ContentImport {...getRootProps()} paddingControl={paddingControl} />
                  <input {...getInputProps()} />
                  {children}
              </div>
      }
      return <div {...getRootProps()} {...params}>
              {dragDisplay ? dragDisplay(isDragActive) : <></>}
              <input {...getInputProps()} />
              {children}
            </div>
    }

    const displayDisabled
      = () => <ContentImport {...getRootProps()} isDisabled paddingControl={paddingControl} />


    return isDisabled ? displayDisabled() : displayArea()
}

export default DropzoneS3Uploader;
