import React, { useCallback } from 'react';
import { useSelector } from "react-redux"
import { makeStyles, Grid, Chip, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Settings from 'utils/Settings'
import Utils from 'utils/Utils'
import useContent from './hook/Content'

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 16,
        '& ul': {
            display: 'flex',
            flexWrap: 'wrap',
            listStyle: 'none',
            margin: 0,
            padding: '0 6px'
        }
    },
    chip: {
        margin: '0 4px',
    },
}));

export default function FiltersTags() {
    const { filters_params } = useSelector(state => state.pageBucketReducer)
    const { setParametersFilters } = useContent()
    const classes = useStyles();
    const { t } = useTranslation()
    const isMobile = useMediaQuery('(max-width:600px)')

    const setOptions = useCallback(() => {
        let options = []

        if (filters_params.hasOwnProperty('order') && !_.isEmpty(filters_params.order)) {
            const { order } = filters_params

            if (order.orderField === "created_at") {
                if (order.orderType !== 'desc') {
                    options.push({
                        key: order.orderType,
                        label: 'Mais antigos',
                        type: 'order',
                        value: order.orderType
                    })
                }
            }
            if (order.orderField === "original_name.keyword") {
                options.push({
                    key: order.orderType,
                    label: order.orderType === "asc" ? 'Arquivos de A-Z' : "Arquivos de Z-A",
                    type: 'order',
                    value: order.orderType
                })
            }
        }

        if (filters_params.hasOwnProperty('fileTypes') && filters_params.fileTypes.length > 0) {
            const { fileTypes } = filters_params
            const dataLabel = Settings.DATA_LABELS_FILTER_BUCKET

            fileTypes.forEach(item => {
                const get_label = dataLabel.find(i => i.value === item)

                options.push({ key: item, label: get_label.label, type: 'file-type', value: item })
            })

        }

        if (filters_params.hasOwnProperty('byUpload') && !_.isEmpty(filters_params.byUpload)) {
            const { byUpload } = filters_params
            if (byUpload.user_id) {
                options.push({ key: byUpload.user_id, label: 'Meus uploads', type: 'upload', value: byUpload.user_id })
            }
            if (byUpload.onlyIntegrationErrors) {
                options.push({ key: 'onlyIntegrationErrors', label: 'Integração não processada', type: 'upload', value: true })
            }
        }

        if (filters_params.hasOwnProperty('rangeDate') && !_.isEmpty(filters_params.rangeDate)) {
            const { start, end } = filters_params.rangeDate
            const startLabel = Utils.formatDate(start)
            const endLabel = Utils.formatDate(end)

            if (Boolean(start) && Boolean(end)) {
                options.push({ key: `${startLabel}-${endLabel}`, label: `${startLabel} ate ${endLabel}`, type: 'range-all', value: true })
            }
            if (Boolean(start) && !Boolean(end)) {
                options.push({ key: `${startLabel}`, label: `${startLabel}`, type: 'range-start', value: true })
            }
            if (!Boolean(start) && Boolean(end)) {
                options.push({ key: `${endLabel}`, label: `${endLabel}`, type: 'range-end', value: true })
            }
        }

        return options
    }, [filters_params])


    const handleDelete = useCallback((evt, chipToDelete) => {
        evt.preventDefault()

        if (chipToDelete.type === 'upload') {
            setParametersFilters('byUpload', {})
        }

        if (chipToDelete.type === 'order') {
            setParametersFilters('order', {})
        }

        if (chipToDelete.type === 'file-type') {
            const newData = filters_params.fileTypes.filter(i => i !== chipToDelete.key)
            setParametersFilters('fileTypes', newData)
        }

        if (chipToDelete.type === 'range-all') {
            setParametersFilters('rangeDate', {})
        }
        if (chipToDelete.type === 'range-start') {
            const newDate = { ...filters_params.rangeDate }
            delete newDate.start
            setParametersFilters('rangeDate', newDate)
        }
        if (chipToDelete.type === 'range-end') {
            const newDate = { ...filters_params.rangeDate }
            delete newDate.end
            setParametersFilters('rangeDate', newDate)
        }
    }, [filters_params.fileTypes, filters_params.rangeDate, setParametersFilters])

    const chipData = setOptions()

    return (chipData.length > 0 && !isMobile) && (
        <Grid item xs={12} className={classes.root} data-disableselect={true}>
            <ul>
                {chipData.map((item) => (
                    <li key={item.key}>
                        <Chip
                            label={t(`DAM:${item.label}`)}
                            onDelete={evt => handleDelete(evt, item)}
                            className={classes.chip}
                            color="primary"
                        />
                    </li>
                )
                )}
            </ul>
        </Grid>
    );
}
