import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import connect from "react-redux/es/connect/connect";
import AuthenticationActions from "../../flux/actions/AuthenticationActions";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom'

export const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))

const ExpiredTokenDialog = ({hasInvalidDns, expiredToken, open, handleClose }) => {
  const {t} = useTranslation()
  const history = useHistory()

  if (!hasInvalidDns && !expiredToken) return null
    
  return (
    <Dialog
      keepMounted
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle id="alert-dialog-slide-title">
        {t('common:Sessão Expirada.')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('common:Sua sessão foi expirada. Favor logar novamente para poder prosseguir com suas atividades.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={evt => handleClose(history)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const mapStateToProps = ({appReducer}) => {
  return {
    open: appReducer.dialogs.expiredToken,
    hasInvalidDns: appReducer.hasInvalidDns,
    expiredToken: appReducer.dialogs['expiredToken']
  }
};
const mapDispatchToProps = dispatch => {
  return {
    handleClose: (history) => dispatch(AuthenticationActions.logout('expiredToken', history)),    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiredTokenDialog);
