import ListDefault from "../../Core/List/DataGridCustom"

import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "utils/Flux"
import { withTranslation } from "react-i18next"

import {
    getColumnDelete,
    getColumnEdit,
    getColumnUsage
} from "../../Core/utils"

class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      messageToDelete:
        props.t("manager:Ao excluir uma ferramenta você estará excluindo todo fluxo atrelado a ela. Os dados da ferramenta serão perdidos e ela não aparecerá para nenhum perfil. Você deseja continuar?"),
    }
  }

  getUrl() {
    const { pathClient } = this.props
    return `${pathClient}/${this.state.entity}`
  }

  getLine(item) {
    let line = {
      id: item.id,
      name: item.name,
    }
    return line
  }

  getColumns() {
    const { t } = this.props

    let columns = [
      {
        field: "name",
        headerName: t("common:Nome"),
        flex: 1,
        sortable: false,
      },
    ]

    columns.push(
        getColumnUsage((id) => {
            this.props.history.push(`/manager/advanced-settings/recipes-usage/${id}`)
        }, t)
    )

    columns.push(
      getColumnEdit((id) => {
        this.props.history.push(`/manager/${this.state.route}/edit/${id}`)
      }, t)
    )

    columns.push(
      getColumnDelete((id, name) => {
        this.setState({
          ...this.state,
          deleteDialogOpen: true,
          idToDelete: id,
          nameToDelete: name,
        })
      }, t)
    )

    return columns
  }
}

export default connect(
  mapStateToPropsToSettings,
  null
)(withTranslation(["manager", "common"])(List))
