import React from "react"
import axios from "axios"
import icoRefresh from "./Refresh_icon.svg"
import FileAlert from "../../imagens/icons/file-alert.svg"
import Skeleton from "@material-ui/lab/Skeleton"
import { withStyles } from "@material-ui/styles"
import { mdiWallpaper } from "@mdi/js"
import Icon from "@mdi/react"
import AppActions from "../../flux/actions/AppActions"
import { connect } from "react-redux"
import Settings from "utils/Settings"
import _ from "lodash"

import PhotoRoundedIcon from "../../imagens/ui/photo-white-24dp.svg"

class YapoliImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.asset,
      type: props.type,
      file: props.file,
      loading: true,
      data: "",
      lazyLoad: props.lazyLoad || false,
      permission: true,
      error: false,
      reload: false,
      retries: 0,
      borderRadius: props.borderRadius || 4,
    }
    this._isMounted = false

    if (props.onClick) {
      this.onClick = props.onClick.bind(this)
    }
  }

  componentDidUpdate(prevProps) {
    const { reload, retries } = this.state

    if (reload) {
      this.setState(
        { ...this.state, retries: retries + 1, loading: true, reload: false },
        () => this.getData()
      )
    } else {
      if (prevProps.file !== this.props.file) {
        this.setState(
          { ...this.state, loading: true, file: this.props.file },
          () => this.getData()
        )
      }
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { lazyLoad } = this.state

    if (!lazyLoad) {
      this.getData()
    } else {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const { isIntersecting } = entry

            if (isIntersecting) {
              this.getData()
              this.observer = this.observer.disconnect()
            }
          })
        },
        {
          root: null,
          rootMargin: "0px 0px 0px 0px",
        }
      )

      this.observer.observe(this.element)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setError(error) {
    const SELF = this
    const { retries } = SELF.state

    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message)

      SELF.setState({
        ...this.state,
        loading: false,
        permission: false,
        error: true,
      })
    } else {
      if (retries >= 2) {
        SELF._isMounted &&
          SELF.setState({
            ...SELF.state,
            file: FileAlert,
            data: FileAlert,
            loading: false,
            reload: false,
            retries: retries + 1
          })
        return
      }
      SELF._isMounted && SELF.setState({ ...SELF.state, loading: true, reload: true })
    }
  }

  getFileFromUrl = (file) => {
    let parts = file.split("/").slice(-3)
    parts[parts.length - 1] = parts[parts.length - 1]
      .split("?")[0]
      .split("#")[0]
    return parts.join("/")
  }

  handleError = (e) => {
    const { file } = this.state
    const SELF = this
    e.target.onerror = null

    if (file.startsWith("https://")) {
      this.setState(
        {
          ...this.state,
          reload: true,
          file: this.getFileFromUrl(e.target.src),
        },
        () => SELF.setError(e)
      )
    } else {
      SELF.setError(e)
    }
  }

  getData() {
    const SELF = this
    const { enqueueRequest } = SELF.props
    let { file } = this.state

    if (!file) {
      SELF._isMounted &&
        SELF.setState({
          ...SELF.state,
          loading: false,
          error: false,
          reload: false,
          file: null,
          lazyLoad: false,
        })

      return
    }

    if (file.startsWith("https://")) {
      const image = new Image()
      image.onload = () =>
        SELF._isMounted &&
        SELF.setState({
          ...SELF.state,
          data: file,
          loading: false,
          error: false,
          reload: false,
        })
      image.onerror = (err) => this.handleError(err)
      image.src = file

      return
    }

    const payload = {file: file}
    enqueueRequest(`${Settings.URL_API}/api/url`, payload)
      .then(
        (resp) => {
          SELF._isMounted &&
            SELF.setState({
              ...SELF.state,
              data: _.get(resp, "data", resp),
              loading: false,
              error: false,
              reload: false,
            })
        }
      )
      .catch((err) => this.setError(err))
  }

  render() {
    const getImgSrc = (data, loading, error, icoRefresh) => {
      if (data !== "" && !loading) {
        return data
      }

      return null
    }
    const { data, error, loading, file, borderRadius } = this.state
    const { width, height, classes } = this.props
    const imgsrc = getImgSrc(data, loading, error, icoRefresh)

    if (!imgsrc && file) {
      return [
        <div
          ref={(el) => (this.element = el)}
          key={`divLoader-${this.props.alt}`}
          className={!width ? classes.skeletonContainer : ""}
        >
          <Skeleton
            variant="rect"
            className={classes.skeleton}
            height={height}
            width={width}
            style={{
              width: `${width}`,
              height: `${height}`,
              borderRadius: borderRadius,
              margin: 0
            }}
          />
        </div>,
      ]
    }

    if (!file) {
      let card = (
        <div
          key={`cardMedia-${this.props.alt}`}
          style={{
            width: "250px",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            path={mdiWallpaper}
            size={7.6}
            ref={(el) => (this.element = el)}
            color="#F0F0F0"
          />
        </div>
      )

      return [card]
    }

    return [
      <div
        key={`cardMedia-${this.props.alt}`}
        alt={this.props.alt}
        ref={(el) => (this.element = el)}
        height={height}
        width={width}
        title={this.props.alt}
        style={{
          width: `${width}`,
          height: `${height}`,
          borderRadius: borderRadius,
          backgroundImage: `url(${imgsrc})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundColor: '#fff'}}
      />,
    ]
  }
}

const mapStateToProps = ({ appReducer }) => {
  return {
    requestHeaders: appReducer.requestHeaders
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    enqueueRequest: (url, payload) =>
      dispatch(AppActions.enqueueRequest({ url, payload, type: "simple" })),
  }
}

const styles = (theme) => ({
  skeletonContainer: {
    height: 0,
    overflow: "hidden",
    paddingTop: "100%",
    position: "relative",
  },
  skeleton: {
    backgroundImage: `url(${PhotoRoundedIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "50%",
    backgroundColor: "#C0C0C0",    
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(YapoliImage))
