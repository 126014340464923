import React, { useState, useCallback } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import axios from 'axios'
import { useSnackbar } from "notistack"

import {
  CircularProgress,
  Button,
  Fade,
  Typography,
  Chip,
  TextField,
  useMediaQuery,
  Box
} from "@material-ui/core"

import ChipInput from "material-ui-chip-input"

import { useStyles } from './styles'
import Settings from 'utils/Settings'
import Utils from "utils/Utils"

const FormSendEmail = ({ disabled, versionIndex, handleStepBack, data, shareExpirationTime = "24", typeListrecipe = '', includeMetadata = false }) => {
  const [state, setState] = useState({
    recipients: [],
    description: "",
    emailValid: true,
  })
  const { requestHeaders, pathServer } = useSelector((state) => state.appReducer)
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMediaQuery('(max-width:600px)')

  const validateEmail = useCallback((value) => {
    return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || value === ""
  }, [])

  const HelperText = useCallback(({ emailValid, recipients }) => {
    if (!emailValid) {
      if (recipients.length === 0) {
        return t("DAM:Informe ao menos um e-mail")
      }

      return t("DAM:Há e-mail(s) com erro")
    }
    return isMobile ? '' : t("DAM:Pressione <Enter> para inserir mais de um e-mail")
  }, [isMobile, t])

  const handleUserInput = useCallback((e) => {
    const name = e.target.name
    const value = e.target.value
    setState(prev => ({ ...prev, [name]: value, }))
  }, [])

  const handleRenderChip = useCallback(({ value, handleClick, handleDelete }, key) => {
    return (
      <Chip
        key={key}
        color={validateEmail(value) ? "primary" : 'secondary'}
        label={value}
        onClick={handleClick}
        onDelete={handleDelete}
        style={{ margin: '0 2.5px' }}
      />
    )
  }, [validateEmail])

  const handleAddChip = useCallback((...chips) => {
    chips = chips.map(Function.prototype.call, String.prototype.trim)

    let recipients = [...state.recipients, ...chips].filter(
      (item, index, array) => array.indexOf(item) === index
    )
    let emailValid = state.emailValid

    if (state.emailValid) {
      emailValid = chips.filter((r) => !validateEmail(r)).length === 0
    } else {
      emailValid = recipients.filter((r) => !validateEmail(r)).length === 0
    }
    setState(prev => ({ ...prev, recipients, emailValid }))
  }, [validateEmail, state.recipients, state.emailValid])

  const handleDeleteChip = useCallback((chip) => {
    let recipients = state.recipients.filter((r) => r !== chip)
    let emailValid = state.emailValid
    if (!state.emailValid) {
      emailValid = recipients.filter((r) => !validateEmail(r)).length === 0
    }
    setState(prev => ({ ...prev, recipients, emailValid }))
  }, [validateEmail, state.recipients, state.emailValid])

  const getParameters = useCallback((customParams) => {
    const payloadData = {
      recipients: state.recipients,
      description: state.description,
      version: versionIndex,
      ...(Boolean(data.alternativeNameId) && { alternativeNameId: data.alternativeNameId }),
      expiration: parseInt(shareExpirationTime),
      ...(Boolean(typeListrecipe) && { type: typeListrecipe }),
      ...(Boolean(includeMetadata) && { include_metadata: true }),
      ...customParams
    }
    return payloadData
  }, [state.description, state.recipients, versionIndex, shareExpirationTime, data.alternativeNameId, typeListrecipe, includeMetadata])

  const sendEmail = useCallback((evt, customParams = {}) => {
    if (!state.emailValid || state.recipients.length <= 0) {
      setState(prev => ({ ...prev, emailValid: false }))
      return false
    }

    const payloadData = getParameters(customParams)
    const url = data.type === 'asset'
      ? `${pathServer}/asset/${data.id}/${data.id_asset}/${versionIndex}/${typeListrecipe}/share`
      : `${pathServer}/item/${data.id}/share`
    setState(prev => ({ ...prev, loading: true }),
      axios
        .post(url, payloadData, requestHeaders)
        .then((resp) => {
          const msg = data.type === 'asset' ? t("DAM:Arquivo compartilhado com sucesso") : t("DAM:Item compartilhado com sucesso")
          setState(prev => ({ ...prev, recipients: [], description: "", loading: false }),
            enqueueSnackbar(msg, {
              ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
            })
          )
        })
        .catch((error) => {
          const message = Utils.ajaxErrorGetMessage(error, t, t("manager:Erro inesperado. Contate o suporte"))
          setState(prev => ({ ...prev, loading: false }),
            enqueueSnackbar(message, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            })
          )
        })
    )
  }, [
    t,
    enqueueSnackbar,
    pathServer,
    requestHeaders,
    data.id,
    state.emailValid,
    state.recipients,
    getParameters,
    data.id_asset,
    data.type,
    versionIndex,
    typeListrecipe
  ])

  return (
    <Box style={{ padding: 10 }}>
      <Typography
        variant="body2"
        style={{ color: "#666" }}
      >
        {t(`common:Para compartilhar com pessoas externas, insira abaixo o e-mail dos destinatários`)}
      </Typography>
      <form action="" className={classes.form}>
        <ChipInput
          label={t('common:Insira os e-mails')}
          variant='outlined'
          value={state.recipients}
          disabled={disabled}
          chipRenderer={(
            { value, handleClick, handleDelete },
            key
          ) =>
            handleRenderChip(
              { value, handleClick, handleDelete },
              key
            )
          }
          onAdd={handleAddChip}
          onDelete={handleDeleteChip}
          blurBehavior="add"
          classes={{
            inputRoot: classes.chipInputRoot
          }}
          onPaste={(event) => {
            const clipboardText = event.clipboardData.getData("Text")
            const regex = /([,(\r\n|\r|\n);])/

            event.preventDefault()

            handleAddChip(
              ...clipboardText
                .split(regex)
                .filter((t) => t.replace(regex, "").trim().length > 0)
            )
          }}
          fullWidth={true}
          disableUnderline={true}
          error={!state.emailValid}
          FormHelperTextProps={{
            classes: {
              root: classes.helper,
            },
          }}
          style={{ marginTop: 20 }}
        />

        <span className={state.emailValid ? classes.helper : classes.error}>
          <HelperText emailValid={state.emailValid} recipients={state.recipients} />
        </span>

        <TextField
          disabled={disabled}
          style={{
            width: "100%",
            margin: "20px 0 20px 0",
            backgroundColor: "#fff",
          }}
          id="share-description"
          name="description"
          label={t("DAM:Escreva uma mensagem para o destinatário")}
          multiline
          rows={4}
          variant="outlined"
          onChange={handleUserInput}
          value={state.description}
        />
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            style={{ marginRight: 9 }}
            variant="outlined"
            color="primary"
            onClick={handleStepBack}
          >
            {t("common:Voltar")}
          </Button>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            disabled={state.loading || disabled}
            onClick={sendEmail}
          >
            {state.loading ? (
              <Fade
                in={state.loading}
                style={{
                  transitionDelay: state.loading ? "800ms" : "0ms",
                }}
                unmountOnExit
              >
                <CircularProgress color="inherit" size={20} />
              </Fade>
            ) : (
              t("DAM:Compartilhar item")
            )}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default FormSendEmail
