import React from "react";
import axios from "axios/index";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Template from "../Template";
import Loading from "components/Loading";

import connect from "react-redux/es/connect/connect";
import {mapDispatchToPropsToSettings, mapStateToPropsToSettings} from "utils/Flux";
import { withTranslation } from 'react-i18next';
import DialogDeleteValidation from 'components/Dialogs/DeleteValidationPhrase'

import { 
  Grid, 
  Button, 
  TextField,
  Typography,
  Chip,
  Avatar
 } from "@material-ui/core";

import { WrapperPaperForms } from 'components/WrapperForms'
import UserComboBox from "components/ComboBox/UserComboBox"

import _ from 'lodash'
class Edit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",  
      domain: "",
      recipients: [],
      loading: true,
      formErrors: {},
      formValid: true,
      id: "",
      nameToDelete: "",
    };
    this.formSubmit = this.formSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  componentDidMount() {
    const ID = this.props.match.params.id;
    this.setState({ ...this.state, id: ID, });
    if (ID) this.getData(ID);
  }

  getData(id) {
    const SELF = this;
    const {requestHeaders, pathServer, triggerError} = SELF.props;
    axios.get(`${pathServer}/region/${id}`, requestHeaders)
      .then(function (resp) {
        const DATA = resp.data;
        
        SELF.setState({
          ...SELF.state,
          loading: false,
          name: DATA.name,
          domain: DATA.domain,
          recipients: DATA.recipients || [],        
        });
      }, function (error) {
        SELF.setState({...SELF.state, loading: false});
        toast.error(error.response.headers['x-message']);
      })
      .catch(err => triggerError(err));
  }

 formSubmit(e) {
    e.preventDefault();
    const SELF = this;
    const {requestHeaders, pathServer, triggerError} = SELF.props;
    
    let data = {
      "domain": SELF.state.domain,
      "language": SELF.state.language,
    };

    const formValid = this.validateField(data)
    
    if (!formValid) {     
      return false;
    }

    if(SELF.state.recipients.length > 0) {
      data['recipients'] = SELF.state.recipients.map(i => i.id)
    }    
  SELF.setState({ ...SELF.state, loading: true });

   axios.put(`${pathServer}/region/${SELF.state.id}`, data, requestHeaders)
     .then(resp => {
       if (SELF.state.id === SELF.props.region_id) {
         SELF.props.setHasSupportCurrentRegion(data.hasOwnProperty('recipients'))
       }

       toast.success(
         resp.headers['x-message'],
         {
           autoClose: 1500,
           onClose: () => {
             SELF.props.history.push("/manager/regions/list");
           }, pauseOnHover: false
         });
     }, error => {
       SELF.setState({ ...SELF.state, loading: false });
       toast.error(error.response.headers['x-message']);
     })
     .catch(err => triggerError(err));
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  };

  validateField(parameters) {
    const {t} = this.props;
    let fieldValidationErrors = {};
    
    Object.entries(parameters).forEach(([key, value]) => {
      switch (key) {
        case 'name':
          const nameValid = value.length >= 3;
          fieldValidationErrors.name = nameValid ? '' : '* ' + t('common:Este campo deve conter mais que 3 caracteres');
          break;
        case 'domain':
          // eslint-disable-next-line
          let domainValid = value.match(/^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/);
          fieldValidationErrors.domain = domainValid ? '' : '* ' + t('manager:Domínio inválido');
          break;        
        default:
          // 
          break;
      }
    })

    const formErrors = {
      ...this.state.formErrors,
      ...fieldValidationErrors
    }

    const getFormValid = (() => {
      let formValid = true

      for (var value in formErrors){      
        if(formErrors[value] && formErrors[value].trim().length > 0) {
          formValid = false
        }
      }
      return formValid
    })()    

    this.setState({
      ...this.state,
      formErrors,     
      formValid: getFormValid,
    });

    return getFormValid
  }

  errorClass(error) {
    return (error.length === 0 ? 'form-control' : 'form-control has-error');
  }

  handleChangeComboBox = (attribute, selectedOption) => {  
    let newState = {...this.state};
    newState[attribute] = selectedOption.label;
    this.setState(newState);
  };

  handleDelete = () => {
    const SELF = this;
    const {requestHeaders, pathServer, triggerError} = SELF.props;
    SELF.setState({ ...SELF.state, loading: true });
    axios.delete(pathServer + `/region/${SELF.state.id}`, requestHeaders)
      .then(function (resp) {
        SELF.setState({
          ...SELF.state,
          loading: false,
        }, () => SELF.props.history.push("/manager/regions/list"));
        toast.success(resp.headers['x-message']);
      }, function (resp) {
        SELF.setState({
          ...SELF.state,
          loading: false,
        });
        toast.error(resp.headers['x-message']);
      })
      .catch(err => triggerError(err));
  };

  handleAddRecipients(data) {
    this.setState(prev => ({
      ...prev, 'recipients': [
        ...prev.recipients,
        { id: data.value, name: data.label }
      ]
    }))
  }

  handleRemoveRecipients(id) {
    this.setState(prev => ({
      ...prev, 'recipients': prev.recipients.filter(i => i.id !== id)
    }))
  }

  render() {
    const SELF = this;
    const {t} = SELF.props;

    const RECIPIENTS_IDS = this.state.recipients.map(i => i.id)
    
    return (
      <Template area={0} localHistory={this.props.history} customClassName={"container-center"}>
        <Loading showLoading={this.state.loading}/>

        <WrapperPaperForms title={t("common:Edição de região")}>
        <Grid item xs={12}>
            <TextField
              name="name"
              label={`${t("Nome da região")}*`}
              variant="outlined"
              value={SELF.state.name}
              disabled
              error={Boolean(this.state.formErrors.name)}
              helperText={this.state.formErrors.name ? this.state.formErrors.name : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="domain"
              label={`${t("Domínio padrão")}*`}
              variant="outlined"
              value={SELF.state.domain}
              onChange={SELF.handleUserInput}
              error={Boolean(this.state.formErrors.domain)}
              helperText={this.state.formErrors.domain ? this.state.formErrors.domain : ''}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='body2' style={{ marginBottom: 20 }}>
              {t("manager:Para dúvidas sobre o conteúdo cadastrado nessa região, quem deve receber o e-mail de suporte?")}
            </Typography>
            <UserComboBox
              title='E-mail para suporte de conteúdo'
              disabledItems={RECIPIENTS_IDS}
              value={""}
              handleChange={(selectedItem) =>
                this.handleAddRecipients(selectedItem)
              }
              error={this.state.formErrors.recipients}
            />
          </Grid>

          {_.get(this.state, "recipients", []).length > 0 && (
            <Grid
              item
              xs={12}
              style={{
                border: "1px solid #C4C4C4",
                borderRadius: 4,
                padding: 10,
                marginBottom: 20
              }}
            >              
              {_.get(this.state, "recipients", []).map((item, key) => (
                <Chip
                  key={key}
                  label={item.name}
                  onDelete={(evt) => this.handleRemoveRecipients(item.id)}
                  avatar={<Avatar>{_.get(item, "name.0", "")}</Avatar>}
                  style={{ margin: 5 }}
                />
              ))}
            </Grid>
          )}             
       
          <div className='paper-actions'>
                <DialogDeleteValidation entity={t("common:Região")} name={this.state.name}>
                  {(confirm) => (
                       <Button
                          style={{
                            height: 40,
                            width: 115,
                            marginRight: 20,
                            color: '#F4511E',
                            border: '1px solid #F4511E'
                          }}
                          variant="outlined"

                          onClick={confirm(SELF.handleDelete)}
                        >
                       {t("common:Excluir")}
                     </Button>
                  )}
                </DialogDeleteValidation>

                <Button
                  style={{height: 40, width: 115,  marginRight: 20}}
                  variant="outlined"
                  color="primary"
                  onClick={() => this.props.history.push("/manager/regions/list")}
                >
                  {t("common:Voltar")}
                </Button>
                <Button
                  style={{height: 40, width: 115,}}
                  variant="contained"
                  color="primary"
                  onClick={this.formSubmit}
                  disabled={this.state.loading}
                >
                  {t("common:Salvar")}
                </Button>
            </div>
        </WrapperPaperForms>       
      </Template>
    );
  }
}

export default connect(mapStateToPropsToSettings, mapDispatchToPropsToSettings)(withTranslation(['manager', 'common'])(Edit));
