import React from "react";
import axios from "axios/index";
import Dropzone from 'react-dropzone'
import Template from "../Template";
import imgUpload from "imagens/ui/ico-upload.svg";
import ErrorBoundary from "components/Core/ErrorBoundary";
import _ from 'lodash'
import {
    Grid,
    Typography,
    Button,
    CircularProgress,   
    Divider
} from "@material-ui/core";

import { Content } from '../Clients/partials/Content'
import UploadDialog from './UploadDialog'
import ImageLazyLoad from "components/ImageLazyLoad"

import Settings from 'utils/Settings'

const getHeaders = () => ({
    headers: {
        "Authorization": localStorage.getItem("user_token"),
        "device": "desktop"
    }
});

export default class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            region_slug: "",
            picturesPreview: null,
            loading: true,
            data: {},
        };
        this.handleRemovePicture = this.handleRemovePicture.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
    }

    componentDidMount() {
        const ID = this.props.match.params.id;
        this.setState({ ...this.state, slug: ID });
        if (ID) this.getData(ID);
    }

    getUrl(path) {
        const pathClient = localStorage.getItem("path")
        return `${pathClient}/${path}`
    }

    getData(id) {
        const SELF = this;
        const { t, enqueueSnackbar } = SELF.props;
        const path = this.getUrl(`${this.state.entity}/${id ? id : this.state.id}`);
        axios.get(path, getHeaders())
            .then(function (resp) {
                const DATA = _.get(resp, 'data.item', _.get(resp, 'data', []))

                let picturesPreview = ''

                if (DATA.thumbnail && DATA.thumbnail.includes('https')) {
                    picturesPreview = DATA.thumbnail
                } else {
                    picturesPreview = DATA.urls.thumbnail.original;
                }

                SELF.setState({
                    ...SELF.state,
                    id,
                    loading: false,
                    name: DATA.name,
                    picturesPreview
                });
            }, function (resp) {               
                SELF.setState({ ...SELF.state, loading: false });                
                enqueueSnackbar(resp.headers['x-message'], {
                    variant: 'error',
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  })
            })
            .catch(function (err) {               
                console.log(err);               
               enqueueSnackbar(t('Erro ao carregar os dados, contate o suporte'), {
                    variant: 'error',
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  })
            });
    }

    onDropUpload(files) {
        let picturesPreview = '';
        const SELF = this;
        const { enqueueSnackbar, t} = SELF.props

        if(files.length <= 0) {
            return      
        }
        
        const uploaders = files.map(file => {
            const formData = new FormData();
            formData.append("thumbnail", file);
            SELF.setState({ ...SELF.state, loading: true });

            const url = this.getUrl(`${SELF.state.entity}/import/${SELF.state.id}`);
            return axios.post(url, formData,
                {
                    headers: (getHeaders())["headers"],
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        console.log("percentCompleted", percentCompleted);
                    }
                }).then(resp => {
                    picturesPreview = resp.data.thumbnail + "?=timestamp=" + Math.floor(Date.now() / 1000);
                  
                    enqueueSnackbar(resp.headers['x-message'], {
                        ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                    });
                }, () => {
                    enqueueSnackbar(t('Erro ao carregar os dados, contate o suporte'), {
                        variant: 'error',
                        autoHideDuration: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                      })     
                })
        });

        axios.all(uploaders).then(() => {
            this.setState({
                ...this.state,
                loading: false,
                picturesPreview
            }, () => {
                console.log(this.state.picturesPreview)
            });
        });
    }

    onDropRejected() {
        const { t, enqueueSnackbar } = this.props;       
        enqueueSnackbar(t('Arquivo inválido. Arquivo até 2MB em JPG ou PNG'), {
            variant: 'error',
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          })
    }

    handleRemovePicture(event) {
        event.preventDefault();
        const SELF = this;
        const { t, enqueueSnackbar } = SELF.props;

        this.setState({ ...this.state, loading: true });
        const url = this.getUrl(`${SELF.state.entity}/remove/${SELF.state.id}/thumbnail`);
        axios.post(url, {}, getHeaders())
            .then(function (resp) {
                SELF.setState({
                    ...SELF.state,
                    loading: false,
                    picturesPreview: '',
                });              
                enqueueSnackbar(t('Miniatura deletada com sucesso.'), {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                  });
            }, function (resp) {
                SELF.setState({ ...SELF.state, loading: false, });            
                enqueueSnackbar(resp.headers['x-message'], {
                    variant: 'error',
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  })
            })
            .catch(function (err) {
                enqueueSnackbar(t("Erro ao deletar, contate o administrador."), {
                    variant: 'error',
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  })              
            });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <Template area={this.state.area} localHistory={this.props.history} customClassName={"container-center"}>
                <ErrorBoundary>
                    <Content title={"manager:Adicionar miniatura"}>
                        <Divider style={{ marginBottom: 20 }} />
                        <Typography variant='body2' style={{ marginBottom: 0 }}>
                            {t("manager:Selecione a miniatura correspondente ao idioma desejado.")}
                        </Typography>
                        <Typography variant='body2' style={{ marginBottom: 20 }}>
                            {`${t('common:Especificações')}: PNG, JPG; 600px x 600px; ${t('common:tamanho máximo')} 2MB.`}
                        </Typography>
                                          
                                <Dropzone
                                    onDrop={(files) => this.onDropUpload(files)}
                                    accept="image/jpeg, image/png"
                                    maxSize={2000000}
                                    onDropRejected={this.onDropRejected}
                                    multiple={false}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className={classes.rootDropzone}>
                                            <div className='content'>
                                                <input {...getInputProps()} />
                                                {this.state.loading ? (
                                                    <div>
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <>                                                    
                                                        {
                                                            this.state.picturesPreview ? (
                                                                <div className='image'>
                                                                    <ImageLazyLoad
                                                                        images={{
                                                                            imgJPG: this.state.picturesPreview, 
                                                                            imgWEBP: this.state.picturesPreview
                                                                          }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <img
                                                                        src={imgUpload}
                                                                        width={50}
                                                                        alt={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                                    />
                                                                    <br />
                                                                    <Typography variant="body2">
                                                                        {t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                                    </Typography>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                    )}
                                </Dropzone>

                                <Grid item xs={12} style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    margin: '20px 0',
                                }}>
                                    {
                                        this.state.picturesPreview &&
                                        <>
                                            <Button
                                                style={{ height: 40, width: 115, marginRight: 20 }}
                                                variant="outlined"
                                                color="primary"
                                                disabled={!this.state.picturesPreview}
                                                onClick={(evt) => this.handleRemovePicture(evt)}
                                            >
                                                {t("common:Excluir")}
                                            </Button>
                                            <UploadDialog
                                                t={t}
                                                title={t("manager:Adicionar miniatura")}
                                                descriptionUpload={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                onDropUpload={this.onDropUpload}
                                                onDropRejected={this.onDropRejected}
                                                loading={this.state.loading}
                                                acceptFiles="image/jpeg, image/png"
                                            >
                                                {(confirm) => (
                                                    <Button
                                                        variant={"contained"}
                                                        color="primary"
                                                        style={{ height: 40 }}
                                                        onClick={confirm()}
                                                    >
                                                        {t("manager:Adicionar miniatura")}
                                                    </Button>
                                                )}
                                            </UploadDialog>
                                        </>
                                    }
                                </Grid>
                            


                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ height: 40, width: 115 }}
                                variant="contained"
                                color="primary"
                                onClick={() => this.props.history.goBack()}>
                                {t("common:Voltar")}
                            </Button>
                        </div>

                    </Content>
                </ErrorBoundary>
            </Template>
        );
    }
}
