import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import i18n from "i18next"
import {
    Button,
    Menu,
    Typography
} from '@material-ui/core'
import moment from "moment"

import * as locales from 'react-date-range/dist/locale'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';

import { makeStyles } from "@material-ui/core/styles"

import { useDataRequestTools } from "./context"

import Utils from 'utils/Utils'

export const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        '& button#submit': {
            marginLeft: 25
        }
    },
    icon: {
        display: 'flex',
        marginLeft: 5,
        cursor: 'pointer',
        '& svg': {
            padding: 2
        },
        '& :hover': {
            backgroundColor: '#eee',
            borderRadius: 4
        }
    },
    wrapper: {
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        }
    }
}))

export default function SimpleMenu() {
    const [anchorEl, setAnchorEl] = useState(false)
    const classes = useStyles()
    const { t } = useTranslation()
    const { primaryColor } = useSelector((state) => state.appReducer.data)
    const { setDate, hasDate, date, errorValidDate, setErrorValidDate } = useDataRequestTools()
    const [startDate, setStartDate] = useState(date.startDate)
    const [endDate, setEndDate] = useState(date.endDate)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleClear = () => {
        handleClose()
        setDate({
            startDate: null,
            endDate: null,
        })
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        const isValidStart = moment(startDate)
        const isValidEnd = moment(endDate)

        let isValid = true

        if (isValidStart.isAfter(isValidEnd, 'day')) {
            setErrorValidDate(t('common:Data inicial maior que a data final'))
            isValid = false
        }

        if (!isValidStart.isValid() && !isValidEnd.isValid()) {
            setErrorValidDate(t('common:Selecione uma data ou um período.'))
            isValid = false
        }

        if (isValid) {
            setErrorValidDate('')
            setDate({ startDate, endDate })
            handleClose()
        }
    }

    return (
        <div>
            <div onClick={handleClick} className={classes.icon}>
                {hasDate ? <CalendarTodayRoundedIcon color='primary' /> : <CalendarTodayRoundedIcon />}
            </div>

            {
                anchorEl && (
                    <Menu
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        keepMounted
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <div className={classes.wrapper}>
                            <div>
                                <div style={{ display: 'flex', padding: '10px 0 0 10px', color: '#1d2429' }}>
                                    <Typography variant="body1" style={{ marginRight: 10 }}>
                                        {t('common:Data inicial')}:
                                    </Typography>

                                    <Typography variant="body1">
                                        {startDate ? Utils.formatDate(startDate) : t('common:Nenhuma data selecionada')}
                                    </Typography>

                                </div>
                                <Calendar
                                    onChange={item => setStartDate(item)}
                                    locale={locales[i18n.language]}
                                    date={startDate}
                                    color={primaryColor}
                                    style={{ borderRight: '2px solid #eee' }}
                                />
                            </div>

                            <div>
                                <div style={{ display: 'flex', padding: '10px 0 0 10px', color: '#1d2429' }}>
                                    <Typography variant="body1" style={{ marginRight: 10 }}>
                                        {t('common:Data final')}:
                                    </Typography>

                                    <Typography variant="body1">
                                        {endDate ? Utils.formatDate(endDate) : t('common:Nenhuma data selecionada')}
                                    </Typography>
                                </div>
                                <Calendar
                                    onChange={item => setEndDate(item)}
                                    locale={locales[i18n.language]}
                                    date={endDate}
                                    color={primaryColor}
                                />
                            </div>
                        </div>
                        <div className={classes.actions}>
                            <div>
                                {
                                    Boolean(errorValidDate) &&
                                    <Typography variant="body1" style={{ color: '#f44336' }}>
                                        * {errorValidDate}
                                    </Typography>

                                }
                            </div>
                            <div>
                                <Button color='primary' onClick={handleClear} disabled={Boolean(errorValidDate)}>
                                    {t('DAM:Limpar')}
                                </Button>
                                <Button color='primary' onClick={handleSubmit} id='submit'>
                                    {t('common:Enviar')}
                                </Button>
                            </div>
                        </div>
                    </Menu>
                )
            }

        </div>
    );
}