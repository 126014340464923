import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
    root: {
        '& #header-root-page': {
            position: 'fixed',
            zIndex: '2 !important'
        },
        "& #barActionsItens": {
            width: '100%',
        }
    },
    container: {
        width: '100%',
        height: 'calc(100vh - 128px)',
        padding: '20px',
        marginTop: 128,
        '& h2': {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '2em',
            padding: '5px 50px',
            marginBottom: 10,
            color: '#757575',
        },
    },
    items: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        listStyle: 'none',
        padding: 0,
        margin: 0,
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center',
        }
    }
}))