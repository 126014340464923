import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import {
  Toolbar,
  Typography,
  Tooltip,
  Badge,
  IconButton,
  useMediaQuery
} from '@material-ui/core';

import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded'

import MenuSelected from './MenuSelected';
import MenuSorting from './MenuSorting';
import FilterItems from './FilterItems';
import MarkedItems from './MarkedItems';
import SetModeView from './SetModeView';
import SetListVisualization from './SetListVisualization';
import MenuViewItems from './MenuViewItems'
import MenuAllDownloads from './MenuAllDownloads'
import MenuAllShared from './MenuAllShared'
import ButtonCreateItems from './ButtonCreateItem'
import BulkDownloadInfosButton from './BulkDownloadInfosButton'
import AssetsItensFilesDialog from './MarkedIAssets/AssetsItensFilesDialog'
import { useStyles } from "./styles";

import { Creators as AssetActions } from "flux/ducks/Asset"
import AppActions from "flux/actions/AppActions"

const ItensFilterBar = ({ data, totals, hideInfoItems, isPageItens }) => {
  const { markedItems, userData: { policies } } = useSelector((state) => state.appReducer)
  const assetReducer = useSelector(state => state.assetReducer);
  const [openMarkedIAssets, setOpenMarkedIAssets] = useState(Boolean(assetReducer.pathname))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles({ heightMobile: markedItems.length > 0 })

  const isMobile = useMediaQuery('(max-width:600px)')

  const handleClickOpen = useCallback(() => {
    setOpenMarkedIAssets(true, dispatch(AppActions.markListAssets([])));
  }, [dispatch])

  const handleClose = useCallback(() => {
    const callback = () => {
      dispatch(AssetActions.clearPathname())
      dispatch(AppActions.markListAssets([]))
    }
    setOpenMarkedIAssets(false, callback())
  }, [dispatch])

  return (
    <>
      <div className={classes.rootBar}>
        <Toolbar>
          <div className={classes.barFilter}>

            <SetModeView />

            {
              Boolean(data.title) && (
                <Typography variant='body2' id='title'>
                  {t(`common:${data.title}`)}
                </Typography>
              )
            }

            {
              isPageItens && (
                <>
                  <SetListVisualization />

                  <div id="line-divider" />

                  <MenuSelected />

                  <MenuSorting />

                  <div id="line-divider" />

                  <FilterItems />

                  <MenuAllDownloads
                    totalResultItems={totals}
                    totalMarkedItems={markedItems.length || 0}
                  />
                  <MenuAllShared
                    totalResultItems={totals}
                    totalMarkedItems={markedItems.length || 0}
                  />
                </>
              )
            }
          </div>
          <div className={classes.barActionsItens} id='barActionsItens'>
            {isPageItens && <BulkDownloadInfosButton />}
            {
              !isMobile &&
              <ButtonCreateItems />
            }

            {_.get(assetReducer, 'data', []).length > 0 && (
              <>
                <Tooltip title={t("DAM:Ativos selecionados")} placement="top">
                  <IconButton onClick={() => handleClickOpen()} style={{ marginRight: 10 }}>
                    <Badge badgeContent={_.get(assetReducer, 'data', []).length} color="primary">
                      <BookmarkRoundedIcon color="primary" />
                    </Badge>
                  </IconButton>
                </Tooltip>
                {
                  markedItems.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div id="line-divider" style={{ margin: '0 15px' }} />
                    </div>
                  )
                }
              </>
            )}

            {
              markedItems.length > 0 &&
              <MarkedItems
                checkedValue={data.checkedValue}
                defaultOpen={data.defaultOpen}
                isGoback={data.isGoback}
              />
            }

            {
              !hideInfoItems &&
              <MenuViewItems
                totalResultItems={totals}
                totalMarkedItems={markedItems.length || 0}
                isPageItens={isPageItens}
              />
            }
          </div>
        </Toolbar>
      </div>
      {
        openMarkedIAssets && <AssetsItensFilesDialog
          open={openMarkedIAssets}
          handleClose={handleClose}
          policies={policies}
        />
      }
    </>
  )
}

ItensFilterBar.defaultProps = {
  totals: 0,
  isPageItens: false,
  data: {
    checkedValue: false,
    defaultOpen: '',
    isGoback: false,
    title: '',
  },
}

ItensFilterBar.propTypes = {
  data: PropTypes.shape({
    checkedValue: PropTypes.bool,
    defaultOpen: PropTypes.string,
    isGoback: PropTypes.bool,
    title: PropTypes.string,
  })
}


export default ItensFilterBar
