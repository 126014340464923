import React from "react";
import icoSuccess from "../../imagens/ui/check-white.svg";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'

import {
  Typography,
  Button
} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: '#666',
    '& div': {
      margin: '20px 0',
      "& img": {
        backgroundColor: theme.palette.primary.main,
        width: 50,
        borderRadius: '50%',
        padding: 8,
      }
    }
  }
}));

const Success = ({ invitationDesc = false }) => {
  const classes = useStyles();
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <div>
        <img src={icoSuccess} alt="Sucesso" />
      </div>

      {
        !invitationDesc ?
          (
            <>
              <Typography variant='body1' style={{ marginBottom: 20 }}>
                {t("solicitacaoAcesso:Solicitação enviada com sucesso!")}
              </Typography>
              <Typography variant='body2'>
                {t("solicitacaoAcesso:Você receberá um e-mail de confirmação após a avaliaçāo do moderador.")}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='body1' style={{ marginBottom: 20 }}>
                {t("solicitacaoAcesso:Cadastro efetuado com sucesso!")}
              </Typography>
              <Button color="primary" onClick={() => history.push('/login')}>
                {t("solicitacaoAcesso:Faça seu login")}
              </Button>              
            </>
          )
        
      }

    </div>
  );

}

export default Success
