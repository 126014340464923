import React from "react"
import PropTypes from 'prop-types';

import {
  ListItem,
  ListItemIcon,
  Typography,
  Collapse,
  Divider,
  Tooltip,
  CircularProgress
} from "@material-ui/core"

import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

import _ from "lodash"

import { useTranslation } from "react-i18next"

import { mdiFilter } from "@mdi/js"
import Icon from "@mdi/react"

import MetadataView from '../MetadataView'
import SearchInput from 'components/SearchInput'
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"

const ValuesList = ({
  dataAttributes, 
  disableHierarchy,
  handleCallbackInput,
  isDataEmpty,
  loading
}) => {
  const [openStructures, setOpenStructures] = React.useState(true)
  const [openAttributes, setOpenAttributes] = React.useState(true)
  const { t } = useTranslation()

  const hasStructures = _.get(dataAttributes, "structures", []).length > 0
  const hasAttributes = _.get(dataAttributes, "attributes", []).length > 0
  const isHiddenDivider = (hasAttributes && hasStructures && !disableHierarchy)
  
  return (
    <>
      {
        hasStructures && !disableHierarchy && (
          <>
            <ListItem button onClick={() => setOpenStructures(prev => !prev)} className='rootListItem'>
              <ListItemIcon style={{ minWidth: 10 }}>
                <Icon path={mdiFilter} color="#666" size={1} />
              </ListItemIcon>

              <Tooltip title={t("DAM:Hierarquia")} placement="top">
                <div className="title">
                  <Typography variant="body2" noWrap>
                    {t("DAM:Hierarquia")}
                  </Typography>
                </div>
              </Tooltip>
              {openStructures ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse
              in={openStructures}
              timeout="auto"
              unmountOnExit
            >
              {openStructures && (
                <>
                  {
                    _.get(dataAttributes, "structures", []).map(i =>
                      <MetadataView                        
                        metaValue={i} key={i.id}
                        loading={loading}
                      />
                    )
                  }
                </>
              )}
            </Collapse>
          </>
        )
      }

      {
        (!hasAttributes && !hasStructures) &&
        (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10
          }}>
            <CircularProgress />
          </div>
        )
      }

      {
         isHiddenDivider &&
          <Divider style={{ margin: 20 }} />
      }

      {
        hasAttributes && (
          <>
            <ListItem button onClick={() => setOpenAttributes(prev => !prev)} className='rootListItem'>
              <ListItemIcon style={{ minWidth: 10 }}>
                <Icon path={mdiFilter} color="#666" size={1} />
              </ListItemIcon>

              <Tooltip title={t("common:Metadado")} placement="top">
                <div className="title">
                  <Typography variant="body2" noWrap>
                    {t("common:Metadado")}
                  </Typography>
                </div>
              </Tooltip>
              {openAttributes ? <ExpandLess /> : <ExpandMore />}

            </ListItem>

            <Collapse
              in={openAttributes}
              timeout="auto"
              unmountOnExit
              className='rootCollapse'
            >
              <SearchInput
                placeholder={t("DAM:Buscar metadado")}
                handleCallbackInput={handleCallbackInput}
                value={''}
                isBtnBack={isDataEmpty}
              />

              {openAttributes && !isDataEmpty && (
                <>
                  {
                    _.get(dataAttributes, "attributes", []).map(i =>
                      <MetadataView                       
                        metaValue={i} key={i.id}                        
                        loading={loading}
                      />
                    )
                  }
                </>
              )}
              {
                isDataEmpty && (
                  <div className="SimpleAlertMessage">
                    <SimpleAlertMessage
                      severity="info"
                      alertTitle="Ops!"
                      message={t("common:Não encontramos o que você procura. Que tal uma nova busca?")}
                    />
                  </div>
                )
              }
            </Collapse>
          </>
        )}
    </>
  )
}

ValuesList.defaultProps = {
  isDataEmpty: false,
  dataAttributes: {
    attributes: [],
    structures: [],
  },
  disableFilters: '',
  getData: () => { },
  handleCallbackInput: () => { },  
}

ValuesList.propTypes = {
  isDataEmpty: PropTypes.bool,
  dataAttributes: PropTypes.shape({
    attributes: PropTypes.array,
    structures: PropTypes.array,
  }),
  disableFilters: PropTypes.string,
  getData: PropTypes.func,
  handleCallbackInput: PropTypes.func,
}

export default ValuesList
