import React from "react";
import i18n from "i18next"
import i18next from "i18next"
import CreateDefault from "../Core/CreateDefault";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { compose } from "recompose";
import TaxonomyAttributeComboBox from '../../../components/ComboBox/TaxonomyAttributeComboBox'
import TableAttribute from "./partials/TableAttributes";
import BoxTools from './partials/BoxTools';
import BoxRecipes from './partials/BoxRecipes';
import BoxAlternativeNames from './partials/BoxAlternativeNames';
import BoxSchemas from './partials/BoxSchemas';
import AlertAttributes from './partials/AlertAttributes'
import CategoryComboBox from "components/ComboBox/CategoryComboBox"
import { omit } from "ramda";
import { arrayMove } from "react-sortable-hoc";
import { settings } from "./_settings.js";
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Grid, Link, Switch, TextField, Typography, } from "@material-ui/core";

import TableBoxTaxonomy from 'components/Forms/TableBoxTaxonomy';
import {
    addAllMetadataToStructure,
    addMetadataToStructure,
    disableSelectedItems,
    disableStructureItem,
    handleChangeCategoryId,
    handleChangeSwitch,
    handleSwitchRelateImages,
    removeAttributeFromStructure
} from './utils'
import SettingsGlobal from 'utils/Settings'

class Create extends CreateDefault {
    constructor(props) {
        super(props);
        const { t } = props;
        const SELF = this;

        i18next.on('languageChanged', function (lng) {
            SELF.setState({
                ...SELF.state
                , taxonomyItems: [
                    { label: t("common:Cliente"), value: "client.name", },
                    { label: t("common:Região"), value: "region.name" },
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" },
                    { label: t("common:Item"), value: "item.name" }
                ]
                , secondaryKeyItems: [
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" }
                ]
            });
        })

        this.state = {
            ...this.state,
            ...settings,
            hasData: true,
            data: {
                name: "",
                description: "",
                attributes: [],
                taxonomy: [],
                secondary_key: [],
                canLink: false,
                gallery: false,
                scopeOfItems: false,
                extractColorPallete: false,
                tools: [],
                recipes: [],
                schemas: [],
                grantable_attributes: [],
                alternatives_names: [],
                relateImages: false,
                linked_category_ids: {},
            },
            fields: ["name", "description", "attributes", "taxonomy", "secondary_key", "canLink", "tools", "recipes", "alternatives_names", "gallery", "scopeOfItems", "extractColorPallete", "linked_category_ids", "schemas"],
            taxonomy: null,
            taxonomyItems: [
                { label: t("common:Cliente"), value: "client.name", },
                { label: t("common:Região"), value: "region.name" },
                { label: t("common:Divisão"), value: "division.name" },
                { label: t("common:Segmento"), value: "segment.name" },
                { label: t("common:Categoria"), value: "category.name" },
                { label: t("common:Item"), value: "item.name" }
            ],
            secondaryKeyItems: [
                { label: t("common:Divisão"), value: "division.name" },
                { label: t("common:Segmento"), value: "segment.name" },
                { label: t("common:Categoria"), value: "category.name" }
            ],
            dialog: {
                open: false,
                data: ''
            }
        };

        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleSetState = this.handleSetState.bind(this)
    }

    formatGetData(newState) {
        const { taxonomyItems, secondaryKeyItems, data } = newState;
        const { taxonomy, secondary_key, attributes } = data;
        const { t } = this.props;
        newState.taxonomyItems = disableSelectedItems(
            addAllMetadataToStructure(t, taxonomyItems, attributes)
            , taxonomy
        );
        newState.secondaryKeyItems = disableSelectedItems(
            addAllMetadataToStructure(t, secondaryKeyItems, attributes)
            , secondary_key
        );
        return newState;
    }

    getParameters() {
        let parameters = super.getParameters();
        parameters["_unset"] = []

        let omitted = {
            attributes: [
                'handleCheckRequired',
                'handleCheckBlockedEdit',
                'handleCheckSecondaryKey',
                'handleCheckGrantable',
                'handleRemoveAttribute',
            ],
            taxonomy: ['label'],
            secondary_key: ['label'],
        };

        parameters.taxonomy = parameters.taxonomy.map((item) => omit(omitted.taxonomy, item));
        parameters.secondary_key = parameters.secondary_key.map((item) => omit(omitted.secondary_key, item));

        parameters.schemas = parameters.schemas.map(function (item, index) {
            const schema = {
                order: index,
                id: item,
                grantable_attributes: parameters.attributes
                    .filter((item) => _.get(item, 'grantable', 0) === true)
                    .map((item) => item.id)
            }
            return schema;
        });

        if (_.get(parameters, 'schemas', []).length === 0) {
            parameters["_unset"].push('schemas')
            delete parameters.schemas
        }

        if (_.get(parameters, 'tools', []).length <= 0) {
            delete parameters.tools
        }

        if (_.get(parameters, 'attributes', []).length <= 0) {
            delete parameters.attributes
        }

        if (_.get(parameters, 'alternatives_names', []).length <= 0) {
            delete parameters.alternatives_names
        }

        if (_.isEmpty(parameters.linked_category_ids)) {
            delete parameters.linked_category_ids
        } else {
            parameters.linked_category_ids = [_.get(parameters, 'linked_category_ids.value', '')]
        }

        if (_.get(parameters, 'recipes', []).length <= 0) {
            delete parameters.recipes
        } else {
            parameters.recipes = parameters.recipes.map(recipe => _.get(recipe, 'id', recipe))
        }

        if (parameters.scopeOfItems) {
            parameters.scopeOfItems = 'open'
        } else {
            parameters.scopeOfItems = 'closed'
        }

        return parameters;
    }

    validateForm(parameters) {
        const { t } = this.props;
        let formValid = true;
        let formErrors = {};
        if (parameters.hasOwnProperty('name') && !(parameters.name).trim()) {
            formErrors.name = `* ${t("common:Este campo é obrigatório")}`
            formValid = false;
        }
        if (parameters.hasOwnProperty('description') && !(parameters.description).trim()) {
            formErrors.description = `* ${t("common:Este campo é obrigatório")}`
            formValid = false;
        }
        if (parameters.hasOwnProperty('taxonomy')) {
            if (parameters.taxonomy.length === 0) {
                formErrors.taxonomy = t("common:Este campo é obrigatório");
                formValid = false;
            }
            if (_.get(this.state, 'data.gallery', false)) {
                const hasItemName = parameters.taxonomy.some(i => (i.column === "name" && i.entity === 'item'))
                if (!hasItemName) {
                    formValid = false
                    this.props.enqueueSnackbar(t("manager:Ao editar uma categoria, a taxonomia deve conter o nome do item, quando esta tem itens atrelados."), {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    })
                }
            }
        }

        this.setState({ ...this.state, formErrors, formValid });
        return formValid;
    }

    // HANDLER ATTRIBUTE
    handleAddAttribute(attribute) {
        let SELF = this;
        let { data } = SELF.state;
        let newRegister = {
            required: (attribute.required ?? false) ? 1 : 0,
            id: attribute.value ?? attribute.id,
            name: attribute.label ?? attribute.name,
            order: data.attributes.length,
        };
        data.attributes.push(newRegister);
        data.attributes = this.setAttributesOrder(data.attributes)

        SELF.setState({ ...SELF.state, data })
    }

    handleRemoveAttribute(id) {
        let { data, taxonomyItems, secondaryKeyItems } = this.state;
        data.attributes = data.attributes.filter((item) => item.id !== id);
        data.attributes = this.setAttributesOrder(data.attributes)
        taxonomyItems = removeAttributeFromStructure(id, taxonomyItems)
        secondaryKeyItems = removeAttributeFromStructure(id, secondaryKeyItems)
        this.setState({ ...this.state, data, taxonomyItems, secondaryKeyItems })
    }

    handleCheckAttribute(id) {
        let { data, taxonomyItems, secondaryKeyItems } = this.state;
        const { t } = this.props;
        let attribute = data.attributes.find((item) => item.id === id);
        taxonomyItems = addMetadataToStructure(t, attribute, taxonomyItems, id)
        secondaryKeyItems = addMetadataToStructure(t, attribute, secondaryKeyItems, id)
        this.setState({ ...this.state, data, taxonomyItems, secondaryKeyItems })
    }

    handleCheckAttributeGrantable = ({ id }) => {
        const newState = { ...this.state };
        newState.data['grantable_attributes'] = newState.data.attributes
            .filter((item) => item.grantable ?? false === true)
            .map((item) => item.id);
        this.setState(newState)
    }

    handleMoveAttribute = ({ oldIndex, newIndex }) => {
        const newState = { ...this.state };
        newState.data.attributes = arrayMove(newState.data.attributes, oldIndex, newIndex);
        newState.data.attributes = this.setAttributesOrder(newState.data.attributes)
        this.setState(newState);
    };

    setAttributesOrder = (attributes) => {
        attributes = attributes.map((attribute, index) => {
            attribute.order = index
            return attribute
        })
        return attributes
    }

    handleAddTaxonomy(newTaxonomy) {
        let SELF = this;
        let { data, taxonomyItems } = SELF.state;
        let partials = (newTaxonomy.value).split('.');
        taxonomyItems = taxonomyItems.map(
            (item) => disableStructureItem(item, newTaxonomy.value)
        );
        data.taxonomy.push({
            column: partials[1],
            entity: partials[0],
            label: newTaxonomy.label,
        });
        SELF.setState({ ...SELF.state, data, taxonomyItems })
    }

    handleAddSecondaryKey(newSecondaryKey) {
        let SELF = this;
        let { data, secondaryKeyItems } = SELF.state;
        let partials = (newSecondaryKey.value).split('.');
        secondaryKeyItems = secondaryKeyItems.map(
            (item) => disableStructureItem(item, newSecondaryKey.value)
        );
        data.secondary_key.push({
            column: partials[1],
            entity: partials[0],
            label: newSecondaryKey.label,
        });
        SELF.setState({ ...SELF.state, data, secondaryKeyItems })
    }

    handleUpdateTaxonomy(data, taxonomyItems) {
        this.setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                taxonomy: data
            },
            taxonomyItems: Boolean(taxonomyItems) ? taxonomyItems : prev.taxonomyItems
        }))
    }

    handleUpdateSecondaryKey(data, secondaryKeyItems) {
        this.setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                secondary_key: data
            },
            secondaryKeyItems: Boolean(secondaryKeyItems) ? secondaryKeyItems : prev.secondaryKeyItems
        }))
    }

    handleCloseAlert() {
        this.setState({
            ...this.state, dialog: {
                open: false,
                data: ''
            }
        });
    }

    handleChangeOptions = (field, values) => {
        let newState = { ...this.state }
        if (!values) {
            newState.data[field] = []
        } else {
            newState.data[field] = values.map((i) => i.value)
        }
        this.setState(newState)
    }

    handleChangeSchema = (values) => {
        const { t } = this.props
        if (!values) {
            this.setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    schemas: [],
                    taxonomy: [],
                    secondary_key: [],
                    attributes: [],
                },
                attributes: [],
                taxonomyItems: [
                    { label: t("common:Cliente"), value: "client.name", },
                    { label: t("common:Região"), value: "region.name" },
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" },
                    { label: t("common:Nome Item"), value: "item.name" }
                ],
                secondaryKeyItems: [
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" }
                ]
            }))
        } else {
            (values ?? []).forEach((schema) => {
                _.get(schema, 'metadata', []).forEach((item) => {
                    this.handleAddAttribute(item);
                    this.handleCheckAttribute(item.id)
                });
            });
            this.handleChangeOptions("schemas", values)
        }
    }

    handleSetState(state) {
        this.setState(state)
    }

    getForm() {
        const SELF = this;
        const { t } = SELF.props;
        let attributesInCategory = [];
        let { data, taxonomyItems, secondaryKeyItems, tools } = SELF.state;

        if (data.hasOwnProperty('attributes')) {
            data['attributes'].forEach((item) => {
                attributesInCategory.push(item.id)
            });
        }

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label={`${t("common:Nome da categoria")}*`}
                        variant="outlined"
                        value={data.name}
                        onChange={(evt) => SELF.handleChange('name', evt)}
                        className={SELF.getClassName('name')}
                        error={Boolean(this.state.formErrors.name)}
                        helperText={this.state.formErrors.name ? this.state.formErrors.name : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="description"
                        label={`${t("common:Descrição")}*`}
                        variant="outlined"
                        value={data.description}
                        className={SELF.getClassName('description')}
                        onChange={(evt) => SELF.handleChange('description', evt)}
                        error={Boolean(this.state.formErrors.description)}
                        helperText={this.state.formErrors.description ? this.state.formErrors.description : ''}
                        multiline
                        rows={4}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h6'>
                        {t("manager:Esquemas de atributos")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina quais metadados serão considerados atributos deste esquema.')}
                    </Typography>

                    <BoxSchemas
                        defaultData={data.schemas.map(i => ({ value: i.value, label: i.label }))}
                        selectionLimit={1}
                        selectedLimit={data.schemas ? data.schemas.length : 0}
                        error={this.state.formErrors.schemas ? this.state.formErrors.schemas : ''}
                        handleChange={(values) =>
                            this.handleChangeSchema(values)} />

                </Grid>

                {
                    data.schemas.length === 1 &&
                    <Grid item xs={12} style={{ margin: '20px 0' }}>
                        <TableAttribute
                            items={_.get(data, 'attributes', '')}
                            taxonomy={data.taxonomy}
                            secondary_key={data.secondary_key}
                            style={{ marginTop: 0 }}
                            switchCells={["grantable"]}
                            disableRemove={true}
                            disableSort={true}
                            handleCheckAttribute={this.handleCheckAttributeGrantable}
                        />
                    </Grid>
                }

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("manager:Categoria Aberta")}
                    </Typography>
                    <div style={{ marginBottom: 10, display: 'flex' }}>
                        <Typography variant='body2'>
                            {t('manager:Em categorias abertas os compartilhamentos não expiram após cada download.')}
                        </Typography>
                        <Typography variant='body2' style={{ marginLeft: 5 }}>
                            {t('common:Saiba mais  ')}
                            <Link href={
                                i18n.language === 'pt'
                                    ? SettingsGlobal.DATA_CATEGORY_LINKS_SHARED_ITENS.pt
                                    : SettingsGlobal.DATA_CATEGORY_LINKS_SHARED_ITENS.en
                            } target="_blank"> {t('common:aqui')}</Link>.
                        </Typography>
                    </div>
                    <Switch
                        color='primary'
                        checked={!!data.scopeOfItems}
                        onChange={(evt) => handleChangeSwitch('scopeOfItems', evt, this.state, this.handleSetState)}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Typography variant='h6'>
                        {t("Taxonomia do Item")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina quais metadados serão adicionados na taxonomia do item.')}
                    </Typography>

                    <TaxonomyAttributeComboBox
                        title={t('common:Metadados')}
                        data={taxonomyItems.map((item) => item)}
                        handleChange={(selectedItem) => SELF.handleAddTaxonomy(selectedItem)}
                        error={this.state.formErrors.taxonomy}
                        field_required
                    />
                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <TableBoxTaxonomy
                        data={data.taxonomy}
                        handleSetState={this.handleUpdateTaxonomy.bind(this)}
                        data_disabled={taxonomyItems}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Typography variant='h6'>
                        {t("manager:Chave secundária do Item")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina quais metadados serão adicionados à chave secundária do item.')}
                    </Typography>

                    <TaxonomyAttributeComboBox
                        title={t('common:Metadados')}
                        data={secondaryKeyItems.map((item) => item)}
                        handleChange={(selectedItem) => SELF.handleAddSecondaryKey(selectedItem)}
                        error={this.state.formErrors.secondary_key}
                        field_required
                    />
                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <TableBoxTaxonomy
                        data={data.secondary_key}
                        handleSetState={this.handleUpdateSecondaryKey.bind(this)}
                        data_disabled={secondaryKeyItems}
                    />
                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <Typography variant='h6'>
                        {t("manager:Nomes alternativos")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Adicione as opções de nomes alternativos para baixar, compartilhar e para CDN.')}
                    </Typography>

                    <BoxAlternativeNames
                        defaultData={data.alternatives_names.map(i => ({ value: i.id, label: i.name }))}
                        selectionLimit={15}
                        selectedLimit={data.alternatives_names ? data.alternatives_names.length : 0}
                        handleChange={(values) =>
                            this.handleChangeOptions('alternatives_names', values)} />

                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <Typography variant='h6'>
                        {t("manager:Especificações da imagem")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Adicione as especificações de formatos da imagem para baixar e compartilhar.')}
                    </Typography>

                    <BoxRecipes
                        defaultData={data.recipes.map(i => ({ value: i.id, label: i.name }))}
                        selectionLimit={15}
                        selectedLimit={data.recipes ? data.recipes.length : 0}
                        handleChange={(values) =>
                            this.handleChangeOptions('recipes', values)} />

                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("manager:Paleta de cores do item")}
                    </Typography>
                    <div style={{ marginBottom: 10, display: 'flex' }}>
                        <Typography variant='body2'>
                            {t('manager:Será extraído as cores predominantes do item.')}
                        </Typography>
                    </div>
                    <Switch
                        color='primary'
                        checked={!!data.extractColorPallete}
                        onChange={(evt) => handleChangeSwitch('extractColorPallete', evt, this.state, this.handleSetState)}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant='h6'>
                        {t("manager:Ferramentas de solicitação")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina qual ferramenta de solicitação está associada a essa categoria.')}
                    </Typography>

                    <BoxTools
                        defaultData={data.tools.map(i => ({ value: i.id, label: i.title }))}
                        selectedLimit={tools ? tools.length : 0}
                        handleChange={(values) =>
                            this.handleChangeOptions('tools', values)} />

                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("common:Galeria de itens")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('common:Ao tornar a Categoria uma galeria, cada item conterá apenas um (01) ativo. Ela ficará visível como um Atalho e não será possível alterar a categoria após a sua criação.')}
                    </Typography>
                    <Switch
                        color='primary'
                        checked={!!data.gallery}
                        onChange={(evt) => handleChangeSwitch('gallery', evt, this.state, this.handleSetState)}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("common:Relacionar Itens")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Quando habilitado, será possível relacionar de forma manual os itens cadastrados na plataforma, durante a criação e edição do item.')}
                    </Typography>
                    <Switch
                        color='primary'
                        checked={!!data.canLink}
                        disabled={!!data.relateImages}
                        onChange={(evt) => handleChangeSwitch('canLink', evt, this.state, this.handleSetState)}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("manager:Relacionamento de imagens")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Ao habilitar, selecione a Categoria que possui Itens relacionados para que ocorra a identificação automática das imagens.')}
                    </Typography>
                    <Switch
                        color='primary'
                        checked={!!data.relateImages}
                        onChange={(evt) => handleSwitchRelateImages(evt, this.state, this.handleSetState)}
                    />
                    <Grid style={{ marginTop: 20 }}>
                        <CategoryComboBox
                            title="manager:Categoria relacionada"
                            isDisabled={!data.relateImages}
                            optionSelected={_.get(data, 'linked_category_ids.label', '')}
                            handleChange={(category) => handleChangeCategoryId(category, this.state, this.handleSetState)}
                        />
                    </Grid>
                </Grid>

                <AlertAttributes
                    open={this.state.dialog.open}
                    data={this.state.dialog.data}
                    handleClose={this.handleCloseAlert}
                />
            </React.Fragment>
        )
    }
}

export const mapStateToProps = ({ appReducer }) => {
    return {
        requestHeaders: appReducer.requestHeaders,
        pathServer: appReducer.pathServer,
    }
}

export default compose(
    connect(mapStateToProps)
)(withTranslation(["manager", "common"])(withSnackbar(Create)))
