import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"

import {
  ListItemIcon,
  ListItem,
  ListItemText,
  Collapse,
  Grid
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { mdiShareVariant } from "@mdi/js"
import Icon from "@mdi/react"

import SharedStepsWrapper from 'components/SharedStepsWrapper'

export default function SharedAsset({
  id_asset,
  id_item,
  version_item,
  hasHighLow,
  isDisabled,
  isDrawerOpen,
  setDrawerOpen,
  category_id,
  dates
}) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    let isMounted = true
    if (isMounted && !isDrawerOpen) {
      setOpen(false)
    }
    return () => { isMounted = false }
  }, [isDrawerOpen])

  const handleOpen = () => {
    setOpen(prev => !prev, setDrawerOpen())
  }

  return (
    <Grid>
      <ListItem button onClick={handleOpen} disabled={isDisabled}>
        <ListItemIcon>
          <Icon path={mdiShareVariant} size={1} color="#616161" />
        </ListItemIcon>
        <ListItemText primary={t('DAM:Compartilhar ativo')} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <SharedStepsWrapper
          category_id={category_id}
          id_item={id_item}
          versionIndex={version_item}
          includeFieldMetadata={false}
          data={{
            name: '',
            id: id_item,
            copyLink: true,
            type: 'asset',
            dates: dates,
            id_asset: id_asset,
            hasHighLow
          }}
        />
      </Collapse>
    </Grid>
  );
}
