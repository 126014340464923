import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import _ from 'lodash'

import SelectUIMultiAsync from './SelectUIMultiAsync'

const RelatedItemsComboBox = ({ 
  handleChange, 
  selectedItems, 
  limitItens, 
  defaultItems = [], 
  currentId = null,  
  searchTags = false,
  withoutItemGallery = false,
  title = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { requestHeaders, pathServer, supportsWebp } = useSelector(
    (state) => state.appReducer
  );
  const [loading, setLoading] = useState(false);
   
  const loadOptions = _.debounce((inputValue = '', callback) => {
    if(inputValue.length >= 2) {
    setLoading(true);
      
      const search = searchTags ? `${inputValue} AND tag:3D` : inputValue
      const fieldThumb = supportsWebp
                            ? "assets.thumbnail_pre_webp"
                            : "assets.thumbnail_pre_original"

      const parameters = {
        limit: 20,
        only: [
          "id", 
          "taxonomy", 
          "name", 
          "thumbnail", 
          fieldThumb
        ],
        search: search,
        ...(withoutItemGallery && {withoutItemGallery: true})
      };

      if(currentId) {
        parameters['not'] = {"id": currentId}
      }

      axios
        .post(`${pathServer}/item/find`, parameters, requestHeaders)
        .then(function (resp) {          
          const DATA = resp.data.items.map((i) => {            
            const { id, taxonomy, assets, name } = i;
            const field = supportsWebp ? "thumbnail_pre_webp" : "thumbnail_pre_original"

            return {
              value: id,
              label: taxonomy ? taxonomy.name : name,
              thumbnail: _.get(assets, `[0].${field}`, '')
            };
          });
          callback(DATA)
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Error ao carregar itens: ", err);
          const message = err.response
            ? err.response.data.message || err.response.headers["x-message"]
            : t("Erro inesperado. Contate o suporte");
          enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        })
      }
  }, 2000);

  const msgNoOptionsandLimit =  selectedItems === limitItens ? t("common:Pode selecionar no maximo 30 itens") : null

  return (
    <React.Fragment>
      <div style={{marginTop: 20}} id="div-relatedItemsComboBox">
        <SelectUIMultiAsync 
          loadOptions={loadOptions}
          isLoading={loading}
          isSearchable={selectedItems === limitItens ? false : true}
          msgNoOptionsandLimit={msgNoOptionsandLimit}
          valueSelect={defaultItems}
          title={Boolean(title) ? title : t("common:Relacionar item")}
          handleChangeValue={handleChange}
          isThumb
        />       
      </div> 
    </React.Fragment>
  );
};

export default RelatedItemsComboBox;
