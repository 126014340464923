import React from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import CurrentPolicyBlockedDialog from "../../Dialogs/CurrentPolicyBlockedDialog";
import ExpiredTokenDialog from "../../Dialogs/ExpiredTokenDialog";
import NotificationsPusher from "../../NotificationsPusher"

import PrivateDialogs from './PrivateDialogs'
import UserSupport from 'components/Dialogs/DialogUserSupport'
import NpsWidget from './NpsWidget'
import AccordionDownload from 'pages/DownloadCentral/AccordionDownload'
import AccordionBucket from 'pages/DAM/AccordionBucket';

import { Creators as ItemsActions } from "flux/ducks/Items"

import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 75,
    right: 20,
    zIndex: 1000,
    minWidth: "500px",
    maxWidth: '500px',
    [theme.breakpoints.down("sm")]: {
      width: '90%',
      minWidth: 0,
      maxWidth: '100%',
    }
  }
}))

const PrivateLayout = ({ children, computedMatch = {} }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  if (!_.isEmpty(_.get(computedMatch, 'params', {}))) {
    let data = {
      division: _.get(computedMatch, 'params.division', false) ? { slug: _.get(computedMatch, 'params.division', '') } : {},
      category: _.get(computedMatch, 'params.category', false) ? { slug: _.get(computedMatch, 'params.category', '') } : {},
      segment: _.get(computedMatch, 'params.segment', false) ? { slug: _.get(computedMatch, 'params.segment', '') } : {},
      shortcut: _.get(computedMatch, 'params.shortcut_slug', false) ? { slug: _.get(computedMatch, 'params.shortcut_slug', '') } : {},
      levels: _.get(computedMatch, 'params.grouped', false) ? _.get(computedMatch, 'params.grouped', '') : "",
      searchText: _.get(computedMatch, 'params.searchText', false) ? _.get(computedMatch, 'params.searchText', '') : "",
    }

    if (_.get(computedMatch, 'params.category', false) && !_.get(computedMatch, 'params.item', false)) {
      const options = JSON.parse(sessionStorage.getItem("itemsFilters"))     
      if (!_.get(options, "sorting", false)) dispatch(ItemsActions.setSession({ name: "sorting", data: { field: "name.keyword", direction: "asc", id: 1 } }))
    }

    dispatch(ItemsActions.setParamsDataToCreateItems(data))
  }

  return (
    <>
      <NpsWidget />
      <NotificationsPusher />
      <CurrentPolicyBlockedDialog />
      <ExpiredTokenDialog />

      {children}

      <PrivateDialogs />

      <UserSupport />
      <div className={classes.root}>
        <AccordionDownload />
        <AccordionBucket />
      </div>
    </>
  )
}

export default PrivateLayout
