import React, { useCallback } from "react";
import filesize from "filesize";
import { useTranslation } from "react-i18next";
import Utils from "utils/Utils";
import { Tooltip } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { useSnackbar } from "notistack";
import UploadActions from "flux/actions/UploadActions";
import { useDispatch, useSelector } from "react-redux";
import DialogConfirm from "components/Alert/DialogConfirm";
import ErrorWrapper from "../ErrorWrapper";
import ViewStatus from './ViewStatus'
import { useStyles } from "../../styles"
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';

const DetailsRows = ({ entity = 'asset' }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { supportsWebp, uploadFiles } = useSelector((state) => state.uploadReducer);
  const classes = useStyles()
  const { t } = useTranslation("DAM")

  const getThumbFile = useCallback((file) => {
    return supportsWebp
      ? file.thumbnail_card_webp
      : file.thumbnail_card_original;
  }, [supportsWebp])

  const showThumb = useCallback(file => {
    if (file.thumbnail_card_original || file.thumbnail_card_webp) {
      return <img
        width="100%"
        style={{ borderRadius: "4px" }}
        src={getThumbFile(file)}
        alt={file.name}
      />
    }

    return <Icon
      path={Utils.getIconSVG(file.type, file.path)}
      size={1}
      color={file.error && "#F4511E"}
    />
  }, [getThumbFile])

  const handleConfirmCancel = useCallback((item) => {
    dispatch(UploadActions.uploadCancelFile(item))
      .catch(error => {
        console.log(error, item.path_file);
        enqueueSnackbar(
          `${t('DAM:Erro ao tentar cancelar o upload')}: ${item.path_file}`,
          {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
  }, [enqueueSnackbar, dispatch, t])

  const handleResumeUpload = useCallback((item) => {
    dispatch(UploadActions.resume(item))
      .then(() => console.log("Upload resumed."))
      .catch(error => {
        console.log(error, item.path_file);
        enqueueSnackbar(
          `${t('DAM:Erro ao tentar retomar o upload')}: ${item.path_file}`,
          {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
  }, [enqueueSnackbar, dispatch, t])

  const rowsFiltered = uploadFiles
    .filter(i => Utils.isSameUploadContext(entity, i.entity))
    .filter(i => !i.finishedSuccessfully)

  return (
    <>
      {
        rowsFiltered.map(item => {
          return (
            <div className={classes.row} key={item.id}>
              <div className='wrapper'>
                <div className="wrapperInfo">
                  <div className="iconType">
                    {showThumb(item)}
                  </div>
                  <div
                    className={`info ${(item.status_pipeline === "ERROR" || item.error)
                      ? classes.error
                      : ""
                      }`}
                  >
                    <span className="name">{item.name}</span>
                    <span className="size">{Utils.formatDateFull(item.updated_at)}</span>
                    <span className="size">{item.size ? filesize(item.size) : ""}</span>
                  </div>
                </div>

                <div className="info-status">

                  {
                    item.error ? (
                      <ErrorWrapper
                        t={t}
                        item={item}
                        classError={classes.error}
                      />
                    ) : (
                      <ViewStatus item={item} />
                    )
                  }

                  {
                    item.upload_paused && (
                      <Tooltip title={t("DAM:Reiniciar upload")}>
                        <div style={{ zIndex: 5, display: 'flex', marginRight: 3 }} onClick={evt => handleResumeUpload(item)}>
                          <RefreshRoundedIcon className="active" />
                        </div>
                      </Tooltip>
                    )}

                  {item.status_view === 'upload' && (
                    <DialogConfirm
                      t={t}
                      title={t("DAM:Cancelar upload")}
                      description={t(
                        `DAM:Você tem certeza que deseja cancelar o upload do arquivo`,
                        { file: item.name }
                      )}
                    >
                      {(confirm) => (
                        <Tooltip title={t("DAM:Cancelar upload")}>
                          <div style={{ zIndex: 5, display: 'flex' }}>
                            <Icon
                              className={
                                !item.uploadFinished
                                  ? "active"
                                  : "inactive"
                              }
                              path={mdiClose}
                              size={1}
                              onClick={confirm(() => handleConfirmCancel(item))}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </DialogConfirm>
                  )}
                </div>
              </div>
            </div>
          )

        })
      }
    </>
  );
};

export default DetailsRows;
