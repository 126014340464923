import React from "react"

import bindKeyboard from "react-swipeable-views-utils/lib/bindKeyboard"
import SwipeableViews from "react-swipeable-views"

const SwipeViews = bindKeyboard(SwipeableViews)

export default function Carousel({ children, ...other }) {
  return <SwipeViews {...other} >
    {children.map((value, key) => React.cloneElement(value, { key }))}
  </SwipeViews>
}
