import React, { useState } from "react"
import { useTranslation } from 'react-i18next'
import { withTranslation } from "react-i18next"

import {
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@material-ui/core"

import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import InfoIcon from "@material-ui/icons/Info"
import DialogConfirm from '../../../../components/Dialogs/DialogConfirm'
import Utils from "../../../../utils/Utils"

import VersionComment from "./CommentBox"
import PaperTitle from "../PaperTitle"
import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded"

import { useStyles } from "./styles"

import _ from "lodash"

import { StyledMenu, StyledMenuItem } from '../utils'
import { useDataAssetIntegra } from "../context"

const ListVersions = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openComment, setOpenComment] = useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [comment, setComment] = useState("")
  const [isComment, setIsComment] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const classes = useStyles()
  const {
    handleChangeVersion,
    handleDeleteVersion,
    stateItem: { data: { versions = [] }, versionIndex = 0 }
  } = useDataAssetIntegra()

  const { t } = useTranslation()

  const handleClick = (event, version, index) => {
    setCurrentIndex(index)
    setComment(_.get(version, "comment", ""))
    setIsComment(_.get(version, "comment.length", 0) > 0 ? true : false)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleVersionComment = () => {
    setOpenComment(true)
    setAnchorEl(null)
  }

  const handleCloseComment = () => {
    setComment(null)
    setOpenComment(false)
  }

  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true)
    setAnchorEl(null)
  }

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false)
  }

  const handleConfirmDelete = (index) => {
    handleDeleteVersion(index)
    setOpenConfirmDelete(false)
    setAnchorEl(null)
  }

  const titleDialogVersionComment = (index) => {
    let amountVersions = _.get(versions, "length", 0)
    if (index === 0) {
      return t("DAM:Descrição da versão mais recente")
    }
    return `${t("common:Descrição")} ${amountVersions - index}${t("ª")} ${t("common:versão")}`
  }

  return (
    <>
      <VersionComment
        open={openComment}
        title={titleDialogVersionComment(currentIndex)}
        comment={comment}
        handleCloseComment={handleCloseComment}
        t={t}
      />
      <DialogConfirm
        open={openConfirmDelete}
        t={t}
        title={t("DAM:Excluir versão")}
        description={t("DAM:Você tem certeza que deseja excluir esta versão?")}
        handleClose={handleCloseConfirmDelete}
        handleConfirm={() => handleConfirmDelete(currentIndex)}
        icon={<DeleteRoundedIcon id='main' />}
      />

      <PaperTitle
        title={t("DAM:Versões do item")}
        subTitle={
          versions.length === 1
            ? t("common:versão encontrada", { qtd: versions.length })
            : t("common:versões encontradas", { qtd: versions.length })
        }
        icon={UpdateRoundedIcon}
      />

      <div className={classes.listWrapper}>
        {versions.map((version, index) => {
          const { created_at } = version

          return (
            <div
              key={index}
              className={
                index === versionIndex
                  ? "container-version selected"
                  : "container-version"
              }
            >
              <button onClick={() => handleChangeVersion(index)}
                style={{
                  border: 'none',
                  background: 'transparent',
                  textAlign: 'left',
                  padding: 0,
                  fontFamily: 'inherit',
                }}
              >
                <Typography component="div" variant="body2">
                  <Box fontWeight={500}>
                    {Utils.versionName(versions, index, t)}
                  </Box>
                </Typography>
                <Typography component="div" variant="caption">
                  <Box fontWeight={300}>
                    {`${Utils.formatDateForVersions(created_at, t)} ${t('DAM:por')} ${_.get(
                      version.user,
                      "name",
                      "no-name"
                    )}`}
                  </Box>
                </Typography>
              </button>

              <button
                style={{
                  border: 'none',
                  background: 'transparent',
                  textAlign: 'left',
                  padding: 0,
                  fontFamily: 'inherit',
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(event) => handleClick(event, version, index)}
              >
                <MoreVertIcon fontSize="large" />
              </button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem
                  disabled={_.get(versions, "length", 0) === 1 ? true : false}
                  onClick={handleOpenConfirmDelete}
                >
                  <ListItemIcon>
                    <DeleteRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("common:Excluir")} />
                </StyledMenuItem>
                {isComment && (
                  <StyledMenuItem onClick={handleVersionComment}>
                    <ListItemIcon>
                      <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("DAM:Comentário")} />
                  </StyledMenuItem>
                )}
              </StyledMenu>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default withTranslation(["common", "DAM"])(ListVersions)
