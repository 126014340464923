import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash'
import { useTranslation } from "react-i18next"
import axios from "axios"
import { Grid } from "@material-ui/core"
import Settings from "../../utils/Settings";
import { useStyles } from "./styles"

import {
    Button,
} from "@material-ui/core"


import CategoryAttributes, { createForm, validation } from 'components/CategoryAttributes'

const ComplementaryData = ({
    profileId,
    handleClick,
    handleBack,
    type,
    requestHeaders,
    client_slug,
    isMobile
}) => {
    const [state, setState] = useState({
        data: [],
        dataFields: {},
        errorFields: {},
        error: false,
        loading: true
    })

    const classes = useStyles()
    const { t } = useTranslation()

    const getData = useCallback(async () => {
        try {
            const parameters = { profile_id: profileId }
            const { data } = await axios.post(`${Settings.URL_API}/client/${client_slug}/attribute`, parameters, requestHeaders)
            if(_.get(data, 'items', []).length > 0) {
                const fields = createForm(data.items)
                setState(prev => ({
                    ...prev,
                    loading: false,
                    data: data.items,
                    dataFields: fields
                }))
            } else {
                handleClick({ complementary: {}, activeStep: 3 })
            }            
        } catch (e) {
            console.log(e)
            setState(prev => ({
                ...prev,
                loading: false,
                error: true
            }))
        }
    }, [requestHeaders, profileId, client_slug, handleClick])

    useEffect(() => {
        let isMounted = true
        if (isMounted && state.loading) getData()
        return () => { isMounted = false }
    }, [getData, state.loading])

    
    const handleChangeDataFields = (id, value) => {
        let newAttributes =  { ...state.dataFields }

        Object.entries(newAttributes).forEach(([key, item]) => {
            if (key === id) {
                newAttributes[key] = value
            }
        })

        setState(prev => {
            let errorFields = prev.errorFields
            delete errorFields[id]
            return {
                ...prev,
                dataFields: newAttributes,
                errorFields: errorFields
            }
        })
    }

    const handleClickValid = () => {       
        let dataErrorForm = validation(state.dataFields, state.data)

        if (_.isEmpty(dataErrorForm)) {
            handleClick({
                complementary: state.dataFields,
                activeStep: 3
            })            
        } else {
            setState(prev => ({ ...prev, errorFields: dataErrorForm })) 
        }
    }

    return (
        <Grid container spacing={2} className={isMobile ? classes.borderMobile : ''}>
            <CategoryAttributes
                attributes={state.data}
                dataFields={state.dataFields}
                errorFields={state.errorFields}
                loading={state.loading}
                error={state.error}
                changeAttributes={handleChangeDataFields}
                optionCreateMetadata={false}
            />            
            <Grid item xs={12} className={classes.btnActions}>
                {
                    type === 'accessRequest' &&
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                        style={{ height: 40, marginRight: 25 }}
                    >
                        {t('common:Voltar')}
                    </Button>
                }

                <Button
                    disabled={!_.isEmpty(state.errorFields)}
                    variant="contained"
                    color="primary"
                    onClick={handleClickValid}
                    style={{ height: 40 }}
                >
                    {t('solicitacaoAcesso:próximo')}
                </Button>
            </Grid>
        </Grid>
    );
};

export default ComplementaryData;
