
import React from 'react';
import i18n from "i18next"
import EditDefault from "../Core/Edit"
import AttributeComboBox from "components/ComboBox/AttributeComboBox";
import TaxonomyAttributeComboBox from 'components/ComboBox/TaxonomyAttributeComboBox'
import TableAttribute from "./partials/TableAttributes";
import { omit } from "ramda";
import { arrayMove } from "react-sortable-hoc";
import { settings } from "./_settings.js";
import AppActions from "flux/actions/AppActions"
import BoxTools from './partials/BoxTools'
import BoxRecipes from './partials/BoxRecipes'
import BoxAlternativeNames from './partials/BoxAlternativeNames'
import CategoryComboBox from "components/ComboBox/CategoryComboBox"
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import _ from 'lodash'
import AlertAttributes from './partials/AlertAttributes'
import connect from "react-redux/es/connect/connect"
import { compose } from "recompose"
import { withSnackbar } from "notistack"

import {
    Grid,
    TextField,
    Typography,
    Switch,
    Link
} from "@material-ui/core";

import TableBoxTaxonomy from 'components/Forms/TableBoxTaxonomy'
import {
    handleChangeSwitch,
    handleSwitchRelateImages,
    handleChangeCategoryId,
    addAllMetadataToStructure,
    addMetadataToStructure,
    disableSelectedItems,
    disableStructureItem,
    removeAttributeFromStructure
} from './utils'
import SettingsGlobal from 'utils/Settings'
import BoxSchemas from "./partials/BoxSchemas";
class Edit extends EditDefault {
    constructor(props) {
        super(props);
        const { t } = props;
        const SELF = this;

        i18next.on('languageChanged', function (lng) {
            SELF.setState({
                ...SELF.state,
                taxonomyItems: [
                    { label: t("common:Cliente"), value: "client.name", },
                    { label: t("common:Região"), value: "region.name" },
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" },
                    { label: t("common:Nome Item"), value: "item.name" }
                ],
                secondaryKeyItems: [
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" }
                ],
            })
        })

        SELF.state = {
            ...SELF.state,
            ...settings,
            entityName: t("common:Categoria"),
            fields: ["name", "description", "attributes", "taxonomy", "secondary_key", "canLink", "tools", "recipes", "alternatives_names", "gallery", "scopeOfItems", "extractColorPallete", "linked_category_ids", "schemas", "loadWithSchema"],
            taxonomy: null,
            secondary_key: null,
            requireConfirmation: true,
            taxonomyItems: [
                { label: t("common:Cliente"), value: "client.name", },
                { label: t("common:Região"), value: "region.name" },
                { label: t("common:Divisão"), value: "division.name" },
                { label: t("common:Segmento"), value: "segment.name" },
                { label: t("common:Categoria"), value: "category.name" },
                { label: t("common:Nome Item"), value: "item.name" }
            ],
            secondaryKeyItems: [
                { label: t("common:Divisão"), value: "division.name" },
                { label: t("common:Segmento"), value: "segment.name" },
                { label: t("common:Categoria"), value: "category.name" }
            ],
            dialogAttributes: {
                open: false,
                data: ''
            }
        };

        SELF.handleCloseAlert = SELF.handleCloseAlert.bind(SELF)
        SELF.handleSetState = SELF.handleSetState.bind(SELF)
    }

    formatGetData(newState) {
        const { taxonomyItems, secondaryKeyItems, data } = newState;
        const { taxonomy, secondary_key } = data;
        const { t } = this.props;
        let { attributes } = data;

        newState.data.loadWithSchema = false;

        if (data.schemas.length === 0 && data.attributes.length === 0) {
            newState.data.loadWithSchema = true;
        }

        if (data.schemas && data.schemas.length > 0) {
            newState.data.loadWithSchema = true;
            data.schemas.forEach(function (schema) {
                const grantable = _.get(schema, 'grantable_attributes', []);
                if (!data.grantable_attributes) {
                    data['grantable_attributes'] = [];
                }
                data.grantable_attributes = [...data.grantable_attributes, ...grantable];
            });
        }

        if (!attributes) {
            attributes = [];
            newState.attributes = [];
            newState.data.attributes = [];
            newState.dataOriginal.attributes = [];
        }
        newState.taxonomyItems = disableSelectedItems(
            addAllMetadataToStructure(t, taxonomyItems, attributes)
            , taxonomy
        );
        newState.secondaryKeyItems = disableSelectedItems(
            addAllMetadataToStructure(t, secondaryKeyItems, attributes)
            , secondary_key
        );
        newState.disabledGallery = _.get(data, 'gallery', false)
        newState.data.scopeOfItems = _.get(data, 'scopeOfItems', 'closed') === 'open'
        return newState;
    }

    getParameters() {
        let parameters = super.getParameters();
        parameters["_unset"] = []
        let omitted = {
            attributes: [
                'handleCheckRequired',
                'handleCheckBlockedEdit',
                'handleCheckSecondaryKey',
                'handleCheckGrantable',
                'handleRemoveAttribute',
            ],
            taxonomy: ['label'],
            secondary_key: ['label'],
        };

        if (parameters.loadWithSchema) {
            parameters.schemas = parameters.schemas.map(function (item, index) {
                const schema = {
                    order: _.get(item, 'order', index),
                    id: _.get(item, 'value', item),
                    grantable_attributes: parameters.attributes
                        .filter((attr) => _.get(attr, 'grantable', 0) === true)
                        .map((attr) => attr.id)
                }
                return schema;
            });
            if (_.get(parameters, 'attributes', []).length > 0) {
                delete parameters.attributes
            }
        }
        else {
            delete parameters.schemas;
            parameters.attributes = parameters.attributes.map((item) => omit(omitted.attributes, item));
        }
        delete parameters.loadWithSchema;
        parameters.taxonomy = parameters.taxonomy.map((item) => omit(omitted.taxonomy, item));
        if (_.get(parameters, 'schemas', []).length === 0) {
            parameters["_unset"].push('schemas')
            delete parameters.schemas
        }
        if (_.get(parameters, 'secondary_key', []).length <= 0) {
            parameters["_unset"].push('secondary_key')
            delete parameters.secondary_key
        } else {
            parameters.secondary_key = parameters.secondary_key.map((item) => omit(omitted.secondary_key, item));
        }

        if (_.get(parameters, 'tools', []).length <= 0) {
            delete parameters.tools
        } else {
            parameters.tools = parameters.tools.map((tool) => _.get(tool, "id", tool))
        }

        if (_.get(parameters, 'attributes', []).length <= 0) {
            delete parameters.attributes
        }

        if (_.isEmpty(parameters.linked_category_ids)) {
            delete parameters.linked_category_ids
        } else {
            parameters.linked_category_ids = [_.get(parameters, 'linked_category_ids.value', '')]
        }

        if (_.get(parameters, 'recipes', []).length <= 0) {
            delete parameters.recipes
        } else {
            parameters.recipes = parameters.recipes.map(recipe => _.get(recipe, 'id', recipe))
        }

        if (_.get(parameters, 'alternatives_names', []).length > 0) {
            const newDataAlternativesNames = _.get(parameters, 'alternatives_names', []).map(i => {
                if (typeof i !== 'string') {
                    return _.get(i, 'id', '')
                } else {
                    return i
                }
            })
            parameters.alternatives_names = newDataAlternativesNames
        }

        if (this.state.disabledGallery) {
            parameters.gallery = true
        }

        if (parameters.scopeOfItems) {
            parameters.scopeOfItems = 'open'
        } else {
            parameters.scopeOfItems = 'closed'
        }

        return parameters;
    }

    validateForm(parameters) {
        const { t } = this.props;
        let formValid = true;
        let formErrors = {};
        if (parameters.hasOwnProperty('name') && !(parameters.name).trim()) {
            formErrors.name = t("common:Este campo é obrigatório");
            formValid = false;
        }
        if (parameters.hasOwnProperty('description') && !(parameters.description).trim()) {
            formErrors.description = t("common:Este campo é obrigatório");
            formValid = false;
        }
        if (parameters.hasOwnProperty('taxonomy')) {
            if (parameters.taxonomy.length === 0) {
                formErrors.taxonomy = t("common:Este campo é obrigatório");
                formValid = false;
            }
            if (_.get(this.state, 'data.gallery', false)) {
                const hasItemName = parameters.taxonomy.some(i => (i.column === "name" && i.entity === 'item'))
                if (!hasItemName) {
                    formValid = false
                    this.props.enqueueSnackbar(t("manager:Ao editar uma categoria, a taxonomia deve conter o nome do item, quando esta tem itens atrelados."), {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    })
                }
            }
        }

        this.setState({ ...this.state, formErrors, formValid });
        return formValid
    }

    // HANDLER ATTRIBUTE
    handleAddAttribute(attribute) {
        let SELF = this;
        let { data } = SELF.state;
        let newRegister = {
            required: (attribute.required ?? false) ? 1 : 0,
            id: attribute.value ?? attribute.id,
            name: attribute.label ?? attribute.name,
            order: data.attributes.length,
        };
        data.attributes.push(newRegister);
        data.attributes = this.setAttributesOrder(data.attributes)
        data.attribute = null;
        SELF.setState({ ...SELF.state, data })
    }

    handleRemoveAttribute(id) {
        let { data, taxonomyItems, secondaryKeyItems } = this.state;
        data.attributes = data.attributes.filter((item) => item.id !== id);
        data.attributes = this.setAttributesOrder(data.attributes)
        taxonomyItems = removeAttributeFromStructure(id, taxonomyItems)
        secondaryKeyItems = removeAttributeFromStructure(id, secondaryKeyItems)
        this.setState({ ...this.state, data, taxonomyItems, secondaryKeyItems })
    }

    handleCheckAttribute(id) {
        let { data, taxonomyItems, secondaryKeyItems } = this.state;
        let attribute = data.attributes.find((item) => item.id === id);
        const { t } = this.props;

        taxonomyItems = addMetadataToStructure(t, attribute, taxonomyItems, id)
        secondaryKeyItems = addMetadataToStructure(t, attribute, secondaryKeyItems, id)
        this.setState({ ...this.state, data, taxonomyItems, secondaryKeyItems })
    }

    handleMoveAttribute = ({ oldIndex, newIndex }) => {
        const newState = { ...this.state };
        newState.data.attributes = arrayMove(newState.data.attributes, oldIndex, newIndex)
        newState.data.attributes = this.setAttributesOrder(newState.data.attributes)
        this.setState(newState);
    };

    setAttributesOrder = (attributes) => {
        attributes = attributes.map((attribute, index) => {
            attribute.order = index
            return attribute
        })
        return attributes
    }

    //HANDLER TAXONOMY
    handleAddTaxonomy(newTaxonomy) {
        let SELF = this;
        let { data, taxonomyItems } = SELF.state;

        if (newTaxonomy === undefined || newTaxonomy === null) {
            return
        }

        let partials = (newTaxonomy.value).split('.');
        taxonomyItems = taxonomyItems.map(
            (item) => disableStructureItem(item, newTaxonomy.value)
        );
        (data.taxonomy = data.taxonomy || []).push({
            column: partials[1],
            entity: partials[0],
            label: newTaxonomy.label,
        });
        data.taxonomyTemp = null;
        SELF.setState({ ...SELF.state, data, taxonomyItems })
    }

    handleAddSecondaryKey(newSecondaryKey) {
        let SELF = this;
        let { data, secondaryKeyItems } = SELF.state;

        if (newSecondaryKey === undefined || newSecondaryKey === null) {
            return
        }

        let partials = (newSecondaryKey.value).split('.');
        secondaryKeyItems = secondaryKeyItems.map(
            (item) => disableStructureItem(item, newSecondaryKey.value)
        );
        (data.secondary_key = data.secondary_key || []).push({
            column: partials[1],
            entity: partials[0],
            label: newSecondaryKey.label,
        });
        SELF.setState({ ...SELF.state, data, secondaryKeyItems })
    }

    handleUpdateTaxonomy(data, taxonomyItems) {
        this.setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                taxonomy: data
            },
            taxonomyItems: Boolean(taxonomyItems) ? taxonomyItems : prev.taxonomyItems
        }))
    }

    handleUpdateSecondaryKey(data, secondaryKeyItems) {
        this.setState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                secondary_key: data
            },
            secondaryKeyItems: Boolean(secondaryKeyItems) ? secondaryKeyItems : prev.secondaryKeyItems
        }))
    }

    handleCloseAlert() {
        this.setState({
            ...this.state, dialogAttributes: {
                open: false,
                data: ''
            }
        });
    }

    handleChangeOptions = (field, values) => {
        let newState = { ...this.state }
        if (!values) {
            newState.data[field] = []
        } else {
            newState.data[field] = values.map((i) => i.value)
        }
        this.setState(newState)
    }

    handleSetState(state) {
        this.setState(state)
    }

    handleChangeSchema = (values) => {
        const { t } = this.props
        if (!values) {
            this.setState(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    schemas: [],
                    taxonomy: [],
                    secondary_key: [],
                    attributes: [],
                },
                attributes: [],
                taxonomyItems: [
                    { label: t("common:Cliente"), value: "client.name", },
                    { label: t("common:Região"), value: "region.name" },
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" },
                    { label: t("common:Nome Item"), value: "item.name" }
                ],
                secondaryKeyItems: [
                    { label: t("common:Divisão"), value: "division.name" },
                    { label: t("common:Segmento"), value: "segment.name" },
                    { label: t("common:Categoria"), value: "category.name" }
                ]
            }))
        } else {
            (values ?? []).forEach((schema) => {
                _.get(schema, 'metadata', []).forEach((item) => {
                    this.handleAddAttribute(item);
                    this.handleCheckAttribute(item.id)
                });
            });
            this.handleChangeOptions("schemas", values)
        }
    }

    handleCheckAttributeGrantable = ({ id }) => {
        const newState = { ...this.state };
        newState.data['grantable_attributes'] = newState.data.attributes
            .filter((item) => item.grantable ?? false === true)
            .map((item) => item.id);
        this.setState(newState)
    }

    getAttributesOrSchema() {
        const SELF = this;
        let { data } = this.state;
        const { t } = SELF.props;
        let attributesInCategory = [];
        if (data.hasOwnProperty('attributes')) {
            data['attributes'].forEach((item, index) => {
                attributesInCategory.push(item.id)
            });
        }

        if (data.loadWithSchema === true) {
            return (<React.Fragment>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        {t("manager:Esquemas de atributos")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina quais metadados serão considerados atributos deste esquema.')}
                    </Typography>

                    <BoxSchemas
                        defaultData={data.schemas.map(i => ({ value: i.value, label: i.label }))}
                        selectionLimit={1}
                        selectedLimit={data.schemas ? data.schemas.length : 0}
                        //error={data.formErrors.schemas ? data.formErrors.schemas : ''}
                        handleChange={(values) =>
                            SELF.handleChangeSchema(values)} />

                </Grid>

                {
                    data.schemas.length === 1 &&
                    <Grid item xs={12} style={{ margin: '20px 0' }}>
                        <TableAttribute
                            items={_.get(data, 'attributes', '')}
                            taxonomy={data.taxonomy}
                            secondary_key={data.secondary_key}
                            style={{ marginTop: 0 }}
                            switchCells={["grantable"]}
                            disableRemove={true}
                            disableSort={true}
                            handleCheckAttribute={SELF.handleCheckAttributeGrantable}
                        />
                    </Grid>
                }
            </React.Fragment>);
        }
        else {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant='h6'>
                            {t('common:Atributos')}
                        </Typography>
                        <Typography variant='body2' style={{ marginBottom: 20 }}>
                            {t('manager:Defina quais metadados serão considerados atributos desta categoria.')}
                        </Typography>
                        <AttributeComboBox
                            disabledItems={attributesInCategory}
                            handleChange={(selectedItem) => SELF.handleAddAttribute(selectedItem)}
                        />
                        {
                            SELF.state.formErrors.attributes &&
                            <div className="error">{SELF.state.formErrors.attributes}</div>
                        }
                    </Grid>

                    {data.attributes.length > 0 &&
                        <Grid item xs={12} style={{ margin: '20px 0' }}>
                            <TableAttribute
                                items={data.attributes}
                                taxonomy={data.taxonomy}
                                secondary_key={data.secondary_key}
                                style={{ marginTop: 0 }}
                                handleRemoveAttribute={(id, evt) => SELF.handleRemoveAttribute(id, evt)}
                                handleCheckAttribute={(id, evt) => SELF.handleCheckAttribute(id, evt)}
                                handleMoveAttribute={(obj) => SELF.handleMoveAttribute(obj)}
                            />
                        </Grid>}
                </React.Fragment>
            );
        }
    }

    getForm() {
        const SELF = this;
        const { t } = SELF.props;
        let { data, disabledGallery } = this.state;

        if (Object.keys(data).length === 0 && data.constructor === Object) {
            return <div />;
        }

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label={`${t("common:Nome da categoria")}*`}
                        variant="outlined"
                        value={data.name}
                        onChange={(evt) => SELF.handleChange('name', evt)}
                        className={SELF.getClassName('name')}
                        error={Boolean(this.state.formErrors.name)}
                        helperText={this.state.formErrors.name ? this.state.formErrors.name : ''}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        name="description"
                        label={`${t("common:Descrição")}*`}
                        variant="outlined"
                        value={data.description}
                        className={SELF.getClassName('description')}
                        onChange={(evt) => SELF.handleChange('description', evt)}
                        error={Boolean(this.state.formErrors.description)}
                        helperText={this.state.formErrors.description ? this.state.formErrors.description : ''}
                        multiline
                        rows={4}
                    />
                </Grid>

                {this.getAttributesOrSchema()}
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("manager:Categoria Aberta")}
                    </Typography>
                    <div style={{ marginBottom: 10, display: 'flex' }}>
                        <Typography variant='body2'>
                            {t('manager:Em categorias abertas os compartilhamentos não expiram após cada download.')}
                        </Typography>
                        <Typography variant='body2' style={{ marginLeft: 5 }}>
                            {t('common:Saiba mais  ')}
                            <Link href={
                                i18n.language === 'pt'
                                    ? SettingsGlobal.DATA_CATEGORY_LINKS_SHARED_ITENS.pt
                                    : SettingsGlobal.DATA_CATEGORY_LINKS_SHARED_ITENS.en
                            } target="_blank"> {t('common:aqui')}</Link>.
                        </Typography>
                    </div>
                    <Switch
                        color='primary'
                        checked={data.scopeOfItems}
                        onChange={(evt) => handleChangeSwitch('scopeOfItems', evt, this.state, this.handleSetState)}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Typography variant='h6'>
                        {t("Taxonomia do Item")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina quais metadados serão adicionados na taxonomia do item.')}
                    </Typography>
                    <TaxonomyAttributeComboBox
                        title={t('Metadados')}
                        data={_.get(this.state, 'taxonomyItems', []).map((item) => item)}
                        handleChange={(selectedItem) => SELF.handleAddTaxonomy(selectedItem)}
                        error={this.state.formErrors.taxonomy}
                        field_required
                    />
                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <TableBoxTaxonomy
                        data={data.taxonomy}
                        handleSetState={this.handleUpdateTaxonomy.bind(this)}
                        data_disabled={_.get(this.state, 'taxonomyItems', [])}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Typography variant='h6'>
                        {t("manager:Chave secundária do Item")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina quais metadados serão adicionados à chave secundária do item.')}
                    </Typography>

                    <TaxonomyAttributeComboBox
                        title={t('common:Metadados')}
                        data={_.get(this.state, 'secondaryKeyItems', []).map((item) => item)}
                        handleChange={(selectedItem) => SELF.handleAddSecondaryKey(selectedItem)}
                        error={this.state.formErrors.secondary_key}
                    />
                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <TableBoxTaxonomy
                        data={data.secondary_key}
                        handleSetState={this.handleUpdateSecondaryKey.bind(this)}
                        data_disabled={_.get(this.state, 'secondaryKeyItems', [])}
                    />
                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <Typography variant='h6'>
                        {t("manager:Nomes alternativos")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Adicione as opções de nomes alternativos para baixar, compartilhar e para CDN.')}
                    </Typography>

                    <BoxAlternativeNames
                        defaultData={data.alternatives_names.map(i => ({ value: i.id, label: i.title }))}
                        selectionLimit={15}
                        selectedLimit={data.alternatives_names ? data.alternatives_names.length : 0}
                        handleChange={(values) =>
                            this.handleChangeOptions('alternatives_names', values)} />

                </Grid>

                <Grid item xs={12} style={{ margin: '20px 0' }}>
                    <Typography variant='h6'>
                        {t("manager:Especificações da imagem")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Adicione as especificações de formatos da imagem para baixar e compartilhar.')}
                    </Typography>

                    <BoxRecipes
                        defaultData={data.recipes.map(i => ({ value: i.id, label: i.name }))}
                        selectionLimit={15}
                        selectedLimit={data.recipes ? data.recipes.length : 0}
                        handleChange={(values) =>
                            this.handleChangeOptions('recipes', values)} />

                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("manager:Paleta de cores do item")}
                    </Typography>
                    <div style={{ marginBottom: 10, display: 'flex' }}>
                        <Typography variant='body2'>
                            {t('manager:Será extraído as cores predominantes do item.')}
                        </Typography>
                    </div>
                    <Switch
                        color='primary'
                        checked={data.extractColorPallete}
                        onChange={(evt) => handleChangeSwitch('extractColorPallete', evt, this.state, this.handleSetState)}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant='h6'>
                        {t("manager:Ferramentas de solicitação")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Defina qual ferramenta de solicitação está associada a essa categoria.')}
                    </Typography>

                    <BoxTools
                        defaultData={data.tools.map(i => ({ value: i.id, label: i.title }))}
                        selectedLimit={data.tools ? data.tools.length : 0}
                        handleChange={(values) =>
                            this.handleChangeOptions('tools', values)} />

                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("common:Galeria")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('common:Ao tornar a Categoria uma galeria, cada item conterá apenas um (01) ativo. Ela ficará visível como um Atalho e não será possível alterar a categoria após a sua criação.')}
                    </Typography>
                    <Switch
                        color='primary'
                        disabled={disabledGallery}
                        checked={!!data.gallery}
                        onChange={(evt) => handleChangeSwitch('gallery', evt, this.state, this.handleSetState)}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("common:Relacionar Itens")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Quando habilitado, será possível relacionar de forma manual os itens cadastrados na plataforma, durante a criação e edição do item.')}
                    </Typography>
                    <Switch
                        color='primary'
                        checked={!!data.canLink}
                        disabled={!!data.relateImages}
                        onChange={(evt) => handleChangeSwitch('canLink', evt, this.state, this.handleSetState)}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <Typography variant="h6">
                        {t("manager:Relacionamento de imagens")}
                    </Typography>
                    <Typography variant='body2' style={{ marginBottom: 20 }}>
                        {t('manager:Ao habilitar, selecione a Categoria que possui Itens relacionados para que ocorra a identificação automática das imagens.')}
                    </Typography>
                    <Switch
                        color='primary'
                        checked={!!data.relateImages}
                        onChange={(evt) => handleSwitchRelateImages(evt, this.state, this.handleSetState)}
                    />
                    <Grid style={{ marginTop: 20 }}>
                        <CategoryComboBox
                            title="manager:Categoria relacionada"
                            isDisabled={!data.relateImages}
                            optionSelected={_.get(data, 'linked_category_ids.label', '')}
                            handleChange={(category) => handleChangeCategoryId(category, this.state, this.handleSetState)}
                        />
                    </Grid>
                </Grid>
                <AlertAttributes
                    open={this.state.dialogAttributes.open}
                    data={this.state.dialogAttributes.data}
                    handleClose={this.handleCloseAlert}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ appReducer }) => {
    return {
        userId: appReducer.userData.user_id,
        channels_connected: appReducer.pusher.channels_connected,
        pathServer: appReducer.pathServer,
        requestHeaders: appReducer.requestHeaders
    }
};

const mapDispatchToProps = dispatch => {
    return {
        bindToChannel: (channel, message, callback) => dispatch(AppActions.bindToChannel(channel, message, callback)),
        channelUnsubscribe: (channel) => dispatch(AppActions.channelUnsubscribe(channel)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation(["manager", "common"])(withSnackbar(Edit)))
