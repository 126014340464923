import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"

export const useStyles = makeStyles((theme) => ({
  rootWrapper: {
    width: "100%",
    position: 'relative'
  },
  arrow: {
    cursor: 'pointer',
    width: 42,
    height: 42,
    position: 'absolute',
    zIndex: 1,
    top: 'calc(50% - 21px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      cursor: 'default',
      "& svg":{
        opacity: .3
      }
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  arrowLeft: {
    left: 0,
  },
  arrowRight: {
    right: 0,
  },
  arrowIcon: {
    color: grey[700],
    fontSize: '2.5rem'
  },
  carouselWrapper: {
    height: "100%",
    maxHeight: 600,
    overflow: "hidden",
    transform: "scale(1.0)",
  },
  slide: {
    width: "100%",
    height: "100%",
  },
  slideMobile: {
    width: "100%",
    height: "100%",
  },
  carousel: {
    height: "100%",
    maxHeight: 600,
  },
}))
