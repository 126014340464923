import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  wrapperBtns: {
    display: "flex",
    marginTop: 25,
    justifyContent: (props) => (props.allow ? "space-between" : "center"),
    "& .MuiFab-root": {
      width: 48,
      height: 48,
    },
    '& .MuiTooltip-popper': {
      margin: '10px !important'
    },
    '& #marginRight': {
      margin: 10,
      cursor: 'pointer',
    },

  },
  paperMenuDownload: {
    "& .MuiMenu-paper": {
      width: "260px",
      border: "1px solid #F0F0F0",
      margin: "0 10px",
      borderRadius: "5px",
      "& .MuiList-padding": {
        padding: 0,
      },
    },
    "& .MuiMenu-paper * span": {
      fontSize: "14px",
    },
  },
}))
