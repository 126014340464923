import React from 'react'

import {    
    CircularProgress,
    Box,
    Typography,      
} from '@material-ui/core';

const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" style={{color: '#666'}}>
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
}

export default CircularProgressWithLabel