import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import axios from 'axios'
import _ from 'lodash'

import {
  ListItemIcon,
  ListItem,
  Typography,
  ListItemText,
  Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Skeleton from "@material-ui/lab/Skeleton"

import feedSVG from 'imagens/icons/feed_black_24dp.svg'

const EXIF_DATA_TYPE = 'exif'
const IPTC_DATA_TYPE = 'iptc'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    paddingTop: 0,
    overflow: 'auto',
    maxHeight: 'calc(100vh - 430px)'
  },
  row: {
    display: "flex",
    flexDirection: "column",
    color: "#666",
    marginTop: "20px",
    "& #title": {
      fontWeight: 500,
    },
    "& #info": {
      marginTop: "10px",
      fontWeight: 300,
      whiteSpace: 'pre-line',
    },
  }
}))


const GroupMetadata = ({ id_asset, id_item, metadata_type }) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { requestHeaders, pathServer } = useSelector(
    (state) => state.appReducer
  )
  const classes = useStyles()
  const { t } = useTranslation()

  const getData = useCallback(() => {
    axios.get(`${pathServer}/item/${id_item}/asset/${id_asset}/${metadata_type}`, requestHeaders)
      .then(function ({ data }) {
        setData(data)
        setError(false)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setError(true)
        setLoading(false)
      });
  }, [pathServer, requestHeaders, id_asset, id_item, metadata_type])

  useEffect(() => {
    let isMounted = true
    if (isMounted) getData()
    return () => { isMounted = false }
  }, [getData])

  const getSkeletonChip = () => {
    const BORDER_RADIUS_SKELETON = 10

    return (
      <>
        <Skeleton
          variant="text"
          width="150px"
          height="20px"
          style={{ borderRadius: BORDER_RADIUS_SKELETON, margin: '5px 0 0 0' }}
        />
        <Skeleton
          variant="text"
          width="120px"
          height="20px"
          style={{ borderRadius: BORDER_RADIUS_SKELETON, margin: '5px 0 0 0' }}
        />
      </>
    )
  }

  return (
    <div className={classes.root}>
      {
        _.isEmpty(data) && !loading &&
        <Typography variant="body1">
          {t('common:Nenhum dado encontrado.')}
        </Typography>
      }
      {
        error && !loading &&
        <Typography variant="body1">
          {t('common:Erro ao carregar os dados, contate o suporte')}
        </Typography>

      }
      {
        loading && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {getSkeletonChip()}
            {getSkeletonChip()}
            {getSkeletonChip()}
            {getSkeletonChip()}
          </div>
        )
      }
      {
        !loading && !_.isEmpty(data) && (
          <>
            {
              Object.entries(data).map(([key, value]) => (
                <div className={classes.row} key={`${value}-${key}`}>
                  <Typography component="div" variant="body2" id="title">
                    {key}
                  </Typography>
                  <Typography component="div" variant="body2" id="info">
                    {Array.isArray(value) ? value.join(", ") : value}
                  </Typography>
                </div>
              ))
            }
          </>
        )
      }
    </div>

  )
}

export default function MetadataDetailsAsset({ id_asset, id_item, isDrawerOpen, setDrawerOpen, metadataType }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    let isMounted = true
    if (!isDrawerOpen && isMounted) {
      setOpen(false)
    }
    return () => { isMounted = false }
  }, [isDrawerOpen])

  const handleOpen = () => {
    setDrawerOpen()
    setOpen(prev => !prev)
  }

  let componentTitle = ''
  if (metadataType === EXIF_DATA_TYPE) {
    componentTitle = t("DAM:EXIF")
  } if (metadataType === IPTC_DATA_TYPE) {
    componentTitle = t("DAM:IPTC")
  }

  return (
    <div>
      <ListItem button onClick={handleOpen}>
        <ListItemIcon>
          <img src={feedSVG} alt='t("DAM:Informações do ativo")' />
        </ListItemIcon>
        <ListItemText primary={componentTitle} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <GroupMetadata id_asset={id_asset} id_item={id_item} metadata_type={metadataType} />
      </Collapse>
    </div>
  );
}
