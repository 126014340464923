import React, { useState } from "react"

import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Dialog
} from "@material-ui/core"
import PropTypes from 'prop-types';
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next"
import { useStyles } from "./styles"

import imgUpload from "../../../../imagens/ui/ico-upload.svg";

const UploadDialog = ({  
  title,
  children,  
  loading,
  onDropUpload,
  onDropRejected,
  acceptFiles,
  descriptionUpload
}) => {
  const [open, setOpen] = useState(false)  
  const classes = useStyles()
  const { t } = useTranslation()
  const show = () => (event) => {
    event.preventDefault()
    event.stopPropagation()

    setOpen(true)   
  }

  const hide = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setOpen(false)   
  } 

  return (
    <React.Fragment>
      {children(show)}
      {open && (
        <Dialog
          open={open}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.rootDialog}
        >
          <Typography variant="body1" style={{marginBottom: 20}}>
              {title}            
          </Typography>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Dropzone
              onDrop={file => {
                onDropUpload(file)
                setOpen(false)
              }}
              accept={acceptFiles}
              maxSize={2000000}
              onDropRejected={onDropRejected}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={classes.rootDropzone}>
                  <div className='content'>
                    <input {...getInputProps()} />
                    {loading ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        <img
                          src={imgUpload}
                          width={50}
                          alt={descriptionUpload}
                        />
                        <br />
                        <Typography variant="body2">
                          {descriptionUpload}
                        </Typography>
                      </>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </Grid>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 20 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={hide}
            >
              {t("common:Voltar")}
            </Button>
          </div>
        </Dialog>
      )}
    </React.Fragment>
  )
}

UploadDialog.defaultProps = { 
  children: () => { },
  title: "",
  description: "",
  acceptFiles: '',
  descriptionUpload: '',
  loading: false,
  hideCallback: () => { },  
  onDropUpload: () => { },
  onDropRejected: () => { },  
}

UploadDialog.propTypes = {
  children: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  acceptFiles: PropTypes.string,
  descriptionUpload: PropTypes.string,
  loading: PropTypes.bool,
  hideCallback: PropTypes.func, 
  onDropUpload: PropTypes.func,
  onDropRejected: PropTypes.func,
  
};

export default UploadDialog
