import React, { useState, useCallback, useEffect } from "react"
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector, useDispatch } from "react-redux"
import { useStyles } from "./styles";
import _ from 'lodash'
import {
    ListItem,
    Typography,
    Collapse,
    Checkbox,
    Tooltip,
} from "@material-ui/core"

import ExpandLess from "@material-ui/icons/ExpandLess"
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';

import { Creators as ItemsActions } from "flux/ducks/Items"
import { useDataItems } from 'pages/DAM/Items/context'
import { slugify } from 'utils/Helpers'

const filterValueToMetadataSlug = (text = "") => {
    return slugify(text.replaceAll("filter_value.", "").replaceAll("_", "-"))
}

function StyledCheckbox({ valueMeta, idMetaValue, loading, handleToggle, filtersOptions = [] }) {
    const classes = useStyles()
    const findMeta = filtersOptions.find(i => filterValueToMetadataSlug(_.get(i, 'id', '')) === `${idMetaValue}-${slugify(valueMeta)}`)

    return (
        <Checkbox
            className={classes.rootCheckBox}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            checked={Boolean(findMeta)}
            disabled={_.get(findMeta, 'disabled', loading)}
            onClick={() => handleToggle(valueMeta, `${idMetaValue}-${slugify(valueMeta)}`)}
        />
    );
}

const MetadataView = ({
    metaValue,
    loading
}) => {
    const [open, setOpen] = useState(false)
    const { filtersOptions } = useSelector((state) => state.itemsReducer)
    const { getRenewedData, getParamsToItems } = useDataItems()
    const classes = useStyles()
    const dispatch = useDispatch()

    const handleClick = () => {
        setOpen(prev => !prev)
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted && !open && filtersOptions.length > 0) {
            const data = _.get(metaValue, 'list_value', []).map(i => `${metaValue.id}-${slugify(_.get(i, "value", _.get(i, "label", "")))}`)
            const setIsOpen = filtersOptions.some(element =>
                data.some(i => i === filterValueToMetadataSlug(_.get(element, 'id', ''))))
            setOpen(setIsOpen)
        }
        return () => { isMounted = false }
    }, [filtersOptions, open, metaValue])

    const handleToggle = useCallback((value, id) => {
        const name = `filter_value.${metaValue.id}`
        const parameters = getParamsToItems()
        let options = []
        if (filtersOptions.some(item => item.id === id)) {
            options = filtersOptions.filter(item => item.id !== id)
        } else {
            options = [...filtersOptions, { [name]: value, id }]
        }
        dispatch(
            ItemsActions.setSession({
                name: "filtersOptions",
                data: options,
            })
        )
        getRenewedData({ ...parameters, filtersOptions: [...options] })
    }, [metaValue.id, filtersOptions, dispatch, getParamsToItems, getRenewedData])

    return (
        <>
            <ListItem button onClick={handleClick}>
                {open ? <ExpandLess /> : <KeyboardArrowRightRoundedIcon />}

                <Tooltip title={_.get(metaValue, 'name', '')} placement="top">
                    <div className="title">
                        <Typography variant="body2" style={{ margin: '0 5px' }} noWrap>
                            {_.get(metaValue, 'name', '')}
                        </Typography>
                    </div>
                </Tooltip>

                <Typography variant="body2" noWrap>
                    {`(${_.get(metaValue, 'list_value', []).length})`}
                </Typography>

            </ListItem>

            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                className={classes.rootListValues}
            >
                {
                    _.get(metaValue, 'list_value', []).map((i, key) => (
                        <div className={classes.rowListValues} key={key}>
                            <StyledCheckbox
                                valueMeta={i.value}
                                idMetaValue={metaValue.id}
                                loading={loading}
                                handleToggle={handleToggle}
                                filtersOptions={filtersOptions}
                            />
                            <Tooltip title={i.label} placement="top">
                                <Typography variant="body2" className='title' noWrap>
                                    {i.value}
                                </Typography>
                            </Tooltip>
                            <Typography variant="body2" style={{ marginLeft: 5 }} noWrap>
                                {`(${i.quantity})`}
                            </Typography>
                        </div>
                    ))
                }
            </Collapse>
        </>
    )
}

MetadataView.defaultProps = {
    metaValue: {
        label: PropTypes.string,
        value: PropTypes.string,
        quantity: PropTypes.number,
    },
    getData: () => { },
}

MetadataView.propTypes = {
    metaValue: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        quantity: PropTypes.number,
    }),
    getData: PropTypes.func,
}

export default MetadataView