import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    Menu,
    Typography,
    MenuItem,
    makeStyles,
    Button,
    Tooltip
}
    from '@material-ui/core'
import { ReactComponent as ItemVisualizationIcon } from '../../../imagens/item-visualization-icon.svg'
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';

const useStyles = makeStyles((theme) => ({
    rootButton: {
        '& span.MuiButton-startIcon': {
            margin: 0
        }
    },
    rootTitle: {
        padding: '6px 16px',
        color: theme.palette.primary.main,
        '& p': {
            fontWeight: 600,
            color: '#666'
        }
    },
}));

const DATA_ICONS = [
    { id: 0, icon: <ItemVisualizationIcon fill="#666" /> },
    { id: 1, icon: <Visibility /> },
    { id: 2, icon: <VisibilityOff /> }
]

const MenuSelectedView = ({
    title,
    options,
    selectedIndex,
    handleMenuItemClick,
    hasFilters,
    primaryColor
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()
    const { t } = useTranslation()

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, [])

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [])

    const handleClickMenu = useCallback((index) => {
        handleMenuItemClick(index)
        setAnchorEl(null);
    }, [handleMenuItemClick])

    const icon = useCallback(() => {
        const data = DATA_ICONS.find(i => i.id === selectedIndex)
        return data.icon
    }, [selectedIndex])

    return (
        <>
            <Tooltip title={t("common:Publicação dos Itens")} placement="top">
                <div className={classes.rootButton}>
                    <Button
                        style={selectedIndex > 0 ? {} : { color: '#666' }}
                        color={selectedIndex > 0 ? "primary" : "default"}
                        endIcon={
                            Boolean(anchorEl)
                                ? <KeyboardArrowUpRoundedIcon fontSize='small' />
                                : <KeyboardArrowDownRoundedIcon fontSize='small' />
                        }
                        startIcon={icon()}
                        onClick={handleClick}
                        disabled={!hasFilters}
                    />
                </div>
            </Tooltip>

            <Menu
                id="menu-selected"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.rootTitle}>
                    <Typography variant='body1' noWrap>
                        {title}
                    </Typography>
                </div>
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        disabled={index === selectedIndex}
                        selected={index === selectedIndex}
                        onClick={() => handleClickMenu(option)}
                        style={{ color: index === selectedIndex && primaryColor, opacity: 1 }}
                    >
                        {
                            option.label.includes('Publicados') ? <Visibility style={{ color: index === selectedIndex ? primaryColor : '#666', marginRight: 5 }} /> :
                                option.label.includes('Despublicados') ? <VisibilityOff style={{ color: index === selectedIndex ? primaryColor : '#666', marginRight: 5 }} /> :
                                    <ItemVisualizationIcon fill={index === selectedIndex ? primaryColor : '#666'} style={{ marginRight: 5 }} />

                        }
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

MenuSelectedView.defaultProps = {
    primaryColor: '#666',
    hasFilters: false,
    title: '',
    options: [],
    selectedIndex: 0,
    handleMenuItemClick: () => { }
}

MenuSelectedView.propTypes = {
    primaryColor: PropTypes.string,
    hasFilters: PropTypes.bool,
    title: PropTypes.string,
    options: PropTypes.array,
    selectedIndex: PropTypes.number,
    handleMenuItemClick: PropTypes.func,
}

export default MenuSelectedView