import React from 'react'

import ToolsList from './ToolsList'
import RequestToolsProvider from './context'

const RequestToolsList = (props) => {
    return (
        <RequestToolsProvider>
            <ToolsList {...props} />
        </RequestToolsProvider>
    )
}

export default RequestToolsList