import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'

import {
    Menu,
    FormGroup,
    makeStyles,
    Tooltip,
    Button,
    FormControlLabel,
    Checkbox,
    Typography
} from '@material-ui/core';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';

const useStyles = makeStyles((theme) => ({
    rootButton: {
        '& span.MuiButton-startIcon': {
            margin: 0
        }
    },
    rootTitle: {
        padding: '6px 16px',
        color: '#666',
        '& p': {
            fontWeight: 500
        }
    },
}));

const MenuTypesView = ({
    title,
    options,
    selecteds,
    handleMenuItemClick,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()
    const { t } = useTranslation()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenu = (evt) => {
        evt.preventDefault()  
        const checked = evt.target.checked
        const field = evt.target.name
        handleMenuItemClick({ field, value: checked })
        setAnchorEl(null)
    }

    const handleClickAll = (evt) => {
        evt.preventDefault()       
        handleMenuItemClick({ field: 'ALL', value: true })
        setAnchorEl(null)
    }

    return (
        <>
            <Tooltip title={title} placement="top">
                <div className={classes.rootButton}>
                    <Button
                        style={selecteds.length > 0 ? {} : { color: '#666' }}
                        color={selecteds.length > 0 ? "primary" : "default"}
                        endIcon={
                            Boolean(anchorEl)
                                ? <KeyboardArrowUpRoundedIcon fontSize='small' />
                                : <KeyboardArrowDownRoundedIcon fontSize='small' />
                        }
                        onClick={handleClick}
                    >
                        {title}
                    </Button>
                </div>
            </Tooltip>

            <Menu
                id="menu-ordination"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.rootTitle}>
                    <Typography variant='body1' noWrap>
                        {t("DAM:Tipo de arquivo")}
                    </Typography>
                </div>
                
                <FormGroup style={{ padding: 10 }}>
                    <Button
                        disabled={selecteds.length === 0}
                        color='primary'
                        onClick={handleClickAll}
                        style={{justifyContent: 'flex-start'}}
                    >
                        {t("DAM:Limpar")}
                    </Button>
                    {
                        options.map(item => (
                            <FormControlLabel
                                key={item.value}
                                control={
                                    <Checkbox
                                        checked={selecteds.some(i => i === item.value)}
                                        onChange={handleClickMenu}
                                        name={item.value}
                                        color="primary"
                                    />
                                }
                                label={t(`DAM:${item.label}`)}
                                style={{ marginRight: 0 }}
                            />
                        ))
                    }
                </FormGroup>
            </Menu>
        </>
    )
}

MenuTypesView.defaultProps = {
    title: '',
    options: [],
    selecteds: [],
    handleMenuItemClick: () => { }
}

MenuTypesView.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    selecteds: PropTypes.array,
    handleMenuItemClick: PropTypes.func,

}

export default MenuTypesView