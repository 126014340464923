import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import { withTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import { Dialog, DialogContent, DialogActions,
  Button } from "@material-ui/core"
  import { useStyles } from "./styles"
import PreviewpdfContainer from "../../../../containers/PreviewpdfContainer"
import Utils from "../../../../utils/Utils"

const DialogDetailsAssets = ({ t, children, handleSetOpen, dataAsset }) => {
  const { requestHeaders, pathServer, triggerError } = useSelector(
    (state) => state.appReducer
  )

  const [open, setOpen] = useState(null)
  const [streamingURL, setStreamingURL] = useState({})
  const classes = useStyles()

  const show = () => (event) => {
    event.preventDefault()
    handleSetOpen()
    getLinkForStreaming()
    setOpen(event.currentTarget)
  }

  const hide = () => {
    handleSetOpen()
    setOpen(null)
  }

  const getLinkForStreaming = () => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const options = { ...requestHeaders, cancelToken: source.token }

    axios
      .get(
        `${pathServer}/asset/${dataAsset.idAsset}/generate/streaming/${dataAsset.idItem}`,
        options
      )
      .then((resp) => {
        if (resp.data.url) {
          setStreamingURL({
            asset: dataAsset.idAsset,
            url: resp.data.url,
          })
        }
      })
      .catch((err) => triggerError(err))
  }

  const componentForExtension = (extension) => {
    const hasStreamingURL = streamingURL.asset === dataAsset.idAsset
    if (hasStreamingURL) {
      if (extension === "pdf") {
        return <PreviewpdfContainer file={streamingURL.url} />
      }

      if (Utils.enabledExtensionsVideos(extension)) {
        return (
          <ReactPlayer
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            playing={true}
            controls={true}
            playsinline
            muted
            url={streamingURL.url}
            onContextMenu={(e) => e.preventDefault()}
            className={classes.video}
            onError={(e) => {
              if(e.target.error.code === 2) {
                getLinkForStreaming()
              }
            }}
          />
        )
      }
    }
  }

  return (
    <Fragment>
      {children(show)}
      {open && (
        <Dialog open={Boolean(open)} onClose={hide} maxWidth="lg"
        style={{maxHeight: '100%'}}
        >
          <DialogContent style={{padding: '40px 40px 5px 40px'}}>
            {componentForExtension(dataAsset.extension)}
          </DialogContent>
          <DialogActions style={{padding: '10px 40px 40px 40px'}}>
              <Button variant="outlined" color="primary"
              onClick={hide}>
                {t(`DAM:Fechar`)}
              </Button>

         </DialogActions>
        </Dialog>
      )}
    </Fragment>
  )
}

DialogDetailsAssets.defaultProps = {
  handleSetOpen: () => {},
}

export default withTranslation(["DAM"])(DialogDetailsAssets)
