import React from "react";
import axios from "axios/index";

import Dropzone from 'react-dropzone'
import Template from "../../Template";
import imgUpload from "../../../../imagens/ui/ico-upload.svg";
import ErrorBoundary from "../../../../components/Core/ErrorBoundary";
import Settings from 'utils/Settings'
import ImageLazyLoad from "../../../../components/ImageLazyLoad"
import {
    Grid,
    Typography,
    Button,
    CircularProgress,   
    Divider
} from "@material-ui/core";

import { uuid } from 'uuidv4'
import _ from 'lodash'

import { Content } from '../../Clients/partials/Content'
import UploadDialog from '../../Core/UploadDialog'

class Upload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
        };
        this.handleRemovePicture = this.handleRemovePicture.bind(this);
        this.handleDataState = this.handleDataState.bind(this)
        this.onDropRejected = this.onDropRejected.bind(this);
        this.onDropUpload = this.onDropUpload.bind(this)
    }

    async getDataAll(id) {
        const { pathServer, requestHeaders, t, enqueueSnackbar } = this.props
        let dataResult = []

        const data = Settings.DATA_SUPPORTED_LNG.map(i =>
        ({
            url: `${pathServer}/${this.state.entity}/${id ? id : this.state.id}/thumbnail/${i.lng}`,
            lng: i.lng, name: i.name
        })
        )

        for (var key in data) {
            try {
                const result = await axios.get(data[key].url, requestHeaders)
                if (_.get(result.data, 'original', false) ||
                    _.get(result.data, 'webp', false)
                ) {
                    dataResult.push({
                        id: uuid(),
                        local: data[key].lng,
                        name_lng: data[key].name,
                        picturesPreview: result.data,
                        loading: false,
                    })
                } else {
                    dataResult.push({
                        id: uuid(),
                        local: data[key].lng,
                        name_lng: data[key].name,
                        picturesPreview: '',
                        loading: false,
                    })
                }
            } catch (err) {
                this.setState({ ...this.state, loading: false });
             
                enqueueSnackbar(t('Erro ao carregar os dados, contate o suporte'), {
                    variant: 'error',
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  }) 
            }
        }

        if (dataResult.length > 0) {
            this.setState({
                ...this.state,
                id,
                loading: false,
                data: dataResult
            });
        }
    }

    componentDidMount() {
        const ID = this.props.match.params.id;
        this.setState({ ...this.state, slug: ID });

        if (ID) {
            this.getDataAll(ID)
        }
    }

    getUrl(path) {
        const { pathServer } = this.props
        return `${pathServer}/${path}`
    }


    handleDataState(chave, value, id) {
        const newState = { ...this.state }

        if (Array.isArray(chave) && Array.isArray(value)) {
            const newData = newState.data.map(i => {
                if (i.id === id) {
                    for (let index in chave) {
                        i[chave[index]] = value[index]
                    }
                }
                return i
            })

            return newData
        } else {
            const newData = newState.data.map(i => {
                if (i.id === id) {
                    i[chave] = value
                }
                return i
            })
            return newData
        }

    }

    onDropUpload(files, id) {
        const SELF = this;
        const newState = { ...this.state }

        const {enqueueSnackbar, t} = SELF.props

        if(files.length <= 0) {
            return      
        }

        const item = newState.data.find(i => i.id === id)

        const uploaders = files.map(file => {

            SELF.setState({ ...newState, data: this.handleDataState('loading', true, id) });

            const formData = new FormData();
            formData.append("thumbnail", file);
            formData.append("local", item.local);
            const url = this.getUrl(`${SELF.state.entity}/import/${SELF.state.id}`);
            return axios.post(url, formData,
                {
                    headers: (this.props.requestHeaders)["headers"],
                    onUploadProgress: progressEvent => {
                        let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        console.log("percentCompleted", percentCompleted);
                    }
                }).then(resp => {
                    SELF.setState({
                        ...newState,
                        data: this.handleDataState('picturesPreview', {
                            webp: resp.data.thumbnail,
                            original: resp.data.thumbnail
                        }, id)
                    });

                   
                    enqueueSnackbar(resp.headers['x-message'], {
                        ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                    });
                }, () =>                 
                enqueueSnackbar(t('Erro ao carregar os dados, contate o suporte'), {
                    variant: 'error',
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  })                 
                )
        });

        axios.all(uploaders).then(() => {
            SELF.setState({ ...newState, data: this.handleDataState('loading', false, id) });
        });
    }

    onDropRejected() {
        const { t, enqueueSnackbar } = this.props;
      
        enqueueSnackbar(t('Arquivo inválido. Arquivo até 2MB em JPG ou PNG'), {
            variant: 'error',
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          })  
    }

    handleRemovePicture(event, id) {
        event.preventDefault();

        const SELF = this;
        const newState = { ...this.state }
        const { t, enqueueSnackbar } = SELF.props;

        SELF.setState({ ...newState, data: this.handleDataState('loading', true, id) })
        const item = this.state.data.find(i => i.id === id)

        const postSuccess = () => {
            SELF.setState({ ...newState, data: this.handleDataState(['loading', 'picturesPreview'], [false, ''], id) })
        }

        const postError = () => {
            SELF.setState({ ...newState, data: this.handleDataState('loading', false, id) })
        }

        const url = this.getUrl(`${SELF.state.entity}/remove/${SELF.state.id}/thumbnail`);
        axios.post(url, { local: item.local }, this.props.requestHeaders)
            .then(function (resp) {
                postSuccess()
            
                enqueueSnackbar(t('Miniatura deletada com sucesso.'), {
                    ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS
                });
            })
            .catch(function (err) {
                postError()               
                enqueueSnackbar(t('Erro ao deletar, contate o administrador.'), {
                    variant: 'error',
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  })
            });


    }


    render() {
        const { t, classes } = this.props;

        return (
            <Template area={this.state.area} localHistory={this.props.history} customClassName={"container-center"}>
                <ErrorBoundary>
                    <Content title={"manager:Adicionar miniatura"}>
                        <Divider style={{ marginBottom: 20 }} />
                        <Typography variant='body2' style={{ marginBottom: 0 }}>
                            {t("manager:Selecione a miniatura correspondente ao idioma desejado.")}
                        </Typography>
                        <Typography variant='body2' style={{ marginBottom: 20 }}>
                            {`${t('common:Especificações')}: PNG, JPG; 600px x 600px; ${t('common:tamanho máximo')} 2MB.`}                            
                        </Typography>
                        {
                            this.state.loading &&
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 200
                            }}>
                                <CircularProgress />
                            </div>
                        }
                        {
                            this.state.data.length > 0 &&
                            this.state.data.map((item, key) => (
                                <div key={key}>
                                    <Grid item xs={12} style={{ margin: '20px 0' }}>
                                        <Typography variant="body1">
                                            {item.name_lng}
                                        </Typography>
                                    </Grid>

                                    <Dropzone
                                        onDrop={(files) => this.onDropUpload(files, item.id)}
                                        accept="image/jpeg, image/png"
                                        maxSize={2000000}
                                        onDropRejected={this.onDropRejected}
                                        multiple={false}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className={classes.rootDropzone}>
                                                <div className='content'>
                                                    <input {...getInputProps()} />
                                                    {item.loading ? (
                                                        <div>
                                                            <CircularProgress />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                item.picturesPreview ? (
                                                                    <div className='image'>
                                                                        <ImageLazyLoad
                                                                            images={{
                                                                                imgJPG: item.picturesPreview.original, 
                                                                                imgWEBP: item.picturesPreview.webp
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <img
                                                                            src={imgUpload}
                                                                            width={50}
                                                                            alt={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                                        />
                                                                        <br />
                                                                        <Typography variant="body2">
                                                                            {t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                                        </Typography>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                        )}
                                    </Dropzone>

                                    <Grid item xs={12} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        margin: '20px 0',
                                    }}>
                                        {
                                            item.picturesPreview &&
                                            <>
                                                <Button
                                                    style={{ height: 40, width: 115, marginRight: 20 }}
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={!item.picturesPreview}
                                                    onClick={(evt) => this.handleRemovePicture(evt, item.id)}
                                                >
                                                    {t("common:Excluir")}
                                                </Button>
                                                <UploadDialog
                                                    t={t}
                                                    title={t("manager:Adicionar miniatura")}
                                                    descriptionUpload={t("manager:Clique aqui ou arraste uma imagem para enviar")}
                                                    onDropUpload={this.onDropUpload}
                                                    onDropRejected={this.onDropRejected}
                                                    loading={item.loading}
                                                    acceptFiles="image/jpeg, image/png"
                                                >
                                                    {(confirm) => (
                                                        <Button
                                                            variant={"contained"}
                                                            color="primary"
                                                            style={{ height: 40 }}
                                                            onClick={confirm()}
                                                        >
                                                            {t("manager:Adicionar miniatura")}
                                                        </Button>
                                                    )}
                                                </UploadDialog>
                                            </>
                                        }
                                    </Grid>

                                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                                        <Divider />
                                    </Grid>

                                </div>
                            ))
                        }


                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button
                                style={{ height: 40, width: 115 }}
                                variant="contained"
                                color="primary"
                                onClick={() => this.props.history.goBack()}>
                                {t("common:Voltar")}
                            </Button>
                        </div>

                    </Content>
                </ErrorBoundary>
            </Template>
        );
    }
}

export default Upload
