import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import i18n from "i18next"
import moment from "moment"

import {
    Button,
    Typography,
    IconButton
} from '@material-ui/core'


import * as locales from 'react-date-range/dist/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { makeStyles } from "@material-ui/core/styles"

import Utils from 'utils/Utils'

import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

export const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 10,
        '& button#submit': {
            marginLeft: 25
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'flex-start',
        }
    },
    icon: {
        marginLeft: 5,
        cursor: 'pointer',
        '& svg': {
            padding: 2
        },
        '& :hover': {
            backgroundColor: '#eee',
            borderRadius: 4
        }
    },
    content: {
        display: 'flex',
        '& .field-date': {
            display: 'flex',
            justifyContent: 'center',
            color: '#1d2429'
        },
        "& #font500": {
            fontWeight: 500,
            marginRight: 5
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        }
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        padding: '7px 10px 20px 10px',
        justifyContent: 'space-between',
        '& h6': {
            fontWeight: 700,
            color: '#666',

        },
        '& .close-mobile': {
            display: 'none'
        },
        [theme.breakpoints.down("sm")]: {
            '& .close-mobile': {
                display: 'block'
            }
        }
    }
}))

export default function DateFilter({ handleSubmit, startDate, endDate, handleClose }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const { primaryColor } = useSelector((state) => state.appReducer.data)
    const [dataRange, setDataRange] = useState({ start: null, end: null, error: '' })

    const getExtendedDate = (date) => {
        const dateMoment = moment(date)
        const formatDate = new Date(dateMoment.format())
        return formatDate
    }

    const handleChangeDate = (field, value) => {
        setDataRange(prev => ({ ...prev, [field]: value }))
    }

    useEffect(() => {
        if (Boolean(startDate)) {
            handleChangeDate('start', getExtendedDate(startDate))
        } else {
            handleChangeDate('start', null)
        }

        if (Boolean(endDate)) {
            handleChangeDate('end', getExtendedDate(endDate))
        } else {
            handleChangeDate('end', null)
        }
    }, [startDate, endDate])

    const handleClear = () => {
        setDataRange({ start: null, end: null, error: null })
    }

    const handleValidAndSubmit = (evt) => {
        evt.preventDefault()
        const isValidStart = moment(dataRange.start)
        const isValidEnd = moment(dataRange.end)

        let isValid = true

        if (isValidStart.isAfter(isValidEnd, 'day')) {
            handleChangeDate('error', t('common:Data inicial maior que a data final'))
            isValid = false
        }

        if (isValid) {
            const { start, end } = dataRange
            handleSubmit({ start, end })
            handleChangeDate('error', null)
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {t('common:Período')}
                </Typography>

                <div className='close-mobile'>
                    <IconButton aria-label="CloseRoundedIcon" onClick={() => handleClose()}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>
            </div>
            <div className={classes.content}>
                <div>
                    <div className='field-date'>
                        <Typography variant="body2" id='font500'>
                            {t('common:Data inicial')}:
                        </Typography>

                        <Typography variant="body2">
                            {dataRange.start ? Utils.formatDate(dataRange.start) : t('common:Nenhuma data selecionada')}
                        </Typography>
                    </div>
                    <Calendar
                        onChange={item => handleChangeDate('start', item)}
                        locale={locales[i18n.language]}
                        date={dataRange.start}
                        color={primaryColor}
                        style={{ borderRight: '2px solid #eee' }}
                    />
                </div>

                <div>
                    <div className='field-date'>
                        <Typography variant="body2" id='font500'>
                            {t('common:Data final')}:
                        </Typography>

                        <Typography variant="body2">
                            {dataRange.end ? Utils.formatDate(dataRange.end) : t('common:Nenhuma data selecionada')}
                        </Typography>
                    </div>
                    <Calendar
                        onChange={item => handleChangeDate('end', item)}
                        locale={locales[i18n.language]}
                        date={dataRange.end}
                        color={primaryColor}
                    />
                </div>
            </div>

            <div className={classes.actions}>
                <div>
                    {
                        Boolean(dataRange.error) &&
                        <Typography variant="body1" style={{ color: '#f44336' }}>
                            * {dataRange.error}
                        </Typography>

                    }
                </div>
                <div>
                    <Button color='primary' onClick={handleClear}>
                        {t('DAM:Limpar')}
                    </Button>
                    <Button color='primary' onClick={handleValidAndSubmit} id='submit' disabled={Boolean(dataRange.error)}>
                        {t('common:Enviar')}
                    </Button>
                </div>
            </div>
        </div>
    );
}