import React, { Fragment } from "react"
import { withTranslation } from "react-i18next"

import { Paper, Typography, Button } from "@material-ui/core"

import { useStyles } from "../styles"

const RouteInvalid = ({ data, t, handleHistory, userAuth }) => {
  const classes = useStyles(data)

  return (
    <Fragment>
      <Paper elevation={2} className={classes.content}>
        <div className="logoImageLogin" />

        <div className="containerText">
          <Typography>{t("login:Rota inválida.")}</Typography>
        </div>
        {userAuth ? (
          <Button
            style={{
              width: 250,
              minHeight: 50,
              borderRadius: 4,
            }}
            variant="contained"
            color="primary"
            onClick={() => handleHistory("/dam")}
          >
            {t("login:Ir para o DAM")}
          </Button>
        ) : (
          <button 
          className="btnLink" 
          onClick={() => handleHistory("/login")}
          style={{
            border: 'none',
            background: 'transparent',
            textAlign: 'left',
            padding: 0,
            fontFamily: 'inherit',
          }}          
          >
            {t("login:Voltar para login")}
          </button>
        )}
      </Paper>
    </Fragment>
  )
}

export default withTranslation(["common", "login"])(RouteInvalid)
