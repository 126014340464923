import React, { useState, Fragment, useEffect, useCallback } from "react";
import _ from 'lodash'
import axios from "axios";

import { useSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import SharedComponent from "./SharedComponent"
import DialogDetails from './SharedComponent/Details'
import useIsMounted from "components/IsMounted";

import Settings from 'utils/Settings'
import Utils from "utils/Utils";
import { useStyles } from "./styles"

const ItemSharedContainer = ({ t, match }) => {
  const { client_slug, platform_name, originalBg, logoLoginJPG } = useSelector((state) => state.appReducer.data);
  const requestHeaders = useSelector((state) => JSON.stringify(state.appReducer.requestHeaders));
  const [state, setState] = useState({
    responseStatus: null,
    loading: false,
    token: null,
    pathServer: null,
    open_dialog: false,
    checked_can_save: false,
    loadingAsset: []
  })
  const isMounted = useIsMounted();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const classes = useStyles({ originalBg, logoLoginJPG })

  const getDataAssets = useCallback(() => {
    if (_.get(state, "can_save_assets_individually", false)) {
      if (_.get(state, 'checked_can_save', false)) {
        setState(prev => ({ ...prev, open_dialog: true }))
      } else {
        axios.get(`${Settings.URL_API}/share/${state.token}/files`, JSON.parse(requestHeaders))
          .then(({ data }) => {
            let DATA = {}
            DATA.dataAssets = data.items
            DATA.reference = data.reference
            DATA.client = data.client

            setState(prev => ({
              ...prev,
              ...DATA,
              open_dialog: true,
              checked_can_save: true
            }))
          }).catch((err) => {
            setState(prev => ({
              ...prev,
              loading: false,
              checked_can_save: true
            }), console.log("Erro ao obter os dados.", err))
          })
      }
    } else {
      setState(prev => ({ ...prev, open_dialog: true }))
    }
  }, [requestHeaders, state])

  const getData = useCallback((token) => {
    axios.get(`${Settings.URL_API}/shareditem/by/link/${token}`, JSON.parse(requestHeaders))
      .then(({ data }) => {
        let DATA = { ...data }

        if (_.get(data, "asset_id", "")) {
          DATA["name"] = `${data.name} ${data.asset_key} ${data.versionName}`
        } else {
          DATA["name"] = `${data.name} ${data.versionName}`
        }

        setState(prev => ({
          ...prev,
          ...DATA,
          responseStatus: Settings.RESPONSE_STATUS.NOT_SENT,
          loading: false
        }))
      }).catch((err) => {
        setState(prev => ({
          ...prev,
          responseStatus: Settings.RESPONSE_STATUS.FAILED,
          loading: false
        }), console.log("Erro ao obter os dados para download", err))
      })
  }, [requestHeaders])

  const showMessage = useCallback((message, type) => {
    return (
      enqueueSnackbar(
        message,
        {
          variant: type,
          autoHideDuration: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          }
        })
    )
  }, [enqueueSnackbar])

  const handleDownload = useCallback((itemName) => {
    const notificationKey = showMessage(t(`Autorizando o download`, { itemName }), 'info');
    const callback = () => {
      axios.get(`${state.pathServer}/token/${state.token}/generate/link`, {}, state.requestHeaders)
        .then(
          resp => {
            Utils.makeDownload(resp.data.url, () => {
              setTimeout(() => {
                setState(prev => ({ ...prev, loading: false, responseStatus: Settings.RESPONSE_STATUS.SUCCEEDED }))
                closeSnackbar(notificationKey)
              }, 500)
            })
          },
          error => showMessage(error.response.headers['x-message'], 'error')
        ).catch(err => {
          const message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading: false, responseStatus: Settings.RESPONSE_STATUS.FAILED }),
            showMessage(message, 'error')
          )
        });
    }
    setState(prev => ({ ...prev, open_dialog: false, loading: true }), callback())
  }, [closeSnackbar, state.pathServer, state.requestHeaders, showMessage, t, state.token])

  const handleOnlyDownload = useCallback((itemName, assetId) => {
    const notificationKey = showMessage(t(`Autorizando o download`, { itemName }), 'info');
    const callback = () => {
      axios.get(`${Settings.URL_API}/share/${state.client}/${state.token}/${state.reference}/${assetId}/url`, state.requestHeaders)
        .then(
          resp => {
            Utils.makeDownload(resp.data.link, () => {
              setTimeout(() => {
                setState(prev => ({
                  ...prev,
                  loadingAsset: prev.loadingAsset.filter(i => i !== assetId)
                }))
                closeSnackbar(notificationKey)
              }, 500)
            })
          }).catch(err => {
            const message = Utils.ajaxErrorGetMessage(err, t)
            setState(prev => ({ ...prev, loadingAsset: prev.loadingAsset.filter(i => i !== assetId) }),
              showMessage(message, 'error')
            )
          });
    }
    setState(prev => ({ ...prev, loadingAsset: [...prev.loadingAsset, assetId] }), callback())
  }, [
    closeSnackbar,
    state.requestHeaders,
    state.client,
    state.reference,
    showMessage,
    state.token,
    t
  ])

  const handleOpenDialog = () => {
    getDataAssets()
  }

  const handleCloseDialog = () => {
    setState(prev => ({ ...prev, open_dialog: false }))
  }

  useEffect(() => {
    if (isMounted.current && Boolean(_.get(match, 'params.token'))) {
      setState(prev => ({
        ...prev,
        pathServer: `${Settings.URL_API}/api/${client_slug}`,
        token: _.get(match, 'params.token'),
        loading: true
      }), getData(_.get(match, 'params.token')))
    }
  }, [client_slug, match, getData, isMounted])

  return (
    <Fragment>
      <Helmet>
        <title>Download Item</title>
      </Helmet>
      <div className={classes.root}>
        <SharedComponent
          platform_name={platform_name}
          downloadData={state}
          responseStatus={state.responseStatus}
          handleDownload={handleDownload}
          showLoading={state.loading}
          handleOpenDialog={handleOpenDialog}
        />
        <DialogDetails
          open={state.open_dialog}
          handleCloseDialog={handleCloseDialog}
          downloadData={state}
          handleDownload={handleDownload}
          handleOnlyDownload={handleOnlyDownload}
        />
      </div>
    </Fragment>
  );
}
export default withTranslation(['DAM'])(ItemSharedContainer);
