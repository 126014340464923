import React from "react"
import { Alert, AlertTitle } from "@material-ui/lab"

export default function SimpleAlertMessage({ severity, alertTitle, message, marginLeft }) {
  return (
    <Alert
      style={{ width: "98.2%", marginLeft: marginLeft ? marginLeft : "8px", marginTop: 30 }}
      severity={severity}
    >
      <AlertTitle>{alertTitle}</AlertTitle>
      {message}
    </Alert>
  )
}
