import React, { useState } from "react"
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, DialogTitle, makeStyles, TextField } from "@material-ui/core"
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import { useDataEditFiles } from './context'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paperWidthSm': {
            margin: '0 !important',
            padding: '40px',
            minWidth: '550px',
            [theme.breakpoints.down("sm")]: {
                minWidth: '100%'
            },
        }
    },
    title: {
        padding: 0,
        color: '#666',
        "& h2": {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            '& div.info': {
                display: "flex",
                alignItems: 'center',
            },
            "& svg#main": {
                width: "40px",
                height: "40px",
                backgroundColor: theme.palette.primary.main,
                borderRadius: "50%",
                padding: "4px",
                marginRight: "10px",
                color: "#fff",
            },
        },
    },
    actions: {
        display: "flex",
        justifyContent: "space-between",
        padding: '0',
        marginTop: '20px',
        "& button": {
            padding: "10px 16px",
            minWidth: "140px",
            height: "40px",
            maxHeight: "40px",
            fontWeight: 500,
        },
        "& button.delete": {
            "& svg": {
                color: "#757575",
            },
            "&:hover": {
                backgroundColor: "#F4511E",
                color: "#fff",
                "& svg": {
                    color: "#fff",
                },
            },
        }
    }
}));

const CommentDialog = ({ children }) => {
    const [descComment, setDescComment] = useState('')
    const [open, setOpen] = useState(false)
    const [errorComment, setErrorComment] = useState(false)
    const { t } = useTranslation()
    const classes = useStyles()
    const {
        dataComment,
        setDataComment,
        setActionsHistory,
    } = useDataEditFiles()

    const show = () => (event) => {
        event.preventDefault()
        event.stopPropagation()

        event = {
            ...event,
            target: { ...event.target, value: event.target.value },
        }
        setOpen(true)
    }

    const hide = (event) => {
        event.preventDefault()
        event.stopPropagation()

        setOpen(false)
    }

    const cancel = (event) => {
        event.preventDefault()
        event.stopPropagation()

        setDescComment('')
        setDataComment('')
        setOpen(false)
        setActionsHistory(prev => ([...prev, 'comment-not']))
    }

    const confirm = (event) => {
        event.preventDefault()
        event.stopPropagation()

        setDataComment(descComment)
        setOpen(false)
    }

    const handleChange = (event) => {
        event.preventDefault()
        event.stopPropagation()

        const value = event.target.value

        if(value.length > 255) {
            setErrorComment(true)
         } else {
            setErrorComment(false)
            setDescComment(value)
         }
         setActionsHistory(prev => ([...prev, 'comment-change']))
    }

    return (
        <React.Fragment>
            {children(show)}
            {open && (
                <Dialog
                    open={open}
                    onClose={hide}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.root}
                >
                    <DialogTitle id="alert-dialog-title" className={classes.title}>
                        <div className="info">
                            <CommentRoundedIcon id='main' />
                            <p>{t('DAM:Gostaria de deixar algum comentário na nova versão?')}</p>
                        </div>
                    </DialogTitle>

                    <DialogContent style={{ padding: "0" }}>
                        <TextField
                            id="description"
                            label={t("common:Comente aqui")}
                            multiline
                            rows={6}
                            onChange={handleChange}
                            value={descComment}
                            variant="outlined"
                            style={{width: '100%', marginTop: 5}}
                            error={errorComment}
                            helperText={
                                errorComment
                                ? t("common:Limite de 255 foi excedido")
                                : `${descComment.length}/255`
                            }
                        />
                    </DialogContent>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 20,
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={cancel}
                            style={{ marginRight: 20 }}
                        >
                            {t(`DAM:Sem comentário`)}
                        </Button>
                        <Button
                            onClick={confirm}
                            variant="contained"
                            color="primary"
                            disabled={errorComment || (dataComment === descComment)}
                        >
                            {t(`common:Adicionar`)}
                        </Button>
                    </div>
                </Dialog>
            )}
        </React.Fragment>
    )
}

CommentDialog.propTypes = {
    children: PropTypes.func,
}


export default CommentDialog
