import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      zIndex: 1300,
      [theme.breakpoints.down("sm")]: {
        height: 'auto',
        top: 0,
      }
    },
    paper: {
      width: '85%',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        minHeight: '100vh',
        borderRadius: 0,
      }
    },
    paperHeader: {
      padding: 20,
      color: '#666',
      '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',       
        '& > div': {
          display: 'flex',
          alignItems: 'center',
        },
        '& svg': {
          color: '#666',
        },
        '& h6': {
          marginLeft: 10
        }
      },
      '& .sub_header': {
        marginTop: 10
      }
    },
    paperContent: {
      padding: 20, 
      paddingTop: 0,   
      [theme.breakpoints.down("sm")]: {        
        paddingBottom: 80,        
      },
      '& p.title':{
        marginBottom: 20,
        fontWeight: 500,
        color: '#666'
      },
      "& .MuiFormControl-root": {
        width: '100%'
      },
      "& #btn_content": {
        height: 'calc(100vh - 350px)',
        "& .MuiGrid-root": {
          width: '100%',
          margin: 0
        }
      }
    },
    grid_row: {
        display: 'flex',
        alignItems: 'center',
        color: '#666',
        cursor: 'pointer',
        padding: 10,
        '& .icon': {
            marginRight: 10,
            display: 'flex'
        },
        "&:hover": {
            backgroundColor: "#F0F0F0",            
        }, 
    }
  }))