import React from 'react'
import _ from 'lodash'
import ItensFilterBar from 'components/ItensFilterBar'

import { useDataItems } from './context'

const ItemsFilters = () => {
    const { totalItems, showItemsSelected } = useDataItems()
    return <ItensFilterBar totals={_.get(totalItems, "total", 0)} isPageItens={!showItemsSelected} />
}
export default ItemsFilters