import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Fab, Paper, Tooltip, Zoom } from "@material-ui/core"

import AddIcon from "@material-ui/icons/Add"

import ErrorBoundary from "components/Core/ErrorBoundary"
import Template from "../../Template"

const ListWrapper = ({
    children,
    area,
    title,
    route,
    hasFab,
}) => {
    const history = useHistory()
    const {t} = useTranslation()

    return (
        <Template
            area={area}
            localHistory={history}
            customClassName={"container-center"}
        >
            <ErrorBoundary>
                <Paper elevation={2} style={{ height: 800, width: "100%" }}>
                    {children}
                </Paper>
                {hasFab && (
                    <Tooltip
                        title={`${t('common:Incluir')} ${t(`common:${title}`)}`}
                        placement="left"
                        TransitionComponent={Zoom}
                    >
                        <Fab
                            style={{
                                position: "fixed",
                                zIndex: 999,
                                bottom: 85,
                                right: 20,
                            }}
                            color="primary"
                            onClick={() =>
                                history.push(`/manager/${route}/new`)
                            }
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                )}
            </ErrorBoundary>
        </Template>
    )
}

export default ListWrapper