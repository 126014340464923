import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core"
import BookmarkBorderRoundedIcon from '@material-ui/icons/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';

import { Creators as AssetActions } from "flux/ducks/Asset"

const BookmarkAsset = ({ asset, isDisabled }) => {
    const dispatch = useDispatch()
    const { data } = useSelector(state => state.assetReducer)
    const { t } = useTranslation()

    const checkedValue = data.some(i => i.id === asset.id)

    const handleClick = (evt) => {
        evt.preventDefault()
        
        if (!checkedValue) {
            dispatch(AssetActions.setSelectedAsset(asset))
        } else {
            dispatch(AssetActions.removeSelectedAsset(asset))
        }
    }

    

    return (
        <ListItem button onClick={evt => handleClick(evt)} disabled={isDisabled}>
            <ListItemIcon>
                {
                    checkedValue ? <BookmarkRoundedIcon color="primary" /> : <BookmarkBorderRoundedIcon style={{ color: '#666' }} />
                }
            </ListItemIcon>
            <ListItemText primary={t("DAM:Selecionar ativo")} />
        </ListItem>
    )
}

export default BookmarkAsset