
import ComboBox from "./index.js";
import _ from "lodash";
import { withTranslation } from "react-i18next"

class TaxonomyAttributeComboBox extends ComboBox {
  constructor(props) {
    super(props);
    this.state = {
      entity: "taxonomy-attribute",
      title: props.title || "Atributos",
      data: this.props.data
    }

    this.getData = this.getData.bind(this);
    this.getSelectValue = this.getSelectValue.bind(this);
  }

  getData() {
    this.setState({
      data: this.props.data,
      initial_data_string: JSON.stringify(this.props.data)
    })
  }

  getSelectValue(object) {
    return {
      disabled: _.get(object, "disabled", false),
      label: _.get(object, "label", "No Name"),
      value: _.get(object, "value", '')
    }
  }

  componentDidUpdate() {
    const current_data_string = JSON.stringify(this.props.data)
    if (current_data_string !== this.state.initial_data_string) {
      this.getData()
    }
  }

}

export default (withTranslation(["manager", "common"])(TaxonomyAttributeComboBox))
