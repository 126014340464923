import React from 'react';

import icoLoading from './ico-loading.svg';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  generalLoading: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.55)',
    zIndex: 1100,    
    transition: 'all 0.3s ease-in-out',    
    visibility: props => props.show ? 'visible' : 'hidden',
    opacity: props => props.show ? 1 : 0,    
    '& .local': {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        width: 60,
        height: 60,
      },
      '& p': {
        position: 'absolute',
        top: '48.5%',
        left: 0,
        textAlign: 'center',
        right: 0,
        bottom: 0,
        fontSize: 14,
        fontWeight: 600,
        color: '#b4b4b4',
      }
    }
  }
}))

const Loading = ({ showLoading }) => {
  const classes = useStyles({show: showLoading})
 
  return (
    <div className={classes.generalLoading}>
      <img src={icoLoading} alt="Carregando" width="50"/>
    </div>
  );
  
}

export default Loading;