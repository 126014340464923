import React, { useCallback } from 'react';
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import CategoryAttributes from 'components/CategoryAttributes'
import PaperCustom from '../PaperCustom';
import { useDataFormItem } from "../context"

const Metadata = ({
    defaultData = {},
}) => {
    const { segments: state, changeAttributes, isEdit } = useDataFormItem()
    const handleChangeAttributes = useCallback((id, value) => {
        const newAttributes = { ...state.dataFields }
        Object.entries(newAttributes).forEach(([key, item]) => {
            if (key === id) {
                newAttributes[key] = value
            }
        })
        changeAttributes(newAttributes, id)
    }, [state.dataFields, changeAttributes])

    const getDisableCategory = useCallback(() => {
        let disabled = isEdit
        const category_initial = _.get(defaultData, 'categoryData', _.get(defaultData, 'category', {}))
        if (isEdit) {
            disabled = _.get(state.categoryData, 'value', '') === _.get(category_initial, 'value', '')
        }
        return disabled
    }, [state.categoryData, defaultData, isEdit])

    return (
        <Grid item xs={12} id="metadata">
            <PaperCustom title='Metadados'>
                <Grid container spacing={2}>
                    <CategoryAttributes
                        attributes={_.get(state, 'attributes', [])}
                        dataFields={_.get(state, 'dataFields', {})}
                        errorFields={_.get(state, 'errorFields', {})}
                        changeAttributes={handleChangeAttributes}
                        isDisabled={getDisableCategory()}
                    />
                </Grid>
            </PaperCustom>
        </Grid>
    );
}

export default React.memo(Metadata)
