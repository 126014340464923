import React, { useState, useCallback } from "react"
import { Paper, IconButton, Typography, CircularProgress } from "@material-ui/core"

import InputAutocomplete from './InputAutocomplete'

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ReactComponent as ArrowSVG } from './icons/arrow.svg'

import { useStyles } from "./styles"

const ButtonDemarcation = ({ submit, dataBox = {}, modelType }) => {
    const [label, setLabel] = useState({ name: "" })
    const [loading, setLoading] = useState(false)

    const classes = useStyles()

    const handleSubmit = useCallback((evt, transition = "", addLabel = true) => {
        evt.stopPropagation()
        if (transition) {
            const value_label = label.name.length > 0 ? label.name : dataBox.label
            const callbackError = () => setLoading(false)
            const callback = () => submit({
                transition,
                boundingBoxIndex: dataBox.key,
                ...(addLabel && { label: value_label }),
                ...(label.id && { id: label.id }),
                ...(dataBox.labelId && { labelId: dataBox.labelId })
            }, callbackError)

            setLoading(true, callback())
        }
    }, [label.name, label.id, submit, dataBox.key, dataBox.label, dataBox.labelId])

    const handleChange = useCallback((value) => {
        setLabel(value)
    }, [])

    if (loading) {
        return (
            <div
                className="root-input"
                id={dataBox.id}
                style={{ visibility: "hidden", position: "relative", zIndex: 10 }}>
                <Paper elevation={2} className={classes.paperAction}>
                    <ArrowSVG className={classes.triangleUp} />
                    <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", width: "100%" }}>
                        <CircularProgress color="primary" size="30px" />
                    </div>
                </Paper>
            </div>
        )
    }

    if (dataBox.marking === "Identificado") {
        return (
            <div
                className="root-input"
                id={dataBox.id}
                style={{ visibility: "hidden", position: "relative", zIndex: 10 }}>
                <Paper elevation={2} className={classes.paperAction}>
                    <ArrowSVG className={classes.triangleUp} />
                    <IconButton disabled={loading} onClick={evt => handleSubmit(evt, "Desidentificar", false)}>
                        <CancelIcon />
                    </IconButton>
                    <Typography>{dataBox.label} ?</Typography>
                    <IconButton onClick={evt => handleSubmit(evt, "ConfirmarIA")} disabled={loading}>
                        <CheckCircleIcon color="primary" />
                    </IconButton>
                </Paper>
            </div>
        )
    }

    if (dataBox.marking === "Confirmado" || dataBox.marking === "Confirmado Pós IA") {
        return (
            <div
                className="root-input"
                id={dataBox.id}
                style={{ visibility: "hidden", position: "relative", zIndex: 10 }}>
                <Paper elevation={2} className={classes.paperAction}>
                    <ArrowSVG className={classes.triangleUp} />
                    <IconButton disabled={loading} onClick={evt => handleSubmit(evt, "Desidentificar", false)}>
                        <CancelIcon />
                    </IconButton>
                    <Typography>{dataBox.label}</Typography>
                    <div />
                </Paper>
            </div>
        )
    }

    return (
        <div
            className="root-input"
            id={dataBox.id}
            style={{ visibility: "hidden", position: "relative", zIndex: 10 }}>
            <Paper elevation={2} className={classes.paperAction}>
                <ArrowSVG className={classes.triangleUp} />
                <InputAutocomplete
                    label="Digite o nome"
                    disabled={loading}
                    handleChange={handleChange}
                    modelType={modelType}
                />
                <IconButton onClick={evt => handleSubmit(evt, "Identificar")} disabled={loading}>
                    <CheckCircleIcon color="primary" />
                </IconButton>
            </Paper>
        </div>
    )
}

export default ButtonDemarcation
