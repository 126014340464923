import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
    Dialog,
    Slide,
    makeStyles,
    Box,
    DialogTitle,
    Button,
    Typography
} from "@material-ui/core";

import { mdiAlertCircle } from "@mdi/js";
import Icon from "@mdi/react";

import AppActions from "flux/actions/AppActions";
import Utils from "utils/Utils";
import { redirect } from 'utils/Helpers'
import CurrentPolicy from './CurrentPolicy'
import AuthenticationActions from "flux/actions/AuthenticationActions"

export const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
));

const useStyles = makeStyles((theme) => ({
    rootDialog: {
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            "& svg#main": {
                display: 'none'
            }
        },
    },
    root: {
        width: '100%',
        padding: 20
    },
    button: {
        height: 40
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    actions: {
        display: 'flex',
        justifyContent: "flex-end",
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& button': {
                margin: '0 0 10px 0 !important'
            }
        },
    },
    rootTitle: {
        "& .info": {
            display: "flex",
            alignItems: "center",
            color: "#666",
        },
        "& svg#main": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50%",
            padding: "4px",
            marginRight: "20px",
            color: "#fff",
        },
        "& h2": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
    },
}))

const CurrentPolicyBlockedDialog = () => {
    const [state, setState] = useState({
        acceptAnswer: false,
        isReject: false,
    })
    const {
        data: { currentPolicy },
        userData: { region, current_policy_accepted },
        pathServer,
        requestHeaders,
        pathClient
    } = useSelector(state => state.appReducer)
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const classes = useStyles()

    if (current_policy_accepted) return null;

    const handleCloseDialog = () => {
        const changeRegion = (slug, data, callback) => dispatch(AppActions.changeRegion(slug, data, callback))
        redirect(region, requestHeaders, history, pathClient, changeRegion)
    }

    const handleReject = () => {
        const callback = () => {
            const params = {
                privacy_policy: {
                    reference: _.get(currentPolicy, "reference", ""),
                    accept: false,
                },
            };
            axios
                .post(`${pathServer}/user/privacy-policy`, params, requestHeaders)
                .then((resp) => {
                    setTimeout(() => dispatch(AuthenticationActions.logout("policyDialog")), 5000);
                })
                .catch((err) => {
                    const message = Utils.ajaxErrorGetMessage(err, t)
                    console.log("Error: ", message);
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    });
                });
        }
        setState(prev => ({ ...prev, isReject: true }), callback())
    }

    const handleChangeAcceptAnswer = () => {
        setState(prev => ({ ...prev, acceptAnswer: !prev.acceptAnswer }))
    }

    const submitPolicy = () => {
        const params = {
            privacy_policy: {
                reference: _.get(currentPolicy, "reference", ""),
                accept: true,
            },
        };

        axios
            .post(`${pathServer}/user/privacy-policy`, params, requestHeaders)
            .then((resp) => {
                console.log('Politica atualizada com sucesso')
                dispatch(AppActions.setUserData({ field: "current_policy_accepted", value: true }))
                handleCloseDialog()
            })
            .catch((err) => {
                const message = Utils.ajaxErrorGetMessage(err, t)
                console.log("Error: ", message);
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                });
            });
    };

    return (
        <Dialog
            keepMounted
            open={!Boolean(current_policy_accepted)}
            TransitionComponent={Transition}
            className={classes.rootDialog}
        >
            <Box className={classes.root}>
                <DialogTitle className={classes.rootTitle}>
                    <Box className="info">
                        <Icon path={mdiAlertCircle} id="main" size={1.5} />
                        <Typography variant="h6" style={{ lineHeight: 1.2 }}>
                            {t("login:Atualização da Política de privacidade")}
                        </Typography>
                    </Box>
                </DialogTitle>
                <CurrentPolicy
                    acceptAnswer={state.acceptAnswer}
                    handleChange={handleChangeAcceptAnswer}
                    acceptAnswerisReject={state.isReject}
                />
                <Box className={classes.actions}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleReject}
                        className={classes.button}
                        disabled={state.isReject}
                        style={{ marginRight: 20 }}
                    >
                        {t("common:não estou de acordo")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submitPolicy}
                        className={classes.button}
                        disabled={!state.acceptAnswer}
                    >
                        {t("common:estou de acordo")}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default CurrentPolicyBlockedDialog
