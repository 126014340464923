import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemText,
  DialogContentText,
  DialogTitle,
  List
 } from "@material-ui/core";

import { withTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { useTranslation } from 'react-i18next';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

import Utils from '../../../../utils/Utils'

const DialogConfirmExpiration = ({
  handleClose,
  handleConfirm,
  data = [],
}) => {
  const { palette } = useSelector((state) => state.appReducer);
  const classes = useStyles({ palette });
  const { t } = useTranslation();

  return (
    <Dialog
      open={data.state}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          <InfoRoundedIcon id="main" />
          <div>{t(`common:${data.title}`)}</div>
        </div>
      </DialogTitle>

      <DialogContent style={{padding: '0'}}>
        {
          data.expirationDataUser.length > 0 ?
          <>
          <DialogContentText id="alert-dialog-description" gutterBottom>
            {t('manager:A alteração do prazo deste perfil impactará nos usuários ativos listado abaixo. Deseja continuar?')}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" gutterBottom>
            {t(`DAM:Usuários`)}:
          </DialogContentText>

          <List style={{overflow: 'auto', maxHeight: 150, color: 'rgba(0, 0, 0, 0.54)'}}>
            {
              Utils.sortedList(data.expirationDataUser).map((i, key) => (
                <ListItem key={key} style={{padding: 0}}>
                  <ListItemText
                    style={{margin: 0}}
                    primary={i}
                  />
                </ListItem>
              ))
            }
          </List>
          </>
          :
          <DialogContentText id="alert-dialog-description">
            {data.description}
          </DialogContentText>
        }

        <DialogActions className={classes.actions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {t(`common:Cancelar`)}
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            style={{marginLeft: 20}}
           >
            {t(`common:Sim`)}
          </Button>
        </DialogActions>
       </DialogContent>
    </Dialog>
  );
};

export default withTranslation(["DAM"])(DialogConfirmExpiration);
