import React, { useState, useCallback, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import {
    InputLabel,
    Grid,
    FormControl,
    FormHelperText,
    Button
} from '@material-ui/core';

import Select from 'react-select';
import { useStyles } from "./styles"

import DialogCreateMetadata from 'components/Dialogs/DialogCreateMetadata'
import svgRemove from 'imagens/ui/cancel-24px.svg'

const INDEX_CREATE_BUTTON = 'create_x99'

export default function SimpleSelect({
    title = '',
    selectValues,
    disabled = false,
    nameField = '',
    valueSelect = {},
    handleChangeValue,
    error = false,
    isMulti = false,
    fieldRequired = false,
    optionCreateMetadata = false,
    idMeta,
    handleUpdateMetadataValues, 
    listType,
    positionMenu = 'absolute',
    isColumnChip = false,
}) {
    const [state, setState] = useState({ focus: false, openDialogMeta: false })
    const classes = useStyles({ focus: state.focus, error: error, isColumnChip })
    const { t } = useTranslation()
    const theme = useTheme()

    const handleFocus = useCallback((evt) => {
        evt.preventDefault()
        setState(prev => ({ ...prev, focus: true }))
    }, [])

    const handleBlur = useCallback((evt) => {
        evt.preventDefault()
        setState(prev => ({ ...prev, focus: false }))
    }, [])

    const handleOpenDialogMeta = useCallback(() => {
        setState(prev => ({ openDialogMeta: !prev.openDialogMeta, focus: false }))
    }, [])

    const CustomOptionMetadata = useCallback(({
        children,
        getStyles,
        innerProps,
        value,
        ...props
    }) => {
        const hasButton = (innerProps.id.indexOf('option-0') !== -1
            && value === INDEX_CREATE_BUTTON)

        return (
            <>
                {
                    hasButton ? (
                        <div {...innerProps} onClick={handleOpenDialogMeta}>
                            <Button
                                style={{ width: '100%', justifyContent: 'flex-start', color: '#666', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                                + {t('common:adicionar metadado a lista')}
                            </Button>
                        </div>
                    ) : (
                        <div
                            {...innerProps}
                            style={{ ...getStyles('option', props), cursor: 'pointer' }}
                        >
                            {children}
                        </div>
                    )
                }
            </>
        )
    }, [handleOpenDialogMeta, t])

    const updateMetadataValues = useCallback((values, id) => {
        setState(prev => ({ openDialogMeta: false, focus: false }), handleUpdateMetadataValues(values, id))
    }, [handleUpdateMetadataValues])

    const constants = useMemo(() => ({
        componentsCustom: optionCreateMetadata ? { Option: CustomOptionMetadata, ...(isMulti && { ClearIndicator: null }) } : { ...(isMulti && { ClearIndicator: null }) },
        selectValuesCustom: optionCreateMetadata ? [{ label: INDEX_CREATE_BUTTON, value: INDEX_CREATE_BUTTON }, ...selectValues] : selectValues,
        hasValue: Boolean(valueSelect)
            ? Array.isArray(valueSelect)
                ? (valueSelect.length > 0) : (valueSelect.label && valueSelect.value)
            : false,
        customStyles: {
            multiValueLabel: () => ({
                color: '#fff',
                padding: '0 12px',
            }),
            multiValue: () => ({
                margin: '4px',
                minHeight: '32px',
                maxWidth: isColumnChip ? 168 : '95%',
                backgroundColor: `${_.get(theme, 'palette.primary.main', '')}`,
                display: 'inline-flex',
                outline: 0,
                padding: 0,
                fontSize: '0.8125rem',
                boxSizing: 'border-box',
                transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                alignItems: 'center',
                borderRadius: '16px',
                verticalAlign: 'middle',
                justifyContent: 'center',
                textDecoration: 'none',
                border: 'none',
                whiteSpace: isColumnChip ? 'pre-line' : 'nowrap',
                wordBreak: "break-all"
            }),
            multiValueRemove: () => ({
                borderRadius: '50%',
                width: 22,
                height: 22,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                backgroundImage: `url(${svgRemove})`,
                backgroundPosition: 'center',
                margin: '0 5px 0 -6px',
                '& svg': {
                    display: 'none'
                },
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)'
                }
            }),
            menu: provided => ({ ...provided, zIndex: 3, position: positionMenu })
        }
    }), [CustomOptionMetadata, isColumnChip, isMulti, optionCreateMetadata, positionMenu, theme, selectValues, valueSelect])

    return (
        <Grid item xs={12} className={error ? classes.rootError : classes.root}>
            <FormControl variant="outlined">
                <InputLabel
                    id={constants.hasValue ? 'label-on' : 'label-of'}
                    className={
                        state.focus
                            ? "label-focus-on"
                            : 'label-focus-off'
                    }
                >
                    {t(`common:${title}`)}{fieldRequired ? ' *' : ''}
                </InputLabel>
                <Select
                    components={constants.componentsCustom}
                    isDisabled={disabled}
                    classNamePrefix="select-custom"
                    placeholder={''}
                    styles={constants.customStyles}
                    isMulti={isMulti}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    name={nameField}
                    value={valueSelect}
                    onChange={(values, action) => handleChangeValue(values, action)}
                    isOptionDisabled={(option) => option.disabled}
                    options={constants.selectValuesCustom}
                />
                {
                    error &&
                    <FormHelperText>
                        {t(error)}
                    </FormHelperText>
                }
            </FormControl>

            {state.openDialogMeta &&
                <DialogCreateMetadata
                    listType={listType}                   
                    open
                    handleClose={handleOpenDialogMeta}
                    idMeta={idMeta}
                    title={nameField}
                    handleUpdateMetadataValues={updateMetadataValues}
                />
            }
        </Grid>
    );
}