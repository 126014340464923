import React from 'react';
import PropTypes from 'prop-types';

import FullScreenDialogCustom from 'components/FullScreenDialogCustom'
import EditFilesProvider from './context'

import ToolsBar from './ToolsBar'
import ComponentsView from './ComponentsView'
import Header from './Header'

const EditFilesDialog = ({ open, handleClose, slugItem, policies, itemID, taxonomyName, isDraft, isGallery }) => {
  return open && (
    <EditFilesProvider isDraft={isDraft} isGallery={isGallery}>
      <Header taxonomyName={taxonomyName} handleClose={handleClose} />
      <ToolsBar itemID={itemID} handleClose={handleClose} isGallery={isGallery} />
      <FullScreenDialogCustom open>
        <ComponentsView slugItem={slugItem} policies={policies} />
      </FullScreenDialogCustom>
    </EditFilesProvider>
  );
}

EditFilesDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  slugItem: PropTypes.string,
  policies: PropTypes.array,
  itemID: PropTypes.string,
  taxonomyName: PropTypes.string,
}

export default EditFilesDialog