import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Details from "./Details"
import SendEmail from "./SendEmail"
import Publish from "./Publish"
import DownloadItem from "./DownloadItem"
import RelatedItems from "./RelatedItems"
import ListVersions from "./Versions"
import ItemFiles from "./ItemFiles"
import RequestTool from "./RequestTool"
import FilesTextures from './FilesTextures'
import ColorPallete from './ColorPallete'
import DistributedFiles from "./DistributedFiles"

import Utils from "utils/Utils"
import { useDataAssetIntegra } from "./context"

const RenderComponentDetails = () => {
  const { t } = useTranslation()
  const { stateItem } = useDataAssetIntegra()

  switch (_.get(stateItem, 'menuComponentName.name', '')) {
    case "publish":
      return <Publish />
    case "versions":
      return (
        <ListVersions />
      )
    case "sendEmail":
      return (
        <SendEmail disabled={!_.get(stateItem, 'allow.email', false)} />
      )
    case "downloadItem":
      return (
        <DownloadItem
          versionIndex={_.get(stateItem, 'versionIndex', 0)}
          disabled={!_.get(stateItem, 'allow.download', false)}
          t={t}
        />
      )
    case "relatedItems":
      return (
        <RelatedItems idItem={_.get(stateItem, 'data.id', '')} />
      )
    case "itemFiles":
      return (
        <ItemFiles
          data={_.get(stateItem, 'data.assets', [])}
          slugItem={_.get(stateItem, "data.taxonomy.slug", "").split("-").join("_")}
          taxonomyName={_.get(stateItem, "data.taxonomy.name", "")}
          itemID={_.get(stateItem, 'data.id', '')}
          subTitle={Utils.versionName(
            _.get(stateItem, 'data.versions', []),
            _.get(stateItem, 'versionIndex', 0),
            t
          )}
          isDraft={_.get(stateItem, 'data.draft', false)}
          isManager={_.get(stateItem, 'allow.manager', false)}
          allowManageAssets={((_.get(stateItem, 'versionIndex', 0) === 0) && _.get(stateItem, 'allow.managerItemAsset', false))}
          isGallery={_.get(stateItem, 'data.isGalleryType', _.get(stateItem, 'data.isGallery', false))}
        />
      )
    case "request-tool":
      return (
        <RequestTool
          idItem={_.get(stateItem, 'data.id', '')}
          componentSlug={_.get(stateItem, 'menuComponentName.id', '')}
        />
      )
    case "associateFilesTextures":
      return (
        <FilesTextures
          idItem={_.get(stateItem, 'data.id', '')}
        />
      )
    case "colorPallete":
      return (
        <ColorPallete idItem={_.get(stateItem, 'data.id', '')} />
      )
    case "distributedFiles": {
      return <DistributedFiles />
    }
    default:
      return <Details data={{
        name: _.get(stateItem, 'data.name', ''),
        attribute: _.get(stateItem, 'data.attribute', []),
        description: _.get(stateItem, 'data.description', ''),
        validity_date: _.get(stateItem, 'data.validity_date', null),
        start_date: _.get(stateItem, 'data.start_date', null),
        itemId: _.get(stateItem, 'data.id', ''),
        category_id: _.get(stateItem, 'data.category_id', '')
      }} loading={_.get(stateItem, 'loading', false)} />
  }
}

export default RenderComponentDetails