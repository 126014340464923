import React, { useState, useCallback } from "react";
import axios from "axios";
import ChipInput from "material-ui-chip-input";
import _ from 'lodash'

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

import { useSnackbar } from 'notistack'
import {
  TextField,
  Chip,
  Grid,
  Button,
  Fade,
  CircularProgress,
  useMediaQuery,
  Typography
} from "@material-ui/core";

import AlertDialogSimple from 'components/Alert/AlertDialogSimple'
import ShareRoundedIcon from "@material-ui/icons/ShareRounded"

import Utils from 'utils/Utils'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "auto",
    '& form': {
      display: 'flex',
      flexDirection: "column"
    }
  },
  paper: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: 10,
    margin: 0,
    backgroundColor: '#eee'
  },
  chip: {
    margin: '3px',
    backgroundColor: '#fff'
  },
  helper: {
    display: 'inline-block',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.7em',
    marginTop: '10px',
  },
  error: {
    display: 'inline-block',
    color: '#FF0000',
    marginTop: '10px',
    fontSize: '.7em',
  },
  textfield: {
    marginTop: '20px',
    width: '100%'
  },
  chipInputRoot: {
    padding: 10,
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
    },
    '& button': {
      minWidth: '140px',
      height: '40px',
      maxHeight: '40px',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        maxHeight: 'none',
        height: 'auto',
        margin: '0 0 20px 0 !important',
        '& span': {
          textAlign: 'center'
        }
      },

    },
  }
}));

const ListSendEmail = ({
  handleClose,
  dataAsset = {},
  handleStepBack,
  pathDefault = null,
  paramsToItems = {},
  mustZipAssetsWithoutFolders,
  alternativeNameId,
  shareExpirationTime = "24",
  name = '',
  typeListrecipe = '',
  includeMetadata = false
}) => {
  const [state, setState] = useState({
    loading: false,
    recipients: [],
    emailValid: true,
    openDialogAlert: { open: false, text: '' }
  })
  const [description, setDescription] = useState('')
  const { markedItems, pathServer, requestHeaders } = useSelector((state) => state.appReducer);

  const { enqueueSnackbar } = useSnackbar()
  const mobile = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation()
  const classes = useStyles()

  const getHelperText = useCallback(() => {
    if (!state.emailValid) {
      if (state.recipients.length === 0) {
        return t("DAM:Informe ao menos um e-mail");
      }

      return t("DAM:Há e-mail(s) com erro");
    }

    return mobile ? '' : t("DAM:Pressione <Enter> para inserir mais de um e-mail");
  }, [mobile, state.emailValid, state.recipients, t])

  const validateEmail = useCallback((value) => {
    return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || value === "";
  }, [])

  const handleRenderChip = useCallback(({ value, handleClick, handleDelete }, key) => {
    return (
      <Chip
        key={key}
        color={validateEmail(value) ? "primary" : 'secondary'}
        label={value}
        onClick={handleClick}
        onDelete={handleDelete}
        style={{ margin: '0 2.5px' }}
      />
    )
  }, [validateEmail])

  const handleAddChip = useCallback((...chips) => {
    chips = chips.map(Function.prototype.call, String.prototype.trim);
    let recipients_;
    let emailValid_;
    recipients_ = [...state.recipients, ...chips];
    recipients_ = recipients_.filter(
      (item, index) => recipients_.indexOf(item) === index
    );

    if (state.emailValid) {
      emailValid_ = chips.filter((r) => !validateEmail(r)).length === 0;
    } else {
      emailValid_ = recipients_.filter((r) => !validateEmail(r)).length === 0;
    }

    setState(prev => ({ ...prev, emailValid: emailValid_, recipients: recipients_ }))
  }, [state.recipients, state.emailValid, validateEmail])

  const handleDeleteChip = useCallback((chip) => {
    let recipients_;
    let emailValid_;
    recipients_ = state.recipients.filter((r) => r !== chip);

    if (!state.emailValid) {
      emailValid_ = recipients_.filter((r) => !validateEmail(r)).length === 0;
    }

    setState(prev => ({ ...prev, emailValid: emailValid_, recipients: recipients_ }))
  }, [state.recipients, state.emailValid, validateEmail])


  const submit = useCallback(() => {
    if (state.recipients.length <= 0) {
      setState(prev => ({ ...prev, emailValid: false }))
      return null
    }

    let urlData = { url: '', payload: {} }

    if (
      dataAsset.hasOwnProperty("idAsset") &&
      dataAsset.hasOwnProperty("idItem") &&
      dataAsset.hasOwnProperty("versionIndex")
    ) {
      urlData.url = `${pathServer}/asset/${dataAsset.idItem}/${dataAsset.idAsset}/${dataAsset.versionIndex}/path_file/share`
      urlData.payload = {
        recipients: state.recipients,
        description: description,
        alternativeNameId: alternativeNameId,
        expiration: parseInt(shareExpirationTime),
        ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
        name,
        ...(Boolean(typeListrecipe) && { type: typeListrecipe }),
        ...(Boolean(includeMetadata) && { include_metadata: true })
      }
    } else {
      urlData.url = `${pathServer}/items/share`;
      urlData.payload = {
        recipients: state.recipients,
        description: description,
        itemsId: markedItems.map((item) => item.id),
        bulk: true,
        expiration: parseInt(shareExpirationTime),
        alternativeNameId: alternativeNameId,
        ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
        name,
        ...(Boolean(typeListrecipe) && { type: typeListrecipe }),
        ...(Boolean(includeMetadata) && { include_metadata: true })
      };
    }

    if (Boolean(pathDefault)) {
      urlData.url = `${pathServer}/${pathDefault}`
      urlData.payload = {
        ...paramsToItems,
        type: 'email',
        bulk: true,
        recipients: state.recipients,
        description: description,
        expiration: parseInt(shareExpirationTime),
        alternativeNameId: alternativeNameId,
        ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
        name,
        ...(Boolean(typeListrecipe) && { type: typeListrecipe }),
        ...(Boolean(includeMetadata) && { include_metadata: true })
      }
    }

    setState(prev => ({ ...prev, loading: true }),
      axios.post(urlData.url, urlData.payload, requestHeaders)
        .then((resp) => {
          setState(prev => ({ ...prev, loading: false, openDialogAlert: { open: true, text: _.get(resp, 'headers["x-message"]', '') } }))
        }).catch((err) => {
          const message = Utils.ajaxErrorGetMessage(err, t)
          setState(prev => ({ ...prev, loading: false, openDialogAlert: { open: false, text: '' } }), enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }))
        }))
  }, [state.recipients, shareExpirationTime, dataAsset, name, includeMetadata, enqueueSnackbar, markedItems, paramsToItems, pathDefault, pathServer, requestHeaders, description, t, mustZipAssetsWithoutFolders, alternativeNameId, typeListrecipe])

  const handleSubmit = useCallback(() => {
    if (state.emailValid) {
      submit()
    }
  }, [state.emailValid, submit])

  const onChangeDescritpion = useCallback((evt) => {
    setDescription(_.get(evt, 'target.value', ''))
  }, [])

  return (
    <div className={classes.wrapper}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='body1' style={{ margin: 0, color: "#666" }}>
            {t('DAM:Com quem você deseja compartilhar?')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ChipInput
            label={t('common:E-mail')}
            variant='outlined'
            value={state.recipients}
            disabled={state.loading}
            chipRenderer={(
              { value, handleClick, handleDelete },
              key
            ) =>
              handleRenderChip(
                { value, handleClick, handleDelete },
                key
              )
            }
            onAdd={handleAddChip}
            onDelete={handleDeleteChip}
            blurBehavior="add"
            onPaste={(event) => {
              const clipboardText = event.clipboardData.getData("Text")
              const regex = /([,(\r\n|\r|\n);])/

              event.preventDefault()

              handleAddChip(
                ...clipboardText
                  .split(regex)
                  .filter((t) => t.replace(regex, "").trim().length > 0)
              )
            }}
            fullWidth={true}
            disableUnderline={true}
            error={!state.emailValid}
            FormHelperTextProps={{
              classes: {
                root: classes.helper,
              },
            }}
            classes={{ inputRoot: classes.chipInputRoot }}
            style={{ marginTop: 20 }}
          />
          <span className={state.emailValid ? classes.helper : classes.error}>
            {getHelperText()}
          </span>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.textfield}
            onChange={onChangeDescritpion}
            value={description}
            multiline
            label={`${t("DAM:Deixe a sua mensagem aqui")}...`}
            variant="outlined"
            rows={4}
          />
        </Grid>
      </Grid>

      <div className={classes.actions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleStepBack}
          style={{ margin: '0 15px 0 0', minWidth: 80 }}
        >
          {t('common:Voltar')}
        </Button>
        <Button
          disabled={state.loading ? true : false}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          style={{ margin: 0 }}
        >
          {state.loading ? (
            <Fade
              in={state.loading}
              style={{
                transitionDelay: state.loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress color="inherit" size={20} />
            </Fade>
          ) : t('common:Compartilhar')}
        </Button>
      </div>
      <AlertDialogSimple
        open={state.openDialogAlert.open}
        icon={<ShareRoundedIcon id="main" />}
        handleClose={() => {
          setState(prev => ({ ...prev, openDialogAlert: { open: false, text: '' } }), handleClose())
        }}
        title={t(`common:Compartilhamento em andamento`)}
        description={state.openDialogAlert.text}
      />
    </div>
  );
};

export default ListSendEmail
