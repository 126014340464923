import React from "react"
import { clone, pick } from "ramda"
import axios from "axios/index"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Template from "../Template"
import Loading from "../../../components/Loading"
import { Grid, Paper } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { responseError } from "../../../utils/Ajax"
import ErrorBoundary from "../../../components/Core/ErrorBoundary"

import DialogDeleteValidation from "../../../components/Dialogs/DeleteValidationPhrase"
import DialogProgress from "./DialogProgress"

import { WrapperPaperForms } from '../../../components/WrapperForms'

export default class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: "",
      dataOriginal: {},
      data: {},
      hasData: false,
      formValid: true,
      formErrors: {},
      loading: true,
      progress: {
        dialogOpen: false,
        confirm: false,
        value: 0,
        total: 0,
        message: "",
        title: "",
        loading: false,
      },
      nameToDeleteUpdate: "",
      pusher_connected: false,
      selectedOption: null,
    }
    this.update = this.update.bind(this)
    this.responseError = responseError.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.close = this.close.bind(this)
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    const ID = this.props.match.params.id
    if (!this.state.hasData) {
      this.getData(ID)
    }

    if (this.state.hasPusher) {
      this.setMaintenance()
    }
  }

  componentDidUpdate() {
    if (this.state.hasPusher) {
      this.checkPusherConnected()
    }
  }

  componentWillUmmount() {
    this._isMounted = false
  }

  setMaintenance() {
    const SELF = this
    const { bindToChannel } = SELF.props
    const channel = "private-maintenance"

    bindToChannel(channel, "started", (msg) => {
      if (msg.data.id === SELF.state.id && SELF.state.progress.total > 0) {
        SELF._isMounted &&
          SELF.setState({
            ...SELF.state,
            progress: {
              dialogOpen: true,
              loading: true,
              title: this.props.t("manager:Alterando"),
              message: this.state.progress.message,
              total: this.state.progress.total,
              value: this.state.progress.value,
            },
          })
      }
    })

    bindToChannel(channel, "updated", (msg) => {
      if (msg.data.id === SELF.state.id && SELF.state.progress.total > 0) {
        SELF._isMounted &&
          SELF.setState({
            ...SELF.state,
            progress: {
              dialogOpen: true,
              value: msg.data.counter,
              total: this.state.progress.total,
              loading: true,
              title: this.props.t("manager:Alterando"),
              message: `${this.props.t("common:Alterando itens")}: ${msg.data.counter} ${this.props.t("common:de")} ${this.state.progress.total}`,
            },
          })
      }
    })

    bindToChannel(channel, "ended", (msg) => {
      if (msg.data.id === SELF.state.id && SELF.state.progress.total > 0) {
        SELF._isMounted &&
          SELF.setState({
            ...SELF.state,
            progress: {
              dialogOpen: true,
              value: 0,
              loading: false,
              total: 0,
              title: this.props.t("manager:Concluído"),
              message: this.props.t("common:Operação concluída."),
              close: true,
            },
          })
        SELF.props.channelUnsubscribe(channel)
      }
    })

    this.checkPusherConnected()
  }

  checkPusherConnected() {
    const SELF = this
    const { channels_connected } = SELF.props
    const { pusher_connected } = SELF.state
    const channel = "private-maintenance"

    if (channels_connected.includes(channel) && !pusher_connected) {
      SELF.setState({
        ...SELF.state,
        pusher_connected: true,
      })
    }
  }

  getUrl(id) {
    const { pathServer } = this.props;
    return (
      `${pathServer}/${this.state.entity}/${id ? id : this.state.id}`
    )
  }

  // GET DATA
  getData(id) {
    const SELF = this
    const path = this.getUrl(id)
    const { requestHeaders } = this.props;

    axios
      .get(path, requestHeaders)
      .then(
        function (resp) {
          let DATA = resp.data.item || []
          let newState = SELF.formatGetData({
            ...SELF.state,
            loading: false,
            id: DATA.id,
            data: DATA,
            nameToDeleteUpdate: DATA.name || "",
            dataOriginal: clone(DATA),
            hasData: true,
          })

          SELF.setState(newState)
        },
        function (resp) {
          SELF.setState({ ...SELF.state, loading: false })
          toast.error(resp.headers["x-message"])
        }
      )
      .catch(function (err) {
        console.error(err)
        toast.error(SELF.props.t("Erro ao carregar os dados, contate o administrador."))
      })
  }

  close() {
    const SELF = this

    if (this.state.progress.alert) {
      this.setState({
        ...this.state,
        progress: {
          dialogOpen: false,
          confirm: false,
          title: "",
          message: "",
          total: 0,
          loading: false,
          alert: false
        },
      })
    } else {
      setTimeout(() => {
        SELF.props.history.push(`/manager/${SELF.state.route}/list`)
      }, 10)
    }
  }

  handleCancel() {
    this._isMounted &&
      this.setState({
        ...this.state,
        progress: {
          dialogOpen: false,
          confirm: false,
          title: "",
          message: "",
          total: 0,
          loading: false,
        },
      })
  }

  handleUpdateError(err, SELF) {
    if (err.response.status === 422) {
      let message = ''
      Object.entries(err.response.data).forEach(([key, msg]) => {
        message = this.props.t(`manager:${msg}`)
      })

      SELF.setState({
        ...SELF.state,
        progress: {
          dialogOpen: true,
          title: this.props.t("common:Alerta"),
          message: message || err.response.headers["x-message"],
          loading: false,
          alert: true,
          close: true
        },
        loading: false,
      })
    } else {
      SELF._isMounted &&
        SELF.setState({
          ...SELF.state,
          progress: {
            dialogOpen: false,
            title: "",
            message: "",
            loading: false,
          },
          loading: false,
        })
    }
  }

  setLoadingState(callback) {
    const SELF = this
    SELF.setState(prev => ({
      ...prev,
      loading: true,
      progress: {
        dialogOpen: false,
        confirm: false,
        title: SELF.props.t("common:Confirmação"),
      }
    }), callback())
  }

  handleUpdate() {
    const SELF = this
    const { pusher_connected, entity } = SELF.state
    const parameters = SELF.getParameters()

    if (pusher_connected) {
      SELF._isMounted &&
        SELF.setState({
          ...SELF.state,
          loading: true,
          progress: {
            dialogOpen: pusher_connected,
            title: this.props.t("manager:Alterando"),
            message: `${this.props.t("manager:Alterando")} ${(SELF.state.entityName ? SELF.state.entityName : "")}`,
            loading: true,
          },
        })
    }

    const path = this.getUrl()
    const { requestHeaders } = this.props;

    this.setLoadingState(() => {
      axios
        .put(path, parameters, requestHeaders)
        .then(
          async function (resp) {
            let msg = { text: resp.headers["x-message"] || SELF.props.t("DAM:Dados salvo com sucesso."), variant: 'success' }

            if (entity === "category") {
              await axios.patch(`${SELF.props.pathServer}/category/scopeOfItems/${SELF.state.id}`, { scopeOfItemsInCategory: parameters.scopeOfItems }, requestHeaders)
                .catch(err => {
                  console.log(err)
                  msg = { text: SELF.props.t("Categoria criada, porém houve um erro ao configurar o compartilhamento de itens. Contate o suporte."), variant: 'warning' }
                })
            }

            if (Boolean(resp.data.items)) {
              if (resp.data.items && pusher_connected) {
                SELF._isMounted &&
                  SELF.setState({
                    ...SELF.state,
                    progress: {
                      dialogOpen: true,
                      title: SELF.props.t("manager:Alterando"),
                      message: `${SELF.props.t("common:Alterando itens")}: 0 ${SELF.props.t("common:de")} ${resp.data.items}`,
                      total: resp.data.items,
                      loading: true,
                    },
                  })
              } else {
                SELF._isMounted &&
                  SELF.setState({
                    ...SELF.state,
                    progress: {
                      dialogOpen: true,
                      message: `${SELF.props.t("common:Itens")} ${(SELF.state.entityName
                        ? SELF.props.t("common:de") + SELF.state.entityName
                        : "")} ${SELF.props.t("common:serão alterados.")}`,
                      loading: false,
                    },
                  })
              }
            } else {
              toast[msg.variant](msg.text, {
                pauseOnHover: false,
                autoClose: 2000,
              })

              if (SELF._isMounted) {
                SELF.setState({
                  ...SELF.state,
                  loading: false,
                  requireConfirmation: false,
                  progress: {
                    dialogOpen: false,
                    confirm: false,
                    title: SELF.props.t("common:Confirmação"),
                  },
                }, SELF.props.history.push(`/manager/${SELF.state.route}/list`))
              } else {
                SELF.props.history.push(`/manager/${SELF.state.route}/list`)
              }
            }
          },
          (err) => {
            this.handleUpdateError(err, SELF)
          }
        )
        .catch(function (error) {
          SELF._isMounted &&
            SELF.setState({
              ...SELF.state,
              progress: {
                dialogOpen: false,
                message: "",
                title: "",
                loading: false,
              },
              loading: false,
            })
          toast.error(SELF.props.t("Erro ao processar, contate o administrador."))
        })
    }
    )
  }

  postValidForm() {
    return true
  }

  // UPDATE
  update(e) {
    e.preventDefault()
    const SELF = this
    const parameters = SELF.getParameters()

    let formValid = SELF.validateForm(parameters)

    if (!formValid) {
      return
    }

    if (!this.postValidForm()) {
      return
    }

    if (this.state.requireConfirmation) {
      this._isMounted &&
        this.setState({
          ...this.state,
          progress: {
            dialogOpen: true,
            confirm: true,
            title: this.props.t('common:Confirmação'),
          },
        })
    } else {
      this.handleUpdate()
    }
  }

  getParameters() {
    const SELF = this
    let { data, dataOriginal, fields } = SELF.state

    let parameters = pick(fields, data)
    let modifyParameters = Object.keys(parameters).filter(
      (fieldName) => parameters[fieldName] !== dataOriginal[fieldName]
    )
    return pick(modifyParameters, parameters)
  }

  validateForm(parameters) {
    let formValid = true
    let formErrors = {}
    if (parameters.hasOwnProperty("name") && !parameters.name.trim()) {
      formErrors.name = this.props.t("common:Este campo é obrigatório")
      formValid = false
    }
    this.setState({ ...this.state, formErrors, formValid })
    return formValid
  }

  // DELETE
  delete = () => {
    const SELF = this
    const { requestHeaders } = this.props;
    SELF.setState({ ...SELF.state, loading: true })
    axios
      .delete(this.getUrl(), requestHeaders)
      .then(
        function (resp) {
          SELF.setState(
            {
              ...SELF.state,
              loading: false,
            },
            () => SELF.props.history.push(`/manager/${SELF.state.route}/list`)
          )

          toast.success(resp.headers["x-message"])
        },
        (err) => {
          this.handleUpdateError(err, SELF)
        }
      )
      .catch(function (err) {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : SELF.props.t('Erro ao deletar os dados, contate o administrador.')
        SELF.setState({
          ...SELF.state,
          loading: false
        });
        toast.error(message);
      })
  }

  getClassName = (nameAttribute) => {
    let isError = !this.state.formErrors.hasOwnProperty(nameAttribute)
    return isError ? "form-control" : "form-control has-error"
  }

  // HANDLERS
  handleChange = (attribute, evt) => {
    let newState = { ...this.state }
    newState.data[attribute] = evt.target.value
    this.setState(newState)
  }

  handleChangeSwitch = (attribute, evt) => {
    let newState = { ...this.state }
    newState.data[attribute] = evt.target.checked
    this.setState(newState)
  }

  handleChangeComboBox = (attribute, selectedOption) => {
    let newState = { ...this.state }

    newState["data"][attribute + "_id"] = selectedOption.value
    newState["data"][attribute]["id"] = selectedOption.value
    newState["data"][attribute]["name"] = selectedOption.label

    this.setState(newState)
  }

  handleChangeSelect = (attribute, selectedOption) => {
    let newState = { ...this.state }
    newState["data"][attribute] = selectedOption
    this.setState(newState)
  }

  getAdditionalRender() {
    return <React.Fragment></React.Fragment>
  }

  render() {
    let singularTitle = this.state.title.singular.toLowerCase()

    const getActions = () => {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <DialogDeleteValidation
            entity={this.props.t(`common:${this.state.title.singular}`)}
            name={this.state.data.name || ""}
          >
            {(confirm) => (
              <Button
                style={{
                  height: 40,
                  width: 115,
                  marginRight: 20,
                  color: "#F4511E",
                  border: "1px solid #F4511E",
                }}
                variant="outlined"
                onClick={confirm(this.delete)}
              >
                {this.props.t("common:Excluir")}
              </Button>
            )}
          </DialogDeleteValidation>
          <Button
            style={{ height: 40, width: 115, marginRight: 20 }}
            variant="outlined"
            color="primary"
            onClick={() =>
              this.props.history.push(`/manager/${this.state.route}/list`)
            }
          >
            {this.props.t("common:Voltar")}
          </Button>
          <Button
            style={{ height: 40, width: 115 }}
            variant="contained"
            color="primary"
            onClick={this.update}
          >
            {this.props.t("common:Salvar")}
          </Button>
        </div>
      )
    }

    return (
      <Template
        area={this.state.area}
        localHistory={this.props.history}
        customClassName={"container-center"}
      >
        <ErrorBoundary>
          {this.state.loading && <Loading showLoading />}
          {
            this.state.entity === 'profile' &&
              this.state.area === 7 ? (
              <Paper elevation={2} className={this.props.classes.paperForm}>
                <form action="" className="form-default">
                  <Grid
                    container
                    spacing={5}
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginBottom: 20,
                    }}
                  >
                    <Grid item xs={9}>
                      <Typography variant="h5" gutterBottom>
                        {this.props.t('common:Editar')} {this.props.t(`common:${singularTitle}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    {this.state.hasData ? this.getForm() : null}
                  </Grid>
                  <div className="clearfix" />
                  {getActions()}
                </form>
              </Paper>

            ) : (
              <WrapperPaperForms
                title={`${this.props.t("manager:Edição de")} ${this.props.t(`common:${singularTitle}`)}`}>
                {this.state.hasData ? this.getForm() : null}
                {getActions()}
              </WrapperPaperForms>
            )
          }


          <DialogProgress
            data={this.state.progress}
            handleClose={this.close}
            handleConfirm={this.handleUpdate}
            handleCancel={this.handleCancel}
            entity={this.state.entityName}
            entityName={this.state.nameToDeleteUpdate}
          />

          {this.getAdditionalRender()}
        </ErrorBoundary>
      </Template>
    )
  }
}
