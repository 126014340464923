import React from "react"
import { Switch, Redirect, matchPath, Route } from "react-router-dom"
import Favicon from "react-favicon"
import Utils from "utils/Utils"
import { saveUserHistory } from 'utils/Helpers'
import { connect } from "react-redux"
import AppActions from "../../flux/actions/AppActions"
import { Creators as ItemsActions } from "../../flux/ducks/Items"
import "react-toastify/dist/ReactToastify.css"
import Loading from "../../components/Loading"
import { withRouter } from "react-router-dom"

/*LOGIN*/
import RedirectDAM from "./RedirectDAM"
import AccessRequest from '../../pages/AccessRequest'

/*DAM*/
import Divisions from "../../pages/DAM/Divisions"
import Segments from "../../pages/DAM/Segments"
import Categories from "../../pages/DAM/Categories"
import Items from "../../pages/DAM/Items"
import GroupingCards from '../../components/GroupingCards'
import Item from "../../pages/DAM/Item"
import Bucket from "../../pages/DAM/Bucket"


/* Pages Publics */
import ItemShared from "pages/Publics/ItemShared"

/*Manager*/
import AccessDenied from "../../components/AccessDenied/ListWithHeader"
import ManagerRegionList from "../../pages/Manager/Regions/List"
import ManagerRegionNew from "../../pages/Manager/Regions/New"
import ManagerRegionEdit from "../../pages/Manager/Regions/Edit"
import ManagerRegionUpload from "../../pages/Manager/Regions/Upload"
import ManagerDivisionList from "../../pages/Manager/Divisions/List"
import ManagerDivisionNew from "../../pages/Manager/Divisions/New"
import ManagerDivisionEdit from "../../pages/Manager/Divisions/Edit"
import ManagerDivisionUpload from "../../pages/Manager/Divisions/Upload"
import ManagerSegmentList from "../../pages/Manager/Segments/List"
import ManagerSegmentNew from "../../pages/Manager/Segments/New"
import ManagerSegmentEdit from "../../pages/Manager/Segments/Edit"
import ManagerSegmentUpload from "../../pages/Manager/Segments/Upload"
import ManagerUserList from "../../pages/Manager/Users/List"
import ManagerUser from "../../pages/Manager/UserProfile"
import ManagerUserUpload from "../../pages/Manager/Users/Upload"
import ManagerAttributeList from "../../pages/Manager/Attributes/List"
import ManagerAttributeNew from "../../pages/Manager/Attributes/New"
import ManagerAttributeEdit from "../../pages/Manager/Attributes/Edit"
import ManagerAttributeUsage from "../../pages/Manager/Attributes/Usage"

import ManagerAttributesSchemaList from "../../pages/Manager/AttributesSchema/List"
import ManagerAttributesSchemaForm from "../../pages/Manager/AttributesSchema/Form"

import ManagerProfileList from "../../pages/Manager/Profiles/List"
import ManagerProfileCreate from "../../pages/Manager/Profiles/Create"
import ManagerProfileEdit from "../../pages/Manager/Profiles/Edit"
import ManagerProfileUpload from "../../pages/Manager/Profiles/Upload"
import ManagerPassword from "../../pages/Manager/Account/Password"
import ManagerAccount from "../../pages/Manager/Account/Account"

import ManagerCategoryList from "../../pages/Manager/Categories/List"
import ManagerCategoryCreate from "../../pages/Manager/Categories/Create"
import ManagerCategoryEdit from "../../pages/Manager/Categories/Edit"
import ManagerCategoryUpload from "../../pages/Manager/Categories/Upload"

import ManagerClientEdit from "../../pages/Manager/Clients"
import ItemCreate from "../../pages/Item/Create"

import RequestToolsList from "../../pages/Manager/RequestTools/List"
import RequestToolsListForm from "../../pages/Manager/RequestTools/Form"
import RequestToolsListUpload from "../../pages/Manager/RequestTools/Upload"

import AdvancedSettingsList from "../../pages/Manager/AdvancedSettings"
import AlternativeNamesUsage from "../../pages/Manager/AdvancedSettings/AlternativeName/Usage"
import RecipesUsage from "../../pages/Manager/AdvancedSettings/Recipes/Usage"

import LoginContainer from "../../containers/LoginContainer"

import AccessModerateContainer from "../../containers/AccessModerateContainer"
import ConfirmEmailContainer from "../../containers/ConfirmEmailContainer"

import ToolsList from "../../containers/RequestToolContainer/ToolsList"
import ToolDetails from "../../containers/RequestToolContainer/ToolDetails"
import ToolFormWorkflow from '../../containers/RequestToolContainer/ToolFormWorkflow'
import ToolUsage from '../../pages/Manager/RequestTools/ToolUsage'

import ExpiredInvitation from "pages/AccessRequest/ExpiredInvitation"
import ItemAccessDenied from "../../components/AccessDenied"
import PrivateLayout from './Template/PrivateLayout'
import PublicLayout from './Template/PublicLayout'

import DownloadCentral from 'pages/DownloadCentral'
import OAuth from 'components/OAuth'

import _ from 'lodash'

class Router extends React.Component {
  constructor(props) {
    super(props)
    this.routesWithNavContext = [
      "/dam/:region/:division/:segment/:category",
      "/dam/:region/:division/:segment/:category/:item",
      "/search/:region/:searchText",
      "/search_template/:region/draft",
      "/gallery/:region/:shortcut_slug",
      "/shortcut/:region/:shortcut_slug",
      "/bucket",
      "/downloads",
      "/downloads/:id",
      "/items/:region",
      "/item/new"
    ]
  }

  validateRegion = () => {
    const { checkingRegion, regionInvalidUrl, checkRegion, dataRegions } = this.props

    if (checkingRegion && !regionInvalidUrl) {
      checkRegion()
    } else if (
      regionInvalidUrl &&
      regionInvalidUrl !== Utils.getRegionFromUrl(
        _.get(dataRegions, 'other_regions', []),
        _.get(dataRegions, 'region', '')
      )
    ) {
      checkRegion()
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.hasJustCheckedRegion(nextProps)
    )
  }

  hasJustCheckedRegion(nextProps) {
    const { checkingRegion } = this.props
    return (
      !nextProps.checkingRegion && checkingRegion !== nextProps.checkingRegion
    )
  }

  componentDidUpdate() {
    this.checkAndClearSession()
    this.validateRegion()
  }

  componentDidMount() {
    this.checkAndClearSession()
    this.validateRegion()
  }

  checkRouteWithNav() {
    const { pathname } = this.props.location
    return this.routesWithNavContext.some((route) => matchPath(pathname, route))
  }

  checkAndClearSession() {
    if (this.props.navigationModeView === 'view-items') {
      return
    }
    if (!this.checkRouteWithNav()) {
      try {
        this.props.resetSession()
      } catch (e) {
        console.log("Erro ao buscar props no Router.")
      }
    }
  }

  render() {
    if (this.props.checkingRegion) {
      return <Loading showLoading={true} />
    } else {
      let { policies } = this.props
      const defaultParams = {
        checkingRegion: this.props.checkingRegion,
        regionInvalidUrl: this.props.regionInvalidUrl,
        modeViewitens: this.props.navigationModeView,
      }
      const defaultManager = {
        checkingRegion: false,
        exact: true,
        policies: policies,
        target: "manager",
      }
      const defaultBucket = {
        checkingRegion: this.props.checkingRegion,
        regionInvalidUrl: this.props.regionInvalidUrl,
        exact: true,
        policies: policies,
        target: "manager",
      }
      const defaultPublic = { checkingRegion: this.props.checkingRegion }

      return (
        <React.Fragment>
          <Favicon url={localStorage.getItem("favicon")} />
          <Switch>
            {/*LOGIN*/}
            <PublicRoute
              {...defaultPublic}
              exact
              path="/login"
              component={(props) => <LoginContainer {...props} />}
            />
            <PublicRoute
              {...defaultPublic}
              exact
              path="/login-oauth"
              component={(props) => <OAuth {...props} />}
              showHeader={false}
            />
            <PublicRoute
              {...defaultPublic}
              exact
              path="/forgot-password"
              component={(props) => (
                <LoginContainer {...props} component="forgotPassword" />
              )}
            />
            <PublicRoute
              {...defaultPublic}
              exact
              path="/new-password"
              component={(props) => (
                <LoginContainer {...props} component="newPassword" />
              )}
            />

            <PublicRoute
              {...defaultPublic}
              exact
              path="/access-request"
              component={(props) => <AccessRequest {...props} type='accessRequest' />}
              showHeader={false}
            />

            <PublicRoute
              {...defaultPublic}
              exact
              path="/access-request/invitation/:id_user"
              component={(props) => <AccessRequest {...props} type='invitation' />}
              showHeader={false}
            />

            <PublicRoute
              {...defaultPublic}
              exact
              path="/expired-invitation"
              component={(props) => <ExpiredInvitation {...props} />}
            />

            <PublicRoute
              {...defaultPublic}
              exact
              path="/request-access-moderate"
              component={(props) => <AccessModerateContainer {...props} />}
            />
            <PublicRoute
              {...defaultPublic}
              exact
              path="/confirm-email"
              component={(props) => <ConfirmEmailContainer {...props} />}
            />

            {/*DAM*/}
            <PrivateRoute exact path="/" component={RedirectDAM} />
            <PrivateRoute
              exact
              path="/access-denied"
              component={AccessDenied}
            />
            <PrivateRoute
              exact
              path="/item-access-denied"
              component={props => <ItemAccessDenied {...props} title="Item não encontrado." msg="Item não encontrado." />}
            />
            <PrivateRoute exact path="/dam" component={RedirectDAM} />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/dam/:region"
              component={(props) => this.props.navigationModeView !== 'view-items'
                ? <Divisions {...props} />
                : <Items {...props} />
              }
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/dam/:region/:division"
              component={(props) => <Segments {...props} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/dam/:region/:division/:segment"
              component={(props) => <Categories {...props} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/dam/:region/:division/:segment/:category"
              component={(props) => <Items {...props} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/dam/:region/:division/:segment/:category/:item"
              component={(props) => <Item {...props} policies={policies} />}
            />

            <PrivateRoute
              {...defaultBucket}
              entity="bucket"
              path="/bucket"
              component={(props) => <Bucket {...props} policies={policies} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/search/:region/:searchText"
              component={(props) => <Items {...props} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/items/:region"
              component={(props) => <Items {...props} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/search_template/:region/draft"
              component={(props) => <Items {...props} draft={true} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/gallery/:region/:shortcut_slug/:grouped*"
              component={(props) => <GroupingCards {...props} />}
            />
            <PrivateRoute
              {...defaultParams}
              exact
              path="/shortcut/:region/:shortcut_slug"
              component={(props) => <Items isShortcut {...props} />}
            />
            <PublicRoute
              {...defaultPublic}
              exact
              path="/shared/:token"
              component={(props) => <ItemShared {...props} />}
            />
            {/*SETTINGS*/}
            <PrivateRoute
              {...defaultManager}
              entity="region"
              path="/manager/"
              component={(props) => <ManagerRegionList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="region"
              path="/manager/regions"
              component={(props) => <ManagerRegionList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="region"
              path="/manager/regions/list"
              component={(props) => <ManagerRegionList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="region"
              path="/manager/regions/new"
              component={(props) => <ManagerRegionNew {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="region"
              path="/manager/regions/edit/:id"
              component={(props) => <ManagerRegionEdit {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="region"
              path="/manager/regions/upload/:id"
              component={(props) => <ManagerRegionUpload {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="division"
              path="/manager/divisions"
              component={(props) => <ManagerDivisionList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="division"
              path="/manager/divisions/list"
              component={(props) => <ManagerDivisionList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="division"
              path="/manager/divisions/new"
              component={(props) => <ManagerDivisionNew {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="division"
              path="/manager/divisions/edit/:id"
              component={(props) => <ManagerDivisionEdit {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="division"
              path="/manager/divisions/upload/:id"
              component={(props) => <ManagerDivisionUpload {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="segment"
              path="/manager/segments"
              component={(props) => <ManagerSegmentList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="segment"
              path="/manager/segments/list"
              component={(props) => <ManagerSegmentList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="segment"
              path="/manager/segments/new"
              component={(props) => <ManagerSegmentNew {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="segment"
              path="/manager/segments/edit/:id"
              component={(props) => <ManagerSegmentEdit {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="segment"
              path="/manager/segments/upload/:id"
              component={(props) => <ManagerSegmentUpload {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="user"
              path="/manager/users"
              component={(props) => <ManagerUserList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="user"
              path="/manager/users/list"
              component={(props) => <ManagerUserList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="user"
              path="/manager/user/:id"
              component={(props) => <ManagerUser {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="user"
              path="/manager/users/upload/:id"
              component={(props) => <ManagerUserUpload {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/metadata"
              component={(props) => <ManagerAttributeList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/metadata/list"
              component={(props) => <ManagerAttributeList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/metadata/new"
              component={(props) => <ManagerAttributeNew {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/metadata/edit/:id"
              component={(props) => <ManagerAttributeEdit {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/metadata/usage/:id"
              component={(props) => <ManagerAttributeUsage {...props} />}
            />

            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/schema"
              component={(props) => <ManagerAttributesSchemaList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/schema/new"
              component={(props) => (
                <ManagerAttributesSchemaForm typeForm="create" {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="attribute"
              path="/manager/schema/edit/:id"
              component={(props) => (
                <ManagerAttributesSchemaForm typeForm="edit" {...props} />
              )}
            />

            <PrivateRoute
              {...defaultManager}
              entity="profile"
              path="/manager/profiles"
              component={(props) => <ManagerProfileList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="profile"
              path="/manager/profiles/list"
              component={(props) => <ManagerProfileList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="profile"
              path="/manager/profiles/new"
              component={(props) => (
                <ManagerProfileCreate typeForm="create" {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="profile"
              path="/manager/profiles/edit/:id"
              component={(props) => (
                <ManagerProfileEdit typeForm="edit" {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="profile"
              path="/manager/profiles/upload/:id"
              component={(props) => <ManagerProfileUpload {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="category"
              path="/manager/categories"
              component={(props) => <ManagerCategoryList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="category"
              path="/manager/categories/list"
              component={(props) => <ManagerCategoryList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="category"
              path="/manager/categories/new"
              component={(props) => (
                <ManagerCategoryCreate typeForm="create" {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="category"
              path="/manager/categories/edit/:id"
              component={(props) => (
                <ManagerCategoryEdit typeForm="edit" {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="category"
              path="/manager/categories/upload/:id"
              component={(props) => <ManagerCategoryUpload {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="client"
              path="/manager/client"
              component={(props) => <ManagerClientEdit {...props} />}
            />

            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/tools"
              component={(props) => <RequestToolsList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/request/new"
              component={(props) => (
                <RequestToolsListForm typeForm="create" {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/request/edit/:id"
              component={(props) => (
                <RequestToolsListForm typeForm="edit" {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/request/upload/:id"
              component={(props) => <RequestToolsListUpload {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/workflow/new"
              component={(props) => (
                <RequestToolsListForm typeForm="create" workflow {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/workflow/edit/:id"
              component={(props) => (
                <RequestToolsListForm typeForm="edit" workflow {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/tool/:id/usage"
              component={(props) => (
                <ToolUsage workflow {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              entity="request-tool"
              path="/manager/workflow/upload/:id"
              component={(props) => <RequestToolsListUpload workflow {...props} />}
            />

            <PrivateRoute
              {...defaultManager}
              path="/manager/advanced-settings"
              component={(props) => <AdvancedSettingsList {...props} />}
            />
            <PrivateRoute
              {...defaultManager}
              path="/manager/advanced-settings/new"
              component={(props) => (
                <AdvancedSettingsList {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              path="/manager/advanced-settings/edit/:id"
              component={(props) => (
                <AdvancedSettingsList {...props} />
              )}
            />
            <PrivateRoute
              {...defaultManager}
              path="/manager/advanced-settings/usage/:id"
              component={(props) => (
                <AlternativeNamesUsage {...props} />
              )}
            />

            <PrivateRoute
              {...defaultManager}
              path="/manager/advanced-settings/recipes-usage/:id"
              component={(props) => (
                <RecipesUsage {...props} />
              )}
            />

            <PrivateRoute
              exact
              path="/account"
              component={(props) => <ManagerAccount {...props} />}
            />
            <PrivateRoute
              exact
              path="/account/password"
              component={(props) => <ManagerPassword {...props} />}
            />
            <PrivateRoute
              exact
              path="/item/new"
              component={(props) => <ItemCreate {...props} />}
            />
            <PrivateRoute
              exact
              path="/downloads"
              component={(props) => <DownloadCentral {...props} />}
            />
            <PrivateRoute
              exact
              path="/downloads/:id"
              component={(props) => <DownloadCentral {...props} />}
            />

            <PrivateRoute
              exact
              path="/:region/request/:slug"
              component={(props) => <ToolsList {...props} />}
            />

            <PrivateRoute
              exact
              path="/:region/request/:slug/:id"
              component={(props) => <ToolDetails {...props} />}
            />

            <PrivateRoute
              exact
              path="/:region/workflow/:slug"
              component={(props) => <ToolsList workflow {...props} />}
            />

            <PrivateRoute
              exact
              path="/:region/workflow/:slug/new"
              component={(props) => <ToolFormWorkflow {...props} />}
            />

            <PrivateRoute
              exact
              path="/:region/workflow/:slug/:id"
              component={(props) => <ToolDetails workflow {...props} />}
            />

            <PublicRoute
              {...defaultPublic}
              component={(props) => (
                <LoginContainer {...props} component="routeInvalid" />
              )}
            />

          </Switch>
        </React.Fragment>
      )
    }
  }
}

const PublicRoute = (data) => {
  let { component: Component, checkingRegion, showHeader, ...rest } = data

  return <Route {...rest} render={(props) =>
    <PublicLayout showHeader={showHeader}>
      <Component {...props} />
    </PublicLayout>
  } />
}

const PrivateRoute = (data) => {
  let {
    component: Component,
    entity,
    policies,
    target,
    checkingRegion,
    regionInvalidUrl,
    ...rest
  } = data

  saveUserHistory(_.get(data, 'computedMatch', { url: '', params: { region: '' } }))

  if (entity && !policies.find((item) => item === target + "-" + entity)) {
    return (
      <Route {...rest} render={(props) => <AccessDenied {...props} />} />
    )
  }

  if (!checkingRegion && regionInvalidUrl) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <PublicLayout>
            <LoginContainer {...props} component="regionInvalid" />
          </PublicLayout>
        )}
      />
    )
  }

  const redirectUrl = (match) => {
    let data = { url: '', redirect: false }

    const routes = [
      "/dam/:region/:division",
      "/dam/:region/:division/:segment",
      "/dam/:region/:division/:segment/:category",
    ]

    if (!localStorage.getItem("user_auth")) {
      data['url'] = '/login'
      data['redirect'] = true
    }

    if (rest.modeViewitens === 'view-items' && routes.some(i => i === match.path)) {
      data['url'] = `/dam/${match.params.region}`
      data['redirect'] = true
    }

    return data
  }

  return (
    <PrivateLayout {...data}>
      <Route
        {...rest}
        render={(props) => {
          const dataUrl = redirectUrl(props.match)

          if (!dataUrl.redirect) {
            return <Component {...props} />
          } else {
            if (props.location) {
              sessionStorage.setItem("pathAfterLogin", props.location.pathname)
            }
            return (
              <Redirect
                to={{ pathname: dataUrl.url, state: { from: props.location } }}
              />
            )
          }
        }}
      />
    </PrivateLayout>
  )
}

const mapStateToProps = (state) => {
  return {
    checkingRegion: state.appReducer.checkingRegion,
    regionInvalidUrl: state.appReducer.regionInvalid,
    dataRegions: state.appReducer.userData,
    navigationModeView: state.appReducer.navigation_view_mode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkRegion: () => dispatch(AppActions.checkRegion()),
    resetSession: () => dispatch(ItemsActions.resetSession()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router))
