import React, { useCallback } from "react"
import _ from 'lodash'
import MaskedInput from "react-maskedinput"
import { useTranslation } from "react-i18next"
import { Grid, FormHelperText, TextField } from "@material-ui/core"
import { useStyles } from "./styles"

import InputDatePicker from 'components/InputDatePicker'
import ReactSelect from './ReactSelect'

import Utils from "utils/Utils"

const CategoryAttributes = ({
  attributes = [],
  dataFields = {},
  errorFields = {},
  changeAttributes,
  isDisabled = false,
  isColumnChip = false,
  optionCreateMetadata,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleChangeAttribute = useCallback((evt, id) => {
    const value = _.get(evt, 'target.value', evt)
    changeAttributes(id, value)
  }, [changeAttributes])

  const formatMaskValue = useCallback((mask) => {
    const newMask = mask.replace(/[\\"]/g, '').replace(/X/g, "#").replace(/x/g, "*")
    return newMask
  }, [])

  return attributes.length > 0 && (
    <>
      {attributes.map((attribute, key) => {
        const disabled = (attribute.blocked && isDisabled)
        if (attribute.field_type === "list") {
          return <ReactSelect
            key={key}
            isMulti={attribute.list_type === "multiple"}
            data={{
              ...attribute,
              isColumnChip,
              isDisabled: disabled,
            }}
            value={dataFields[attribute.id]}
            optionCreateMetadata={optionCreateMetadata}
            handleChangeAttribute={handleChangeAttribute}
            error={Boolean(errorFields[attribute.id]) ? errorFields[attribute.id] : ""}
          />
        }

        if (attribute.field_type === "date") {
          const defaultValue = Boolean(dataFields[attribute.id]) ? new Date(dataFields[attribute.id]) : null
          return <Grid item xs={12} key={key}>
            <InputDatePicker
              title={_.get(attribute, 'name', _.get(attribute, 'slug', ''))}
              isDisabled={disabled}
              error={Boolean(errorFields[attribute.id])}
              errorMsg={t(errorFields[attribute.id])}
              onChange={(evt) => handleChangeAttribute(evt, attribute.id)}
              defaultValue={defaultValue}
            />
          </Grid>
        }

        return (
          <Grid item xs={12} key={key}>
            <>
              {attribute.mask_value && Utils.maskValid(attribute.mask_value) ? (
                <div className={Boolean(errorFields[attribute.id]) ? `${classes.maskedInput} ${classes.error} ` : classes.maskedInput}>
                  <label
                    style={{ opacity: disabled ? .3 : 1, color: Boolean(errorFields[attribute.id]) && '#f44336' }}
                  >{Boolean(attribute.required) ? `${attribute.name} *` : attribute.name}</label>
                  <MaskedInput
                    disabled={disabled}
                    style={{ padding: disabled ? "10.5px 14px" : "17.5px 14px", fontSize: "1em" }}
                    type="text"
                    mask={formatMaskValue(attribute.mask_value)}
                    value={dataFields[attribute.id]}
                    name={attribute.slug}
                    autoComplete={attribute.slug}
                    onChange={(evt) => handleChangeAttribute(evt, attribute.id)}
                    required={Boolean(attribute.required)}
                  />
                  {
                    Boolean(errorFields[attribute.id]) ?
                      <FormHelperText style={{ color: '#f44336', marginLeft: 11 }} component="span">
                        {t(errorFields[attribute.id])}
                      </FormHelperText> :
                      <FormHelperText style={{ color: 'inherit', marginLeft: 11 }} component="span">
                        {`${t('common:Exemplo')}: ${attribute.mask_value.replace(/[\\"]/g, '')}`}
                      </FormHelperText>
                  }
                </div>
              ) : (
                <>
                  <TextField
                    value={dataFields[attribute.id]}
                    name={_.get(attribute, 'name', _.get(attribute, 'slug', ''))}
                    type={_.get(attribute, 'type', 'text')}
                    onChange={(evt) => handleChangeAttribute(evt, attribute.id)}
                    label={_.get(attribute, 'name', _.get(attribute, 'slug', ''))}
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={disabled}
                    required={Boolean(attribute.required)}
                    error={Boolean(errorFields[attribute.id])}
                    helperText={Boolean(errorFields[attribute.id]) ? t(errorFields[attribute.id]) : null}
                  />
                </>
              )}
            </>
          </Grid>
        )
      })}
    </>
  )
}

export const attributesForInput = (fields, attributes) => {
  let formattedAttributes = []
  let unsetAttributes = []
  Object.entries(fields).forEach(([key, value]) => {
    const find = attributes.find(i => i.id === key)
    const newValue = _.get(value, 'value', value)
    if (Boolean(find) && Boolean(newValue) && newValue.toString().length > 0) {
      formattedAttributes.push({
        "value": Array.isArray(value) ? value.map(i => i.value) : newValue,
        "attribute_id": key,
        "label": find.name
      });
    } else {
      unsetAttributes.push(key)
    }
  })
  return [formattedAttributes, unsetAttributes]
}

export const createEditForm = (entityAttributes, schemaAttributes) =>
  _.reduce(schemaAttributes, (obj, attr) => {
    const entityData = entityAttributes.find(i => i.attribute_id === attr.id)
    if (entityData) {
      obj[attr.id] = getValueFromEntityMetadataRecord(attr, entityData)
    } else {
      obj[attr.id] = (attr.field_type === "list") ? [] : ''
    }

    return obj
  }, {})

const getValueFromEntityMetadataRecord = (attr, obj) => {
  const value = _.get(obj, "value", [])

  if (attr.field_type !== "list") {
    return Array.isArray(value) ? _.get(obj, "value[0]", "") : _.get(obj, "value", "")
  }
  if (Array.isArray(value)) {
    return value.map(i => ({ value: i, label: i, id: attr.id }))
  } else {
    return { value, label: value, id: attr.id }
  }
}


export const createForm = (_data) => {
  let fields = {}
  for (let key in _data) {
    let obj = _data[key]
    fields[obj.id] = (obj.field_type === "list") ? [] : ''
  }
  return fields
}

export const validation = (fields, data) => {
  let dataErrorForm = {}
  let error = false

  Object.entries(fields).forEach(([key, value]) => {
    const find = data.find(i => i.id === key)

    if (Boolean(find)) {
      if (find.field_type === 'number' && !Boolean(_.get(find, 'mask_value', '').trim())) {
        if (isNaN(value)) {
          dataErrorForm[key] = 'common:Somente números'
          error = true
        }
      }
      if (Boolean(find.required)) {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            dataErrorForm[key] = 'common:Campo obrigatório'
            error = true
          }
        }
        if (!Boolean(value)) {
          dataErrorForm[key] = 'common:Campo obrigatório'
          error = true
        }
      }
    }
  })

  if (error) {
    return dataErrorForm
  } else {
    return {}
  }
}

export default CategoryAttributes
