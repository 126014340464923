import React from 'react'
import ListDefault from "../Core/List/DataGridCustom"
import ListWrapper from '../Core/List/ListWrapper'
import { getColumnDelete, getColumnEdit, getColumnDetails, getColumnAttributeUsage } from "../Core/utils"
import { settings } from "./_settings.js"
import connect from "react-redux/es/connect/connect"
import { mapStateToPropsToSettings } from "../../../utils/Flux"
import { withTranslation } from "react-i18next"

class List extends ListDefault {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
    }
    this.superRender = super.render
  }

  getLine(item) {
    let line = {
      id: item.id,
      name: item.name,
      field_type: item.field_type,
    }
    return line
  }

  getColumns() {
    const { t } = this.props

    let columns = [
      getColumnDetails("name", t("common:Nome"), 310),
      getColumnDetails("field_type", t("common:Tipo"), 210),
    ]
    
    columns.push(
      getColumnAttributeUsage((id) => {
        this.props.history.push(`/manager/${this.state.route}/usage/${id}`)
      }, t)
    )

    columns.push(
      getColumnEdit((id) => {
        this.props.history.push(`/manager/${this.state.route}/edit/${id}`)
      }, t)
    )

    columns.push(
      getColumnDelete((id, name) => {
        this.setState({
          ...this.state,
          deleteDialogOpen: true,
          idToDelete: id,
          nameToDelete: name,
        })
      }, t)
    )

    return columns
  }

  render(){
    const { hasFab, area, title, route } = this.state

    return (
      <ListWrapper 
        area={area}
        title={title.singular}
        route={route}
        hasFab={hasFab}
      >
        {this.superRender()}
      </ListWrapper>
    )
  }
}

export default connect(
  mapStateToPropsToSettings,
  null
)(withTranslation(["manager", "common"])(List))
