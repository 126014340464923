import React, { useEffect } from 'react'
import _ from 'lodash'
import { Helmet } from "react-helmet"
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Grid } from "@material-ui/core"

import AccessDenied from "components/AccessDenied"
import AssetIntegraProvider, { useDataAssetIntegra } from "./context"
import HeaderBreadcrumbs from "./HeaderBreadcrumbs"
import NavMenu from "./Nav"
import RenderComponentDetails from './RenderComponentDetails'
import RenderComponentArea from './RenderComponentArea'

import Utils from "utils/Utils"

import { useStyles } from "./styles"

const ItemContent = () => {
  const { getDataItem, stateItem } = useDataAssetIntegra()
  const classes = useStyles()

  useEffect(() => {
    let isMounted = true
    if (isMounted && _.isEmpty(stateItem.data)) {    
      getDataItem()
    }

    return () => {
      isMounted = false
    }
  }, [getDataItem, stateItem.data])

  return (
    <>
      {
        _.get(stateItem, 'permission.status', false) ? (
          <AccessDenied
            msg={_.get(stateItem, 'permission.msg', '')}
            title={_.get(stateItem, 'permission.title', '')}
          />
        ) : (
          <Grid className={classes.root}>
            <Grid item className={classes.view}>
              <HeaderBreadcrumbs />

              <RenderComponentArea />
            </Grid>
            <Grid item className={classes.info}>
              <RenderComponentDetails />
            </Grid>
            <Grid item className={classes.infoNav}>
              <NavMenu />
            </Grid>
          </Grid>
        )

      }
    </>
  )
}

const Item = ({ match }) => {
  const { hasInvalidRegion } = useSelector((state) => state.appReducer)
  const history = useHistory()

  if (hasInvalidRegion) {
    history.push("/region-invalid")
    return
  }

  return (
    <AssetIntegraProvider slugItem={match.params.item}>
      <Helmet>
        <title>{Utils.getTitleFromUrl()}</title>
        <meta name="description" />
      </Helmet>
      <ItemContent />
    </AssetIntegraProvider>
  )
}

export default Item