import React from "react";
import axios from "axios";
import connect from "react-redux/es/connect/connect";
import {responseError} from "../../utils/Ajax";
import {mapStateToPropsToDam, mapDispatchToPropsToDam} from "../../utils/Flux";
import { withTranslation } from 'react-i18next';

import { Wrapper } from './Template'

import _ from 'lodash'

class Segments extends React.Component {
  constructor(props) {
    super(props);
    const {t} = props;

    this.state = {
      loading: false,
      data: [],
      breadcrumbs: [],
      divisionSlug: props.match.params.division,
      message: t("manager:carregando") + '...',      
      title: ''
    };
    this.responseError = responseError.bind(this);
    this.region = props.match.params.region || '';
    this._isMounted = false;
  }

  componentDidMount() {
    const { hasInvalidRegion, invalidateRegion } = this.props
    this._isMounted = true

    if (hasInvalidRegion) {
      invalidateRegion()
    } else {
      this.getData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    const headerPrev = prevProps.requestHeaders.headers
    const headerNext = this.props.requestHeaders.headers   
    
    if(headerPrev['X-Localization'] !== headerNext['X-Localization']){
      this.getData()
    }   
  }

  getData() {
    const SELF = this;
    const {requestHeaders, pathServer, triggerError, t} = SELF.props;
    const {divisionSlug} = SELF.state;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = {...requestHeaders, cancelToken: source.token}

    axios.post(pathServer + "/segment/item", {"division.slug": divisionSlug}, options)
      .then(
        resp => {
          const message = resp.data.items.length ? t("common:Dados retornados com sucesso") : t('common:Nenhum resultado encontrado');
          const data = resp.data.items;
          const title = resp.data.items.length <= 1 ? 'Segmento' : 'Segmentos'
          SELF._isMounted && SELF.setState({...SELF.state, loading: false, data, message, title}, SELF.getBreadcumb())
        },
        err => SELF.responseError(err)
      ).catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          triggerError(err)
        }
      });
  }

  getBreadcumb() {
    const SELF = this;
    const {requestHeaders, pathServer, triggerError} = SELF.props;
    const {divisionSlug} = SELF.state;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const options = {...requestHeaders, cancelToken: source.token}

    axios.post(pathServer + "/division/find", {slug: divisionSlug}, options)
      .then(function (resp) {
          let newState = {...SELF.state};
          let item = resp.data.items[0];
          newState.breadcrumbs = [
            {label: item.name, url: `/dam/${SELF.region}/${item.slug}`}
          ];
          SELF._isMounted && SELF.setState(newState);
        }, err => SELF.responseError(err)
      ).catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          triggerError(err);
        }
      });
  }

  render() {  

    const dataPropsCard = (item) => ({
      slug: _.get(this.state, 'divisionSlug', ''),
      slugSegment: item.slug || '',     
    })  

    return (
        <Wrapper          
          pageDescription={_.get(this.props, 'data.description', '')}
          loading={this.state.loading}
          data={_.get(this.state, 'data', [])}
          region={_.get(this.props, 'region', '')}
          breadcrumbs={this.state.breadcrumbs}
          dataPropsCard={dataPropsCard} 
          title={this.state.title} 
        />
    );
  }
}

export default connect(mapStateToPropsToDam, mapDispatchToPropsToDam)(withTranslation('common')(Segments));
