import React, { useCallback, useMemo, useState } from "react"
import _ from 'lodash'
import axios from 'axios'
import i18n from "i18next"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { Button, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTheme } from '@material-ui/core/styles'

import View from './view'
import AlertDialogSimple from 'components/Alert/AlertDialogSimple'

import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded'

import AppActions from "flux/actions/AppActions"
import { useDataItems } from 'pages/DAM/Items/context'

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiDialog-paperWidthSm": {
            margin: "0 !important",
            padding: "40px",
        }
    }
}))

function MenuItens({ totalMarkedItems = 0, totalResultItems = 0, isPageItens }) {
    const [state, setState] = useState({
        loading: false, openDialogAlert: false, openDialog: false, dialogCount: { open: false, total: 0 }
    })
    const { params_filter_items } = useSelector(state => state.appBarReducer)
    const { requestHeaders, pathServer, userData: { region } } = useSelector((state) => state.appReducer)
    const { totalItems, getParamsToItems, showItemsSelected } = useDataItems()

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const theme = useTheme()
    const classes = useStyles()
    const history = useHistory()

    const handleClearItemList = useCallback(() => {
        dispatch(AppActions.clearMarkedItems())    
    }, [dispatch])
    
    const handleShowItemsSelected = useCallback(() => {
        if(showItemsSelected) {
            history.goBack()
        } else {
            history.push(`/items/${region}`)  
        }      
    }, [history, showItemsSelected, region])

    const handleCloseDialogState = () => {
        setState(prev => ({ ...prev, openDialogAlert: false }))
    }

    const getParamsAllDownload = useCallback(() => {
        const paramsToItems = getParamsToItems()
        delete paramsToItems.limit
        delete paramsToItems.only
        delete paramsToItems.page

        return { ...paramsToItems, ...params_filter_items }
    }, [getParamsToItems, params_filter_items])

    const getCountItensForBD = useCallback(() => {
        const params = getParamsAllDownload()
        setState(prev => ({ ...prev, loading: true }),
            axios.post(`${pathServer}/item/count`, params, requestHeaders)
                .then(resp => {
                    if (_.get(resp, 'data.total', 0) > 0) {
                        setState(prev => ({
                            ...prev,
                            dialogCount: { open: true, total: _.get(resp, 'data.total', 0) },
                            loading: false
                        }))
                    }
                })
                .catch(err => {
                    setState(prev => ({ ...prev, loading: false }))
                    console.log(err)
                })
        )
    }, [getParamsAllDownload, requestHeaders, pathServer])

    const handleCloseDialogCount = () => {
        setState(prev => ({ ...prev, dialogCount: { open: false, total: 0 } }))
    }

    const constants = useMemo(() => ({
        options: [
            {
                id: 0,
                title: showItemsSelected ? t("common:Voltar") : t("DAM:Mostrar selecionados"),
                func: handleShowItemsSelected,
                disabled: ((totalMarkedItems === 0))
            },
            {
                id: 1,
                title: t("DAM:Total de Itens"),
                func: getCountItensForBD,
                disabled: !totalItems.exceeded_limit
            },
            {
                id: 2,
                title: t("DAM:Limpar"),
                func: handleClearItemList,
                disabled: (totalMarkedItems === 0)
            },
        ],
        paramsAllDownalod: getParamsAllDownload(),
    }), [
        t,
        getCountItensForBD,
        handleShowItemsSelected,       
        handleClearItemList,
        totalMarkedItems,
        showItemsSelected,
        getParamsAllDownload,
        totalItems.exceeded_limit
    ])

    return (
        <>
            <View
                totalResultItems={totalResultItems}
                options={constants.options}
                totalMarkedItems={totalMarkedItems}
                isPageItens={isPageItens}
                loading={state.loading}
                exceededLimit={totalItems.exceeded_limit}
            />

            <AlertDialogSimple
                open={state.openDialogAlert}
                icon={<NotificationsRoundedIcon id="main" />}
                handleClose={handleCloseDialogState}
                title={t(`common:Download em andamento`)}
                description={t(`common:Uma notificação será enviada quando os itens estiverem prontos para o download.`)}
            />

            <Dialog
                open={state.dialogCount.open}
                onClose={handleCloseDialogCount}
                aria-labelledby="alert-dialog-title-count"
                aria-describedby="alert-dialog-description-count"
                className={classes.root}
            >
                <DialogTitle id="alert-dialog-title-count" style={{ color: _.get(theme, 'palette.primary.main', ""), padding: 0 }}>
                    {t("DAM:Total de Itens")}
                </DialogTitle>
                <DialogContent style={{ padding: '10px 0 0 0' }}>
                    <DialogContentText id="alert-dialog-description-count">
                        {_.get(state, 'dialogCount.total', 0).toLocaleString(i18n.language)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: 0 }}>
                    <Button onClick={handleCloseDialogCount} variant="outlined" color="primary" autoFocus>
                        {t("common:Fechar")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default MenuItens
