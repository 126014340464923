import React from "react"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import {
  Grid,
  Typography,
  useMediaQuery
} from "@material-ui/core"

import { useStyles } from "./styles"

import Login from "./login"
import ForgotPassword from "./forgotPassword"
import NewPassword from "./newPassword"
import RouteInvalid from "./routeInvalid"
import RegionInvalid from "./regionInvalid"

const DefaultLayout = ({
  t,
  handleSubmit,
  component,
  responseStatus,
  loading,
  loadingLogin,
  handleAccessRequest,
}) => {
  let history = useHistory()
  const handleHistory = (text) => history.push(text)
  const {data, csrf_token} = useSelector((state) => state.appReducer)
  const { user_auth } = useSelector((state) => state.appReducer.userData)
  const mobile = useMediaQuery('(max-width:600px)');
  const classes = useStyles(data)

  const description = () => {
    switch (component) {
      case "newPassword":
        return t("Redefinição de senha")
      case "forgotPassword":
        return t("Recuperação de senha")
      default:
        return data.description
    }
  }

  const ComponentSelected = () => {
    switch (component) {
      case "forgotPassword":
        return (
          <ForgotPassword
            data={data}
            loading={loading}
            handleSubmit={handleSubmit}
            responseStatus={responseStatus}
            handleHistory={handleHistory}
          />
        )
      case "routeInvalid":
        return (
          <RouteInvalid
            data={data}
            userAuth={user_auth}
            handleHistory={handleHistory}
          />
        )
      case "regionInvalid":
        return (
          <RegionInvalid
            data={data}
            userAuth={user_auth}
            handleHistory={handleHistory}
          />
        )
      case "newPassword":
        return (
          <NewPassword
            data={data}
            handleSubmit={handleSubmit}
            loading={loading}
            responseStatus={responseStatus}
            handleHistory={handleHistory}
          />
        )

      default:
        return (
          <Login
            csrf_token={csrf_token}
            data={data}
            loading={loadingLogin}
            handleHistory={handleHistory}
            handleSubmit={handleSubmit}
            handleAccessRequest={handleAccessRequest}
          />
        )
    }
  }



  return (
    <>
      {
        mobile ? (
          <Grid item className={classes.papers}>
            {ComponentSelected()}
          </Grid>
        ) : (
          <>
            <Grid item className={classes.wrapper}>
              <Grid item className={classes.container}>
                <Grid item className="wrapperTitles">
                  <Typography variant="h1">{data.platform_name}</Typography>
                  <Typography variant="h2">{description()}</Typography>
                </Grid>
                <Grid item className={classes.papers}>
                  {ComponentSelected()}
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      }
    </>
  )
}
export default withTranslation(["common"])(DefaultLayout)
