import React from 'react'

import { useTranslation } from 'react-i18next';
import { Grid, Typography, Paper, useMediaQuery, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '20px 10px 0 10px'
    },
    paper: {
        ...theme.mixins.gutters(),
        padding: 20,
        borderRadius: 0,
        '& h5, h6': {
            color: "#666",
        },
        '& h6': {
            fontWeight: 500
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 135,
        border: `2px dashed ${theme.palette.primary.main}`,
        cursor: props => props.disable ? 'default' : 'pointer',
        opacity: props => props.disable ? .3 : 1
    },
    mobile: {
        display: 'flex',
        justifyContent: 'center',
        '& button': {
            width: '100%'
        }
    }
}))

const ContentImport = React.forwardRef((props, ref) => {
    const classes = useStyles({ disable: props.isDisabled });
    const { t } = useTranslation();
    const mobile = useMediaQuery('(max-width:600px)')

    return (
        <Grid style={{ padding: props.paddingControl ? 0 : '20px 10px 0 10px' }} item xs className={classes.root} id='root_content_import' ref={ref} data-disableselect={true}>
            {
                !mobile ? (
                    <Paper style={{ padding: props.paddingControl ? 0 : 20 }} className={classes.paper} elevation={0} id='root_content_paper_import'>
                        <Grid container alignItems="center">
                            {
                                !(props.paddingControl) &&
                                <>
                                    <Grid item xs>
                                        <Typography gutterBottom variant="h5">
                                            {t("DAM:Importar arquivos")}
                                        </Typography>
                                    </Grid>                                
                                </>
                            }
                            <Grid item xs={12} className={classes.content}
                                onClick={(evt) => {
                                    props.onClick(evt)
                                }}
                            >
                                <Typography variant={props.paddingControl ? 'body2' : 'subtitle1'} gutterBottom>
                                    {t("DAM:Solte os arquivos aqui ou clique para fazer o upload")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                ) : (
                    <div className={classes.mobile} data-disableSelect={true}>
                        <Button variant="contained" color="primary" {...props}>
                            {t("DAM:Importar arquivos")}
                        </Button>
                    </div>
                )
            }

        </Grid>
    )
})

export default ContentImport