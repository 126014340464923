import {toast} from "react-toastify";
import _ from 'lodash'

export function responseError(err) {
  var showed = false
  const showError = (err) => {
    if(_.get(err, 'response.data', null)) {
      const key = Object.keys(_.get(err, 'response.data', []))[0];
      if(_.get(err, `response.data.${key}`)) {
        toast.error(_.get(err, `response.data.${key}`));
        return true;
      }
    }
    return false;
  }

  switch (parseInt(err.response.status, 10)) {
    case 401:
      this.props.closeDialogExpiredToken();
      break;
    case 403:
      if(_.get(err, 'response.data.reason', '') === 'PrivacyPolicyNotAccepted') {
        this.props.openDialogPolicy();
      } else {
        showError(err)
      }
      break;
    case 405:
      toast.error("Não foi possível carregar as paginas. O webservice consumido não possui o método solicitado.");
      break;
    default:
      showed = showError(err)
      if(!showed)
        toast.error(_.get(err, 'response.headers.x-message', 'Erro inesperado'));
  }
  this.setState({...this.state, loading: false})
}