import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    paperForm: {
        padding: 20,
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 50,
        '& .full-width': {
            width: '100%'
        },
        '& .form-field': {
            width: '100%',
            position: 'relative',
            color: '#666',
            '& label': {
                display: 'block',
                fontSize: '.94rem',
                marginBottom: 5,
                fontWeight: 500,
            },
            '& label.section': {
                fontSize: '1.125rem',
            },
            '& p.subtitle': {
                fontSize: '0.875rem',
                marginTop: 5,
                marginBottom: 10,
                lineHeight: '1.2rem',
            },
            '& .info': {
                opacity: 0.7,
                display: 'block',
                fontSize: 11,
                margin: '5px 0',
                fontWeight: 400,
            },

            '& input, textarea': {
                width: '100%',
                borderRadius: 4,
                textAlign: 'left',
                fontSize: 15,
                border: '1px solid #bababa',
                padding: '10px 12px',
                '&.has-error ': {
                    borderBottom: '1px solid #a9021b',
                },
                '&::placeholder': {
                    fontSize: '0.875rem',
                },
            },
            'select': {
                width: '100%',
                borderRadius: 4,
                textAlign: 'left',
                fontSize: 15,
                color: '#666',
                border: '1px solid #bababa',
                padding: '10px 8px',
            }
        }
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    richText: {
        minHeight: '150px',
        border: '1px solid #bababa',
        borderRadius: '4px',
    },
    categoryAttributes: {
        width: '100%',
        '& .form-field, .MuiGrid-root': {
            marginTop: 20
        }
    },
    fieldError: {
        color: '#f44336',
        margin: 0,
        marginLeft: 11,
        fontSize: '0.75rem',
        marginTop: 3,
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
    },
    fieldDateRoot: {
        margin: '15px 0 5px 0',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column'
        },
        '& div.select': {
            width: '30%',
            height: 48,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            },
        },
        '& .select-custom__value-container': {
            padding: '18.6px 14px'
        }
    },
    fieldDate: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        width: '70%',
        color: '#666',
        '& :disabled': {
            color: 'currentColor'
        },
        '& input': {
            color: '#666',
            '&::-webkit-calendar-picker-indicator': {
                filter: 'invert(42%) sepia(0%) saturate(264%) hue-rotate(165deg) brightness(92%) contrast(89%)'
            }
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            '& span.text-range': {
                margin: '20px 0',
            },
        },
    },
    wrapperRichText: {
        minHeight: "200px",
        border: "1px solid #bababa",
        borderRadius: "4px",
    },
    labelDescription: {
        top: "12px",
        margin: '0!important',
        position: "relative",
        background: "#fff",
        display: 'inline-block !important',
        left: "11px",
        padding: "0 3px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: 1,
        letterSpacing: '0.00938em',
        zIndex: 1
    }
}))