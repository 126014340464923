const conf = {
  stages: [
    {
      hosts: [
        "homol.yapoli.com.s3-website-sa-east-1.amazonaws.com",
        "homol.yapoli.com",
      ],
      domain: "corteva.yapoli.com",
      lang: "pt",
    },
    {
      hosts: ["media-homol.yapoli.com"],
      domain: "media.yapoli.com",
      lang: "pt",
    },
    {
      hosts: ["santander-homol.yapoli.com"],
      domain: "santander.yapoli.com",
      lang: "pt",
    },
    {
      hosts: ["localhost", "d134tz6ofoqjoa.cloudfront.net"],
      domain: "media.yapoli.com",
      lang: "pt",
    },
    {
      hosts: ["havaianas.yapoli.com"],
      domain: "havaianas.yapoli.com",
      lang: "en",
    },
    {
      hosts: ["connect.havaianas.com"],
      domain: "connect.havaianas.com",
      lang: "en",
    },
    {
      hosts: ["estebanecheverria.yapoli.com"],
      domain: "estebanecheverria.yapoli.com",
      lang: "es",
    },
  ],
  flags: {
    multiSelect: false,
  },
}

export default conf
