import React from 'react'

import { useTranslation } from 'react-i18next'

import { Paper, Tabs, Tab } from '@material-ui/core';

import WrapperRows from './WrapperRows'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

export default function SimpleTabs({ changeRegion, handleMenuClose }) {
    const [value, setValue] = React.useState(0)

    const { t } = useTranslation()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Paper square>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}
                    onChange={handleChange}
                >                    
                    <Tab label={t('Geral')} />
                </Tabs>
            </Paper>          

            <TabPanel value={value} index={0}>
                <WrapperRows
                    changeRegion={changeRegion}
                    handleMenuClose={handleMenuClose}
                    type='notification'
                />
            </TabPanel>
        </div>
    );
}