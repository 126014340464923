import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
    rootDialog: {
        display: "flex",
    },
    rootBoxSidebar: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#eee",
        minWidth: 340,
        "& div.header": {
            display: "flex",
            alignItems: "center",
            padding: "16px 24px"
        },
        "& div.content": {
            display: "flex",
            flexDirection: "column",
            justifyContent: 'space-between',
            height: '100%',
            "& div.footer": {
                display: "flex",
                justifyContent: "flex-end",
                padding: 16
            }
        },
    },
    paperAction: {
        position: "relative",
        padding: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        minWidth: 191,
        minHeight: 64,
        marginTop: 12,
        "& p": {
            fontWeight: 700,
            color: theme.palette.grey700
        }
    },
    triangleUp: {
        width: 20,
        height: 20,
        position: 'absolute',
        top: '-15px',
        zIndex: 2,
        marginLeft: 16
    },
    rootTags: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: "0 20px",
        overflow: 'auto',
        maxHeight: 400,
        '& > *': {
            margin: 4,
        },
    }
}))