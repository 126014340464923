import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useStyles } from "../styles"
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

const DialogConfirm = ({
  title,
  description,
  open,
  t,
  handleClose,
  handleConfirm,
  listNames = false,
  icon,
  children
}) => {
  const { palette } = useSelector((state) => state.appReducer);
  const classes = useStyles({ palette });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          {icon ? icon : <AccountCircleRoundedIcon id="main" />}
          <div>{title}</div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: '0' }}>
        {
          !children
            ? (
              <DialogContentText id="alert-dialog-description">
                {description}
              </DialogContentText>
            ) : (
              <>
                {children}
              </>
            )
        }
        {
          listNames &&
          <DialogContentText>
            {listNames}
          </DialogContentText>
        }
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            style={{ height: 40, width: 115, marginRight: 20 }}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {t("common:Cancelar")}
          </Button>
          <Button
            style={{ height: 40, width: 115 }}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            {t("common:Sim")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation(["DAM"])(DialogConfirm);
