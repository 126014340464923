import React from "react"
import axios from "axios"
import { Helmet } from "react-helmet"
import Loading from "../../components/Loading"
import { compose } from "recompose"
import { withTranslation } from "react-i18next"
import { withSnackbar } from "notistack"

import Settings from 'utils/Settings'
import AppActions from "../../flux/actions/AppActions"
import connect from "react-redux/es/connect/connect"

import Login from "../../components/LoginLayout"

import { redirect } from 'utils/Helpers'

import { contentSnackbar } from 'utils/Helpers'

class LoginContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      loading_login: false,
      component: props.component,
      responseStatus: Settings.RESPONSE_STATUS.NOT_SENT,
    }
    this._isMounted = false
    this.formSubmit = this.formSubmit.bind(this)
    this.setUserData = this.setUserData.bind(this)
  }


  componentDidMount() {
    this._isMounted = true
    this.checkAndLoginToPlatform()
  }


  checkAndLoginToPlatform() {
    const {
      history,
      component,
      user_data,
    } = this.props

    if (
      localStorage.getItem("user_auth") &&
      component !== "logout" &&
      component !== "newPassword" &&
      component !== "regionInvalid"
    ) {
      history.push(`/dam/${user_data.region}`)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  stateOk = (callback) => {
    this._isMounted &&
      this.setState(
        {
          ...this.state,
          responseStatus: Settings.RESPONSE_STATUS.SUCCEEDED,
          loading: false,
        },
        () => {
          if (callback) callback()
        }
      )
  }

  showError = (message, params = {}) =>
    this.props.enqueueSnackbar(message, {
      ...params,
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    })

    stateError = (error, callback) => {
      const { t } = this.props

      if (error.response) {
        this.showError(
          error.response.data.message || error.response.headers["x-message"]
        )
      } else {
        this.showError(error.message)
      }

      this._isMounted &&
        this.setState(
          {
            ...this.state,
            loading: false,
            responseStatus: Settings.RESPONSE_STATUS.FAILED,
          },
          () => {
            if (error.response) {
              if (
                !error.response.data.message &&
                !error.response.headers["x-message"]
              ) {
                this.showError(
                  t("manager:Erro ao processar, contate o administrador.")
                )
              }
            }

            if (callback) callback()
          }
        )
    }

  async setUserData(respData) {
    const {
      updateUserData,
      history,
      pathClient,
      changeRegion
    } = this.props

    const newData = {
      ...respData,
      client_slug: respData.client_slug,
    }

    const res = await updateUserData(newData)

    if (res.token) {
      const requestHeaders = {
        headers: {
          Authorization: res.token
        }
      }

      redirect(res.data.region, requestHeaders, history, pathClient, changeRegion)
    }
  }

  submitLogin = (email, password, csrf_token) => {
    const {
      data,
      requestHeaders
    } = this.props

    axios.defaults.headers.common['X-Localization'] = requestHeaders.headers['X-Localization'];

    this._isMounted &&
      this.setState({
        ...this.state,
        loading_login: true
      })

    axios
      .post(
        Settings.URL_API + "/api/" + data.client_slug + "/login",
        {},
        {
          auth: {
            username: email,
            password: password
          },
          headers: {
            'X-XSRF-TOKEN': csrf_token
          }
        }
      )
      .then(
        (resp) =>
          this.stateOk(() => this.setUserData(resp.data)),
        (error) => {
          this._isMounted &&
            this.setState({
              ...this.state,
              loading_login: false
            })
          this.stateError(error)
        }
      )
      .catch((err) => {
        this._isMounted &&
          this.setState({
            ...this.state,
            loading_login: false
          })
        this.stateError(err)
      })
  }

  submitForgotPassword = (email) => {
    if (Boolean(email)) {
      const { data, requestHeaders } = this.props
      this._isMounted &&
        this.setState({
          ...this.state,
          loading: true,
          responseMsg: "",
          typeMsg: "",
        })

      axios
        .post(
          Settings.URL_API + "/forget-password",
          { email, client_slug: data.client_slug },
          requestHeaders
        )
        .then(
          () => this.stateOk(),
          (error) => this.stateError(error)
        )
        .catch((err) => this.stateError(err))
    } else {
      this.showError(`Email: ${this.props.t("common:Campo obrigatório")}`)
    }
  }

  submitNewPassword = (password, token) => {
    const { requestHeaders } = this.props
    this._isMounted &&
      this.setState({
        ...this.state,
        loading: true,
      })

    axios
      .post(Settings.URL_API + "/reset-password/" + token, { password }, requestHeaders)
      .then(
        () => {
          this.stateOk()
        },
        (err) => {
          if (err.response.data.hasOwnProperty('error')) {
            this.showError(err.response.data.error,
              {content: (snackbarId, message) => contentSnackbar(snackbarId, message, this.props.t, this.props.closeSnackbar)}
            )
          } else {
            const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : this.props.t("Erro inesperado. Contate o suporte")
            this.showError(message, {content: (snackbarId, message) => contentSnackbar(snackbarId, message, this.props.t, this.props.closeSnackbar)})
          }
          this._isMounted &&
              this.setState({
                ...this.state,
                loading: false,
              })
        }
      )
      .catch((err) => this.stateError(err))
  }

  formSubmit = (e, { email, password, token }) => {
    e.preventDefault()

    switch(this.state.component) {
      case "newPassword":
        this.submitNewPassword(password, token)
        break;
      case "forgotPassword":
        this.submitForgotPassword(email)
        break;
      default:
        this.submitLogin(email, password, token)
    }
  }

  handleAccessRequest = () => {
    this.props.history.push("/access-request")
  }

  description = () => {
    const { component, t } = this.props

    switch (component) {
      case "newPassword":
        return t("login:Redefinição")
      case "forgotPassword":
        return t("login:Recuperação")
      case "logout":
        return "Logout"
      case "routeInvalid":
        return t("login:Rota inválida")
      case "regionInvalid":
        return t("login:Região inválida")
      default:
        return "Login"
    }
  }

  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {data.platform_name} | {this.description()}
          </title>
          <meta name="description" content={data.description} />
        </Helmet>
        <Loading showLoading={this.state.loading} />
        <Login
          component={this.state.component}
          loading={this.state.loading}
          loadingLogin={this.state.loading_login}
          handleSubmit={this.formSubmit}
          responseStatus={this.state.responseStatus}
          handleAccessRequest={(source) => this.handleAccessRequest(source)}
        />
      </React.Fragment>
    )
  }
}

export const mapStateToPropsToDam = ({ appReducer }) => {
  return {
    requestHeaders: appReducer.requestHeaders,
    pathClient: appReducer.pathClient,
    data: appReducer.data,
    user_data: appReducer.userData,
  }
}
export const mapDispatchToPropsToDam = (dispatch) => {
  return {
    triggerError: (exception) => dispatch(AppActions.triggerError(exception)),
    updateUserData: (userData) => dispatch(AppActions.updateUserData(userData)),
    changeRegion: (slug, data, callback) =>
      dispatch(AppActions.changeRegion(slug, data, callback)),
  }
}

export default compose(connect(mapStateToPropsToDam, mapDispatchToPropsToDam))(
  withTranslation(["login", "common"])(withSnackbar(LoginContainer))
)
