import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"

import { convertToRaw } from 'draft-js'
import { useStyles } from './styles'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"
import MUIRichTextEditor from "mui-rte"
import Utils from 'utils/Utils'
import {
    Button,
    Typography,
    Avatar,
    Grid,
    Tooltip
} from '@material-ui/core'

import CommentIcon from '@material-ui/icons/Comment'
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded'
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded'
import { ReactComponent as WarningIcon } from "imagens/warning-icon.svg"

import MenuRow from './MenuRow'

import { useDataToolDetails } from '../context'

const defaultTheme = createMuiTheme({
    overrides: {
        MUIRichTextEditor: {
            container: {
                margin: 0
            },
            toolbar: {
                borderBottom: '1px solid #eee',
                padding: '8px 0',
            },
            placeHolder: {
                margin: 0,
                padding: '19px 16px',
            },
            editorContainer: {
                margin: 0,
                padding: '19px 16px',
            }
        }
    }
})

const ToolComment = ({ marking }) => {
    const [dataText, setDataText] = useState({ initial_value: '', data: {} })
    const [loading, setLoading] = useState(false)

    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer);
    const { data, getDataAllComments } = useDataToolDetails()

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const classes = useStyles()
    const match = useRouteMatch()

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            getDataAllComments()
            setDataText({ initial_value: '', data: { text: '' } })
        }
        return () => {
            isMounted = false
        }
    }, [marking, getDataAllComments])

    const handleChangeEditorDraft = useCallback((editorState) => {
        if (Boolean(dataText)) {
            const editorConverted = convertToRaw(editorState.getCurrentContent())
            const textPerLine = editorConverted.blocks.map((el) => el.text)

            const comment = {
                text: Boolean(textPerLine) ? textPerLine.join(" ") : '',
                formatter: {
                    name: "MUI-RTE/DraftJs",
                    version: process.env.REACT_APP_VERSION_MUIRTE_DRAFTJS || "^1.23.1",
                    format: JSON.stringify(editorConverted),
                },
            }
            setDataText({ data: comment })
        }
    }, [dataText])

    const handleSubmitComment = useCallback(() => {
        setLoading(true)
        const parameters = { message: dataText.data }
        axios
            .post(`${pathServer}/tool/${match.params.slug}/record/${match.params.id}/createComment`, parameters, requestHeaders)
            .then(() => {
                setLoading(false)
                setDataText({ initial_value: '', data: { text: '' } })
                getDataAllComments()
            })
            .catch((err) => {
                const message = err.response
                    ? err.response.data.message || err.response.headers["x-message"]
                    : t("manager:Erro inesperado. Contate o suporte")
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
                setLoading(false)
            })
    }, [pathServer, match.params.slug, match.params.id, enqueueSnackbar, requestHeaders, dataText.data, getDataAllComments, t])


    const handleCancelComment = useCallback(() => {
        setDataText({ initial_value: '', data: { text: '' } })
    }, [])

    return (
        <Grid style={{ marginTop: 35 }}>
            {
                (data.canComment || data.dataComments.length > 0) &&
                <div style={{ display: 'flex', marginTop: 15, alignItems: 'center' }}>
                    <CommentIcon style={{ marginRight: 10, color: '#666' }} />
                    <Typography variant='body1' style={{ fontWeight: 500, color: '#666' }}>
                        {t('DAM:Comentários')}
                    </Typography>
                </div>
            }

            {
                data.canComment && (
                    <>
                        <div>
                            <Typography style={{ marginTop: 5 }} variant='body2'>
                                {t('manager:Envie mensagens aos envolvidos no projeto')}
                            </Typography>
                        </div>
                        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                            <div className={classes.formField} style={{ height: "auto" }}>
                                <div className={classes.wrapperRichText}>
                                    <MuiThemeProvider theme={defaultTheme}>
                                        <MUIRichTextEditor
                                            maxLength={500}
                                            controls={["bold", "italic", "underline", "link"]}
                                            label={t("common:Adicione um comentário...")}
                                            value={dataText.initial_value}
                                            onChange={(evt) => handleChangeEditorDraft(evt)}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <div>
                                    {
                                        dataText.data.text &&
                                        <Button
                                            color='primary'
                                            variant="outlined"
                                            style={{ margin: '10px 15px 0 0' }}
                                            onClick={handleCancelComment}
                                        >
                                            {t('common:Limpar comentário')}
                                        </Button>
                                    }
                                    <Button
                                        disabled={!(dataText.data.text) || loading}
                                        color='primary'
                                        variant="contained"
                                        style={{ marginTop: 10 }}
                                        onClick={handleSubmitComment}
                                    >
                                        {t('common:Enviar')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            {
                data.dataComments.length > 0 &&
                (
                    <>
                        <div>
                            {
                                data.dataComments.length < 2 ?
                                    <Typography variant='body2'>
                                        {t(`manager:1 comentário`)}
                                    </Typography> :
                                    <Typography variant='body2'>
                                        {t(`manager:comentários`,  {qtd: data.dataComments.length})}
                                    </Typography>
                            }
                        </div>
                        {data.dataComments.map((comment, key) => (
                            <div key={`${comment.id}_${key}`} style={{ display: 'flex', borderRadius: 6, marginTop: 23 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Avatar alt={comment.commenter_name} src='#'></Avatar>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                        <div style={{ display: 'flex' }}>
                                            <Typography variant='body2' style={{ fontWeight: 500, marginRight: 10 }}>
                                                {comment.commenter_name}
                                            </Typography>
                                            <Typography variant='body2'>
                                                {Utils.formatDateFull(comment.created_at)}
                                            </Typography>
                                        </div>
                                        <div>
                                            <MUIRichTextEditor
                                                defaultValue={Utils.getFormattedDescription(comment.message)}
                                                inlineToolbar={false}
                                                toolbar={false}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    comment.type ?
                                        comment.type === 'validar' ?
                                            <Tooltip title={t('manager:Comentário para validação')} >
                                                <div style={{ width: 22, height: 22, borderRadius: '50%', background: '#FFC078', display: 'flex', alignItems: 'center', margin: '0 0 4px 8px' }}>
                                                    <PlaylistAddCheckRoundedIcon style={{ color: '#fff', padding: 3 }} />
                                                </div>
                                            </Tooltip>
                                            :
                                            comment.type === 'recusar' ?
                                                <Tooltip title={t('manager:Comentário da reprovação')} >
                                                    <ThumbDownRoundedIcon style={{ color: '#EA2C2C', marginLeft: 8 }} />
                                                </Tooltip> :
                                                comment.type === 'invalidar' ?
                                                    <Tooltip title={t('manager:Comentário da invalidação')}>
                                                        <WarningIcon style={{ marginLeft: 8 }} />
                                                    </Tooltip> : null : null
                                }
                                {
                                    data.canComment && !comment.type &&
                                      <MenuRow commentId={comment.id} />
                                }
                            </div>
                        ))}
                    </>
                )

            }
        </Grid>

    )
}

export default ToolComment
