import React from "react"

import Skeleton from "@material-ui/lab/Skeleton"

const CustomLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: 700,
        padding: 20,
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton
          variant="rect"
          width="230px"
          height="20px"
          style={{ marginLeft: 10, borderRadius: 4 }}
        />
      </div>
      
      <div
        style={{
          width: '100%',
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Skeleton
          variant="rect"
          width="200px"
          height="40px"
          style={{ borderRadius: 4 }}
        />
        <Skeleton
          variant="rect"
          width="200px"
          height="40px"
          style={{ borderRadius: 4 }}
        />
        <Skeleton
          variant="rect"
          width="200px"
          height="40px"
          style={{ borderRadius: 4 }}
        />
      </div>

      <Skeleton
        variant="rect"       
        height="20px"
        style={{ marginTop: 20, borderRadius: 4 }}
      />

      <Skeleton
        variant="rect"
        height="20px"
        style={{ marginTop: 20, borderRadius: 4 }}
      />

      <Skeleton
        variant="rect"
        height="100px"
        style={{ marginTop: 20, borderRadius: 4 }}
      />

      <Skeleton
        variant="rect"
        height="20px"
        style={{ marginTop: 20, borderRadius: 4 }}
      />

      <Skeleton
        variant="rect"
        height="20px"
        style={{ marginTop: 20, borderRadius: 4 }}
      />
    </div>
  )
}

export default CustomLoading
