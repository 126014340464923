import React, { useState, useCallback } from 'react';
import { useTranslation, Trans } from "react-i18next"
import { useSelector } from "react-redux"
import axios from "axios"
import _ from 'lodash'
import i18n from "i18next"
import { useSnackbar } from "notistack";
import {
    Button,
    TextField,
    Grid,
    FormControlLabel,
    Switch,
    Typography,
    FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    InputLabel,
    FormHelperText,
    CircularProgress,
    useMediaQuery
} from "@material-ui/core"

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles } from "./styles"

import Success from './Success'
import Utils from 'utils/Utils'
import Settings from "utils/Settings";
import { getLanguageFromLocalization } from 'utils/Helpers'

const PersonalData = ({ dataSelected, handleBack, type = '', setUserData }) => {
    const [state, setState] = useState({
        name: _.get(dataSelected, 'name', ''),
        email: _.get(dataSelected, 'email', ''),
        password: "",
        policy: false,
        loading: false,
        success: false,
        errorFields: {}
    })
    const [showPassword, setShowPassword] = useState(false)

    const { t } = useTranslation()
    const classes = useStyles()
    const {
        requestHeaders,
        data: { client_slug, currentPolicy, platform_name: dataClientName },
        language,
    } = useSelector(
        (state) => state.appReducer
    )

    const paramLanguage = language ? language : getLanguageFromLocalization(i18n.language)
    const { enqueueSnackbar } = useSnackbar();
    const isInvitation = type === 'invitation'

    const handleFieldState = (field, value) => {
        setState(prev => ({ ...prev, [field]: value }))
    }

    const handlePolicyState = () => {
        setState(prev => ({ ...prev, policy: !prev.policy }))
    }

    const handleClickShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    const getParameters = useCallback(() => {
        const { profile, complementary } = dataSelected
        let attributes = []

        if (!_.isEmpty(complementary)) {
            Object.entries(complementary).forEach(([key, item]) => {
                if (item) {
                    attributes.push({ value: item.value, id: key, label: _.get(item, 'label', _.get(item, 'name', '')) })
                }
            })
        }

        const getEmail = (email) => {
            if (email.indexOf('@') !== -1) {
                const index = email.indexOf('@')
                return email.slice(0, index)
            } else {
                return email
            }
        }

        let params = {
            name: state.name,
            profile: _.get(profile, "id", ''),
            privacy_policy: {
                reference: _.get(currentPolicy, "reference", null),
                accept: state.policy
            },
            language: paramLanguage
        }

        if (attributes.length > 0) {
            params['attributes'] = attributes
        }

        if (!dataSelected.oauth) {
            if (_.get(dataSelected, 'profile.type', '') !== 'group' && Boolean(_.get(dataSelected, 'profile.domain', false))) {
                params['email'] = `${getEmail(state.email)}@${_.get(dataSelected, 'profile.domain', '')}`
            } else {
                params['email'] = state.email
            }
            params['password'] = state.password
        }

        return params
    }, [currentPolicy, dataSelected, paramLanguage, state.email, state.name, state.password, state.policy])

    const handleSubmit = useCallback((params) => {
        let url = `${Settings.URL_API}/api/${client_slug}/access-request`

        if (type !== 'accessRequest') {
            url = `${Settings.URL_API}/api/${client_slug}/user/completeRegistration/${dataSelected.id}`
        }

        let axiosParams = {
            method: type !== 'accessRequest' ? 'PUT' : 'POST',
            url,
            data: params,
            headers: { ...requestHeaders.headers },
        }

        axios(axiosParams)
            .then(function (resp) {
                if (dataSelected.dataUser && !_.isEmpty(dataSelected.dataUser)) {
                    setUserData({ current_policy_accepted: state.policy })
                } else {
                    setState(prev => ({ ...prev, success: true, loading: false }))
                }
            }, function (err) {
                let message = Utils.ajaxErrorGetMessage(err, t)

                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })

                setState(prev => ({ ...prev, loading: false }))
            })
            .catch(function (err) {
                let message = Utils.ajaxErrorGetMessage(err, t)
                enqueueSnackbar(typeof message === 'string' ? message : t("Erro inesperado. Contate o suporte"), {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                });
                setState(prev => ({ ...prev, loading: false }))
            });

    }, [
        requestHeaders,
        client_slug,
        enqueueSnackbar,
        t,
        dataSelected.id,
        dataSelected.dataUser,
        type,
        setUserData,
        state.policy
    ])

    const handleClickValid = useCallback(() => {
        let dataErrorForm = {}
        let error = false

        if (state.name.trim().length === 0) {
            dataErrorForm['name'] = t('common:Campo obrigatório')
            error = true
        }

        if (!dataSelected.oauth) {
            if (state.email.trim().length <= 0) {
                dataErrorForm['email'] = t('common:Campo obrigatório')
                error = true
            }

            if (state.password.trim().length === 0) {
                dataErrorForm['password'] = t('common:Campo obrigatório')
                error = true
            }

            if (_.get(dataSelected, 'profile.type', _.get(dataSelected, 'profile.type_approval', '')) === 'yourself') {
                if (state.email && (state.email.indexOf('@') !== -1) && Boolean(_.get(dataSelected, 'profile.domain', false))) {
                    const index = state.email.indexOf('@')
                    const domain = state.email.slice(index + 1)

                    if (domain !== _.get(dataSelected, 'profile.domain', '')) {
                        dataErrorForm['email'] = `${t('common:Seu email deve ser do mesmo dominio')}:  @${_.get(dataSelected, 'profile.domain', '')}`
                        error = true
                    }
                }
            } else {
                if (!Utils.validateEmail(state.email.trim())) {
                    dataErrorForm['email'] = t('common:E-mail inválido')
                    error = true
                }
            }
        }


        if (error) {
            setState(prev => ({ ...prev, errorFields: dataErrorForm }))
        } else {
            const params = getParameters()
            setState(prev => ({ ...prev, errorFields: {}, loading: true }), handleSubmit(params))
        }
    }, [dataSelected, getParameters, handleSubmit, state.email, state.name, state.password, t])


    const onClickGoBack = useCallback(() => {
        if((type !== 'accessRequest') && !_.isEmpty(dataSelected.complementary)) {
            handleBack(_, 2)
        }
        if (_.isEmpty(dataSelected.complementary)) {
            handleBack(_, 1)
        } else {
            handleBack(2)
        }
    }, [dataSelected.complementary, handleBack, type])

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className={isMobile ? classes.borderMobile : ''}>
            {
                state.loading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {
                            state.success ? (
                                <div>
                                    <Success invitationDesc={isInvitation} />
                                </div>
                            ) : (
                                <>
                                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                                        <TextField
                                            label={t('common:Nome completo')}
                                            required
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={state.name}
                                            helperText={Boolean(state.errorFields['name']) ? state.errorFields['name'] : ''}
                                            error={Boolean(state.errorFields['name'])}
                                            onChange={evt => handleFieldState('name', _.get(evt, 'target.value', ''))}
                                        />
                                    </Grid>

                                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                                        {
                                            !Boolean(_.get(dataSelected, 'profile.domain', false)) ? (
                                                <TextField
                                                    label={'E-mail'}
                                                    required
                                                    type='email'
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    value={state.email}
                                                    helperText={Boolean(state.errorFields['email']) ? state.errorFields['email'] : ''}
                                                    error={Boolean(state.errorFields['email'])}
                                                    onChange={evt => handleFieldState('email', _.get(evt, 'target.value', ''))}
                                                    disabled={Boolean(_.get(dataSelected, 'email', false))}
                                                />
                                            ) : (
                                                <FormControl
                                                    variant="outlined"
                                                    error={Boolean(state.errorFields['email'])}
                                                    id={Boolean(state.errorFields['email']) ? 'error-field' : ''}
                                                    style={{ width: '100%' }}
                                                >
                                                    <InputLabel htmlFor="outlined-adornment-email">{'E-mail'}</InputLabel>
                                                    <OutlinedInput
                                                        value={state.email}
                                                        id="outlined-adornment-email"
                                                        onChange={evt => handleFieldState('email', _.get(evt, 'target.value', ''))}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                @{_.get(dataSelected, 'profile.domain', '')}
                                                            </InputAdornment>
                                                        }
                                                        error={Boolean(state.errorFields['email'])}
                                                        labelWidth={70}
                                                        style={{ width: '100%' }}
                                                        disabled={Boolean(_.get(dataSelected, 'email', false))}
                                                    />
                                                    {
                                                        Boolean(state.errorFields['email']) &&
                                                        <FormHelperText style={{ color: 'inherit', marginLeft: 11 }}>
                                                            {state.errorFields['email']}
                                                        </FormHelperText>
                                                    }
                                                </FormControl>
                                            )

                                        }

                                    </Grid>

                                    {
                                        !dataSelected.oauth && (
                                            <Grid item xs={12} style={{ marginBottom: 8 }}>
                                                <FormControl
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                    error={state.errorFields['password']}
                                                >
                                                    <InputLabel htmlFor="outlined-adornment-password">{t('solicitacaoAcesso:Senha')}</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={state.password}
                                                        onChange={evt => handleFieldState('password', _.get(evt, 'target.value', ''))}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={70}
                                                    />
                                                    {
                                                        Boolean(state.errorFields['password']) &&
                                                        <FormHelperText style={{ marginLeft: 11 }}>
                                                            {state.errorFields['password']}
                                                        </FormHelperText>
                                                    }
                                                    <div style={{ marginBottom: 20, width: "100%", color: '#666' }}>
                                                        <Typography style={{ marginTop: 13 }} variant="body2">
                                                            {t("solicitacaoAcesso:Requisito de Senha")}:{t("solicitacaoAcesso: A senha deve ter o mínimo de 8 caracteres contendo ao menos uma letra maiúscula, uma letra minúscula, número e caractere especial.")}
                                                        </Typography>
                                                    </div>
                                                </FormControl>
                                            </Grid>
                                        )
                                    }

                                    {
                                        _.get(currentPolicy, "reference", false) &&
                                        _.get(currentPolicy, "file", false) && (
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20, width: '100%' }}>
                                                <FormControlLabel
                                                    control={<Switch
                                                        checked={state.policy}
                                                        color='primary'
                                                        onChange={handlePolicyState}
                                                        name="currentPolicy" />}
                                                    style={{ marginRight: 0 }}
                                                />
                                                <div style={{ display: "flex" }}>
                                                    <Typography component='span' variant="body2">{t("common:Declaro ter lido a")}
                                                        <p
                                                            onClick={() => Utils.makeDownload(currentPolicy.file)}
                                                            style={{
                                                                margin: "0 3px",
                                                                cursor: "pointer",
                                                                color: "#1976d2",
                                                                display: 'inline'
                                                            }}
                                                        >
                                                            {t("common:Política de privacidade")}
                                                        </p>
                                                        {t("common:requerido por")} {dataClientName}
                                                        {t("common: atualizado em")} {Utils.formatDate(currentPolicy.reference)}.
                                                    </Typography>
                                                </div>
                                            </div>
                                        )}

                                    <div style={{ marginBottom: 20, width: "100%", color: '#666' }}>
                                        <Typography variant="body2">
                                            <Trans i18nKey="common:nps-access-request">
                                                Com o seu consentimento, a Yapoli utiliza os dados pessoais, como nome <strong style={{ fontWeight: 500 }}>completo</strong> e <strong style={{ fontWeight: 500 }}>e-mail</strong> com a finalidade de criação de ID para a utilização da plataforma {{ dataClientName }}. Além disso, os dados mencionados podem ser alterados/excluidos a qualquer momento pelo usuário, basta solicitar através do e-mail dpo@yapoli.com.
                                            </Trans>
                                        </Typography>
                                    </div>

                                    <div className={classes.btnActions}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={onClickGoBack}
                                            style={{ height: 40, marginRight: 25 }}
                                            disabled={((type !== 'accessRequest') && _.isEmpty(dataSelected.complementary))}
                                        >
                                            {t('common:Voltar')}
                                        </Button>

                                        <Button
                                            disabled={_.get(currentPolicy, "reference", false) && !state.policy}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleClickValid}
                                            style={{ height: 40, color: '#fff' }}
                                        >
                                            {isMobile
                                                ? t('common:Enviar')
                                                : isInvitation
                                                    ? t('solicitacaoAcesso:Enviar cadastro')
                                                    : t('solicitacaoAcesso:Enviar solicitação')}
                                        </Button>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

        </div>
    );
};

export default PersonalData;
