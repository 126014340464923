import React, { useMemo } from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { useSelector } from "react-redux"

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY

const ZenDesk = () => {
    const { data: { client_slug, primaryColor }, language } = useSelector((state) => state.appReducer)

    const setting = useMemo(() => ({
        color: {
            theme: primaryColor
        },
        webWidget: {
            position: { horizontal: 'right', vertical: 'bottom' },
            search: 'DAM',
            labelVisible: false,
            launcher: {
                label: {
                    '*': null
                }
            },
        }
    }), [primaryColor])

    const onSetDefaultConfig = () => {
        ZendeskAPI("webWidget", "setLocale", language)
        ZendeskAPI("webWidget", "helpCenter:setSuggestions", { search: 'DAM' })
    }
   
    return Boolean(client_slug) && <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={() => onSetDefaultConfig()}
    />
}

export default ZenDesk