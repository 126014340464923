import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"
import { Tooltip, IconButton } from '@material-ui/core'
import { AddCircleRounded } from '@material-ui/icons'
import AppActions from "flux/actions/AppActions"

import DialogCreateItem from 'components/Dialogs/FormItem'

const ButtonCreateItem = ({ createItemByBucket, bucket = false }) => {
    const [open, setOpen] = useState(false)
    const { enableNewItemFormPage } = useSelector(state => state.appReducer.userData)
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()

    const handleClick = useCallback(() => {
        setOpen(prev => !prev)
    }, [])

    const createItemByDAM = useCallback(() => {
        dispatch(AppActions.markListAssets([]))
        history.push('/item/new')
    }, [history, dispatch])

    if (!enableNewItemFormPage) {
        return (
            <Tooltip title={t("DAM:Criar item")} placement="top">
                <IconButton
                    color="inherit"
                    onClick={() => bucket ? createItemByBucket() : createItemByDAM()}
                >
                    <AddCircleRounded fontSize='large' color='primary' />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <>
            <Tooltip title={t("DAM:Criar item")} placement="top">
                <IconButton
                    color="inherit"
                    onClick={() => handleClick()}
                >
                    <AddCircleRounded fontSize='large' color='primary' />
                </IconButton>
            </Tooltip>
            {open && <DialogCreateItem open handleClose={() => {
                handleClick()
                dispatch(AppActions.markListAssets([]))
            }} />}
        </>
    )
}

export default ButtonCreateItem