import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useSnackbar } from "notistack"
import axios from 'axios'
import {

    Grid,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
    Typography
} from "@material-ui/core";

import Skeleton from '@material-ui/lab/Skeleton'

import _ from 'lodash'

const ListDownloadsbyitems = ({ data_slugs, path, title, option, onChangeOption, optionFieldName, opstionsDefault = [], gridSizeMd = 4 }) => {
    const [state, setState] = useState({ data: [], loading: true })
    const { pathServer, requestHeaders } = useSelector((state) => state.appReducer)
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const getData = useCallback((slugs) => {
        if (state.data.length === 0) {
            axios
                .post(`${pathServer}/${path}`, { category_slug: slugs }, requestHeaders)
                .then((result) => {
                    let DATA = []
                    if (_.get(result, 'data.alternatives_names', []).length > 0) {
                        DATA = _.get(result, 'data.alternatives_names', [])
                    }

                    if (_.get(result, 'data.recipes', []).length > 0) {
                        DATA = _.get(result, 'data.recipes', [])
                    }

                    if (DATA.length > 0) {
                        setState(prev => ({ ...prev, data: [...opstionsDefault, ...DATA], loading: false }))
                    } else {
                        setState(prev => ({ ...prev, data: opstionsDefault, loading: false }))
                    }

                })
                .catch((err) => {
                    setState(prev => ({ ...prev, loading: false }))
                    const message = err.response
                        ? err.response.data.message || err.response.headers["x-message"]
                        : t("manager:Erro inesperado. Contate o suporte")
                    enqueueSnackbar(message, {
                        variant: "error",
                        autoHideDuration: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                    })
                })
        }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, path, opstionsDefault, state.data])

    useEffect(() => {
        let isMounted = true
        if (isMounted && (data_slugs.length > 0)) {
            getData(data_slugs)
        } else {
            setState(prev => ({ ...prev, loading: false, data: opstionsDefault }))
        }
        return () => { isMounted = false }
    }, [getData, data_slugs, opstionsDefault])

    const changeOption = useCallback((field, value, type) => {
        onChangeOption(field, {
            id: value,
            ...(type && { type })
        })
    }, [onChangeOption])

    const constants = useMemo(
        () => ({
            hasData: (state.data.length > 0),
        }), [state.data])

    return (
        <>
            <Typography variant='body1' style={{ color: "#666" }}>
                {title}:
            </Typography>
            <Grid style={{ width: '100%', maxHeight: 460, overflow: 'auto' }}>
                {
                    state.loading ? (
                        <>
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="rect" height={40} style={{ borderRadius: 4, margin: 0 }} />
                            </Grid>
                        </>
                    ) : (
                        <FormControl component="fieldset">
                            {constants.hasData && state.data.map((i) => {
                                return (
                                    <RadioGroup key={i.id} aria-label="gender" name="gender1" value={option} onChange={evt => changeOption(optionFieldName, _.get(evt, 'target.value', ''), _.get(i, 'type', _.get(i, 'slug', '')))}>
                                        <Tooltip title={i.can_use ? '' : t('common:Incompatível com as categorias  de todos os itens')}>
                                            <FormControlLabel
                                                value={i.id}
                                                control={<Radio color='primary' disabled={!i.can_use} />}
                                                label={_.get(i, 'title', _.get(i, 'name', ''))}
                                                style={{ color: "#666" }}
                                            />
                                        </Tooltip>
                                    </RadioGroup>
                                )
                            }
                            )}
                        </FormControl>
                    )
                }
            </Grid>
        </>
    );
};

ListDownloadsbyitems.defaultProps = {
    data_slugs: [],
    opstionsDefault: []
};

export default ListDownloadsbyitems
