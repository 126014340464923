import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #eee',
        '& :hover': {
            cursor: 'pointer',
            backgroundColor: '#EBEBEB'
        },
    },
    rootError: {
        height: 110,
        display: 'flex',
        alignItems: 'center',
    },
    info: {
        width: '100%',
        color: '#666',
        display: 'flex',

        '& .content': {
            width: '100%',
            padding: '10px 20px 5px 20px',
            display: 'flex',
        },
        '& .footer': {
            display: 'flex',
            alignItems: 'center',
            height: 26,
            paddingLeft: '20px',
            paddingBottom: 5,
        },
        '& .read': {
            display: 'flex',
            alignItems: 'center',
            paddingRight: 20
        },
        '& #not-read': {
            backgroundColor: '#2196F3',
            width: 10,
            height: 10,
            borderRadius: '50%'
        },
        '& #not-read-error': {
            backgroundColor: 'red',
            width: 10,
            height: 10,
            borderRadius: '50%'
        },
    },
    headerDate: {
        padding: '3px 4px',
        borderRadius: 16,
        backgroundColor: '#eee',
        display: 'inline-flex',
        alignItems: 'center',
        width: 90,
        '& svg': {
            fontSize: '.8rem',
            color: '#616161',
            marginRight: 5,
        }
    },
    info_download: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 20
    }
}))