export const settings = {
  area: 9,
  route: "advanced-settings",
  entity: "alternative-name",
  title: {
    singular: 'Nome alternativo'
  },
  page: 0,
  initial_page: 0,
  isNotPageOne: true
}