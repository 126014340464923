import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import ContentCard from './ContentCard'
import Skeleton from './Skeleton'
import SimpleAlertMessage from "components/AlertMessage/SimpleAlertMessage"

import { useDataItems } from '../context'
import { useIntersect } from 'components/Intersect'

import {
    Typography,
    Button
} from "@material-ui/core"

import { useStyles } from "./styles"

const ContentListCard = () => {
    const {
        loading,
        hasData,
        handleLoadMore,
        dataParams,
        loadingMoreData,
        getRenewedData,
        getParamsToItems,
        checkedData,
        dataItems,
        hasNext,
        hasMoreThanOnePage
    } = useDataItems()
    const classes = useStyles()
    const { t } = useTranslation()

    const [isIntersecting, ref] = useIntersect();
    useEffect(() => {
      let isMounted = true

      if(isMounted && isIntersecting) {
        handleLoadMore()
      }
      return () => {
        isMounted = false
      }
    }, [isIntersecting, handleLoadMore])


    useEffect(() => {
      let isMounted = true
      if (!checkedData && !loading) {
          if (isMounted) {
            const paramsToItems = getParamsToItems()
            getRenewedData(paramsToItems)
          }
      }

      return () => {
          isMounted = false
      }
    }, [getRenewedData, checkedData, loading, getParamsToItems])

    const getDevicePixelRatio = (() => {
      const devicePixelRatio = _.get(window, "devicePixelRatio", 1)
      return Math.round(devicePixelRatio * 100)
    })()

    const END_TEXT = !loading && !hasNext() && hasMoreThanOnePage()
    const BTN_LOAD_MORE = !loading && getDevicePixelRatio < 90 && hasNext()


    const ShowData = useCallback(() => {
      if(hasData) {
        return <ContentCard data={dataItems} />
      }
      return <></>
    }, [dataItems, hasData])

    const ShowSkeleton = () => {
      if((!hasData && loading) || (hasData && loadingMoreData)) {
        return <Skeleton />
      }
      return <></>
    }

    const ShowWarning = () => {
      if(!hasData && !loading) {
        return <SimpleAlertMessage
                  severity="info"
                  alertTitle="Ops!"
                  message={
                      _.get(dataParams, 'searchText', false)
                          ? t(
                              "common:Não encontramos o que você procura. Que tal uma nova busca?"
                          )
                          : t("common:Não há itens a serem mostrados neste filtro.")
                  }
                />
      }
      return <></>
    }

    return (
        <>
            <div className={classes.contentCard_root}>
                <ShowData />
                <ShowSkeleton />
                <ShowWarning />
                {checkedData && <div ref={ref} />}                
            </div>

            <div style={{ width: '100%', minHeight: 60 }}>
                {
                    END_TEXT &&
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 20,
                        }}
                    >
                        <Typography variant="subtitle1">
                            {t("common:Parece que você chegou ao fim")}
                        </Typography>
                    </div>
                }
                {
                    BTN_LOAD_MORE && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 20,
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleLoadMore()}
                            >
                                {t("DAM:Carregar mais")}
                            </Button>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default ContentListCard
