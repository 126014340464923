import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "calc(100vh - 210px)",
    borderRadius: 0,
    padding: "0 25px",
    wordWrap: "break-word",
    margin: "30px 0",
    [theme.breakpoints.down("sm")]: {
      height: "auto",      
    },
    "& #outlined-basic": {
      minHeight: 76,
    },
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "column",
    color: "#666",
    marginTop: "20px",
    "& #title": {
      fontWeight: 500,
    },
    "& #info": {
      marginTop: "10px",
      fontWeight: 300,
      "& span": {
        fontSize: "0.875rem",
      },
      "& #mui-rte-container": {
        margin: 0,
      },
      "& #mui-rte-editor-container": {
        margin: 0,
        padding: 0,
      },
    },
  },
  richTextEditor: {
    minHeight: 200,
    border: "1px solid #bababa",
    borderRadius: 4,
  },
  errorRichTextEditor: {
    border: '1px solid #f44336',
    borderRadius: 4,
    minHeight: 200
  }
}))
