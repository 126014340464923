import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"

import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import { Button, makeStyles } from "@material-ui/core"

import { ContentRow } from './ContentRow'

const useStyles = makeStyles(() => ({
    root: {
        height: 'calc(100vh - 275px)',
        overflow: 'auto',
    },
    pdd: {
        padding: 20
    }
}))

const WrapperRows = ({ changeRegion, handleMenuClose, type }) => {
    const [state, setState] = useState({
        loading: false,
        errorGetData: false,
        dataEmpty: false,
        dataNotification: [],
        totalNotification: 0,
        lastResultData: null
    })
    const [disabledBtn, setDisabledBtn] = useState(false)
    const { requestHeaders, pathClient } = useSelector(
        (state) => state.appReducer
    )

    const { t } = useTranslation()
    const refList = useRef(null)
    const classes = useStyles()

    useEffect(() => {
        let isMounted = true
        if (isMounted && !state.loading) {
            refList.current.addEventListener("scroll", function () {
                if (
                    refList.current.scrollTop + refList.current.clientHeight >=
                    refList.current.scrollHeight
                ) {
                    setDisabledBtn(true)
                } else {
                    setDisabledBtn(false)
                }
            })
        }
        return () => {
            isMounted = false
        }
    }, [refList, state.loading])

    const getData = useCallback(async (pageLast, firstGet) => {
        let parameters = {
            limit: 20,
            type,
            ...(Boolean(pageLast) && {last: pageLast})
        }
        
        try {
            const result = await axios.post(`${pathClient}/notification/find`, parameters, requestHeaders)

            if (result.status === 200) {
                axios.post(`${pathClient}/notification/read`, { all: true, type }, requestHeaders)
                    .then(result => {
                        if (result.status >= 400) {
                            console.error('Erro ao consultar contador de notificações', result.status)
                        }
                    })
                    .catch(e => console.error('Erro ao consultar contador de notificações', e))
            }

            setState(prev => ({
                ...prev,
                totalNotification: _.get(result, 'data.total', 0),
                lastResultData: _.get(result, 'data.last', null),
                errorGetData: false,
                loading: false,
                dataNotification: firstGet
                    ? _.get(result, 'data.items', [])
                    : [...prev.dataNotification, ..._.get(result, 'data.items', [])]
            }))
        } catch (e) {
            if (e.response) {
                if (e.response.status === 404) {
                    setState(prev => ({
                        ...prev,
                        errorGetData: false,
                        dataEmpt: true,
                        loading: false
                    }))
                    return;
                }
            }
            setState(prev => ({
                ...prev,
                errorGetData: true,
                loading: false
            }))
            console.log(e)
        }
    }, [pathClient, requestHeaders, type])

    useEffect(() => {
        let isMounted = true
        if (isMounted) getData(null, true)
        return () => {
            isMounted = false
        }
    }, [getData])

    const constants = useMemo(() => ({
        disabledBtn: (state.dataNotification.length === state.totalNotification) || !disabledBtn
    }), [state.dataNotification, state.totalNotification, disabledBtn])

    return (
        <>
            <div className={classes.root} ref={refList}>
                {
                    state.loading ? (
                        <div>
                            <div style={{ padding: '0 20px' }}>
                                <Skeleton variant="rect" width={'100%'} height={80} />
                            </div>
                            <div style={{ padding: '0 20px', margin: '10px 0' }}>
                                <Skeleton variant="rect" width={'100%'} height={80} />
                            </div>
                            <div style={{ padding: '0 20px' }}>
                                <Skeleton variant="rect" width={'100%'} height={80} />
                            </div>
                        </div>
                    ) : (
                        <>
                            {
                                !state.dataEmpty && state.dataNotification.map((notification, index) =>
                                    <ContentRow
                                        key={index}
                                        data={notification}
                                        changeRegion={changeRegion}
                                        handleClose={handleMenuClose}
                                        type={type}
                                    />
                                )
                            }
                            {
                                state.errorGetData &&
                                <div className={classes.pdd}>
                                    <Alert severity="error">
                                        {t('Erro ao carregar os dados.')}
                                    </Alert>
                                </div>
                            }
                            {
                                state.dataEmpty &&
                                <div className={classes.pdd}>
                                    <Alert severity="info">
                                        {t('common:Não há notificações')}
                                    </Alert>
                                </div>
                            }
                        </>
                    )
                }

            </div>

            <div style={{ padding: 20, height: 70, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="small"
                    variant="outlined"
                    color='primary'
                    onClick={() => getData(state.lastResultData)}
                    disabled={constants.disabledBtn}
                >
                    {t('common:mostrar mais')}
                </Button>
            </div>
        </>
    )
}

export default WrapperRows