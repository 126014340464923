import React, { useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux"
import ContentListCard from './ContentListCard'
import ContentList from './ContentList'

import { useDataItems } from './context'

const ModeWiewItems = () => {
    const { visualization_mode } = useSelector((state) => state.itemsReducer)
    const { markedItems } = useSelector((state) => state.appReducer)
    const { showItemsSelected } = useDataItems()
    const history = useHistory()

    useEffect(() => {
        let isMounted = true
        if (isMounted && markedItems.length === 0 && showItemsSelected) {
            history.goBack()
        }
        return () => { isMounted = false }
    }, [markedItems,  showItemsSelected, history])

    const constants = useMemo(() =>
        ({ isModeList: visualization_mode === 'card-mode' }),
        [visualization_mode])
    return constants.isModeList ? <ContentListCard /> : <ContentList />
}
export default ModeWiewItems
