/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable  no-unused-vars*/
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded"
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Switch from '@material-ui/core/Switch';
import {toast} from 'react-toastify';

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: 0,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    }
});

const DragHandle = SortableHandle(() => {
    return (
        <IconButton>
            <DragIndicatorIcon/>
        </IconButton>
    );
});

const SortableItem = SortableElement(({value}) => {
    return (
        <TableRow>
            <TableCell padding="checkbox">
                <DragHandle/>
            </TableCell>
            <TableCell> {value.label ?? value.name} </TableCell>
            <TableCell>
                <Switch
                    color='primary'
                    checked={!!value.required}
                    onChange={(evt) => {
                        value.handleCheckAttribute(value.id, evt)
                    }}
                />
            </TableCell>
            <TableCell>
                <IconButton onClick={(evt) => { value.handleRemoveAttribute(value.id, evt)}}>
                    <DeleteRoundedIcon  />
                </IconButton>
            </TableCell>
        </TableRow>
    );
});

const SortableList = SortableContainer(({items}) => {
    return (
        <TableBody>
            {items.map((value, index) => {
                value.handleRemoveAttribute = items.handleRemoveAttribute;
                value.handleCheckAttribute = items.handleCheckAttribute;
                return (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={value}
                    />
                )
            })}
        </TableBody>
    );
});

class SimpleTable extends React.Component {
    render() {
        const SELF = this;
        const {t, classes, items, handleRemoveAttribute, handleMoveAttribute} = SELF.props;

        if(!Array.isArray(items)) {
            return <React.Fragment></React.Fragment>
        }
        items.handleRemoveAttribute = (id, evt) => {
            let newItems = items.filter((item) => item.id !== id);
            SELF.setState({...SELF, items: newItems});
            return handleRemoveAttribute(id, evt);
        };

        items.handleCheckAttribute = (id) => {
            let newItems;
            try {
                newItems = items.map((item) => {
                    if (item.id === id) {
                        item.required = (!!item.required) ? 0 : 1;
                    }
                    return item;
                });
            } catch (error) {
                toast.warn(error.message);
                return;
            }
            SELF.setState({...SELF, items: newItems});
        };
        return (
            <Paper className={classes.root}>
                {(items.length) ? (
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('common:Ordenar')}</TableCell>
                                <TableCell>{t('common:Nome')}</TableCell>
                                <TableCell>{t('common:Obrigatório')}</TableCell>
                                <TableCell>{t('common:Excluir')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <SortableList
                            items={items}
                            onSortEnd={handleMoveAttribute}
                            useDragHandle={true}
                            helperClass='sortable'
                        />
                    </Table>
                ) : (
                    <div />
                )}
            </Paper>
        )
    }
}

SimpleTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleTable);