import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTypography-body1, .MuiTypography-root': {
        color: '#666'
    },
    [theme.breakpoints.down("sm")]: {
        height: 'calc(100vh - 70px)'
    },
    '& .MuiPaper-root': {
        backgroundColor: 'inherit'
    },
    '& #error-field': {
        color: '#f44336'
    },
},
rootContainer: {
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
        padding: 0,
        '& div.MuiPaper-elevation2': {
            boxShadow: 'none'
        },
        '& div.container-paper': {
            marginBottom: '0 !important',
            padding: ' 0 20px 20px 20px !important',
        },
        '& .container-footer': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 20,
        },
        '& .root_richtexteditor': {
            textAlign: 'center',
            padding: 20,
            paddingBottom: 0
        },
    },
    '& a.MuiTypography-colorPrimary': {
        color: `${theme.palette.primary.main} !important`
    },
    '& .root_richtexteditor': {
        marginBottom: 24
    },
    '& div.container-paper': {
        padding: 40,
        backgroundColor: '#fff',
        marginBottom: 20,
        '& #last': {
            border: 'none'
        },
        [theme.breakpoints.down("sm")]: {
            backgroundColor: '#eee',
        }

    }
},
backButton: {
    marginRight: theme.spacing(1),
},
instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
},
rootStepper: {
    padding: '24px 0',
    maxWidth: 1058,
    width: '100%',
    [theme.breakpoints.down("sm")]: {
        margin: '0 25%',
        padding: '20px 10px 19px 10px',
        '& span.MuiTypography-root': {
            display: "none",
        }
    },
}
}));
