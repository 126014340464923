import React, { useState, useCallback, useEffect } from "react"
import axios from "axios"
import _ from 'lodash'
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"

import {
  ListItem,
  ListItemAvatar,
  List,
  Checkbox,
  Typography,
  Grid,
  Tooltip
} from "@material-ui/core"

import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import ImageLazyLoad from "components/ImageLazyLoad"
import Utils from "utils/Utils"
import DetailsRows from './DetailsRows'

import { useStyles } from "./styles"

const AssetsToRecord = ({ dataAssets = [], data, loadingItems }) => {
  const uploadFilesFiltered = useSelector((state) => state.uploadReducer.uploadFiles)
    .filter(i => i.entity === data.entity)
  const [dataFiles, setDataFiles] = useState({
    loadings: {}, checkeds: {}
  })
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  useEffect(() => {
    let isMounted = true

    if (isMounted && dataAssets.length > 0) {
      let dataFiles = {}
      dataAssets.forEach(i => {
        if (i.isItemAsset) {
          dataFiles[i.asset_id] = true
        } else {
          dataFiles[i.asset_id] = false
        }
      })
      setDataFiles(prev => ({ ...prev, checkeds: dataFiles }))
    }
    return () => {
      isMounted = false
    }
  }, [dataAssets])


  const submitAssetMarked = useCallback((evt, id) => {
    evt.preventDefault()
    const checked = _.get(evt, 'target.checked', false)

    const axiosConfig = {
      method: checked ? 'PUT' : 'DELETE',
      url: `${data.axios.pathServer}/tool/${data.slugTool}/record/${data.idRecord}/asset/${id}`,
      headers: { ...data.axios.requestHeaders.headers }
    }

    axios(axiosConfig).then((resp) => {
      setDataFiles(prev => ({ ...prev, checkeds: { ...prev.checkeds, [id]: checked } }))
    })
      .catch((err) => {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("DAM:Falha ao salvar o asset selecionado.")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
        console.log(err)
      });
  }, [data.axios.pathServer, data.axios.requestHeaders, t, enqueueSnackbar, data.slugTool, data.idRecord])

  const getUrlDownload = useCallback((evt, asset_id) => {
    setDataFiles(prev => ({ ...prev, loadings: { ...prev.loadings, [asset_id]: true } }))

    axios.get(`${data.axios.pathServer}/attachment/${asset_id}/download`, data.axios.requestHeaders)
      .then((result) => {
        const linkDownload = result.data.url
        Utils.makeDownload(linkDownload, () => setDataFiles(prev => ({ ...prev, loadings: { ...prev.loadings, [asset_id]: false } })))
      })
      .catch((err) => {
        const message = err.response
          ? err.response.data.message || err.response.headers["x-message"]
          : t("manager:Erro inesperado. Contate o suporte")
        enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        })
        setDataFiles(prev => ({ ...prev, loadings: { ...prev.loadings, [asset_id]: false } }))
      })
  }, [data.axios.pathServer, data.axios.requestHeaders, enqueueSnackbar, t])

  return !loadingItems && (dataAssets.length > 0 || uploadFilesFiltered.length > 0) && (
    <>
      <Grid style={{ border: '1px solid #e0e0e0', margin: '20px 0 30px' }}>
        <Grid style={{ borderBottom: '1px solid #e0e0e0', padding: 10 }}>
          <Typography variant='body1' style={{ fontWeight: 500, color: "#666" }}>
            {t('manager:Arquivos anexados')}
          </Typography>
          <Typography variant='body2'>
            {
              data.original_marking === 'Em validação' || data.original_marking === 'Em aprovação' ?
                t('manager:Os arquivos selecionados aguardam aprovação') :

                data.original_marking === 'Finalizada' ?
                  t('manager:Os arquivos selecionados foram aprovados') :
                  
                  t('manager:Selecione os arquivos que devem ir para aprovação')
            }
          </Typography>
        </Grid>
        <Grid container>
          <List dense className={classes.list_root}>
            <DetailsRows
              entity={data.entity}
              getUrlDownload={getUrlDownload}
              canSelectAttach={data.canSelectAttach}
              submitAssetMarked={submitAssetMarked}
              dataIds={dataAssets.map(i => i.asset_id)}
              dataFilesCheckeds={dataFiles.checkeds}
              isWorkflow={data.workflow}
            />

            {
              dataAssets.length > 0 && dataAssets.map(i => {
                return (
                  <ListItem key={i.asset_id} className={classes.list_item}>
                    {
                      data.workflow && (
                        <div style={{ paddingRight: 16 }}>
                          <Checkbox
                            color='primary'
                            checked={Boolean(dataFiles.checkeds[i.asset_id])}
                            onChange={evt => submitAssetMarked(evt, i.asset_id)}
                            disabled={!data.canSelectAttach}
                          />
                        </div>
                      )
                    }
                    <ListItemAvatar>
                      <ImageLazyLoad
                        images={{ imgWEBP: _.get(i, "thumbnail_card_webp", ""), imgJPG: _.get(i, "thumbnail_card_original", "") }}
                        styles={{ width: 100, height: 100 }}
                      />
                    </ListItemAvatar>
                    <Grid className={classes.row_info} style={{ maxWidth: 'calc(100% - 200px)' }}>
                      <div className="info">
                        <Tooltip title={_.get(i, 'client_name', '')}>
                          <Typography variant='body2' noWrap>{_.get(i, 'client_name', '')}</Typography>
                        </Tooltip>
                        <div className="icon" onClick={evt => dataFiles.loadings[i.asset_id] ? () => { } : getUrlDownload(evt, i.asset_id)}>
                          <GetAppRoundedIcon />
                        </div>
                      </div>
                      <div className="actions" />
                    </Grid>
                  </ListItem>
                )
              })
            }
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default AssetsToRecord;
