import React from "react"

import { settings } from "./_settings.js"

import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import axios from "axios"
import _ from "lodash"

import { withSnackbar } from "notistack"
import { compose } from "recompose"

import {
  Grid,
  Typography,
  TextField,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import DialogConfirm from "components/Dialogs/DialogConfirm"
import DialogDeleteValidation from "components/Dialogs/DeleteValidationPhrase"

import Settings from 'utils/Settings'
import Utils from 'utils/Utils'

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      ...settings,
      fields: [
        'authorizeUrl',
        'clientSecret',
        'tokenUrl',
        'userUrl',
        'name',
        'clientId',
        'scope'
      ],
      data: {
        name: "",
        authorizeUrl: "",
        clientSecret: "",
        tokenUrl: "",
        userUrl: "",
        clientId: "",
        scope: "",
        request_type: "JSON",
        identification_field: "email",
        identification_type: "ID_TOKEN"
      },
      updateDialogOpen: false,
      updateClientSecret: (props.typeForm === "create"),
      formErrors: {},
      loading: false
    }

    this.IS_CREATE = props.typeForm === "create"

    this.changeField = this.changeField.bind(this)
    this.changeSwitch = this.changeSwitch.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }

  componentDidMount() {
    const ID = _.get(this, 'props.match.params.id', false)
    if (ID) {
      this.setState((prev) => ({
        ...prev,
        loading: true,
        id: ID
      }), this.getData(ID))
    }
  }

  getData(id) {
    const SELF = this
    const { requestHeaders, pathClient, t } = SELF.props

    axios
      .get(`${pathClient}/${SELF.state.entity}/${id}`, requestHeaders)
      .then((resp) => {
        SELF.setState((prev) => ({
          ...prev,
          data: {
            name: _.get(resp, 'data.item.name', ''),
            authorizeUrl: _.get(resp, 'data.item.authorizeUrl', ''),
            tokenUrl: _.get(resp, 'data.item.tokenUrl', ''),
            userUrl: _.get(resp, 'data.item.userUrl', ''),
            clientId: _.get(resp, 'data.item.clientId', ''),
            scope: _.get(resp, 'data.item.scope', ''),
            clientSecret: "",
            request_type: _.get(resp, 'data.item.request_type', "JSON"),
            identification_field: _.get(resp, 'data.item.identification.field', "email"),
            identification_type: _.get(resp, 'data.item.identification.type', "SERVICE")
          },
          loading: false
        }))
      })
      .catch((err) => {
        const message = Utils.ajaxErrorGetMessage(err, t)
        console.log(err)
        SELF.setState((prev) => ({ ...prev, loading: false }), SELF.showMessage(message))
      })
  }

  getParamsAxios() {
    const { pathClient } = this.props

    return {
      method: this.IS_CREATE ? "post" : "put",
      url: this.IS_CREATE
        ? `${pathClient}/${this.state.entity}`
        : `${pathClient}/${this.state.entity}/${this.state.id}`,
    }
  }

  validateForm(callback) {
    const SELF = this
    const { t } = SELF.props
    const { data } = SELF.state
    let formValid = true
    let formErrors = {}

    if (_.get(data, "authorizeUrl", '').length === 0 && this.IS_CREATE) {
      formErrors.authorizeUrl = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    if (_.get(data, "clientSecret", '').length === 0 && this.IS_CREATE) {
      formErrors.clientSecret = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    if (_.get(data, "name", '').length === 0 && this.IS_CREATE) {
      formErrors.name = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    if (_.get(data, "tokenUrl", '').length === 0 && this.IS_CREATE) {
      formErrors.tokenUrl = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    if (_.get(data, "userUrl", '').length === 0 && this.IS_CREATE && _.get(data, "identification_type", '') === "SERVICE") {
      formErrors.userUrl = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    if (_.get(data, "identification_field", '').length === 0 && this.IS_CREATE) {
      formErrors.identification_field = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    if (_.get(data, "clientId", '').length === 0 && this.IS_CREATE) {
      formErrors.clientId = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }
    if (_.get(data, "scope", '').length === 0 && this.IS_CREATE) {
      formErrors.scope = `* ${t("common:Este campo é obrigatório")}`
      formValid = false
    }

    if (formValid && _.isEmpty(formErrors)) {
      callback()
    } else {
      this.setState({ ...this.state, formErrors, formValid })
    }
  }

  showMessage = (message, type) => {
    const params = type === "success"
      ? { ...Settings.SUCCESS_NOTIFICATION_SNACKBAR_PARAMS }
      : {
        variant: type,
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }

    this.props.enqueueSnackbar(message, params)
  }

  submitForm() {
    const SELF = this
    const { t, requestHeaders, history } = SELF.props
    const { data, updateClientSecret } = SELF.state

    const paramsAxios = this.getParamsAxios()
    const parameters = { ...data }

    parameters.identification = {
      type: data.identification_type,
      field: data.identification_field
    }

    if(!parameters.userUrl || parameters.identification.type === "ID_TOKEN") {
      delete parameters.userUrl
    }

    delete parameters.identification_field
    delete parameters.identification_type

    if (!updateClientSecret) {
      delete parameters.clientSecret
    }

    const callback = () => {
      axios({
        method: paramsAxios.method,
        url: paramsAxios.url,
        data: parameters,
        headers: { ...requestHeaders.headers },
      })
        .then(
          function (resp) {
            SELF.showMessage(resp.headers["x-message"], 'success')
            history.push(`/manager/${SELF.state.route}`)
          },
          (err) => {
            const message = Utils.ajaxErrorGetMessage(err, t)
            if (err.response.status === 422) {
              SELF.setState({
                ...SELF.state,
                loading: false,
                dialog: {
                  open: true,
                  data: err.response.data
                }
              }, SELF.showMessage(message, 'error'))
            } else {
              SELF.showMessage(message, 'error')
            }
          }
        )
        .catch(function (error) {
          const message = Utils.ajaxErrorGetMessage(error, t, t("common:Erro ao processar, contate o suporte"))
          console.log(error)
          SELF.setState({ ...SELF.state, loading: false }, SELF.showMessage(message, 'error'))
        })
    }

    SELF.setState({ ...SELF.state, updateDialogOpen: false, loading: true }, callback())
  }


  handleInsert = () => {
    const SELF = this
    SELF.validateForm(this.submitForm)
  }

  insert(evt) {
    evt.preventDefault()
    if (this.IS_CREATE) {
      this.handleInsert()
    } else {
      this.setState((prev) => ({
        ...prev,
        updateDialogOpen: true,
      }))
    }
  }

  changeField(evt) {
    const value = _.get(evt, 'target.value', '')
    const name = _.get(evt, 'target.name', '')
    this.setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value
      },
      formErrors: {
        ...prev.formErrors,
        [name]: null
      }
    }))
  }

  changeSwitch(evt) {
    const value = _.get(evt, 'target.checked', '')
    const name = _.get(evt, 'target.name', '')

    this.setState((prev) => ({
      ...prev,
      [name]: value
    }))
  }


  getDisabledSubmit() {
    const {
      loading,
      data: {
        name,
        authorizeUrl,
        clientSecret,
        tokenUrl,
        userUrl,
        identification_field,
        identification_type
      }
    } = this.state

    if (loading) {
      return loading
    }

    if (this.IS_CREATE) {
      if (clientSecret.length === 0) return true
    }
    if (identification_field.length === 0 || name.length === 0 || authorizeUrl.length === 0 || tokenUrl.length === 0) {
      return true
    }
    if (userUrl.length === 0 && identification_type === "SERVICE") {
      return true
    }
  }

  handleClose = () => {
    this.setState((prev) => ({
      ...prev,
      updateDialogOpen: false
    }))
  }

  delete = () => {
    const SELF = this
    const { pathClient, requestHeaders } = SELF.props
    SELF.setState({ ...SELF.state, loading: true })

    axios
      .delete(
        `${pathClient}/${SELF.state.entity}/${SELF.state.id}`,
        requestHeaders
      )
      .then(
        function (resp) {
          SELF.setState(
            {
              ...SELF.state,
              loading: false,
            },
            () => SELF.props.history.push(`/manager/${SELF.state.route}`)
          )
          SELF.showMessage(resp.headers["x-message"], 'success')
        },
        (resp) => {
          SELF.setState({ ...SELF.state, loading: false })
          SELF.showMessage(resp.headers["x-message"], 'error')
        }
      )
      .catch((err) => {
        SELF.showMessage(SELF.props.t("Erro ao deletar os dados, contate o administrador."), 'error')
      })
  }


  getForm() {
    const SELF = this
    const { t } = SELF.props
    const { data, updateDialogOpen, updateClientSecret, formErrors, loading } = SELF.state

    return (
      <React.Fragment>
        <Grid item xs={12} style={{ marginBottom: 20, display: "flex", justifyContent: "space-between" }}>
          <Typography variant='h5'>
            {this.IS_CREATE ? t("common:Criar") : t("common:Editar")}
          </Typography>

          <Typography variant='body2'>
            {`*${t('common:Campo obrigatório')}`}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body2' style={{ marginBottom: 20 }}>
            {t("manager:Configure os parâmetros do seu modelo de autenticação Single Sign ON - SSO. Dúvidas entre em contato com o suporte@yapoli.com")}.
          </Typography>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="outlined"
              label={`${t("common:Nome")} *`}
              value={_.get(data, 'name', '')}
              onChange={this.changeField}
              error={Boolean(formErrors.name)}
              helperText={formErrors.name ? formErrors.name : ''}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="clientId"
              variant="outlined"
              label={`${t("manager:Cliente ID")} *`}
              value={_.get(data, 'clientId', '')}
              onChange={this.changeField}
              error={Boolean(formErrors.clientId)}
              helperText={formErrors.clientId ? formErrors.clientId : ''}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="scope"
              variant="outlined"
              label={`${t("manager:Escopo")} *`}
              value={_.get(data, 'scope', '')}
              onChange={this.changeField}
              error={Boolean(formErrors.scope)}
              helperText={formErrors.scope ? formErrors.scope : ''}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="authorizeUrl"
              variant="outlined"
              label={`${t("manager:URL de autorização")} *`}
              value={_.get(data, 'authorizeUrl', '')}
              onChange={this.changeField}
              error={Boolean(formErrors.authorizeUrl)}
              helperText={formErrors.authorizeUrl ? formErrors.authorizeUrl : ''}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="tokenUrl"
              variant="outlined"
              label={`${t("manager:URL de token")} *`}
              value={_.get(data, 'tokenUrl', '')}
              onChange={this.changeField}
              error={Boolean(formErrors.tokenUrl)}
              helperText={formErrors.tokenUrl ? formErrors.tokenUrl : ''}
              disabled={loading}

            />
          </Grid>
          <Grid item xs={this.IS_CREATE ? 12 : 8}>
            <TextField
              name="clientSecret"
              variant="outlined"
              label={`${t("manager:Chave secreta")} *`}
              value={_.get(data, 'clientSecret', '')}
              onChange={this.changeField}
              error={Boolean(formErrors.clientSecret)}
              helperText={formErrors.clientSecret ? formErrors.clientSecret : ''}
              disabled={!updateClientSecret || loading}
            />
          </Grid>

          {
            !this.IS_CREATE && (
              <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name="updateClientSecret"
                        checked={updateClientSecret}
                        color="primary"
                        onChange={this.changeSwitch}
                        disabled={loading}
                      />
                    }
                    label={t("manager:Mudar Senha")}
                  />
                </FormGroup>
              </Grid>
            )
          }

          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("Tipo da solicitação")}</FormLabel>
              <RadioGroup
                aria-label="request_type"
                name="request_type"
                value={_.get(data, 'request_type', '')}
                onChange={this.changeField}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel disabled={loading} value="FORM" control={<Radio color="primary" />} label="FORM" />
                <FormControlLabel disabled={loading} value="JSON" control={<Radio color="primary" />} label="JSON" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={4} style={{ paddingBottom: 0 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("Identificação")}</FormLabel>
              <RadioGroup
                aria-label="identification_type"
                name="identification_type"
                value={_.get(data, 'identification_type', '')}
                onChange={this.changeField}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel disabled={loading} value="SERVICE" control={<Radio color="primary" />} label="SERVICE" />
                <FormControlLabel disabled={loading} value="ID_TOKEN" control={<Radio color="primary" />} label="ID_TOKEN" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <TextField
              name="identification_field"
              variant="outlined"
              label={`${t("manager:Campo da identificação")} *`}
              value={_.get(data, 'identification_field', '')}
              onChange={this.changeField}
              error={Boolean(formErrors.identification_field)}
              helperText={formErrors.identification_field ? formErrors.identification_field : ''}
              disabled={loading}
            />
          </Grid>

          {_.get(data, 'identification_type', '') === "SERVICE" &&
            <Grid item xs={12}>
              <TextField
                name="userUrl"
                variant="outlined"
                label={`${t("manager:URL do serviço")} *`}
                value={_.get(data, 'userUrl', '')}
                onChange={this.changeField}
                error={Boolean(formErrors.userUrl)}
                helperText={formErrors.userUrl ? formErrors.userUrl : ''}
                disabled={loading}
              />
            </Grid>}
        </Grid>


        {updateDialogOpen && (
          <DialogConfirm
            handleClose={() => this.handleClose("updateDialogOpen")}
            handleConfirm={this.handleInsert.bind(this)}
            title={t("Confirmação")}
            icon
            description={t('common:A alteração feita será aplicada no cadastro do SSO. Deseja continuar?')}
            open={updateDialogOpen}
          />
        )}

        <Box style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}>
          {this.props.typeForm !== "create" && (
            <DialogDeleteValidation
              entity={this.props.t(`manager:${this.state.title.singular}`)}
              name={this.state.data.title || ""}
            >
              {(confirm) => (
                <Button
                  style={{
                    height: 40,
                    width: 115,
                    marginRight: 20,
                    color: "#F4511E",
                    border: "1px solid #F4511E",
                  }}
                  variant="outlined"
                  onClick={confirm(this.delete.bind(this))}
                  disabled={loading}
                >
                  {this.props.t("common:Excluir")}
                </Button>
              )}
            </DialogDeleteValidation>
          )}
          <Button
            style={{ height: 40, width: 115, marginRight: 20 }}
            variant="outlined"
            color="primary"
            onClick={() =>
              this.props.history.push(`/manager/${this.state.route}`)
            }
          >
            {t("common:Voltar")}
          </Button>
          <Button
            style={{ height: 40, width: 115 }}
            variant="contained"
            color="primary"
            onClick={this.insert.bind(this)}
            disabled={this.getDisabledSubmit()}
          >
            {t("common:Salvar")}
          </Button>
        </Box>
      </React.Fragment>
    )
  }

  render() {
    return this.getForm()
  }
}

export const mapStateToProps = ({ appReducer }) => {
  return {
    region_id: appReducer.userData.region_id,
    region: appReducer.userData.region,
    requestHeaders: appReducer.requestHeaders,
    pathClient: appReducer.pathClient,
  }
}

export default compose(
  connect(mapStateToProps)
)(withTranslation(["manager", "common"])(withSnackbar(Form)))
