import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import LinearProgress from "@material-ui/core/LinearProgress"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useStyles } from "./styles"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"

import _ from "lodash"

const DialogProgress = ({
  handleClose,
  handleConfirm,
  handleCancel,
  entity,
  entityName,
  data,
}) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const normalise = (value) =>
    ((value - 0) * 100) / (_.get(data, "total", 0) - 0)

  const onclose = _.get(data, "loading", false) || _.get(data, "close", false)

  return (
    <Dialog
      open={_.get(data, "dialogOpen", false)}
      onClose={onclose ? handleClose : handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <div className="info">
          <div>{t(`common:${_.get(data, "title", "")}`)}</div>
        </div>
      </DialogTitle>

      <DialogContent style={{ padding: "0", overflowY: "hidden" }}>
        {_.get(data, "message", false) && (
          <Typography variant="body2" gutterBottom>
            {_.get(data, "message", "")}
          </Typography>
        )}
        {_.get(data, "confirm", false) && (
          <React.Fragment>
            <Typography variant="body2" gutterBottom>
              {`${t(
                "manager:Ao proceder com esta alteração, todos os itens na"
              )} ${entity.toString().toLowerCase()}`}
              &nbsp;<span style={{ fontStyle: "italic" }}>{entityName}</span>
              &nbsp;
              {t(
                "manager:serão alterados. As URLs dos respectivos itens poderão ser reescritas e a operação levará algum tempo. Ao final do processo você será notificado"
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t("common:Tem certeza que deseja realizar essa alteração?")}
            </Typography>
          </React.Fragment>
        )}
        {_.get(data, "loading", false) && (
          <>
            {_.get(data, "total", 0) > 0 ? (
              <LinearProgress
                variant="determinate"
                value={normalise(_.get(data, "progress", 0))}
              />
            ) : (
              <CircularProgress />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {_.get(data, "confirm", false) ? (
          <>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              {t(`common:Cancelar`)}
            </Button>
            <Button onClick={handleConfirm} variant="contained" color="primary">
              {t(`common:Confirmar`)}
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={onclose ? handleClose : handleCancel}
          >
            {
              onclose
                ? _.get(data, "loading", false)
                  ? t("manager:Fechar, o processamento continuará")
                  : t("common:Fechar")
                : t(`common:Voltar`)
            }
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DialogProgress
