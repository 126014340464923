import React, { Fragment, useState, useCallback, useMemo } from "react";
import axios from 'axios'
import _ from 'lodash'

import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useSnackbar } from "notistack"

import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Radio,
  RadioGroup,
  Box,
  Switch
} from "@material-ui/core";

import { mdiProgressDownload } from "@mdi/js"
import Icon from "@mdi/react"
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import { useStyles } from "../styles";

import ListDownloadsbyitems from './ListDownloadsbyitems'
import AlertDialogSimple from 'components/Alert/AlertDialogSimple'

import Settings from 'utils/Settings'
import { Creators as DownloadCentralActions } from "flux/ducks/Download_central"
import { Creators as ItemsActions } from "flux/ducks/Items"
import { getDefaultName } from '../utils'

const INITIAL_STATE = {
  openDialogAlert: false,
  inputName: '',
  inputNameError: false,
  alternativeName: { id: 'item-taxonomy' },
  recipeName: { id: 'original-assets', type: 'download' },
  loading: false,
  mustNotifyThroughMail: false,
  mustZipAssetsWithoutFolders: 'default',
  include_metadata: false
}

const BulkDownload = ({ children, handleSetOpen, openDefault, dataItems, pathDefault, paramsToItems }) => {
  const [state, setState] = useState({ open: openDefault, ...INITIAL_STATE })
  const {
    data: { client_slug },
    userData: { region },
    requestHeaders,
    pathServer,
    markedItems
  } = useSelector((state) => _.get(state, "appReducer", null))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles();

  const show = () => (event) => {
    event.preventDefault();
    setState(prev => ({
      ...prev, open: event.currentTarget
    }), handleSetOpen(true))
  };

  const hide = useCallback(() => {
    dispatch(ItemsActions.setOpenDialogItemsMarked({ open: '' }))
    setState({ open: null, ...INITIAL_STATE }, handleSetOpen(false))
  }, [handleSetOpen, dispatch])

  const handleChangeInput = useCallback((value) => {
    if (value.length > 30) {
      setState(prev => ({ ...prev, inputNameError: true }))
    } else {
      setState(prev => ({ ...prev, inputNameError: false, inputName: value }))
    }
  }, [])

  const onChangeOption = useCallback((field, value) => {
    setState(prev => ({
      ...prev, [field]: {
        id: _.get(value, 'id', ''),
        ...(_.get(value, 'type', '') && { type: value.type })
      }
    }))
  }, [])

  const onChangeNotificationEmail = useCallback(() => {
    setState(prev => ({ ...prev, mustNotifyThroughMail: !prev.mustNotifyThroughMail }))
  }, [])

  const onChangeMustZipAssetsWithoutFolders = useCallback((value) => {
    setState(prev => ({ ...prev, mustZipAssetsWithoutFolders: value }))
  }, [])

  const onChangeIncludeMetadata = useCallback((value) => {
    setState(prev => ({ ...prev, include_metadata: value.target.checked }))
  }, [])

  const getParameters = useCallback((parameters) => {
    let params = {}
    const mustZipAssetsWithoutFolders = _.get(state, "mustZipAssetsWithoutFolders", null) !== 'default'
    const inputName = _.get(state, "inputName", null)
    const mustNotifyThroughMail = _.get(state, "mustNotifyThroughMail", null)

    if (_.isEmpty(paramsToItems)) {
      params = {
        items: _.get(dataItems, 'items', []).map(i => {
          delete i.assets
          return i
        }),
        type: _.get(state, 'recipeName.type', ''),
        bulk: true,
        ...(_.get(state, 'alternativeName.id') !== 'item-taxonomy' && {
          alternativename_id: _.get(state, 'alternativeName.id', '')
        }),
        name: Boolean(inputName.trim()) ? inputName : getDefaultName(client_slug, region),
        ...(_.get(parameters, 'force', false) && { force: true }),
        ...(mustNotifyThroughMail && { mustNotifyThroughMail: true }),
        ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
        ...(state.include_metadata && { include_metadata: true })
      }
    } else {
      params = {
        type: _.get(state, 'recipeName.type', ''),
        ...(_.get(state, 'alternativeName.id') !== 'item-taxonomy' && {
          alternativename_id: _.get(state, 'alternativeName.id', '')
        }),
        bulk: true,
        ...(Boolean(inputName.trim()) && { name: inputName }),
        ...(mustNotifyThroughMail && { mustNotifyThroughMail: true }),
        ...(mustZipAssetsWithoutFolders && { mustZipAssetsWithoutFolders }),
        ...(state.include_metadata && { include_metadata: true }),
        ...paramsToItems
      }
      delete params.category_slug
    }
    return params
  }, [state, dataItems, client_slug, region, paramsToItems])

  const onClickDownloads = useCallback((parameters) => {
    if (markedItems.length > Settings.MAX_ITEMS_DOWNLOAD) {
      enqueueSnackbar(t("DAM:Você selecionou mais itens que o permitido para download em massa.",
        { maximum: Settings.MAX_ITEMS_DOWNLOAD, selected: markedItems.length }
      ), {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      return
    }

    const payload = getParameters(parameters)

    setState(prev => ({ ...prev, loading: true }),
      axios.post(`${pathServer}/${pathDefault}`, payload, requestHeaders)
        .then((resp) => {
          setState(prev => ({ ...prev, loading: false, openDialogAlert: true }),
            dispatch(DownloadCentralActions.setReloadDownloadData())
          )
        })
        .catch((err) => {
          const message = _.get(err, 'response.data.message', _.get(err, 'response.headers["x-message"]', t("manager:Erro inesperado. Contate o suporte")))
          setState(prev => ({ ...prev, loading: false }), enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }))
        })
    )
  }, [enqueueSnackbar, markedItems, pathServer, requestHeaders, t, getParameters, pathDefault, dispatch])

  const getDataCategory = useCallback(() => {
    let data = []
    Object.entries(dataItems.items).forEach(([key, value]) => {
      if (!data.some(i => i === value.category_slug) && Boolean(value.category_slug)) {
        data.push(value.category_slug)
      }
    })
    return data
  }, [dataItems.items])

  const constants = useMemo(() => ({
    dataError: markedItems.filter((item) => item.downloadable === false),
    hasError: (markedItems.filter((item) => item.downloadable === false).length > 0),
    category_slug: getDataCategory()
  }), [markedItems, getDataCategory])

  return (
    <Fragment>
      {children(show)}
      {Boolean(state.open) && (
        <Dialog
          open={Boolean(state.open)}
          onClose={hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.root}
          maxWidth='lg'
        >
          <DialogTitle id="alert-dialog-title" className={classes.title}>
            <Box className="info">
              <GetAppRoundedIcon id="main" />
              <span>
                {constants.hasError
                  ? t("DAM:O seu Perfil não possui permissão para baixar estes Itens.")
                  : t("common:Download")}
              </span>
            </Box>
          </DialogTitle>

          <Box className={classes.details}>
            <Grid container spacing={6} style={{ marginBottom: 24 }}>
              {
                constants.hasError ? (
                  <List>
                    {constants.dataError.map((item) => (
                      <ListItem key={item.id}>
                        <Avatar variant="rounded" alt={_.get(item, 'name', '')}
                          src={_.get(item, 'assets[0].thumbnail_card_webp', _.get(item, 'assets[0].thumbnail_card_original', _.get(item, 'assets[0].thumbnail_pre_webp', _.get(item, 'assets[0].thumbnail_pre_original', ''))))}
                          style={{ marginRight: 15 }}
                        />
                        <ListItemText style={{ margin: 0 }} primary={_.get(item, 'name', '')} secondary={_.get(item, 'taxonomy.name', '')} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <>
                    <Grid item md={4} xs={12} style={{ borderRight: '1px solid #e0e0e0' }}>
                      <ListDownloadsbyitems
                        data_slugs={[..._.get(paramsToItems, 'category_slug', []), ...constants.category_slug]}
                        path='items/listalternativenamesbyitems'
                        title={t("common:Nome do item")}
                        option={_.get(state, "alternativeName.id", null)}
                        optionFieldName='alternativeName'
                        onChangeOption={onChangeOption}
                        opstionsDefault={[
                          { id: 'item-taxonomy', title: t('manager:Taxonomia do item'), can_use: true, hasHighLow: true }
                        ]}
                        alternativesNames
                      />
                    </Grid>

                    <Grid item md={4} xs={12} style={{ borderRight: '1px solid #e0e0e0' }}>
                      <ListDownloadsbyitems
                        data_slugs={[..._.get(paramsToItems, 'category_slug', []), ...constants.category_slug]}
                        path='items/listrecipesbyitems'
                        title={t("common:Selecione o formato")}
                        option={_.get(state, "recipeName.id", null)}
                        optionFieldName='recipeName'
                        onChangeOption={onChangeOption}
                        opstionsDefault={[
                          {
                            id: 'original-assets',
                            title: t("common:Ativos originais"),
                            type: 'download',
                            can_use: true,
                            hasHighLow: true,
                          },
                          {
                            id: 'high-quality-web-images',
                            title: t("common:Imagens da web em alta"),
                            type: 'path_clone',
                            can_use: true,
                            hasHighLow: true,
                          },
                          {
                            id: 'downloadable-web-images',
                            title: t("common:Imagens da web em baixa"),
                            type: 'path_low',
                            can_use: true,
                            hasHighLow: true,
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Grid>
                        <Typography variant='body1' style={{ color: "#666" }}>
                          {t("common:Defina seu ZIP")}:
                        </Typography>

                        <Grid style={{ width: '100%' }}>
                          <FormControl component="fieldset">
                            <TextField
                              label={t("common:Nome")}
                              variant="outlined"
                              placeholder={getDefaultName(client_slug, region)}
                              value={_.get(state, "inputName", null)}
                              onChange={evt => handleChangeInput(_.get(evt, 'target.value', ''))}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={_.get(state, "inputNameError", null)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid style={{ marginTop: 20 }}>
                        <Typography variant='body1' style={{ margin: 0 }}>
                          {t("common:Itens dentro do ZIP")}:
                        </Typography>
                        <Grid style={{ width: '100%' }}>
                          <FormControl component="fieldset">
                            <RadioGroup>
                              <FormControlLabel
                                value="default"
                                control={
                                  <Radio
                                    checked={_.get(state, "mustZipAssetsWithoutFolders", null) === 'default'}
                                    onChange={() => onChangeMustZipAssetsWithoutFolders('default')}
                                    name="default"
                                    color='primary'
                                  />
                                }
                                label={t(`common:Cada Item em uma pasta`)}
                                style={{ color: "#666" }}
                              />
                              <FormControlLabel
                                value="mustZipAssetsWithoutFolders"
                                control={
                                  <Radio
                                    checked={_.get(state, "mustZipAssetsWithoutFolders", null) === 'mustZipAssetsWithoutFolders'}
                                    onChange={() => onChangeMustZipAssetsWithoutFolders('mustZipAssetsWithoutFolders')}
                                    name="mustZipAssetsWithoutFolders"
                                    color='primary'
                                  />
                                }
                                label={t(`common:Todos os Itens em uma única pasta`)}
                                style={{ color: "#666" }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid style={{ marginTop: 20 }}>
                        <Typography variant='body1' style={{ margin: 0 }}>
                          {t("common:Receber notificação")}:
                        </Typography>
                        <Grid style={{ width: '100%' }}>
                          <FormControl component="fieldset">
                            <FormControlLabel
                              control={<Checkbox checked color="primary" name="checked_platform" disabled />}
                              label={t(`common:Na plataforma`)}
                              style={{ color: "#666" }}
                            />
                            <FormControlLabel
                              control={<Checkbox color="primary" onChange={onChangeNotificationEmail} checked={_.get(state, "mustNotifyThroughMail", null)} name="checked_email" />}
                              label={t(`common:Por e-mail`)}
                              style={{ color: "#666" }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid style={{ marginTop: 20 }}>
                        <Typography variant='body1' style={{ margin: 0 }}>
                          {t("common:Informações de metadados")}:
                        </Typography>
                        <Grid style={{ width: '100%' }}>
                          <FormControl component="fieldset">
                            <Switch
                              checked={state.include_metadata}
                              onChange={onChangeIncludeMetadata}
                              color="primary"
                              name="include_metadata"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              }
            </Grid>
          </Box>

          <Box className={classes.actions}>
            <Button
              style={{ height: 40 }}
              variant="outlined"
              color="primary"
              onClick={hide}
            >
              {t("common:Cancelar")}
            </Button>
            <Button
              style={{ height: 40, marginLeft: 10 }}
              variant="contained"
              color="primary"
              onClick={onClickDownloads}
              disabled={_.get(state, "loading", null) || constants.hasError}
            >
              {t("common:solicitar download")}
            </Button>
          </Box>
          <AlertDialogSimple
            open={_.get(state, "openDialogAlert", null)}
            icon={<Icon path={mdiProgressDownload} id="main" />}
            handleClose={() => {
              setState(prev => ({ ...prev, openDialogAlert: false }))
              hide()
            }}
            title={t(`common:Download em andamento`)}
            description={t(`common:Acompanhe na Central de downloads a sua solicitação. Uma notificação será enviada quando os Itens estiverem prontos.`)}
          />
        </Dialog>
      )}
    </Fragment>
  );
}

BulkDownload.defaultProps = {
  handleSetOpen: () => { },
  openDefault: null,
  dataItems: { items: [] },
  pathDefault: 'item/download'
}

export default BulkDownload
