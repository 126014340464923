import UploadDefault from "../Core/UploadLanguage"
import {settings} from "./_settings.js";
import { compose } from "recompose"
import connect from "react-redux/es/connect/connect";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next"

import { styles } from '../Core/UploadLanguage/styles'

import { withSnackbar } from 'notistack';
class Upload extends UploadDefault {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            ...settings,
        };
    }
}

const mapStateToProps = ({ appReducer }) => {
    return {
        requestHeaders: appReducer.requestHeaders,
        pathServer: appReducer.pathServer,
        pathClient: appReducer.pathClient,
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, null)
)(withTranslation(["manager", "common"])(withSnackbar(Upload)))
