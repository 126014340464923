import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        color: "#666",
        "& #div-relatedItemsComboBox": {
            margin: "0 !important"
        }
    },
    fieldDate: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#666',
        marginTop: 16,
        '& :disabled': {
            color: 'currentColor'
        },
        '& input': {
            color: '#666',
            '&::-webkit-calendar-picker-indicator': {
                filter: 'invert(42%) sepia(0%) saturate(264%) hue-rotate(165deg) brightness(92%) contrast(89%)'
            }
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column',
            '& span.text-range': {
                margin: '20px 0',
            },
        },
    }
}))