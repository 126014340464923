import React, { useCallback, useState } from 'react';
import _ from "lodash"
import { useTranslation } from 'react-i18next'
import MUIRichTextEditor from "mui-rte"
import {
    makeStyles,
    Grid,
    TextField,
    Typography,
    Box
} from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { convertToRaw, EditorState } from 'draft-js'

import PaperCustom from '../PaperCustom';

import { useDataFormItem } from "../context"

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    labelDescription: {
        top: "12px",
        margin: '0!important',
        position: "relative",
        background: "#fff",
        display: 'inline-block !important',
        left: "11px",
        padding: "0 3px",
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: '14px !important',
        fontWeight: '400 !important',
        lineHeight: 1,
        letterSpacing: '0.00938em',
        zIndex: 1
    },
    wrapperRichText: {
        minHeight: "200px",
        border: "1px solid #bababa",
        borderRadius: "4px",
    },
}))

const defaultTheme = createMuiTheme({
    overrides: {
        MUIRichTextEditor: {
            container: {
                margin: 0
            },
            toolbar: {
                borderBottom: '1px solid #eee',
                padding: '8px 0',
            },
            placeHolder: {
                margin: 0,
                padding: '19px 16px',
            },
            editorContainer: {
                margin: 0,
                padding: '19px 16px',
            }
        }
    }
})

const InfoItem = () => {
    const [editorStateTemp, setEditorStateTemp] = useState(EditorState.createEmpty())
    const { info: state, changeName, changeEditorDraft, errors, isGallery, isEdit } = useDataFormItem()
    const classes = useStyles()
    const { t } = useTranslation()

    const changeStateEditorDraft = _.debounce((data) => handleChangeEditorDraft(data), 1000)
    const changeStateName = _.debounce((data) => changeName(data), 1000)

    const handleChangeEditorDraft = useCallback((editorValue) => {
        const currentContent = editorValue.getCurrentContent()
        if (currentContent) {
            const dataDescription = convertToRaw(currentContent)
            const textPerLine = dataDescription.blocks.map((el) => el.text).join(" ").trim()
            const callback = () => changeEditorDraft({ editorState: { text: textPerLine, data: JSON.stringify(dataDescription) } })
            setEditorStateTemp(editorValue, callback())
        }
    }, [changeEditorDraft])

    return (
        <Grid item xs={12} id="titleDesc">
            <PaperCustom title="Nome e Descrição">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={t("common:Nome")}
                            defaultValue={_.get(state, 'name', '')}
                            onChange={(evt) => changeStateName(_.get(evt, 'target.value', ''))}
                            variant="outlined"
                            error={_.get(errors, 'name', false)}
                            disabled={isGallery && !isEdit}
                            helperText={
                                (isGallery && !isEdit)
                                    ? t("common:A categoria trata-se de uma galeria, portanto o nome do item terá o mesmo nome do arquivo.")
                                    : _.get(errors, 'name', '')}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.labelDescription}>
                            {t("common:Descrição")}
                        </Typography>

                        <Box className={classes.wrapperRichText}>
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor
                                    maxLength={1000}
                                    controls={["bold", "italic", "underline", "link"]}
                                    label={t("common:Descrição do item")}
                                    onChange={changeStateEditorDraft}
                                    editorState={editorStateTemp}
                                    defaultValue={_.get(state, 'editorState.data', "")}
                                />
                            </MuiThemeProvider>
                        </Box>
                    </Grid>
                </Grid>
            </PaperCustom>
        </Grid>
    );
}

export default React.memo(InfoItem)
