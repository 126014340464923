import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  listWrapper: {
    marginTop: "30px",
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "calc(100vh - 200px)",
    wordWrap: "break-word",
    "& button:first-child": {
      borderTop: "1px solid #DDDDDD",
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 25px",
  },
  contentColor: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    borderRadius: 4,
    marginBottom: 16,
    padding: 20
  }
}))
