import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux"
import axios from 'axios'
import { useSnackbar } from "notistack"

import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    TextField,
    Typography,
    IconButton,
    useMediaQuery
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";

import CloseIcon from "@material-ui/icons/Close"

import { mdiSend } from '@mdi/js'
import Icon from "@mdi/react"
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'

import _ from 'lodash'

const DialogUserSupport = ({
    open,
    handleClose,
}) => {
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorField, setErrorField] = useState(false)
    const [dataUser, setDataUser] = useState({
        name: '', email: '', msg: ''
    })

    const { requestHeaders, pathServer, userData: { user_id }, data: { name } } = useSelector(
        (state) => state.appReducer
    )

    const classes = useStyles();
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const getData = useCallback((useId) => {
        axios.get(`${pathServer}/user/${useId}`, requestHeaders)
            .then(function ({ data }) {              
                setDataUser({
                    name: _.get(data, 'user.name', ''),
                    email: _.get(data, 'user.email', ''),
                    msg: ''
                })
            })
            .catch((err) => {
                console.log(err)
            });
    }, [pathServer, requestHeaders])

    useEffect(() => {
        let isMounted = true
        if (isMounted && Boolean(user_id)) getData(user_id)
        return () => { isMounted = false }
    }, [getData, user_id])

    const submitForm = () => {
        if (errorField) {
            return
        }
        setLoading(true)

        const parameters = {
            message: dataUser.msg
        }

        axios.post(`${pathServer}/client/support`, parameters, requestHeaders)
            .then((resp) => {
                setLoading(false)
                setSubmitted(true)
            })
            .catch((err) => {
                setLoading(false)
                const message = err.response
                    ? t(err.response.data.slug) || err.response.data.message || err.response.headers["x-message"]
                    : t("Erro inesperado. Contate o suporte")
                enqueueSnackbar(message, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                })
                console.log(err)
            });
    }

    const handleChange = (evt) => {
        const value = evt.target.value

        if (value.length > 100) {
            setErrorField(true)
        } else {
            setErrorField(false)
        }

        setDataUser(prev => ({
            ...prev,
            msg: value
        }))
    }

    const handleCloseDialog = () => {
        setSubmitted(false)
        setDataUser(prev => ({
            ...prev,
            msg: ''
        }))
        handleClose()
    }

    const DISABLED_SUBMIT = loading || errorField || dataUser.msg.trim().length === 0

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.root_dialog}
        >
            <div className={classes.root_title}>
                <div />
                <div className='center'>
                    <Icon path={mdiSend} id="main" size={1} />
                    <Typography variant="body1">
                        {t("DAM:Suporte")}
                    </Typography>
                </div>
                <IconButton color="inherit" onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </div>

            {
                submitted &&
                <DialogContent className={classes.content}>
                    <div className={classes.content_send}>
                        <CheckCircleRoundedIcon />
                        <Typography variant="body1" className='center'>
                            {t("DAM:E-mail enviado com sucesso.")}
                        </Typography>
                        <Typography variant="body1">
                            {t("DAM:A resposta chegará em seu e-mail de acesso ao ")} {name}
                        </Typography>
                    </div>
                </DialogContent>

            }
            {
                !submitted &&
                <DialogContent className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label={t("common:Nome")}
                                variant="outlined"
                                value={dataUser.name}
                                fullWidth
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="email"
                                label={t("common:E-mail")}
                                variant="outlined"
                                value={dataUser.email}
                                fullWidth
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {t("DAM:Envie a sua dúvida sobre o conteúdo para o suporte de", { name })}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="message"
                                label={t("DAM:Escreva aqui a sua dúvida")}
                                variant="outlined"
                                value={dataUser.msg}
                                onChange={handleChange}
                                rows={4}
                                multiline
                                fullWidth
                                disabled={loading}
                                error={errorField}
                                helperText={`${dataUser.msg.length}/100`}
                            />
                        </Grid>

                    </Grid>
                    <Grid item xs={12} className={classes.actions}>
                        <Button
                            style={{ height: 40, width: 115, marginRight: 20 }}
                            variant="outlined"
                            color="primary"
                            onClick={handleCloseDialog}
                        >
                            {t("common:Cancelar")}
                        </Button>
                        <Button
                            style={{ height: 40, width: 115 }}
                            variant="contained"
                            color="primary"
                            onClick={submitForm}
                            disabled={DISABLED_SUBMIT}
                        >
                            {t("common:Enviar")}
                        </Button>
                    </Grid>
                </DialogContent>
            }
        </Dialog>
    );
};


const UserSupport = () => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const mobile = useMediaQuery('(max-width:600px)')
    const { t } = useTranslation()
    const { userData: { hasSupport } } = useSelector((state) => state.appReducer);

    return hasSupport ? (
        <>
            <button
                onClick={() => setOpen(prev => !prev)}
                className={classes.button_support}
            >
                <Icon path={mdiSend} id="main" size={.8} />
                {!mobile && <Typography variant="body2">{t("DAM:Suporte")}</Typography>}
            </button>

            <DialogUserSupport open={open} handleClose={() => setOpen(prev => !prev)} />
        </>
    ) : null

}

export default UserSupport
