import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
    List,
    Paper,
    ListItem,
    ListItemText,
    Divider,
    makeStyles,
    Button
} from '@material-ui/core'

import AlertEditConfirm from "./AlertEditConfirm"

import { useDataFormItem } from "./context"

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        height: "100%"
    },
    liButtom: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16
    }
}))

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />
}

const links = [
    { label: "Segmento e Categoria", id: "segmentsCategory" },
    { label: "Gerenciar Ativos", id: "bucket" },
    { label: "Nome e Descrição ", id: "titleDesc" },
    { label: "Publicação", id: "published" },
    { label: "Metadados", id: "metadata" },
    { label: "Relacionar Itens", id: "relatedItems" },
]

const CreateItemAside = () => {
    const [openAlert, setOpenAlert] = useState({ open: false, published_changed: false })
    const { formValidationAndSubmit, isEdit, published } = useDataFormItem()
    const classes = useStyles()
    const { t } = useTranslation()

    const submitForm = useCallback((evt, comment) => {
        evt.preventDefault()
        setOpenAlert({ open: false, published_changed: true }, formValidationAndSubmit({ comment }))
    }, [formValidationAndSubmit])

    const onClick = useCallback(() => {
        if (isEdit) {
            const data = { ...published }
            delete data.validation
            if (published.validation !== JSON.stringify(data)) {
                setOpenAlert({ open: true, published_changed: true })
            } else {
                setOpenAlert({ open: true, published_changed: false })
            }
        } else {
            formValidationAndSubmit()
        }
    }, [published, formValidationAndSubmit, isEdit])

    return (
        <Paper className={classes.root}>
            <List>
                {links.map((item) => (
                    <li key={item.id}>
                        <ListItemLink href={`#${item.id}`}>
                            <ListItemText primary={item.label} />
                        </ListItemLink>
                        <Divider />
                    </li>
                ))}
                <li className={classes.liButtom}>
                    <Button
                        color="primary"
                        variant='contained'
                        fullWidth
                        size='large'
                        onClick={onClick}
                    >
                        {isEdit ? t("common:Salvar") : t("DAM:Criar item")}
                    </Button>
                </li>
            </List>
            {
                openAlert.open && (
                    <AlertEditConfirm
                        open
                        handleClose={() => setOpenAlert(false)}
                        handleConfirm={(evt, comment) => submitForm(evt, comment)}
                        hasAlterDate={openAlert.published_changed}
                    />)
            }
        </Paper>
    )
}

export default CreateItemAside