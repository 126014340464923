import React, { useState } from "react"

import AutoRotatingCarousel from "./AutoRotatingCarousel"
import { useStyles } from "./styles"

import ImageLazyLoad from "../../../../ImageLazyLoad"

const ShowAsset = ({ asset }) => {
  const classes = useStyles()

  const renderView = (asset) => {
    return (
      <ImageLazyLoad
        key={asset.id}
        images={{imgWEBP: asset["thumbnail_card_webp"], imgJPG: asset["thumbnail_card_original"]}}
        styles={{width: 250, height: 250}}  
      />
    )
  }

  return <div className={classes.mediaContent}>{renderView(asset)}</div>
}

const CardCarousel = ({ data }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [assets, setAssets] = useState(data.assets.slice(0, 1))
  
  const decreaseIndex = () => {
    setSlideIndex(() => slideIndex - 1)
  }

  const increaseIndex = () => {
    setSlideIndex((prev) => prev + 1)

    if (assets.length < data.assets.length) {
      const asset = data.assets[slideIndex + 1]
      const fileIds = assets.map((i) => i.id)

      if (fileIds.some((i) => i === asset.id)) {
        return
      } else {
        setAssets((prev) => [...prev, asset])
      }
    }
  }

  return (
    <AutoRotatingCarousel
      increaseIndex={increaseIndex}
      decreaseIndex={decreaseIndex}
      slideIndex={slideIndex}
      dataLength={data.assets.length}
    >
      {assets.map((asset, index) => (
        <ShowAsset asset={asset} key={index} />
      ))}
    </AutoRotatingCarousel>
  )
}

CardCarousel.defaultProps = {
  data: { assets: [] },
}

export default CardCarousel
