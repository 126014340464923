/*eslint-disable no-unused-vars*/
import React from 'react';
/*eslint-enable  no-unused-vars*/
import ComboBox from "./index.js";
import connect from "react-redux/es/connect/connect";
import {mapDispatchToPropsToSettings, mapStateToPropsToSettings} from "../../utils/Flux";
import _ from "lodash";
import { withTranslation } from "react-i18next"

class AttributeComboBox extends ComboBox {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      entity: "attribute",
      title: _.get(props, 'title', props.t('Metadados')),
      field_required: _.get(props, 'field_required', true),
    }
    this.getUrl = this.getUrl.bind(this);
    this.getSelectValue = this.getSelectValue.bind(this);
  }

  getUrl() {
    const { pathServer, pathClient, showAllRegions } = this.props
    const {entity} = this.state;

    if(showAllRegions) {
      return `${pathClient}/form/${entity}`;
    }

    return `${pathServer}/form/${entity}`;
  }

  getSelectLabel(object) {
    const {showAllRegions} = this.props;
    return showAllRegions
      ? `${_.get(object, "region.name", "No Region")} > ${_.get(object, "name", "No Name")}`
      : _.get(object, "name", "No Name");
  }

  getSelectValue(object) {
    const disabledItems
      = this.props.hasOwnProperty('disabledItems') ? this.props.disabledItems : [];
    return {
      disabled: (disabledItems.indexOf(_.get(object, "id", null)) !== -1),
      label: this.getSelectLabel(object),
      name: _.get(object, "name", "No Name"),
      value: _.get(object, "id", null) || "",
      can_be_master: _.get(object, "can_be_master", null) || ""
    }
  }


  componentDidMount() {
    super.componentDidMount.apply(this);
    this.getData();
  }
}

export default connect(
  mapStateToPropsToSettings,
  mapDispatchToPropsToSettings
)(withTranslation(["manager", "common"])(AttributeComboBox))
