import React from 'react';
import _ from 'lodash'
import { Grid, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SegmentsCategory from './SegmentsCategory'
import InfoItem from './InfoItem'
import Published from './Published'
import Metadata from './Metadata'
import RelatedItems from './RelatedItems'

import { useDataFormItem } from "../context"

export default function Sections() {
    const { segments, isEdit } = useDataFormItem()
    const { t } = useTranslation()

    return (
        <Grid container spacing={4}>
            <SegmentsCategory />
            {
                _.get(segments, "isGalleryInitial", false) &&
                <Grid item xs={12} id="info-gallery">
                    <Box style={{
                        borderRadius: 4,
                        backgroundColor: "#e0e0e0",
                        padding: "16px 32px"
                    }}>
                        <Typography variant='body'>
                            {
                                isEdit
                                    ? t("DAM:Você esta editando o item de") + ` `
                                    : t("DAM:Você esta criando o item em uma") + ` `
                            }
                        </Typography>
                        <Typography variant='body' style={{ fontWeight: 700 }}>
                            {t("DAM:Galeria")}
                        </Typography>
                    </Box>
                </Grid>
            }
            <InfoItem />
            <Published />
            {
                (_.get(segments, "attributes", []).length > 0) &&
                <Metadata />
            }
            {
                _.get(segments, "categoryData.canLink", false) &&
                <RelatedItems />
            }
        </Grid>
    );
}
