import React from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import { useStyles } from "components/LoginLayout/styles"
import Loading from "../Loading";
import Login from "./login";
import _ from "lodash"

const AccessModerate = ({
  loggedStatus,
  t,
  history,
  handleSubmit,
  loading,
  message,
}) => {
  const reducerData = useSelector((state) => state.appReducer);
  const classes = useStyles(reducerData.data);

  return (
      <Grid item className={classes.wrapper}>
        <Grid item className={classes.container}>
          <Grid item className="wrapperTitles">
            <Typography variant="h1">
              {reducerData.data.platform_name}
            </Typography>
            <Typography variant="h2">{t('common:Solicitação de acesso')}</Typography>
          </Grid>
          <Grid item className={classes.papers}>
            <Loading showLoading={loading} />
            {!loggedStatus && (
              <Login handleSubmit={handleSubmit} loading={loading} csrf_token={_.get(reducerData, "csrf_token", "")} />
            )}

            {loggedStatus && !loading && (
              <Paper elevation={2} className={classes.content}>
                <div className="logoImageLogin" />
                <Typography className="titleMobile">
                  {t('common:Solicitação de acesso')}
                </Typography>

                <div className="containerText">
                  <Typography>{message}</Typography>
                </div>

                <button
                  className='btnLink'
                  onClick={() => history.push("/login")}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    textAlign: 'left',
                    padding: 0,
                    fontFamily: 'inherit',
                  }}
                >
                  {t("login:Ir para o login")}
                </button>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Grid>

  );
};
export default withTranslation(["common", "login"])(AccessModerate);
