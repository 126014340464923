import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import axios from 'axios'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

import PlayForWorkRoundedIcon from '@material-ui/icons/PlayForWorkRounded';

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialog-paper": {
            padding: 40
        },
        "& .MuiDialogContent-root": {
            padding: '12px 0 0 0'
        }
    },
    title: {
        padding: '0 0 16px 0',
        "& h2": {
            display: "flex",
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#666',
            '& div.info': {
                display: "flex",
                alignItems: 'center',
            },
            "& svg#main": {
                width: "40px",
                height: "40px",
                backgroundColor: theme.palette.primary.main,
                borderRadius: "50%",
                padding: "7px",
                marginRight: "10px",
                color: "#fff",
            },
        },
    },
}))
export default function DialogItemLink({ open, handleClose, params }) {
    const [link, setLink] = useState('')
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            axios
                .get(`${params.pathServer}/tool/${params.slug}/record/${params.id}`, params.requestHeaders)
                .then((resp) => {
                    const result = { ...resp.data.item }
                    setLink(result.item.link)
                })
                .catch((err) => {
                    handleClose()
                    console.log(err)
                })
        }
        return () => {
            isMounted = false
        }
    }, [params.slug, params.id, params.pathServer, params.requestHeaders, handleClose])

    return (
        <Dialog open={open} onClose={handleClose} className={classes.root}>
            <DialogTitle id="alert-dialog-title" className={classes.title}>
                <div className="info">
                    <PlayForWorkRoundedIcon id="main" />
                    <span>{t("manager:A tarefa foi finalizada com sucesso.")}</span>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" variant='body2'>                    
                    {t("manager:O que gostaria de fazer agora?")}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{marginTop: 20, padding: 0}}>
                <Button onClick={handleClose} color="primary" variant="outlined">
                    {t("common:Voltar")}
                </Button>
                <Button onClick={() => history.push(`/dam/${link}`)} color="primary" autoFocus variant="contained">
                    {t("manager:abrir item")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}