import React from 'react'
import { Trans } from 'react-i18next'

export const styleRoot = {
    width: 300,
    minHeight: 70,
    borderRadius: 4,
    backgroundColor: '#2196F3',
    color: '#fff',
    padding: 10,
    boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
}

export const styleHeader = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
}

export const getDescription = (type, details) => {
    switch (type) {
        case 'share-created':
            return <Trans i18nKey="common:shared-generated">
                Link para compartilhamento gerado com sucesso.
            </Trans>
        default:
            return <Trans i18nKey="common:notification_default">
                Você tem notificações não lidas!
            </Trans>
    }
}