import React from 'react';
import { Skeleton as SkeletonMUI } from '@material-ui/lab'

const Skeleton = () => {
    const getLimit = (() => {
        const width = window.innerWidth - 40
        const card = 266
        const row = Math.trunc(width / card)
        let limit = 0

        for (let i = 0; i < 10; i++) {
            if (limit < 30) {
                limit += row
            }
        }

        return limit
    })()

    const getData = (() => {
        let data = []
        for (let i = 1; i < getLimit + 1; i++) {
            data.push({ hasSkeleton: true })
        }
        return data
    })()

    return (

        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {
                getData.map((i) =>
                    <SkeletonMUI key={Math.random()} style={{ margin: 8, borderRadius: 4 }} variant="rect" width={250} height={365} />
                )
            }
        </div>

    )
}

export default Skeleton