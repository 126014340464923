import React, { useEffect, useState } from 'react'

import { Canvas } from '@react-three/fiber'
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OrbitControls } from "@react-three/drei";
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useSnackbar } from "notistack"
import axios from 'axios'

import _ from 'lodash'
import { getTypesTextures } from './utils'

import {
    Button,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useStyles } from "./styles"

import CircularProgressWithLabel from 'components/CircularProgressWithLabel'

const Geometry = ({ data }) => {
    return (
        <>
            {
                data.obj.map((geometry, key) => (
                    <mesh
                        geometry={geometry}
                        scale={.4} key={key}
                    >
                        <meshStandardMaterial
                            {...data.textures}
                        />
                    </mesh>
                ))
            }
        </>
    )
}

const SceneContent = ({ idItem }) => {
    const [dataTexture, setDataTexture] = useState({})
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [erroView3d, setErroView3d] = useState(false)
    const { t } = useTranslation()
    const { pathServer, requestHeaders } = useSelector(
        (state) => state.appReducer
    )
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        let isMounted = true
        const getData = () => {
            axios
                .get(`${pathServer}/item/${idItem}/3D`, requestHeaders)
                .then(({ data }) => {
                    if (isMounted) {
                        const { textures = {}, obj = {} } = data

                        let props = { textures: {} }

                        for (var key in textures) {
                            const param = getTypesTextures(textures[key].type, textures[key].url)
                            props.textures = { ...props.textures, ...param }
                        }
                        const loader = new OBJLoader()

                        loader.load(
                            obj,
                            data => {
                                const object = data.children.map(i => {
                                    i.geometry.attributes.uv2 = i.geometry.attributes.uv
                                    return i.geometry
                                })
                                
                                if (isMounted) {
                                    setDataTexture({
                                        ...props,
                                        obj: object,                                        
                                    })
                                }
                            },
                            function (xhr) {
                                setLoadingProgress((xhr.loaded / xhr.total * 100))
                            },
                            function (error) {
                                if (isMounted) {
                                    setErroView3d(true)
                                    console.log(error)
                                }
                            }
                        )

                    }
                })
                .catch((err) => {
                    if (isMounted) {
                        const message = err.response
                            ? err.response.data.message || err.response.headers["x-message"]
                            : t("manager:Erro inesperado. Contate o suporte")
                        enqueueSnackbar(message, {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                        })
                    }
                })
        }
        getData()
        return () => {
            isMounted = false
        }
    }, [pathServer, requestHeaders, enqueueSnackbar, t, idItem])

    return (
        <>
            {
                erroView3d &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span>
                        {t("manager:Erro ao carregar o arquivo 3D")}
                    </span>
                </div>
            }
            {
                _.isEmpty(dataTexture) && !erroView3d &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgressWithLabel value={loadingProgress} color='primary' />
                </div>
            }
            {
                !_.isEmpty(dataTexture) && !erroView3d &&
                <Canvas
                    colorManagement
                    shadowMap
                    camera={{ position: [-5, 2, 10], fov: 60 }}>
                    <ambientLight intensity={0.3} />
                    <directionalLight
                        castShadow
                        position={[0, 10, 0]}
                        intensity={1.5}
                        shadow-mapSize-width={1024}
                        shadow-mapSize-height={1024}
                        shadow-camera-far={50}
                        shadow-camera-left={-10}
                        shadow-camera-right={10}
                        shadow-camera-top={10}
                        shadow-camera-bottom={-10}
                    />
                    <pointLight position={[-10, 0, -20]} intensity={0.5} />
                    <pointLight position={[0, -10, 0]} intensity={1.5} />
                    <Geometry data={dataTexture} />
                    <OrbitControls />
                </Canvas>
            }
        </>
    )
}

const ViewerModel3D = ({ idItem, handleComponentArea }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <>
            {
                isMobile ? (
                    <div className={classes.root}>
                        <div className={classes.content}>
                            <SceneContent idItem={idItem} />
                            <div className='action_close' onClick={() => handleComponentArea('')}>
                                <CloseRoundedIcon />
                            </div>
                        </div>
                    </div>

                ) : (
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <div className={classes.content}>
                                <SceneContent idItem={idItem} />
                            </div>
                            <div className={classes.footer}>
                                <div className='texts'>
                                    <Typography variant="caption" gutterBottom>
                                        {t('common:Instruções')}
                                    </Typography>
                                    <Typography variant="caption" gutterBottom>
                                        {t('common:Com mouse')}
                                    </Typography>
                                    <Typography variant="caption">
                                        01:{t('common:Utilize o roda central para aproximar ou afastar o objeto.')}
                                    </Typography>
                                    <Typography variant="caption">
                                        02:{t('common:Pressione o botão esquerdo para realizar os movimentos de rotação.')}
                                    </Typography>

                                    <Typography variant="caption" gutterBottom>
                                        {t('common:Com touchpad')}
                                    </Typography>
                                    <Typography variant="caption">
                                        01:{t('common:Utilize o touchpad com os dois dedos para aproximar ou afastar o objeto.')}
                                    </Typography>
                                    <Typography variant="caption">
                                        02:{t('common:Pressione o touchpad com um dos dedos e com outro dedo realize os movimentos de rotação.')}
                                    </Typography>
                                </div>
                                <div className='actions'>
                                    <Button
                                        variant="outlined"
                                        color='primary'
                                        onClick={() => handleComponentArea('')}
                                        disableElevation>
                                        {t('common:Voltar')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}



export default ViewerModel3D